/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useMemo, /* useCallback */} from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormikErrorFocus from 'formik-error-focus';
import { withTranslation } from 'react-i18next';
import { CircularProgress, Grid } from '@mui/material';
import SearchSelect from '../../../core/SearchSelect';
import blastStyles from './styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import { postBlasts, updateBlast, deleteBlast } from "../../services/blast";
import { getDeals } from '../../../deals/services/deals';
import { getTenderos } from '../../../referrers/services/referrers';
import { getGroups } from '../../../groups/services/groups';
import { getCountry } from '../../../core/services/workflow';
import { actions, callAssignToast, crudAction, formDealLabel, formUserLabel, getAssetType, getUrlExtension, mimeTypes, modules, /* capitalize, repeatDays, weeknumber, days, */ formGroupLabel, getDatei18n, dateToOtherZone } from '../../../utils';
// import BasicModal from '../../../core/BasicModal';
// import ToggleDays from '../../../core/TogglesDays';
import { NavLink } from 'react-router-dom';
import ModalCloseButton from '../../../core/ModalCloseButton';
import moment from 'moment';
import 'moment-timezone';
import DatePickerFieldMui from '../../../core/DatePickerFieldMui';


const BlastCreate = ({ t, onClose = () => { }, ...props }) => {
  const [currentBlast, setCurrentBlast] = useState(null);
  const [selectedDeals, setSelectedDeals] = useState([]);
  const [selectedTenderosList, setSelectedTenderosList] = useState([]);
  const [selectedGroupsList, setSelectedGroupsList] = useState([]);
  const [selectedGroupsListCount, setSelectedGroupsListCount] = useState([]);
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [loaderEnabled, setLoaderEnabled] = useState(true);

  // const [currentRepeatEach, setCurrentRepeatEach] = useState(currentBlast?.recurrency?.repeatEach || 1);
  // const [currentRepeatMode, setCurrentRepeatMode] = useState(currentBlast?.recurrency?.repeatMode || "day");
  // const [currentRepeatAtArray, setCurrentRepeatAtArray] = useState(currentBlast?.repeatAtArray || []);
  // const [currentRepeatAtObject, setCurrentRepeatAtObject] = useState(currentBlast?.repeatAtObject || null);
  // const [checkRepeatEnd, setCheckRepeatEnd] = useState(currentBlast?.checkRepeatEnd || "never");
  // const [currentRepeatEndDate, setCurrentRepeatEndDate] = useState(currentBlast?.recurrency?.repeatEndDate || (currentBlast?.checkRepeatEnd === 'the' ? currentBlast?.endDate : null) || null);
  // const [currentRepeatAfter, setCurrentRepeatAfter] = useState(currentBlast?.recurrency?.repeatAfter || null);
  const [userCount, setUserCount] = useState(0);
  const [countryTimeZone, setCountryTimeZone] = useState(null);

  const module = modules(t).blast;
  const headerCountry = getCountry();
  const initialCountry = headerCountry || "";

  const dateFormat = {
    year: 'numeric', month: 'short', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false,
    timeZoneName: 'short'
  };
  const datei18n = getDatei18n(initialCountry);

  // eslint-disable-next-line no-useless-escape
  const url = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

  const BlastSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')),
    url: Yup.string().when('checkBlastType', {
      is: (checkBlastType) => checkBlastType && checkBlastType === "webhook",
      then: Yup.string().matches(url, t('Enter a valid url')).min(1).required(t('Required'))
    }).nullable(),
    // deals: Yup.array().when('checkBlastType', {
    //   is: (checkBlastType) => checkBlastType && checkBlastType === "deals",
    //   then: Yup.array().min(1).required(t('Required'))
    // }).nullable(),
    limit: Yup.number().when('hasLimit', {
      is: (hasLimit) => !hasLimit,
      then: Yup.number().required(t('Required')),
      otherwise: Yup.number().nullable(),
    }),
    hasPhone: Yup.boolean().required(t('Required')),
    hasName: Yup.boolean().nullable().required(t('Required')).nullable(),
    hasMessage: Yup.boolean().nullable().required(t('Required')).nullable(),
    message: Yup.string().when('hasMessage', {
      is: (hasMessage) => hasMessage,
      then: Yup.string().min(1,t('Required')).required(t('Required')),
      otherwise: Yup.string().nullable(),
    }),
    startDate: Yup.string().required(t('Required')),
    // recurrency: Yup.object().shape({
    //   repeatEach: Yup.number().min(1,t('Required')).required(t('Required')),
    //   repeatMode: Yup.string().required(t('Required')),
    //   repeatAt: Yup.array().nullable() || Yup.object().nullable(),
    //   repeatEnd: Yup.string().nullable(),
    //   repeatAfter: Yup.number().nullable(),
    // }).nullable(),
    groups: Yup.array().when('referrers', {
      is: (array) => array && array.length < 1,
      then: Yup.array(),
      otherwise: Yup.array(),
    }).nullable(),
    referrers: Yup.array().when('groups', {
      is: (array) => array && array.length < 1,
      then: Yup.array(),
      otherwise: Yup.array(),
    }).nullable(),
  }, [['referrers', 'groups', 'checkBlastType', 'hasLimit', 'hasMessage']]);


  

  const crudOptions = useMemo(()=> ({
    module,
    name: currentBlast?.name || '',
  }), []);

  const formatDealsArray = (deals) => (deals?.map(deal => ({ label: formDealLabel(deal), value: deal?.id, ...deal })));
  const formatTenderosArray = (tenderos) => (tenderos?.map(tendero => ({ label: formUserLabel(tendero), value: tendero?.id, ...tendero })));
  const formatGroupsArray = (groups) => (groups?.map(group => ({ label: formGroupLabel(group) , value: group?.id, ...group })));


  const returnDeals = async (queryTerm) => {
    let res = [];
    const dealsResult = await callAssignToast(getDeals.bind(null, { q: queryTerm, country: initialCountry }), () => { }, 'list Deals');
    if (dealsResult.data) res = formatDealsArray(dealsResult.data);
    return res;
  };

  const returnGroups = async (queryTerm) => {
    let res = [];
    const groupsResult = await callAssignToast(getGroups.bind(null, { q: queryTerm, country: initialCountry }), () => { }, 'list Groups');
    if (groupsResult.data) res = formatGroupsArray(groupsResult.data);
    return res;
  };

  const returnTenderos = async (queryTerm) => {
    let res = [];
    const tenderosResult = await callAssignToast(getTenderos.bind(null, { q: queryTerm, country: initialCountry }), () => { }, 'list Tenderos');
    if (tenderosResult.data) res = formatTenderosArray(tenderosResult.data);
    return res;
  };

  /**
   * Passed to the list to execute on Item click, updates the form with the selected Blast
   * @param {string} id
   */
  const fetchBlastByObject = (object) => {
    const blast = { ...object };
    blast.deals = formatDealsArray(blast.deals);
    blast.groups = formatGroupsArray(blast?.groups);
    setSelectedGroupsList(blast?.groups);
    setSelectedGroupsListCount(blast?.groups.map(option => option.count));
    blast.referrers = formatTenderosArray(blast?.referrers);
    setSelectedTenderosList(blast?.referrers);
    if(blast.startDate){
      blast.startDate = dateToOtherZone(blast?.startDate,countryTimeZone?.[0]);
    }
    if (!blast.limit) {
      blast.hasLimit = true;
    }
    // if(blast.type === "deals"){
    //   blast.checkBlastType = "deals";
    // } else {
    //   blast.checkBlastType = "webhook";
    // };
    // if(blast.repeatAt){
    //   if(Array.isArray(blast.repeatAt)){
    //     blast.repeatAtArray = blast.repeatAt;
    //   };

    //   if(blast.repeatAt && typeof blast.repeatAt === "object"){
    //     let repeatAtObject = null;

    //     if(blast.repeatAt?.week){
    //       repeatAtObject = {
    //         value: `${t('MonthlyOnWeek', { week: weeknumber(new Date(blast?.startDate))}))} ${days(new Date(blast?.startDate).getDay())}` || null,
    //         label: `${t('MonthlyOnWeek', { week: weeknumber(new Date(blast?.startDate))}))} ${days(new Date(blast?.startDate).getDay())}`,
    //         objectData: {date: `${new Date(blast?.startDate).getDate()}`, week:`${weeknumber(new Date(blast?.startDate))}`} || null,
    //       };
    //     }else{
    //       repeatAtObject = {
    //         value:`${t?.MonthlyOnDay} ${new Date(blast?.startDate).getDate()}` || null,
    //         label: `${t?.MonthlyOnDay} ${new Date(blast?.startDate).getDate()}`,
    //         objectData: {date: `${new Date(blast?.startDate).getDate()}`} || null
    //       };
    //     };

    //     blast.repeatAtObject = repeatAtObject;
    //   };
    // }
    // if(blast?.endDate){
    //   blast.checkRepeatEnd = "the";
    // } else if(blast.recurrency?.repeatAfter > 0) {
    //   blast.checkRepeatEnd = "after";
    // } else {
    //   blast.checkRepeatEnd = "never";
    // }
    setCurrentBlast(blast);
  };

    useEffect(() => {
      // console.log("moment.tz?", moment.tz?.zonesForCountry(initialCountry)[0])
      setCountryTimeZone(moment.tz?.zonesForCountry(initialCountry));
    }, [initialCountry]);
    
    // On load, get Tenderos, Groups, Deals and Blasts
    useEffect(() => {
      const initLoad = async () => {
        // await Promise.all([loadTenderos(), loadGroups(), loadDeals()]);
        setReadyToLoad(true);
      };
      initLoad();
    }, []);
  
    // On CurrentBlast update, update Blasts list
    useEffect(() => {
      const currentUpdate = async () => {
        setSelectedTenderosList(currentBlast?.referrers || []);
        setSelectedGroupsList(currentBlast?.groups || []);
      };
      if (currentBlast) currentUpdate();
    }, [currentBlast]);
  
    useEffect(() => {
      const afterLoad = async () => {
        if (props?.object) fetchBlastByObject(props?.object);
        setLoaderEnabled(false);
      };
      if (readyToLoad) afterLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readyToLoad]);

    useEffect(() => {
      let groupCount = 0;
      let tenderosCount = 0;
      let usersCount = 0;

      if(selectedGroupsListCount && selectedGroupsListCount?.length){
        groupCount = selectedGroupsListCount?.reduce((countTotal, group)=>countTotal + (group || 0),0);
      }

      if(selectedTenderosList && selectedTenderosList?.length){
        tenderosCount = selectedTenderosList?.length;
      };

      usersCount = groupCount + tenderosCount;

      setUserCount(usersCount);
    }, [selectedGroupsList, selectedTenderosList]);
    
  // Form placeholders
  const placeholder = {
    name: t('Full Name'),
    description: t("Enter Blast description"),
    tags: t("Blast Tags"),
    deals: ` -- ${t("Select Deals")} -- `,
    referrers: ` -- ${t("Select Tenderos")} -- `,
    groups: ` -- ${t("Select Groups")} -- `,
    messageGreeting: `${t("We hope you are having a great day")}. ${t("Please reply if you want to receive our current deals")}`,
    userResponse: `${t("Yes")}!`,
    // messageTemplate: t('Hello! Your new daily requested product is "' + selectedDeals?.name || '' + '"You can share the product information below with your customers:')
    messageTemplate: `${t('Hello! Your new daily requested product is')} ${selectedDeals?.name} . ${t('You can share the product information below with your customers')} :`,
    writeMessage: `${t('Write message')}`,
    url: t('URL'),
    limit: t('Limit'),


  };
  // dealsDisplayList.unshift({ value: '', label: placeholder.deals, disabled: true });

  const waMessagesArray = [
    { type: 'received', text: `${t("We hope you are having a great day")}. ${t("Please reply if you want to receive our current deals")}` },
    { type: 'sent', text: `${t("Yes")}!` }
  ];

  selectedDeals?.forEach(deal => {
    const parsedMedia = (typeof deal.media === 'string') ? JSON.parse(deal.media) : deal.media;
    waMessagesArray.push({ type: 'received', text: t('newDealMessage', { name: deal.name, details: deal.details || '' }) });
    parsedMedia?.forEach(mediaURL => {
      waMessagesArray.push({ type: 'received', mediaSrc: mediaURL.url });
    });
    let messageText = `${deal.name}:\n${deal.details}\n`;
    if (deal.discountPrice) {
      messageText += `${t('Price')}: ${deal.discountPrice}\n${t('Discount')}: ${deal.discount}`;
    } else {
      messageText += `${t('Price')}: ${deal.price}`;
    }
    waMessagesArray.push({ type: 'received', text: messageText });
  });

  // Form type, used to differentiate between update and delete
  const submitType = {
    update: 1,
    delete: 2
  };

  // Formik initial/default values
  const formInitialValues = {
    type: currentBlast?.type || "",
    name: currentBlast?.name || "",
    url: currentBlast?.url || "",
    // deals: currentBlast?.deals || [],
    referrers: currentBlast?.referrers || [],
    groups: currentBlast?.groups || [],
    limit: currentBlast?.limit || 0,
    hasLimit: currentBlast?.hasLimit || false,
    checkBlastType: /* currentBlast?.checkBlastType || */ "webhook",
    hasPhone: currentBlast?.hasPhone || true,
    hasName: currentBlast?.hasName || false,
    hasMessage: currentBlast?.hasMessage || false,
    message: currentBlast?.message || "",
    startDate: currentBlast?.startDate || "",
    // recurrency: currentBlast?.recurrency || null,
    // checkRepeatEnd: currentBlast?.checkRepeatEnd || "never",
    // repeatAtArray: currentBlast?.repeatAtArray || [],
    // repeatAtObject: currentBlast?.repeatAtObject || null,
    operationType: submitType.update // update
  };

  /**
   * Function called whenever the Formik form is submitted
   * @async
   * @param {Object} values Form values
   * @param {Object} options Functions provided by the Formik form
   */
  const formOnSubmit = async (values) => {
    if (!currentBlast) { // CREATE
      const currentTenderos = values?.referrers?.map((tendero) => tendero?.value);
      const currentGroups = values?.groups?.map((group) => group?.value);
      const deals = values?.deals?.map((deal) => (deal.id));
      const newBlast = {
        name: values?.name,
        type: values?.checkBlastType,
        url: values?.checkBlastType === "webhook" ? values?.url : null,
        deals: values?.checkBlastType === "deals" ? deals : null,
        limit: (!values.hasLimit) ? parseInt(values?.limit, 10) : null,
        hasPhone: values?.hasPhone,
        hasName: values?.hasName,
        hasMessage: values?.hasMessage,
        message: values?.hasMessage ? values?.message : null,
        startDate: new Date(values?.startDate).toISOString(),
        recurrency: values?.recurrency,
        country: initialCountry
      };
      if (!newBlast?.recurrency) Reflect.deleteProperty(newBlast, 'recurrency');
      if (currentTenderos && currentTenderos?.length > 0) newBlast.referrers = currentTenderos;
      if (currentGroups && currentGroups?.length > 0) newBlast.groups = currentGroups;
      await crudAction(postBlasts.bind(null, newBlast), onClose,
        { ...crudOptions, name: values?.name, action: actions.CREATE }, t
      );
    } else if (values?.type && values?.type === submitType.delete) { // DELETE
      await crudAction(deleteBlast.bind(null, currentBlast.id), onClose,
        { ...crudOptions, action: actions.DELETE }, t
      );
    } else if (props.clone === true) { // CLONE
      const currentTenderos = values?.referrers?.map((tendero) => tendero?.value);
      const currentGroups = values?.groups?.map((group) => group?.value);
      const deals = values?.deals?.map((deal) => (deal.id));
      const newBlast = {
        name: values?.name,
        type: values?.checkBlastType,
        url: values?.checkBlastType === "webhook" ? values?.url : null,
        deals: values?.checkBlastType === "deals" ? deals : null,
        limit: (!values.hasLimit) ? parseInt(values?.limit, 10) : null,
        hasPhone: values?.hasPhone,
        hasName: values?.hasName,
        hasMessage: values?.hasMessage,
        message: values?.hasMessage ? values?.message : null,
        startDate: new Date(values?.startDate).toISOString(),
        recurrency: values?.recurrency,
        country: initialCountry
      };
      if (!newBlast?.recurrency) Reflect.deleteProperty(newBlast, 'recurrency');
      if (currentTenderos && currentTenderos?.length > 0) newBlast.referrers = currentTenderos;
      if (currentGroups && currentGroups?.length > 0) newBlast.groups = currentGroups;
      await crudAction(postBlasts.bind(null, newBlast), onClose,
        { ...crudOptions, name: values?.name, action: actions.CREATE }, t
      );
    } else { // UPDATE
      const currentTenderos = values?.referrers?.map((tendero) => tendero?.value);
      const currentGroups = values?.groups?.map((group) => group?.value);
      const deals = values?.deals?.map((deal) => (deal.id));
      const updatedBlast = {
        name: values?.name,
        type: values?.checkBlastType,
        url: values?.checkBlastType === "webhook" ? values?.url : null,
        deals: values?.checkBlastType === "deals" ? deals : null,
        limit: (!values.hasLimit) ? parseInt(values?.limit, 10) : null,
        hasPhone: values?.hasPhone,
        hasName: values?.hasName,
        hasMessage: values?.hasMessage,
        message: values?.hasMessage ? values?.message : null,
        startDate: new Date(values?.startDate).toISOString(),
        recurrency: values?.recurrency,
        country: initialCountry
      };
      if (!updatedBlast?.recurrency) Reflect.deleteProperty(updatedBlast, 'recurrency');
      if (currentTenderos && currentTenderos?.length > 0) updatedBlast.referrers = currentTenderos;
      if (currentGroups && currentGroups?.length > 0) updatedBlast.groups = currentGroups;
      await crudAction(updateBlast.bind(null, currentBlast?.id, updatedBlast), onClose,
        { ...crudOptions, action: actions.UPDATE }, t
      );
    }
  };

  const processDealsErrors = (deals, errors) => {
    if (!deals) return;
    const errorMessages = [];
    errors.forEach((error, index) => {
      if (error && error.expiresAt) {
        let name = '';
        if (deals[index] && deals[index].name) name = deals[index].name;
        errorMessages.push(t('dealExpired', { name }));
      }
    });

    return errorMessages.join('\n');
  };

  const defaultRenderAssetOptions = {
    url: [],
    type: "",
    imageHeight: 250,
    imageWidth: 250,
    imageClass: 'no-image-class'
  };

  const renderAsset = (options = {}) => {
    options = { ...defaultRenderAssetOptions, ...options };
    if (options.url && Array.isArray(options.url) && options.url.length > 0) {
      options.url = options.url[0].url;
      if (!options.type) options.type = getUrlExtension(options.url);
      if (!options.type) return;
      if (mimeTypes[options.type]) options.type = mimeTypes[options.type];
    }

    if (!options.url || !options.type || Array.isArray(options.url)) {
      options.url = `https://via.placeholder.com/${options.imageWidth}x${options.imageHeight}`;
      options.type = 'image';
    }

    if (getAssetType(options.type) === 'application')
      return <embed width="100%" height="100%" src={options.url} />;
    if (getAssetType(options.type) === 'video')
      return <video width="100%" src={options.url} controls>
        Your browser does not support the video tag.
      </video>;
    if (getAssetType(options.type) === 'image')
      return <img className={options.imageClass} src={options.url} />;
    return "";
  };

  // const deleteRecurrency = (setFieldValue) => {
  //   if(setFieldValue) setFieldValue("recurrency", null);
  // };

  // const BlastRecurrence = useCallback(({ onClose = () => { }, ...props }) => {
  //   const modeOptions = [
  //     {
  //       value: "day",
  //       label: t('Day')
  //     },
  //     {
  //       value: "week",
  //       label: t('Week')
  //     },
  //     {
  //       value: "month",
  //       label: t('Month')
  //     },
  //     {
  //       value: "year",
  //       label: t('Year')
  //     },
  //   ];

  //   const repeatAtByMonthOptions = [
  //     {
  //       value:`${t?.MonthlyOnDay} ${new Date(props.object?.values?.startDate).getDate()}` || null,
  //       label: `${t?.MonthlyOnDay} ${new Date(props.object?.values?.startDate).getDate()}`,
  //       objectData: {date: `${new Date(props.object?.values?.startDate).getDate()}`} || null
  //     },
  //     {
  //       value: `${t('MonthlyOnWeek', { week: weeknumber(new Date(props.object?.values?.startDate))}))} ${days(new Date(props.object?.values?.startDate).getDay())}` || null,
  //       label: `${t('MonthlyOnWeek', { week: weeknumber(new Date(props.object?.values?.startDate))}))} ${days(new Date(props.object?.values?.startDate).getDay())}`,
  //       objectData: {date: `${new Date(props.object?.values?.startDate).getDate()}`, week:`${weeknumber(new Date(props.object?.values?.startDate))}`} || null,
  //     },
  //   ];

  //   let currentRepeatAt = null;

  //   if(currentRepeatMode === "week" && currentRepeatAtArray?.length) {
  //     currentRepeatAt = currentRepeatAtArray;
  //   } else if(currentRepeatMode === "month" && currentRepeatAtObject?.objectData){
  //     currentRepeatAt = currentRepeatAtObject?.objectData;
  //   };

  //   const submitFormik = useCallback(() => {
  //     props.object?.setFieldValue("recurrency.repeatEach", !currentRepeatEach ? 1 : parseInt(currentRepeatEach, 10));
  //     props.object?.setFieldValue("recurrency.repeatMode", currentRepeatMode);
  //     props.object?.setFieldValue("recurrency.repeatAt", currentRepeatAt);
  //     props.object?.setFieldValue("recurrency.repeatEnd", checkRepeatEnd !== 'the' ? null : currentRepeatEndDate);
  //     props.object?.setFieldValue("recurrency.repeatAfter", checkRepeatEnd !== 'after' ? 0 : parseInt(currentRepeatAfter, 10));

  //     props.object?.setFieldValue("repeatAtArray", currentRepeatAtArray);
  //     props.object?.setFieldValue("repeatAtObject", currentRepeatAtObject);

  //     onClose();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [currentRepeatAfter, currentRepeatAt, currentRepeatEach, currentRepeatEndDate, currentRepeatMode, onClose, props.object]);

  //   const cancelFormik = useCallback(() => {
  //     setCurrentRepeatEach(props.object?.values?.recurrency?.repeatEach || currentBlast?.recurrency?.repeatEach || 1);
  //     setCurrentRepeatMode(props.object?.values?.recurrency?.repeatMode || currentBlast?.recurrency?.repeatMode || "day");
  //     setCurrentRepeatAtArray(props.object?.values?.repeatAtArray || currentBlast?.repeatAtArray|| []);
  //     setCurrentRepeatAtObject(props.object?.values?.repeatAtObject || currentBlast?.repeatAtObject || null);
  //     setCheckRepeatEnd(props.object?.values?.checkRepeatEnd || currentBlast?.checkRepeatEnd || "never");
  //     setCurrentRepeatEndDate(props.object?.values?.recurrency?.repeatEndDate || currentBlast?.recurrency?.repeatEndDate || null);
  //     setCurrentRepeatAfter(props.object?.values?.recurrency?.repeatAfter || currentBlast?.recurrency?.repeatAfter || null);
  //     onClose();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [onClose, props.object, currentBlast]);

  //   const handleChangeRepeatEach = (e) => {
  //     setCurrentRepeatEach(e.target.value);
  //   };
  //   const handleChangeRepeatAfter = (e) => {
  //     setCurrentRepeatAfter(e.target.value);
  //   };

  //   const defaultArrayDay = [new Date(props.object?.values?.startDate).getDay()];

  //   const arrayDay = currentRepeatAtArray?.length ? currentRepeatAtArray : defaultArrayDay;

  //   return(<div>
  //     <Grid container spacing={0} alignItems="flex-start" >
  //       <Grid item xs>
  //         <div className={blastStyles["blast-view"]}>
  //           <div className={themeStyles.currentContent}>
  //             <header className={themeStyles["header-form"]}>
  //               <h3>{t?.recurrency}</h3>
  //             </header>
  //             <div className={`${themeStyles["form-group"]} ${blastStyles["blast-recurrency__repeatEach"]}`}>
  //                 <label htmlFor="repeatEach" className={`${themeStyles["col-form-label"]} ${blastStyles["blast-recurrency__repeatEach__label"]}`}>{t?.repeatEach}</label>
  //                 <div>
  //                   <Field type="number" className={`${themeStyles["form-control"]}  ${blastStyles["blast-recurrency__repeatEach__quantity"]}`} name="recurrency.repeatEach" placeholder={placeholder?.repeatEach} value={currentRepeatEach || 1} onChange={handleChangeRepeatEach} onBlur={props.handleBlur} autoComplete="new-password"/>
  //                   {props.object?.errors.recurrency?.repeatEach && props.object?.touched.recurrency?.repeatEach ? (<div className={themeStyles.formError}>{props.object?.errors.recurrency?.repeatEach}</div>) : ' '}
  //                 </div>
  //                 <di>
  //                   <SearchSelect
  //                     className={`optionListContainer ${blastStyles["blast-recurrency__repeatEach__select"]}`}
  //                     onChange={mode => {
  //                       setCurrentRepeatMode(mode?.value);
  //                     }}
  //                     value={currentRepeatMode}
  //                     options={modeOptions}
  //                     placeholder={placeholder?.mode}
  //                     isOptionDisabled={(option) => option.disabled}
  //                     cacheOptions
  //                     />
  //                   {props.object?.errors.recurrency?.repeatMode && props.object?.touched.recurrency?.repeatMode ? (<div className={themeStyles.formError}>{props.object?.errors.recurrency?.repeatMode}</div>) : ' '}
  //                 </di>
  //             </div>
  //             {currentRepeatMode === "week" || currentRepeatMode === "month" ? <div className={themeStyles["form-wrap"]}>
  //               <label htmlFor="repeatAt" className={`${themeStyles["col-form-label"]} ${blastStyles["blast-recurrency__repeatMode__label"]}`}>{t?.repeatAt}</label>
  //               {currentRepeatMode === "week" && <div>
  //                 <ToggleDays setFunction={setCurrentRepeatAtArray} dayExternal={arrayDay}/>
  //               </div>}
  //               {currentRepeatMode === "month" && <div style={{margin: "16px 0"}}>
  //               <SearchSelect
  //                   className={`optionListContainer ${blastStyles["blast-recurrency__repeatEach__select"]}`}
  //                   onChange={repeatAtByMonth => {
  //                     setCurrentRepeatAtObject(repeatAtByMonth);
  //                   }}
  //                   value={currentRepeatAtObject?.value}
  //                   options={repeatAtByMonthOptions}
  //                   placeholder={placeholder?.mode}
  //                   isOptionDisabled={(option) => option.disabled}
  //                   cacheOptions
  //                 />
  //               </div>}
  //             </div> : ""}
  //             <div className={`${themeStyles["form-group"] }`}>
  //               <div style={{marginBottom:"10px"}}>
  //                 <label htmlFor='repeatEnd' className={themeStyles["col-form-label"]}>{t('RepeatEnd')}</label>
  //               </div>
  //               <div style={{display:"flex", flexDirection:"column"}}>
  //               <div className={`${themeStyles["form-group"]} ${blastStyles["blast-recurrency__repeatEndNever"]}`}>
  //               <label htmlFor='checkRepeatEnd'>
  //                 <input type="radio" name="checkRepeatEnd" value="never" checked={checkRepeatEnd === 'never'} disabled={false} onChange={
  //                   (e) => {
  //                     setCheckRepeatEnd(e.target.value);
  //                   }
  //                 } />
  //                 {` ${t?.never }`}
  //               </label>
  //             </div>
  //             <div className={`${themeStyles["form-group"]} ${blastStyles["blast-recurrency__repeatEndAt"]}`}>
  //               <label htmlFor='checkRepeatEnd' className={`${blastStyles["blast-recurrency__repeatEndAt__label"]}`}>
  //                 <input type="radio" name="checkRepeatEnd" value="the" checked={checkRepeatEnd=== 'the'} disabled={false} onChange={
  //                   (e) => {
  //                     setCheckRepeatEnd(e.target.value);
  //                   }
  //                 } />
  //                 {` ${t?.the }`}
  //               </label>
  //             <div className={`${blastStyles["blast-recurrency__repeatEndAt__date"]}`}>
  //               <DatePickerField popperProps={{
  //                 positionFixed: true,
  //                 strategy: 'fixed'
  //               }}
  //               name="recurrency.repeatEndDate" dateFormat="yyyy-MM-dd"
  //               value={currentRepeatEndDate}
  //               valueExternal={currentRepeatEndDate}
  //               autoComplete="off"
  //               setFunction={setCurrentRepeatEndDate}
  //               disabled={checkRepeatEnd !== 'the'}
  //               tabIndex={1} />
  //             </div>
  //             </div>
  //             <div className={`${themeStyles["form-group"]} ${blastStyles["blast-recurrency__repeatAfter"]}`}>
  //               <label htmlFor='checkRepeatEnd'>
  //                 <input type="radio" name="checkRepeatEnd" value="after" checked={checkRepeatEnd === 'after'} disabled={false} onChange={
  //                   (e) => {
  //                     setCheckRepeatEnd(e.target.value);
  //                   }
  //                 } />
  //                 {` ${t('After') }`}
  //               </label>
  //               <Field type="number" className={`${themeStyles["form-control"]}  ${blastStyles["blast-recurrency__repeatAfter__quantity"]}`} name="recurrency.repeatAfter" placeholder={placeholder?.repeatAfter} value={currentRepeatAfter || 0} onChange={handleChangeRepeatAfter} onBlur={props.handleBlur} autoComplete="new-password" disabled={checkRepeatEnd !== 'after'}/>
  //               <span>{t('Recurrencies')}</span>
  //             </div>
  //             </div>
  //           </div>
  //           </div>
  //           <div className={themeStyles["form-group"]}>
  //             <div className={`${blastStyles["blast-btn-container"]}`}>
  //               <button onClick={cancelFormik} className={`${themeStyles.btn} ${themeStyles["btn-cancel"]}`}>{t?.cancel}</button>
  //               <button onClick={submitFormik} type="submit" className={`${themeStyles.btn } ${ themeStyles["btn-secondary"]}`}>{ t?.done }</button>
  //             </div>
  //           </div>
  //         </div>
  //       </Grid>
  //     </Grid>
  //   </div>);},[t, currentRepeatMode, currentRepeatAtArray, currentRepeatAtObject, currentRepeatAfter, currentRepeatEach, currentRepeatEndDate, currentBlast, placeholder?.repeatEach, placeholder?.mode, placeholder?.repeatAfter, checkRepeatEnd]);

  return (
    (
      loaderEnabled ? <div style={{ display: 'flex', justifyContent: 'center', padding: '200px' }}>
        <CircularProgress />
      </div>
        :
        <div>
          <ModalCloseButton onClick={() => onClose(true)} floating />
          <Grid container spacing={0} alignItems="flex-start" style={{ marginTop: "-30px" }}>
            <Grid item xs>
              <div className={blastStyles["blast-view"]}>
                <div className={themeStyles.currentContent}>
                  <header className={themeStyles["header-form"]}>
                    <h3>{!currentBlast ? t('Create Blast') : currentBlast.name}</h3>
                  </header>
                  {!currentBlast ? (
                    <div />
                  ) : (
                    <div>
                      <div className={themeStyles["date-created"]}>
                          <b>{t('Created at')}: &nbsp;</b> {` ${ new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentBlast.created_at))}`}
                      </div>
                      <div className={themeStyles["date-updated"]}>
                          <b>{t('Updated at')}: &nbsp;</b> {` ${ new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentBlast.updated_at))}`}
                      </div>
                    </div>
                  )}
                  <div className={themeStyles["form-wrap"]} style={{width: "95%"}}>
                    <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formOnSubmit} validationSchema={BlastSchema} autoComplete="off" >
                      {({ isSubmitting, values, handleChange, handleBlur, setFieldValue, setFieldTouched, errors, touched }) => (
                        <Form>
                            <div className={themeStyles["form-group"]}>
                                <div>
                              <label htmlFor='blastType' className={themeStyles["col-form-label"]}>{t('BlastType')}</label>
                                </div>
                                <div style={{marginTop:"2px", flexGrow: 2}}>
                                  <label htmlFor='checkBlastType' style={{marginRight:"15px"}}>
                                    <input type="radio" name="checkBlastType" value="webhook" checked={values?.checkBlastType === "webhook"} onChange={
                                    (e) => {
                                      setFieldValue('checkBlastType', e.target.value);
                                    }
                                  }/>
                                    <span>{` ${t("Webhook")}`}</span>
                                  </label>
                                  {/* <label htmlFor='checkBlastType' style={{marginRight:"15px"}}>
                                    <input type="radio" name="checkBlastType" value="deals" checked={values?.checkBlastType === "deals"} onChange={
                                    (e) => {
                                      setFieldValue('checkBlastType', e.target.value);
                                    }
                                  }/>
                                    <span>{` ${t('Deals')}`}</span>
                                  </label> */}
                                </div>
                            </div>
                          <div className={themeStyles["form-group"]} >
                            <label htmlFor="name" className={themeStyles["col-form-label"]}>{t('Name')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                            <Field className={themeStyles["form-control"]} name="name" placeholder={placeholder.name} value={values?.name} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" /* disabled={currentBlast} */ />
                            {errors.name && touched.name ? (<div className={themeStyles.formError}>{errors.name}</div>) : ' '}
                          </div>
                          <div className={themeStyles["form-group"]}>
                            <label htmlFor="url" className={themeStyles["col-form-label"]}>{values?.checkBlastType === "webhook" ? "URL webhook" : t('Deals')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                            {values?.checkBlastType === "webhook" ? <Field className={themeStyles["form-control"]} name="url" placeholder={placeholder.url} value={values?.url} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" /* disabled={currentBlast} */ /> :
                             <SearchSelect
                              className='optionListContainer'
                              onChange={array => {
                                setFieldValue('deals', array);
                                setSelectedDeals(array);
                              }}
                              value={values?.deals}
                              onBlur={() => setFieldTouched('deals', true)}
                              loadOptions={returnDeals}
                              placeholder={placeholder.deals}
                              isOptionDisabled={(option) => option.disabled}
                              disabled={currentBlast}
                              isMulti
                              cacheOptions
                            />}
                            {errors.deals && touched.deals && values?.checkBlastType !== "webhook" ? (<div className={themeStyles.formError}>{
                              Array.isArray(errors.deals) ? processDealsErrors(values?.deals, errors.deals) : errors.deals
                            }</div>) : ' '}
                            {errors.url && touched.url && values?.checkBlastType === "webhook" ? (<div className={themeStyles.formError}>{errors.url}</div>) : ' '}
                          </div>
                          {values?.checkBlastType === "webhook" ?
                            <div className={themeStyles["form-group"]}>
                                <div style={{marginBottom: "10px"}}>
                                <label htmlFor='parameters' className={themeStyles["col-form-label"]}>{t('Parameters')}</label>
                                </div>
                                <div style={{display:"flex", flexDirection:"column"}}>
                                <div>
                                <label htmlFor='hasPhone'>
                                  <input type="checkbox" name="hasPhone" checked={values?.hasPhone || true} disabled onChange={
                                    (e) => {
                                      setFieldValue('hasPhone', e.target.checked);
                                    }
                                  } />
                                    {` ${t('Cell phone number')}`}
                                </label>
                              </div>
                              <div>
                                <label htmlFor='hasName'>
                                  <input type="checkbox" name="hasName" checked={values?.hasName || false} disabled={false} onChange={
                                    (e) => {
                                      setFieldValue('hasName', e.target.checked);
                                    }
                                  } />
                                  {` ${t('Name')}`}
                                </label>
                              </div>
                              <div>
                                <label htmlFor='hasMessage'>
                                  <input type="checkbox" name="hasMessage" checked={values?.hasMessage || false} disabled={false} onChange={
                                    (e) => {
                                      setFieldValue('hasMessage', e.target.checked);
                                    }
                                  } />
                                    {` ${t('Message')}`}
                                </label>
                              </div>
                              {values?.hasMessage && <div style={{marginTop:"10px"}}>
                                <Field id="message" as="textarea" className={themeStyles["form-control"]} name="message" placeholder={placeholder.writeMessage} rows="6" autoComplete="new-password" />
                                {errors.message && touched.message ? (<div className={themeStyles.formError}>{errors.message}</div>) : ' '}
                              </div>}
                                </div>
                            </div>
                          :""}
                          <div className={`${themeStyles["form-group"] } form-blast ${blastStyles["limit-blast"] }`}>
                            <label htmlFor="limit" className={themeStyles["col-form-label"]}>{t('Limit')}</label>
                            <div>
                              <Field type="number" className={`${themeStyles["form-control"]}  ${blastStyles["blast-recurrency__repeatEach__quantity"]}`} name="limit" placeholder={placeholder?.limit} value={values.hasLimit ? 0 : values?.limit} onChange={handleChange} onBlur={props.handleBlur} autoComplete="new-password" disabled={values.hasLimit}/>
                              {errors.limit && touched.limit ? (<div className={themeStyles.formError}>{errors.limit}</div>) : ' '}
                            </div>
                            <div>
                                <label htmlFor='hasLimit'>
                                  <input type="checkbox" name="hasLimit" checked={values.hasLimit || false} disabled={false} onChange={
                                    (e) => {
                                      setFieldValue('hasLimit', e.target.checked);
                                      setFieldValue('limit', values.limit);
                                    }
                                  } />
                                  {` ${ t('No Limit')}`}
                                </label>
                              </div>
                          </div>
                          <div className={`${themeStyles["form-group"] } form-blast`}>
                            <label htmlFor="groups" className={themeStyles["col-form-label"]}>{t('Group', { count: 100 })}</label>
                            <SearchSelect
                              isMulti
                              className='optionListContainer'
                              onChange={field => {
                                if (!field) {
                                  setFieldValue('groups', []);
                                  setSelectedGroupsList([]);
                                } else {
                                  setFieldValue('groups', field);
                                  setSelectedGroupsList(field.map(option => option.value));
                                  setSelectedGroupsListCount(field.map(option => option.count));
                                }
                              }}
                              value={values?.groups}
                              onBlur={() => setFieldTouched('groups', true)}
                              loadOptions={returnGroups}
                              placeholder={placeholder.groups}
                              // disabled={currentBlast}
                              cacheOptions
                            />
                            {errors.groups && touched.groups ? (<div className={themeStyles.formError}>{errors.groups}</div>) : ' '}
                          </div>
                          <div className={`${themeStyles["form-group"] } form-blast`}>
                            <label htmlFor="referrers" className={themeStyles["col-form-label"]}>{t('Referrer', { count: 100 })}</label>
                            <SearchSelect
                              isMulti
                              className='optionListContainer'
                              onChange={field => {
                                if (!field) {
                                  setFieldValue('referrers', []);
                                  setSelectedTenderosList([]);
                                } else {
                                  setFieldValue('referrers', field);
                                  setSelectedTenderosList(field.map(option => option.value));
                                }
                              }}
                              value={values?.referrers}
                              onBlur={() => setFieldTouched('referrers', true)}
                              loadOptions={returnTenderos}
                              placeholder={placeholder.referrers}
                              // disabled={currentBlast}
                              cacheOptions
                            />
                            {errors.referrers && touched.referrers ? (<div className={themeStyles.formError}>{errors.referrers}</div>) : ' '}
                          </div>
                          <Grid container spacing={1} className={themeStyles["form-group"]}>
                          <Grid item xs={6}>
                              <label htmlFor="startAt" className={themeStyles["col-form-label"]}>{t('Date')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                          </Grid>
                          <Grid item xs={6}>
                              <label htmlFor="hour" className={themeStyles["col-form-label"]}>{t('Hour')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                          </Grid>
                          <Grid container spacing={1} item xs={12} className={blastStyles["time-inputs"]}>
                            <Grid item xs={6}>
                                <DatePickerFieldMui popperProps={{
                                  positionFixed: true,
                                  strategy: 'fixed'
                                }}
                                  style={{ padding: "2px" }}
                                  name="startDate" inputFormat="yyyy-MM-dd"
                                  value={values?.startDate}
                                  autoComplete="off"
                                  timeZone={countryTimeZone?.[0]}
                                  typePickers={["MobileDatePicker"]}
                                  item xs={6}
                                  
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <DatePickerFieldMui popperProps={{
                                positionFixed: true,
                                strategy: 'fixed'
                              }}
                              style={{padding:"2px"}}
                              name="startDate"
                              value={values?.startDate}
                              showTimeSelect
                              autoComplete="off"
                              timeZone={countryTimeZone?.[0]}
                              showTime={{ user12hours: true }}
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              typePickers={["MobileTimePicker"]}
                              item xs={6}
                              />
                              {errors.startDate && touched.startDate ? (<div className={themeStyles.formError}>{errors.startDate}</div>) : ' '}
                            </Grid>
                          </Grid>
                          </Grid>
                          {/* {!values?.recurrency ? <div style={{display:"flex", justifyContent:"flex-end", marginTop:"5px"}}>
                            {values?.startDate && <BasicModal content={BlastRecurrence} modalSize="xs" options={{ html: <span style={{borderBottom: "solid 1px #000", cursor:"pointer"}} disabled={!values?.startDate}>{t('Add recurrence')}</span> }} object={{values, errors, touched, handleBlur, setFieldValue, setFieldTouched}} t={t} onClose={()=>{}} />}
                            {!values?.startDate ? (<div className={themeStyles.formError}>{props?.t("Must be a date")}</div>) : ' '}
                          </div> : ""}
                          {values?.recurrency ? <div style={{display:"flex", justifyContent:"space-between", marginTop:"5px"}}>
                            <div>
                              <span>{`${values?.recurrency?.repeatMode ? t(`${capitalize(values?.recurrency?.repeatMode)}ly`) : ""} ${values?.recurrency?.repeatMode === "week" || values?.recurrency?.repeatMode === "month" ? t('The') : ""} ${!values?.recurrency?.repeatAt?.length && values?.recurrency?.repeatMode === "month" ? new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(values?.startDate)) : ""} ${values?.recurrency?.repeatAt?.length && values?.recurrency?.repeatMode === "week" ? repeatDays(values?.recurrency?.repeatAt) : ""} ${!values?.recurrency?.repeatEnd && !values?.recurrency?.repeatAfter ? "" : t('Until')} ${values?.recurrency?.repeatEnd ? new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(values?.recurrency?.repeatEnd)): ""} ${values?.recurrency?.repeatAfter ? `${values?.recurrency?.repeatAfter} ${values?.recurrency?.repeatAfter > 1 ? props?.t("Recurrencies") : props?.t("Recurrence")}` : ""}`}</span>
                            </div>
                            <div style={{display:"flex", gap:"5px", alignItems: "center"}}>
                            <BasicModal content={BlastRecurrence} modalSize="xs" options={{ html: <span style={{cursor:"pointer"}}><Icon color="#CCC" size={16} icon="edit_cover-" /></span> }} object={{values, errors, touched, handleBlur, setFieldValue, setFieldTouched}} t={t} onClose={()=>{}} />
                            <span style={{cursor:"pointer"}} onClick={() => deleteRecurrency(setFieldValue)}><Icon color="#CCC" size={16} icon="delete-" /></span>
                            </div>
                          </div> : ""} */}
                          {!selectedDeals || selectedDeals.length === 0 || values?.checkBlastType === "webhook" ? (
                            // <div className={`${themeStyles["form-group"] } ${ blastStyles["blast-deals-empty"]}`}>
                            //   <br />
                            //   <br />
                            //   <br />
                            //   <br />
                            // </div>
                            ""
                          ) : (
                            <div className={`${themeStyles["form-group"] } ${ blastStyles["blast-deals"]}`}>
                                <label htmlFor="preview" className={themeStyles["col-form-label"]}>{t('Preview')}</label>
                              <br />

                              {selectedDeals.map(deal => {
                                const parsedMedia = (typeof deal.media === 'string') ? JSON.parse(deal.media) : deal.media;
                                return (
                                  <Grid key={deal.id} container className={themeStyles["form-group"]} spacing={1}>
                                    <Grid item xs={2} className={themeStyles["center-text"]}>
                                      {renderAsset({
                                        url: parsedMedia,
                                        imageClass: themeStyles["deal-image"]
                                      })}
                                    </Grid>
                                    <Grid item xs={10}>
                                      {t('newDealMessage', { name: deal.name, details: deal.details || '' })}
                                      <br />
                                      {`${deal.name}:\n${deal.details}\n`}
                                      <br />
                                      {deal.discountPrice ?
                                        <span>{t('Price')}: {deal.discountPrice}
                                          <br />
                                          {t('Discount')}: {deal.discount}</span>
                                        :
                                        `${t('Price')}: ${deal.price}`}
                                    </Grid>
                                  </Grid>
                                );
                              })

                              }
                            </div>
                          )}
                          {userCount ? <div className={blastStyles?.usersCount}>
                            <span>{`${t('Blasts addressed to')} `}<b>{`${userCount} ${t('Users', { count: userCount })}`}</b></span>
                          </div> : ""}
                          {/* {currentBlast ? ("") : ( */}
                          <div className={themeStyles["form-group"]}>
                            <Grid container item spacing={1} xs={8} justifyContent="space-between" alignItems="center">
                              <Grid item xs>
                                <NavLink to="/deals">
                                  <button className={`${themeStyles.btn} ${themeStyles["btn-cancel"]}`} disabled={isSubmitting}>{t('Cancel')}</button>
                                </NavLink>
                              </Grid>
                          <Grid item xs style={{ textAlign: "end" }}>
                              <button type="submit" className={`${themeStyles.btn } ${ themeStyles["btn-secondary"]}`} disabled={isSubmitting} onClick={() => {
                                  // eslint-disable-next-line no-console
                                  console.log({ errors });
                                setFieldValue('operationType', submitType.update);
                              }}>{
                                    isSubmitting ? `${t('Please Wait')}...` : ((!currentBlast || props?.clone === true) ? t('Create') : t('Update') )
                                }</button>
                          </Grid>
                          </Grid>
                          </div>
                          {/* )} */}
                          <FormikErrorFocus offset={0} align="top" duration={1000} />
                        </Form>
                      )}
                    </Formik>

                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>)
  );
};

export default withTranslation()(BlastCreate);