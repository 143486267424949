/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormikErrorFocus from 'formik-error-focus';
import { withTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { actions, capitalize, crudAction, cleanObject, modules, formatTimestamp } from '../../../utils';
import orderStyles from './styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import { exportOrders } from "../../services/orders";
import { isEmpty } from 'lodash';
import moment from 'moment';
import ModalCloseButton from '../../../core/ModalCloseButton';

const OrdersExport = ({ onClose = () => { }, ...props }) => {

    const [groupDeals, setGroupDeals] = useState(false);
  const [filters] = useState(cleanObject({ ...props.filters }, ['page', 'limit', 'refresh', 'sort', 'priceMax', 'priceMin']));

  const module = modules(props.t).order;

  const t = {
    exportOrders: props.t('exportOrders'),
    emails: props.t('Email Addresses'),
    required: props.t('Required'),
    export: props.t('Export'),
    exporting: props.t('Exporting'),
    mailsErrors: props.t('One or more email is not valid'),
    filters: props.t('filters'),
    filtersEmpty: props.t('filtersEmpty'),
    ID: props.t('ID'),
    'Deal Name': props.t('Deal Name'),
    'Deal Quantity': props.t('Deal Quantity'),
    'Precio de Venta': props.t('Precio de Venta'),
    'Precio WAO': props.t('Precio WAO'),
    'Customer ID': props.t('Customer ID'),
    'Customer Name': props.t('Customer Name'),
    'Customer Document': props.t('Customer Document'),
    'Customer Phone': props.t('Customer Phone'),
    'Customer Email': props.t('Customer Email'),
    'Address': props.t('Address'),
    'Address 2': props.t('Address 2'),
    'City': props.t('Address City'),
    'Region': props.t('Region'),
    'Country': props.t('Country'),
    'Zip Code': props.t('Zip Code'),
    'Description': props.t('Address Description'),
    'Latitude': props.t('Latitude'),
    'Longitude': props.t('Longitude'),
    'Tendero Name': props.t('Tendero Name'),
    'Tendero Document': props.t('Tendero Document'),
    'Tendero ID': props.t('Tendero ID'),
    'Tendero Phone': props.t('Tendero Phone'),
    'Tendero Email': props.t('Tendero Email'),
    'Total': props.t('Total'),
    'Commission Status': props.t('Commission Status'),
    'Total Commission': props.t('Total Commission'),
    'Transaction Status': props.t('Transaction Status'),
    'Total Transaction': props.t('Total Transaction'),
    'Order Status': props.t('Order Status'),
    'Created at': props.t('Created at'),
    'Expected Delivery Date': props.t('Expected Delivery Date'),
    'Notes': props.t('Notes'),
    'Sku': props.t('Sku'),
    'Payment Type': props.t('Payment Type'),
    groupDeals: props.t('Group by Deal')
  };

  const tag = {
    q: props.t('Search'),
    country: props.t('Country'),
    startDate: props.t('Status Date from'),
    endDate: props.t('Status Date to'),
    dateFrom: props.t('Created From'),
    dateTo: props.t('Created To'),
    status: props.t('Status'),
    commissionStatus: props.t('Commission Status'),
    transactionStatus: props.t('Payment Status'),
    expectedDeliveryDate: props.t('Expected Delivery Date'),
    listAbandoned: props.t('listAbandoned')
  };

  

  const crudOptions = {
    module,
    name: '',
  };

  function validateMails(msg) {
    return Yup.mixed().test({
      name: 'validateMails',
      exclusive: false,
      message: msg || t.mailsErrors,
      test (value) {
        const validator = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g;
        return validator.test(value);
      },
    });
  }
  Yup.addMethod(Yup.string, 'validateMails', validateMails);

  // Form placeholders
  const placeholder = {
    emails: props.t('doe@wao.shop, doe2@wao.shop')
  };

  // Formik initial/default values
  const formInitialValues = {
    emails: ""
  };

  // Formik validation
  const ExportSchema = Yup.object().shape({
    emails: Yup.string().required(t.required).validateMails()
  });

  /**
   * Function called whenever the Formik form is submitted
   * @async
   * @param {Object} values Form values
   * @param {Object} options Functions provided by the Formik form
   */
  const formOnSubmit = async (values) => {
    if (filters.country && filters.country === 'ALL') {
      Reflect.deleteProperty(filters, 'country');
    }
    const body = {
      groupDeals,
      emails: values.emails.replace(/\s+/g, '').split(','),
      filters,
      timeZone: moment().utcOffset()
    };
    await crudAction(exportOrders.bind(null, body), onClose,
      { ...crudOptions, action: actions.EXPORT }, props.t
    );
  };

  return (
    <div>
      <ModalCloseButton onClick={() => onClose(true)} floating />
      <Grid container spacing={0} alignItems="flex-start" >
        <Grid item xs>
          <div className={themeStyles.currentContent}>
            <div className={orderStyles["order-view"]}>
              <header className={themeStyles["header-form"]}>
                <h3>{t.exportOrders}</h3>
              </header>
              <div className={themeStyles["form-wrap"]}>
                <div className={themeStyles["form-group"]} >
                  <label htmlFor="filters" className={themeStyles["col-form-label"]}>{t.filters}</label>
                  {isEmpty(filters) ?
                    <p>
                      <label htmlFor="filtersEmpty" className={themeStyles["col-form-label"]}>{t.filtersEmpty}</label>
                    </p>
                    :
                    Object.keys(filters).map((key, i) => {
                      if (key === 'entities') {
                        return Object.keys(filters[key]).map((entity, j) => <p key={j}>
                            <span><b>{tag[entity] ? tag[entity] : props.t(capitalize(entity))}: </b>
                              {filters[key][entity].map((entityValue) => (entityValue.label)).join(` ${ props.t('or') } `)}
                            </span>
                          </p>);
                      }
                        let value;
                        if (props.filters[key] instanceof Date) value = formatTimestamp(props.filters[key], false, true);
                        else value = props.t(capitalize(filters[key]));
                        return <p key={i}>
                          <span><b>{tag[key] ? tag[key] : props.t(key)}:</b> {value}</span>
                        </p>;
                      
                    })
                  }
                </div>
                <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formOnSubmit} validationSchema={ExportSchema} autoComplete="off" >
                  {({ isSubmitting, values, handleChange, handleBlur, errors, touched }) => (
                    <Form>
                      <div className={themeStyles["form-group"]} >
                        <label htmlFor="emails" className={themeStyles["col-form-label"]}>{t.emails}<span className={themeStyles["label-asterisk"]}>*</span></label>
                        <Field className={themeStyles["form-control"]} name="emails" placeholder={placeholder.emails} value={values.emaisl} onChange={handleChange} onBlur={handleBlur} />
                        {errors.emails && touched.emails ? (<div className={themeStyles.formError}>{errors.emails}</div>) : ' '}
                      </div>
                      <div className={themeStyles["form-group"]} >
                        <label htmlFor="emails" className={themeStyles["col-form-label"]}>{t.groupDeals}</label>
                        <input type="checkbox" name="groupDeal" checked={groupDeals} onChange={
                          (e) => { setGroupDeals(e.target.checked); }
                        } />
                      </div>

                      <div className={themeStyles["form-crud-actions"]}>
                        <button type="submit" className={`${themeStyles.btn } ${ themeStyles["btn-primary"]}`} disabled={isSubmitting}>{
                          isSubmitting ? `${t.exporting}...` : t.export
                        }</button>
                      </div>
                      <FormikErrorFocus offset={0} align="top" duration={1000} />
                    </Form>
                  )}
                </Formik>

              </div>
            </div>
          </div>
        </Grid>
      </Grid>


    </div>
  );
};

export default withTranslation()(OrdersExport);
