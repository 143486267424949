import { combineReducers } from 'redux';

export default combineReducers({
  auth: require('./auth/reducer').reducer,
  blast: require('../components/shared-comp/blast/model/reducer').reducer,
  deals: require('../components/shared-comp/deals/model/reducer').reducer,
  orders: require('../components/shared-comp/orders/model/reducer').reducer,
  users: require('../components/shared-comp/users/model/reducer').reducer,
  referrers: require('../components/shared-comp/referrers/model/reducer').reducer,
  customers: require('../components/shared-comp/customers/model/reducer').reducer,
  groups: require('../components/shared-comp/groups/model/reducer').reducer,
  commissions: require('../components/shared-comp/commissions/model/reducer').reducer,
  payouts: require('../components/shared-comp/payouts/model/reducer').reducer,
  promotions: require('../components/shared-comp/promotions/model/reducer').reducer,
  recomendations: require('../components/shared-comp/recomendations/model/reducer').reducer,
  workflow: require('./workflow/reducer').reducer,
  categories: require('./categories/reducer').reducer,
  products: require('./products/reducer').reducer,
  serviceWorker: require('./serviceWorker/reducer').reducer,
  modals: require('./modals/reducer').reducer,
  notifications: require('./notifications/reducer').reducer,
});
