/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable import/no-named-as-default */
import React, { useState, useEffect, Fragment, useCallback, useMemo } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { CircularProgress, Collapse, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import * as Yup from 'yup';
import FormikErrorFocus from 'formik-error-focus';
import { withTranslation } from 'react-i18next';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Icon from "../../../core/Icon";
import UploadFile from '../../../core/UploadFile';
import { formatToDate, actions, callAssignToast, crudAction, deleteDuplicates, /* formatDateTimeSimple, */ formCol, getAssetType, getFileExtension, getUrlExtension, makeId, mimeTypes, modules, toFloat, getPercentageFromPrices, getPriceFromDiscountAndPercentage, getDatei18n } from '../../../utils';
import dealStyles from './styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import orderStyles from '../../../orders/views/forms/styles.module.sass';
import SearchSelect from '../../../core/SearchSelect';
import DatePickerField from '../../../core/DatePickerField';
import { upload } from '../../../core/services/upload';
import { postDeals, updateDeal, /* deleteDeal, */ getZohoDeals, getDealsCategories } from '../../services/deals';
import { getCountry, getCurrencies, getCurrencyByCountry } from '../../../core/services/workflow';
import { columnTypes } from '../../../core/TableCommon';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import log from '../../../../../utils/log';
import { useRequest } from '../../../../../utils/hooks';
import { toast } from 'react-toastify';

// TODO Remove this if you have restored enforcing Zoho deals
const disableZoho = true;

// let showErrorsTimer;
// const showErrorsSecs = 5;
const defaultStartingCommission = parseFloat(20);
const urlRegExp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;
const dateFormat = {
  year: 'numeric', month: 'short', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  hour12: false,
  timeZoneName: 'short'
};

// const defaultUploadAccept = [
//   'image/webp', 'image/jpeg', 'image/gif', 'image/png', 'image/bmp', '.pdf', 'video/mp4', 'video/mpeg', 'video/quicktime', 'video/3gpp', 'video/3gpp2', 'video/webm'
// ];
const defaultUploadAccept = {
  'image/*': ['.png', '.jpeg', '.gif', '.jpg'],
  'video/*': ['.mp4', '.mov'],
  'application/pdf': ['.pdf'],
};
const today = new Date();
const tomorrow = new Date(today.getTime() + (1 * 24 * 60 * 60 * 1000));
const tomorrowPlusFive = new Date(tomorrow.getTime() + (5 * 24 * 60 * 60 * 1000));
const maximumFileSizeText = "5MB";
let mediaArray = [];

// Form type, used to differentiate between update and delete
const submitType = {
  update: 1,
  delete: 2
};

const DealDetails = ({ onClose = () => { }, t, i18n }) => {
  // const [showErrors, setShowErrors] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { object, clone } = (location.state || {});
  const headerCountry = getCountry();
  const [quantityCheck, setQuantityCheck] = useState(true);
  const [isBundle, setIsBundle] = useState(false);
  const [isOversized, setIsOversized] = useState(false);
  const [grantPoints, setGrantPoints] = useState(false);
  const [dealClone, setDealClone] = useState(null);
  const [currentDeal, setCurrentDeal] = useState(null);
  const [zohoDeals, setZohoDeals] = useState([]);
  const { loading: zohoDealsLoading, run: runGetZohoDeals } = useRequest(getZohoDeals.bind(null, headerCountry));
  const [currencyList, setCurrencyList] = useState([]);
  const [emojiSectionExpanded, setEmojiSectionExpanded] = useState(false);
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [isZohoDeal, setIsZohoDeal] = useState(false);
  const [dealsCategories, setDealsCategories] = useState([]);
  const [/* dealCategory */, setDealCategory] = useState(null);
  const [loaderEnabled, setLoaderEnabled] = useState(true);
  const [tableColumns, setTableColumns] = useState([]);
  const [useImage, setUseImage] = useState('');
  const [editingDeal, setEditingDeal] = useState(false);
  const [cloningDeal, setCloningDeal] = useState(false);
  const [showPaymentMethodsCheck, setShowPaymentMethodsCheck] = useState(false);
    const module = modules(t).deal;
  
  const initialCountry = currentDeal?.country || headerCountry || "";
  const initialCurrency = currentDeal?.currency || getCurrencyByCountry(initialCountry) || "";
  const datei18n = getDatei18n(initialCountry);

  const toggleEmojiSection = () => {
    setEmojiSectionExpanded(!emojiSectionExpanded);
  };

  // const ErrorReporting = () => {
  //   // Grab values and submitForm from context
  //   const { isValid, submitCount } = useFormikContext();
  //   useEffect(() => {
  //     if (submitCount > 0 && isValid) {
  //       // onSubmissionError(errors);
  //       setShowErrors(true);
  //     }
  //   }, [isValid, submitCount]);
  //   return null;
  // };

  function linkOrFile(msg) {
    return Yup.mixed().test({
      name: 'linkOrFile',
      exclusive: false,
      message: msg || t('urlOrFile'),
      test: function test() {
        const { file, media } = this.parent;
        if (!file) return new RegExp(urlRegExp).test(media);
        if (!media) return !!file;
        return true;
      }
    });
  }
  Yup.addMethod(Yup.string, 'linkOrFile', linkOrFile);

  const productAndMarketingMedia = (errorMessage) => Yup.array().test({
      name: 'productAndMarketingMedia',
      exclusive: false,
      message: errorMessage || t('mediaTypeRequired'),
      test: function test() {
        const { media } = this.parent;
        if (!media) return false;

        let hasProduct;
        let hasMarketing = false;
        media.forEach(mediaObject => {
          if (mediaObject?.type === 'product') hasProduct = true;
          if (mediaObject?.type === 'marketing') hasMarketing = true;
        });
        return (hasProduct && hasMarketing);
      }
    });
    
  Yup.addMethod(Yup.array, "productAndMarketingMedia", productAndMarketingMedia);

  const zohoDealSchemaDefinition = !disableZoho ? Yup.boolean().when('currentForm', {
    is: currentForm => currentForm !== 'editing',
    then: Yup.boolean().oneOf([true], t('Required'))
  }) : Yup.boolean();


  const DealSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')),
    isZohoDeal: zohoDealSchemaDefinition,
    media: Yup.array().productAndMarketingMedia().min(2, t('Required')).required(t('Required')),
    dealCategory: Yup.string().nullable().required(t('Required')),
    shortDesc: Yup.string().max(200, t('Maximum N characters', { char: 200 })),
    details: Yup.string().required(t('Required')),
    deals: Yup.array().of(
      Yup.object().shape({
        currency: Yup.string().required(t('Required')),
        discountPrice: Yup.number().min(0, t('Must use a positive value')).required(t('Required')),
        country: Yup.string().required(t('Required')),
        sku: Yup.string().required(t('Required')),
        price: Yup.number().min(Yup.ref('discountPrice'), t('Final price must be greater than base price')).required(t('Required')),
        commissionPercentage: Yup.number().min(0, t('Must use a positive value')).max(100, t('Commission percentage limit')).required(t('Required')),
        marketPrice: Yup.number().nullable().transform((value) => (Number.isNaN(value) ? undefined : value)).when({
          is: value => typeof value === 'number' && value > 0,
          then: Yup.number().moreThan(Yup.ref('price'), t('Market price must be greater than Price'))
        }),
        zohoQuantity: Yup.number().integer().nullable(),
        quantity: Yup.number().nullable().min(0, t('Must use a positive value')).integer(),
        quantityBundle: Yup.number().nullable().min(0, t('Must use a positive value')).integer(),
        installmentPayment: Yup.number().nullable(),
        interests: Yup.boolean().nullable(),
        pointsLimit: Yup.number().nullable().min(1, t('Must use a positive value')).max(2000, t('Points quantity limit')).integer(),
        redeemPoints: Yup.boolean().nullable(),
        zohoId: Yup.string().nullable(),
        status: Yup.string().required(t('Required'))
      })
    ),
    startAt: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
    expiresAt: Yup.date(t('Required')).typeError(t('Required')).min(Yup.ref('startAt'), t('dealExpireGreater')).required(t('Required')),
    zohoId: Yup.string().nullable(),
  });

  const crudOptions = useMemo(()=>({
    module,
    name: currentDeal?.name || '',
  }),[currentDeal?.name, module]);

  // Formik initial/default values
  const formInitialValues = useMemo(()=>({
    name: currentDeal?.name || dealClone?.name || "",
    isZohoDeal: isZohoDeal || false,
    dealCategory: currentDeal?.deals[0]?.category || dealClone?.deals[0]?.category || null,
    media: currentDeal?.media || dealClone?.media || [],
    deals: currentDeal?.deals || dealClone?.deals || [{
      currency: initialCurrency,
      discountPrice: "",
      country: initialCountry,
      sku: "",
      price: "",
      marketPrice: "",
      zohoQuantity: 0,
      quantity: 0,
      quantityBundle: 0,
      zohoId: "",
      commissionPercentage: defaultStartingCommission,
      zohoBasePrice: "",
      status: 'active',
      installmentPayment: "",
      interests: false,
      pointsLimit: 0,
      redeemPoints: false,
      attributes: []
    }],
    hasNoPointLimit: currentDeal?.hasNoPointLimit || dealClone?.hasNoPointLimit || false,
    checkInterestsInstallment: currentDeal?.checkInterestsInstallment || dealClone?.checkInterestsInstallment || false,
    checkInstallmentPayment: currentDeal?.checkInstallmentPayment || dealClone?.checkInstallmentPayment || "3",
    checkRedeemPoints: currentDeal?.checkRedeemPoints || dealClone?.checkRedeemPoints || false,
    startAt: currentDeal?.deals?.[0]?.startAt || dealClone?.startAt || tomorrow,
    expiresAt: currentDeal?.deals?.[0]?.expiresAt || dealClone?.expiresAt || tomorrowPlusFive,
    shortDesc: currentDeal?.deals?.[0]?.shortDesc || dealClone?.deals?.[0]?.shortDesc || "",
    details: currentDeal?.deals?.[0]?.details || dealClone?.deals?.[0]?.details || "",
    zohoId: currentDeal?.deals[0].zohoId || dealClone?.deals?.[0]?.zohoId || "",
    type: submitType.update, // update,
    currentForm: currentDeal?.currentForm || dealClone?.currentForm || 'new'
  }), [currentDeal, dealClone, initialCountry, initialCurrency, isZohoDeal]);


   /**
   * @async
   * Fetches Categories from the API, sets the categories list with them and reports any errors via Toasts
   */
  const loadCategories = useCallback(() => callAssignToast(getDealsCategories, setDealsCategories, 'list Categories'), [setDealsCategories]);

  /**
   * @async
   * Fetches Currencies from the API, sets the country list with them and reports any errors via Toasts
   */
  const loadCurrencies = useCallback(() => callAssignToast(getCurrencies, setCurrencyList, 'list Currencies'), [setCurrencyList]);

  /**
   * @async
   * Fetches Zoho Deals from the API, sets the deals list with them and reports any errors via Toasts
   */
  const loadZohoDeals = useCallback(async () => {
    if (headerCountry) callAssignToast(runGetZohoDeals, setZohoDeals, 'list Zoho Deals');
    // Not ignoring runGetZohoDeals changes here triggers an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerCountry]);
  
  /**
     * Passed to the list to execute on Item click, updates the form with the selected Deal
     * @async
     * @param {string} id
     */
  const fetchDealByObject = useCallback((dealObject) => {
    mediaArray = [];
    const deal = { ...dealObject };
    const firstDeal = deal.deals?.[0];

    if (cloningDeal) {
      deal.currentForm = 'cloning';
    } else if (editingDeal) {
      deal.currentForm = 'editing';
    } else {
      deal.currentForm = 'new';
    }

    deal.media = (typeof deal?.media === 'string') ? JSON.parse(deal.media) : deal.media;

    if (deal.media) mediaArray = [...deal.media];
    deal.deals = deal.deals.map((innerDeal) => {
      const commissionPercentage = getPercentageFromPrices(innerDeal.price, innerDeal.discountPrice);
      let { quantity, quantityBundle } = innerDeal;
      const { zohoId } = innerDeal;
      if (innerDeal.quantity && innerDeal.quantity != null) {
        setQuantityCheck(true);
      } else {
        setQuantityCheck(false);
         if(zohoId === "") quantity = 0;
      }

      if (innerDeal.quantityBundle && innerDeal.quantityBundle != null) {
        setIsBundle(true);
      }else{
        quantityBundle = 0;
      }
      
      return { ...innerDeal, commissionPercentage, quantity, quantityBundle };
    });

    if (firstDeal?.startAt) deal.startAt = formatToDate(firstDeal.startAt);
    if (firstDeal?.expiresAt) deal.expiresAt = formatToDate(deal?.deals[0].expiresAt);
    setIsBundle(!!firstDeal?.isBundle);
    setIsOversized(!!firstDeal?.isOversized);
    setGrantPoints(!!firstDeal?.grantPoints === false && typeof firstDeal?.grantPoints !== 'undefined');
    
    if (firstDeal?.attributes) {
      firstDeal.attributes.forEach((a) => {
        if (a.useImage) setUseImage(a.name);
      });
    }
    if (firstDeal?.zohoId) {
      deal.zohoId = firstDeal.zohoId;
      setIsZohoDeal(true);
    }

    if (firstDeal?.interests || firstDeal?.installmentPayment) {
      deal.checkInterestsInstallment = true;
      if (firstDeal.installmentPayment) {
        deal.checkInstallmentPayment = (firstDeal.installmentPayment).toString();
      }
      setShowPaymentMethodsCheck(true);
    } else {
      deal.checkInterestsInstallment = false;
      setShowPaymentMethodsCheck(false);
    }

    deal.checkRedeemPoints = !!firstDeal?.redeemPoints;
    deal.hasNoPointLimit = firstDeal?.pointsLimit === null;


    if (clone) setDealClone(deal);
    else setCurrentDeal(deal);
    setDealCategory(deal.category);
  }, [clone, cloningDeal, editingDeal]);

  useEffect(() => {
    if (currentDeal && currentDeal.id) setEditingDeal(true);
    if (dealClone) setCloningDeal(true);
  }, [currentDeal, dealClone]);

  // On load, get Countries, Currencies and Deals
  useEffect(() => {
    // Fetch deals list
    const initLoad = async () => {
      await Promise.all([loadCurrencies(), loadZohoDeals(), loadCategories()]);
      setReadyToLoad(true);
    };
    initLoad();
  }, [loadZohoDeals, loadCurrencies, loadCategories]);

  useEffect(() => {
    const afterLoad = async () => {
      mediaArray = [];
      let titles = [];
      if (object) {
        let attributes = [];
        object.deals.forEach(deal => {
          if (deal.attributes) {
            deal.attributes.forEach(attr => {
              if (attr.name) attributes.push({label: attr.name});
            });
          }
        });
        attributes = deleteDuplicates(attributes);
        titles = [
          // formCol({label: t('Image'), type: columnTypes.IMAGE, alignment: 'center'}),
          formCol({label: 'Sku', type: columnTypes.TEXT, alignment: 'center', element: 'sku', width: '100px', required: true}),
        ];
        attributes.forEach(attr => {
          titles.push(formCol({label: attr.label, type: columnTypes.TEXT, alignment: 'center', element: `attributes_${attr.label}`, useImage: true}));
        });
        titles = [...titles,
          formCol({ label: 'zohoQuantity', type: columnTypes.NUMBER, alignment: 'center', element: 'zohoQuantity', width: '150px' }),
          formCol({label: 'Quantity', type: columnTypes.NUMBER, alignment: 'center', element: 'quantity', width: '150px'}),
          formCol({label: 'Cost', type: columnTypes.NUMBER, alignment: 'center', element: 'cost', width: '150px'}),
          formCol({label: 'Brand', type: columnTypes.TEXTBOX, alignment: 'center', element: 'brand', width: '150px'}),
          formCol({label: 'WAO Socio Price', type: columnTypes.TEXTBOX, alignment: 'center', element: 'discountPrice', width: '150px', withCopy: true, required: true}),
          formCol({label: 'Base Commission', type: columnTypes.TEXTBOX, alignment: 'center', element: 'commissionPercentage', width: '150px', withCopy: true, required: true}),
          formCol({label: 'consumerPrice', type: columnTypes.TEXTBOX, alignment: 'center', element: 'price', width: '150px', withCopy: true, required: true}),
          formCol({label: 'Market Price', type: columnTypes.TEXTBOX, alignment: 'center', element: 'marketPrice', width: '150px', withCopy: true}),
          formCol({label: 'Active', type: columnTypes.SELECT, alignment: 'center', element: 'status', width: '150px'}),
        ];
        setTableColumns(titles);
        fetchDealByObject(object);
      }
      else {
        titles = [
          // formCol({label: t('Image'), type: columnTypes.IMAGE, alignment: 'center'}),
          formCol({label: 'Sku', type: columnTypes.TEXT, alignment: 'center', element: 'sku', width: '150px', required: true}),
          formCol({label: 'zohoQuantity', type: columnTypes.NUMBER, alignment: 'center', element: 'zohoQuantity', width: '150px'}),
          formCol({label: 'Quantity', type: columnTypes.NUMBER, alignment: 'center', element: 'quantity', width: '150px'}),
          formCol({label: 'Cost', type: columnTypes.NUMBER, alignment: 'center', element: 'cost', width: '150px'}),
          formCol({label: 'Brand', type: columnTypes.TEXTBOX, alignment: 'center', element: 'brand', width: '150px'}),
          formCol({label: 'WAO Socio Price', type: columnTypes.TEXTBOX, alignment: 'center', element: 'discountPrice', width: '150px', withCopy: true, required: true}),
          formCol({label: 'Base Commission', type: columnTypes.TEXTBOX, alignment: 'center', element: 'commissionPercentage', width: '150px', withCopy: true, required: true}),
          formCol({label: 'consumerPrice', type: columnTypes.TEXTBOX, alignment: 'center', element: 'price', width: '150px', withCopy: true, required: true}),
          formCol({label: 'Market Price', type: columnTypes.TEXTBOX, alignment: 'center', element: 'marketPrice', width: '150px', withCopy: true}),
          formCol({label: 'Active', type: columnTypes.SELECT, alignment: 'center', element: 'status', width: '150px'}),
        ];
        setTableColumns(titles);
        fetchDealByObject(formInitialValues);
      }
      setLoaderEnabled(false);
    };
    if (readyToLoad) afterLoad();
    // had to remove formInitialValues to avoid a loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToLoad, fetchDealByObject, object]);

  // Form placeholders
  const placeholder = useMemo(() => ({
    name: t("Deal Name"),
    sku: "SKU",
    shortDesc: t("Short description placeholder"),
    details: t("Enter your message details here"),
    discountPrice: t("WAO Socio Price"),
    commissionPercentage: t("Commission"),
    media: t("Enter your media file URL here"),
    price: t("consumerPrice"),
    marketPrice: t("Market Price"),
    quantity: t("Inventory"),
    quantityBundle: t("QuantityBundle"),
    pointsLimit: t("PointsLimit"),
    country: t('Country'),
    dealCategory: t('Deal Category'),
    cost: t("Cost"),
    brand: t("Brand"),
  }), [t]);
    
  /**
   * Function called whenever the Formik form is submitted
   * @async
   * @param {Object} valuesToSubmit Form values
   * @param {Object} options Functions provided by the Formik form
   */
  const formOnSubmit = useCallback( async (values) => {
    const valuesToSubmit = {...values};
    if (valuesToSubmit.discountPrice && valuesToSubmit.discountPrice === "") delete valuesToSubmit.discountPrice;

    const newMedia = [];
    if (valuesToSubmit.media && valuesToSubmit.media.length > 0) {
      await Promise.all(valuesToSubmit.media.map(async (mediaObject) => {
        let obj = mediaObject;
        if (mediaObject?.file) { // IMAGE
          const key = makeId(30);
          const ext = getFileExtension(mediaObject.file.name);
          obj = {url: await upload(mediaObject.file, `deals/images/${key}.${ext}`), type: mediaObject.type, source: mediaObject.source};
        }
        try {
          obj = {url: obj.url, type: obj.type, source: obj.source, priority: (mediaObject.priority && mediaObject.priority !== '') ? mediaObject.priority : null};
          newMedia.push(obj);
        } catch (error) {
          log.log('Error', error);
          toast.error(error);
        }
      }));
    }

    const startAt = new Date(valuesToSubmit.startAt).toISOString();
    const expiresAt = new Date(valuesToSubmit.expiresAt).toISOString();
    const { details, shortDesc } = valuesToSubmit;
    const category = valuesToSubmit.dealCategory;
    const country = headerCountry;

    const newDeal = {
      media: newMedia,
      name: valuesToSubmit.name,
      deals: valuesToSubmit.deals.map((deal) => {
        // TODO: managed the bundle for deal.
        let zohoMedia = null;
        // Copy the zoho media in deal
        if (editingDeal && currentDeal) {
          const resultingDeal = currentDeal.deals.find(d1 => deal.id === d1.id);
          zohoMedia = resultingDeal.media.find(media => media.source === 'zoho');
        }
        
        const obj = {...deal,
          category,
          shortDesc: shortDesc || null,
          details,
          expiresAt,
          startAt,
          country,
          discount: parseFloat(valuesToSubmit.deals[0].commissionPercentage / 100),
          media: (zohoMedia) ? [...newMedia, zohoMedia] : newMedia,
          isBundle,
          isOversized,
          grantPoints: !grantPoints,
          installmentPayment: (showPaymentMethodsCheck && valuesToSubmit.checkInstallmentPayment) ? Number(valuesToSubmit.checkInstallmentPayment) : null,
          interests: (showPaymentMethodsCheck && valuesToSubmit.checkInterestsInstallment) ? valuesToSubmit.checkInterestsInstallment : false,
          marketPrice: (!valuesToSubmit.deals?.[0].marketPrice || valuesToSubmit.deals?.[0].marketPrice === 0) ? "" : valuesToSubmit.deals?.[0].marketPrice,
          quantity: (!quantityCheck && valuesToSubmit.deals?.[0].zohoId === "" || valuesToSubmit.deals?.[0].quantity === "") ? null : valuesToSubmit.deals?.[0].quantity,
          pointsLimit: (!valuesToSubmit.checkRedeemPoints || valuesToSubmit.hasNoPointLimit) ? null : valuesToSubmit.deals?.[0].pointsLimit,
          redeemPoints: (valuesToSubmit.checkRedeemPoints) ? valuesToSubmit.checkRedeemPoints : false,
          quantityBundle: (!isBundle || valuesToSubmit.deals?.[0].quantityBundle === "") ? null : valuesToSubmit.deals?.[0].quantityBundle

        };
        
        if (dealClone) Reflect.deleteProperty(obj, 'id');
        Reflect.deleteProperty(obj, 'commissionPercentage');
        Reflect.deleteProperty(obj, 'countryName');
        Reflect.deleteProperty(obj, 'zohoBasePrice');
        Reflect.deleteProperty(obj, 'products');
        Reflect.deleteProperty(obj, 'questions');
        Reflect.deleteProperty(obj, 'created_at');
        Reflect.deleteProperty(obj, 'updated_at');
        Reflect.deleteProperty(obj, 'dealId');
        Reflect.deleteProperty(obj, 'sold');
        Reflect.deleteProperty(obj, 'dealGroup');
        Reflect.deleteProperty(obj, 'zohoMedia');
        Reflect.deleteProperty(obj, 'costPrice');
        Reflect.deleteProperty(obj, 'brand');
        Reflect.deleteProperty(obj, 'priority');
        Reflect.deleteProperty(obj, 'faqs');

        // Config the atrribute properties if use or not the image
        if (obj.attributes) {
          let variantName = '';
          obj.attributes = obj.attributes.map((attr, index) => {
            let result;
            if (index === obj.attributes.length - 1){
              variantName += attr.value;
            } else {
              variantName += `${attr.value}/`;
            }
            if (attr.name === useImage) {
              result = {...attr, useImage: true};
            } else {
              result = {...attr, useImage: false};
            }
            if (dealClone) {
              Reflect.deleteProperty(attr, 'id');
              Reflect.deleteProperty(attr, 'label');
              result = attr;
            };
            return result;
          });
          obj.name = `${valuesToSubmit.name}-${variantName}`;
        }

        return obj;
      })
    };
    let response = null;
    if (!editingDeal) { // CREATE
      response = await crudAction(postDeals.bind(null, newDeal), onClose,
        { ...crudOptions, name: valuesToSubmit.name, action: actions.CREATE }, t
      );
    } else { // UPDATE
      response = await crudAction(updateDeal.bind(null, currentDeal.id, newDeal), onClose,
        { ...crudOptions, name: valuesToSubmit.name, action: actions.UPDATE }, t
      );
    }
    if (response.ok && response.status === 200) setTimeout(() => { navigate('/deals'); }, 3000);
  }, [crudOptions, currentDeal, dealClone, editingDeal, grantPoints, headerCountry, isBundle, isOversized, navigate, onClose, quantityCheck, showPaymentMethodsCheck, t, useImage]);

  const getOptionsSelect = (optionsData, hasDeals = false) => {
    if (!optionsData) return [];
    return optionsData.map((item, index) => {
      let valueString = item.name || item.category;
      if (hasDeals) {
        valueString += item.deals.map((d, i) => (i === 0) ? ` - SKUs: ${d.sku}` : ` ${d.sku}`);
      }

      return {
        key: index,
        value: item.code || item.zohoId || item.category || item,
        label: valueString
      };
    });
  };

  const getSelect = useCallback((zohoId, hasDeals = false) => {
    if (zohoId && zohoDeals.length > 0){
      const resultZohoProduct = zohoDeals?.find(zoho => zoho.deals.find(deal => deal.zohoId === zohoId));

      if(resultZohoProduct){
        let valueString = resultZohoProduct.name || resultZohoProduct.category;
        if (hasDeals) {
          valueString += resultZohoProduct.deals.map((d, i) => (i === 0) ? ` - SKUs: ${d.sku}` : ` ${d.sku}`);
        }
        return {
          value: resultZohoProduct.code || resultZohoProduct.zohoId || resultZohoProduct.category || resultZohoProduct,
          label: valueString
        };
      }
    }
  },[zohoDeals]);

  const renderAsset = (fileUrl, fileType) => {
    if (!fileType) return '';
    let result = "";
    let type = fileType;
    if (mimeTypes[type]) type = mimeTypes[type];
    type = getAssetType(type);

    if (type === 'application')
      result = <embed className={dealStyles["deal-image"]} key={`${fileUrl}x`} src={fileUrl} width="160px" height="240px" />;

    else if (type === 'video')
      // eslint-disable-next-line jsx-a11y/media-has-caption
      result = <video className={dealStyles["deal-image"]} width="160" height="112" src={fileUrl} controls>
        Your browser does not support the video tag.
      </video>;
    else if (type === 'image')
      result = <img className={dealStyles["deal-image"]} src={fileUrl} />;
    
    if (result) result = <div> {result} </div>;
    return result;
  };
  
  const renderCurrency = () => <Grid item xs={2}>
    <Field as="select" className={`${themeStyles["form-control"]} ${dealStyles["deal-discount-currency"]}`} name={`deals.${0}.currency`} disabled={initialCurrency} style={{margin:"0px"}}>
      {currencyList.map((currencyItem) => (
        <option key={currencyItem.code} value={currencyItem.code}>{`${currencyItem.code} ${currencyItem.symbol}`}</option>
      ))};
    </Field>
  </Grid>;

  const renderMarketPriceInput = (values, errors) => <div>
    <label htmlFor="marketPrice" className={`${dealStyles["col-form-label"]} ${dealStyles["price-label"]}`}>{t('Market Price')}</label>
    <Grid container item spacing={0} className={dealStyles["price-div"]}>
      {renderCurrency()}
      <Grid item xs className={themeStyles.z2}>
        <Field type="number" className={`${themeStyles["form-control"]} ${dealStyles["deal-price"]}`} name={`deals.${0}.marketPrice`} step="any" placeholder={placeholder.marketPrice} value={values?.deals?.[0].marketPrice || ''} />
      </Grid>
    </Grid>
    {errors && errors.deals && errors.deals[0].marketPrice ? (<div className={themeStyles.formError}>{errors?.deals[0]?.marketPrice}</div>) : ' '}
    {values && values.deals && values.deals[0] && values.deals[0].price && values.deals[0].marketPrice &&
    ((values?.deals?.[0].price || 1) / (values?.deals?.[0].marketPrice || (values?.deals?.[0].price || 1)) ) * 100 < 50 ? (<div className={themeStyles.formWarning}>{t('TheDifferenceIsHigherThan50')}</div>) : ' '}
  </div>;

  // Remove comments to enable live sorting
  const prioritySortFn = (/* a, b */) => (0);// (a.priority || 999999) - (b.priority || 999999);

  const selectZohoProduct = useCallback((e, setFieldValue) => {
    mediaArray = [];
    if (e) {
      setQuantityCheck(true);
      let titles = [];
      const obj = zohoDeals[e.key];
      obj.media = [];
      obj.deals = obj.deals.map(deal => {
        const commissionPercentage = deal.commissionPercentage || getPercentageFromPrices(deal.price, deal.discountPrice);
        const price = deal.price || getPriceFromDiscountAndPercentage(deal.discountPrice, commissionPercentage);
        return { commissionPercentage, price, ...deal };
      });
      if (obj.deals[0].attributes && obj.deals[0].attributes.length > 0) {
        const { deals } = obj;
        let attributes = [];
        deals.forEach((deal) => {
          if (deal.attributes) {
            deal.attributes.forEach(attr => {
              if (attr.name) attributes.push({label: attr.name});
            });
          }
        });
        attributes = deleteDuplicates(attributes);
        titles = [
          formCol({label: 'Sku', type: columnTypes.TEXT, alignment: 'center', element: 'sku', width: '150px', required: true}),
        ];
        attributes.forEach(attr => {
          titles.push(formCol({label: attr.label, type: columnTypes.TEXT, alignment: 'center', element: `attributes_${attr.label}`, useImage: true}));
        });
        titles = [...titles,
          formCol({ label: 'zohoQuantity', type: columnTypes.NUMBER, alignment: 'center', element: 'zohoQuantity', width: '150px' }),
          formCol({ label: 'Quantity', type: columnTypes.NUMBER, alignment: 'center', element: 'quantity', width: '150px' }),
          formCol({label: 'Cost', type: columnTypes.NUMBER, alignment: 'center', element: 'cost', width: '150px'}),
          formCol({label: 'Brand', type: columnTypes.TEXTBOX, alignment: 'center', element: 'brand', width: '150px'}),
          formCol({ label: 'WAO Socio Price', type: columnTypes.TEXTBOX, alignment: 'center', element: 'discountPrice', width: '150px', withCopy: true, required: true }),
          formCol({ label: 'Base Commission', type: columnTypes.TEXTBOX, alignment: 'center', element: 'commissionPercentage', width: '150px', withCopy: true, required: true }),
          formCol({ label: 'consumerPrice', type: columnTypes.TEXTBOX, alignment: 'center', element: 'price', width: '150px', withCopy: true, required: true }),
          formCol({ label: 'Market Price', type: columnTypes.TEXTBOX, alignment: 'center', element: 'marketPrice', width: '150px', withCopy: true }),
          formCol({ label: 'Active', type: columnTypes.SELECT, alignment: 'center', element: 'status', width: '150px' }),
        ];
      } else {
        titles = [
          // formCol({label: t('Image'), type: columnTypes.IMAGE, alignment: 'center'}),
          formCol({ label: 'Sku', type: columnTypes.TEXT, alignment: 'center', element: 'sku', width: '150px', required: true }),
          formCol({ label: 'zohoQuantity', type: columnTypes.NUMBER, alignment: 'center', element: 'zohoQuantity', width: '150px' }),
          formCol({ label: 'Quantity', type: columnTypes.NUMBER, alignment: 'center', element: 'quantity', width: '150px' }),
          formCol({label: 'Cost', type: columnTypes.NUMBER, alignment: 'center', element: 'cost', width: '150px'}),
          formCol({label: 'Brand', type: columnTypes.TEXTBOX, alignment: 'center', element: 'brand', width: '150px'}),
          formCol({ label: 'WAO Socio Price', type: columnTypes.TEXTBOX, alignment: 'center', element: 'discountPrice', width: '150px', withCopy: true, required: true }),
          formCol({ label: 'Base Commission', type: columnTypes.TEXTBOX, alignment: 'center', element: 'commissionPercentage', width: '150px', withCopy: true, required: true }),
          formCol({ label: 'consumerPrice', type: columnTypes.TEXTBOX, alignment: 'center', element: 'price', width: '150px', withCopy: true, required: true }),
          formCol({ label: 'Market Price', type: columnTypes.TEXTBOX, alignment: 'center', element: 'marketPrice', width: '150px', withCopy: true }),
          formCol({ label: 'Active', type: columnTypes.SELECT, alignment: 'center', element: 'status', width: '150px', required: true }),
        ];
      }
      setTableColumns(titles);
      setCurrentDeal(obj);
      setIsBundle(obj.deals[0].isBundle);
      setIsZohoDeal(true);
      setFieldValue("isZohoDeal", true);
    } else {
      const titles = [
        // formCol({label: t('Image'), type: columnTypes.IMAGE, alignment: 'center'}),
        formCol({ label: 'Sku', type: columnTypes.TEXT, alignment: 'center', element: 'sku', width: '150px' }),
        formCol({ label: 'zohoQuantity', type: columnTypes.NUMBER, alignment: 'center', element: 'zohoQuantity', width: '150px' }),
        formCol({ label: 'Quantity', type: columnTypes.NUMBER, alignment: 'center', element: 'quantity', width: '150px' }),
        formCol({label: 'Cost', type: columnTypes.NUMBER, alignment: 'center', element: 'cost', width: '150px'}),
        formCol({label: 'Brand', type: columnTypes.TEXTBOX, alignment: 'center', element: 'brand', width: '150px'}),
        formCol({ label: 'WAO Socio Price', type: columnTypes.TEXTBOX, alignment: 'center', element: 'discountPrice', width: '150px', withCopy: true }),
        formCol({ label: 'Base Commission', type: columnTypes.TEXTBOX, alignment: 'center', element: 'commissionPercentage', width: '150px', withCopy: true }),
        formCol({ label: 'consumerPrice', type: columnTypes.TEXTBOX, alignment: 'center', element: 'price', width: '150px', withCopy: true }),
        formCol({ label: 'Market Price', type: columnTypes.TEXTBOX, alignment: 'center', element: 'marketPrice', width: '150px', withCopy: true }),
        formCol({ label: 'Active', type: columnTypes.SELECT, alignment: 'center', element: 'status', width: '150px' }),
      ];
      setTableColumns(titles);
      setCurrentDeal(null);
      setIsZohoDeal(false);
      setFieldValue("isZohoDeal", false);
      setIsBundle(false);
      setIsOversized(false);
      setGrantPoints(false);
      setQuantityCheck(true);
      setDealCategory(null);
      setShowPaymentMethodsCheck(false);
    }
  }, [zohoDeals]);

  const getAttributes =  (name, obj) => {
    const res = obj.find((e) => e.name === name);
    if (res && res.value) return res.value;
    return '';
  };

  const getHeader = useCallback(({ handleBlur, handleChange, setFieldValue, errors, values }, header, deal, dealIndex) => {
    const tempDeal = {...deal};
    switch (header.element) {
      case 'discountPrice':
        return <div>
          <div className={`${dealStyles['discount-container']} ${dealStyles.amount}`}>
            <Field type="number" className={themeStyles["form-control"]} placeholder='0' name={`deals.${dealIndex}.discountPrice`} value={tempDeal.discountPrice}
              onChange={(e) => {
                e.preventDefault();
                const value = toFloat(e?.target?.value);
                if (value && !Number.isNaN(value)) {
                  const commissionPercentage = getPercentageFromPrices(tempDeal.price, value);
                  setFieldValue(`deals.${dealIndex}.commissionPercentage`, commissionPercentage);
                  // const price = getPriceFromDiscountAndPercentage(value, commissionPercentage);
                  // setFieldValue(`deals.${dealIndex}.price`, price);
                  setFieldValue(`deals.${dealIndex}.discountPrice`, value);
                } else if (e?.target?.value === '') {
                  setFieldValue(`deals.${dealIndex}.discountPrice`, '');
                }
              }}
            />
            <span>$</span>
          </div>
          {errors?.deals?.[dealIndex]?.discountPrice ? (<div className={themeStyles.formError}>{errors?.deals?.[dealIndex]?.discountPrice}</div>) : ' '}
        </div>;
      case 'price':
        return <div>
          <div className={`${dealStyles['discount-container']} ${dealStyles.amount}`}>
            <Field type="number" className={themeStyles["form-control"]} placeholder='0' name={`deals.${dealIndex}.price`} value={tempDeal.price}
              onChange={(e) => {
                e.preventDefault();
                const value = toFloat(e?.target?.value);
                if (value && !Number.isNaN(value)) {
                  tempDeal.commissionPercentage = getPercentageFromPrices(value, tempDeal.discountPrice);
                  setFieldValue(`deals.${dealIndex}.commissionPercentage`, tempDeal.commissionPercentage);
                  // tempDeal.discountPrice = getDiscountFromPriceAndPercentage(value, tempDeal.commissionPercentage);
                  // setFieldValue(`deals.${dealIndex}.discountPrice`, tempDeal.discountPrice);
                  
                  setFieldValue(`deals.${dealIndex}.price`, value);
                } else if (e?.target?.value === '') {
                  setFieldValue(`deals.${dealIndex}.price`, '');
                }
              }}
            />
            <span>$</span>
          </div>
          {errors?.deals?.[dealIndex]?.price ? (<div className={themeStyles.formError}>{errors?.deals?.[dealIndex]?.price}</div>) : ' '}
        </div>;
      case 'commissionPercentage':
        return <div>
          <div className={`${dealStyles['discount-container']} ${dealStyles.discount}`}>
            <Field type="number" className={`${themeStyles["form-control"]} ${dealStyles["deal-price"]}`} name={`deals.${dealIndex}.commissionPercentage`} autoComplete="new-password"
              value={tempDeal.commissionPercentage}
              onChange={(e) => {
                e.preventDefault();
                const value = toFloat(e?.target?.value);
                if (value && !Number.isNaN(value) && value >= 0 && value <= 100) {
                  // tempDeal.discountPrice = getDiscountFromPriceAndPercentage(tempDeal.price, value);
                  // setFieldValue(`deals.${dealIndex}.discountPrice`, tempDeal.discountPrice);
                  const price = getPriceFromDiscountAndPercentage(tempDeal.discountPrice, value);
                  tempDeal.price = price;
                  setFieldValue(`deals.${dealIndex}.price`, tempDeal.price);

                  setFieldValue(`deals.${dealIndex}.commissionPercentage`, value);
                } else if (e?.target?.value === '') {
                  setFieldValue(`deals.${dealIndex}.commissionPercentage`, '');
                }
              }}
            />
            <span>%</span>
          </div>
          {errors?.deals?.[dealIndex]?.commissionPercentage ? (<div className={themeStyles.formError}>{errors?.deals?.[dealIndex]?.commissionPercentage}</div>) : ' '}
        </div>;
      case 'zohoQuantity':
        return <div>
          <Field type="number" className={`${themeStyles["form-control"]} ${dealStyles["deal-quantity"]}`} name={`deals.${dealIndex}.zohoQuantity`} min="0" value={tempDeal.zohoQuantity} placeholder={placeholder.quantity} autoComplete="new-password" disabled={tempDeal.isZohoDeal || (tempDeal.zohoId && tempDeal.zohoId.length > 0) || !quantityCheck} />
          {errors?.deals?.[dealIndex]?.zohoQuantity ? (<div className={themeStyles.formError}>{errors?.deals?.[dealIndex]?.zohoQuantity}</div>) : ' '}
        </div>;
      case 'quantity':
        return <div>
          <Field type="number" className={`${themeStyles["form-control"]} ${dealStyles["deal-quantity"]}`} name={`deals.${dealIndex}.quantity`} min="0" value={tempDeal.quantity} placeholder={placeholder.quantity} autoComplete="new-password"/>
          {errors?.deals?.[dealIndex]?.quantity ? (<div className={themeStyles.formError}>{errors?.deals?.[dealIndex]?.quantity}</div>) : ' '}
        </div>;
      case 'marketPrice':
        return <div>
          <div className={`${dealStyles['discount-container']} ${dealStyles.amount}`}>
            <Field type="number" className={`${themeStyles["form-control"]} ${dealStyles["deal-price"]}`} name={`deals.${dealIndex}.marketPrice`} step="any" placeholder='' value={tempDeal.marketPrice || ''} />
            <span>$</span>
          </div>
          {errors?.deals?.[dealIndex]?.marketPrice ? (<div className={themeStyles.formError}>{errors?.deals?.[dealIndex]?.marketPrice}</div>) : ' '}
          {values && values.deals && values.deals[dealIndex] && values.deals[dealIndex].price && values.deals[dealIndex].marketPrice &&
          ((values?.deals?.[dealIndex].price || 1) / (values?.deals?.[dealIndex].marketPrice || (values?.deals?.[dealIndex].price || 1)) ) * 100 < 50 ? (<div className={themeStyles.formWarning}>{t('TheDifferenceIsHigherThan50')}</div>) : ' '}
        </div>;
      case 'sku':
        return <div>
          <Field type="text" className={themeStyles["form-control"]} name={`deals.${dealIndex}.sku`} value={tempDeal.sku} placeholder='' onChange={handleChange} onBlur={handleBlur} disabled={tempDeal.isZohoDeal || tempDeal?.zohoId?.length} />
          {/* errors && errors.deals && errors.deals[index] && errors.deals[index].sku ? (<div className={themeStyles.formError}>{errors.deals[index].sku}</div>) : ' ' */}
          {errors?.deals?.[dealIndex]?.sku ? (<div className={themeStyles.formError}>Requerido*</div>) : ' '}
        </div>;
      case 'status':
        return <input type="checkbox" name={`deals.${dealIndex}.status`} checked={tempDeal.status === 'active'}
          style={{ cursor: "pointer" }}
          onChange={
            e => {
              const value = e.target.checked;
              setFieldValue(`deals.${dealIndex}.status`, (value) ? 'active' : 'disabled');
            }
          } />;
      case 'cost':
        return <div>
          <Field type="number" className={themeStyles["form-control"]} name={`deals.${dealIndex}.costPrice`} value={tempDeal.costPrice} placeholder={placeholder.cost} onChange={handleChange} onBlur={handleBlur} disabled={tempDeal.isZohoDeal || tempDeal?.zohoId?.length} />
        </div>;
      case 'brand':
        return <div>
          <Field type="text" className={themeStyles["form-control"]} name={`deals.${dealIndex}.brand`} value={tempDeal.brand} placeholder={placeholder.brand} onChange={handleChange} onBlur={handleBlur} disabled={tempDeal.isZohoDeal || tempDeal?.zohoId?.length}/>
        </div>;
      default:
        if (header.element.indexOf('attributes_') > -1) {
          return (tempDeal && tempDeal.attributes && tempDeal.attributes.length > 0) ? getAttributes(header.element.split('_')[1], tempDeal.attributes) : '-';
        }
        return tempDeal[header.element];
    }
  }, [t, quantityCheck, placeholder]);

  const fieldArrayRender = useCallback(({ form }) => {
    const { values: { deals }, errors } = form;

    return (
      deals && deals.length > 0
        ? deals.map((deal, dealIndex) => (
          <TableRow key={deal.zohoId || deal.sku || deal.name} className={errors.deals && errors.deals.length && errors.deals[dealIndex] != null ? orderStyles["deal-error-row"] : ""}>
            {tableColumns.map(header => (
              <TableCell key={header.label} align={header.align}>{getHeader(form, header, deal, dealIndex)}</TableCell>
            ))}
          </TableRow>
        ))
        : ''
    );
  }, [tableColumns, getHeader]);

  let title = t('Create Deal');
  if (clone) title = t('Cloning xdeal', { deal: dealClone?.name });
  else if (editingDeal) title = currentDeal?.name;

  const buttonText = !editingDeal ? t('Create') : t('Update');

  const copyClickAllHandler = (values, setFieldValue) => (
    () => {
      const cellValue = values.deals[0];
      if (cellValue !== '') {
        const deals = values.deals.map((deal) => {
          const item = { ...deal };
          item.price = cellValue.price;
          item.commissionPercentage = cellValue.commissionPercentage;
          item.discountPrice = cellValue.discountPrice;
          item.marketPrice = cellValue.marketPrice;
          return item;
        });
        setFieldValue('deals', deals);
      }
    }
  );

  const getZohoDeal = useCallback((idZoho) => {
    if (idZoho && zohoDeals.length > 0) {
      const zohoProduct = zohoDeals.find(zoho => zoho.deals.find(deal => deal.zohoId === idZoho));
      if(zohoProduct){
        let valueString = zohoProduct?.name || zohoProduct?.category;
        valueString += zohoProduct.deals.map((d, i) => (i === 0) ? ` - SKUs: ${d.sku}` : ` ${d.sku}`);
        return valueString;
      }
    }
  },[zohoDeals]);

  // useEffect(() => {
  //   if (showErrors && !showErrorsTimer) {
  //     // console.log('Start timeout');
  //     showErrorsTimer = setTimeout(() => {
  //       // console.log('end timeout');
  //       clearTimeout(showErrorsTimer);
  //       showErrorsTimer = null;
  //       setShowErrors(false);
  //     }, showErrorsSecs * 1000);
  //   }
  // }, [showErrors]);

  return (
    (
      loaderEnabled ? <div style={{ display: 'flex', justifyContent: 'center', padding: '200px' }}>
        <CircularProgress />
      </div> :
        <div>
          <Grid container spacing={0} alignItems="flex-start" >
            <Grid item xs>
              <div className={themeStyles.currentContent}>
                <div className={dealStyles["deal-view"]}>

                  <header className={themeStyles["header-form"]}>
                    <h3>{title}</h3>
                  </header>

                  {!editingDeal ?
                    ("")
                    : (
                      <div>
                        <div className={themeStyles["date-created"]}>
                          <b>{t('Created at')}: &nbsp;</b> {` ${new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentDeal?.created_at))}`}
                        </div>
                        <div className={themeStyles["date-updated"]}>
                          <b>{t('Updated at')}: &nbsp;</b> {` ${new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentDeal?.updated_at))}`}
                        </div>
                      </div>
                    )}
                  <div className={themeStyles["form-wrap"]}>
                    <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formOnSubmit} validationSchema={DealSchema} autoComplete="off" >
                      {({ isSubmitting, values, handleChange, handleBlur, setFieldValue, errors, touched }) => (
                        <Form>
                        {!editingDeal ?
                            (
                              <Grid container spacing={1} className="form-group" style={{ paddingBottom: "1em" }}>
                              <Grid item xs={4}>
                                <label htmlFor="zohoDeals" className="font-bold col-form-label">{t('Zoho Deals')}</label>
                                {!zohoDealsLoading ? (
                                  <SearchSelect
                                    aria-label="zohoDeals"
                                    onChange={(e) => selectZohoProduct(e, setFieldValue)}
                                    externalFunctionValue={(item)=> {
                                      if(item?.value?.deals[0]?.zohoId) {
                                        setFieldValue("isZohoDeal",true);
                                      }
                                    }}
                                    value={getSelect(values?.zohoId, true)?.label}
                                    findValueParamName="label"
                                    options={getOptionsSelect(zohoDeals, true)} placeholder={t('Zoho Deals')}
                                    isClearable
                                  />) : (<CircularProgress size={16} />)
                                }
                              {errors.isZohoDeal && touched.isZohoDeal ? (<div className={themeStyles.formError}>{errors.isZohoDeal}</div>) : ' '}
                              </Grid>
                            </Grid>
                          )
                          : (
                            <div>
                              {currentDeal.zohoId ? (
                                <div>
                                  <b>{t('Zoho Deal')}: &nbsp;</b> {!zohoDealsLoading ?
                                    `${getZohoDeal(currentDeal.zohoId) }`
                                  : (<CircularProgress size={16} />) }
                                </div>) : ""
                              }
                            </div>
                          )}
                          <Grid container spacing={1} className={themeStyles["form-group"]}>
                            <Grid item xs={4}>
                              <label htmlFor="name" className={themeStyles["col-form-label"]}>{t('Name')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                              <Field type="text" className={themeStyles["form-control"]} name="name" placeholder={placeholder.name} value={values.name} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                              {errors.name && touched.name ? (<div className={themeStyles.formError}>{errors.name}</div>) : ' '}
                            </Grid>
                          </Grid>

                          <Grid container spacing={1} className={themeStyles["form-group"]} direction="row" alignItems="center">
                            {!values.zohoId === '' || values.deals.some(deal => deal.attributes?.length > 0 ) || values.deals?.length > 1? (
                              <Grid item xs={4}>
                                <label htmlFor="dealCategory" className={themeStyles["col-form-label"]}>{t('Deal Category')}</label>
                                <SearchSelect
                                  aria-label="dealCategory"
                                  onChange={e => {
                                    if (e) {
                                      setDealCategory(e.value);
                                      setFieldValue('dealCategory', e.value);
                                    } else {
                                      setDealCategory(null);
                                      setFieldValue('dealCategory', null);
                                    }
                                  }}
                                  value={values.dealCategory}
                                  options={getOptionsSelect(dealsCategories)} placeholder={t('Deal Category')}
                                  isClearable maxMenuHeight="150px"
                                />
                                {errors.dealCategory && touched.dealCategory ? (<div className={themeStyles.formError}>{errors.dealCategory}</div>) : ' '}
                              </Grid>
                              )
                              :
                              (
                              <Fragment>
                                <Grid item xs={4} style={{ marginRight: "5px" }}>
                                  <label htmlFor="dealCategory" className={themeStyles["col-form-label"]}>{t('Deal Category')}</label>
                                  <SearchSelect
                                    aria-label="dealCategory"
                                    onChange={e => {
                                      if (e) {
                                        setDealCategory(e.value);
                                        setFieldValue('dealCategory', e.value);
                                      } else {
                                        setDealCategory(null);
                                        setFieldValue('dealCategory', null);
                                      }
                                    }}
                                    value={values.dealCategory}
                                    options={getOptionsSelect(dealsCategories)} placeholder={t('Deal Category')}
                                    isClearable maxMenuHeight="150px"
                                  />
                                  {errors.dealCategory && touched.dealCategory ? (<div className={themeStyles.formError}>{errors.dealCategory}</div>) : ' '}
                                </Grid>
                              <Grid item xs={4}>
                                <label htmlFor={`deals.${0}.sku`} className={themeStyles["col-form-label"]}>SKU
                                  <Field type="text" className={themeStyles["form-control"]} name={`deals.${0}.sku`} placeholder={placeholder.sku} value={values?.deals[0].sku || ''} onChange={handleChange} onBlur={handleBlur}
                                    disabled={values?.deals?.[0].isZohoDeal || (values?.deals?.[0].zohoId && values?.deals?.[0].zohoId.length > 0)}
                                  />
                                </label>
                                {errors && errors.deals && errors.deals[0].sku ? (<div className={themeStyles.formError}>Requerido*</div>) : ' '}
                              </Grid>
                              </Fragment>
                              )}
                          </Grid>
                          <Grid container spacing={1} className={themeStyles["form-group"]} direction="row" alignItems="center">
                            <Grid item xs={4} style={{ marginRight: "5px" }}>
                              <label htmlFor="isBundle">
                                <input type="checkbox" name="isBundle" checked={isBundle} disabled={isZohoDeal} onChange={
                                  (e) => { setIsBundle(e.target.checked); }
                                } />
                                {` ${t('Is a bundle?')}`}
                              </label>
                            </Grid>
                            {
                              !isBundle ? '' :
                              <Grid item xs={4}>
                                  <label htmlFor="quantityBundle" className={themeStyles["col-form-label"]}>{t('QuantityBundle')}</label>
                                  <Field type="number" className={`${themeStyles["form-control"]} ${dealStyles["deal-quantity"]}`} name={`deals.${0}.quantityBundle`} min="0" placeholder={placeholder.quantityBundle} autoComplete="new-password"
                                    value={!isBundle ? "" : values?.deals?.[0].quantityBundle}
                                  />
                                  {errors && errors.deals && errors.deals[0].isBundle && touched.deals[0].isBundle ? (<div className={`${themeStyles.formError} align-left`}>{errors.deals[0].quantityBundle}</div>) : ''}
                              </Grid>
                            }
                          </Grid>
                          <Grid container spacing={1} className={themeStyles["form-group"]} direction="row" alignItems="center">
                            <Grid item xs={4}>
                              <label htmlFor="isOversized">
                                <input type="checkbox" name="isOversized" checked={isOversized} onChange={
                                  (e) => { setIsOversized(e.target.checked); }
                                } />
                                {` ${t('Is oversized?')}`}
                              </label>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className={themeStyles["form-group"]} direction="row" alignItems="center">
                            <Grid item xs={4}>
                              <label htmlFor="grantPoints">
                                <input type="checkbox" name="grantPoints" checked={grantPoints} onChange={
                                  (e) => { setGrantPoints(e.target.checked); }
                                } />
                                {` ${t('Training product')}`}
                              </label>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className={themeStyles["form-group"]}>
                            <Grid item xs>
                              {(values.media && values.media.length > 0) ? (
                                <div className={dealStyles["deal-file"]} >
                                  <label htmlFor="file" className={themeStyles["col-form-label"]}>{t('Media Files')}</label>
                                  {
                                    !values.media || values.media.length < 1 ? (' ') : (
                                      <Grid container item xs spacing={3} justifyContent="flex-start" alignItems="center" alignContent="center">
                                        {[...values.media].sort(prioritySortFn).map((mediaObject, index) => (
                                          <Grid container item xs key={mediaObject?.url} className={dealStyles['container-content']}>
                                            <div className={dealStyles["media-panel"]}>
                                              <div className={dealStyles["media-container"]}>
                                                {!mediaObject?.file ? renderAsset(mediaObject?.url, getUrlExtension(mediaObject.url)) : renderAsset(mediaObject.url, mediaObject.file.type)}
                                              </div>
                                              <div className={dealStyles["media-attr"]}>
                                                <label htmlFor="isMarketing">
                                                  <input type="checkbox" name="isMarketing" checked={mediaObject.type !== 'product'} onChange={
                                                    (e) => {
                                                      const value = e.target.checked;
                                                      let filesCopy = [...mediaArray];
                                                      filesCopy = filesCopy.sort(prioritySortFn);
                                                      const obj = { ...filesCopy[index] };
                                                      obj.type = (value) ? 'marketing' : 'product';
                                                      if (value) {
                                                        obj.priority = '';
                                                      }
                                                      filesCopy[index] = obj;
                                                      mediaArray = [...filesCopy];
                                                      setFieldValue('media', mediaArray);
                                                    }
                                                  } />
                                                  <span>{t('Flyer?')}</span>
                                                </label> -
                                                <span className={dealStyles["media-filename"]}>
                                                  <a href={!mediaObject?.file ? mediaObject.url : mediaObject.url} target="_blank" rel="noreferrer">{`Media ${(index + 1)}`}</a>
                                                  <i>
                                                    <Icon color="#000" size={13} icon="delete-" className={dealStyles["trash-icon"]} onClick={() => {
                                                      const filesCopy = [...mediaArray];
                                                      filesCopy.splice(index, 1);
                                                      mediaArray = [...filesCopy];
                                                      setFieldValue('media', mediaArray);
                                                    }} />
                                                  </i>
                                                </span>
                                              </div>
                                              <div className={dealStyles["media-attr"]}>
                                                <span>{t('Priority')}</span>
                                                <input min={1} type="number" name="priority" value={mediaObject.priority || ''} onChange={
                                                  ({ target: { value } }) => {
                                                    let filesCopy = [...mediaArray];
                                                    filesCopy = filesCopy.sort(prioritySortFn);
                                                    const obj = { ...filesCopy[index] };
                                                    obj.priority = value;
                                                    filesCopy[index] = obj;
                                                    mediaArray = [...filesCopy];
                                                    setFieldValue('media', mediaArray);
                                                  }
                                                } className={`${themeStyles['form-control']} ${dealStyles['media-priority']}`} disabled={mediaObject.type === 'marketing'}/>
                                              </div>
                                            </div>
                                          </Grid>
                                        ))}
                                        {values.media.length % 3 === 1 ? <Grid item xs={8} /> : ''}
                                        {values.media.length % 3 === 2 ? <Grid item xs={4} /> : ''}
                                      </Grid>
                                    )
                                  }
                                </div>
                              ) : ("")}
                              <div className={dealStyles["upload-file-section"]}>
                                <label htmlFor="file" className={themeStyles["col-form-label"]}>{t('Upload File')}<span className={dealStyles["media-file-size"]}>{t('maximumFileSize', { size: maximumFileSizeText })}</span></label>
                                <UploadFile
                                  successFunction={(files) => {
                                    const updatedMediaFiles = files.map(file => ({ file, url: URL.createObjectURL(file), type: 'product', source: 'manager' }));
                                    mediaArray = [...mediaArray, ...updatedMediaFiles];
                                    setFieldValue('media', mediaArray);
                                  }}
                                  errorFunction={(message) => {
                                    toast.error(`${t('Unable to upload asset')}: ${message}`);
                                  }}
                                  accept={defaultUploadAccept}
                                />
                                {errors.file && touched.file ? (<div className={themeStyles.formError}>{errors.file}</div>) : ' '}
                                {errors.media && touched.media ? (<div className={themeStyles.formError}>{errors.media}</div>) : ' '}
                              </div>
                            </Grid>
                          </Grid>
                          {
                           values.zohoId === '' || !values.deals.some(deal => deal.attributes?.length > 0 ) || !values.deals?.length? (
                          <Fragment>
                          <Grid container spacing={1} className={themeStyles["form-group"]}>
                            <Grid item xs={4} style={{marginRight:"5px"}}>
                              <label htmlFor="price" className={`${dealStyles["col-form-label"]} ${dealStyles["price-label"]}`}>{t('consumerPrice')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                              <Grid container item spacing={0} className={dealStyles["price-div"]}>
                                {renderCurrency()}
                                <Grid item xs className={themeStyles.z2}>
                                  <Field type="number" className={`${themeStyles["form-control"]} ${dealStyles["deal-price"]}`} name={`deals.${0}.price`} step="any" placeholder={placeholder.price}
                                    onChange={(e) => {
                                      e.preventDefault();
                                      const value = toFloat(e?.target?.value);
                                      if (value && !Number.isNaN(value)) {
                                        // const discount = getDiscountFromPriceAndPercentage(value, values?.deals?.[0].commissionPercentage);
                                        // setFieldValue(`deals.${0}.discountPrice`, discount);
                                        const percentage = getPercentageFromPrices(value, values?.deals?.[0].discountPrice);
                                        setFieldValue(`deals.${0}.commissionPercentage`, percentage);
                                        setFieldValue(`deals.${0}.price`, value);
                                      } else if (e?.target?.value === '') {
                                        setFieldValue(`deals.${0}.price`, '');
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              {errors && errors.deals && errors.deals[0].price ? (<div className={`${themeStyles.formError} align-left`} >{errors?.deals?.[0].price}</div>) : ' '}
                            </Grid>
                            <Grid item xs={4}>
                              { renderMarketPriceInput(values, errors, touched) }
                            </Grid>
                          </Grid>

                        <Grid container spacing={1} className={themeStyles["form-group"]}>

                        <Grid item xs={4} style={{marginRight:"5px"}}>
                          <label htmlFor="commissionPercentage" className={`${dealStyles["col-form-label"]} ${dealStyles["price-label"]}`}>{t('Comisión Base')} (%)<span className={themeStyles["label-asterisk"]}>*</span></label>
                          <Grid container item spacing={0} className={dealStyles["price-div"]}>
                            <Field type="number" className={`${themeStyles["form-control"]} ${dealStyles["deal-price"]}`} name={`deals.${0}.commissionPercentage`} step=".01" placeholder={placeholder.commissionPercentage} autoComplete="new-password"
                              onChange={(e) => {
                                e.preventDefault();
                                const value = toFloat(e?.target?.value);
                                if (value && !Number.isNaN(value) && value >= 0 && value <= 100) {
                                  // const discount = getDiscountFromPriceAndPercentage(values?.deals?.[0].price, value);
                                  // setFieldValue(`deals.${0}.discountPrice`, discount);
                                  const price = getPriceFromDiscountAndPercentage(values?.deals?.[0].discountPrice, value);
                                  setFieldValue(`deals.${0}.price`, price);
                                  setFieldValue(`deals.${0}.commissionPercentage`, value);
                                } else if (e?.target?.value === '') {
                                  setFieldValue(`deals.${0}.commissionPercentage`, '');
                                }
                              }}
                            />
                          </Grid>
                          {errors && errors.deals && errors.deals[0].commissionPercentage ? (<div className={`${themeStyles.formError} align-left`} >{errors?.deals?.[0].commissionPercentage}</div>) : ' '}
                        </Grid>

                        <Grid item xs={4}>
                          <label htmlFor="discountPrice" className={`${dealStyles["col-form-label"]} ${dealStyles["discount-label"]}`}>{t('WAO Socio Price')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                          <Grid container item spacing={0} className={dealStyles["price-div"]}>
                            {renderCurrency()}
                            <Grid item xs className={themeStyles.z2}>
                              <Field type="number" className={`${themeStyles["form-control"]} ${dealStyles["deal-discount"]}`} name={`deals.${0}.discountPrice`} placeholder={placeholder.discountPrice} autoComplete="new-password"
                                onChange={e => {
                                  e.preventDefault();
                                  const value = toFloat(e?.target?.value);
                                  if (value && !Number.isNaN(value)) {
                                    const percentage = getPercentageFromPrices(values?.deals?.[0].price, value);
                                    setFieldValue(`deals.${0}.commissionPercentage`, percentage);
                                    // const price = getPriceFromDiscountAndPercentage(value, percentage);
                                    // setFieldValue(`deals.${0}.price`, price);
                                    setFieldValue(`deals.${0}.discountPrice`, value);
                                  } else if (e?.target?.value === '') {
                                    setFieldValue(`deals.${0}.discountPrice`, '');
                                  }
                                }} />
                            </Grid>
                          </Grid>
                          {errors && errors.deals && errors.deals[0].discountPrice ? (<div className={themeStyles.formError}>{errors?.deals?.[0].discountPrice}</div>) : ' '}
                        </Grid>
                        
                      </Grid>
                      </Fragment>)
                      :
                          <Grid container spacing={1} className={themeStyles["form-group"]} direction="row" alignItems="center">
                            <div style={{width: "100%"}}>
                            <TableContainer className={orderStyles["products-table-cell"]}>
                                  <Table stickyHeader aria-label="sticky table" >
                                    <TableHead className={orderStyles["products-hr"]}>
                                      <TableRow>
                                        { tableColumns.map((header) => {
                                          let cell = <TableCell key={header.label} align={header.align}>{header.label}{header.required ? <span className={themeStyles["label-asterisk"]}>*</span> : ''}</TableCell>;
                                          if (header.withCopy) {
                                            cell =
                                              <TableCell key={header.label} align={header.align}>
                                                <div>
                                                  {header.label}{header.required ? <span className={themeStyles["label-asterisk"]}>*</span> : ''}
                                                  <br />
                                                  <div style={{ fontSize: '12px', paddingTop: '6px' }} onClick={copyClickAllHandler(values, setFieldValue)}>
                                                    <span className={themeStyles.link}>{t('copyAll')}</span>
                                                  </div>
                                                </div>
                                                
                                              </TableCell>;
                                          } else if (header.useImage) {
                                            cell =
                                              <TableCell key={header.label} align={header.align}>
                                                <div className={dealStyles['container-use-image']}>
                                                {header.label}{header.required ? <span className={themeStyles["label-asterisk"]}>*</span> : ''}
                                                  <label htmlFor={`useImage_${header.label}`}>
                                                    <input type="checkbox" name={`useImage_${header.label}`} checked={useImage === header.label} onChange={
                                                      () => {
                                                        if (useImage !== header.label) {
                                                          setUseImage(header.label);
                                                        } else {
                                                          setUseImage('');
                                                        }
                                                     }} />
                                                    {t('useImage')}
                                                  </label>
                                                </div>
                                                
                                              </TableCell>;
                                          }
                                          return cell;
                                        })}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <FieldArray name="deals" render={fieldArrayRender}
                                    />
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                            </div>
                          </Grid>}
                          <Grid container spacing={1} className={themeStyles["form-group"]} direction="row" alignItems="center">
                            <Grid item xs={4} style={{ marginRight: "5px" }}>
                              <label htmlFor="checkRedeemPoints">
                                <input type="checkbox" name="checkRedeemPoints" checked={values?.checkRedeemPoints} onChange={
                                  (e) => { setFieldValue(`checkRedeemPoints`,e.target.checked); }
                                } />
                                {` ${t('Allow to pay with points')}`}
                              </label>
                            </Grid>
                            {values?.checkRedeemPoints ? <>
                            <Grid item xs={2} style={{ marginRight: "5px" }}>
                                <label htmlFor="pointsLimit" className={themeStyles["col-form-label"]}>{t('Until')}</label>
                              <Field type="number" className={`${themeStyles["form-control"]} ${dealStyles["deal-quantity"]}`} name={`deals.${0}.pointsLimit`} min="0" placeholder={placeholder.pointsLimit} autoComplete="new-password"
                                disabled={!values?.checkRedeemPoints || values?.hasNoPointLimit}
                                value={!values?.checkRedeemPoints || values.hasNoPointLimit ? 0 : values?.deals?.[0].pointsLimit}
                                />
                              {errors && errors.deals?.[0].pointsLimit && touched.deals?.[0].pointsLimit ? (<div className={`${themeStyles.formError} align-left`}>{errors.deals[0].pointsLimit}</div>) : ''}
                            </Grid>
                            <Grid item xs={2}>
                                <label htmlFor='hasNoPointLimit'>
                                  <input type="checkbox" name="hasNoPointLimit" checked={values.hasNoPointLimit || false} disabled={false} onChange={
                                    (e) => {
                                      setFieldValue('hasNoPointLimit', e.target.checked);
                                    }
                                  } />
                                  {` ${t('No Limit')}`}
                                </label>
                              </Grid>
                              </> : ""}
                          </Grid>
                          <Grid container spacing={1} className={themeStyles["form-group"]} direction="row" alignItems="center">
                          {values.zohoId === '' ?
                              <Grid item xs={4} style={{ marginRight: "5px" }}>
                                <label htmlFor="quantityCheck">
                                  <input type="checkbox" name="quantityCheck" checked={quantityCheck} onChange={
                                    (e) => { setQuantityCheck(e.target.checked); }
                                  } disabled={isZohoDeal} />
                                  {` ${t('Offer with Quantity Limitation')}`}
                                </label>
                              </Grid>
                                  : "" }
                          {(!values.deals.some(deal => deal.attributes?.length > 0 ) || !values.deals?.length) && (values?.deals?.[0].isZohoDeal || (values?.deals?.[0].zohoId && values?.deals?.[0].zohoId.length > 0))?
                              <Grid item xs={4} style={{ marginRight: "5px" }}>
                                  <label htmlFor="zohoQuantity" className={themeStyles["col-form-label"]}>{t('zohoQuantity')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <Field type="number" className={`${themeStyles["form-control"]} ${dealStyles["deal-quantity"]}`} name={`deals.${0}.zohoQuantity`} min="0" placeholder={placeholder.quantity} autoComplete="new-password"
                                    disabled={values?.deals?.[0].isZohoDeal || (values?.deals?.[0].zohoId && values?.deals?.[0].zohoId.length > 0) || !quantityCheck}
                                    value={!values?.deals?.[0].isZohoDeal && !(values?.deals?.[0].zohoId && values?.deals?.[0].zohoId.length > 0) && !quantityCheck ? "" : values?.deals?.[0].zohoQuantity}
                                  />
                                  {errors && errors.deals && errors.deals[0].zohoQuantity && touched.deals[0].zohoQuantity ? (<div className={`${themeStyles.formError} align-left`}>{errors.deals[0].zohoQuantity}</div>) : ''}
                                </Grid>
                              : ""}
                          {!values.deals.some(deal => deal.attributes?.length > 0 ) || !values.deals?.length ?
                              <Grid item xs={4}>
                                  <label htmlFor="quantity" className={themeStyles["col-form-label"]}>{t('Quantity')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <Field type="number" className={`${themeStyles["form-control"]} ${dealStyles["deal-quantity"]}`} name={`deals.${0}.quantity`} min="0" placeholder={placeholder.quantity} autoComplete="new-password"
                                    disabled={!values?.deals?.[0]?.isZohoDeal && !(values?.deals?.[0]?.zohoId && values?.deals?.[0]?.zohoId.length > 0) && !quantityCheck}
                                    value={!values?.deals?.[0]?.isZohoDeal && !(values?.deals?.[0]?.zohoId && values?.deals?.[0]?.zohoId.length > 0) && !quantityCheck ? "" : values?.deals?.[0].quantity}
                                  />
                                  {errors && errors.deals && errors.deals?.[0]?.quantity && touched?.deals?.[0]?.quantity ? (<div className={`${themeStyles.formError} align-left`}>{errors.deals?.[0]?.quantity}</div>) : ''}
                                </Grid>
                            : ""}
                            </Grid>
                            <Grid container spacing={1} className={themeStyles["form-group"]} direction="row" alignItems="center">
                            {(!values.deals.some(deal => deal.attributes?.length > 0 ) || !values.deals?.length) && (values?.deals?.[0].isZohoDeal || (values?.deals?.[0].zohoId && values?.deals?.[0].zohoId.length > 0))?
                              <Grid item xs={4} style={{ marginRight: "5px" }}>
                                  <label htmlFor="costPrice" className={themeStyles["col-form-label"]}>{t('Cost')}</label>
                                  <Field type="number" className={`${themeStyles["form-control"]} ${dealStyles["deal-quantity"]}`} name={`deals.${0}.costPrice`} min="0" placeholder={placeholder.cost} autoComplete="new-password"
                                    disabled={values?.deals?.[0].isZohoDeal || (values?.deals?.[0].zohoId && values?.deals?.[0].zohoId.length > 0)}
                                    value={values?.deals?.[0].costPrice}
                                  />
                                  {errors && errors.deals && errors.deals[0].costPrice && touched.deals[0].costPrice ? (<div className={`${themeStyles.formError} align-left`}>{errors.deals[0].costPrice}</div>) : ''}
                                </Grid>
                            : ""}
                            {(!values.deals.some(deal => deal.attributes?.length > 0 ) || !values.deals?.length) && (values?.deals?.[0].isZohoDeal || (values?.deals?.[0].zohoId && values?.deals?.[0].zohoId.length > 0))?
                              <Grid item xs={4}>
                                  <label htmlFor="brand" className={themeStyles["col-form-label"]}>{t('Brand')}</label>
                                  <Field type="text" className={`${themeStyles["form-control"]} ${dealStyles["deal-quantity"]}`} name={`deals.${0}.brand`} min="0" placeholder={placeholder.brand} autoComplete="new-password"
                                    disabled={values?.deals?.[0].isZohoDeal || (values?.deals?.[0].zohoId && values?.deals?.[0].zohoId.length > 0)}
                                    value={values?.deals?.[0].brand}
                                  />
                                  {errors && errors.deals && errors.deals[0].brand && touched.deals[0].brand ? (<div className={`${themeStyles.formError} align-left`}>{errors.deals[0].brand}</div>) : ''}
                                </Grid>
                            : ""}
                            </Grid>
                            <Grid container spacing={1} className={`${themeStyles["form-group"]} ${dealStyles["date-group"]}`}>
                            <Grid item xs={4}>
                              <label htmlFor="allowedPaymentMethods" className={themeStyles["col-form-label"]}>{t('Allowed payment methods')}</label>
                              <div className={dealStyles["installmentPayment-input__label__div"]}>
                                <div>
                                  <input type="checkbox" name="checkInterestsInstallment" checked={showPaymentMethodsCheck} onChange={
                                    (e) => {
                                      setShowPaymentMethodsCheck(e.target.checked);
                                      setFieldValue('checkInterestsInstallment', e.target.checked);
                                    }
                                  }/>
                                  <span className={dealStyles["installmentPayment-input__label"]}>{t("Interest-free fees")}</span>
                                </div>
                                <span className={dealStyles["installmentPayment-input__copy"]}>{t("Choose to pay with installments")}</span>
                              </div>
                              <div className={`${dealStyles["installmentPayment-content"]} ${showPaymentMethodsCheck ? dealStyles["installmentPayment-content-show"]: dealStyles["installmentPayment-content-hidden"]} `}>
                                <div className={`${dealStyles.installmentPayment} ${showPaymentMethodsCheck ? dealStyles["installmentPayment-show"] : dealStyles["installmentPayment-hidden"]} `}>
                                    <div className={`${dealStyles["installmentPayment-input"]} ${dealStyles["installmentPayment-input__header"]}`}>
                                      <span>{t('Dues')}</span>
                                    </div>
                                    <div className={dealStyles["installmentPayment-input"]}>
                                      <div className={dealStyles["installmentPayment-input__dues"]}>
                                        <input type="radio" name="checkInstallmentPayment" value="3" checked={values.checkInstallmentPayment === "3"} onChange={
                                          (e) => {
                                            setFieldValue('checkInstallmentPayment', e.target.value);
                                          }
                                          }/>
                                        <span>{`3 ${t('dues')}`}</span>
                                      </div>
                                  </div>
                                  <div className={dealStyles["installmentPayment-input"]}>
                                    <div className={dealStyles["installmentPayment-input__dues"]}>
                                      <input type="radio" name="checkInstallmentPayment" value="6" checked={values.checkInstallmentPayment === "6"} onChange={
                                        (e) => {
                                          setFieldValue('checkInstallmentPayment', e.target.value);
                                        }
                                      }/>
                                      <span>{`6 ${t('dues')}`}</span>
                                    </div>
                                  </div>
                                  <div className={dealStyles["installmentPayment-input"]}>
                                    <div className={dealStyles["installmentPayment-input__dues"]}>
                                      <input type="radio" name="checkInstallmentPayment" value={9} checked={values.checkInstallmentPayment === "9"} onChange={
                                        (e) => {
                                          setFieldValue('checkInstallmentPayment', e.target.value);
                                        }
                                      }/>
                                      <span>{`9 ${t('dues')}`}</span>
                                    </div>
                                  </div>
                                  <div className={dealStyles["installmentPayment-input"]}>
                                    <div className={dealStyles["installmentPayment-input__dues"]}>
                                      <input type="radio" name="checkInstallmentPayment" value="12" checked={values.checkInstallmentPayment === "12"} onChange={
                                        (e) => {
                                          setFieldValue('checkInstallmentPayment', e.target.value);
                                        }
                                      }/>
                                    <span>{`12 ${t('dues')}`}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {errors.allowedPaymentMethods && touched.allowedPaymentMethods ? (<div className={themeStyles.formError}>{errors.allowedPaymentMethods}</div>) : ' '}
                            </Grid>
                          </Grid>

                          <Grid container spacing={1} className={`${themeStyles["form-group"]} ${dealStyles["date-group"]}`}>
                            <Grid item xs={4} style={{marginRight:"5px"}}>
                              <label htmlFor="startAt" className={themeStyles["col-form-label"]}>{t('Starts At')}:<span className={themeStyles["label-asterisk"]}>*</span></label>
                              <DatePickerField className="dateTimePicker" name="startAt" dateFormat="yyyy-MM-dd hh:mm a" value={values.startAt} showTimeSelect timeIntervals={1} autoComplete="new-password" showTime={{ user12hours: true }} />
                              {errors.startAt && touched.startAt ? (<div className={themeStyles.formError}>{errors.startAt}</div>) : ' '}
                            </Grid>
                            <Grid item xs={4}>
                              <label htmlFor="expiresAt" className={themeStyles["col-form-label"]}>{t('Expires At')}:<span className={themeStyles["label-asterisk"]}>*</span></label>
                              <DatePickerField className="dateTimePicker" name="expiresAt" dateFormat="yyyy-MM-dd hh:mm a" value={values.expiresAt} showTimeSelect timeIntervals={1} autoComplete="new-password" showTime={{ user12hours: true }} />
                              {errors.expiresAt && touched.expiresAt ? (<div className={themeStyles.formError}>{errors.expiresAt}</div>) : ' '}
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className={themeStyles["form-group"]}>
                            <Grid item xs={8}>
                              <label htmlFor="shortDesc" className={themeStyles["col-form-label"]}>{t('Short Description')}</label>
                              <Field id="deal-shortDesc" as="textarea" className={themeStyles["form-control"]} name="shortDesc" placeholder={placeholder.shortDesc} rows="4" autoComplete="new-password" />
                              {errors.shortDesc && touched.shortDesc ? (<div className={themeStyles.formError}>{errors.shortDesc}</div>) : ' '}
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item xs={8}>
                              <label htmlFor="details" className={themeStyles["col-form-label"]}>{t('Description')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                              <Field id="deal-details" as="textarea" className={themeStyles["form-control"]} name="details" placeholder={placeholder.details} rows="6" autoComplete="new-password" />
                              {errors.details && touched.details ? (<div className={themeStyles.formError}>{errors.details}</div>) : ' '}
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className={themeStyles["form-group"]}>
                            <Grid item xs={6}>
                              <i><Icon color="#000" size={20} icon="insert_emoticon" className={dealStyles["trash-icon"]} onClick={toggleEmojiSection} /></i>
                            </Grid>
                            <Collapse in={emojiSectionExpanded}>
                              <Grid item xs={8}>
                                <Picker locale={i18n.language || "en"} data={data} onEmojiSelect={emojiObject => {
                                  setFieldValue("details", values.details + (emojiObject?.native || ''));
                                  document.getElementById("deal-details").focus();
                                }} native sheetSize={32} emoji="" previewPosition="none" title=""
                                />
                              </Grid>
                            </Collapse>
                          </Grid>
                          {/* {
                            showErrors && Object.keys(errors).length ?
                              <div>
                                {
                                  Object.keys(errors)?.map((error) => <div key={error}>
                                    {`${error}: ${errors[error]}`}
                                  </div>)
                                }
                              </div>
                            : ''
                          } */}
                          {/* <ErrorReporting /> */}
                          <div className={themeStyles["form-group"]}>
                            <Grid container item spacing={1} xs={8} justifyContent="space-between" alignItems="center">
                              <Grid item xs>
                                <NavLink to="/deals">
                                  <button className={`${themeStyles.btn} ${themeStyles["btn-cancel"]}`} disabled={isSubmitting}>{t('Cancel')}</button>
                                </NavLink>
                              </Grid>
                              <Grid item xs style={{ textAlign: "end" }}>
                                <button type="submit" className={`${themeStyles.btn} ${themeStyles["btn-secondary"]}`} disabled={isSubmitting} onClick={() => {
                                  // eslint-disable-next-line no-console
                                  console.log({ errors });
                                  // setShowErrors(true);
                                  setFieldValue('type', submitType.update);
                                }}>{
                                  isSubmitting ? `${t('Please Wait')}...` : buttonText
                                }</button>
                              </Grid>
                            </Grid>
                          </div>
                          <FormikErrorFocus offset={0} align="top" duration={1000} />
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>)
  );
};

export default withTranslation()(DealDetails);