import PayoutActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case PayoutActions.fetchPayoutsRequest().type:
      return state.merge({
        payoutsLoading: true,
        payoutsError: null,
        payoutsLoaded: false,
      });

      case PayoutActions.fetchPayoutsSuccess().type:
        return state.merge({
          payoutsLoading: false,
          payoutsError: null,
          payoutsLoaded: true,
          payouts: action.payload.payouts.data || state.payouts,
        });

    default:
      return state;
  }
};
