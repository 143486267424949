import Immutable from 'seamless-immutable';

const initialState = Immutable({
  payoutsInit: true,
  payoutsLoading: false,
  payoutsError: null,
  payoutsLoaded: false,
  payouts: null,

  createPayoutLoading: false,
  createPayoutError: null,
  createdPayout: false,
});


export default initialState;
