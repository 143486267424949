/* eslint-disable no-console */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable guard-for-in */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import FormikErrorFocus from 'formik-error-focus';
import { withTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import SearchSelect from '../../../core/SearchSelect';
import promoStyles from './styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import { postPromotions, validateCode as validatePost, updatePromotion } from '../../services/promotions';
import { getDeals, getCategories } from '../../../deals/services/deals';
import { getGroups } from '../../../groups/services/groups';
import { getCountry } from '../../../core/services/workflow';
import DatePickerField from '../../../core/DatePickerField';
import ToggleCheck from '../../../core/ToggleCheck';
import UploadFile from '../../../core/UploadFile';
import Icon from "../../../core/Icon";
import { actions, callAssignToast, crudAction, formDealLabel, getAssetType, getFileExtension, getUrlExtension, makeId, mimeTypes, modules, tempPromotionTypes, translatedSimpleOptions, tempPromotionTypesObj, filterOperatorsArray, tempFilterTypes, groupBy, deleteDuplicates, toFloat, getDatei18n } from '../../../utils';
import Dialog from '../../../core/Dialog';
import { upload } from '../../../core/services/upload';
import dealStyles from '../../../deals/views/forms/styles.module.sass';
import ModalCloseButton from '../../../core/ModalCloseButton';
import { toast } from 'react-toastify';

const dateFormat = {
  year: 'numeric', month: 'short', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  hour12: false,
  timeZoneName: 'short'
};

const PromotionCreate = ({ onClose = () => { }, ...props }) => {
  let mediaArray = [];
  let catalogMediaArray = [];
  const [isCodeValid, setIsCodeValid] = useState(true);
  const [currentPromotion, setCurrentPromotion] = useState(null);
  const [currentExceptions, setCurrentExceptions] = useState([]);
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [promoType, setPromoType] = useState('discounts_on_products');
    


  if(currentPromotion?.media){
    catalogMediaArray = currentPromotion?.media;
  }


  const dialogRef = useRef();
  const [dialogContent, setDialogContent] = useState('');
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [, setDialogResult] = useState(false);

  const module = modules(props.t).promotion;
  const headerCountry = getCountry();
  const initialCountry = headerCountry || "";

  const datei18n = getDatei18n(initialCountry);

  const {t} = props;

  const tr = {
    promotion: props.t('PromotionSection'),
    createPromotion: props.t('Create Promotion or Catalog'),
    groups: props.t('Groups'),
    name: props.t('Promotion Name'),
    preview: props.t('Preview'),
    pleaseWait: props.t('Please Wait'),
    create: props.t('Create'),
    update: props.t('Update'),
    delete: props.t('Delete'),
    createdAt: props.t('Created at'),
    updatedAt: props.t('Updated at'),
    required: props.t('Required'),
    groupOrTenderoRequired: props.t('groupOrTenderoRequired'),
    price: props.t('Price'),
    dealErrors: props.t('Deals have errors'),
    type: props.t('Promotion Type'),
    discount: props.t('Discount applied'),
    label: props.t('Label'),
    equal: props.t('equal'),
    lt: props.t('less than'),
    lte: props.t('less than or equal'),
    gt: props.t('greater than'),
    gte: props.t('greater than or equal'),
    toggle: props.t('toggle'),
    rules: props.t('Rules'),
  };

  const maximumFileSizeText = "5MB";
  const defaultUploadAccept = [
    'image/webp', 'image/jpeg', 'image/gif', 'image/png'
  ];

  const ModalSchema = Yup.object().shape({
    value: Yup.number().required(tr.required),
    type: Yup.string().required(tr.required)
  });

  

  const crudOptions = {
    module,
    name: currentPromotion?.name || '',
  };

  const formatDealsArray = (deals) => {
    const groupByDealGroup = currentExceptions.reduce((group, d) => {
      const { dealGroup } = d;
      group[dealGroup] = group[dealGroup] ?? [];
      group[dealGroup].push(d);
      return group;
    }, {});

    const data = [];
    try {
      deals.forEach((deal, i) => {
        if(groupByDealGroup[deal.id] === undefined || deal.deals.length !== groupByDealGroup[deal.id]?.length){
        data.push({label: formDealLabel(deal), value: deal.id, ...deal });
        if (deal.deals && deal.deals.length > 1) {
          data.push({label: '------------------------', value: `${deal.id}${i}`, id: `${deal.id}${i}`, disabled: true});
          deal.deals.forEach(d => {
            data.push({label: `${formDealLabel(d)}`, value: d.id, ...d });
          });
        }
      }
      });
    } catch (err) {
      console.log(err);
    }
    return data;
  };

  const formatCategoriesArray = (categories) => (categories.map(category => ({ label: category.category, value: category.id, ...category })));
  const formatExceptionsArray = (exceptions) => exceptions.map((exception) => {
      const labelObj = filterOperatorsArray.find((e) => e.value === exception.operator);
      return { value: exception, label: `${labelObj.label} ${exception.value}`, operator: labelObj.value };
    });
  const formatGroupsArray = (groups) => (groups.map(group => ({ label: group.name, value: group.id, ...group })));

  const returnDeals = async (queryTerm) => {
    let res = [];
    const dealsResult = await callAssignToast(getDeals.bind(null, { q: queryTerm, country: initialCountry }), () => { }, 'list Deals');
    if (dealsResult.data) res = formatDealsArray(dealsResult.data);
    return res;
  };

  const returnCategories = async (queryTerm) => {
    let res = [];
    const categoriesResult = await callAssignToast(getCategories.bind(null, { q: queryTerm, country: initialCountry }), () => { }, 'list Categories');
    if (categoriesResult.data) res = formatCategoriesArray(categoriesResult.data);
    return res;
  };

  const returnGroups = async (queryTerm) => {
    let res = [];
    const groupsResult = await callAssignToast(getGroups.bind(null, { q: queryTerm, country: initialCountry }), () => { }, 'list Groups');
    if (groupsResult.data) res = formatGroupsArray(groupsResult.data);
    return res;
  };

  const validateCode = async (code) => {
    if (!currentPromotion || props.clone || currentPromotion.code !== code) {
      const response = await crudAction(validatePost.bind(null, code.toUpperCase()), () => { },
        { ...crudOptions, name: 'Code', action: actions.CREATE }, t, true
      );
      setIsCodeValid(response.data.data.isValid);
      return response.data.data.isValid;
    }
      setIsCodeValid(true);
      return true;
    
  };

    /**
   * Passed to the list to execute on Item click, updates the form with the selected Blast
   * @param {string} id
   */
     const fetchPromotionByObject = (object) => {
      const promotion = { ...object };
      promotion.exceptions = [];
      promotion.exceptions.push({ type: 'deals', value: formatDealsArray(promotion.deals) });
      if(promotion.filterByPrice && promotion.filterByPrice.length > 0) promotion.exceptions.push({ type: 'price range', value: formatExceptionsArray(promotion.filterByPrice) });
      if(promotion.filterByCategory && promotion.filterByCategory.length > 0) promotion.exceptions.push({ type: 'categories', value: formatCategoriesArray(promotion.filterByCategory) });
      promotion.groups = formatGroupsArray(promotion.groups);
  
      promotion.dealsOption = promotion.filterByDeal.toLowerCase();
      promotion.isAllowCombo = promotion.allowCombo;
  
      promotion.type = promotion.key.toLowerCase();
      setPromoType(promotion.type);
      if (promotion.startAt && (typeof promotion.startAt !== 'object' || !promotion.startAt.toISOString)) {
        promotion.startAt = new Date(promotion.startAt);
      }
      if (promotion.expiresAt && (typeof promotion.expiresAt !== 'object' || !promotion.expiresAt.toISOString)) {
        promotion.expiresAt = new Date(promotion.expiresAt);
      }
      if (!promotion.expiresAt) {
        promotion.onGoing = true;
      }
      
      promotion.quantity = promotion.limit;
      promotion.quantityByProduct = promotion.limitByProductByBuyer;
      promotion.quantityByOrder = promotion.limitByOrderByBuyer;
      promotion.quantityByBuyer = promotion.limitByBuyer;
      promotion.limitUser = promotion.limitPerCustomer;
      promotion.limitReferrer = promotion.limitPerReferrer;
  
      if (!promotion.limit) {
        promotion.hasLimit = true;
      }
      if (!promotion.limitByProductByBuyer) {
        promotion.hasLimitByProduct = true;
      }
      if (!promotion.limitByOrderByBuyer) {
        promotion.hasLimitByOrder = true;
      }
      if (!promotion.limitByBuyer) {
        promotion.hasLimitByBuyer = true;
      }
  
      if (!promotion.limitPerCustomer) {
        promotion.hasLimitUser = true;
      }
  
      if (!promotion.limitPerReferrer) {
        promotion.hasLimitReferrer = true;
      }
  
      promotion.promoCode = promotion.code;
  
      if(promotion.onlyCustomer){
        promotion.checkReferrerAndCustomer = "customer";
      } else if (promotion.onlyReferrer){
        promotion.checkReferrerAndCustomer = "referrer";
      } else {
        promotion.checkReferrerAndCustomer = "all";
      }
  
      if (promotion.key.toLowerCase() === 'amount_discount') {
        promotion.rules = promotion.discount.options.map((option) => ({
            value: option.quantity || option.quantityByProduct,
            discount: option.rate * 100,
            type: option.operator
          }));
        promotion.discount = 0;
      } else if (promotion.key.toLowerCase() === 'discount_on_order') {
        promotion.rules = promotion.discount.options.map((option) => ({
            value: option.amount,
            discount: option.rate * 100,
            type: option.operator
          }));
        promotion.discount = 0;
      } else if (promotion.key.toLowerCase() === 'money_voucher_on_order') {
        promotion.rules_money = promotion.discount.options.map((option) => ({
            value: option.value,
            amount: option.amount,
            type: option.operator
          }));
        promotion.discount = 0;
      } else {
        promotion.discount = (promotion.discount.rate) ? parseInt(toFloat(promotion.discount.rate * 100), 10) : (promotion.discount.value) ? promotion.discount.value : null;
      }
  
      const images = (promotion.key.toLowerCase() === 'catalog') ? promotion.images : promotion.images?.map((img) => ({ url: img }));
      mediaArray = images;
      catalogMediaArray = images;

      promotion.media = images;
      promotion.visibility = (promotion.visibility === 'PUBLIC');
      setCurrentPromotion(promotion);
    };

  // On load, get Tenderos, Groups, Deals and Blasts
  useEffect(() => {
    const initLoad = async () => {
      // await Promise.all([loadTenderos(), loadGroups(), loadDeals()]);
      setReadyToLoad(true);
    };
    initLoad();
  }, []);

  // On currentPromotion update, update Promotion list
  useEffect(() => {
    const currentUpdate = async () => {
      if (currentPromotion.promoCode) {
        validateCode(currentPromotion.promoCode);
      }
    };
    if (currentPromotion) currentUpdate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPromotion]);

  useEffect(() => {
    const afterLoad = async () => {
      if (props.object) fetchPromotionByObject(props.object);
    };
    if (readyToLoad) afterLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToLoad]);


  // Form placeholders
  const placeholder = {
    name: props.t('Full Name'),
    description: props.t("Enter Blast description"),
    tags: props.t("Blast Tags"),
    deals: ` -- ${props.t("Select Deals")} -- `,
    categories: ` -- ${props.t("Select Categories")} -- `,
    users: ` -- ${props.t("Select Tenderos")} -- `,
    groups: ` -- ${props.t("Select Groups")} -- `,
  };

  const submitType = {
    update: 1,
    delete: 2
  };

  // Formik initial/default values

  const formInitialValues = {
    visibility: (currentPromotion) ? currentPromotion?.visibility : true,
    type: currentPromotion?.type || "discounts_on_products",
    name: currentPromotion?.name || "",
    discount: currentPromotion?.discount || 0,
    promoCode: currentPromotion?.promoCode || "",
    isCodeValid: !((!currentPromotion || props.clone)),
    quantity: currentPromotion?.quantity || 0,
    quantityByProduct: currentPromotion?.quantityByProduct || 0,
    quantityByOrder: currentPromotion?.quantityByOrder || 0,
    quantityByBuyer: currentPromotion?.quantityByBuyer || 0,
    hasLimit: currentPromotion?.hasLimit || false,
    hasLimitByProduct: currentPromotion?.hasLimitByProduct || false,
    hasLimitByOrder: currentPromotion?.hasLimitByOrder || false,
    hasLimitByBuyer: currentPromotion?.hasLimitByBuyer || false,
    hasLimitUser: currentPromotion?.hasLimitUser || false,
    limitReferrer: currentPromotion?.limitReferrer || 1,
    hasLimitReferrer: currentPromotion?.hasLimitReferrer || false,
    isAllowCombo: (currentPromotion) ? currentPromotion?.isAllowCombo: true,
    checkReferrerAndCustomer: currentPromotion?.checkReferrerAndCustomer || "all",
    label: currentPromotion?.label || "",
    onGoing: currentPromotion?.onGoing || false,
    startAt: currentPromotion?.startAt || "",
    expiresAt: currentPromotion?.expiresAt || "",
    deals: currentPromotion?.deals || "",
    dealsOption: currentPromotion?.dealsOption || "all",
    list: currentPromotion?.list || "",
    groups: currentPromotion?.groups || [],
    media: currentPromotion?.media || [],
    catalogMedia: currentPromotion?.media || [],
    rules: currentPromotion?.rules || [{
      value: 0,
      discount: 0,
      type: ''
    }],
    rules_money: currentPromotion?.rules_money || [{
      value: 0,
      amount: 0,
      type: ''
    }],
    typeButton: submitType.update,
    modal: {
      type: '',
      value: 0
    },
    exceptions: currentPromotion?.exceptions || [{
      type: 'deals',
      value: []
    }]

  };

  const formModalInitialValues = {
    type: '',
    value: 0,
  };


  /**
   * Function called whenever the Formik form is submitted
   * @async
   * @param {Object} values Form values
   * @param {Object} options Functions provided by the Formik form
   */
  const formOnSubmit = async (values) => {

    const exceptions = groupBy(values.exceptions, 'type', 'value');
    for (const key in exceptions) {
      exceptions[key] = deleteDuplicates(exceptions[key]);
    }
    const newMedia = [];
    const newMediaCatalog = [];
    const payload = {};
    if (values.type === 'catalog') {
      if (values.catalogMedia && values.catalogMedia.length > 0) {
        await Promise.all(values.catalogMedia.map(async (mediaObject) => {
          let obj = mediaObject;
          if (mediaObject?.file) { // IMAGE
            const key = makeId(30);
            const ext = getFileExtension(mediaObject.file.name);
            obj = { url: await upload(mediaObject.file, `deals/images/${key}.${ext}`), type: mediaObject.type, source: mediaObject.source };
          }
          try {
            obj = { url: obj.url, type: obj.type, source: obj.source };
            newMediaCatalog.push(obj);
          } catch (error) {
            console.log('Error', error);
            toast.error(error);
          }
        }));
      }
    } else if (values.media && values.media.length > 0) {
        await Promise.all(values.media.map(async (mediaObject) => {
          let obj = mediaObject;
          if (mediaObject?.file) { // IMAGE
            const key = makeId(30);
            const ext = getFileExtension(mediaObject.file.name);
            obj = { url: await upload(mediaObject.file, `deals/images/${key}.${ext}`), type: mediaObject.type, source: mediaObject.source };
          }
          try {
            newMedia.push(obj);
          } catch (error) {
            toast.error(error);
          }
        }));
      }

    payload.deals = (exceptions.deals && exceptions.deals.length > 0) ? exceptions.deals?.map((d) => d.id) : [];
    payload.filterByPrice = (exceptions['price range'] && exceptions['price range'].length > 0) ? exceptions['price range']?.map((p) => ({ value: p.value.value, operator: p.value.operator })) : [];
    payload.filterByCategory = (exceptions.categories && exceptions.categories.length > 0) ? exceptions.categories?.map((c) => ({ id: c.id })) : [];
    payload.filterByDeal = values.dealsOption.toUpperCase();
    payload.key = values.type.toUpperCase();
    payload.country = initialCountry;
    payload.name = values.name;
    payload.startAt = new Date(values.startAt).toISOString();
    payload.expiresAt = (!values.onGoing) ? new Date(values.expiresAt).toISOString() : null;
    payload.groups = values.groups.map((g) => g.id);
    payload.visibility = (values.visibility) ? 'PUBLIC' : 'PRIVATE';
    payload.allowCombo = values.isAllowCombo;
    payload.onlyCustomer = (values.checkReferrerAndCustomer === 'customer');
    payload.onlyReferrer = (values.checkReferrerAndCustomer === 'referrer');

    if (values.type === 'discounts_on_products') {
      payload.images = newMedia.map((image) => image.url)||[];
      payload.discount = { rate: toFloat(values.discount / 100) };
      payload.label = null;
      payload.code = null;
      payload.limit = null;
      payload.limitByProductByBuyer = null;
      payload.limitByOrderByBuyer = null;
      payload.limitByBuyer = null;
      payload.limitPerCustomer = null;
      payload.limitPerReferrer = null;
    } else if (values.type === 'discounts_with_promo_code') {
      payload.images = newMedia.map((image) => image.url)||[];
      payload.discount = { rate: toFloat(values.discount / 100) };
      payload.limit = null;
      payload.limitByProductByBuyer = (!values.hasLimitByProduct) ? parseInt(values.quantityByProduct, 10) : null;
      payload.limitByOrderByBuyer = null;
      payload.limitByBuyer = (!values.hasLimitByBuyer) ? parseInt(values.quantityByBuyer, 10) : null;
      payload.limitPerCustomer = null;
      payload.limitPerReferrer = (!values.hasLimitReferrer) ? parseInt(values.limitReferrer, 10) : null;
      payload.label = values.label;
      payload.code = values.promoCode.toUpperCase();
    } else if (values.type === 'money_voucher') {
      payload.images = newMedia.map((image) => image.url)||[];
      payload.limit = null;
      payload.limitByProductByBuyer = (!values.hasLimitByProduct) ? parseInt(values.quantityByProduct, 10) : null;
      payload.limitByOrderByBuyer = null;
      payload.limitByBuyer = (!values.hasLimitByBuyer) ? parseInt(values.quantityByBuyer, 10) : null;
      payload.limitPerCustomer = null;
      payload.limitPerReferrer = (!values.hasLimitReferrer) ? parseInt(values.limitReferrer, 10) : null;
      payload.label = values.label;
      payload.discount = { value: parseFloat(values.discount) };
      payload.code = values.promoCode.toUpperCase();
    } else if (values.type === 'first_customer_purchase') {
      payload.images = newMedia.map((image) => image.url)||[];
      payload.limit = null;
      payload.limitByProductByBuyer = null;
      payload.limitByOrderByBuyer = (!values.hasLimitByOrder) ? parseInt(values.quantityByOrder, 10) : null;
      payload.limitByBuyer = null;
      payload.limitPerCustomer = null;
      payload.limitPerReferrer = null;
      payload.label = values.label;
      payload.code = values.promoCode.toUpperCase();
      payload.discount = { rate: toFloat(values.discount / 100) };
    } else if (values.type === 'amount_discount') {
      payload.images = newMedia.map((image) => image.url)||[];
      payload.limit = null;
      payload.limitByProductByBuyer = null;
      payload.limitByOrderByBuyer = null;
      payload.limitByBuyer = null;
      payload.limitPerCustomer = null;
      payload.limitPerReferrer = null;
      payload.label = null;
      payload.code = null;
      payload.discount = {
        options: values.rules.map((r) => ({
            rate: toFloat(r.discount / 100),
            quantity: parseInt(r.value, 10),
            operator: r.type
          })),
      };
    } else if (values.type === 'discount_on_order') {
      payload.images = newMedia.map((image) => image.url)||[];
      payload.limit = null;
      payload.limitByProductByBuyer = null;
      payload.limitByOrderByBuyer = null;
      payload.limitByBuyer = null;
      payload.limitPerCustomer = null;
      payload.limitPerReferrer = null;
      payload.label = null;
      payload.code = null;
      payload.discount = {
        options: values.rules.map((r) => ({
            rate: toFloat(r.discount / 100),
            amount: parseInt(r.value, 10),
            operator: r.type
          })),
      };
    } else if (values.type === 'catalog') {
      payload.images = newMediaCatalog;
      payload.discount = { rate: 0 };
      payload.label = null;
      payload.code = null;
      payload.limit = null;
      payload.limitByProductByBuyer = null;
      payload.limitByOrderByBuyer = null;
      payload.limitByBuyer = null;
      payload.limitPerCustomer = null;
      payload.limitPerReferrer = null;
    } else if (values.type === 'money_voucher_on_order') {
      payload.images = newMedia.map((image) => image.url)||[];
      payload.label = values.label;
      payload.code = values.promoCode.toUpperCase();
      payload.limit = null;
      payload.limitByProductByBuyer = null;
      payload.limitByOrderByBuyer = (!values.hasLimitByOrder) ? parseInt(values.quantityByOrder, 10) : null;
      payload.limitByBuyer = (!values.hasLimitByBuyer) ? parseInt(values.quantityByBuyer, 10) : null;
      payload.limitPerCustomer = (!values.hasLimitUser) ? parseInt(values.limitUser, 10) : null;
      payload.limitPerReferrer = (!values.hasLimitReferrer) ? parseInt(values.limitReferrer, 10) : null;
      payload.discount = {
        options: values.rules_money.map((r) => ({
            amount: parseFloat(r.amount),
            value: parseFloat(r.value),
            operator: r.type
          })),
      };
    }

    const notificationName = payload.name;
    if (!currentPromotion || props.clone === true) {
      await crudAction(postPromotions.bind(null, payload), onClose,
        { ...crudOptions, name: notificationName, action: actions.CREATE }, t
      );
    } else {
      payload.status = currentPromotion.status;
      await crudAction(updatePromotion.bind(null, currentPromotion.id, payload), onClose,
        { ...crudOptions, name: notificationName, action: actions.UPDATE }, t
      );
    }
  };

  const renderAsset = (fileUrl, fileType) => {
    if (!fileType) return '';
    let result = "";
    if (mimeTypes[fileType]) fileType = mimeTypes[fileType];
    if (getAssetType(fileType) === 'application')
      result = <embed className={dealStyles["deal-image"]} key={`${fileUrl }x`} src={fileUrl} width="160px" height="240px" />;

    else if (getAssetType(fileType) === 'video')
      result = <video className={dealStyles["deal-image"]} width="160" height="112" src={fileUrl} controls>
        Your browser does not support the video tag.
      </video>;
    else if (getAssetType(fileType) === 'image')
      result = <img className={dealStyles["deal-image"]} src={fileUrl} />;

    if (result) result = <div> {result} </div>;
    return result;
  };

  const getIndicatorWidth = (filterType, content) => {
    let result = '';
    if (filterType === 'price range') {
      result = '120px';
      if (content?.length > 0) result = '160px';
    }
    return result;
  };

  const rulesModal = (fun, value, content) => <Formik enableReinitialize initialValues={formModalInitialValues} onSubmit={
      async (values) => {
        const labelObj = filterOperatorsArray.find((e) => e.value === values.type);
        const previus = content || [];
        const data = [...previus, { value: { ...values, operator: values.type }, label: `${labelObj.label} ${values.value}`, operator: values.type }];
        fun(value, data);
        dialogRef.current.handleClickClose();

      }
    } validationSchema={ModalSchema} autoComplete="off" >
      {({ isSubmitting, values, handleChange, handleBlur, setFieldValue, errors }) => (
        <Form className={themeStyles["form-wrap"]}>
          <div className={promoStyles["rules-dialog"]}>
            <label htmlFor="type" className={themeStyles["col-form-label"]}>{t('filter_label')}</label>
            <Grid container spacing={1}>
              <Grid item xs>
                <SearchSelect
                  onChange={(e) => e ? setFieldValue('type', e.value) : setFieldValue('type', '')
                  }
                  value={values.type}
                  options={filterOperatorsArray} placeholder={placeholder.newFilterOperator}
                  disabled={false}
                  menuPosition="fixed"
                  menuPlacement="auto"
                />
                {errors.type && errors.type ? (<div className={themeStyles.formError}>{errors.type}</div>) : ' '}
              </Grid>
              <Grid item xs>
                <Field className={themeStyles["form-control"]} name="value" placeholder={placeholder.name} value={values.value} onChange={handleChange} onBlur={handleBlur} type="number" />
                {errors.value && errors.value ? (<div className={themeStyles.formError}>{errors.value}</div>) : ' '}
              </Grid>
            </Grid>
            <Grid container spacing={1} className={promoStyles.submit}>
              <button type="submit" className={`${themeStyles.btn } ${ themeStyles["btn-secondary"]}`} disabled={isSubmitting} onClick={() => {
                // eslint-disable-next-line no-console
                console.log({ errors });
                setFieldValue('typeButton', submitType.update);
              }}>{
                  isSubmitting ? `${tr.pleaseWait}...` : tr.create
                }
              </button>
            </Grid>
          </div>
        </Form>
      )}
    </Formik>;

    const showRules = (fun, value, content) => {
      setDialogTitle(t('filter_title'));
      setDialogContent(rulesModal(fun, value, content));
      setDialogConfirm(true);
      dialogRef.current.handleClickOpen();
    };

    const succesCatalogMedia = useCallback((files, setFieldValue) => {
      const updatedMediaFiles = files.map(file => ({ file, url: URL.createObjectURL(file), type: 'product', source: 'manager' }));
      // TODO REVIEW this deps as it will probbably increase performance
      // eslint-disable-next-line react-hooks/exhaustive-deps
      catalogMediaArray = [...catalogMediaArray, ...updatedMediaFiles];
      setFieldValue('catalogMedia', catalogMediaArray);
    }, [catalogMediaArray]);

  return (
    <div>
      <ModalCloseButton onClick={() => onClose(true)} floating />
      <Grid container spacing={0} alignItems="flex-start" style={{ marginTop: "-30px" }}>
        <Grid item xs>
          <div className={themeStyles.currentContent}>
            <div className={promoStyles["blast-view"]}>
              <header className={themeStyles["header-form"]}>
                <h3>{!currentPromotion ? tr.createPromotion : currentPromotion.name}</h3>
              </header>
              {!currentPromotion ? (
                <div />
              ) : (
                <div>
                  <div className={themeStyles["date-created"]}>
                    <b>{tr.createdAt}: &nbsp;</b> {` ${ new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentPromotion.created_at))}`}
                  </div>
                  <div className={themeStyles["date-updated"]}>
                    <b>{tr.updatedAt}: &nbsp;</b> {` ${ new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentPromotion.updated_at))}`}
                  </div>
                </div>
              )}

              <div className={themeStyles["form-wrap"]}>
                <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formOnSubmit} validationSchema={tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.schema} autoComplete="off" >
                  {({ isSubmitting, values, handleChange, handleBlur, setFieldValue, setFieldTouched, errors, touched, validateForm, setFieldError }) => (
                    <Form>
                      <Grid container spacing={1} className={themeStyles["form-group"]}>
                        <Dialog ref={dialogRef} hideCancel hideConfirm content={dialogContent} alert confirm={dialogConfirm} confirmUpdate={setDialogResult} title={dialogTitle} />
                        <Grid item xs={12}>
                          <label htmlFor="type" className={themeStyles["col-form-label"]}>{t('Promotion Type or Catalog')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                          <SearchSelect
                            className='optionListContainer'
                            onChange={array => {
                              setFieldValue('type', array.value);
                              setPromoType(array.value);
                              setIsCodeValid(true);
                            }}
                            value={values.type}
                            onBlur={() => setFieldTouched('type', true)}
                            placeholder={placeholder.deals}
                            options={[
                              ...translatedSimpleOptions(tempPromotionTypes.data, true).map((a) => ({ 'label': a.label, 'value': a.value }))
                            ]}
                          />
                          {errors.type && touched.type ? (<div className={themeStyles.formError}>{errors.type}</div>) : ' '}
                        </Grid>
                      </Grid>
                      {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('name') ?
                        <Grid container spacing={1} className={themeStyles["form-group"]}>
                          <Grid item xs={12}>
                            <label htmlFor="name" className={themeStyles["col-form-label"]}>{(values.type === 'catalog') ? t('Catalog Name') : tr.name}<span className={themeStyles["label-asterisk"]}>*</span></label>
                            <Field className={themeStyles["form-control"]} name="name" placeholder={placeholder.name} value={values.name} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                            {errors.name && touched.name ? (<div className={themeStyles.formError}>{errors.name}</div>) : ' '}
                          </Grid>
                        </Grid>
                        : ''}
                      {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('media') ?
                        <Grid container spacing={1} className={themeStyles["form-group"]}>
                          <Grid item xs={12}>
                            {(values.media && values.media.length > 0) ? (
                              <div className={dealStyles["deal-file"]} >
                                <label htmlFor="file" className={themeStyles["col-form-label"]}>{t('Media Files')}</label>
                                {
                                  !values.media || values.media.length < 1 ? (' ') : (
                                    <Grid container spacing={3} justifyContent="center" alignItems="center" alignContent="center">
                                      {values.media.map((mediaObject, index) => (
                                        <Grid container item xs={4} key={index}>
                                          <div className={dealStyles["media-panel"]}>
                                            <div className={dealStyles["media-container"]}>
                                              {!mediaObject?.file ? renderAsset(mediaObject.url, getUrlExtension(mediaObject.url)) : renderAsset(mediaObject.url, mediaObject.file.type)}
                                            </div>
                                            <div className={dealStyles["media-attr"]}>
                                              <span className={dealStyles["media-filename"]}>
                                                <a href={!mediaObject?.file ? mediaObject.url : mediaObject.url} target="_blank" rel="noreferrer">{`Media ${ index + 1}`}</a>
                                                <i>
                                                  <Icon color="#000" size={13} icon="delete-" className={dealStyles["trash-icon"]} onClick={() => {
                                                    const filesCopy = [...mediaArray];
                                                    filesCopy.splice(index, 1);
                                                    mediaArray = [...filesCopy];
                                                    setFieldValue('media', mediaArray);
                                                  }} />
                                                </i>
                                              </span>
                                            </div>
                                          </div>
                                        </Grid>
                                      ))}
                                      {values.media.length % 3 === 1 ? <Grid item xs={8} /> : ''}
                                      {values.media.length % 3 === 2 ? <Grid item xs={4} /> : ''}
                                    </Grid>
                                  )
                                }
                              </div>
                            ) : ("")}
                            {values.media.length === 0 ?
                              <div className={dealStyles["upload-file-section"]}>
                                <label htmlFor="file" className={themeStyles["col-form-label"]}>{`${t('Upload File')} (${t('Optional')})`}<span className={dealStyles["media-file-size"]}>{t('maximumFileSize', { size: maximumFileSizeText })}</span></label>
                                <span className={themeStyles["search-description"]}>{t('asset_description')}</span>
                                <UploadFile
                                  successFunction={(files) => {
                                    const updatedMediaFiles = files.map(file => ({ file, url: URL.createObjectURL(file), type: 'product', source: 'manager' }));
                                    mediaArray = [...mediaArray, ...updatedMediaFiles];
                                    setFieldValue('media', mediaArray);
                                  }}
                                  errorFunction={(message) => {
                                    toast.error(`${t('Unable to upload asset') }: ${ message}`);
                                  }}
                                  accept={defaultUploadAccept}
                                />
                                {errors.file && touched.file ? (<div className={themeStyles.formError}>{errors.file}</div>) : ' '}
                                {errors.media ? (<div className={themeStyles.formError}>{errors.media}</div>) : ' '}
                              </div>
                              :
                              <div className={dealStyles["upload-file-section"]}>
                                {errors.file && touched.file ? (<div className={themeStyles.formError}>{errors.file}</div>) : ' '}
                                {errors.media ? (<div className={themeStyles.formError}>{errors.media}</div>) : ' '}
                              </div>
                            }
                          </Grid>
                        </Grid>
                        : ''}
                      {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('catalogMedia') ?
                        <Grid container spacing={1} className={themeStyles["form-group"]}>
                          <Grid item xs={12}>
                            {(values.catalogMedia && values.catalogMedia.length > 0) ? (
                              <div className={dealStyles["deal-file"]} >
                                <label htmlFor="file" className={themeStyles["col-form-label"]}>{t('Media Files')}</label>
                                {
                                  !values.catalogMedia || values.catalogMedia.length < 1 ? (' ') : (
                                    <Grid container spacing={3} justifyContent="center" alignItems="center" alignContent="center">
                                      {values.catalogMedia.map((mediaObject, index) => (
                                        <Grid container item xs={4} key={index}>
                                          <div className={dealStyles["media-panel"]}>
                                            <div className={dealStyles["media-container"]}>
                                              {!mediaObject?.file ? renderAsset(mediaObject.url, getUrlExtension(mediaObject.url)) : renderAsset(mediaObject.url, mediaObject.file.type)}
                                            </div>
                                            <div className={dealStyles["media-attr"]}>
                                              <label htmlFor="isMarketing">
                                                <input type="checkbox" name="isMarketing" checked={!((mediaObject.type === 'product' || mediaObject.type === 'flyer'))} onChange={
                                                  (e) => {
                                                    const value = e.target.checked;
                                                    const filesCopy = [...values.catalogMedia];
                                                    const obj = { ...filesCopy[index] };
                                                    obj.type = (value) ? 'marketing' : 'product';
                                                    filesCopy[index] = obj;
                                                    catalogMediaArray = [...filesCopy];
                                                    setFieldValue('catalogMedia', catalogMediaArray);
                                                  }
                                                } />
                                                <span>{t('Cover Page?')}</span>
                                              </label>
                                              <br />
                                              <label htmlFor="isFlyer">
                                                <input type="checkbox" name="isFlyer" checked={!((mediaObject.type === 'product' || mediaObject.type === 'marketing'))} onChange={
                                                  (e) => {
                                                    const value = e.target.checked;
                                                    const filesCopy = [...values.catalogMedia];
                                                    const obj = { ...filesCopy[index] };
                                                    obj.type = (value) ? 'flyer' : 'product';
                                                    filesCopy[index] = obj;
                                                    catalogMediaArray = [...filesCopy];
                                                    setFieldValue('catalogMedia', catalogMediaArray);
                                                  }
                                                } />
                                                <span>{t('Banner?')}</span>
                                              </label>
                                              <span className={dealStyles["media-filename"]}>
                                                <a href={!mediaObject?.file ? mediaObject.url : mediaObject.url} target="_blank" rel="noreferrer">{`Media ${ index + 1}`}</a>
                                                <i>
                                                  <Icon color="#000" size={13} icon="delete-" className={dealStyles["trash-icon"]} onClick={() => {
                                                    const filesCopy = [...values.catalogMedia];
                                                    filesCopy.splice(index, 1);
                                                    catalogMediaArray = [...filesCopy];
                                                    setFieldValue('catalogMedia', catalogMediaArray);
                                                  }} />
                                                </i>
                                              </span>
                                            </div>
                                          </div>
                                        </Grid>
                                      ))}
                                      {values.catalogMedia.length % 3 === 1 ? <Grid item xs={8} /> : ''}
                                      {values.catalogMedia.length % 3 === 2 ? <Grid item xs={4} /> : ''}
                                    </Grid>
                                  )
                                }
                              </div>
                            ) : ("")}
                            {values.catalogMedia.length < 3 ?
                              <div className={dealStyles["upload-file-section"]}>
                                <label htmlFor="file" className={themeStyles["col-form-label"]}>{t('Upload File')}<span className={dealStyles["media-file-size"]}>{t('maximumFileSize', { size: maximumFileSizeText })}</span></label>
                                <span className={themeStyles["search-description"]}>{t('asset_description')}</span>
                                <UploadFile
                                  successFunction={(file) => succesCatalogMedia(file, setFieldValue)}
                                  errorFunction={(message) => {
                                    toast.error(`${t('Unable to upload asset') }: ${ message}`);
                                  }}
                                  accept={defaultUploadAccept}
                                />
                                {errors.file && touched.file ? (<div className={themeStyles.formError}>{errors.file}</div>) : ' '}
                                {errors.catalogMedia ? (<div className={themeStyles.formError}>{errors.catalogMedia}</div>) : ' '}
                              </div>
                              :
                              <div className={dealStyles["upload-file-section"]}>
                                {errors.file && touched.file ? (<div className={themeStyles.formError}>{errors.file}</div>) : ' '}
                                {errors.catalogMedia ? (<div className={themeStyles.formError}>{errors.catalogMedia}</div>) : ' '}
                              </div>
                            }
                          </Grid>
                        </Grid>
                        : ''}
                      {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('rules') ?
                        <Grid container spacing={1} className={`${themeStyles["form-group"] } ${ promoStyles['rules-container']}`}>
                          <Grid item xs={12}>
                            <label htmlFor="rules" className={themeStyles["col-form-label"]}>{tr.rules}</label>
                            <div className={promoStyles["rules-description"]} >{(values.type === 'discount_on_order') ? t('rules_amount_discount') : t('rules_quantity_discount')}</div>
                            <FieldArray
                              name="rules"
                              render={arrayHelpers => {
                                const {rules} = values;
                                return (
                                  <div>
                                    {rules && rules.length > 0
                                      ? rules.map((rule, index) => (
                                        <Grid container spacing={1} key={index} className={`${themeStyles["form-group"] } ${ promoStyles['rules-inside-container']}`}>
                                          <Grid item xs={4}>
                                            <SearchSelect
                                              onChange={(e) => e ? setFieldValue(`rules[${index}].type`, e.value) : setFieldValue(`rules[${index}].type`, '')}
                                              value={rules[index].type}
                                              options={filterOperatorsArray} placeholder={placeholder.newFilterOperator}
                                              disabled={false}
                                              menuPosition="block"
                                              menuPlacement="auto"
                                              styles={{
                                                menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                                                menu: provided => ({ ...provided, zIndex: 9999 }),
                                                control: provided => ({ ...provided, width: 250 })
                                              }}
                                            />
                                            {errors && errors.rules && errors.rules[index] && errors.rules[index].type ? (<div className={themeStyles.formError}>{errors.rules[index].type}</div>) : ' '}
                                          </Grid>
                                          <Grid item xs={3}>
                                            <div className={`${promoStyles['discount-container'] } ${ (values.type === 'discount_on_order') ? `${promoStyles.amount } ${ promoStyles['amount-size']}` : ''}`}>
                                              <Field className={themeStyles["form-control"]} name={`rules.${index}.value`} placeholder='' value={rules[index].value} onChange={handleChange} onBlur={handleBlur} type="number" />
                                              {['discount_on_order', 'money_voucher_on_order'].includes(values.type) ?
                                                <span>$</span>
                                                : ''
                                              }
                                            </div>
                                            {errors && errors.rules && errors.rules[index] && errors.rules[index].value ? (<div className={themeStyles.formError}>{errors.rules[index].value}</div>) : ' '}
                                          </Grid>
                                          <Grid item xs={2}>
                                            <label htmlFor="type" className={themeStyles["col-form-label"]}>{t('Discount')}</label>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <div className={`${promoStyles['discount-container'] } ${ promoStyles.discount}`}>
                                              <Field className={themeStyles["form-control"]} name={`rules.${index}.discount`} placeholder='' value={rules[index].discount} onChange={handleChange} onBlur={handleBlur} type="number" />
                                              <span>%</span>
                                            </div>
                                            {errors && errors.rules && errors.rules[index] && errors.rules[index].discount ? (<div className={themeStyles.formError}>{errors.rules[index].discount}</div>) : ' '}
                                          </Grid>
                                          {values.rules.length > 1 ?
                                            <Grid item xs={1}>
                                              <i>
                                                <Icon color="#000" size={13} icon="cancel" onClick={() => {
                                                  if (values.rules.length > 1) {
                                                    arrayHelpers.remove(index);
                                                  }
                                                }} />
                                              </i>
                                            </Grid>
                                            : ''}
                                        </Grid>
                                      ))
                                      : ''
                                    }
                                    <div>
                                      <label htmlFor="dealsOption" className={`${themeStyles["col-form-label"] } ${ promoStyles['label-add-rule']}`} onClick={() => {
                                        arrayHelpers.push({
                                          type: "",
                                          value: 0,
                                          discount: 0
                                        });
                                      }}><u>{t('+ Add New Rule')}</u></label>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                        : ''}
                      {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('rules_money') ?
                        <Grid container spacing={1} className={`${themeStyles["form-group"] } ${ promoStyles['rules-container']}`}>
                          <Grid item xs={12}>
                            <label htmlFor="rules" className={themeStyles["col-form-label"]}>{tr.rules}</label>
                            <div className={promoStyles["rules-description"]} >{t('rules_amount_discount')}</div>
                            <FieldArray
                              name="rules_money"
                              render={arrayHelpers => {
                                const {rules_money} = values;
                                return (
                                  <div>
                                    {rules_money && rules_money.length > 0
                                      ? rules_money.map((rule_money, index) => (
                                        <Grid container spacing={1} key={index} className={`${themeStyles["form-group"] } ${ promoStyles['rules-inside-container']}`}>
                                          <Grid item xs={4}>
                                            <SearchSelect
                                              onChange={(e) => e ? setFieldValue(`rules_money[${index}].type`, e.value) : setFieldValue(`rules_money[${index}].type`, '')}
                                              value={rules_money[index].type}
                                              options={filterOperatorsArray} placeholder={placeholder.newFilterOperator}
                                              disabled={false}
                                              menuPosition="block"
                                              menuPlacement="auto"
                                              styles={{
                                                menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                                                menu: provided => ({ ...provided, zIndex: 9999 }),
                                                control: provided => ({ ...provided, width: 250 })
                                              }}
                                            />
                                            {errors && errors.rules_money && errors.rules_money[index] && errors.rules_money[index].type ? (<div className={themeStyles.formError}>{errors.rules_money[index].type}</div>) : ' '}
                                          </Grid>
                                          <Grid item xs={3}>
                                            <div className={`${promoStyles['discount-container'] } ${ (values.type === 'money_voucher_on_order') ? `${promoStyles.amount } ${ promoStyles['amount-size']}` : ''}`}>
                                              <Field className={themeStyles["form-control"]} name={`rules_money.${index}.amount`} placeholder='' value={rules_money[index].amount} onChange={handleChange} onBlur={handleBlur} type="number" />
                                              {['discount_on_order', 'money_voucher_on_order'].includes(values.type) ?
                                                <span>$</span>
                                                : ''
                                              }
                                            </div>
                                            {errors && errors.rules_money && errors.rules_money[index] && errors.rules_money[index].amount ? (<div className={themeStyles.formError}>{errors.rules_money[index].amount}</div>) : ' '}
                                          </Grid>
                                          <Grid item xs={2}>
                                            <label htmlFor="type" className={themeStyles["col-form-label"]}>{t('Discount')}</label>
                                          </Grid>
                                          <Grid item xs={3}>
                                            <div className={`${promoStyles['discount-container'] } ${ promoStyles.amount}`}>
                                              <Field className={themeStyles["form-control"]} name={`rules_money.${index}.value`} placeholder='' value={rules_money[index].value} onChange={handleChange} onBlur={handleBlur} type="number" />
                                              <span>$</span>
                                            </div>
                                            {errors && errors.rules_money && errors.rules_money[index] && errors.rules_money[index].value ? (<div className={themeStyles.formError}>{errors.rules_money[index].value}</div>) : ' '}
                                          </Grid>
                                          {values.rules_money.length > 1 ?
                                            <Grid item xs={1}>
                                              <i>
                                                <Icon color="#000" size={13} icon="cancel" onClick={() => {
                                                  if (values.rules_money.length > 1) {
                                                    arrayHelpers.remove(index);
                                                  }
                                                }} />
                                              </i>
                                            </Grid>
                                            : ''}
                                        </Grid>
                                      ))
                                      : ''
                                    }
                                    <div>
                                      <label htmlFor="dealsOption" className={`${themeStyles["col-form-label"] } ${ promoStyles['label-add-rule']}`} onClick={() => {
                                        arrayHelpers.push({
                                          type: "",
                                          value: 0,
                                          discount: 0
                                        });
                                      }}><u>{t('+ Add New Rule')}</u></label>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                        : ''}
                      {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('discount') ?
                        <Grid container spacing={1} className={themeStyles["form-group"]}>
                          <Grid item xs={6}>
                            <label htmlFor="discount" className={themeStyles["col-form-label"]}>{tr.discount}<span className={themeStyles["label-asterisk"]}>*</span></label>
                            <div className={`${promoStyles['discount-container'] } ${ (values.type !== 'money_voucher') ? promoStyles.discount : promoStyles.amount}`}>
                              <Field className={themeStyles["form-control"]} name="discount" placeholder={(values.type === 'money_voucher') ? t('applied value') : t('applied percentage')} value={values.discount} onChange={handleChange} onBlur={handleBlur} type="number" />
                              {(values.type !== 'money_voucher') ?
                                <span>%</span>
                                : ''}
                              {(values.type === 'money_voucher') ?
                                <span>$</span>
                                : ''}
                            </div>
                            {errors.discount && touched.discount ? (<div className={themeStyles.formError}>{errors.discount}</div>) : ' '}
                          </Grid>
                        </Grid>
                        : ''}
                      {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('limitContent') ?
                        <div className={promoStyles["promo-code-section"]}>
                        {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('code') ?
                          <div className={themeStyles["form-group"]}>
                            <div>
                              <label htmlFor="label" className={themeStyles["col-form-label"]}>{t('Promo Code')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                              <span className={themeStyles["search-description"]}>{t('code_description')}</span>
                            </div>
                            <div className={promoStyles["generate-promo-code-row"]}>
                              <div>
                                <Field className={themeStyles["form-control"]} name="promoCode" placeholder={t('Promo Code')} value={values.promoCode}
                                  onChange={
                                    // handleChange
                                    async (e) => {
                                      setFieldValue('promoCode', e.target.value.replace(/\s/g, ''));
                                      if (e.target.value.length > 0) {
                                        const isValid = await validateCode(e.target.value);
                                        setFieldValue('isCodeValid', isValid);
                                      } else {
                                        setFieldValue('isCodeValid', false);
                                      }
                                    }
                                  }
                                  onBlur={handleBlur} type="text"
                                />
                                {(values.promoCode) ?
                                  <Icon
                                    color={(values.isCodeValid && !errors.promoCode) ? "#00F700" : '#F21810'} size={16} icon={(values.isCodeValid && !errors.promoCode) ? 'check_circle' : 'cancel'} className={promoStyles.right}
                                  />
                                  : ''}
                                {errors.promoCode && touched.promoCode ? (<div className={themeStyles.formError}>{errors.promoCode}</div>) : ' '}
                                {errors.isCodeValid && !errors.promoCode ? (<div className={themeStyles.formError}>{errors.isCodeValid}</div>) : ' '}

                              </div>
                              <label
                                htmlFor='promoCode'
                                onClick={async () => {
                                const newCode = makeId(6).toUpperCase();
                                setFieldValue('promoCode', newCode);
                                const isValid = await validateCode(newCode);
                                setFieldValue('isCodeValid', isValid);
                              }}><ins>{t('Generate random code')}</ins></label>
                            </div>
                          </div>
                          : ''}
                        {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('only') ?
                            <div className={`${themeStyles["form-group"] } ${ promoStyles["quantity-promo-code-row"]}`}>
                                <div>
                                  <label htmlFor='Only To' className={themeStyles["col-form-label"]}>{t('Only To')}</label>
                                  <span className={themeStyles["search-description"]}>{t('OnlyTo_copy')}</span>
                                </div>
                                <div className={promoStyles["has-limit"]} style={{marginTop:"2px"}}>
                                  <label htmlFor='checkReferrerAndCustomer' style={{marginRight:"15px"}}>
                                    <input type="radio" name="checkReferrerAndCustomer" value="referrer" checked={values.checkReferrerAndCustomer === "referrer"} onChange={
                                    (e) => {
                                      setFieldValue('checkReferrerAndCustomer', e.target.value);
                                    }
                                  }/>
                                    <span>{` ${ t('Socio')}`}</span>
                                  </label>
                                  <label htmlFor='checkReferrerAndCustomer' style={{marginRight:"15px"}}>
                                    <input type="radio" name="checkReferrerAndCustomer" value="customer" checked={values.checkReferrerAndCustomer === "customer"} onChange={
                                    (e) => {
                                      setFieldValue('checkReferrerAndCustomer', e.target.value);
                                    }
                                  }/>
                                    <span>{` ${ t('Customer2')}`}</span>
                                  </label>
                                  <label htmlFor='checkReferrerAndCustomer'>
                                    <input type="radio" name="checkReferrerAndCustomer" value="all" checked={values.checkReferrerAndCustomer === 'all'} onChange={
                                    (e) => {
                                      setFieldValue('checkReferrerAndCustomer', e.target.value);
                                    }
                                  }/>
                                    <span>{` ${ t('SocioAndCustomer')}`}</span>
                                  </label>
                                </div>
                            </div>
                          : ''}
                        {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('limitByProduct') ?
                          <div className={`${themeStyles["form-group"] } ${ promoStyles["quantity-promo-code-row"]}`}>
                            <div>
                              <label htmlFor="quantityByProduct" className={themeStyles["col-form-label"]}>{t('LimitByProductsQuantity')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                              <span className={themeStyles["search-description"]}>{t('limit_copy_byProdcuts')}</span>
                            </div>
                            <div className={promoStyles.inputs}>
                              <div>
                                <Field className={themeStyles["form-control"]} min="1" disabled={values.hasLimitByProduct} name="quantityByProduct" placeholder={t('Quantity')} value={values.quantityByProduct} onChange={handleChange} onBlur={handleBlur} type="number" />
                                {errors.quantityByProduct && touched.quantityByProduct ? (<div className={themeStyles.formError}>{errors.quantityByProduct}</div>) : ' '}
                              </div>
                              <div>
                                <label htmlFor='hasLimitByProduct'>
                                  <input type="checkbox" name="hasLimitByProduct" checked={values.hasLimitByProduct || false} disabled={false} onChange={
                                    (e) => {
                                      setFieldValue('hasLimitByProduct', e.target.checked);
                                      setFieldValue('quantityByProduct', values.quantityByProduct);
                                      setTimeout(() => {
                                        validateForm().then((result) => {
                                          if (result) {
                                            Object.keys(result).forEach(key => {
                                              setFieldError(key, result[key]);
                                            });
                                          }
                                        });
                                      }, 100);
                                    }
                                  } />
                                  {` ${ t('Until promotion ends')}`}
                                </label>
                              </div>
                            </div>
                          </div>
                          : ''}
                        {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('limitByOrder') ?
                          <div className={`${themeStyles["form-group"] } ${ promoStyles["quantity-promo-code-row"]}`}>
                            <div>
                              <label htmlFor="quantityByOrder" className={themeStyles["col-form-label"]}>{t('LimitByOrdersQuantity')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                              <span className={themeStyles["search-description"]}>{t('limit_copy_byOrders')}</span>
                            </div>
                            <div className={promoStyles.inputs}>
                              <div>
                                <Field className={themeStyles["form-control"]} min="1" disabled={values.hasLimitByOrder} name="quantityByOrder" placeholder={t('Quantity')} value={values.quantityByOrder} onChange={handleChange} onBlur={handleBlur} type="number" />
                                {errors.quantityByOrder && touched.quantityByOrder ? (<div className={themeStyles.formError}>{errors.quantityByOrder}</div>) : ' '}
                              </div>
                              <div>
                                <label htmlFor='hasLimitByOrder'>
                                  <input type="checkbox" name="hasLimitByOrder" checked={values.hasLimitByOrder || false} disabled={false} onChange={
                                    (e) => {
                                      setFieldValue('hasLimitByOrder', e.target.checked);
                                      setFieldValue('quantityByOrder', values.quantityByOrder);
                                      setTimeout(() => {
                                        validateForm().then((result) => {
                                          if (result) {
                                            Object.keys(result).forEach(key => {
                                              setFieldError(key, result[key]);
                                            });
                                          }
                                        });
                                      }, 100);
                                    }
                                  } />
                                  {` ${ t('Until promotion ends')}`}
                                </label>
                              </div>
                            </div>
                          </div>
                          : ''}
                          {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('limitByBuyer') ?
                          <div className={`${themeStyles["form-group"] } ${ promoStyles["quantity-promo-code-row"]}`}>
                            <div>
                              <label htmlFor="quantityByBuyer" className={themeStyles["col-form-label"]}>{t('LimitByBuyer')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                              <span className={themeStyles["search-description"]}>{t('limit_copy_byBuyer')}</span>
                            </div>
                            <div className={promoStyles.inputs}>
                              <div>
                                <Field className={themeStyles["form-control"]} min="1" disabled={values.hasLimitByBuyer} name="quantityByBuyer" placeholder={t('Quantity')} value={values.quantityByBuyer} onChange={handleChange} onBlur={handleBlur} type="number" />
                                {errors.quantityByBuyer && touched.quantityByBuyer ? (<div className={themeStyles.formError}>{errors.quantityByBuyer}</div>) : ' '}
                              </div>
                              <div>
                                <label htmlFor='hasLimitByBuyer'>
                                  <input type="checkbox" name="hasLimitByBuyer" checked={values.hasLimitByBuyer || false} disabled={false} onChange={
                                    (e) => {
                                      setFieldValue('hasLimitByBuyer', e.target.checked);
                                      setFieldValue('quantityByBuyer', values.quantityByBuyer);
                                      setTimeout(() => {
                                        validateForm().then((result) => {
                                          if (result) {
                                            Object.keys(result).forEach(key => {
                                              setFieldError(key, result[key]);
                                            });
                                          }
                                        });
                                      }, 100);
                                    }
                                  } />
                                  {` ${ t('Until promotion ends')}`}
                                </label>
                              </div>
                            </div>
                          </div>
                          : ''}
                          {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('limit') ?
                            <div>
                              <div className={`${themeStyles["form-group"] } ${ promoStyles["quantity-promo-code-row"]}`}>
                                <div>
                                  <label htmlFor="limitReferrer" className={themeStyles["col-form-label"]}>{t('VoucherLimit')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <span className={themeStyles["search-description"]}>{t('limit_copy_Voucher')}</span>
                                </div>
                                <div className={promoStyles.inputs}>
                                  <div>
                                    <Field className={themeStyles["form-control"]} min="1" disabled={values.hasLimitReferrer} name="limitReferrer" placeholder={t('Limit')} value={values.limitReferrer} onChange={handleChange} onBlur={handleBlur} type="number" />
                                    {errors.limitReferrer && touched.limitReferrer ? (<div className={themeStyles.formError}>{errors.limitReferrer}</div>) : ' '}
                                  </div>
                                  <div>
                                    <label htmlFor='hasLimitReferrer'>
                                      <input type="checkbox" name="hasLimitReferrer" checked={values.hasLimitReferrer || false} disabled={false} onChange={
                                        (e) => {
                                          setFieldValue('hasLimitReferrer', e.target.checked);
                                          setFieldValue('limitReferrer', values.limitReferrer);
                                          setTimeout(() => {
                                            validateForm().then((result) => {
                                              if (result) {
                                                Object.keys(result).forEach(key => {
                                                  setFieldError(key, result[key]);
                                                });
                                              }
                                            });
                                          }, 100);
                                        }
                                      } />
                                      {` ${ t('Until promotion ends')}`}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            : ''}
                        </div>
                        : ''}
                      {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('label') ?
                        <Grid container spacing={1} className={themeStyles["form-group"]}>
                          <Grid item xs={12}>
                            <label htmlFor="label" className={themeStyles["col-form-label"]}>{tr.label}<span className={themeStyles["label-asterisk"]}>*</span></label>
                            <span className={themeStyles["search-description"]}>{t('label_description')}</span>
                            <Field className={themeStyles["form-control"]} name="label" placeholder="" value={values.label} onChange={handleChange} onBlur={handleBlur} type="text" />
                            {errors.label && touched.label ? (<div className={themeStyles.formError}>{errors.label}</div>) : ' '}
                          </Grid>
                        </Grid>
                        : ''}
                      {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('dates') ?
                        <Grid container spacing={1} className={themeStyles["form-group"]}>
                          <Grid item xs={6}>
                            <label htmlFor="startAt" className={themeStyles["col-form-label"]}>{t('Time frame')}:<span className={themeStyles["label-asterisk"]}>*</span></label>
                          </Grid>
                          <Grid item xs={6}>
                            <label htmlFor='onGoing'>
                              <input type="checkbox" name="onGoing" checked={values.onGoing || false} disabled={false} onChange={
                                (e) => {
                                  setFieldValue('onGoing', e.target.checked);
                                  if (e.target.checked) {
                                    setFieldValue('expiresAt', '');
                                  }
                                }
                              } />
                              {` ${ t('Ongoing')}`}
                            </label>
                          </Grid>
                          <Grid container spacing={1} item xs={12} className={promoStyles["time-inputs"]}>
                            <Grid item xs={6}>
                              <DatePickerField popperProps={{
                                positionFixed: true,
                                strategy: 'fixed'
                              }} className={promoStyles.datetime} name="startAt" dateFormat="yyyy-MM-dd hh:mm a" value={values.startAt} showTimeSelect timeIntervals={1} autoComplete="off" showTime={{ user12hours: true }} tabIndex={1} />
                              {errors.startAt && touched.startAt ? (<div className={themeStyles.formError}>{errors.startAt}</div>) : ' '}
                              <label htmlFor="startAt" className={themeStyles["col-form-label"]}>{t('Start')}<span className={themeStyles["label-separator"]}>|</span></label>
                              <Icon color="#CCC" size={16} icon="calendar-1" />
                            </Grid>
                            <Grid item xs={6}>
                              <label htmlFor="expiresAt" className={themeStyles["col-form-label"]}>{t('End')}<span className={themeStyles["label-separator"]}>|</span></label>
                              <Icon color="#CCC" size={16} icon="calendar-1" />
                              <DatePickerField popperProps={{
                                positionFixed: true,
                                strategy: 'fixed'
                              }} readOnly={values.onGoing} className={promoStyles.datetime} name="expiresAt" dateFormat="yyyy-MM-dd hh:mm a" value={values.expiresAt} showTimeSelect timeIntervals={1} autoComplete="off" showTime={{ user12hours: true }} />
                              {errors.expiresAt && touched.expiresAt ? (<div className={themeStyles.formError}>{errors.expiresAt}</div>) : ' '}
                            </Grid>
                          </Grid>
                        </Grid>
                        : ''}
                      {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('dealsOption') ?
                        <div>
                          <Grid container spacing={1} className={themeStyles["form-group"]}>
                            <Grid item xs={12}>
                              <label htmlFor="dealsOption" className={themeStyles["col-form-label"]}>{t('Deals for promotion')}:<span className={themeStyles["label-asterisk"]}>*</span></label>
                            </Grid>
                            <Grid item xs={4}>
                              <label htmlFor='dealsOption'>
                                <input type="radio" name="dealsOption" value="all" onChange={
                                  (e) => {
                                    setFieldValue('dealsOption', e.target.value);
                                    // Clean all deals exception
                                    const exceptionObj = [...values.exceptions].map((a) => (a.type === 'deals') ? { type: 'deals', value: [] } : a);
                                    setFieldValue(`exceptions`, exceptionObj);
                                  }
                                } checked={values.dealsOption === 'all'} />
                                {` ${ t('All deals')}`}
                              </label>
                            </Grid>
                            <Grid item xs={4}>
                              <label htmlFor='dealsOption'>
                                <input type="radio" name="dealsOption" value="deny_list" onChange={
                                  (e) => {
                                    setFieldValue('dealsOption', e.target.value);
                                  }
                                } checked={values.dealsOption === 'deny_list'} />
                                {` ${ t('All deals with exceptions')}`}
                              </label>
                            </Grid>
                            <Grid item xs={4}>
                              <label htmlFor='dealsOption'>
                                <input type="radio" name="dealsOption" value="allow_list" onChange={
                                  (e) => {
                                    setFieldValue('dealsOption', e.target.value);
                                  }
                                } checked={values.dealsOption === 'allow_list'} />
                                {` ${ t('Specific deals')}`}
                              </label>
                            </Grid>
                          </Grid>
                          <FieldArray
                            name="exceptions"
                            render={arrayHelpers => {
                              const {exceptions} = values;

                              return (
                                <div>
                                  {exceptions && exceptions.length > 0
                                    ? exceptions.map((rule, index) => (
                                      <Grid container spacing={1} key={index} className={`${themeStyles["form-group"] } ${ promoStyles['rules-inside-container']}`}>
                                        <Grid item xs={3}>
                                          <SearchSelect
                                            className='optionListContainer'
                                            onChange={array => {
                                              setFieldValue(`exceptions[${index}].value`, []);
                                              return array ? setFieldValue(`exceptions[${index}].type`, array.value) : setFieldValue(`exceptions[${index}].type`, '');
                                            }}
                                            value={exceptions[index].type || 'deals'}
                                            onBlur={() => setFieldTouched(`exceptions[${index}].type`, true)}
                                            placeholder={placeholder.deals}
                                            options={[
                                              ...translatedSimpleOptions(tempFilterTypes.data, true).map((a) => ({ 'label': a.label, 'value': a.value }))
                                            ]}
                                            disabled={(index === 0)}
                                          />
                                        </Grid>
                                        <Grid item xs={8}>
                                          <div className={promoStyles["deals-rules"]}>
                                            <SearchSelect
                                              className='optionListContainer'
                                              onChange={array => {
                                                let aux = [];
                                                array.forEach(x => {
                                                  if (x.deals && x.deals.length > 0) {
                                                    const deals = formatDealsArray(x.deals);
                                                    aux = [...deals, ...aux];
                                                  } else {
                                                    aux.push(x);
                                                  }
                                                });
                                                aux = deleteDuplicates(aux);
                                                setCurrentExceptions(aux);
                                                setFieldValue(`exceptions[${index}].value`, aux);
                                              }}
                                              value={exceptions[index].value}
                                              loadOptions={values.exceptions[index].type === 'categories' ? returnCategories : values.dealsOption !== 'all' ? returnDeals : () => []}
                                              placeholder={values.exceptions[index].type === 'price range' ? t('Add Rule') : values.exceptions[index].type === 'deals' ? placeholder.deals : placeholder.categories}
                                              isOptionDisabled={(option) => option.disabled}
                                              disabled={values.dealsOption === 'all' && values.exceptions[index].type === 'deals'}
                                              isMulti
                                              indicatorContainerWidth={getIndicatorWidth(values.exceptions[index].type, exceptions[index].value)}
                                            />
                                            {values.exceptions[index].type === 'price range' ?
                                              <label htmlFor="dealsOption" className={themeStyles["col-form-label"]} onClick={() => {
                                                showRules(setFieldValue, `exceptions[${index}].value`, values.exceptions[index].value);
                                              }}>{t('Add Rule')}</label>
                                              : ''}
                                          </div>
                                          {errors.exceptions && touched.exceptions && errors.exceptions[index] ? (<div className={themeStyles.formError}>{
                                            errors.exceptions[index].value
                                          }</div>) : ' '}
                                        </Grid>
                                        {index > 0 ?
                                          <Grid item xs={1}>
                                            <i>
                                              <Icon color="#000" size={13} icon="cancel" onClick={() => {
                                                if (values.exceptions.length > 1) {
                                                  arrayHelpers.remove(index);
                                                }
                                              }} />
                                            </i>
                                          </Grid>
                                          : <Grid item xs={1} />}
                                      </Grid>
                                    ))
                                    : ''
                                  }
                                  <div className={promoStyles['add-rule-label']}>
                                    <label htmlFor="dealsOption" className={`${themeStyles["col-form-label"] } ${ promoStyles['label-add-rule']}`} onClick={() => {
                                      arrayHelpers.push({
                                        type: "deals",
                                        value: []
                                      });
                                    }}><u>{t('Add New Rule')}</u></label>
                                  </div>
                                </div>
                              );
                            }}
                          />
                        </div>
                        : ''}
                      {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('allowCombo') ?
                        <Grid container spacing={1} className={themeStyles["form-group"]}>
                          <Grid item xs={6}>
                            <label htmlFor="isAllowCombo" className={themeStyles["col-form-label"]}>{t('Allow combos')}</label>
                            <ToggleCheck value={values.isAllowCombo} onChange={(event) => {
                              setFieldValue('isAllowCombo', event.target.checked);
                            }} />
                          </Grid>
                        </Grid>
                      : ''}
                      {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('group') ?
                        <Grid container spacing={1} className={themeStyles["form-group"]}>
                          <Grid item xs={12}>
                            <label htmlFor="groups" className={themeStyles["col-form-label"]}>{tr.groups}</label>
                            <SearchSelect
                              isMulti
                              className='optionListContainer'
                              onChange={field => {
                                if (!field) {
                                  setFieldValue('groups', []);
                                } else {
                                  setFieldValue('groups', field);
                                }
                              }}
                              value={values.groups}
                              onBlur={() => setFieldTouched('groups', true)}
                              loadOptions={returnGroups}
                              placeholder={placeholder.groups}
                            />
                            {errors.groups && touched.groups ? (<div className={themeStyles.formError}>{errors.groups}</div>) : ' '}
                          </Grid>
                        </Grid>
                        : ''}
                      {tempPromotionTypesObj?.data?.find((e) => e.key === promoType)?.field?.includes('toggle') ?
                        <Grid container spacing={1} className={themeStyles["form-group"]}>
                          <Grid item xs={6}>
                            <label htmlFor="toggle" className={themeStyles["col-form-label"]}>{tr.toggle}</label>
                            <ToggleCheck value={values.visibility} onChange={(event) => {
                              setFieldValue('visibility', event.target.checked);
                            }} />
                          </Grid>
                        </Grid>
                        : ''}
                      <div className={themeStyles["form-crud-actions"]}>
                        <button type="submit" className={`${themeStyles.btn } ${ themeStyles["btn-secondary"]}`} disabled={isSubmitting || !isCodeValid} onClick={() => {
                          // eslint-disable-next-line no-console
                          console.log({ errors });
                          setFieldValue('typeButton', submitType.update);
                          setFieldTouched('deals', true);
                        }}>{
                            isSubmitting ? `${tr.pleaseWait}...` : (!currentPromotion || props.clone === true) ? tr.create : tr.update
                          }</button>
                      </div>
                      <FormikErrorFocus offset={0} align="top" duration={1000} />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation()(PromotionCreate);