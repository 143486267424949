/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Icon from '../Icon';
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Fade, Modal } from '@mui/material';
import { withTranslation } from 'react-i18next';


const BasicModal = ({ t, options = {}, onClose = () => { }, modalSize, typeButton, content: Content, loading, ...props }) => {
  const { label, html, clone, filters } = options;
  const actionLabel = loading ? <CircularProgress size="1rem" color="primary" /> : (label || t('Create'));

  const modalSizes = {
    'xxs': { maxWidth: '312px' },
    'xs': { maxWidth: '400px' },
    'sm': { maxWidth: '691px' },
    'md': { maxWidth: '800px' },
    'lg': { maxWidth: '1200px' }
  };
  
  let modalProp = modalSizes.md;
  
  modalProp = (modalSize) ? modalSizes[modalSize] : modalProp;
  
  const useStyles = (/* modalSize */) => makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    button: {
      color: '#767676',
      border: '2px solid #39DECB'
    },
    paper: {
      width: modalProp.maxWidth,
      maxHeight: '80%',
      overflow: 'auto',
      borderRadius: '5px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 3, 3),
      overflowX: 'hidden'
    },
  }));

  
  const classes = useStyles(modalSize)();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => { setOpen(true); };

  const handleClose = (justClose=false) => {
    if(justClose) {
      setOpen(false);
    }else{
      setOpen(false);
      onClose();
    }
  };

  const renderOpenModelButton = () => {
    
    let htmlToRender;
    
    switch (typeButton) {
      case 'export':
        htmlToRender = <Button disabled={loading} variant="contained" color="secondary" onClick={handleOpen}>
          <Icon color="#000000" size={20} icon="cloud_down-2" />
        </Button>;
        break;
      case 'create':
        htmlToRender = <Button disabled={loading} variant="contained" color="secondary" onClick={handleOpen}>{actionLabel}</Button>;
        break;
      case 'bulk':
        htmlToRender = <Button disabled={loading} className={classes.button} variant="outlined" color="secondary" onClick={handleOpen}>{actionLabel}</Button>;
        break;
      case 'bulkUpdate':
        htmlToRender = <Button disabled={loading} className={classes.button} variant="outlined" color="secondary" onClick={handleOpen}>
          <Icon color="#000000" size={20} icon="cloud_up-2" />
        </Button>;
        break;
      case 'html':
      default:
        htmlToRender = <span className="clickable-span" onClick={(e) => { e.preventDefault(); handleOpen(); }}>{html}</span>;
        break;
    }

    return htmlToRender;
  };

  return (
    <div>
      {renderOpenModelButton()}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <div style={{position: "relative"}} className={classes.paper}>
            {/* <div style={{
                position: "absolute",
                background: "white",
                width: "100%",
                height: "90%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center"
              }}>
                <CircularProgress />
              </div> */}
            <Content onClose={handleClose} clone={clone} filters={filters} {...props} />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default withTranslation()(BasicModal);