import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContainerReferrersListing from '../../components/shared-comp/referrers/views/listing';
import ReferrersActions from '../../components/shared-comp/referrers/model/actions';
import WorkflowActions from '../../redux/workflow/actions';

const mapStateToProps = (state) => ({
  referrersLoading: state.referrers.referrersLoading,
  referrersError: state.referrers.referrersError,
  referrersCalled: state.referrers.referrersCalled,
  referrers: state.referrers.referrers,
  createReferrerLoading: state.referrers.createReferrerLoading,
  createReferrerError: state.referrers.createReferrerError,
  createdReferrer: state.referrers.createdReferrer,
  country: state.workflow.country,
  countries: state.workflow.countries,
});


const mapDispatchToProps = (dispatch) => ({
  createReferrer: bindActionCreators(ReferrersActions.createReferrer, dispatch),
  deleteReferrer: bindActionCreators(ReferrersActions.deleteReferrer, dispatch),
  fetchReferrers: bindActionCreators(ReferrersActions.fetchReferrers, dispatch),
  fetchReferrersFailed: bindActionCreators(ReferrersActions.fetchReferrersFailed, dispatch),
  fetchReferrersRequest: bindActionCreators(ReferrersActions.fetchReferrersRequest, dispatch),
  fetchReferrersSuccess: bindActionCreators(ReferrersActions.fetchReferrersSuccess, dispatch),
  getReferrer: bindActionCreators(ReferrersActions.getReferrer, dispatch),
  fetchCountries: bindActionCreators(WorkflowActions.fetchCountries, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerReferrersListing);