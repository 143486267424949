import React, { useEffect, useState } from 'react';
import Store, { persistor } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Root from './pages/Root';
import CacheBuster from './CacheBuster';
import mock from './services/mocks';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = () => {

  const [showUpdateMessage, setShowUpdateMessage] = useState(false);

  useEffect( () => {
    if(process.env.REACT_APP_SHOULD_MOCK_API_CALLS) {
      console.log('mocking configurated api calls');
      mock();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer position="bottom-right" hideProgressBar closeOnClick={false} theme="colored" />
        <CacheBuster>
            {({ loading, isLatestVersion }) => {
              if (loading) return null;
              setShowUpdateMessage(!loading && !isLatestVersion);
              return (
                <Provider store={Store}>
                    <PersistGate loading={null} persistor={persistor}>
                      <Root showUpdateMessage={showUpdateMessage}/>
                    </PersistGate>
                  </Provider>
              );
            }}
        </CacheBuster>
    </ThemeProvider>
  );
};

export default Container;
