/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import Icon from '../Icon';
import { withTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import styles from './styles.module.sass';

const MainSidebar = ({ t, LinkProp, addMenuItems, country, countries, countriesLoading, setCountry, fetchCountries, fetchUserInfo, userInfo, userInfoLoading, parentVersion }) => {
  
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [userCountries, setUserCountries] = useState([]);
  const [availableCountries, setAvailableCountries] = useState([]);

  const userId = userInfo?.data?.id;
  let currentlyStoredCountry = localStorage.getItem('selectedCountry');

  const updateCountry = useCallback((newCountry) => {
    if (userId) {
      localStorage.setItem('selectedCountry', newCountry);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      currentlyStoredCountry = newCountry;
    }
    if (newCountry !== country) setCountry(newCountry);
  }, [country, setCountry, userId]);

  useEffect(() => {
    if (!userInfo && !userInfoLoading) fetchUserInfo();
    if (!countries && !countriesLoading) fetchCountries();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userInfo?.data?.countries) setUserCountries(userInfo.data.countries);
  }, [userInfo]);

  useEffect(() => {
    if (countries?.data?.length && countries?.data?.length > 0 && userCountries?.length && userCountries.length > 0) setAvailableCountries(userCountries);
  }, [userCountries, countries]);

  // If no country has been specified, try to set localStorage country or user country, otherwise use 1st in array
  useEffect(() => {
    if (!country && availableCountries?.length && availableCountries.length > 0) {
      if (currentlyStoredCountry && availableCountries.includes(currentlyStoredCountry)) updateCountry(currentlyStoredCountry);
      else if (userInfo?.data?.country && availableCountries.includes(userInfo?.data?.country)) updateCountry(userInfo?.data?.country);
      else updateCountry(availableCountries[0]);
    }
  }, [availableCountries, country, currentlyStoredCountry, updateCountry, userInfo?.data?.country]);

  const changeToggleSidebar = () => {
    setToggleSidebar(!toggleSidebar);
  };

  const userPage = {
    label: t('User', { count: 100 }),
    module: 'USERS',
    url: '/users',
    icon: 'profile-'
  };

  const mainItems = [
    {
      groupName: t('Manage'),
      options: [
        {
          label: t('Deal', { count: 100 }),
          module: 'DEALS',
          url: '/deals',
          icon: 'tag_round-'
        },
        {
          label: t('Blast', { count: 100 }),
          module: 'BLASTS',
          url: '/blast',
          icon: 'megaphone-1'
        },
        {
          label: t('Order', { count: 100 }),
          module: 'ORDERS',
          url: '/orders',
          icon: 'fileboard_checklist-1'
        },
        {
          label: t('Referrer', { count: 100 }),
          module: 'TENDEROS',
          url: '/referrers',
          icon: 'shop_center-'
        },
        {
          label: t('Customer', { count: 100 }),
          module: 'CUSTOMERS',
          url: '/customers',
          icon: 'profile-'
        },
        {
          label: t('Group', { count: 100 }),
          module: 'GROUPS',
          url: '/groups',
          icon: 'connection_pattern-2'
        }
      ]
    },
    
    {
      groupName: t('Transaction'),
      options: [
        {
          label: t('Commission', { count: 100 }),
          module: 'COMMISSIONS',
          url: '/commissions',
          icon: 'tag_round-'
        },
        // {
        //   label: t('Payout', { count: 100 }),
        //   module: 'PAYOUTS',
        //   url: '/payouts',
        //   icon: '1105'
        // },
        {
          label: t('PromotionSection', { count: 100 }),
          module: 'PROMOTIONS',
          url: '/promotions',
          icon: 'tag_round-'
        },
      ]
    },
    {
      groupName: t('Research'),
      options: [
        {
          label: t('Product', { count: 100 }),
          module: 'PRODUCTS',
          url: '/products',
          icon: 'database_system-3'
        },
        {
          label: t('Trending'),
          module: 'TRENDING',
          url: '/trending',
          icon: 'bookmark-2'
        },
        {
          label: t('Recomendations'),
          module: 'TRENDING',
          url: '/recomendations',
          icon: 'stats-9'
        }
      ]
    }
  ];
  
  if(userInfo?.data?.type === "admin" || userInfo?.data?.type === "superAdmin") mainItems[0]?.options?.splice(3, 0, userPage);

  if (addMenuItems) mainItems.push(addMenuItems);
  
  return (
    <aside className={`${styles['main-sidebar'] } ${ toggleSidebar ? styles['__main-sidebar-toggled'] : null}`} >
      <section className={styles['main-sidebar__container']}>
        <header className={styles['main-sidebar__header']}>
          <div className={styles.title}>
            <a className={styles.logo} href="/">
              <Icon color="#ffffff" size={90} icon="logo-wao" />
            </a>
            <div className={styles["country-container"]} >
              <select value={country || userInfo?.data?.country} className={`${styles["country-selector"] } ${ availableCountries?.length && availableCountries?.length > 1 ? styles.enabled : ''}`} disabled={(availableCountries?.length && availableCountries.length <= 1)}
                onChange={(e) => { updateCountry(e.target?.value); }}
              >
                {availableCountries ? availableCountries.map((countryCode) => <option key={countryCode} value={countryCode}>{countryCode}</option>): ''};
              </select>
            </div>
            <div className={styles.break} />
          </div>
          <div className={styles['sidebar-toggle']}>
            <span onClick={changeToggleSidebar}>
              <Icon color="#ffffff" size={15} icon="arrow_left" />
            </span>
          </div>
        </header>
        <section className={styles['sidebar-content']}>
          {!userInfo ? '' :
            <nav className={styles['sidebar-nav']}>
              {
                mainItems?.map((groupItems) => (<div key={groupItems.groupName}>
                    <b>{groupItems.groupName}</b>
                    {groupItems.options.map((item) => {
                      if (LinkProp) {
                        return (
                          <LinkProp to={{ pathname: item.url }} key={item.url} className={({ isActive }) => (isActive ? styles.__current : "")}>
                            <i><Icon color="#ffffff" size={20} icon={item.icon} /></i>
                            <span>{item.label}</span>
                          </LinkProp>
                        );
                      }
                        return (
                          <a href={item.url} key={item.url} /* activeClassName={styles['__current']} */>
                            <i><Icon color="#ffffff" size={20} icon={item.icon} /></i>
                            <span>{item.label}</span>
                          </a>
                        );
                      
                    })}
                  </div>))
              }

            </nav>
          }
        </section>
        <footer className={styles['main-sidebar__footer']}>
          <div className={styles.user}>
            <i><Icon color="#ffffff" size={14} icon="user-circle-o" /></i>
            {userInfo?.data?.email}
          </div>
          <div className={styles['d-flex']}>
            <nav className={styles['log-item']}>
              <a href="./logout" key="1">
                <i><Icon color="#ffffff" size={20} icon="arrow_in_right-" /></i>
              </a>
            </nav>
            <div className={styles['nav-country']}>
              <div className="__lang-area">
                <button key="1" onClick={() => {
                  i18n.changeLanguage('es');
                }}>es</button>
                <span>|</span>
                <button onClick={() => {
                  i18n.changeLanguage('en');
                }}>en</button>
              </div>
            </div>
            <div className={styles['version-label']}>
              {parentVersion ? <span>v{parentVersion}</span> : ''}
            </div>
          </div>
        </footer>
      </section>
    </aside>
  );
};

export default withTranslation()(MainSidebar);
