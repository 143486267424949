/* eslint-disable no-promise-executor-return */
import api from './api';

export const setHeader = () => {
  console.log(localStorage.getItem('token-firebase'));
  api.setHeader('Authorization', `Bearer ${ localStorage.getItem('token-firebase')}`);
};

export const storeFirebaseToken = token => {
  localStorage.setItem('token-firebase', token);
  setHeader();
};

export const setCountry = countryCode => {
  api.setHeader('wao-country', countryCode);
};

const countries = [
  // { name: "United States", code: "US" },
  { name: "Colombia", code: "CO" },
  // { name: "Guatemala", code: "GT" },
  { name: "Argentina", code: "AR" },
  { name: "Ecuador", code: "EC" },
  { name: "México", code: "MX" }
];

export const getCountries = () => new Promise((resolve) => resolve({
      "ok": true, "data": {
        "data": countries
      }
    }));