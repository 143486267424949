import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContainerCustomersListing from '../../components/shared-comp/customers/views/listing';
import CustomersActions from '../../components/shared-comp/customers/model/actions';
import WorkflowActions from '../../redux/workflow/actions';

const mapStateToProps = (state) => ({
  customersLoading: state.customers.customersLoading,
  customersError: state.customers.customersError,
  customersCalled: state.customers.customersCalled,
  customers: state.customers.customers,
  createCustomerLoading: state.customers.createCustomerLoading,
  createCustomerError: state.customers.createCustomerError,
  createdCustomer: state.customers.createdCustomer,
  country: state.workflow.country,
  countries: state.workflow.countries,
});


const mapDispatchToProps = (dispatch) => ({
  createCustomer: bindActionCreators(CustomersActions.createCustomer, dispatch),
  deleteCustomer: bindActionCreators(CustomersActions.deleteCustomer, dispatch),
  fetchCustomers: bindActionCreators(CustomersActions.fetchCustomers, dispatch),
  fetchCustomersFailed: bindActionCreators(CustomersActions.fetchCustomersFailed, dispatch),
  fetchCustomersRequest: bindActionCreators(CustomersActions.fetchCustomersRequest, dispatch),
  fetchCustomersSuccess: bindActionCreators(CustomersActions.fetchCustomersSuccess, dispatch),
  getCustomer: bindActionCreators(CustomersActions.getCustomer, dispatch),
  fetchCountries: bindActionCreators(WorkflowActions.fetchCountries, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(ContainerCustomersListing);
