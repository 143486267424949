/* eslint-disable no-shadow */
/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { modules } from '../../../utils';
import DealsCreate from '../../../deals/views/forms/DealsCreate';
import BasicModal from '../../../core/BasicModal';
import themeStyles from '../../../../../theme.module.sass';
import { createItem } from '../../../core/Menu';
import { getCountry, getCurrencyByCountry } from '../../../core/services/workflow';
import ListView from '../../../core/ListView';

const RecomendationListing = ({ t, recomendations, recomendationsLoading, fetchRecomendations, country, countries, fetchCountries }) => {
  const module = modules(t).recomendations;
  
  const recomendationsData = useMemo(()=> ({
    data: recomendations?.data?.recomended_products,
    paging: {
      limit: Number(recomendations?.data?.pagination?.perPage),
      page: Number(recomendations?.data?.pagination?.currentPage),
      totalItems: Number(recomendations?.data?.pagination?.total),
      totalPages: Number(recomendations?.data?.pagination?.lastPage)
    },
    message: recomendations?.data?.message,
    statusCode: recomendations?.data?.statusCode
   }),[recomendations]);

  const [externalRefresh, setExternalRefresh] = useState(0);
  const headerCountry = getCountry();
  const initialCurrency =  getCurrencyByCountry(headerCountry) || "";
  const [currencyValues, setCurrencyValues] = useState([]);



  const tableColumns = [
    {label:'Name', alignment: 'left'},
    {label:'Source', alignment: 'left'},
    {label:'Category', alignment: 'left'},
    {label:`Price ${initialCurrency}`, alignment: 'left'},
    {label:'Rating', alignment: 'left'},
    {label:'Popularity', alignment: 'left'},
  ];

  const tableSubColumns = [
    {label:'Name Ref', alignment: 'left'},
    {label:'Zoho Id', alignment: 'left'},
    {label:'Recommended Prod Price', alignment: 'left'},
    {label:'Currency', alignment: 'left'},
    {label:'Last Update', alignment: 'left'},
    {label:'Actions', alignment: 'center'}
  ];

  const createSubData = (data) => {
    const temp_data = JSON.parse(JSON.stringify(data));
    // temp_data.zohoId = temp_data.zohoId; // "2880271000000228074"
    const { name, zohoId, currency, updated_at, marketPrice} = temp_data;
    
    const cloneButtonHtml = createItem({ icon: { icon: 'file_double-1' }, text: t("Create") });
    const createDeal = <BasicModal content={DealsCreate} typeButton="html" options={{ html: cloneButtonHtml, clone: true }} object={temp_data} onClose={()=> setExternalRefresh(externalRefresh + 1)} />;
    const r_obj = { name, zohoId, marketPrice, currency, updated_at, createDeal, temp_data };
    return r_obj;
  };
  
  const createData = (data) => {
    const temp_data = JSON.parse(JSON.stringify(data));
    const { source, title, category, rating, popularity} = temp_data;
    let { min_price} = temp_data;
    if (source === "Amazon" || source === "amazon"){
      min_price = min_price * currencyValues[headerCountry] || 1;
    }
    if (Number.isNaN(parseFloat(min_price))) min_price = 0;
    
    
    // min_price = <Price value={min_price} />
    temp_data.children.forEach(e => e.marketPrice = min_price);
    const children = temp_data.children.map(createSubData);
    return { title, source, category, min_price, rating, popularity, children, data };
  };

  useEffect(() => {

    if (recomendations && recomendations.data) {
      if (recomendations.data.currencies && recomendations.data.currencies.length > 0){
        setCurrencyValues(recomendations?.data.currencies[0]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recomendations]);
  
  return (
    <section className={themeStyles["inner-component-view"]}>
      <ListView collection={recomendationsData} countries={countries} country={country}
        createData={createData}
        externalRefresh={externalRefresh}
        fetchCountries={fetchCountries} fetchFunction={fetchRecomendations} module={module} loadingTable={recomendationsLoading}
        tableColumns={tableColumns} subTableColumns={tableSubColumns}
      />
    </section>
  );
};

export default withTranslation()(RecomendationListing);