/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createActions } from "redux-actions";

import { GenericError } from '../../../../utils/errors';

import {
  getPromotions
} from '../services/promotions';

const PromotionActions = createActions({
    FETCH_PROMOTIONS_REQUEST: () => {},
    FETCH_PROMOTIONS_SUCCESS: (promotions) => ({promotions}),
    FETCH_PROMOTIONS_FAILED: (error) => ({error}),

    GET_PROMOTION: () => {},
    CREATE_PROMOTION: () => {},
    DELETE_PROMOTION: () => {},
});


PromotionActions.fetchPromotions = (query) => async (dispatch) => {
      try {
        dispatch(PromotionActions.fetchPromotionsRequest());
        if (query.country === 'ALL') query.country = null;
        const result = await getPromotions(query);
        dispatch(PromotionActions.fetchPromotionsSuccess(result));
      } catch (err) {
          console.log('err ==>', err);
        dispatch(PromotionActions.fetchPromotionsFailed({ error: new GenericError(err, err) }));
      }
    };

export default PromotionActions;