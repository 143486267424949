import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContainerTrendingListing from '../../components/shared-comp/trending/views/listing';
import CategoriesActions from '../../redux/categories/actions';
import WorkflowActions from '../../redux/workflow/actions';

const mapStateToProps = (state) => ({
  categoriesLoading: state.categories?.categoriesLoading,
  categoriesError: state.categories?.categoriesError,
  categoriesLoaded: state.categories?.categoriesLoaded,
  categories: state.categories?.categories,
  categoriesTrendingLoading: state.categories?.categoriesTrendingLoading,
  categoriesTrendingError: state.categories?.categoriesTrendingError,
  categoriesTrendingLoaded: state.categories?.categoriesTrendingLoaded,
  categoriesTrending: state.categories?.categoriesTrending,
  createCategoryLoading: state.categories?.createCategoryLoading,
  createCategoryError: state.categories?.createCategoryError,
  createdCategory: state.categories?.createdCategory,
  keywordsLoading: state.categories?.keywordsLoading,
  keywordsError: state.categories?.keywordsError,
  keywordsLoaded: state.categories?.keywordsLoaded,
  keywords: state.keywords?.keywords,
  keywordsTrendingLoading: state.categories?.keywordsTrendingLoading,
  keywordsTrendingError: state.categories?.keywordsTrendingError,
  keywordsTrendingLoaded: state.categories?.keywordsTrendingLoaded,
  keywordsTrending: state.categories?.keywordsTrending,
  country: state.workflow.country,
  countries: state.workflow.countries,
});

const mapDispatchToProps = (dispatch) => ({
  createCategory: bindActionCreators(CategoriesActions.createCategory, dispatch),
  deleteCategory: bindActionCreators(CategoriesActions.deleteCategory, dispatch),
  fetchCategories: bindActionCreators(CategoriesActions.fetchCategories, dispatch),
  fetchCategoriesFailed: bindActionCreators(CategoriesActions.fetchCategoriesFailed, dispatch),
  fetchCategoriesRequest: bindActionCreators(CategoriesActions.fetchCategoriesRequest, dispatch),
  fetchCategoriesSuccess: bindActionCreators(CategoriesActions.fetchCategoriesSuccess, dispatch),
  fetchKeywords: bindActionCreators(CategoriesActions.fetchKeywords, dispatch),
  fetchKeywordsFailed: bindActionCreators(CategoriesActions.fetchKeywordsFailed, dispatch),
  fetchKeywordsRequest: bindActionCreators(CategoriesActions.fetchKeywordsRequest, dispatch),
  fetchKeywordsSuccess: bindActionCreators(CategoriesActions.fetchKeywordsSuccess, dispatch),
  fetchTrendingCategories: bindActionCreators(CategoriesActions.fetchTrendingCategories, dispatch),
  fetchTrendingCategoriesFailed: bindActionCreators(CategoriesActions.fetchTrendingCategoriesFailed, dispatch),
  fetchTrendingCategoriesRequest: bindActionCreators(CategoriesActions.fetchTrendingCategoriesRequest, dispatch),
  fetchTrendingCategoriesSuccess: bindActionCreators(CategoriesActions.fetchTrendingCategoriesSuccess, dispatch),
  fetchTrendingKeywords: bindActionCreators(CategoriesActions.fetchTrendingKeywords, dispatch),
  fetchTrendingKeywordsFailed: bindActionCreators(CategoriesActions.fetchTrendingKeywordsFailed, dispatch),
  fetchTrendingKeywordsRequest: bindActionCreators(CategoriesActions.fetchTrendingKeywordsRequest, dispatch),
  fetchTrendingKeywordsSuccess: bindActionCreators(CategoriesActions.fetchTrendingKeywordsSuccess, dispatch),
  getCategory: bindActionCreators(CategoriesActions.getCategory, dispatch),
  fetchCountries: bindActionCreators(WorkflowActions.fetchCountries, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerTrendingListing);