import CustomersActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case CustomersActions.fetchCustomersRequest().type:
    return state.merge({
      customersLoading: true,
      customersError: null,
      customersCalled: false,
    });

    case CustomersActions.fetchCustomersSuccess().type:
      return state.merge({
        customersLoading: false,
        customersError: null,
        customersCalled: true,
        customers: action.payload.customers.data || state.customers,
      });

    default:
      return state;
  }

};
