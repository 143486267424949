import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DealsActions from '../../components/shared-comp/deals/model/actions';
import WorkflowActions from '../../redux/workflow/actions';
import ContainerDealsListing from '../../components/shared-comp/deals/views/listing';


const mapStateToProps = (state) => ({
    dealsLoading: state.deals.dealsLoading,
    dealsError: state.deals.dealsError,
    dealsCalled: state.deals.dealsCalled,
    deals: state.deals.deals,
    createDealLoading: state.deals.createDealLoading,
    createDealError: state.deals.createDealError,
    createdDeal: state.deals.createdDeal,
    country: state.workflow.country,
    countries: state.workflow.countries,
});


const mapDispatchToProps = (dispatch) => ({
  createDeal: bindActionCreators(DealsActions.createDeal, dispatch),
  deleteDeal: bindActionCreators(DealsActions.deleteDeal, dispatch),
  fetchDeals: bindActionCreators(DealsActions.fetchDeals, dispatch),
  fetchDealsFailed: bindActionCreators(DealsActions.fetchDealsFailed, dispatch),
  fetchDealsRequest: bindActionCreators(DealsActions.fetchDealsRequest, dispatch),
  fetchDealsSuccess: bindActionCreators(DealsActions.fetchDealsSuccess, dispatch),
  getDeal: bindActionCreators(DealsActions.getDeal, dispatch),

  fetchCountries: bindActionCreators(WorkflowActions.fetchCountries, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(ContainerDealsListing);
// export default connect(mapStateToProps, mapDispatchToProps)(DealsListing);
// export default compose( withRouter, connect(mapStateToProps, mapDispatchToProps))(ContainerDealsListing);