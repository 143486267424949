import Immutable from 'seamless-immutable';

const initialState = Immutable({
  ordersLoading: false,
  ordersError: null,
  ordersCalled: false,
  orders: null,
});


export default initialState;
