import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./pages/auth/login";
import Logout from "./pages/auth/logout";
import Loading from "./pages/loading";
import Guideline from "./pages/guideline";
import AdminRoot from './pages/admin';

import ContainerDealsListing from './pages/containers/DealsListing.Container';
import ContainerDealsDetail from './pages/containers/DealDetails.Container';
import ContainerBlastListing from './pages/containers/BlastListing.Container';
import ContainerOrdersListing from './pages/containers/OrdersListing.Container';
import ContainerUsersListing from './pages/containers/UsersListing.Container';
import ContainerReferrersListing from './pages/containers/ReferrersListing.Container';
import ContainerPromotionListing from './pages/containers/PromotionListing.Container';
import ContainerCustomersListing from './pages/containers/CustomersListing.Container';
import ContainerProductsListing from './pages/containers/ProductsListing.Container';
import ContainerGroupsListing from './pages/containers/GroupsListing.Container';
import ContainerTrendingListing from './pages/containers/TrendingListing.Container';
import ContainerCommissionListing from './pages/containers/CommissionListing.Container';
import ContainerRecomendationsListing from './pages/containers/RecomendationsListing.Container';
import ContainerPayoutListing from './pages/containers/PayoutListing.Container';

import { useTranslation } from "react-i18next";

// Create router
const AppRouter = () => {
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const checkingUser = useSelector((state) => state.auth.checkingUser);
  const { userInfo, userInfoLoaded, useInfoError } = useSelector((state) => state.workflow);
  const { t } = useTranslation();

  if (checkingUser) {
    return (
      <BrowserRouter>
        <Routes>
          <Route index path="*" element={<Loading />} />
        </Routes>
      </BrowserRouter>
    );
  }

  if (!loggedIn) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" exact element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </BrowserRouter>
    );
  }

  if (!userInfo && userInfoLoaded && useInfoError) {
    return (
      <AdminRoot >
        <div style={{
          display: "flex", justifyContent: "center", color: "red", alignItems: "center", height: "100%"
        }}>
          {t("Unable to load user info")}
          {JSON.stringify(useInfoError)}
        </div>
      </AdminRoot>
    );
  }

  return (
    <BrowserRouter>
      <AdminRoot>
        <Routes>
          <Route path="/guideline" exact element={<Guideline />} />

          <Route path="/deals" exact element={<ContainerDealsListing />} />
          <Route path="/deals/create" exact element={<ContainerDealsDetail />} />
          <Route path="/blast" exact element={<ContainerBlastListing />} />
          <Route path="/orders" exact element={<ContainerOrdersListing />} />
          <Route path="/users" exact element={<ContainerUsersListing />} />
          <Route path="/referrers" exact element={<ContainerReferrersListing />} />
          <Route path="/customers" exact element={<ContainerCustomersListing />} />
          <Route path="/groups" exact element={<ContainerGroupsListing />} />

          <Route path="/commissions" exact element={<ContainerCommissionListing />} />
          <Route path="/payouts" exact element={<ContainerPayoutListing />} />
          <Route path="/promotions" exact element={<ContainerPromotionListing />} />

          <Route path="/trending" exact element={<ContainerTrendingListing />} />
          <Route path="/products" exact element={<ContainerProductsListing />} />
          <Route path="/recomendations" exact element={<ContainerRecomendationsListing />} />

          <Route path="/logout" exact element={<Logout />} />
          <Route index path="*" element={<Navigate to="/deals" replace />} />
        </Routes>
      </AdminRoot>
    </BrowserRouter>
  );
  
};


export default AppRouter;