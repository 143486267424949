import { createActions } from "redux-actions";
import { GenericError } from '../../utils/errors';
import { getCountries, setCountry } from '../../services/workflow';
import { getStatuses } from '../../components/shared-comp/orders/services/orders';
import { myData } from '../../services/auth';
// import i18n from './i18n';

const allowRoles = [
  'admin',
  'superAdmin',
  'financial',
  'agent',
  'buyer',
  'reader'
];

const WorkflowActions = createActions({
  FETCH_COUNTRIES_REQUEST: () => { },
  FETCH_COUNTRIES_SUCCESS: (countries) => ({ countries }),
  FETCH_COUNTRIES_FAILED: (error) => ({ error }),
  
  FETCH_USER_INFO_REQUEST: () => { },
  FETCH_USER_INFO_SUCCESS: (userInfo) => ({ userInfo }),
  FETCH_USER_INFO_FAILED: (error) => ({ error }),

  SET_COUNTRY_REQUEST: () => { },
  SET_COUNTRY_SUCCESS: (country) => ({ country }),
  SET_COUNTRY_FAILED: (error) => ({ error }),

  FETCH_STATUSES_REQUEST: () => { },
  FETCH_STATUSES_SUCCESS: (orders) => ({ orders }),
  FETCH_STATUSES_FAILED: (error) => ({ error }),
});


WorkflowActions.fetchCountries = (query) => async (dispatch) => {
    try {
      dispatch(WorkflowActions.fetchCountriesRequest());
      const result = await getCountries(query);
      dispatch(WorkflowActions.fetchCountriesSuccess(result));
    } catch (err) {
      console.log('err ==>', err);
      dispatch(WorkflowActions.fetchCountriesFailed({ error: new GenericError(err, err) }));
    }
  };

WorkflowActions.fetchUserInfo = () => async (dispatch) => {
    try {
      dispatch(WorkflowActions.fetchUserInfoRequest());
      const result = await myData();
      if (allowRoles.find((a) => a === result.data?.data?.type)){
        dispatch(WorkflowActions.fetchUserInfoSuccess(result));
      } else {
        // TODO report error before logout?
        window.location.replace('/logout');
        // throw new GenericError(i18n.t('This user is not authorized'));
      }
    } catch (err) {
      console.log('err ==>', err);
      dispatch(WorkflowActions.fetchUserInfoFailed({ error: new GenericError(err, err) }));
    }
  };

WorkflowActions.setCountry = (result) => async (dispatch) => {
    try {
      dispatch(WorkflowActions.setCountryRequest());
      setCountry(result);
      dispatch(WorkflowActions.setCountrySuccess(result));
    } catch (err) {
      console.log('err ==>', err);
      dispatch(WorkflowActions.setCountryFailed({ error: new GenericError(err, err) }));
    }
  };

WorkflowActions.fetchStatuses = () => async (dispatch) => {
    try {
      dispatch(WorkflowActions.fetchStatusesRequest());
      const result = await getStatuses();
      dispatch(WorkflowActions.fetchStatusesSuccess(result));
    } catch (err) {
      dispatch(WorkflowActions.fetchStatusesFailed({ error: new GenericError(err, err) }));
    }
  };

export default WorkflowActions;