/* eslint-disable no-return-await */
/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import Menu, { createItem } from '../../../core/Menu';
import { columnTypes } from '../../../core/TableCommon';
import Icon from "../../../core/Icon";
import BasicModal from '../../../core/BasicModal';
import ReferrersCreate from '../forms/ReferrersCreate';
import ReferrersExport from '../forms/ReferrersExport';
import themeStyles from '../../../../../theme.module.sass';
import { callAssignToast, getShopUrl, modules , crudAction, actions} from '../../../utils';
import { getUserMeta, updateStatusTendero } from "../../services/referrers";
import { Grid } from '@mui/material';
import ListView from '../../../core/ListView';
import ReferrersAddPoints from '../forms/ReferrersAddPoints';

const ReferrersListing = ({ t, referrers, referrersLoading, fetchReferrers, country, countries, fetchCountries }) => {
  
  const module = modules(t).referrer;

  

  const externalQueryParamsFiltersInit = useMemo(()=>({
    all:true
  }), []);

  
    const [userMeta, setUserMeta] = useState();
  const [statuses, setStatuses] = useState();
  const [externalRefresh, setExternalRefresh] = useState(0);
  
  
  const refreshOptions = { refresh: (externalRefresh + 1)};

  const tableColumns = [
    {label : 'ID', sort : 'id'},
    { label : 'Name' , sort : 'firstName' },
    { label : 'Email' , sort : 'email'},
    { label : 'Phone' , sort : 'phone' },
    { label : 'Address', sort : 'addressHtml'},
    { label : 'WAO! Points', sort : 'totalAvailable', alignment: 'center'},
    { label : 'Status' , sort : 'status', alignment: 'center' },
    { label : 'Created at', type : columnTypes.DATE , sort : 'created_at', alignment: 'center'},
    { label : 'Actions', alignment: 'center' }
  ];


  const createData = (data) => {
    const { id, firstName, lastName, email, phone, addresses = [], created_at, pointsAvailable: points } = data;
    const buttonHtml = createItem({ icon: { icon: 'edit_cover-' }, text: t("Edit") });
    const editHtml = <BasicModal content={ReferrersCreate} typeButton="html" options={{ html: buttonHtml }} object={data} onClose={()=> setExternalRefresh(externalRefresh + 1)} />;
    const buttonPointsHtml = createItem({ icon: { icon: 'trophy' }, text: t("WAO! Points") });
    const addPointsHtml = <BasicModal content={ReferrersAddPoints} typeButton="html" options={{ html: buttonPointsHtml }} object={{data, country}} onClose={()=> setExternalRefresh(externalRefresh + 1)} />;
    const updateStatus = (data.status === 'active') ?
    {
      icon: { icon: 'cancel' },
      text: t("Deactivate User"),
      onClick: async () => {
        const response = await crudAction(updateStatusTendero.bind(null, data.id, 'inactive'), ()=>{},
          {
            module, name: data.name, action: actions.UPDATE }, t
        );
        if (response?.ok) {
          setExternalRefresh(externalRefresh + 1);
        }
      }
    }
    :
    {
        icon: { icon: 'arrow_in_right-' },
        text: t("Activate User"),
        onClick: async () => {
          const response = await crudAction(updateStatusTendero.bind(null, data.id, 'active'), ()=>{},
            { module, name: data.name, action: actions.UPDATE }, t
          );
          if (response?.ok) {
            setExternalRefresh(externalRefresh + 1);
          }
        }
    };
    const menuItems = [
      { html: editHtml },
      { icon: { icon: 'shop_center-' },
      text: t("Shop"),
      openNewTab: getShopUrl(data?.shopId)
      },
      { html: addPointsHtml },
      updateStatus
    ];
    const menu = <Menu items={menuItems} />;
    const status = t(data?.metadata?.status);
    const mainAddress = addresses[0];
    let addressHtml = "";
    if (mainAddress) {
      const address = `${mainAddress.address1 + (mainAddress.address2 ? ` ${ mainAddress.address2}` : '') } ${ mainAddress.city}`;
      const addressValidIcon = <Icon color="#52D8BD" size={15} icon="check_circle" />;
      const addressInvalidIcon = <Icon color="#E24E78" size={15} icon="cancel" />;
      const isAddressValid = mainAddress.lat && mainAddress.lng;
      addressHtml = <Grid container>
        <Grid item xs={12}>
          <span className={themeStyles["table-address-icon"]}>{isAddressValid ? addressValidIcon : addressInvalidIcon}</span>
          <span className={`${themeStyles["table-list-address"] } ${ !isAddressValid ? themeStyles.invalid : ''}`}>{address} </span>
          {(!mainAddress.description) ? ('') : (
            <span className={`${themeStyles["table-info-tooltip"] } ${ themeStyles["bold-label"] } ${ themeStyles.clickable}`} title={mainAddress.description} >
              <Icon color="#CFCFCF" size={20} icon="info1" className={themeStyles["table-info-icon"]} />
            </span>
          )}
        </Grid>
      </Grid>;
    }

    const waoPoints = <span>{points || 0}</span>;

    return { id,
      name: `${firstName} ${lastName}`,
      email,
      phone,
      addressHtml,
      points:waoPoints,
      status,
      created_at,
      actions: menu,
      tooltip: created_at,
      data
    };
  };


  const loadUserMeta = async () => await callAssignToast(getUserMeta, setUserMeta, 'list User Metadata');
  
  useEffect(() => {
    if (userMeta && userMeta.status) setStatuses(userMeta.status);
  }, [userMeta]);

  useEffect(() => {
    const initLoad = async () => {
      await Promise.all([loadUserMeta()]);
      // setReadyToLoad(true);
    };
    initLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  


  return (
    <section className={themeStyles["inner-component-view"]}>
     <ListView
        createData={createData} collection={referrers} loadingTable={referrersLoading} tableColumns={tableColumns} module={module} statuses={statuses}
        createView={ReferrersCreate} exportView={ReferrersExport} fetchFunction={fetchReferrers} country={country}
        countries={countries} fetchCountries={fetchCountries} externalRefresh={externalRefresh}
        externalRefreshOptions={refreshOptions} externalQueryParamsFiltersInit={externalQueryParamsFiltersInit}
      />
    </section>
  );
};

export default withTranslation()(ReferrersListing);