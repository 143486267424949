import { getConfig } from '../../../../config';

let api;

const checkConfig = () => {
  if (!api) api = getConfig('api');
};

/**
{string} message Blast message
{string} name Blast name
{Array} numbers Blast numbers
*/
export const getCommissions = (query) => {
  checkConfig();
  if (query.country) {
    query = {
      countries: query.country,
      ...query
    };
    delete query.country;
  }
  if (query.dateFrom) {
    query = {
      fromDate: query.dateFrom,
      ...query
    };
    delete query.dateFrom;
  }
  if (query.dateTo) {
    query = {
      toDate: query.dateTo,
      ...query
    };
    delete query.dateTo;
  }
  if (query.commissionStatus) {
    query = {
      statuses: query.commissionStatus,
      ...query
    };
    delete query.commissionStatus;
  }
  if (query.type) {
    query = {
      types: [query.type],
      ...query
    };
    delete query.type;
  }
  query = {
    perPage: query.limit,
    currentPage: query.page,
    ...query
  };
  delete query.limit;
  delete query.page;

  return api.get(`/commissions`, query);
};

/**
 * Update the transaction with the given id
 * @param {string} id id of the transaction to update
 * @param {object} data
 */
 export const updateCommissions = (data) => {
  checkConfig();
  return api.put('/commissions', data);
};

/**
 * Export commission
 */
 export const exportCommissions = (data) => {
  checkConfig();
  if (data.filters.country) {
    data.filters = {
      countries: data.filters.country,
      ...data.filters
    };
    delete data.filters.country;
  }
  if (data.filters.dateFrom) {
    data.filters = {
      fromDate: data.filters.dateFrom,
      ...data.filters
    };
    delete data.filters.dateFrom;
  }
  if (data.filters.dateTo) {
    data.filters = {
      toDate: data.filters.dateTo,
      ...data.filters
    };
    delete data.filters.dateTo;
  }
  if (data.filters.commissionStatus) {
    data.filters = {
      statuses: data.filters.commissionStatus,
      ...data.filters
    };
    delete data.filters.commissionStatus;
  }
  if (data.filters.type) {
    data.filters = {
      types: [data.filters.type],
      ...data.filters
    };
    delete data.filters.type;
  }
  data.filters = {
    perPage: data.filters.limit,
    currentPage: data.filters.page,
    ...data.filters
  };
  delete data.filters.limit;
  delete data.filters.page;

  return api.get(`/commissions/exportCommissions`, data);
};
