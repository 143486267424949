import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#fff'
    },
    third: {
      main: '#c0c0c0'
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    },
  },
  overrides: {
    // MuiInputBase: {
    //   root: {
    //     fontFamily: 'FuturaBT !important',
    //     fontSize: 15,
    //     lineHeight: 1.27,
    //     letterSpacing: 0.17,
    //     color: '#c0c0c0',
    //   }
    // },
    // MuiRating: {
    //   iconEmpty: {
    //     fill: '#efeff4'
    //   },
    //   iconFilled: {
    //     fill: '#a84600'
    //   }
    // }
  }
});
