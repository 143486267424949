import { getConfig } from '../../../../config';

let api;


const checkConfig = () => {
  if (!api) api = getConfig('api');
};

/**
{string} message Blast message
{string} name Blast name
{Array} numbers Blast numbers
*/
export const getRecomendations = (query) => {
  checkConfig();
  return api.get(`/recomendations`, query);
};

export const getRecomendationsList = (query) => {
  checkConfig();
  query = {
    perPage: query.limit,
    currentPage: query.page,
    ...query
  };
  delete query.limit;
  delete query.page;
  return api.get(`/recomendations`, query);
};
  
/**
 * Creates a blast
 * @param {object} data
 * data.name {string} Blast name
 * data.message {string} Blast message
 * data.numbers {Array} Tenderos phone numbers
 */
export const postRecomendations = (data) => {
  checkConfig();
  return api.post('/recomendations', data);
};


/**
 * Update the blast with the given id
 * @param {string} id id of the blast to update
 * @param {object} data
 * data.name {string} Blast name
 * data.message {string} Blast message
 * data.numbers {Array} Blast numbers
 */
export const updateRecomendations = (id, data) => {
  checkConfig();
  return api.put(`/recomendations/${id}`, data);
};

/**
 * Delete the blast with the given id
 * @param {string} id id of the blast to delete
 */
export const deleteRecomendations = (id) => {
  checkConfig();
  return api.delete(`/recomendations/${id}`);
};

