import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormikErrorFocus from 'formik-error-focus';
import { withTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import blastStyles from './styles.module.sass';
import themeStyles from '../../../../theme.module.sass';
import {addingBonus, updateBono} from '../services/payout';
import { toast } from 'react-toastify';


const AddBonus = ({ onClose = () => { }, t, object, pagoId, toupdate }) => {
  const { type, commissions, commissionsMap } = object;
    // const [objCommissions, setObjCommissions] = useState([]);
  // const [tableRows, setTableRows] = useState(null);
  const [PayoutSchema, setPayoutSchema] = useState(Yup.object().shape({
    title: Yup.string().required(t('Required')),
    description: Yup.string().required(t('Required')),
    value_bon: Yup.string().required(t('Required')),
    dateOfPayout: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
    dateFrom: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
    dateTo: Yup.date(t('Required')).typeError(t('Required')).min(Yup.ref('dateFrom'), t('paymentToGreater')).required(t('Required'))
  }));
 
  // const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const initLoad = async () => {
      if (type === 'simple') {
        let objects = [];
        for (const [key, value] of Object.entries(commissionsMap)) {
          if (value) objects.push(commissions[key]);
        }

        if (objects.length === 0) {
          const btn = document.getElementById('submit-button');
          btn.disabled = true;
          // setIsValid(false);
        }

        const helper = {};
        objects = objects.reduce((r, o) => {
          const { id, amount, userId, referrer } = o;

          if (!helper[userId]) {
            helper[userId] = {
              commissions: [id],
              amount,
              user: referrer.fullName,
              userId
            };
            r.push(helper[userId]);
          } else {
            helper[userId].commissions.push(id);
            helper[userId].amount += amount;
          }
          return r;
        }, []);
        // setObjCommissions(objects);
        // setTableRows(objects.map(createData));
        setPayoutSchema(Yup.object().shape({
          title: Yup.string().required(t('Required')),
          description: Yup.string().required(t('Required')),
          value_bon: Yup.string().required(t('Required')),
          dateOfPayout: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
          commissions: Yup.array(t('Required')).min(1, t("Required")).of(Yup.number()).required(t("Required")),
        }));
      }
    };
    initLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
   * Function called whenever the Formik form is submitted
   * @async
   * @param {Object} values Form values
   * @param {Object} options Functions provided by the Formik form
   */
   
  const formOnSubmit = async (values) => {

    if (type === 'add') {

      const body = {
        title: values.title,
        description: values.description,
        idPayout: pagoId,
        amount: values.value_bon
      };

      const response = await addingBonus(body);
      if (response?.ok) {
        onClose();
        toupdate();
        toast.success('Bono Agregado');
      }

    } else if (type === 'update') {

      const body = {
        title: values.title,
        description: values.description,
        idPayout: pagoId.idPayout,
        amount: values.value_bon,
        idRewardLog: pagoId.idRewardLog
      };
      
      const response = await updateBono(body);
      if (response?.ok) {
        onClose();
        toupdate();
        toast.success('Bono Editado');
      }

    }
       
  };

  const formInitialValues = {
      title: "",
    description: "",
    value_bon: "",
    dateFrom: new Date(),
    dateTo: new Date(),
    dateOfPayout: new Date(),
    commissions: (commissionsMap) ? Object.keys(commissionsMap) : []
};
  return (
    <div>
      <Grid container spacing={0} alignItems="flex-start" >
        <Grid item xs>

          <div className={themeStyles.currentContent}>
            <div className={blastStyles["blast-view"]}>
              <header className={themeStyles["header-form"]}>
                {(type === 'bulk') ?
                  <h3>Agregar bonificación</h3>
                  :
                  <h3>{t('Agregar Bonificación')}</h3>
                }
              </header>
              <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formOnSubmit} validationSchema={PayoutSchema} autoComplete="off" >
                    {({ values, handleChange, handleBlur, errors, touched }) => (
                <div className={themeStyles["form-wrap"]}>
                
                  <div>
                    <Form>
                      <div className={themeStyles["form-group"]} >
                        <label htmlFor="title" className={themeStyles["col-form-label"]}>{t("Titulo de Bonificación")}</label>
                        <Field className={themeStyles["form-control"]} name="title" placeholder={t("Titulo de la Bonificación")} value={values.title} onChange={handleChange} onBlur={handleBlur} autoComplete="title" />
                        {errors.description && touched.description ? (<div className={themeStyles.formError}>{errors.description}</div>) : ' '}


                        <label htmlFor="description" className={themeStyles["col-form-label"]}>{t("Description")}</label>
                        <Field className={themeStyles["form-control"]} name="description" placeholder={t("Descripción de la bonificación")} value={values.description} onChange={handleChange} onBlur={handleBlur} autoComplete="description"/>
                        {errors.description && touched.description ? (<div className={themeStyles.formError}>{errors.description}</div>) : ' '}


                        <label htmlFor="value_bon" className={themeStyles["col-form-label"]}>{t("Value")}</label>
                        <Field className={themeStyles["form-control"]} name="value_bon" placeholder={t("Valor $")} value={values.value_bon} onChange={handleChange} onBlur={handleBlur} autoComplete="value" />
                        {errors.description && touched.description ? (<div className={themeStyles.formError}>{errors.description}</div>) : ' '}
                      </div>
                  
                      
                      <div className={themeStyles["form-crud-actions"]}>
                        <button type="submit" style={{marginRight : "10px"}} id='submit-button' className={`${themeStyles.btn} ${themeStyles["btn-secondary"]}`}>
                          Agregar
                        </button>
                        <button id='submit-button' onClick={onClose} className={`${themeStyles.btn} ${themeStyles["btn-secondary"]}`}>
                          Cancelar
                        </button>
                      </div>
                      <FormikErrorFocus offset={0} align="top" duration={1000} />
                    </Form>
                  </div>
                </div>
              )}
              </Formik>
            </div>
          </div>
        </Grid>
      </Grid>

    </div>
  );
};

export default withTranslation()(AddBonus);