import React, { createContext, useCallback, useMemo, useReducer } from 'react';
import { OrderIdReducer } from './orderIdReducer';

export const OrderIdContext = createContext();

const orderIds = localStorage.getItem('orderIdFraud') ? JSON.parse(localStorage.getItem('orderIdFraud')) : {};

const initialState = {
  orderIds,
};


const OrderIdContextProvider = ({ children }) => {

  const [state, dispatch] = useReducer(OrderIdReducer, initialState);
  const increase = payload => {
    dispatch({ type: 'INCREASE', payload });
  };
  const decrease = payload => {
    dispatch({ type: 'DECREASE', payload });
  };
  const addOrderId = payload => {
    dispatch({ type: 'ADD_ID', payload });
    /* setTimeout(function () {
      dispatch({ type: 'CLEAR_ACTIONS', payload });
    }, 1000); */
  };
  const removeOrderId = payload => {
    dispatch({ type: 'REMOVE_ID', payload });
  };
  const clearOrderId = (payload) => {
    dispatch({ type: 'CLEAR', payload });
  };
  const getOrderId = useCallback((emailUser) => (state.orderIds[emailUser] || {}), [state.orderIds]);

    const contextValues = useMemo(() => ({
      removeOrderId,
      addOrderId,
      increase,
      decrease,
      clearOrderId,
      getOrderId,

      ...state
    }), [getOrderId, state]);

  return (
    <OrderIdContext.Provider value={contextValues}>
      {children}
    </OrderIdContext.Provider>
  );
};

export default OrderIdContextProvider;