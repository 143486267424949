import React from 'react';
import { NavLink } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import ContainerMainSidebar from '../containers/MainSidebar.Container';
import HeaderUser from '../../components/HeaderUser';
import packageJson from '../../../package.json';

const AdminRoot = ({ /* t, */ children }) => (

    <section className="main-page-view">
        <ContainerMainSidebar LinkProp={NavLink} parentVersion={packageJson?.version} />
        <section className="main-content-view">
          <HeaderUser />
          {children}
        </section>
      </section>
  );

export default withTranslation()(AdminRoot);