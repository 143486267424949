/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useState, useEffect, useMemo } from "react";
import { withTranslation } from 'react-i18next';
import HeaderViews from '../HeaderViews';
import TopFilters from '../TopFilters';
import TableCommon from '../TableCommon';
import { deepDiff, formCol } from '../../utils';
import Pagination from '../Pagination';
import { usePrevious } from '../../../../utils/hooks';
import { Collapse } from "@mui/material";
import themeStyles from '../../../../theme.module.sass';

const textColor = { danger: "#dc3545" };

const textColorItemSelect = {
  chargeback: textColor.danger,
};

const ListView = ({ collection, loadingTable, tableColumns: rawColumnsData, subTableColumns: rawSubColumnsData, module, fetchFunction, country, tableHeaders, conditionTable, toupdateTable,
  countries, fetchCountries, createData, externalRefresh, location, subTableClass, createView, exportView, bulkUpdateView, externalRefreshOptions, externalTableRows, subTableColumns2,
  statuses, statusLabels, externalComponentTop, loadOptionsHeaderViews, bulkSection, checkCollection, expandAllRows, customTitleHeaderViews, showTitleHeader, selectRefresh, externalTableRows2, showPrice,
  modalSizeHeaderViews, externalQueryParamsFiltersInit, hiddenSubHeader, externalCellStyles, externalTableStyles, category_name}) => {
  
  const prevExternalParams = usePrevious(externalQueryParamsFiltersInit);
  const tableColumns = useMemo(() => rawColumnsData.map((column) => formCol(column)), [rawColumnsData]);
  const subTableColumns = useMemo(() => !rawSubColumnsData?.length ? null : rawSubColumnsData.map((column) => formCol(column)), [rawSubColumnsData]);
  const prevCountry = usePrevious(country);

  const defaultQueryParams = useMemo(()=>({
    page: 1,
    limit: 10,
    sort: [],
    q: null,
    country,
    refresh: 0,
    ...externalQueryParamsFiltersInit
  }), [country]);

  const [expanded, setExpanded] = useState(false);
  const [tableRows, setTableRows] = useState(null);
  const [tableRows2, setTableRows2] = useState(null);
  const [paging, setPaging] = useState(null);
  const [resetCounter, setResetCounter] = useState(0);
  const [currentQueryParams, setCurrentQueryParams] = useState(defaultQueryParams);

  const areOrdersSelected = bulkSection && Object.values(checkCollection).find(value => value);
  const refreshOptions = externalRefreshOptions || { refresh: (currentQueryParams.refresh + 1), page : 1};
  const options = useMemo(() => ({ filters: currentQueryParams }), [currentQueryParams]);

  const updateQuery = newParams => {
    const query = { ...currentQueryParams, ...newParams };
    setCurrentQueryParams(query);
  };

  const updateQueryOnFirstPage = queryParams => {
    const newQuery = { ...queryParams, page: 1 };
    updateQuery(newQuery);
    setPaging({ ...paging, page: 1 });
  };

  const sortBy = (sort) => {
    updateQuery({sort: `${sort.key} ${sort.direction}`});
  };

  // Update query params whenever a filter reset is triggered?
  const resetFilters = () => {
    setResetCounter(resetCounter + 1);
    setCurrentQueryParams(defaultQueryParams);
  };
  
  const refreshCurrentQuery = useCallback(() => {
    const newParams = { ...currentQueryParams, ...refreshOptions };
    setCurrentQueryParams(newParams);
  }, [currentQueryParams, refreshOptions]);

  // Update current query params on external update
  useEffect(() => {
    const newParams = { ...currentQueryParams, ...deepDiff(prevExternalParams, externalQueryParamsFiltersInit, true) };
    setCurrentQueryParams(newParams);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalQueryParamsFiltersInit]);

  // Update queryParams when country is updated
  useEffect(() => {
    if (country && country !== prevCountry) setCurrentQueryParams({ ...currentQueryParams, country });
  }, [country, currentQueryParams, prevCountry]);

  useEffect(() => {
    if (collection && collection.data) {
      setTableRows(externalTableRows || collection.data?.map(createData));
      setTableRows2(externalTableRows2);
      setPaging(collection.paging);
    }
  }, [createData, collection, selectRefresh]);
 
  // Fetch deals whenever query params are updated
  useEffect(() => {
    const newParams = { ...currentQueryParams };
    delete newParams.refresh;
    // TODO review delete
    if (!newParams.isWatched) { delete newParams.isWatched; }
    if (newParams.country) fetchFunction(newParams);
  }, [fetchFunction, currentQueryParams, externalRefresh]);
  
  return (
    <section className={themeStyles["inner-component-view"]}>
      <HeaderViews
        loading={loadingTable}
        location={location}
        module={module}
        count={collection?.paging?.totalItems}
        applyFilter={updateQueryOnFirstPage}
        resetSearch={resetCounter}
        expanded={expanded}
        setExpanded={setExpanded}
        createContent={createView}
        exportContent={exportView}
        bulkUpdateContent={bulkUpdateView}
        refresh={refreshCurrentQuery}
        options={options}
        loadOptions={loadOptionsHeaderViews}
        customTitle={customTitleHeaderViews}
        showTitle={showTitleHeader}
        modalSize={modalSizeHeaderViews}
      />
      <TopFilters
        statuses={statuses}
        statusLabels={statusLabels}
        textColorItemSelect={textColorItemSelect}
        expanded={expanded}
        module={module.name}
        country={country}
        countries={countries}
        fetchCountries={fetchCountries}
        applyFilter={updateQueryOnFirstPage}
        resetFilters={resetFilters}
        showPrice={showPrice}
        category_name={category_name}
      />
      {bulkSection && <div> <Collapse in={areOrdersSelected}>{bulkSection()}</Collapse></div>}
      {externalComponentTop && externalComponentTop()}
      <TableCommon
        scrollToSides
        style={{ flex: "0 1 auto" }}
        expandAtEnd
        expandAllRows={expandAllRows}
        altStyle
        sortBy={sortBy}
        rows={tableRows}
        rows2={tableRows2}
        columns={tableColumns}
        subcolumns={subTableColumns}
        subcolumns2={subTableColumns2}
        hiddenSubHeader={hiddenSubHeader}
        headers={tableHeaders}
        subtableClass={subTableClass}
        module={module}
        refresh={refreshCurrentQuery}
        externalCellStyles={externalCellStyles}
        externalTableStyles={externalTableStyles}
        loadingTable={loadingTable}
        condition={conditionTable}
        toupdate={toupdateTable}
      />
      {paging ? <Pagination onClick={updateQuery} paging={paging} zIndexPagination={1000}/> : null}
    </section>
  );
};

export default withTranslation()(ListView);