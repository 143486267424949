/* eslint-disable no-return-await */
/* eslint-disable no-promise-executor-return */
/* eslint-disable import/no-cycle */
import { create } from 'apisauce';
import { storeCredentials, getIdTokenRefreshed, getCurrentUser, getUserToken, getCredentials } from './auth';
import AuthActions from '../redux/auth/actions';
import store from "../redux/store";

// define the api
const api = create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const axios = api.axiosInstance;

const sleep = async (ms) => new Promise(resolve => setTimeout(resolve, ms));

let refreshingToken = false;
// TODO Instead of a timeout we could probably listen for an event to retry pending requests
const refreshSleep = process.env.REACT_APP_TOKEN_REFRESH_SLEEP_SECS || 5;

const refreshToken = async (originalRequest) => {
  // Refresh Token
  console.log('Renewing token');
  let success = false;
  try {
    let newToken;
    const currentUser = await getCurrentUser();
    console.log('CurrentUser:', currentUser);
    if (!currentUser) newToken = await getIdTokenRefreshed();
    else newToken = await getUserToken();
    storeCredentials(newToken);
    if (originalRequest) {
      originalRequest.retry = true;
      originalRequest.headers.Authorization = `Bearer ${newToken}`;
      success = true;
    } else {
      console.log('No original request to repeat');
    }
  } catch (error) {
    console.log('New Token failed', error);
    return store.dispatch(AuthActions.logout());
  }
  refreshingToken = false;
  if (success) {
    console.log('Token refreshed, retrying request:', originalRequest.method, originalRequest.baseURL + originalRequest.url, originalRequest.headers);
    return axios(originalRequest);
  }
  return Promise.reject(new Error('IgnoreResponse'));
};

const refreshCheck = async (originalRequest) => {
  // Refresh Token
  if (refreshingToken) {
    console.log('Already refreshing token');
    await sleep(refreshSleep * 1000);
    console.log('Attempting after possible refresh:', originalRequest.method, originalRequest.baseURL + originalRequest.url);
    originalRequest.retry = true;
    originalRequest.headers.Authorization = `Bearer ${getCredentials()}`;
    return axios(originalRequest);
  }
  console.log('No refresh in progress');
  if (!originalRequest.retry) {
    originalRequest.retry = true;
    refreshingToken = true;
    return await refreshToken(originalRequest);
  }
  console.log('This was already a retry, stop retrying...');
  return Promise.reject(new Error('IgnoreResponse'));
};

axios.interceptors.response.use(
  response => response,
  async (error) => {
    const errorStatus = error?.response?.status;
    const originalRequest = error?.config;
    if (errorStatus === 401 && originalRequest && !originalRequest.retry && error?.response?.data?.message === 'auth/id-token-expired') {
      console.log('Token expired');
      return await refreshCheck(originalRequest);
    } if (errorStatus === 402 || errorStatus === 403) {
      return Promise.reject(error);
    } if (errorStatus === 401 || errorStatus === 404) {
      console.log(errorStatus, 'error');
      return store.dispatch(AuthActions.logout());
    }

    if (!error.response) {
      // Attempting to catch CORS error to refresh token, could just be an actual Network Error
      console.log('Network Err:', error);
      // Refresh Token
      return await refreshCheck(originalRequest);
    }
      console.log('Additional error details:', error);
      return Promise.reject(error);
    
  }
);
export default api;