/* eslint-disable default-param-last */
import UsersActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case UsersActions.fetchUsersRequest().type:
      return state.merge({
        usersLoading: true,
        usersError: null,
        usersCalled: false,
      });

      case UsersActions.fetchUsersSuccess().type:
        return state.merge({
          usersLoading: false,
          usersError: null,
          usersCalled: true,
          users: action.payload.users.data || state.users,
        });


    default:
      return state;
  }
};
