import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WorkflowActions from '../../redux/workflow/actions';
import ContainerMainSidebar from '../../components/shared-comp/core/MainSidebar';

const mapStateToProps = (state) => ({
  country: state.workflow.country,
  countries: state.workflow.countries,
  countriesLoading: state.workflow.countriesLoading,
  userInfo: state.workflow.userInfo,
  userInfoLoading: state.workflow.userInfoLoading
});

const mapDispatchToProps = (dispatch) => ({
  setCountry: bindActionCreators(WorkflowActions.setCountry, dispatch),
  fetchCountries: bindActionCreators(WorkflowActions.fetchCountries, dispatch),
  fetchUserInfo: bindActionCreators(WorkflowActions.fetchUserInfo, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerMainSidebar);