/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';import * as Yup from 'yup';
import FormikErrorFocus from 'formik-error-focus';
import { withTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { actions, capitalize, crudAction, cleanObject, modules, formatTimestamp } from '../../../utils';
import orderStyles from './styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import { exportCommissions } from '../../services/commission';
import { isEmpty } from 'lodash';
import moment from 'moment';
import ModalCloseButton from '../../../core/ModalCloseButton';

const CommissionsExport = ({ onClose = () => { }, ...props }) => {

    const [filters] = useState(cleanObject({ ...props.filters }, ['page', 'limit', 'refresh', 'sort', 'priceMax', 'priceMin']));

  const module = modules(props.t).commission;

  const t = {
    exportCommissions: props.t('exportCommissions'),
    emails: props.t('Email Addresses'),
    required: props.t('Required'),
    export: props.t('Export'),
    exporting: props.t('Exporting'),
    mailsErrors: props.t('One or more email is not valid'),
    filters: props.t('filters'),
    filtersEmpty: props.t('filtersEmpty'),
  };

  const tag = {
    q: props.t('Search'),
    country: props.t('Country'),
    startDate: props.t('Status Date from'),
    endDate: props.t('Status Date to'),
    dateFrom: props.t('Created From'),
    dateTo: props.t('Created To'),
    status: props.t('Status'),
    commissionStatus: props.t('Commission Status')
  };

  

  const crudOptions = {
    module,
    name: '',
  };

  function validateMails(msg) {
    return Yup.mixed().test({
      name: 'validateMails',
      exclusive: false,
      message: msg || t.mailsErrors,
      test (value) {
        const validator = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g;
        return validator.test(value);
      },
    });
  }
  Yup.addMethod(Yup.string, 'validateMails', validateMails);

  // Form placeholders
  const placeholder = {
    emails: props.t('doe@wao.shop, doe2@wao.shop')
  };

  // Formik initial/default values
  const formInitialValues = {
    emails: ""
  };

  // Formik validation
  const ExportSchema = Yup.object().shape({
    emails: Yup.string().required(t.required).validateMails()
  });

  /**
   * Function called whenever the Formik form is submitted
   * @async
   * @param {Object} values Form values
   * @param {Object} options Functions provided by the Formik form
   */
  const formOnSubmit = async (values) => {
    if (filters.country && filters.country === 'ALL') {
      Reflect.deleteProperty(filters, 'country');
    }
    const body = {
      emails: values.emails.replace(/\s+/g, '').split(','),
      filters,
      timeZone: moment().utcOffset()
    };

    await crudAction(exportCommissions.bind(null, body), onClose,
      { ...crudOptions, action: actions.EXPORT }, props.t
    );
  };

  return (
    <div>
      <ModalCloseButton onClick={() => onClose(true)} floating />
      <Grid container spacing={0} alignItems="flex-start" >
        <Grid item xs>
          <div className={themeStyles.currentContent}>
            <div className={orderStyles["order-view"]}>
              <header className={themeStyles["header-form"]}>
                <h3>{t.exportCommissions}</h3>
              </header>
              <div className={themeStyles["form-wrap"]}>
                <div className={themeStyles["form-group"]} >
                  <label htmlFor="filters" className={themeStyles["col-form-label"]}>{t.filters}</label>
                  {isEmpty(filters) ?
                    <p>
                      <label htmlFor="filtersEmpty" className={themeStyles["col-form-label"]}>{t.filtersEmpty}</label>
                    </p>
                    :
                    Object.keys(filters).map((key, i) => {
                      if (key === 'entities') {
                        return Object.keys(filters[key]).map((entity, j) => <p key={j}>
                            <span><b>{tag[entity] ? tag[entity] : props.t(capitalize(entity))}: </b>
                              {filters[key][entity].map((entityValue) => (entityValue.label)).join(` ${ props.t('or') } `)}
                            </span>
                          </p>);
                      }
                        let value;
                        if (props.filters[key] instanceof Date) value = formatTimestamp(props.filters[key], false, true);
                        else value = props.t(capitalize(filters[key]));
                        return <p key={i}>
                          <span><b>{tag[key] ? tag[key] : props.t(key)}:</b> {value}</span>
                        </p>;
                      
                    })
                  }
                </div>
                <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formOnSubmit} validationSchema={ExportSchema} autoComplete="off" >
                  {({ isSubmitting, values, handleChange, handleBlur, errors, touched }) => (
                    <Form>
                      <div className={themeStyles["form-group"]} >
                        <label htmlFor="emails" className={themeStyles["col-form-label"]}>{t.emails}<span className={themeStyles["label-asterisk"]}>*</span></label>
                        <Field className={themeStyles["form-control"]} name="emails" placeholder={placeholder.emails} value={values.emaisl} onChange={handleChange} onBlur={handleBlur} />
                        {errors.emails && touched.emails ? (<div className={themeStyles.formError}>{errors.emails}</div>) : ' '}
                      </div>
                      <div className={themeStyles["form-crud-actions"]}>
                        <button type="submit" className={`${themeStyles.btn } ${ themeStyles["btn-primary"]}`} disabled={isSubmitting}>{
                          isSubmitting ? `${t.exporting}...` : t.export
                        }</button>
                      </div>
                      <FormikErrorFocus offset={0} align="top" duration={1000} />
                    </Form>
                  )}
                </Formik>

              </div>
            </div>
          </div>
        </Grid>
      </Grid>


    </div>
  );
};

export default withTranslation()(CommissionsExport);
