import { createActions } from "redux-actions";

import { GenericError } from '../../../../utils/errors';

import {
  getOrders,
} from '../services/orders';

const OrdersActions = createActions({
  FETCH_ORDERS_REQUEST: () => { },
  FETCH_ORDERS_SUCCESS: (orders) => ({ orders }),
  FETCH_ORDERS_FAILED: (error) => ({ error }),

  GET_ORDER: () => { },
  CREATE_ORDER: () => { },
  DELETE_ORDER: () => { },
});


OrdersActions.fetchOrders = query => async (dispatch) => {
  const updatedQuery = { ...query };
    try {
      dispatch(OrdersActions.fetchOrdersRequest());
      if (updatedQuery.country === 'ALL') updatedQuery.country = null;
      const result = await getOrders(updatedQuery);
      dispatch(OrdersActions.fetchOrdersSuccess(result));
    } catch (err) {
      dispatch(OrdersActions.fetchOrdersFailed({ error: new GenericError(err, err) }));
    }
  };

export default OrdersActions;