import React from 'react';
import ReactDOM from "react-dom/client";
import 'normalize.css';
import './index.sass';
import 'animate.css/animate.min.css';
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/browser';
import store from './redux/store';
import ServiceWorkerActions from './redux/serviceWorker/actions';
import api from "./services/api";
import files from "./services/files";
import { setConfig } from './config';
import * as firebase from './services/firebase';

const googleConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_APIKEY
};

setConfig(
  {
    firebase,
    api,
    files,
    google: googleConfig
  });


if (process.env.REACT_APP_SENTRY !== 'disable') {
  console.log('Sentry initialized');
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY
  });
}

if (process.env.REACT_APP_GOOGLE_TAG_MANAGER && process.env.REACT_APP_GOOGLE_TAG_MANAGER !== 'disable') {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER
  });
}

// ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.createRoot(document.getElementById('root')).render(<App />);
serviceWorker.register({
  onSuccess: () => store.dispatch( ServiceWorkerActions.init() ),
  onUpdate: registration =>
    store.dispatch(ServiceWorkerActions.update(registration)),
});