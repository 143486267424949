/* eslint-disable no-param-reassign */
import { createActions } from "redux-actions";

import { GenericError } from '../../../../utils/errors';

import {
  getUsers
} from '../services/users';

const UsersActions = createActions({
  FETCH_USERS_REQUEST: () => { },
  FETCH_USERS_SUCCESS: (users) => ({ users }),
  FETCH_USERS_FAILED: (error) => ({ error }),

  GET_USER: () => { },
  CREATE_USER: () => { },
  DELETE_USER: () => { },
});


UsersActions.fetchUsers = (query) => async (dispatch) => {
    try {
      dispatch(UsersActions.fetchUsersRequest());
      if (query.country === 'ALL') query.country = null;
      const result = await getUsers(query);
      dispatch(UsersActions.fetchUsersSuccess(result));
    } catch (err) {
      dispatch(UsersActions.fetchUsersFailed({ error: new GenericError(err, err) }));
    }
  };

export default UsersActions;