/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDateZoneFormated } from "../../utils";


const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <DatePicker
      {...field}
      {...props}
      
      selected={(props.valueExternal && new Date(getDateZoneFormated(props.valueExternal, props?.timeZone))) || field.value && new Date(getDateZoneFormated(field.value, props?.timeZone))}
      onChange={(val) => {
        if(props.setFunction){
          props.setFunction(getDateZoneFormated(val, props?.timeZone, false));
        } else {
          setFieldValue(field.name, getDateZoneFormated(val, props?.timeZone, false));
        }
        switch (field.name) {
          case 'expectedDeliveryDate':
            setFieldValue('isChangedExpectedDeliveryDate', true);
            break;
          default:
            break;
        }
      }}
      onCalendarOpen={() => {
        if (field.value) {
          const aux = new Date(field.value);
          setFieldValue(field.name, aux);
          switch (field.name) {
            case 'expectedDeliveryDate':
              setFieldValue('isChangedExpectedDeliveryDate', true);
              break;
            default:
              break;
          }
        }
      }}
    />
  );
};
export default DatePickerField;