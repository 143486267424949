import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContainerOrdersListing from '../../components/shared-comp/orders/views/listing';
import OrdersActions from '../../components/shared-comp/orders/model/actions';
import WorkflowActions from '../../redux/workflow/actions';

const mapStateToProps = (state) => ({
  ordersLoading: state.orders.ordersLoading,
  ordersError: state.orders.ordersError,
  ordersCalled: state.orders.ordersCalled,
  orders: state.orders.orders,
  createDealLoading: state.orders.createDealLoading,
  createDealError: state.orders.createDealError,
  createdDeal: state.orders.createdDeal,
  country: state.workflow.country,
  countries: state.workflow.countries,
  statuses: state.workflow.statuses,
});


const mapDispatchToProps = (dispatch) => ({
  createOrder: bindActionCreators(OrdersActions.createOrder, dispatch),
  deleteOrder: bindActionCreators(OrdersActions.deleteOrder, dispatch),
  fetchOrders: bindActionCreators(OrdersActions.fetchOrders, dispatch),
  fetchOrdersFailed: bindActionCreators(OrdersActions.fetchOrdersFailed, dispatch),
  fetchOrdersRequest: bindActionCreators(OrdersActions.fetchOrdersRequest, dispatch),
  fetchOrdersSuccess: bindActionCreators(OrdersActions.fetchOrdersSuccess, dispatch),
  getOrder: bindActionCreators(OrdersActions.getOrder, dispatch),
  fetchCountries: bindActionCreators(WorkflowActions.fetchCountries, dispatch),
  fetchStatuses: bindActionCreators(WorkflowActions.fetchStatuses, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerOrdersListing);