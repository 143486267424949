import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContainerCommissionsListing from '../../components/shared-comp/commissions/views/listing';
import CommissionActions from '../../components/shared-comp/commissions/model/actions';
import WorkflowActions from '../../redux/workflow/actions';

const mapStateToProps = (state) => ({
  commissionsLoading: state.commissions.commissionsLoading,
  commissionsError: state.commissions.commissionsError,
  commissionsCalled: state.commissions.commissionsCalled,
  commissions: state.commissions.commissions,
  createCommissionLoading: state.commissions.createCommissionLoading,
  createCommissionError: state.commissions.createCommissionError,
  createdCommission: state.commissions.createdCommission,
  country: state.workflow.country,
  countries: state.workflow.countries,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCommissions: bindActionCreators(CommissionActions.fetchCommissions, dispatch),
  fetchCommissionsFailed: bindActionCreators(CommissionActions.fetchCommissionsFailed, dispatch),
  fetchCommissionsRequest: bindActionCreators(CommissionActions.fetchCommissionsRequest, dispatch),
  fetchCommissionsSuccess: bindActionCreators(CommissionActions.fetchCommissionsSuccess, dispatch),
  getCommission: bindActionCreators(CommissionActions.getCommission, dispatch),
  fetchCountries: bindActionCreators(WorkflowActions.fetchCountries, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerCommissionsListing);