/* eslint-disable consistent-return */
import { messaging } from './firebase';

export const registerNotifications = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await messaging.getToken();
      return token;
    }
  } catch (e) {
    console.log(e);
    console.log('Unable to get permission to notify.');
    throw e;
  }
};
