/* eslint-disable no-param-reassign */
import { getConfig } from '../../../../config';

let api;

const checkConfig = () => {
  if (!api) api = getConfig('api');
};

const defaultQuery = {
  "page": 1,
  "limit": 10,
  "sort": [],
  "q": null,
  "country": null
};

/**
 * Get the User with the given id
 * @param {string} id id of the User to get
 */
export const getUser = (id) => {
  checkConfig();
  return api.get(`/users/${id}?all=true`);
};

export const getUsers = (query) => {
  checkConfig();
  return api.get('/users', query);
};

export const postUsers = (data) => {
  checkConfig();
  return api.post('/users', data);
};

export const updateUser = (id, data) => {
  checkConfig();
  delete data.metadata.status;
  delete data.metadata.lapsed;
  delete data.metadata.reactivated;
  return api.put(`/users/${id}`, data);
};

/**
 * Delete the User with the given id
 * @param {string} id id of the User to delete
 */
export const deleteUser = (id) => {
  checkConfig();
  return api.delete(`/users/${id}`);
};

  /**
 * Soft delete the User with the given id
 * @param {string} id id of the User to delete
 */
export const softDeleteUser = (id) => {
  checkConfig();
  return api.put(`/users/status/${id}`, { status: 'deleted' });
};

export const getBuyers = (query) => {
  checkConfig();
  // TODO this should list customers and users but not agents
  return api.get('/users', { ...defaultQuery, ...query });
};

export const getUserMeta = () => {
  checkConfig();
  return api.get(`/users/meta-data`);
};

/**
 * Export users
 */
export const exportUsers = (data) => {
  checkConfig();
  return api.get(`/users/exportUsers`, data);
};

  /**
 * Update status of the User with the given id
 * @param {string} id id of the User to delete
 */
   export const updateStatusUser = (id, status) => {
    checkConfig();
    return api.put(`/users/status/${id}`, { status });
  };

  export const getPointsUser = (id, query) => {
    checkConfig();
    return api.get(`/pointsManage/${id}`, query);
  };


