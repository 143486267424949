/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import IcomoonReact from "icomoon-react";
import iconSet from "../../../../assets/icon-fonts/selection.json";
import styles from './styles.module.sass';

const Icon = ({ style: customStyle = {}, iconStyle = {}, className, description, icon = "", size = "20", color = "#000", title, ...props }) => (
  <div style={customStyle} className={`${styles.container} ${className}`} title={title} >
    <IcomoonReact style={iconStyle} iconSet={iconSet} icon={icon} size={size} color={color} {...props} />
    {description && <div>{description}</div>}
  </div>
);

export default Icon;