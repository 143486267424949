/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import SearchSelect from '../../../core/SearchSelect';
import Icon from "../../../core/Icon";
import BasicModal from '../../../core/BasicModal';
import PayoutsCreate from '../../../commissions/views/forms/PayoutsCreate';
import { putPayoutsBulk } from '../../services/payout';
import { modules, capitalize, tempPayoutStatuses, crudAction, actions, formCol } from '../../../utils';
import Dialog from '../../../core/Dialog';
import themeStyles from '../../../../../theme.module.sass';
import styles from '../../../commissions/views/listing/styles.module.sass';
import ListView from '../../../core/ListView';
import { columnTypes } from '../../../core/TableCommon';

const PayoutListing = ({ t, payouts, payoutsLoading, fetchPayouts, country, countries, fetchCountries }) => {

  const module = modules(t).payout;
    const dialogRef = useRef();
  const [dialogContent, setDialogContent] = useState('');
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [dialogResult, setDialogResult] = useState(false);
  const [updateData, setUpdateData] = useState({});


  
  
  const queryParamsFilters = {
    page: 1,
    limit: 10,
    sort: [],
    q: null,
    country,
    refresh: 0
  };

  const badgeColor = {
    placed: 'badge-success',
    confirmado: 'badge-success',
    delivered: 'badge-success',
    entregado: 'badge-success',
    'out for delivery': 'badge-primary',
    'en reparto': 'badge-primary',
    created: 'badge-warning',
    creado: 'badge-warning',
    shipped: 'badge-secondary',
    enviado: 'badge-secondary',
    canceled: 'badge-danger',
    paid: 'badge-info',
    pending: 'badge-warning',
    cancelado: 'badge-danger',
    pago: 'badge-info',
    pendiente: 'badge-warning',
    order: 'badge-secondary',
    orden: 'badge-secondary',
    reward: 'badge-light',
    incentivo: 'badge-light',
    unpaid: 'badge-warning',
    'no pagado': 'badge-warning'
  };

 

  const [checkPayouts, setCheckPayouts ] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [statusSelected, /* */ ] = useState('');
  const [externalRefresh, setExternalRefresh] = useState(0);
  const [tableRows, setTableRows] = useState(null);
  // TODO Remove?
  const [tableRows2, /* setTableRows2 */] = useState(null);

  const [queryParamsFiltersInit, setQueryParamsFiltersInit] = useState(queryParamsFilters);

  const refreshCurrentQuery = () => {
    const newParams = { ...queryParamsFiltersInit, refresh: (queryParamsFiltersInit.refresh + 1) };
    setQueryParamsFiltersInit(newParams);
  };

  const createData = (data) => {
    const defaultColorClass = 'badge-light';
    const { socioName, userId, dateOfPayout, status, commissions, reward } = data;
    // const name = data?.referrer?.fullName;
    const buttonHtml = <Icon className={themeStyles.clickable} color="#310f49" size={15} icon="edit_cover-" />;
    const editHtml = <BasicModal content={PayoutsCreate} options={{ html: buttonHtml }} object={data} onClose={()=> setExternalRefresh(externalRefresh + 1)} />;
    // const totalCommissionHtml = <Price />;

    const {rewards} = data;
    let totalComisiones = 0;
    let totalBono = 0;

    const formatStatus = (rawStatus) => {
      const result = rawStatus?.toUpperCase();
      return result;
    };

    rewards.map((e) => {
      totalBono += e.commissionAmount;
    });

    commissions.map((e) => {
      totalComisiones += e.orderTotal;
    });
    // commisions: commissions.join(', ')
    // totalCommissionHtml
    // description
    const total = totalComisiones + totalBono;
    return {
      obj: {

      }, socioName, userId, reward, totalComisiones, totalBono, total, dateOfPayout, status: {
        colorClass: badgeColor[status.toLowerCase()] || defaultColorClass,
        description: /* tracking code? */'',
        value: formatStatus(t(capitalize(status)))
      }, actions: editHtml, data
    };
  };

  const tableColumns = [
    { label : 'SELECTALL', type : columnTypes.SELECT, alignment : 'center', method :() => {
      const aux = checkPayouts;
      if (payouts && payouts.data) {
        payouts.data.map((a) => {
          aux[a.id] = !selectAll;
        });
      }
      setTableRows(payouts.data.map(createData));
      setSelectAll(!selectAll);
    },isChecked : selectAll },
    { label : 'Socio Name' },
    { label : 'ID' },
    { label : 'Account data' },
    { label : 'Commission_other' },
    { label : 'Bonuses' },
    { label : 'Total' },
    { label : 'Date of delivery', type : columnTypes.DATE , sort : 'created_at'},
    { label : 'Status', type : columnTypes.BADGE, alignment : 'center' , sort : 'status'},
    { alignment: 'center' },
  ];

  // TODO Check useEffects dependencies

  useEffect(() => {
    const asyncFunc = async () => {
      const data = updateData;
      const updateCom = await crudAction(putPayoutsBulk.bind(null, data),
        () => { }, {
        name: '',
        module: { ...modules(t).status, name: 'Payout Status' },
        action: actions.UPDATE
      }, t, false
      );
      if (updateCom?.ok) {
        setCheckPayouts({});
        setSelectAll(false);
        refreshCurrentQuery();
      }
    };
    if (dialogResult) asyncFunc();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogResult]);
  
  const translatedSimpleOptions = (optionsArray, shouldCapitalize = false) => (
    optionsArray.map(value => ({ label: shouldCapitalize ? t(capitalize(value)) : t(value), value }))
  );

  const changePayoutStatus = async (e) => {
    const status = e.value;
    const ids = [];
    for (const [key, value] of Object.entries(checkPayouts)) {
      if (value) {
        ids.push(Number(key));
      }
    }
    if (ids.length > 0) {
      setUpdateData({ status, payouts: ids });
      setDialogContent(t('confirmStatusChangeForNumberObject', { count: ids.length, object: t('Payout', { count: ids.length }) }));
      setDialogConfirm(true);
      dialogRef.current.handleClickOpen();
      // let a = confirm(`${t('Are you sure you want to change the status of ')}${ids.length} ${t('payouts')} ${t('to_1')} ${t(capitalize(status))}`);
    } else {
      setDialogContent(t('choose_some_payout'));
      setDialogConfirm(false);
      dialogRef.current.handleClickOpen();
      // alert(`${t('choose_some_payout')}`)
    }
  };

  const subTableColumns = [
    { label: 'Order ID' },
    { label: 'Client', alignment: 'center' },
    { label: 'Product Name', alignment: 'center' },
    { label: 'Qty', type: columnTypes.TEXT, alignment: 'center' },
    { label: 'Total Price', alignment: 'center' },
    { label: 'Commission Type', alignment: 'center' },
    { label: 'Commission', alignment: 'center' },
  ];


  const subTableColumns2 = [
    formCol({ label: 'Bonus' }),
    formCol({ label: 'Descriptions', alignment: 'center' }),
    formCol({ label: 'Value', alignment: 'center' }),
    formCol({ label : 'Actions', alignment : 'center'})

  ];

  const updateStatus = () => <div className={styles['container-main']}>
  <section className={`${styles["update-selected"]} ${themeStyles["form-wrap"]}`}>
    <label>{t('Update Selected Payouts:')}</label>
    <SearchSelect className={styles["update-item"]} placeholder="" value={statusSelected} options={[
      ...translatedSimpleOptions(tempPayoutStatuses.data, true).map(option => ({ 'label': option.label, 'value': option.value }))
      ]}
    onChange={changePayoutStatus} onBlur={() => { }} />
  </section>
</div>;

// createData2 rewards

  return (
    <section className={themeStyles["inner-component-view"]}>
      <Dialog ref={dialogRef} content={dialogContent} alert confirm={dialogConfirm} confirmUpdate={setDialogResult} />
      <ListView collection={payouts} countries={countries} country={country} toupdateTable={refreshCurrentQuery} externalTableRows={tableRows} externalTableRows2={tableRows2}
        createData={createData} conditionTable="condition" subTableColumns={subTableColumns} subTableColumns2={subTableColumns2} expandAllRows
        externalRefresh={externalRefresh} externalComponentTop={updateStatus} subtableClass={styles.subtable}
        fetchCountries={fetchCountries} fetchFunction={fetchPayouts} module={module} loadingTable={payoutsLoading}
        tableColumns={tableColumns}
      />
    </section>
  );
};

export default withTranslation()(PayoutListing);