import { createTheme } from '@mui/material/styles';

const vars = {
  primary: {
    // main: "#6B246B"
    main: "#a646eb"
  },
  secondary: {
    main: "#39DECB"
  },
  error: {
    main: "#f67173"
  },
  warning: {
    main: "#FFBE13"
  },
  dark: {
    main: "#2B303A"
  },
  yellow: {
    main: "#FFBE13"
  },
  red: {
    main: "#f67173"
  },
  orange: {
    main: "#FF7B24"
  },
};

// Create a theme instance.
export const theme = createTheme({
  palette: {
    primary: {
      main: vars.primary.main
    },
    secondary: {
      main: vars.secondary.main
    },
    error: {
      main: vars.error.main
    },
    warning: {
      main: vars.warning.main
    },
    dark: {
      main: vars.dark.main
    },
    yellow: {
      main: vars.yellow.main
    },
    red: {
      main: vars.red.main
    },
    orange: {
      main: vars.orange.main
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 16,
    body2: {
      fontFamily: "'Work Sans', sans-serif",
      fontSize: 16
    },
    button: {
      textTransform: 'none'
    }
  },
  shape: {
    borderRadius: 5
  },
  spacing: 8,
  components: {
    MuiFilledInput: {
      root: {}
    },
    MuiOutlinedInput: {
      root: {
        // fontSize: "24px",
        // padding: ".7em 1em"
      },

    },
    MuiFormControl: {
      root: {}
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {},
        outlined: {
          transform: 'translate(14px, 14px)',
          '&$shrink': {},
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {},
        input: {
          padding: '0 1em!important',
          fontSize: '14px',
          height: 40
        },
        multiline: {
          backgroundColor: 'red',
          fontSize: '16px!important'
        }
      }
    },
    MuiTextField: {
      root: {},
      multiline: {
        // MuiOutlinedInput-multiline
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          // backgroundColor: "red",
        },
        outlined: {
          minHeight: '40px!important',
          lineHeight: '40px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          padding: ".7em 2.5em",
          height: 40,
          boxShadow: 0,
          elevation: false,
          fontWeight: 700
        },
        fullWidth: {}
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#f1f1f1',
          // outline: '10px solid rgb(220, 220, 220)'
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: '700',
          fontSize: 15,
          lineHeight: '1em'
        },
        body: {
          fontSize: 15,
        },
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(even)': {
            backgroundColor: '#fdfcfe',
          },
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontWeight: 700,
          padding: ".7em 2em",
          fontSize: 14
        },
        filledSuccess: {
          backgroundColor: vars.secondary.main,
          fontWeight: 700,
        },
        filledInfo: {
          backgroundColor: vars.primary.main,
          fontWeight: 700,
        },
        filledWarning: {
          backgroundColor: vars.warning.main,
          fontWeight: 700,
        },
        filledError: {
          backgroundColor: vars.error.main,
          fontWeight: 700,
        },
      }
    }
  },
  props: {
    MuiButtonBase: {
      // disableElevation: true,
      elevation: 0,
    },
    MuiButton: {
      disableRipple: true,
      // disableElevation: true,
      elevation: 0,
      variant: "contained",
      color: "primary"
    },
    MuiFormControl: {
      fullWidth: true
    },
    MuiSelect: {
      variant: "outlined",
      color: "secondary",
      fullWidth: true
    },
    MuiCheckbox: {
      disableRipple: true
    },
    MuiTextField: {
      color: "secondary",
      variant: "outlined",
      fullWidth: true,
      InputLabelProps: {
        shrink: false
      }
    },
    MuiPaper: {
      elevation: 1
    },
    MuiCard: {
      elevation: 1
    }

  }
});