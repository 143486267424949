import OrdersActions from "./actions";
import InitialState from ".";

// eslint-disable-next-line default-param-last
export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case OrdersActions.fetchOrdersRequest().type:
      return state.merge({
        ordersLoading: true,
        ordersError: null,
        ordersCalled: false,
      });

    case OrdersActions.fetchOrdersSuccess().type:
      return state.merge({
        ordersLoading: false,
        ordersError: null,
        ordersCalled: true,
        orders: action.payload.orders.data || state.orders,
      });

    default:
      return state;
  }
};
