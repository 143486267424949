import Immutable from 'seamless-immutable';

const initialState = Immutable({
  loginLoading: false,
  loginError: null,
  loginCalled: false,

  createAccountLoading: false,
  createAccountError: null,
  createdAccount: false,

  createGuestAccountLoading: false,
  createGuestAccountError: null,
  createdGuestAccount: false,

  updateAccountLoading: false,
  updateAccountError: null,
  updateAccountCalled: false,

  forgotPasswordLoading: false,
  forgotPasswordError: null,
  forgotPasswordCalled: false,

  emailVerificationLoading: false,
  emailVerificationError: null,
  emailVerificationCalled: false,

  changePasswordLoading: false,
  changePasswordError: null,
  changedPassword: false,

  uploadProfilePictureLoading: false,
  uploadProfilePictureError: null,
  uploadedProfilePicture: false,

  loggedIn: false,
  user: null,
  checkingUser: true,
  emailVerified: false,
  firebaseAuthId: null
});


export default initialState;
