import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// import NavigationBar from '../../../components/NavigationBar'
// import TextField from '../../../components/TextField'
import { Button } from '@mui/material';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { useSelector, useDispatch } from 'react-redux';
import AuthActions from '../../../redux/auth/actions';

import styles from './styles.module.sass';

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Enter a valid email')
    .required('This is a required field'),
  password: Yup.string()
    .required('This is a required field')
});

const Login = ({ history }) => {

  const dispatch = useDispatch();

  const {
    loginCalled,
    // loginLoading,
    loginError,
  } = useSelector( state => state.auth );

  const emailVerified = useSelector((state) => state.auth.emailVerified);

  useEffect( () => {
    if (loginCalled && loginError === null && emailVerified === false) {
      history.replace('email-verification');
    }
  }, [history, loginCalled, loginError, emailVerified]);

  useEffect( () => {
    dispatch( AuthActions.resetLoginState() );
  }, [dispatch]);

  function handleSubmit(values) {
    dispatch( AuthActions.loginWithEmailAndPassword(values.email, values.password) );
  }

  const handleSignInWithGoogle = () => {
    dispatch(AuthActions.signInWithGoogle());
  };

  return (
    <Box className={styles.container}>
      {/* <NavigationBar
        title="Login"
        hasBackButton
        history={history}
        customBack={() => {history.replace('create-account')}}/> */}
      <Container className={styles.content}>
        <Box>
          <Box className={styles.container_form}>
            <h4>Login</h4>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values);
                setSubmitting(false);
              }}>

              {({handleSubmit: handleThisSubmit}) => (
                <form noValidate onSubmit={handleThisSubmit}>
                  <Box className={styles.container_forgot_password} >
                    <Button variant="contained" color="primary" className="btn-block" onClick={handleSignInWithGoogle}>
                      Sign in with Google
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
