import RecomendationsActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case RecomendationsActions.fetchRecomendationsRequest().type:
      return state.merge({
        recomendationLoading: true,
        recomendationError: null,
        recomendationLoaded: false,
      });

      case RecomendationsActions.fetchRecomendationsSuccess().type:
        return state.merge({
          recomendationLoading: false,
          recomendationError: null,
          recomendationLoaded: true,
          recomendations: action.payload.recomendations.data || state.recomendations,
        });


    default:
      return state;
  }
};
