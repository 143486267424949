/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormikErrorFocus from 'formik-error-focus';
import { withTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { actions, crudAction, modules, getFileExtension, getUrlExtension, mimeTypes, getAssetType, makeId } from '../../../utils';
import orderStyles from './styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import dealStyles from '../../../deals/views/forms/styles.module.sass';
import { updateOrdersBulk } from '../../services/orders';
import { upload } from '../../../core/services/upload';
import UploadFile from '../../../core/UploadFile';
import Icon from "../../../core/Icon";
import ModalCloseButton from '../../../core/ModalCloseButton';
import { toast } from 'react-toastify';

const OrdersBulkUpdate = ({ onClose = () => { }, ...props }) => {
  let mediaArray = [];
  
  const module = modules(props.t).order;

  const t = {
    updateOrders: props.t('Update Orders'),
    emails: props.t('Email Addresses'),
    required: props.t('Required'),
    upload: props.t('Upload'),
    uploading: props.t('Uploading'),
    mailsErrors: props.t('One or more email is not valid'),
  };

  const maximumFileSizeText = "5MB";
  const defaultUploadAccept = [
    '.csv'
  ];

  

  const crudOptions = {
    module,
    name: 'bulk update',
  };

  function validateMails(msg) {
    return Yup.mixed().test({
      name: 'validateMails',
      exclusive: false,
      message: msg || t.mailsErrors,
      test (value) {
        const validator = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g;
        return validator.test(value);
      },
    });
  }
  Yup.addMethod(Yup.string, 'validateMails', validateMails);


  // Form placeholders
  const placeholder = {
    emails: props.t('doe@wao.shop, doe2@wao.shop')
  };

  // Formik initial/default values
  const formInitialValues = {
    emails: "",
    media: [],
  };

  // Formik validation
  const BulkSchema = Yup.object().shape({
    emails: Yup.string().required(t.required).validateMails(),
    media: Yup.array().min(1, props.t('Required')).max(1, `${props.t('file_length')}`),
  });

  /**
   * Function called whenever the Formik form is submitted
   * @async
   * @param {Object} values Form values
   * @param {Object} options Functions provided by the Formik form
   */
  const formOnSubmit = async (values) => {

    let newMedia = '';
    if (values.media && values.media.length > 0) {
      await Promise.all(values.media.map(async (mediaObject) => {
        let url = null;
        if (mediaObject?.file) { // FILE
          const key = makeId(30);
          const ext = getFileExtension(mediaObject.file.name);
          const filePatch =  `input-files/${key}.${ext}`;
          url = await upload(mediaObject.file, filePatch);
          
        }
        try {
          if (url) newMedia = url;
        } catch (error) {
          toast.error(`${props.t('Upload fail')}: ${error}`);
        }
      }));
    }
      const body = {
        emails: values.emails.replace(/\s+/g, '').split(','),
        urlBulk: newMedia
      };

      await crudAction(updateOrdersBulk.bind(null, body), onClose,
        { ...crudOptions, action: actions.UPLOAD }, props.t
      );
  };

  const renderAsset = (fileUrl, fileType) => {
    let result = "";
    if (!fileType) result = "";
    // eslint-disable-next-line no-param-reassign
    if (mimeTypes[fileType]) fileType = mimeTypes[fileType];
    if (getAssetType(fileType) === 'application')
      result = <embed className={dealStyles["deal-image"]} key={`${fileUrl }x`} src={fileUrl} width="160px" height="240px" />;
    else if (getAssetType(fileType) === 'text')
      result = <Icon color="#176d31" size={130} icon="file-csv" />;
    if (result) result = <div> {result} </div>;
    return result;
  };

  return (
    <div>
      <ModalCloseButton onClick={() => onClose(true)} floating />
      <Grid container spacing={0} alignItems="flex-start" >
        <Grid item xs>
          <div className={themeStyles.currentContent}>
            <div className={orderStyles["order-view"]}>
              <header className={themeStyles["header-form"]}>
                <h3>{t.updateOrders}</h3>
                <span className={dealStyles["description-bulk"]}>{props.t('Description_bulk')}</span>
              </header>
              <div className={themeStyles["form-wrap"]}>
                <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formOnSubmit} validationSchema={BulkSchema} autoComplete="off" >
                  {({ isSubmitting, values, handleChange, handleBlur, setFieldValue, errors, touched }) => (
                    <Form>
                      <div className={themeStyles["form-group"]} >
                        <label htmlFor="emails" className={themeStyles["col-form-label"]}>{t.emails}<span className={themeStyles["label-asterisk"]}>*</span></label>
                        <Field className={themeStyles["form-control"]} name="emails" placeholder={placeholder.emails} value={values.emaisl} onChange={handleChange} onBlur={handleBlur} />
                        {errors.emails && touched.emails ? (<div className={themeStyles.formError}>{errors.emails}</div>) : ' '}
                      </div>
                      <Grid container spacing={1} className={themeStyles["form-group"]}>
                          <Grid item xs={12}>
                            {(values.media && values.media.length > 0) ? (
                              <div className={dealStyles["deal-file"]} >
                                <label htmlFor="file" className={themeStyles["col-form-label"]}>{props.t('Media Files')}</label>
                                {
                                  !values.media || values.media.length < 1 ? (' ') : (
                                    <Grid container spacing={3} justifyContent="center" alignItems="center" alignContent="center">
                                      {values.media.map((mediaObject, index) => (
                                        <Grid container item xs={4} key={index}>
                                          <div className={dealStyles["media-panel"]}>
                                            <div className={dealStyles["media-container"]}>
                                              {!mediaObject?.file ? renderAsset(mediaObject.url, getUrlExtension(mediaObject.url)) : renderAsset(mediaObject.url, mediaObject.file.type)}
                                            </div>
                                            <div className={dealStyles["media-attr"]}>
                                              <span className={dealStyles["media-filename"]}>
                                                <a href={!mediaObject?.file ? mediaObject.url : mediaObject.url} target="_blank" rel="noreferrer">{`Media ${ index + 1}`}</a>
                                                <i>
                                                  <Icon color="#000" size={13} icon="delete-" className={dealStyles["trash-icon"]} onClick={() => {
                                                    const filesCopy = [...mediaArray];
                                                    filesCopy.splice(index, 1);
                                                    mediaArray = [...filesCopy];
                                                    setFieldValue('media', mediaArray);
                                                  }} />
                                                </i>
                                              </span>
                                            </div>
                                          </div>
                                        </Grid>
                                      ))}
                                      {values.media.length % 3 === 1 ? <Grid item xs={8} /> : ''}
                                      {values.media.length % 3 === 2 ? <Grid item xs={4} /> : ''}
                                    </Grid>
                                  )
                                }
                              </div>
                            ) : ("")}
                            {values.media.length === 0 ?
                              <div className={dealStyles["upload-file-section"]}>
                                <label htmlFor="file" className={themeStyles["col-form-label"]}>{props.t('Upload File')}<span className={dealStyles["media-file-size"]}>{props.t('maximumFileSize', { size: maximumFileSizeText })}</span></label>
                                <UploadFile
                                  successFunction={(files) => {
                                    const updatedMediaFiles = files.map(file => ({ file, url: URL.createObjectURL(file)}));
                                    mediaArray = [...mediaArray, ...updatedMediaFiles];
                                    setFieldValue('media', mediaArray);
                                  }}
                                  errorFunction={(message) => {
                                    toast.error(`${props.t('Unable to upload asset') }: ${ message}`);
                                  }}
                                  accept={defaultUploadAccept}
                                  fileTypesAllowed="CSV"
                                />
                                {errors.file && touched.file ? (<div className={themeStyles.formError}>{errors.file}</div>) : ' '}
                                {errors.media ? (<div className={themeStyles.formError}>{errors.media}</div>) : ' '}
                              </div>
                              :
                              <div className={dealStyles["upload-file-section"]}>
                                {errors.file && touched.file ? (<div className={themeStyles.formError}>{errors.file}</div>) : ' '}
                                {errors.media ? (<div className={themeStyles.formError}>{errors.media}</div>) : ' '}
                              </div>
                            }
                          </Grid>
                        </Grid>

                      <div className={themeStyles["form-crud-actions"]}>
                        <button type="submit" className={`${themeStyles.btn } ${ themeStyles["btn-primary"]}`} disabled={isSubmitting}>{
                          isSubmitting ? `${t.uploading}...` : t.upload
                        }</button>
                      </div>
                      <FormikErrorFocus offset={0} align="top" duration={1000} />
                    </Form>
                  )}
                </Formik>

              </div>
            </div>
          </div>
        </Grid>
      </Grid>


    </div>
  );
};

export default withTranslation()(OrdersBulkUpdate);
