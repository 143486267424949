/* eslint-disable no-underscore-dangle */
import React from 'react';
import { withTranslation } from 'react-i18next';
// MUI Components
import { Button, CircularProgress } from '@mui/material';
import styles from './styles.module.sass';
import stylesRoot from '../../../../theme.module.sass';
import BasicModal from '../BasicModal';
import SearchFilter from '../SearchFilter';
import { Link } from 'react-router-dom';
import Icon from '../Icon';

const HeaderViews = ({ t, loading, customTitle, showTitle = true, refresh = () => { }, options = {}, module, createContent, exportContent, bulkUpdateContent, modalSize, applyFilter, loadOptions, count, expanded = false, setExpanded = () => { }, location /* , ...props */ }) => {

  function ButtonModal(compProps) {
    let comp = false;
    const { typeButton } = compProps;
    switch (typeButton) {
      case 'export':
        comp = <BasicModal loading={loading} content={exportContent} typeButton={typeButton} onClose={refresh} modalSize="md" options={options} exportType={module.name} />;
        break;
      case 'bulkUpdate':
        comp = <BasicModal loading={loading} content={bulkUpdateContent} typeButton={typeButton} onClose={refresh} modalSize="md" options={options} />;
        break;
      default:
        comp = <BasicModal loading={loading} content={createContent} typeButton={typeButton} onClose={refresh} modalSize={modalSize} options={options} />;
        break;
    }

    return comp;
  }

  let filterButtonClasses = `${styles["filters-button"]} ${stylesRoot.clickable}`;
  if (expanded) filterButtonClasses += ` ${styles.selected}`;

  return (
    <header style={{ flexDirection: "column" }} className={styles['header-views']}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "24px", gap: "16px", flexWrap: "wrap" }}>
        <div>{showTitle ? <div><h4>{t(customTitle || module.plural, { count: 100 })}</h4></div> : ''}</div>
        <div style={{ display: "flex", gap: "16px", alignItems: "center", flexGrow: 1, flexWrap: "wrap" }} className={stylesRoot["form-wrap"]}>
          <SearchFilter customStyles={{ flexGrow: 2, minWidth: "320px", maxWidth: "700px" }} applyFilter={applyFilter} loadOptions={loadOptions} />
          <div className={filterButtonClasses} onClick={() => { setExpanded(!expanded); }}>
            <div className={styles.text}>{t("Filters")}</div>
            <div className={styles.button}><Icon color={expanded ? "#893b90" : "#000000"} size={15} icon="filter-" /></div>
          </div>
          {
            loading ? <CircularProgress size="1rem" color="primary" /> :
              <div style={{ color: "#8E939C", fontFamily: 'Work Sans', fontSize: "14px", fontWeight: "500", display: "flex", flexDirection: "column" }}>
                <div>{count}</div>
                <div>{t("amountModuleListed", { count, module: module.name, gender: module.gender })}</div>
              </div>
          }
        </div>
        <div style={{ marginLeft: "auto" }}>
          <div className={styles.__actions}>
            {exportContent ? <ButtonModal typeButton="export" /> : null}
            {bulkUpdateContent ? <ButtonModal typeButton="bulkUpdate" /> : null}
            {createContent ? <ButtonModal typeButton="create" /> : null}
            {location ? <Link to={{ pathname: location }}><Button variant="contained" color="secondary" >{t('Create')}</Button></Link> : null}
          </div>
        </div>
      </div>
    </header>
  );
};

export default withTranslation()(HeaderViews);