/* eslint-disable camelcase */
/**
* Get the city and set the city input value to the one selected
*
* @param addressArray
* @return {string}
*/
const getCity = (addressArray) => {
  let city = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0]) {
      for (let j = 0; j < addressArray[i].types.length; j++) {
        if (addressArray[i].types[j] === 'locality' || addressArray[i].types[j] === 'postal_town') {
          return addressArray[i].long_name;
        } if (addressArray[i].types[j] === 'administrative_area_level_2') {
          city = addressArray[i].long_name;
        } else if (!city && addressArray[i].types[j] === 'administrative_area_level_1') {
          city = addressArray[i].long_name;
        }
      }
    }
  }
  return city;
};

/**
* Get the neighborhood and set the neighborhood input value to the one selected
*
* @param addressArray
* @return {string}
*/
const getNeighborhood = (addressArray) => {
  let neighborhood = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0]) {
      for (let j = 0; j < addressArray[i].types.length; j++) {
        if (addressArray[i].types[j] === 'neighborhood') {
          return addressArray[i].long_name;
        } if (addressArray[i].types[j] === 'sublocality_level_1') {
          neighborhood = addressArray[i].long_name;
        } else if (!neighborhood && addressArray[i].types[j] === 'locality') {
          neighborhood = addressArray[i].long_name;
        }
      }
    }
  }
  return neighborhood;
};

/**
* Get the address and set the address input value to the one selected
*
* @param addressArray
* @return {string}
*/
const getState = (addressArray) => {
  let state = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && addressArray[i].types[0] === 'administrative_area_level_1') {
      state = addressArray[i].long_name;
      return state;
    }
  }
  return state;
};


/**
* Get the city and set the city input value to the one selected
*
* @param addressArray
* @return {string}
*/
const getZipcode = (addressArray, countryCode) => {
  let zipcode = '';
  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types[0] && addressArray[i].types[0] === 'postal_code') {
      zipcode = addressArray[i].long_name;
      // Remove first letter from Argentina's ZipCodes
      if (countryCode?.toLowerCase() === 'ar' && (/[a-zA-Z]/).test(zipcode[0])) zipcode = zipcode.substr(1);
      return zipcode;
    }
  }
  return zipcode;
};

/**
 * Extracts the City value from a Google Plus Code
 * @param {*} plusCode
 * XCFW+CF Viña del Mar, Chile
 * [XCFW+CF Viña del Mar, Chile]
 * [XCFW+CF Viña del Mar]
 * XCFW+CF Viña del Mar
 * [XCFW+CF, Viña, del, Mar]
 * [Viña, del, Mar]
 * Viña del Mar
 * @returns City name
 */
const getCityFromPlusCode = (plusCode) => plusCode?.compound_code.split(',').slice(0, 1)[0].split(' ').slice(1).join(' ');

export const getLocationFromPlace = (place, countryCode) => {
  let city;
  const { lat, lng } = place?.geometry?.location || {};
    const { formatted_address, address_components, plus_code } = place;
    const zipCode = getZipcode(address_components, countryCode);
    const neighborhood = getNeighborhood(address_components);
    const state = getState(address_components);

  if (plus_code && plus_code.compound_code) {
    city = getCityFromPlusCode(plus_code);
  } else {
    city = getCity(address_components);
  }
  const result = { lat, lng, formatted_address, address_components, zipCode, city, state, neighborhood, country: countryCode };
  return result;
};

// This fixed the issue where 'Cra. 17 ##119-21, Bogotá, Colombia' turned into 'Cra. 17, Bogotá, Colombia'
export const cleanString = addressString => {
  let result = addressString;
  result = result.replace('##', '#');
  return result;
};