/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable camelcase */

import React, { useState, useEffect, useRef, useCallback, useMemo, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { columnTypes } from '../../../core/TableCommon';
import Icon from "../../../core/Icon";
import BasicModal from '../../../core/BasicModal';
import stylesTableCommon from '../../../core/TableCommon/styles.module.sass';
import OrdersCreate from '../forms/OrdersCreate';
import OrdersExport from '../forms/OrdersExport';
import OrdersBulkUpdate from '../forms/OrdersBulkUpdate';
import themeStyles from '../../../../../theme.module.sass';
import { Grid, Tooltip } from '@mui/material';
import { modules, actions, crudAction, durationFromNow, translatedSimpleOptions, formatTimestamp, getThumbnail, toFloat, badgeColor, reasonInsteadOfSubstastus, callAssignToast /* , dateToOtherZone */ } from '../../../utils';
import SearchSelect from '../../../core/SearchSelect';
import Dialog from '../../../core/Dialog';
import style from './styles.module.sass';
import ListView from '../../../core/ListView';
import { getOrders, reviewFraudOrders, updateOrderStatusesBulk } from '../../services/orders';
import Tabber from '../Tabber';
import { getBuyers } from '../../../referrers/services/referrers';
import { getDeals } from '../../../deals/services/deals';
import Price from '../../../core/Price';
import Menu, { createItem } from '../../../core/Menu';
import FraudModal from '../FraudModal';
import { getUserFromFirebase } from '../../../core/services/auth';
import { OrderIdContext } from '../../context/orderIdContext';
import { getCountry } from '../../../core/services/workflow';
import moment from 'moment';

const fraudLowerLimit = 0.3;
const fraudHigherLimit = 0.65;
const lowFraudColor = "#28a745";
const midFraudColor = "#ffc107";
const hiFraudColor = "#dc3545";

const textColor = { danger: "#dc3545" };

const textColorItemSelect = {
  chargeback: textColor.danger,
};

const centerNoBreakVerticalStyle = {
  display: "flex",
  justifyContent: "center",
  whiteSpace: "pre",
  flexDirection: 'column',
  minWidth: "120px"
};

const subBasePriceStyle = {
  color: "#938c9c", fontSize: "12px",
  display: "flex",
  justifyContent: "center"
};

const OrdersListing = ({ t, orders, fetchOrders, ordersLoading, country, countries, fetchCountries, fetchStatuses, statuses }) => {
  const module = modules(t).order;
  const queryParamsFilters = {
    page: 1,
    limit: 10,
    sort: [],
    q: null,
    country,
    refresh: 0,
    entities: {}
  };

  const [paymentStatusOptions, setPaymentStatusOptions] = useState([]);
  const [paymentStatusSelected, setPaymentStatusSelected] = useState('');
  const [orderStatusOptions, setOrderStatusOptions] = useState([]);
  const [orderStatusSelected, setOrderStatusSelected] = useState('');
  const [orderReasonSelected, setOrderReasonSelected] = useState('');
  const [commissionStatusOptions, setCommissionStatusOptions] = useState([]);
  const [commissionStatusSelected, setCommissionStatusSelected] = useState('');
  const [statusLabels, setStatusLabels] = useState([]);
  const [checkOrders, setCheckOrders] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [orderReasons, setOrderReasons] = useState([]);
  const [currentOrderReasons, setCurrentOrderReasons] = useState([]);
  
  const dialogRef = useRef();
  const [dialogCloseIcon, setDialogCloseIcon] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [dialogResult, setDialogResult] = useState(false);
  const [externalRefresh, setExternalRefresh] = useState(0);
  const [selectRefresh, setSelectRefresh] = useState(0);
  const [updateData, setUpdateData] = useState({});
  const [queryParamsFiltersInit, setQueryParamsFiltersInit] = useState(queryParamsFilters);
  const [shouldExpandAllRows, setShouldExpandAllRows] = useState(true);
  const [tabStatus, setTabStatus] = useState(0);
  const [isAbandoned, setIsAbandoned] = useState(false);

  const user = getUserFromFirebase();
  const { email } = user;
  const orderIdContext = useContext(OrderIdContext);
  const { addOrderId, getOrderId, removeOrderId } = orderIdContext;
  const { ids } = getOrderId(email);

  const changeCommissionStatus = e => {
    const status = e.value;
    setCommissionStatusSelected(status);
  };

  const changePaymentStatus = e => {
    const status = e.value;
    setPaymentStatusSelected(status);
  };

  const changeOrdeStatus = e => {
    const status = e.value;
    setOrderStatusSelected(status);
  };

  const changeOrderReason = e => {
    const reason = e.value;
    setOrderReasonSelected(reason);
  };


  const disableOptionsSelect = (currentValidate) => {
    // add options needed to disable
    let disable = false;

    if (currentValidate === 'chargeback') disable = true;

    return disable;
  };

  const showMessage = ({ message, title, close }) => {
    setDialogCloseIcon(!!close);
    setDialogTitle(title);
    setDialogContent(message);
    setDialogConfirm(false);
    dialogRef.current.handleClickOpen();
  };

  const toggleExpandAllRows = () => {
    setShouldExpandAllRows(!shouldExpandAllRows);
  };

  const returnOrderTerms = useCallback(async (queryTerm) => {
    let buyersResult; let dealsResult; let ordersResult;
    const defaultQuery = {
      "page": 1,
      "limit": 40,
      "sort": [],
      "q": queryTerm,
      "country": country,
    };

    try {
      buyersResult = await callAssignToast(getBuyers.bind(null, defaultQuery), () => { }, 'list Users');
      dealsResult = await callAssignToast(getDeals.bind(null, defaultQuery), () => { }, 'list Deals');
      ordersResult = await callAssignToast(getOrders.bind(null, { ...queryParamsFiltersInit, ...defaultQuery }), () => { }, 'list Orders');
    } catch (error) {
      console.log('Failed', error);
    }

    let searchTermsResult = [];

    if (buyersResult.data || dealsResult.data || ordersResult.data) {
      const resultArray = [];
      const idsArray = [];
      const dealsArray = [];
      const referrersArray = [];
      const customersArray = [];

      buyersResult.data.forEach(buyer => {
        const name = buyer.firstName + ((buyer.lastName) ? ` ${buyer.lastName} (${buyer.phone})` : '');
        const buyerObject = { label: name, value: buyer.id, type: buyer.type, object: buyer };
        if (buyer.type && buyer.type === 'referrer') referrersArray.push(buyerObject);
        else if (buyer.type && buyer.type === 'customer') customersArray.push(buyerObject);
      });

      dealsResult.data.forEach(deal => {
        const dealObject = { label: deal.name, value: deal.id, type: 'deal', object: deal };
        dealsArray.push(dealObject);
      });

      ordersResult.data.forEach(order => {
        const orderObject = { label: order.orderId, value: order.id, type: 'order', object: order };
        idsArray.push(orderObject);
      });

      if (referrersArray.length > 0) resultArray.push({ "label": t("Referrer", { count: 100 }), "options": referrersArray });
      if (customersArray.length > 0) resultArray.push({ "label": t("Customer", { count: 100 }), "options": customersArray });
      if (idsArray.length > 0) resultArray.push({ "label": t("Order", { count: 100 }), "options": idsArray });
      if (dealsArray.length > 0) resultArray.push({ "label": t("Deal", { count: 100 }), "options": dealsArray });
      searchTermsResult = resultArray;
    }
    return searchTermsResult;
  }, [country, queryParamsFiltersInit, t]);

  const renderReason = (reason, orderStatus, comments) => <span>
    <b>{`${orderStatus} - ${reason}${comments ? ':' : ''}`}</b>
    {comments ? (<span><br /></span>) : ''}
    {comments || ''}
  </span>;

  const dealImageStyles = useMemo(() => ({
    maxWidth: "46px",
    maxHeight: "39px",
    marginRight: "20px"
  }), []);

  const imgDivStyles = useMemo(() => ({
    width: "66px",
    height: "39px"
  }), []);

  const externalCellStyles = {
    headerCellStyles: {
      borderRight: '1px solid #BBB',
      background: "#481F67",
      color: "#FFF",
      textAlign: "center"
    },
    cellStyles: {
      border: "0px solid #BBB",
      borderTop: "4px solid #d9d9df",
      boxShadow: "-10px 0px 0px -9px #d9d9d9",
      background: "#979192",
      color: "#fff"
    },
    subCellStyles: {
      border: "0px solid #BBB",
      borderBottom: "1px solid #b3b3b3",
      boxShadow: "20px 0px 0px -19px #b3b3b3",
      color: "#7f7f7f",
      fontSize: "13px"
    }
  };

  const externalTableStyles = {
    tableRowStyles: {
      background: "#fefefe",
    }
  };

  const tabs = [
    {
      label: t('activefemale'),
    },
    {
      label: t('abandonedFemele')
    },
    {
      label: t('Possible fraud')
    }
  ];

  const stylesOverride = {
    tab: {
      root: {
        textTransform: 'none',
        fontSize: '18px',
        minWidth: "0",
        maxWidth: "none",
        '&:nth-child(1)': {
          borderRight: '1px solid #c8c8c8'
        },
        '&:nth-child(2)': {
          borderRight: '1px solid #c8c8c8'
        },
        height: '5px',
        minHeight: '20px'

      },
      selected: {
        fontWeight: '800',
        color: '#000',
      }
    }
  };

  const promotionCol = (deal, total, totalDiscount, hasInnerPromos, colorPromotion, points, currency, isTotal) => {
    const { promotion, discountPromotionTotal } = deal;
    const value = (total || discountPromotionTotal || 0);
    let result = { title: '' };
    if (!promotion && !points && !Array.isArray(promotion)) result = { title: 'N/A' };
    if (promotion && !Array.isArray(promotion)) result = { title: promotion.name };
    const needPercentage = promotion?.key !== 'MONEY_VOUCHER_ON_ORDER' && value !== 0;
    // const basePercentage = promotion?.discountRate || promotion?.discountTotal / total;
    const percentage = `${toFloat(((totalDiscount || promotion?.discountRate) * 100))}%`;
    if (hasInnerPromos) delete result.title;
    result.description = <div>
      {Array.isArray(promotion) && promotion?.length && promotion[0] !== null && !isTotal ?
        promotion?.map(promo => {
          const value = promo?.discountTotal;
          const needPercentage = promo?.key !== 'MONEY_VOUCHER_ON_ORDER' && value !== 0;
          // const basePercentage = promotion?.discountRate || promotion?.discountTotal / total;
          const percentage = `${toFloat(((totalDiscount || promo?.discountRate) * 100))}%`;
          return (<div key={`${deal?.id}-${promo?.id}`}>
            <div className={stylesTableCommon.title}>
              {promo?.name}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ color: colorPromotion || "#fff" }}>
                <Price value={value || 0} />
              </div>
              {needPercentage && percentage !== 'NaN%' ? (<div style={{ paddingLeft: "6px" }}>({percentage})</div>) : ''}
            </div>
          </div>);
        })
        :
        <div>
          {promotion && !Array.isArray(promotion) ? <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ color: colorPromotion || "#fff" }}>
              <Price value={value || 0} />
            </div>
            {needPercentage && percentage !== 'NaN%' ? (<div style={{ paddingLeft: "6px" }}>({percentage})</div>) : ''}
          </div> : ''}
        </div>
      }
      {total !== null && promotion && isTotal ? <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ color: colorPromotion || "#fff" }}>
          <Price value={value || 0} />
        </div>
        {needPercentage && percentage !== 'NaN%' ? (<div style={{ paddingLeft: "6px" }}>({percentage})</div>) : ''}
      </div> : ''}
      {points ? <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ color: colorPromotion || "#fff" }}>
          <Price value={points?.points} sign='' />
        </div>
        <div style={{ paddingLeft: "6px", marginRight: "3px" }}>(pts)</div>
        <span> - </span>
        <div style={{ color: colorPromotion || "#fff", marginLeft: "3px" }}>
          <Price value={points?.metadata?.amountExchange} currency={currency} />
        </div>
      </div> : ''}
    </div>;

    return result;
  };

  const discountCol = (deal, total, totalDiscount, colorDiscount,) => {
    const { discount = 0, discountCommissionTotal = 0 } = deal;
    const value = deal.originalQuantity ? (((total || discountCommissionTotal || 0) / deal.originalQuantity) * deal.quantity) : (total || discountCommissionTotal || 0);

    const percentage = `${toFloat(((totalDiscount || discount) * 100))}%`;

    const result = {};
    result.description = <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ color: colorDiscount || "#fff" }}>
        <Price value={value} />
      </div>
      {value !== 0 ? <div style={{ paddingLeft: "6px" }}>({(percentage !== 'NaN%') ? percentage : <Price value={discountCommissionTotal} />})</div> : ''}
    </div>;
    return result;
  };

  const createChildren = useCallback((data) => data?.deals.reduce((acc, deal) => {
    let packages = [...acc];
    if (deal?.packages && deal?.packages?.length) {
      let packegeDealArray = [];
      deal?.packages.map((packegeDeal) => {
        if (packegeDeal.dealId === deal.id) {
          packegeDealArray = [...packegeDealArray,
          {
            ...deal,
            packageId: packegeDeal.packageId,
            zohoPackageId: packegeDeal.zohoPackageId,
            status: packegeDeal.status,
            quantity: packegeDeal.dealQuantity,
            originalQuantity: deal.quantity

          }
          ];
        }
      }
      );

      const restQuantity = packegeDealArray.reduce((acc, packege) => acc + packege.quantity, 0);

      if ((deal.quantity - restQuantity) > 0) {
        packegeDealArray = [...packegeDealArray, {
          ...deal,
          packageId: null,
          zohoPackageId: null,
          status: null,
          quantity: deal.quantity - restQuantity,
          originalQuantity: deal.quantity
        }
        ];
      }

      packages = [...packages, ...packegeDealArray];

    } else {
      packages = [...packages, deal];
    }

    return packages;

  }, []).map(deal => {
    const result = {};
    // let id = {};
    let idHtml = '';
    let promotion = {};
    let basePrice = '';
    let shippingCost = '';
    // let commissionDiscountHtml = '';
    let commissionHtml = '';
    // let finalPromoPrice = '';
    let totalPrice = '';
    let discountSocio = '';
    let paymentStatus = '';
    let status = '';
    let totalCommission = '';
    let updatedAtHtml = '';

    const defaultColorClass = 'badge-light';
    const lastModificationUser = data.lastModificationUsers?.fullName ? t('ByUser', { user: data.lastModificationUsers?.fullName }) : t('BySystem');
    const currentStatusLabel = statusLabels?.order?.[deal?.status || data?.status];

    const dateToText = (date) => {
      if (!date) return '-';
      let result = date;
      if (!Number.isNaN(Date.parse(date))) result = formatTimestamp(date);
      return result;
    };

    const dealNameHtml = <div style={{ display: "flex", padding: "10  px 0px" }}>
      <div style={imgDivStyles}><img src={getThumbnail(deal.media)} style={dealImageStyles} /></div>
      <div style={{ display: "flex", flexDirection: 'column' }}>
        <span><b>{deal.name}</b></span>
        <div>
          <span>{deal.sku ? deal.sku : ''}</span>
          {deal.zohoPackageId ? <b> · </b> : ''}
          <span><b>{deal.zohoPackageId ? `${t("Package")} ${deal.zohoPackageId}` : ''}</b></span>
        </div>
      </div>
    </div>;

    if (deal.version === 'v1') {

      // id = {id: deal.id};

      idHtml = <div style={{ fontSize: "13px" }}>{deal.zohoPackageId ? `${data.orderId} - ${deal.zohoPackageId}` : data.orderId}</div>;

      promotion = promotionCol(deal, (deal.promotion?.discountTotal) ? deal.promotion.discountTotal : 0, undefined, undefined, "rgb(127, 127, 127)");

      if (data?.commission && data?.commission.amount === 0) {
        discountSocio = discountCol(deal, (deal?.price * deal?.discount), undefined, "rgb(127, 127, 127)");
      } else {
        discountSocio = discountCol(deal, 0, 0, "rgb(127, 127, 127)");
      }

      basePrice = <div style={centerNoBreakVerticalStyle}>
        <div>
          <Price value={deal.price * deal.quantity} />
        </div>
        {deal.quantity < 2 ? '' : <div style={subBasePriceStyle}>
          <span>(</span><Price value={deal.price} /><span>)</span>
        </div>}
      </div>;

      // commissionDiscountHtml = (data.commission.amount === 0 ) ?
      // <div style={centerNoBreakVerticalStyle}>
      //   AA<Price value={toFloat(deal.price * deal.discount * deal.quantity)} />
      // </div> : "-";

      commissionHtml = (data.commission.amount > 0) ? <div style={centerNoBreakVerticalStyle}>
        <Price value={toFloat(deal.price * deal.discount * deal.quantity)} />
        {deal.commissionTotal ? <div>({100 * deal.discount}%)</div> : ''}
      </div> : "-";

      // finalPromoPrice =<div>
      //   <b>
      //     CC<Price value={ (deal.promotion?.discountTotal) ? toFloat((deal.price - deal.promotion?.discountValue) * deal.quantity) : toFloat(deal.price * deal.quantity) } />
      //   </b>
      // </div>;

      totalPrice = <div style={{ display: "flex", justifyContent: "center", whiteSpace: "pre", flexDirection: 'column' }}><Price value={
        (deal.promotion?.discountTotal) ?
          toFloat((deal.price - deal.promotion?.discountValue) * deal.quantity)
          : (data.commission.amount === 0) ?
            toFloat(deal.price * deal.discount * deal.quantity)
            : toFloat(deal.price * deal.quantity)
      } /></div>;

      shippingCost = <div style={centerNoBreakVerticalStyle}>
        <div>
          -
        </div>
      </div>;

      paymentStatus = {
        colorClass: badgeColor[data.transaction?.status] || defaultColorClass,
        description: totalPrice,
        value: statusLabels?.transaction?.[data.transaction?.status]?.toUpperCase() || ""
      };

      status = {
        colorClass: badgeColor[deal?.status || data.status] || defaultColorClass,
        description: /* tracking code? */ "",
        value: currentStatusLabel?.toUpperCase()
      };

      totalCommission = {
        colorClass: commissionHtml < 0 ? "none" : badgeColor[data.commission?.status] || defaultColorClass,
        description: commissionHtml,
        value: totalCommission < 0 ? "-" : statusLabels?.commission?.[data.commission?.status]?.toUpperCase()
      };
      updatedAtHtml = <div style={{ display: "flex", flexDirection: "column", fontSize: "13px" }}>
        <div title={durationFromNow(data.updated_at)} className={themeStyles.capitalize}>
          {dateToText(data.updated_at)}
        </div>
        <span>{lastModificationUser}</span>
        {/* <div style={{ fontSize: "12px", color: '#888' }}>
            {`(${data.updatedBy})`}
          </div> */}
      </div>;
    } else {

      // id = {id: deal.id};

      idHtml = <div style={{ fontSize: "13px" }}>{deal.zohoPackageId ? `${data.orderId} - ${deal.zohoPackageId}` : data.orderId}</div>;

      promotion = promotionCol(deal, undefined, undefined, undefined, "rgb(127, 127, 127)");


      const quantityInside = deal.originalQuantity || deal.quantity || 0;

      const commissionPercentage = toFloat(((deal.commissionRate / quantityInside) * deal.quantity) * 100);

      commissionHtml = (data.commission.amount > 0) ? <div style={centerNoBreakVerticalStyle}>
        <Price value={(deal.commissionTotal / quantityInside) * deal.quantity} />
        {deal.commissionTotal ? <div>({commissionPercentage}%)</div> : ''}
      </div> : "-";

      // commissionDiscountHtml = (data.commission.amount === 0 ) ? <div style={centerNoBreakVerticalStyle}>
      //   <Price value={deal.discountCommissionTotal} />
      //   {deal.discountCommissionTotal ? <div>({commissionPercentage}%)</div> : ''}
      // </div> : "-";

      basePrice = <div style={centerNoBreakVerticalStyle}>
        <div>
          <Price value={deal.priceBasePerUnit * deal.quantity} />
        </div>
        {deal.quantity < 2 ? '' : <div style={subBasePriceStyle}>
          <span>(</span><Price value={deal.priceBasePerUnit} /><span>)</span>
        </div>}
      </div>;

      // finalPromoPrice =<div>
      //   <b>
      //     <Price value={deal.priceWithDiscountPromotionTotal} />
      //   </b>
      // </div>;

      totalPrice = <div style={{ display: "flex", justifyContent: "center", whiteSpace: "pre", flexDirection: 'column' }}><Price value={deal.priceFinalTotal} /></div>;

      discountSocio = discountCol(deal, undefined, undefined, "rgb(127, 127, 127)");

      shippingCost = <div style={centerNoBreakVerticalStyle}>
        <div>
          -
        </div>
      </div>;

      paymentStatus = {
        colorClass: badgeColor[data.transaction?.status] || defaultColorClass,
        description: totalPrice,
        value: statusLabels?.transaction?.[data.transaction?.status]?.toUpperCase() || ""
      };

      status = {
        colorClass: badgeColor[deal?.status || data.status] || defaultColorClass,
        description: /* tracking code? */ "",
        value: currentStatusLabel?.toUpperCase()
      };

      totalCommission = {
        colorClass: commissionHtml < 0 ? "none" : badgeColor[data.commission?.status] || defaultColorClass,
        description: commissionHtml,
        value: totalCommission < 0 ? "-" : statusLabels?.commission?.[data.commission?.status]?.toUpperCase()
      };

      updatedAtHtml = <div style={{ display: "flex", flexDirection: "column", fontSize: "13px" }}>
        <div title={durationFromNow(data.updated_at)} className={themeStyles.capitalize}>
          {dateToText(data.updated_at)}
        </div>
        <span>{lastModificationUser}</span>
        {/* <div style={{ fontSize: "12px", color: '#888' }}>
          {`(${data.updatedBy})`}
        </div> */}
      </div>;
    }
    result.id = '';
    result.idHtml = idHtml;
    result.dealHtml = dealNameHtml;
    result.qty = <b>{deal.quantity}</b>;
    result.basePrice = basePrice;
    result.promotion = promotion;
    result.discountSocio = discountSocio;
    result.shippingCost = shippingCost;
    result.paymentStatus = paymentStatus;
    result.status = status;
    result.totalCommission = totalCommission;
    result.updatedAtHtml = updatedAtHtml;
    result.actions = '';
    return result;
  }), [dealImageStyles, imgDivStyles, statusLabels, t]);


  const createData = useCallback(data => {
    const timeZone = moment.tz?.zonesForCountry(getCountry());
    const { id, orderId, created_at, /* updated_at, */ total, status, /* expectedDeliveryDate, lastModificationUsers,  paymentMethod, */ riskLevel, fraudRawdata, points } = data;
    const validateIdFraud = ids?.some(idFraud => idFraud === id);
    let { shippingAddress: customerAddress } = data;
    const hasInnerPromo = data.deals?.find(deal => deal.promotion?.name || deal.promotion?.find(promo => promo?.name));
    const averageDiscountPromo = data.deals?.reduce((sum, deal) => (sum + (deal.promotion?.discountRate || 0)), 0) / data.deals?.length;
    const promotion = promotionCol(data?.deals[0], data?.deals.reduce((acc, deal) => acc + (deal?.promotion?.discountTotal || deal?.promotion.reduce((acc, promo) => acc + (promo?.discountTotal), 0) || 0), 0), averageDiscountPromo, hasInnerPromo, null, points, data?.deals[0].currency, true);
    const currentReasonLabel = orderReasons?.[status]?.find(reason => reason.value === data.statusReason)?.label;
    const currentStatusLabel = statusLabels?.order?.[data.status];

    // const lastModificationUser = lastModificationUsers?.fullName ? t('ByUser', { user: lastModificationUsers?.fullName }) : t('BySystem');
    // const paymentMethodLabel = paymentMethod?.name;
    const dealsCount = <div><b><span>{data.deals.reduce((acc, deal) => acc + deal.quantity, 0)}</span></b></div>;

    const customerName = data.user.firstName + ((data.user.lastName) ? (` ${data.user.lastName}`) : '');
    const referrerName = data.referrer.firstName + ((data.referrer.lastName) ? (` ${data.referrer.lastName}`) : '');
    const defaultColorClass = 'badge-light';

    const totalPriceHtml = <div style={{ display: "flex", flexDirection: "column", fontSize: "13px" }}>
      <Price value={total} />
      {/* <span>{paymentMethodLabel}</span> */}
    </div>;

    const totalCommission = data.commission?.amount + (data.commissionReward?.amount || 0);
    const commissionPercentageRaw = totalCommission * 100 / total;
    const commissionPercentage = <div style={centerNoBreakVerticalStyle}>
      <div>
        <div>{`${toFloat((commissionPercentageRaw))}%`}</div>
        {data.commissionReward?.amount ? <div style={subBasePriceStyle}>(<Price value={data.commissionReward?.amount} />)</div> : ''}
      </div>
    </div>;
    const totalCommissionHtml = <Price value={totalCommission} />;
    const commName = data.commissionReward?.name || 'Base';
    const commType = <div title={data.commissionReward?.description}>{commName}</div>;
    const hasCommission = totalCommission;
    const commissionType = { title: hasCommission ? commType : 'N/A' };
    if (hasCommission) commissionType.description = commissionPercentage;

    const editButtonHtml = createItem({ icon: { icon: 'edit_cover-' }, text: t("Edit") });
    const editHtml = <BasicModal content={OrdersCreate} typeButton="html" options={{ html: editButtonHtml }} object={{ ...data, statuses: { orderReasons, orderStatusOptions, commissionStatusOptions, paymentStatusOptions, statuses } }} onClose={() => setExternalRefresh(externalRefresh + 1)} />;
    const menuItems = [
      { html: editHtml }
    ];

    const reviewFraudButton = {
      icon: { icon: 'exclamation-circle' },
      text: t("Review Fraud"),
      onClick: async () => {
        const response = await crudAction(reviewFraudOrders.bind(null, [{ id }]), () => { },
          {
            
            module, name: data.orderId, action: actions.REVIEW
          }, t
        );
        if (response?.ok) {
          addOrderId({
            id,
            emailUser: email,
          });
          setExternalRefresh(externalRefresh + 1);
        }
      }
    };

    if (riskLevel !== null && validateIdFraud) {
      removeOrderId({
        id,
        emailUser: email,
      });
    }

    if (!riskLevel && !validateIdFraud) menuItems.push(reviewFraudButton);

    const menu = <Menu items={menuItems} color="#fff" />;
    const actionsHtml = { content: menu, colorExpandIconDefault: "#fff" };
    const { address1 = '', address2 = '', city = '', /* description = '' */ } = customerAddress;
    // const isAddressValid = customerAddress.lat && customerAddress.lng;
    customerAddress = `${address1 + (address2 ? ` ${address2}` : '')} ${city}`;
    // const addressValidIcon = <Icon color="#52D8BD" size={15} icon="check_circle" />;
    // const addressInvalidIcon = <Icon color="#E24E78" size={15} icon="cancel" />;
    let borderColorAlert = false;
    const colorFraudAlert = (riskLevel) => {
      let colorAlert = "";
      let status = "";
      if (riskLevel >= 0 && riskLevel <= fraudLowerLimit) {
        colorAlert = lowFraudColor;
        status = "low";
      }
      if (riskLevel > fraudLowerLimit && riskLevel <= fraudHigherLimit) {
        colorAlert = midFraudColor;
        status = "medium";
      }
      if (riskLevel > fraudHigherLimit) {
        colorAlert = hiFraudColor;
        status = "high";
        borderColorAlert = true;
      }


      return { colorAlert, status };
    };

    const alerIconFraud = <div style={{ cursor: "pointer" }}>
      <span><Icon iconStyle={{ background: "black", borderRadius: "50%" }} size={18} color={colorFraudAlert(riskLevel).colorAlert} icon="exclamation-circle" /></span>
    </div>;

    const alerIconFraudReview = <div style={{ cursor: "pointer" }} onClick={() => setExternalRefresh(externalRefresh + 1)}>
      <Tooltip title={t("In review by fraud")}>
        <div><Icon size={18} icon='hour-glass' color="" /></div>
      </Tooltip>
    </div>;

    const idHtml = <div style={{ display: 'flex', width: '180px', alignItems: 'center', gap: "8px" }}>
      {riskLevel !== null ?
        <BasicModal content={FraudModal} typeButton="html" onClose={() => { }} modalSize="md" options={{ html: alerIconFraud }} object={{ fraudRawdata: fraudRawdata?.rows_detail, tachometerFraud: { colorAlert: colorFraudAlert(riskLevel).colorAlert, status: colorFraudAlert(riskLevel).status }, colorFraudAlert }} />
        : ""}
      {riskLevel === null && validateIdFraud ?
        alerIconFraudReview
        : ""}

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span><b>{t("Order ID")}:</b></span>
        <div style={{ fontSize: "13px" }}>{orderId}</div>
      </div>
      <div>
        <Tooltip title={(data.createdBy === 'mini-store') ? 'WAO Shop' : 'Manager'}>
          <div className={`${themeStyles["table-badge"]} ${themeStyles.badge} ${themeStyles["badge-pill"]} ${themeStyles["badge-narrow"]} ${data.createdBy === 'mini-store' ? themeStyles["badge-wao-orange"] : themeStyles["badge-light-purple"]}`}>{data.createdBy === 'mini-store' ? 'W' : 'M'} </div>
        </Tooltip>
      </div>
    </div>;

    const addressCustomerTooltip = `${customerAddress}`;

    const customerHtml = <div style={{ width: '200px', alignItems: 'center' }}>
      <div>
        <span><b>{t("Client")}:</b></span>
      </div>
      <div style={{ display: 'flex', gap: '2px' }}>
        <Tooltip title={customerName}>
          <div style={{ display: 'flex', gap: '4px' }}>
            <span><Icon color="#caf982" size={12} icon="check_circle" /></span>
            <span style={{ overflow: "hidden", maxWidth: "140px", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{customerName}</span>
          </div>
        </Tooltip>
        <div style={{ flexGrow: "0", marginRight: "5px" }}>
          <Tooltip title={data.user.phone}>
            <div><Icon color="#fff" size={12} icon="phone" /></div>
          </Tooltip>
        </div>
        <div style={{ flexGrow: "0" }}>
          <Tooltip title={addressCustomerTooltip}>
            <div><Icon color="#fff" size={12} icon="location-stuffed" /></div>
          </Tooltip>
        </div>
      </div>
    </div>;

    const referrerHtml = <div style={{ width: '200px', alignItems: 'center' }}>
      <div>
        <span><b>{t("Socio")}:</b></span>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ overflow: "hidden", maxWidth: "140px", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <span style={{ marginRight: "10px" }}>{referrerName}</span>
        </div>
        <div style={{ flexGrow: "0", marginRight: "5px" }}>
          <Tooltip title={data.referrer?.phone}>
            <div><Icon color="#fff" size={12} icon="phone" /></div>
          </Tooltip>
        </div>
      </div>
    </div>;

    // const referrerText = { title: referrerName, description: data.referrer?.phone, subStyle: true };

    const onCheckChangeHandler = (id, value) => {
      const aux = checkOrders;
      aux[id] = value;
      setCheckOrders(aux);
      setSelectRefresh(selectRefresh + 1);
      setSelectAll(false);
    };

    const dateToText = (date) => {
      if (!date) return '-';
      let result = date;
      if (!Number.isNaN(Date.parse(date))) result = formatTimestamp(date, false, false, (timeZone ? timeZone[0] : ''));
      return result;
    };

    const createdAtHtml = <Grid container style={{ width: '200px', alignItems: 'center' }}>
      <Grid item xs={12}>
        <span><b>{t("Created")}:</b></span>
      </Grid>
      <Grid item xs={12}>
        <div title={durationFromNow(created_at)} className={themeStyles.capitalize}>
          {dateToText(created_at)}
        </div>
      </Grid>
    </Grid>;

    const basePrice = <div style={centerNoBreakVerticalStyle}>
      <div>
        <Price value={data?.deals.reduce((acc, deal) => acc + deal.priceBaseTotal, 0)} displayType="text" thousandSeparator decimalSeparator="." prefix="$" suffix={` ${data?.deals[0].currency}`} />
      </div>
    </div>;

    const averageDiscount = data.deals?.reduce((sum, deal) => (sum + deal?.discount), 0) / data.deals?.length;

    let discountSocio = {};
    if (data?.deals[0].version === 'v1') {
      if (data?.commission && data?.commission.amount === 0) {
        discountSocio = discountCol(data?.deals[0], data?.deals.reduce((acc, deal) => (acc + (deal?.price * deal?.discount)), 0), averageDiscount);
      } else {
        discountSocio = discountCol(data?.deals[0], 0, 0);
      }
    } else {
      discountSocio = discountCol(data?.deals[0], data?.deals.reduce((acc, deal) => acc + deal?.discountCommissionTotal, 0), averageDiscount);
    }
    
    const shippingCost = <div style={centerNoBreakVerticalStyle}>
      <div>
        <Price value={data?.costShipping || 0} displayType="text" thousandSeparator decimalSeparator="." prefix="$" suffix={` ${data?.deals[0].currency}`} />
      </div>
    </div>;

    const updatedAtHtml = ''; // <div style={{ display: "flex", flexDirection: "column", fontSize: "13px" }}>
    //   <div title={durationFromNow(updated_at)} className={themeStyles.capitalize}>
    //     {dateToText(updated_at)}
    //   </div>
    //   <span>{lastModificationUser}</span>
    //   {/* <div style={{ fontSize: "12px", color: '#888' }}>
    //     {`(${data.updatedBy})`}
    //   </div> */}
    // </div>;

    // const expectedDeliveryDateHtml = <div style={{ display: "flex", fontSize: "13px" }}>
    //   <div title={durationFromNow(expectedDeliveryDate)} className={themeStyles.capitalize}>
    //     {dateToText(expectedDeliveryDate)}
    //   </div>
    // </div>;


    const children = createChildren({ ...data, commissionPercentageRaw });

    const dataObject = {
      id: { id, checked: checkOrders[id] || false, onChange: onCheckChangeHandler },
      idHtml,
      createdAtHtml,
      customerHtml,
      referrerHtml,
      dealsCount,
      basePrice,
      promotion,
      discountSocio,
      shippingCost,
      totalPrice: {
        colorClass: badgeColor[data.transaction?.status] || defaultColorClass,
        description: totalPriceHtml,
        descriptionStyles: { color: "#fff" },
        value: statusLabels?.transaction?.[data.transaction?.status]?.toUpperCase() || ""
      },
      // commissionType,
      // expectedDeliveryDate: expectedDeliveryDateHtml,
      status: {
        colorClass: badgeColor[status] || defaultColorClass,
        description: /* tracking code? */ "",
        value: currentStatusLabel?.toUpperCase(),
        reason: orderReasons?.[status]?.find(reason => reason.value === data.statusReason)?.label,
        colorIconReason: "#fff",
        reasonClickHandler: showMessage.bind(null, {
          message: renderReason(currentReasonLabel, currentStatusLabel, data.statusComments),
          title: t("Order status change reason"),
          close: true
        })
      },
      totalCommission: {
        colorClass: totalCommission < 0 ? "none" : badgeColor[data.commission?.status] || defaultColorClass,
        description: totalCommissionHtml,
        descriptionStyles: { color: "#fff" },
        value: totalCommission < 0 ? "-" : statusLabels?.commission?.[data.commission?.status]?.toUpperCase()
      },
      updatedAtHtml,
      actions: actionsHtml,
      data,
      children,
      borderColorAlert

    };

    return dataObject;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAbandoned, ids, email, orderReasons, statusLabels, t, orderStatusOptions, commissionStatusOptions, paymentStatusOptions, statuses, createChildren, checkOrders, externalRefresh, selectRefresh]);



  const selectAllMethod = () => {
    const aux = checkOrders;
    if (orders && orders.data) {
      orders.data.forEach(a => {
        aux[a.id] = !selectAll;
      });
    }
    setSelectRefresh(selectRefresh + 1);
    setSelectAll(!selectAll);
  };


  const tableHeaders = [
    {
      label: "SELECTALL",
      type: columnTypes.SELECT,
      alignment: "center",
      method: selectAllMethod,
      isChecked: selectAll
    },
    { label: "ID", sort: "orderId" },
    { label: "Item Name", colSpan: 3 },
    { label: "Qty", alignment: "center", sort: "quantity" },
    { label: "consumerPrice", alignment: "center" },
    { label: "Promotion/Points", type: columnTypes.TEXT, alignment: "center" },
    { label: "Socio Discount", type: columnTypes.TEXT, alignment: "center" },
    { label: "Shipping Cost", alignment: "center" },
    { label: "Payment Status", type: columnTypes.BADGE, alignment: "center" },
    // { label: "Commission Type", type: columnTypes.TEXT, alignment: "center" },
    // { label: "Expected Delivery Date", sort: "expectedDeliveryDate", alignment: "center" },
    { label: "Order Status", type: columnTypes.BADGE, alignment: "center", sort: "status" },
    { label: "Commission Status", type: columnTypes.BADGE, alignment: "center" },
    { label: "Updated at", sort: "updated_at", alignment: "center" },
    {
      alignment: "center",
      method: toggleExpandAllRows,
      expandIcon: true,
      expanded: shouldExpandAllRows,
      type: columnTypes.TOGGLE
    }
  ];

  const tableColumns = [
    {
      label: "SELECTALL",
      type: columnTypes.SELECT,
      alignment: "center",
      method: selectAllMethod,
      isChecked: selectAll
    },
    { label: "ID", sort: "orderId" },
    // { label: 'Deals', alignment: 'center'/*, width: '10%'*/ }),
    // { label: 'Test', type: columnTypes.TEXT }),
    { label: "Created at" },
    { label: "Customer" },
    { label: "Referrer" },
    { label: "Qty", alignment: "center", sort: "quantity" },
    { label: "consumerPrice", alignment: "center" },
    { label: "Promotion/Points", type: columnTypes.TEXT, alignment: "center" },
    { label: "Socio Discount", type: columnTypes.TEXT, alignment: "center" },
    { label: "Shipping Cost", alignment: "center" },
    { label: "Payment Status", type: columnTypes.BADGE, alignment: "center" },
    // { label: "Commission Type", type: columnTypes.TEXT, alignment: "center" },
    // { label: "Expected Delivery Date", sort: "expectedDeliveryDate", alignment: "center" },
    { label: "Order Status", type: columnTypes.BADGE, alignment: "center", sort: "status" },
    { label: "Commission Status", type: columnTypes.BADGE, alignment: "center" },
    { label: "Updated at", sort: "updated_at", alignment: "center" },
    {
      alignment: "center",
      method: toggleExpandAllRows,
      expandIcon: true,
      expanded: shouldExpandAllRows,
      type: columnTypes.TOGGLE
    }
  ];

  const subTableColumns = [
    { alignment: "center" },
    { label: "ID" },
    { label: "Item Name", colSpan: 3 },
    { label: 'Qty', alignment: 'center' },
    { label: 'consumerPrice', alignment: 'center' },
    { label: 'Promotion/Points', type: columnTypes.TEXT, alignment: 'center' },
    { label: "Socio Discount", type: columnTypes.TEXT, alignment: "center" },
    { label: "Shipping Cost", alignment: "center" },
    { label: "Payment Status", type: columnTypes.BADGE, alignment: "center" },
    { label: "Order Status", type: columnTypes.BADGE, alignment: "center" },
    { label: "Commission Status", type: columnTypes.BADGE, alignment: "center" },
    { label: "Updated at", alignment: "center" },
    { alignment: "center" }
  ];

  // eslint-disable-next-line consistent-return
  const applyStatuses = async () => {
    const ids = [];
    if (commissionStatusSelected === '' && orderStatusSelected === '' && paymentStatusSelected === '') {
      return showMessage({ message: t('choose_some_order_status') });
    }
    if (orderStatusSelected && currentOrderReasons.length && !orderReasonSelected) {
      return showMessage({ message: t('choose_some_order_status_reason') });
    }

    for (const [key, value] of Object.entries(checkOrders)) {
      if (value) ids.push(Number(key));
    }

    if (ids.length > 0) {
      setDialogContent(t('confirmStatusChangeForNumberObject', { count: ids.length, object: t('Order', { count: ids.length }) }));
      setDialogConfirm(true);
      const data = { orders: ids };

      if (commissionStatusSelected) data.commissionStatus = commissionStatusSelected;
      if (orderStatusSelected) data.orderStatus = orderStatusSelected;
      if (paymentStatusSelected) data.paymentStatus = paymentStatusSelected;
      if (orderReasonSelected) data.orderStatusReason = orderReasonSelected;
      setUpdateData(data);
      dialogRef.current.handleClickOpen();
    } else {
      showMessage({ message: t('choose_some_order') });
    }
  };

  useEffect(() => {
    if (fetchStatuses) fetchStatuses();
  }, [fetchStatuses]);

  useEffect(() => {
    if (disableOptionsSelect(paymentStatusSelected) === true) {
      setCommissionStatusSelected('');
      setOrderStatusSelected('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableOptionsSelect]);

  useEffect(() => {
    const asyncFunc = async () => {
      const data = updateData;
      setIsSubmitting(true);
      const updateCom = await crudAction(
        updateOrderStatusesBulk.bind(null, data),
        () => { },
        {
          name: "",
          module: { ...modules(t).status, name: "Orders Status" },
          action: actions.UPDATE
        },
        t,
        false
      );
      if (updateCom?.ok) {
        setCheckOrders({});
        setSelectAll(false);
        setPaymentStatusSelected("");
        setOrderStatusSelected("");
        setOrderReasonSelected("");
        setCommissionStatusSelected("");
        setExternalRefresh(externalRefresh + 1);
      }
      setIsSubmitting(false);
      setDialogResult(null);
    };
    if (dialogResult) asyncFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogResult]);

  useEffect(() => {
    if (orderStatusSelected) {
      if (orderReasons[orderStatusSelected]) {
        setCurrentOrderReasons(orderReasons[orderStatusSelected]);
      } else {
        setCurrentOrderReasons([]);
        setOrderReasonSelected("");
      }
    } else {
      setCurrentOrderReasons([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatusSelected]);

  useEffect(() => {
    if (statuses && Object.keys(statuses)?.length) {
      const orderStatusesWithReasons = statuses?.order?.reduce((orderStatuses, current) => {
        if (current?.reasons?.length) {
          orderStatuses[current.key] = current.reasons.map(reason => ({ label: reason.label, value: reason.key }));
        }
        return orderStatuses;
      }, {});
      setOrderReasons(orderStatusesWithReasons);
      setOrderStatusOptions(translatedSimpleOptions(statuses?.order, { valueKey: "key" }, textColorItemSelect));
      setPaymentStatusOptions(
        translatedSimpleOptions(statuses?.transaction, { valueKey: "key" }, textColorItemSelect)
      );
      setCommissionStatusOptions(
        translatedSimpleOptions(statuses?.commission, { valueKey: "key" }, textColorItemSelect)
      );

      const labels = Object.keys(statuses || {}).reduce((labelsResult, currentStatusType) => {
        labelsResult[currentStatusType] = statuses[currentStatusType].reduce((result, current) => {
          if (current.children) {
            current.children.forEach(child => {
              result[child.key] = child.label;
            });
          } else {
            result[current.key] = current.label;
          }
          return result;
        }, {});
        return labelsResult;
      }, {});
      setStatusLabels(labels);
    }
  }, [statuses]);

  useEffect(() => {
    switch (tabStatus) {
      case 0:
        if (orders) setQueryParamsFiltersInit({ ...queryParamsFiltersInit, listAbandoned: null, page: 1 });
        break;

      case 1:
        setQueryParamsFiltersInit({ ...queryParamsFiltersInit, listAbandoned: "only", page: 1 });
        break;

      case 2:
        setQueryParamsFiltersInit({ ...queryParamsFiltersInit, listAbandoned: "fraud", page: 1 });
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabStatus]);

  useEffect(() => {
    if (tabStatus === 1) {
      setIsAbandoned(true);
    } else {
      setIsAbandoned(false);
    }
  }, [tabStatus, setIsAbandoned]);

  const bulkSection = () => <div>
    <section className={`${themeStyles.box} ${themeStyles["form-wrap"]}`} >
      <div className={style['status-container']}>
        <div className={style['status-section-title']}>
          <label>{t('Update Selected Orders:')}</label>
        </div>
        <div className={style['status-section']}>
          <b>{t('Payment Status')}:</b>
          <SearchSelect
            className={style['status-item']}
            placeholder={t("Payment Status")}
            value={paymentStatusSelected}
            options={paymentStatusOptions}
            onChange={changePaymentStatus}
          />
        </div>
        <div className={style['status-section']}>
          <b>{t("Commission Status")}:</b>
          <SearchSelect
            className={style['status-item']}
            placeholder={t("Commission Status")}
            value={commissionStatusSelected}
            options={commissionStatusOptions}
            onChange={changeCommissionStatus}
            disabled={disableOptionsSelect(paymentStatusSelected)}
          />
        </div>
        <div className={style['status-section']}>
          <b>{t("Order Status")}:</b>
          <SearchSelect
            className={style['status-item']}
            placeholder={t("Order Status")}
            highlightGroups
            value={orderStatusSelected}
            options={orderStatusOptions}
            onChange={changeOrdeStatus}
            disabled={disableOptionsSelect(paymentStatusSelected)}
          />
          {!!(currentOrderReasons?.length) && (
            <div className={style['reasons-dropdown']}>
              <b>{reasonInsteadOfSubstastus.includes(orderStatusSelected) ? t('Reason') : t('Substatus')}:</b>
              <SearchSelect
                className={style['status-item']}
                placeholder={t("Order substatus")}
                value={orderReasonSelected}
                options={currentOrderReasons}
                onChange={changeOrderReason}
              />
            </div>
          )}
          {/* <textarea rows="4" className={themeStyles["form-control"]} placeholder={t('Comments')} onChange={(e)=> setOrderComments(e?.target?.value)} value={orderComments} autoComplete="off" /> */}
        </div>
        <button
          type="button" className={`${themeStyles.btn} ${themeStyles["btn-primary"]}`}
          disabled={isSubmitting} onClick={applyStatuses}
        >
          {t('Apply')}
        </button>
      </div>
    </section>
  </div>;

  const tabberComponent = () => (
    <Tabber tabs={tabs} styles={stylesOverride} setTabStatus={setTabStatus} />
  );

  return (

    <section className={themeStyles["inner-component-view"]}>
      <Dialog ref={dialogRef} closeIcon={dialogCloseIcon} title={dialogTitle} content={dialogContent} alert confirm={dialogConfirm} confirmUpdate={setDialogResult} />
      <ListView bulkSection={bulkSection} bulkUpdateView={OrdersBulkUpdate} checkCollection={checkOrders} collection={orders} countries={countries} country={country}
        createData={createData} createView={OrdersCreate} expandAllRows={shouldExpandAllRows} exportView={OrdersExport}
        externalCellStyles={externalCellStyles} externalComponentTop={tabberComponent} externalQueryParamsFiltersInit={queryParamsFiltersInit} externalRefresh={externalRefresh} externalTableStyles={externalTableStyles}
        fetchCountries={fetchCountries} fetchFunction={fetchOrders} hiddenSubHeader module={module} loadingTable={ordersLoading} loadOptionsHeaderViews={returnOrderTerms}
        selectRefresh={selectRefresh} statuses={statuses} statusLabels={statusLabels} subTableClass={style.subtable} subTableColumns={subTableColumns} tableColumns={tableColumns} tableHeaders={tableHeaders}
      />
    </section>
  );
};
export default withTranslation()(OrdersListing);
