import { createActions } from "redux-actions";

import { GenericError } from '../../../../utils/errors';

import {
  getDeals,
  postDeals,
} from '../services/deals';

const DealsActions = createActions({
  FETCH_DEALS_REQUEST: () => { },
  FETCH_DEALS_SUCCESS: (deals) => ({ deals }),
  FETCH_DEALS_FAILED: (error) => ({ error }),
  CREATE_DEAL_REQUEST: () => { },
  CREATE_DEAL_SUCCESS: (data) => ({ data }),
  CREATE_DEAL_FAILED: (error) => ({ error }),
  GET_DEAL: () => { },
  DELETE_DEAL: () => { },
});


DealsActions.fetchDeals = (query) => async (dispatch) => {
    try {
      dispatch(DealsActions.fetchDealsRequest());
      if (query.country === 'ALL') query.country = null;
      const result = await getDeals(query, true);
      dispatch(DealsActions.fetchDealsSuccess(result));
    } catch (err) {
      console.log('err ==>', err);
      dispatch(DealsActions.fetchDealsFailed({ error: new GenericError(err, err) }));
    }
  };

DealsActions.createDeal = (data) => async (dispatch) => {
    try {
      dispatch(DealsActions.createDealRequest());
      const result = await postDeals(data);
      dispatch(DealsActions.createDealSuccess(result));
    } catch (err) {
      console.log('err ==>', err);
      dispatch(DealsActions.createDealFailed({ error: new GenericError(err, err) }));
    }
  };

export default DealsActions;