/* eslint-disable camelcase */
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
// import BoxAverage from '../../../core/BoxAverage';
import Icon from "../../../core/Icon";
import BasicModal from '../../../core/BasicModal';
import CustomersCreate from '../forms/CustomersCreate';
import CustomersExport from '../forms/CustomersExport';
import themeStyles from '../../../../../theme.module.sass';
import { modules } from '../../../utils';
import { Grid } from '@mui/material';
import ListView from '../../../core/ListView';
import { columnTypes } from '../../../core/TableCommon';

const CustomersListing = ({ t, customers, customersLoading, fetchCustomers, country, countries, fetchCountries }) => {

  const module = modules(t).customer;

  const [externalRefresh, setExternalRefresh] = useState(0);

  const tableColumns = [

    { label : 'ID' , sort : 'id'},
    { label : 'Name', sort : 'firstName'},
    { label : 'Email' , sort : 'email'},
    { label : 'Phone' , sort : 'phone'},
    { label : 'Address'},
    { label : 'Status' , sort : 'status'},
    { label :'Created at', type : columnTypes.DATE , sort : 'created_at'},
     { label : 'Actions', alignment : 'center' }

  ];
  
  const createData = (data) => {
    const { id, firstName, lastName, email, phone, addresses, status, created_at } = data;
    const buttonHtml = <Icon className={themeStyles.clickable} color="#310f49" size={15} icon="edit_cover-" />;
    const editHtml = <BasicModal content={CustomersCreate} options={{ html: buttonHtml }} object={data} onClose={()=> setExternalRefresh(externalRefresh + 1)} />;
    const mainAddress = addresses[0];
    let addressHtml = "";
    if (mainAddress) {
      const address = `${mainAddress.address1 + (mainAddress.address2 ? ` ${ mainAddress.address2}` : '') } ${ mainAddress.city}`;
      const addressValidIcon = <Icon color="#52D8BD" size={15} icon="check_circle" />;
      const addressInvalidIcon = <Icon color="#E24E78" size={15} icon="cancel" />;
      const isAddressValid = mainAddress.lat && mainAddress.lng;
      addressHtml = <Grid container>
        <Grid item xs={12}>
          <span className={themeStyles["table-address-icon"]}>{isAddressValid ? addressValidIcon : addressInvalidIcon}</span>
          <span className={`${themeStyles["table-list-address"] } ${ !isAddressValid ? themeStyles.invalid : ''}`}>{address} </span>
          {(!mainAddress.description) ? ('') : (
            <span className={`${themeStyles["table-info-tooltip"] } ${ themeStyles["bold-label"] } ${ themeStyles.clickable}`} title={mainAddress.description} >
              <Icon color="#CFCFCF" size={20} icon="info1" className={themeStyles["table-info-icon"]} />
            </span>
          )}
        </Grid>
      </Grid>;
    }
    return { id, name: `${firstName } ${ lastName}`, email, phone, addressHtml, status, created_at, actions: editHtml, tooltip: created_at, data };
  };

  return (
    <section className={themeStyles["inner-component-view"]}>
      <ListView collection={customers} countries={countries} country={country}
        createData={createData} createView={CustomersCreate} exportView={CustomersExport}
        externalRefresh={externalRefresh}
        fetchCountries={fetchCountries} fetchFunction={fetchCustomers} module={module} loadingTable={customersLoading}
        tableColumns={tableColumns}
      />
    </section>
  );
};

export default withTranslation()(CustomersListing);