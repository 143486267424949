/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import styles from './styles.module.sass';
import CategoriesListing from './categories';
import KeywordsListing from './keywords';
import { t } from 'i18next';

const TrendingListing = ({categories, categoriesLoading, keywords, keywordsLoading, fetchCategories, fetchKeywords, fetchCountries, country, countries}) => {

  // const moduleName = {
  //   singular: "Trending",
  //   plural: "Trending",
  // };

  const [tab, setTab] = useState('categories');
  
  
  return (
    <section className="inner-component-view">
      <Grid container spacing={0} alignItems="center" className={styles['wrap-box']}>
        <Grid item xs>
          <h4>{t('Trending')}</h4>
        </Grid>
      </Grid>
      <section className={styles.tabs}>
        <button className={tab === 'categories' ? styles.__active : null } onClick={() => setTab('categories')}>Categories</button>
        <button className={tab === 'keywords' ? styles.__active : null } onClick={() => setTab('keywords')}>Keywords</button>
      </section>
      { tab === 'categories'? <CategoriesListing t={t} categories={categories} categoriesLoading={categoriesLoading} fetchCategories={fetchCategories} fetchCountries={fetchCountries} country={country} countries={countries}/> : null }
      { tab === 'keywords'? <KeywordsListing t={t} keywords={keywords} keywordsLoading={keywordsLoading} fetchKeywords={fetchKeywords} fetchCountries={fetchCountries} country={country} countries={countries}/> : null }
    </section>
  );
  
};

export default TrendingListing;
