import { getConfig } from '../../../../config';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

let firebase; let storage;

const checkConfig = () => {
  if (!firebase) {
    const firebaseImport = getConfig('firebase');
    ; ({ firebase, storage } = firebaseImport);
  }
};

export const upload = (file, filePath) => {
  checkConfig();
  return new Promise((resolve, reject) => {
    const fileRef = ref(storage, filePath);
    uploadBytes(fileRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then(fileUrl => {
        resolve(fileUrl);
      }).catch(reject);
    }).catch(reject);
  }
  );
};

export const uploadResponseAll = (file, filePath) => {
  checkConfig();
  return new Promise((resolve, reject) => {
    const fileRef = ref(storage, filePath);
    const object = {};
    uploadBytes(fileRef, file).then((snapshot) => {
      object.metadata = snapshot.metadata;
      getDownloadURL(snapshot.ref).then(fileUrl => {
        object.url = fileUrl;
        resolve(object);
      }).catch(reject);
    }).catch(reject);
  }
  );
};