import Immutable from 'seamless-immutable';

const initialState = Immutable({
  productsInit: true,
  productsLoading: false,
  productsError: null,
  productsLoaded: false,
  products: null,

  createProductLoading: false,
  createProductError: null,
  createdProduct: false,

  watchProductLoading: false,
  watchProductError: null,
  watchProduct: false,
  watchedProduct: null,
});


export default initialState;
