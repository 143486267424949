/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import debounce from "debounce-promise";

const defaultDebounceMs = 500;
const minCharsToSearch = 2;

const findByParam = (data, value, paramName) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i][paramName] === value) {
      return data[i];
    }
    if (data[i].options && data[i].options.length > 0 ) {
      const possibleResult = findByParam(data[i].options, value, paramName);
      if(possibleResult) return possibleResult;
    }
  }
  return undefined;
};

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <div />
  </components.DropdownIndicator>
);

const SearchSelect = ({ portal, options, value, findValueParamName, externalFunctionValue, className, rounded, disabled, hideButton, indicatorContainerWidth, loadOptions, highlightGroups, ...props }) => {
  
  const defaultValue = useCallback((valOptions, val, paramName='value') => {
    if (props.isMulti) return val;
    if (!val) return val;
    return valOptions ? findByParam(valOptions, val, paramName) : '';
  },[props?.isMulti]);

  useEffect(() => {
    if(externalFunctionValue) externalFunctionValue(defaultValue(options, value, findValueParamName));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  
  const selectStyles = {};
  const componentsContent = {};

  selectStyles.menuPortal = (base) => ({ ...base, zIndex: 9999 });

  selectStyles.menu = (provided/* , state */) => ({ ...provided,
    zIndex: 3
  });

  selectStyles.option = (provided, state) => ({ ...provided,
    color: state.data.color
  });

  if (highlightGroups) {
    selectStyles.group = (provided/* , state */) => ({ ...provided,
      // background: "#f7f7fb", //"#eee8f7", //"#e7ddf8",
      paddingTop: "0px",
      border: "1px dashed #e7e7e7"
    });
    selectStyles.groupHeading = (provided/* , state */) => ({ ...provided,
      // background: "#f7f7fb",//"#f3f3ff",
      paddingTop: "10px",
      // color: "#000",
      // fontWeight: "bold"
    });
  }

  if (hideButton) {
    selectStyles.indicatorsContainer = (/* provided, state */) => ({ display: 'none' });
    componentsContent.DropdownIndicator = DropdownIndicator;
  }

  if (indicatorContainerWidth) {
    selectStyles.indicatorsContainer = (provided/* , state */) => ({ ...provided, width: indicatorContainerWidth });
  }
  
  if (rounded) {
    selectStyles.control = (styles/* , state */) => ({ ...styles, borderColor: "#ccc", borderRadius: "20px", fontSize: "14px" });
  } else {
    selectStyles.control = (styles/* , state */) => ({ ...styles, fontSize: "14px"});
  }

  if (loadOptions) {
    const restrictedLoadOptions = debounce(async(query) => {
      let res = await loadOptions(query);
      if (query && query.length < minCharsToSearch) res = [];

      return res;
    }, defaultDebounceMs);

    if (props.creatable) {
      return (
        <div className={className}>
          <AsyncCreatableSelect
            {...props}
            loadOptions={restrictedLoadOptions}
            components={componentsContent}
            styles={selectStyles}
            value={defaultValue(props.defaultOptions, value, findValueParamName)}
            isDisabled={disabled}
          />
        </div>
      );
    }
    
      return (
        <div className={className}>
          <AsyncSelect
            {...props}
            loadOptions={restrictedLoadOptions}
            components={{ DropdownIndicator }}
            styles={selectStyles}
            value={defaultValue(props.defaultOptions, value, findValueParamName)}
            isDisabled={disabled}
          />
        </div>
      );
    
  }
    return (
      <div className={className}>
        <Select
          {...props}
          value={defaultValue(options, value, findValueParamName)}
          options={options}
          styles={selectStyles}
          menuPortalTarget={document.body}
          menuPosition={!portal ? 'fixed' : 'absolute'}
          isDisabled={disabled}
        />
      </div>
    );
  
};
export default SearchSelect;