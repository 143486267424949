/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-empty */
/* eslint-disable import/no-cycle */
import api from "./api";
import { auth, analytics, provider, signIn } from './firebase';
import { setUserId } from 'firebase/analytics';

const tokenName = 'access_token';

export const login = async (email, password) => new Promise((resolve, reject) => {
    try {
      auth.signInWithEmailAndPassword(email, password)
        .then((result) => {
          resolve(result.user);
        })
        .catch((error) => {
          reject(error.message);
        });
    } catch (err) {
      reject(err);
    }
  });

export const getUserToken = async () => new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line no-unused-expressions
      auth.currentUser?.getIdToken(true).then((idToken) => {
        resolve(idToken);
      }).catch((error) => {
        reject(error.message);
      });
    } catch (err) {
      reject(err);
    }
  });

export const getIdTokenRefreshed = async () => new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      unsubscribe();
      const refreshedToken = await user?.getIdToken(true).catch(err => console.error(err));
      resolve(refreshedToken);
    }, reject);
  });

export const getCurrentUser = async () => new Promise((resolve) => {
    let user;
    try {
      user = auth.currentUser;
    } catch (error) {}
    resolve(user);
  });

export const createAccount = async (params) => new Promise((resolve, reject) => {
    try {
      auth.createUserWithEmailAndPassword(params.email, params.password)
        .then((result) => {
          resolve(result.user);
        })
        .catch((error) => {
          reject(error.message);
        });
    } catch (err) {
      reject(err);
    }
  });

export const updateProfile = async (params) => new Promise((resolve, reject) => {
    try {
      const user = auth.currentUser;
      user
        .updateProfile({
          displayName: `${params.firstName }  ${ params.lastName}`,
        })
        .then(() => {
          resolve(user);
        })
        .catch((error) => {
          reject(error.message);
        });
    } catch (err) {
      reject(err);
    }
  });

export const getUserFromFirebase = () => {
  const firebaseUser = auth.currentUser;
  const user = { 'email' : firebaseUser.email, 'firstName': '', 'lastName':''};
  if (firebaseUser.displayName) {
    const name = firebaseUser.displayName.split("  ");
    if (name[0]) {
      user.firstName = name[0];
    }
    if (name[1]) {
      user.lastName = name[1];
    }
  }
  return user;
};

export const sendEmailVerification = async () => new Promise((resolve, reject) => {
    try {
      const user = auth.currentUser;
      user.sendEmailVerification().then(() => {
        resolve();
      }).catch((error) => {
        reject(error.message);
      });
    } catch (err) {
      reject(err);
    }
  });

export const verifyEmail = async (actionCode) => new Promise((resolve, reject) => {
    try {
      auth.applyActionCode(actionCode).then(() => {
        resolve();
      }).catch((error) => {
        reject(error.message);
      });
    } catch (err) {
      reject(err);
    }
  });

export const forgotPassword = (email) => new Promise((resolve, reject) => {
    try {
      auth.sendPasswordResetEmail(email).then(() => {
        resolve();
      }).catch((error) => {
        reject(error.message);
      });
    } catch (err) {
      reject(err);
    }
  });

export const changePassword = (password) => new Promise((resolve, reject) => {
    try {
      if (auth.currentUser) {
        auth.currentUser.updatePassword(password).then(() => {
          resolve();
        }).catch((error) => {
          reject(error.message);
        });
      }
    } catch (err) {
      reject(err);
    }
  });

export const storeCredentials = (credentials) => {
  localStorage.setItem(tokenName, credentials);
  api.setHeader("Authorization", `Bearer ${ credentials}`);
};

export const getCredentials = () => localStorage.getItem(tokenName);

export const removeCredentials = async () => {
  localStorage.removeItem(tokenName);
  delete api.headers.Authorization;
  await auth.signOut();
};

export const restoreCredentials = () => {
  const credentials = localStorage.getItem(tokenName);
  if (credentials) {
    api.setHeader("Authorization", `Bearer ${ credentials}`);
    return true;
  }

  return false;
};

export const myData = () => api.get("users/me");

export const putMe = (data) => api.put("users/me", data);

export const checkFirebaseUserSession = () => new Promise((resolve, reject) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        resolve(user);
      } else {
        reject("User is signed out.");
      }
    });
  });

export const setAnalyticsUserId = (userId) => {
  setUserId(analytics, userId);
};

export const signInWithGoogle = () => {
  provider.setCustomParameters({ prompt: "select_account" });
  return new Promise((resolve, reject) => {
    signIn(auth, provider)
      .then((result) => {
      resolve(result.user);
    })
      .catch((error) => {
      console.log("Error = ", error);
      reject(error.message);
      });
  });
};