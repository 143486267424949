import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContainerBlastListing from '../../components/shared-comp/blast/views/listing';
import BlastActions from '../../components/shared-comp/blast/model/actions';
import WorkflowActions from '../../redux/workflow/actions';

const mapStateToProps = (state) => ({
  blastsLoading: state.blast.blastsLoading,
  blastsError: state.blast.blastsError,
  blastsCalled: state.blast.blastsCalled,
  blasts: state.blast.blasts,
  createBlastLoading: state.blast.cSireateBlastLoading,
  createBlastError: state.blast.createBlastError,
  createdBlast: state.blast.createdBlast,
  country: state.workflow.country,
  countries: state.workflow.countries,
});

const mapDispatchToProps = (dispatch) => ({
  createBlast: bindActionCreators(BlastActions.createBlast, dispatch),
  deleteBlast: bindActionCreators(BlastActions.deleteBlast, dispatch),
  fetchBlasts: bindActionCreators(BlastActions.fetchBlasts, dispatch),
  fetchBlastsFailed: bindActionCreators(BlastActions.fetchBlastsFailed, dispatch),
  fetchBlastsRequest: bindActionCreators(BlastActions.fetchBlastsRequest, dispatch),
  fetchBlastsSuccess: bindActionCreators(BlastActions.fetchBlastsSuccess, dispatch),
  getBlast: bindActionCreators(BlastActions.getBlast, dispatch),
  fetchCountries: bindActionCreators(WorkflowActions.fetchCountries, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerBlastListing);