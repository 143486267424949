/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@mui/material';
import Icon from "../Icon";
import themeStyles from '../../../../theme.module.sass';
import menuStyles from './styles.module.sass';

const defaultButtonIconName = 'star';
const defaultButtonIconSize = 16;
const defaultButtonIconColor = "#310f49";

const createIcon = ({ color, size, icon }) => {
  if (!color) color = defaultButtonIconColor;
  if (!icon) icon = defaultButtonIconName;
  if (!size) size = defaultButtonIconSize;
  return <Icon color={color} size={size} icon={icon} className={menuStyles['item-icon']} />;
};

export const createItem = ({ icon, text, textStyles }) => <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
  {icon ? createIcon(icon) : ''}
  <span className={menuStyles["menu-text"]} style={{ ...textStyles }}> {text}</span>
</div>;


const MenuComponent = ({ items, color }) => {

  const defaultColor = "#310f49";
  const [anchorEl, setAnchorEl] = React.useState(null);
  const moreButton = <Icon className={themeStyles.clickable} color={color || defaultColor} size={20} icon="more_vert" onClick={(event) => {
    setAnchorEl(event?.currentTarget);
  }} />;

  const closeMenu = () => {
    setAnchorEl(null);
  };
  
  const openNewTab = url => {
    const win = window.open(url, "_blank");
    win.focus();
  };

  const renderItems = (items) => items.map((item, i) => <MenuItem key={i} onClick={() => {
        if (item.openNewTab) openNewTab(item.openNewTab);
        else item.onClick ? item.onClick() : '';
        closeMenu();
      }} disableRipple>
        <div className={menuStyles["menu-item"]}>{item.html ? (item.html) : (createItem(item))}</div>
      </MenuItem>);


  return (
    <div>
      {moreButton}
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu} transitionDuration={0}>
        {renderItems(items)}
      </Menu>
    </div>
  );
};

export default withTranslation()(MenuComponent);