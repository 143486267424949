/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-return-await */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { CircularProgress, Grid } from '@mui/material';
import TagsInput from 'react-tagsinput';
import * as Yup from 'yup';
import FormikErrorFocus from 'formik-error-focus';
import { withTranslation } from 'react-i18next';
import Icon from "../../../core/Icon";
import { actions, callAssignToast, crudAction, formUserLabel, getDatei18n, modules } from '../../../utils';
import DatePickerField from '../../../core/DatePickerField';
import { getCountry } from '../../../core/services/workflow';
import SearchSelect from '../../../core/SearchSelect';
import { getTenderos, getUserMeta } from '../../../referrers/services/referrers';
import { postGroups, getGroup, updateGroup, deleteGroup } from '../../services/groups';
import 'react-tagsinput/react-tagsinput.css'; // If using WebPack and style-loader.
import groupStyles from './styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import ModalCloseButton from '../../../core/ModalCloseButton';

const dateFormat = {
  year: 'numeric', month: 'short', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  hour12: false,
  timeZoneName: 'short'
};

const GroupDetails = ({ onClose = () => { }, ...props }) => {
  const [currentGroup, setCurrentGroup] = useState(null);
  const [userMeta, setUserMeta] = useState(null);
  const [groupTags, setGroupTags] = useState([]);
  const [groupTenderos, setGroupTenderos] = useState([]);
  const [currentFilters, setCurrentFilters] = useState({});

  const [currentFilterParam, setCurrentFilterParam] = useState('');
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [loaderEnabled, setLoaderEnabled] = useState(true);
  
  

  const module = modules(props.t).group;

  const headerCountry = getCountry();
  const initialCountry = headerCountry || "";

  const datei18n = getDatei18n(initialCountry);

  const t = {
    groups: props.t('Groups'),
    createGroup: props.t('Create Group'),
    createdAt: props.t('Created at'),
    updatedAt: props.t('Updated at'),
    name: props.t('Name'),
    tags: props.t('Tags'),
    filters: props.t('Filters'),
    createFilter: props.t('Create Filter'),
    tenderos: props.t('Tenderos'),
    description: props.t('Description'),
    pleaseWait: props.t('Please wait'),
    create: props.t('Create'),
    update: props.t('Update'),
    delete: props.t('Delete'),
    parameter: props.t('Parameter'),
    operator: props.t('Operator'),
    filterValue: props.t('Filter Value'),
    filterDate: props.t('Filter Date'),
    gender: props.t('Gender'),
    status: props.t('Status'),
    birthDate: props.t('birthDate'),
    networkSize: props.t('networkSize'),
    commerceType: props.t('commerceType'),
    marketingType: props.t('marketingType'),
    onboardedDate: props.t('onboardedDate'),
    activatedDate: props.t('activatedDate'),
    equal: props.t('equal'),
    lt: props.t('less than'),
    lte: props.t('less than or equal'),
    gt: props.t('greater than'),
    gte: props.t('greater than or equal'),
    addFilter: props.t('Add Filter'),
    contains: props.t('contains'),
    and: props.t('and'),
    or: props.t('or'),
    selectStatus: props.t("Select Status"),
    selectGender: props.t("Select Gender"),
    active: props.t("active"),
    activePlus: props.t("activePlus"),
    unsubcribedDueToLackOfActivity: props.t("unsubcribedDueToLackOfActivity"),
    unsubcribedByRequest: props.t("unsubcribedByRequest"),
    onboarded: props.t("onboarded"),
    engaged: props.t("engaged"),
    churned: props.t("churned"),
    male: props.t("male"),
    female: props.t("female"),
    unknown: props.t("unknown"),
    between: props.t("between"),
    referrerCreatedAt: props.t("Created at"),
    required: props.t("Required"),
    ageRange: props.t("ageRange"),
  };

  const prettyOperator = {
    equal: '=',
    '<': '<',
    lte: '≤',
    '>': '>',
    gte: '≥',
    contains: t.contains,
    between: t.between
  };

  const valueLabels = {
    true: props.t("Yes"),
    false: props.t("No")
  };

  const deleteFilterHandler = (param, value) => {
    const updatedFilters = { ...currentFilters };

    if (updatedFilters[param].operator !== 'or') {
      delete updatedFilters[param];
    } else {
      const filterValue = updatedFilters[param].value;
      filterValue.splice(filterValue.indexOf(value), 1);

      if (filterValue.length === 1) {
        updatedFilters[param].value = filterValue[0];
        updatedFilters[param].operator = 'equal';
      }
    }

    setCurrentFilters(updatedFilters);
  };

  const printPrettyParamFilters = (param, operator, value) => {
    if (typeof value === 'object' && value.toISOString) value = value.toLocaleString();
    else if (Array.isArray(value) && value.length > 0 && typeof value[0] === 'object' && value[0].toISOString) {
      value = value.map((innerValue) => innerValue.toLocaleString());
    }

    if (operator !== 'or') {
      let newValue;
      if (operator === 'between') {
        newValue = `${value[0] } ${ props.t("and") } ${ value[1]}`;
      } else if (userMeta[param]?.type === 'date' || userMeta[param]?.type === 'datetime') {
        newValue = new Intl.DateTimeFormat(datei18n, dateFormat).format(newValue);
      } else {
        const valIndex = userMeta?.[param]?.options?.findIndex(val => val === value);
        newValue = userMeta[param]?.labels?.[valIndex] || valueLabels[value] || value;
      }
      return (
        <div style={{display: "flex", gap: "8px"}}>
          <span className={groupStyles["applied-filter-tag"]}>
            {userMeta[param].label} {prettyOperator[operator]} {newValue}
          </span>
          <Icon color="#000" size={13} icon="delete-" className={groupStyles["delete-filter"]} onClick={deleteFilterHandler.bind(null, param, value)} />
        </div>
      );
    }
    // OR case
  
      return (
        <div>
          {
            value.map((filterValue, index) => {
              const valIndex = userMeta?.[param]?.options?.findIndex(val => val === filterValue);
              return <div key={index}>
                <span className={groupStyles["applied-filter-tag"]}>
                  {userMeta?.[param]?.label} {prettyOperator.equal} {userMeta?.[param]?.labels?.[valIndex] || valueLabels[filterValue]}
                </span>
                <Icon color="#000" size={13} icon="delete-" className={groupStyles["delete-filter"]} onClick={deleteFilterHandler.bind(null, param, filterValue)} />
                {(index < value.length - 1) ? (
                  <div className={groupStyles["filter-connector"]}>
                    <span>{t.or}</span>
                  </div>
                ) : ("")}
              </div>;})
          }
        </div>
      );
    
  };

  const paramType = currentFilterParam?.type?.toLowerCase();
  const paramIsDate = (paramType === 'date' || paramType === 'datetime');
  const paramIsString = paramType === 'string';
  const paramIsNumber = paramType === 'number';
  const paramIsBoolean = paramType === 'boolean';
  const paramIsEnum = paramType?.includes('enu');
  const needsOperator = !paramIsEnum && !paramIsBoolean;

  const filterParametersArray = [];
  const filterValuesArray = [];

  // Add to params Array
  if (userMeta) {
    Object.keys(userMeta).forEach(param => {
      filterParametersArray.push({ label: userMeta[param].label, type: userMeta[param].type, value: param });
    });
  }

  // If a param is selected
  if (currentFilterParam?.value && userMeta[currentFilterParam.value]) {
    if (paramIsEnum && userMeta[currentFilterParam.value].options) {
      userMeta[currentFilterParam.value].options.forEach((param, paramOptionIndex) => {
        // We are only adding options that have a corresponding label (suspended status doesn't have one)
        if (userMeta[currentFilterParam.value].labels[paramOptionIndex]) filterValuesArray.push({ label: userMeta[currentFilterParam.value].labels[paramOptionIndex] || props.t(param), value: param });
      });
    } else if (paramIsBoolean) {
      filterValuesArray.push({ label: props.t('Yes'), value: true });
      filterValuesArray.push({ label: props.t('No'), value: false });
    }
  }

  const filterOperatorsArray = [
    { label: t.equal, value: 'equal' },
    { label: t.lt, value: '<' },
    { label: t.gt, value: '>' }
  ];

  const GroupSchema = Yup.object().shape({
    name: Yup.string().required(t.required),
    tags: Yup.array(),
    tenderos: Yup.array(),
    description: Yup.string().required(t.required),
  });

  

  const crudOptions = {
    module,
    name: currentGroup?.name || '',
  };

  /**
   * Fetches Tenderos from the API, sets the Tenderos list with them and reports any errors via Toasts
   * @async
   */
  const loadUserMeta = async () => await callAssignToast(getUserMeta, (usermeta) => {
      // usermeta.referrerCreatedAt = { "type": "date" };
      setUserMeta(usermeta);
    }, 'list User Metadata');

  const formatTenderosArray = (tenderos) => (tenderos.map(tendero => ({ label: formUserLabel(tendero), value: tendero.id, ...tendero })));

  const returnTenderos = async (queryTerm) => {
    let res = [];
    const tenderosResult = await callAssignToast(getTenderos.bind(null, { q: queryTerm, country: initialCountry }), () => { }, 'list Tenderos');
    if (tenderosResult.data) res = formatTenderosArray(tenderosResult.data);
    return res;
  };

    /**
   * Passed to the list to execute on Item click, updates the form with the selected Group
   * @async
   * @param {string} id
   */
     const fetchGroupByObject = (object) => {
      const group = { ...object };
      if (group.referrers) {
        group.referrers = formatTenderosArray(group.referrers);
        setGroupTenderos(group.referrers.map(option => option.value));
      }
      setCurrentGroup(group);
    };

  // On load, get Tenderos
  useEffect(() => {
    const initLoad = async () => {
      await Promise.all([loadUserMeta()]);
      setReadyToLoad(true);
    };
    initLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On CurrentGroup update, update Groups list
  useEffect(() => {
    const currentUpdate = async () => {
      const groupCopy = { ...currentGroup};
      setCurrentFilters(groupCopy?.filters || {});
      setGroupTags([...currentGroup?.tags] || []);
    };
    if (currentGroup) currentUpdate();
  }, [currentGroup]);

  useEffect(() => {
    const afterLoad = async () => {
      if (props.object) fetchGroupByObject(props.object);
      setLoaderEnabled(false);
    };
    if (readyToLoad) afterLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToLoad]);

  // Form placeholders
  const placeholder = {
    name: props.t("Full Name"),
    description: props.t("Enter Group description"),
    tags: props.t("Group Tags"),
    addTags: props.t("Add a Tag"),
    referrers: `-- ${props.t("Select Tenderos")} -- `,
    newFilterParam: t.parameter,
    newFilterOperator: t.operator,
    newFilterValue: t.filterValue,
    newFilterDate: t.filterDate,
    ageRange: props.t("Select an Age Range"),
    onboardedDate: props.t("Select Onboarding Date"),
    activatedDate: props.t("Select Activation Date"),
    sellCategory: props.t("sellCategoryPlaceholder")
  };

  // Form type, used to differentiate between update and delete
  const submitType = {
    update: 1,
    delete: 2
  };

  // Formik initial/default values
  const formInitialValues = {
    name: currentGroup?.name || "",
    description: currentGroup?.description || "",
    type: submitType.update, // update
    referrers: currentGroup?.referrers || [],
    newFilterValue: currentGroup?.newFilterValue || ""
  };

  for (let index = 0; index < currentFilters.length; index++) {
    formInitialValues[`logicalOp${ index}`] = "and";
  }

  /**
   * Function called whenever the Formik form is submitted
   * @async
   * @param {Object} values Form values
   * @param {Object} options Functions provided by the Formik form
   */
  const formOnSubmit = async (values) => {

    if (!currentGroup) { // CREATE
      const newGroup = {
        name: values.name,
        description: values.description,
        tags: groupTags,
        referrers: groupTenderos,
        filters: currentFilters,
        country: initialCountry
      };

      await crudAction(postGroups.bind(null, newGroup), onClose,
        { ...crudOptions, name: values.name, action: actions.CREATE }, props.t
      );
    } else if (values.type && values.type === submitType.delete) { // DELETE
      await crudAction(deleteGroup.bind(null, currentGroup.id), onClose,
        { ...crudOptions, action: actions.DELETE }, props.t
      );
    } else { // UPDATE
      delete values.type;
      const result = await getGroup(currentGroup.id);
      if (result.ok) {
        const oldTenderos = result.data.data.referrers.map((tendero) => tendero.id);
        const added = groupTenderos.filter((id) => !oldTenderos.includes(id));
        const deleted = oldTenderos.filter((id) => !groupTenderos.includes(id));
        let updatedGroup = {
          name: values.name,
          description: values.description,
          tags: groupTags,
          filters: currentFilters
        };
        if (added.length > 0 || deleted.length > 0) updatedGroup = { ...updatedGroup, referrers: { added, deleted } };
        await crudAction(updateGroup.bind(null, currentGroup.id, updatedGroup), onClose,
          { ...crudOptions, action: actions.UPDATE }, props.t
        );
      }
    }
  };

  return (
    (
      loaderEnabled ? <div style={{ display: 'flex', justifyContent: 'center', padding: '200px' }}>
        <CircularProgress />
      </div>
        :
        <div>
          <ModalCloseButton onClick={() => onClose(true)} floating />
          <Grid container spacing={0} alignItems="flex-start" style={{ marginTop: "-30px" }}>
            <Grid item xs>
              <div className={themeStyles.currentContent}>
                <div className={groupStyles["group-view"]}>
                  <header className={themeStyles["header-form"]}>
                    <h3>{!currentGroup ? t.createGroup : currentGroup.name}</h3>
                  </header>
                  {!currentGroup ? (
                    <div />
                  ) : (
                    <div>
                      <div className={themeStyles["date-created"]}>
                        <b>{t.createdAt}: &nbsp;</b> {` ${ new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentGroup.created_at))}`}
                      </div>
                      <div className={themeStyles["date-updated"]}>
                        <b>{t.updatedAt}: &nbsp;</b> {` ${ new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentGroup.updated_at))}`}
                      </div>
                    </div>
                  )}
                  <div className={themeStyles["form-wrap"]}>
                    <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formOnSubmit} validationSchema={GroupSchema} autoComplete="off" >
                      {({ isSubmitting, values, handleChange, handleBlur, setFieldValue, setFieldTouched, errors, touched }) => (
                        <Form>
                          <div className={themeStyles["form-group"]}>
                            <label htmlFor="name" className={themeStyles["col-form-label"]}>{t.name}<span className={themeStyles["label-asterisk"]}>*</span></label>
                            <Field className={themeStyles["form-control"]} name="name" placeholder={placeholder.name} value={values.name} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                            {errors.name && touched.name ? (<div className={themeStyles.formError}>{errors.name}</div>) : ' '}
                          </div>
                          <div className={themeStyles["form-group"]}>
                            <label htmlFor="tags" className={themeStyles["col-form-label"]}>{t.tags}</label>
                            <TagsInput value={groupTags} inputProps={{ placeholder: placeholder.addTags }} onChange={setGroupTags} addKeys={[9, 13, 188]} />
                          </div>
                          <div className={themeStyles["form-group"]}>
                            <div className={themeStyles["form-group"]}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <label htmlFor='createFilter' className={themeStyles["col-form-label"]}>{t.createFilter}</label>
                                </Grid>
                                <Grid container spacing={1} item xs className="">
                                  <Grid item xs>
                                    {/* Param selector */}
                                    <SearchSelect
                                      key={`paramval${ values.newFilterParam}`}
                                      onChange={e => {
                                        if (e) {
                                          setFieldValue('newFilterParam', e.value);
                                          setCurrentFilterParam(e);
                                          if (userMeta[e.value]?.type === 'date' || userMeta[e.value]?.type === 'datetime') setFieldValue('newFilterValue', null);
                                          else setFieldValue('newFilterValue', '');
                                        } else {
                                          setFieldValue('newFilterParam', null);
                                          setCurrentFilterParam(null);
                                        }
                                      }
                                      }
                                      value={values.newFilterParam} onBlur={() => setFieldTouched('newFilterParam', true)}
                                      options={filterParametersArray} placeholder={placeholder.newFilterParam}
                                    />
                                  </Grid>
                                  {/* Operator selector */}
                                  {needsOperator ? (
                                    <Grid item xs>
                                      <SearchSelect
                                        key={`paramop${ values.newFilterParam}`}
                                        onChange={e => e ? setFieldValue('newFilterOperator', e.value) : setFieldValue('newFilterOperator', '')}
                                        value={values.newFilterOperator} onBlur={() => setFieldTouched('newFilterOperator', true)}
                                        options={filterOperatorsArray} placeholder={placeholder.newFilterOperator}
                                        disabled={!values.newFilterParam}
                                      />
                                    </Grid>
                                  ) : ("")}
                                  {/* Enum value selector */}
                                  {!needsOperator ? (
                                    <Grid item xs>
                                      <SearchSelect
                                        key={`paramop${ values.newFilterParam}`}
                                        onChange={e => e ? setFieldValue('newFilterValue', e.value) : setFieldValue('newFilterValue', '')}
                                        value={values.newFilterValue} onBlur={() => setFieldTouched('newFilterValue', true)}
                                        options={filterValuesArray} placeholder={placeholder.newFilterValue}
                                        disabled={!values.newFilterParam}
                                      />
                                    </Grid>
                                  ) : ("")}

                                  {/* Value selector */}
                                  {paramIsString || paramIsNumber || paramIsDate ? (
                                    <Grid item xs>
                                      {paramIsString ? (
                                        <Field className={themeStyles["form-control"]} name="newFilterValue" placeholder={placeholder.newFilterValue} value={values.newFilterValue} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" disabled={!values.newFilterOperator} />
                                      ) : ("")}
                                      {paramIsNumber ? (
                                        <Field className={themeStyles["form-control"]} type="Number" name="newFilterValue" placeholder={placeholder.newFilterValue} value={values.newFilterValue} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" disabled={!values.newFilterOperator} />
                                      ) : ("")}
                                      {paramIsDate ? (
                                        <div><DatePickerField format="yyyy/MM/dd" name="newFilterValue" placeholderText={placeholder.newFilterDate} autoComplete="off" /></div>
                                      ) : ("")}
                                    </Grid>
                                  ) : ("")}
                                  <Grid item xs={2} className={groupStyles["add-filter"]}>
                                    <button
                                      style={{ width: "120px", padding: "10px" }}
                                      className={`${themeStyles.btn } ${ themeStyles["btn-primary"] } ${ ["btn-sm"]}`}
                                      type="button"
                                      disabled={
                                        !values.newFilterParam || (needsOperator && !values.newFilterOperator) || values.newFilterValue === ""
                                      }
                                      // Add new filter
                                      onClick={(e) => {
                                        e.preventDefault();
                                        // Clone currentFilters
                                        const updatedFilters = { ...currentFilters};
                                        const newParam = values.newFilterParam;
                                        let newOperator = needsOperator ? values.newFilterOperator : 'equal';
                                        let newValue = values.newFilterValue;

                                        // Param already exists
                                        if (updatedFilters[values.newFilterParam]) {
                                          const oldOperator = updatedFilters[values.newFilterParam].operator;
                                          const oldValue = updatedFilters[values.newFilterParam].value;

                                          if (newOperator === 'equal' && oldOperator !== 'between' && oldValue !== newValue) {
                                            newOperator = 'or';

                                            // We can just push new value
                                            if (oldOperator === 'or') {
                                              if (!oldValue.includes(newValue)) {
                                                oldValue.push(newValue);
                                              }
                                              newValue = oldValue;
                                            } else { // We make it an 'or' and add value
                                              newValue = [oldValue, newValue];
                                            }
                                          } else {
                                            // Turn < + > into between or leave unchanged to replace with new values
                                            if (oldOperator === '>' && newOperator === '<') {
                                              if (oldValue < newValue) {
                                                newOperator = 'between';
                                                newValue = [oldValue, newValue];
                                              }
                                            } else if (oldOperator === '<' && newOperator === '>') {
                                              if (oldValue > newValue) {
                                                newOperator = 'between';
                                                newValue = [newValue, oldValue];
                                              }
                                            }
                                          }
                                        }

                                        updatedFilters[newParam] = { operator: newOperator, value: newValue };
                                        setCurrentFilters(updatedFilters);
                                        setCurrentFilterParam(null);
                                        setFieldValue('newFilterParam', '');
                                        setFieldValue('newFilterOperator', '');
                                        setFieldValue('newFilterValue', '');
                                      }} // insert an empty string at a position
                                    >{t.addFilter}</button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                            {currentFilters && Object.keys(currentFilters).length > 0 ? (
                              <div>
                                <label htmlFor='filters' className={`${themeStyles["col-form-label"] } ${ themeStyles["form-group"]}`}>{t.filters}</label>
                                <div>
                                  {
                                    Object.keys(currentFilters).map((param, index) => (
                                      <div key={`${param}filter`} className={themeStyles["form-group"]}>
                                        <label htmlFor="filters" className={`${themeStyles["col-form-label"] } ${ themeStyles["form-label"]}`}>{userMeta[param].label}:</label>
                                        <div>
                                          {printPrettyParamFilters(param, currentFilters[param].operator, currentFilters[param].value)}
                                        </div>
                                        {
                                          (index < currentFilters.length - 1) ? (
                                            // Filter connect
                                            <span>{t.and}</span>
                                          ) : ("")
                                        }
                                      </div>
                                    ))
                                  }
                                </div>
                              </div>
                            ) : ("")}
                          </div>

                          <div className={`${themeStyles["form-group"] } margin-bot`}>
                            <label htmlFor="tenderos" className={themeStyles["col-form-label"]}>{t.tenderos}</label>
                            <SearchSelect
                              isMulti
                              onChange={field => {
                                if (!field) {
                                  setFieldValue('referrers', []);
                                  setGroupTenderos([]);
                                } else {
                                  setFieldValue('referrers', field);
                                  setGroupTenderos(field.map(option => option.value));
                                }
                              }}
                              value={values.referrers} onBlur={() => setFieldTouched('referrers', true)}
                              loadOptions={returnTenderos} placeholder={placeholder.referrers}
                              cacheOptions
                            />
                            {errors.referrers && touched.referrers ? (<div className={themeStyles.formError}>{errors.referrers}</div>) : ' '}
                          </div>
                          <div className={themeStyles["form-group"]}>
                            <label htmlFor="description" className={themeStyles["col-form-label"]}>{t.description}</label>
                            <Field as="textarea" className={themeStyles["form-control"]} name="description" placeholder={placeholder.description} value={values.description} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                            {errors.description && touched.description ? (<div className={themeStyles.formError}>{errors.description}</div>) : ' '}
                          </div>
                          <div className={`${themeStyles["form-group"] } ${ themeStyles["form-crud-actions"]}`}>
                            <button type="submit" className={`${themeStyles.btn} ${themeStyles["btn-secondary"]}`} disabled={isSubmitting} onClick={() => {
                              // eslint-disable-next-line no-console
                              console.log({ errors });
                              setFieldValue('type', submitType.update);
                            }}>{
                                // eslint-disable-next-line no-nested-ternary
                                isSubmitting ? `${t.pleaseWait}...` : (!currentGroup ? t.create : t.update)
                              }</button>
                          </div>
                          <FormikErrorFocus offset={0} align="top" duration={1000} />

                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>

            </Grid>
          </Grid>
        </div>)
  );
};

export default withTranslation()(GroupDetails);