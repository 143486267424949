/* eslint-disable import/no-mutable-exports */
import { getAnalytics } from "firebase/analytics";
import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup /* , initializeAuth, browserLocalPersistence, browserPopupRedirectResolver, browserSessionPersistence, indexedDBLocalPersistence */ } from "firebase/auth";
import { getMessaging } from "firebase/messaging";
import { getStorage } from "firebase/storage";
  
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
let app; let auth; let analytics; let messaging; let storage;

const provider = new GoogleAuthProvider();
const signIn = signInWithPopup;

if (getApps().length < 1) {
  app = initializeApp(firebaseConfig);
  auth = getAuth(); // Simple version
  analytics = getAnalytics(); // Simple version
  messaging = getMessaging(); // Simple version
  storage = getStorage(); // Simple version
  /* const auth = initializeAuth(app, {
    persistence: [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence],
    popupRedirectResolver: browserPopupRedirectResolver,
  }); // Specific version */

  auth.useDeviceLanguage();
  
  // eslint-disable-next-line no-console
  console.log('Firebase initialized');
} else {
  app = getApp();
  auth = getAuth();
  analytics = getAnalytics();
  messaging = getMessaging();
  storage = getStorage();
}

export { app, auth, analytics, messaging, provider, signIn, storage };
