/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';

import styles from './styles.module.sass';

const MinAndMaxPriceFilter = (props) => {

  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [newQuery, setNewQuery] = useState("");
  const [getAll, setGetAll] = useState(false);
  const {applyFilter, t} = props;
  

  useEffect(() => {
    const timeOutId = setTimeout(() => setNewQuery(minValue), 500);
    return () => clearTimeout(timeOutId);
  }, [minValue]);

  useEffect(() => {
    const timeOutId = setTimeout(() => setNewQuery(maxValue), 500);
    return () => clearTimeout(timeOutId);
  }, [maxValue]);

  useEffect(() => {

    const query = {
      priceMin: minValue,
      priceMax: maxValue,
    };
    if (newQuery !== "") {
      setGetAll(true);
      applyFilter(query);
    }
    if (getAll && newQuery === "") {
      applyFilter(query);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newQuery]);
    
  
  return (
    <div className={styles["wrap-range-price"]}>
      <div>
        <div>
          <label htmlFor="promotionVisibilityFilter" className={styles["common-label"]}>{t("Min Price")}</label>
        </div>
        <input type="number" placeholder="Min Price" className={`${styles.__input } ${styles["form-control"]}`} value={minValue} onChange={event => setMinValue(event.target.value)}/>
      </div>
      <div>
        <div>
          <label htmlFor="promotionVisibilityFilter" className={styles["common-label"]}>{t("Max Price")}</label>
        </div>
        <input type="number" placeholder="Max Price" className={`${styles.__input } ${styles["form-control"]}`} value={maxValue} onChange={event => setMaxValue(event.target.value)}/>
      </div>
    </div>
  );
};
export default MinAndMaxPriceFilter;