/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-promise-executor-return */
/* eslint-disable react/forbid-prop-types */
import { t } from 'i18next';
import * as Yup from 'yup';
import moment from 'moment';
import 'moment-timezone';
import BigNumber from 'bignumber.js';
import i18n from '../../../i18n';
import { get as _get, isUndefined as _isUndefined, keys as _keys, has as _has, entries as _entries, isObjectLike as _isObjectLike, isEqual as _isEqual } from 'lodash';
import { toast } from 'react-toastify';

export const capitalize = (word) => {
  if (!word || !word[0].toUpperCase) return word;
  return word[0].toUpperCase() + word.slice(1);
};

// eslint-disable-next-line no-useless-escape
export const toUrlValidate = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

const defaultModule = { name: 'Item' };

export const getDatei18n = (country) => {
  const language = i18n.language;
  const validateLanguage = language.includes('-');
  let datei18n = null;
  if(validateLanguage){
    datei18n = `${language}`;
  }else{
    datei18n = `${language}-${country}`;
  }
  return datei18n;
};

export const MAX_ORDER_VALUE = process.env.REACT_APP_MAX_ORDER_VALUE;
export const MIN_PROMO_LABEL = process.env.REACT_APP_MIN_PROMO_LABEL;
export const MAX_PROMO_LABEL = process.env.REACT_APP_MAX_PROMO_LABEL;
export const DEFAULT_STARTING_COMMISSION = process.env.REACT_APP_DEFAULT_STARTING_COMMISSION || 20;

export const filterOperatorsArray = [
  { label: t('equal'), value: '=' },
  // { label: t('less than'), value: '<' },
  { label: t('less than or equal'), value: '<=' },
  // { label: t('greater than'), value: '>' },
  { label: t('greater than or equal'), value: '>=' }
];

export const getShopUrl = shopId => `${process.env.REACT_APP_SHOP_BASE_URL }/store/${shopId}`;

const getModuleObject = (slug, getT) => ({
  slug,
  name: capitalize(slug),
  singular: getT(capitalize(slug)),
  plural: getT(capitalize(slug), { count: 100 }),
  gender: getT(`${slug }Gender`)
});

export const modules = mt => ({
  blast: getModuleObject('blast', mt),
  customer: getModuleObject('customer', mt),
  deal: getModuleObject('deal', mt),
  group: getModuleObject('group', mt),
  order: getModuleObject('order', mt),
  referrer: getModuleObject('referrer', mt),
  user: getModuleObject('user', mt),
  address: getModuleObject('address', mt),
  status: getModuleObject('status', mt),
  commission: getModuleObject('commission', mt),
  payout: getModuleObject('payout',mt),
  promotion: getModuleObject('promotion',mt),
  recomendations: getModuleObject('recomendations',mt),
  points: getModuleObject('points',mt),
  product: getModuleObject('product',mt),
  keyword: getModuleObject('keyword',mt),
  category: getModuleObject('category',mt),
});

export const tempCommissionStatuses = {
  data: [
    'pending',
    'canceled',
    'paid'
  ]
};

export const restrictPaymentStatuses = [
  'rma requested',
  'rma rejected',
  'rma approved',
  'rma completed'
];

export const tempCommissionTypes = {
  data: [
    'order',
    'reward'
  ]
};

export const tempPayoutStatuses = {
  data: [
    'canceled', 'paid', 'partial_paid', 'unpaid'
  ]
};

const imageWidthAndHeight = (provideFile) => {
  // take the given file (which should be an image) and return the width and height
  const imgDimensions = { width: null, height: null };
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(provideFile);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        imgDimensions.width = img.width;
        imgDimensions.height = img.height;
        resolve(imgDimensions);
      };
    };
  });
};

Yup.addMethod(Yup.array, "imageDimensionCheck", function imageDimensionCheck(message, requiredWidth, requiredHeight) {
  return this.test("image-width-height-check", message, async function test(value) {
    const { path, createError } = this;
    if (value.length < 1) return;
    if (!value[0].file) return true;
    const imgDimensions = await imageWidthAndHeight(value[0].file);
    if (
      !imgDimensions.width ||
      !imgDimensions.height ||
      (imgDimensions.width/imgDimensions.height).toFixed(2) !== (requiredWidth/requiredHeight).toFixed(2)
    ) {
      return createError({
        path,
        message
      });
    }
    return true;
  });
});

const catalogMedia = (errorMessage) =>
  Yup.array().test({
    name: 'catalogMedia',
    exclusive: false,
    message: errorMessage || t('mediaTypeRequired'),
    test: function test() {
      const { catalogMedia: catalogMediaInner } = this.parent;
      if (!catalogMediaInner) return false;

      let hasProduct;
      let hasMarketing = false;
      // let hasFlyer = false; // it is not being used at the moment
      catalogMediaInner.forEach(mediaObject => {
        if (mediaObject.type === 'product') hasProduct = true;
        if (mediaObject.type === 'marketing') hasMarketing = true;
        // if (mediaObject.type === 'flyer') hasFlyer = true; // it is not being used at the moment
      });
      return (hasProduct && hasMarketing);
    }
  });
  
Yup.addMethod(Yup.array, "catalogMedia", catalogMedia);

export const tempPromotionTypesObj = {
  data:[
    {
      key: 'discounts_on_products',
      value: t('Discounts_on_products'),
      field: ['name', 'media', 'discount', 'dates', 'dealsOption', 'group', 'limitContent', 'only', 'allowCombo'],
      schema: Yup.object().shape({
        name: Yup.string().required(t('Required')),
        media: Yup.array().max(1, `${t('images_length')}`).when({
          is: value => value.length > 0,
          then: Yup.array().imageDimensionCheck(t('images_wrong_size'), 360, 180),
        }),
        discount: Yup.number(t('Required')).min(1, `${t('Discount')} ${t('greater than')} 0`).max(100, `${t('Discount')} ${t('less than or equal')} 100`).required(t('Required')),
        startAt: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
        onGoing: Yup.boolean(),
        expiresAt: Yup.date(t('Required')).typeError(t('Required')).when('onGoing', {
          is: (onGoing) => !onGoing,
          then: Yup.date(t('Required')).typeError(t('Required')).min(Yup.ref('startAt'), t('promotionDateGreater')).required(t('Required')),
          otherwise: Yup.date(t('Required')).typeError(t('Required')).nullable()
        }),
        dealsOption: Yup.string(),
        group: Yup.array(),
        exceptions: Yup.array().when('dealsOption', {
          is: 'all',
          then: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().when('type',{
                is: 'deals',
                then: Yup.array(),
                otherwise: Yup.array().min(1, t('Required')).required(t('Required'))
              })
              
              // .min(1, t('Required')).required(t('Required'))
            })
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().min(1, t('Required')).required(t('Required'))
            })
          )
        })
      }),
      type: 'DEAL'
    },
    {
      key: 'discounts_with_promo_code',
      value: t('Discounts_with_promo_code'),
      field: ['name', 'media', 'discount', 'limitContent', 'only', 'code', 'limit', 'limitByProduct', 'limitByBuyer', 'label', 'dates', 'dealsOption', 'group', 'toggle', 'allowCombo'],
      schema: Yup.object().shape({
        name: Yup.string().required(t('Required')),
        media: Yup.array().max(1, `${t('images_length')}`).when({
          is: value => value.length > 0,
          then: Yup.array().imageDimensionCheck(t('images_wrong_size'), 360, 180),
        }),
        discount: Yup.number(t('Required')).min(1, `${t('Discount')} ${t('greater than')} 0`).max(100, `${t('Discount')} ${t('less than or equal')} 100`).required(t('Required')),
        promoCode: Yup.string().min(6, t('Promo code need to have 6 characters minimum')).required(t('Required')),
        isCodeValid: Yup.boolean().oneOf([true], t('Promo code invalid')),
        hasLimitByProduct: Yup.boolean(),
        quantityByProduct: Yup.number().when('hasLimitByProduct', {
          is: (hasLimitByProduct) => hasLimitByProduct,
          then: Yup.number(),
          otherwise: Yup.number().min(1, `${t('Quantity')} ${t('greater than')} 0`) .required(t('Required'))
        }),
        hasLimitByBuyer: Yup.boolean(),
        quantityByBuyer: Yup.number().when('hasLimitByBuyer', {
          is: (hasLimitByBuyer) => hasLimitByBuyer,
          then: Yup.number(),
          otherwise: Yup.number().min(1, `${t('Quantity')} ${t('greater than')} 0`) .required(t('Required'))
        }),
        hasLimitReferrer: Yup.boolean(),
        limitReferrer: Yup.number().when('hasLimitReferrer', {
          is: (hasLimitReferrer) => hasLimitReferrer,
          then: Yup.number().nullable(),
          otherwise: Yup.number().min(1, `${t('Limit')} ${t('must be')} ${t('greater than')} 0`).required()
        }),
        label: Yup.string().min(MIN_PROMO_LABEL, `${t('Label')} ${t('must be')} ${t('greater than')} ${MIN_PROMO_LABEL} `)
          .max(MAX_PROMO_LABEL, `${t('Label')} ${t('must be')} ${t('less than or equal')} ${MAX_PROMO_LABEL} `).required(t('Required')),
        startAt: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
        onGoing: Yup.boolean(),
        expiresAt: Yup.date(t('Required')).typeError(t('Required')).when('onGoing', {
          is: (onGoing) => !onGoing,
          then: Yup.date(t('Required')).typeError(t('Required')).min(Yup.ref('startAt'), t('promotionDateGreater')).required(t('Required')),
          otherwise: Yup.date(t('Required')).typeError(t('Required')).nullable()
        }),
        dealsOption: Yup.string(),
        exceptions: Yup.array().when('dealsOption', {
          is: 'all',
          then: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().when('type',{
                is: 'deals',
                then: Yup.array(),
                otherwise: Yup.array().min(1, t('Required')).required(t('Required'))
              })
              
              // .min(1, t('Required')).required(t('Required'))
            })
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().min(1, t('Required')).required(t('Required'))
            })
          )
        }),
        group: Yup.array()
      }),
      type: 'DEAL'
    },
    {
      key: 'money_voucher',
      value: t('Money_voucher'),
      field: ['name', 'media', 'discount', 'limitContent', 'only', 'code', 'limit', 'limitByProduct', 'limitByBuyer', 'label', 'dates', 'dealsOption', 'group', 'toggle', 'allowCombo'],
      schema: Yup.object().shape({
        name: Yup.string().required(t('Required')),
        media: Yup.array().max(1, `${t('images_length')}`).when({
          is: value => value.length > 0,
          then: Yup.array().imageDimensionCheck(t('images_wrong_size'), 360, 180),
        }),
        discount: Yup.number(t('Required')).min(1, `${t('Discount')} ${t('must be')} ${t('greater than')} 0`).required(t('Required')),
        promoCode: Yup.string().min(6, t('Promo code need to have 6 characters minimum')).required(t('Required')),
        isCodeValid: Yup.boolean().oneOf([true], t('Promo code invalid')),
        hasLimitByProduct: Yup.boolean(),
        quantityByProduct: Yup.number().when('hasLimitByProduct', {
          is: (hasLimitByProduct) => hasLimitByProduct,
          then: Yup.number(),
          otherwise: Yup.number().min(1, `${t('Quantity')} ${t('greater than')} 0`) .required(t('Required'))
        }),
        hasLimitByBuyer: Yup.boolean(),
        quantityByBuyer: Yup.number().when('hasLimitByBuyer', {
          is: (hasLimitByBuyer) => hasLimitByBuyer,
          then: Yup.number(),
          otherwise: Yup.number().min(1, `${t('Quantity')} ${t('greater than')} 0`) .required(t('Required'))
        }),
        label: Yup.string().min(MIN_PROMO_LABEL, `${t('Label')} ${t('must be')} ${t('greater than')} ${MIN_PROMO_LABEL} `)
          .max(MAX_PROMO_LABEL, `${t('Label')} ${t('must be')} ${t('less than or equal')} ${MAX_PROMO_LABEL} `).required(t('Required')),
        startAt: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
        onGoing: Yup.boolean(),
        expiresAt: Yup.date(t('Required')).typeError(t('Required')).when('onGoing', {
          is: (onGoing) => !onGoing,
          then: Yup.date(t('Required')).typeError(t('Required')).min(Yup.ref('startAt'), t('promotionDateGreater')).required(t('Required')),
          otherwise: Yup.date(t('Required')).typeError(t('Required')).nullable()
        }),
        dealsOption: Yup.string(),
        exceptions: Yup.array().when('dealsOption', {
          is: 'all',
          then: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().when('type',{
                is: 'deals',
                then: Yup.array(),
                otherwise: Yup.array().min(1, t('Required')).required(t('Required'))
              })
              
              // .min(1, t('Required')).required(t('Required'))
            })
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().min(1, t('Required')).required(t('Required'))
            })
          )
        }),
        group: Yup.array()
      }),
      type: 'DEAL'
    },
    {
      key: 'first_customer_purchase',
      value: t('First_customer_purchase'),
      field: ['name', 'media', 'discount', 'limitContent', 'only', 'code', 'label', 'dates', 'dealsOption', 'limitByOrder', 'group', 'toggle', 'allowCombo'],
      schema: Yup.object().shape({
        name: Yup.string().required(t('Required')),
        media: Yup.array().max(1, `${t('images_length')}`).when({
          is: value => value.length > 0,
          then: Yup.array().imageDimensionCheck(t('images_wrong_size'), 360, 180),
        }),
        discount: Yup.number(t('Required')).min(1, `${t('Discount')} ${t('greater than')} 0`).max(100, `${t('Discount')} ${t('less than or equal')} 100`).required(t('Required')),
        promoCode: Yup.string().min(6, t('Promo code need to have 6 characters minimum')).required(t('Required')),
        isCodeValid: Yup.boolean().oneOf([true], t('Promo code invalid')),
        hasLimitByOrder: Yup.boolean(),
        quantityByOrder: Yup.number().when('hasLimitByOrder', {
          is: (hasLimitByOrder) => hasLimitByOrder,
          then: Yup.number(),
          otherwise: Yup.number().min(1, `${t('Quantity')} ${t('greater than')} 0`) .required(t('Required'))
        }),
        hasLimitReferrer: Yup.boolean(),
        limitReferrer: Yup.number().nullable(),
        label: Yup.string().min(MIN_PROMO_LABEL, `${t('Label')} ${t('must be')} ${t('greater than')} ${MIN_PROMO_LABEL} `)
          .max(MAX_PROMO_LABEL, `${t('Label')} ${t('must be')} ${t('less than or equal')} ${MAX_PROMO_LABEL} `).required(t('Required')),
        startAt: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
        onGoing: Yup.boolean(),
        expiresAt: Yup.date(t('Required')).typeError(t('Required')).when('onGoing', {
          is: (onGoing) => !onGoing,
          then: Yup.date(t('Required')).typeError(t('Required')).min(Yup.ref('startAt'), t('promotionDateGreater')).required(t('Required')),
          otherwise: Yup.date(t('Required')).typeError(t('Required')).nullable()
        }),
        dealsOption: Yup.string(),
        exceptions: Yup.array().when('dealsOption', {
          is: 'all',
          then: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().when('type',{
                is: 'deals',
                then: Yup.array(),
                otherwise: Yup.array().min(1, t('Required')).required(t('Required'))
              })
              
              // .min(1, t('Required')).required(t('Required'))
            })
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().min(1, t('Required')).required(t('Required'))
            })
          )
        }),
        group: Yup.array()
      }),
      type: 'ORDER'
    },
    {
      key: 'amount_discount',
      value: t('Amount_discount'),
      field: ['name', 'media', 'rules', 'dates', 'dealsOption', 'group', 'limitContent', 'only', 'allowCombo'],
      schema: Yup.object().shape({
        name: Yup.string().required(t('Required')),
        media: Yup.array().max(1, `${t('images_length')}`).when({
          is: value => value.length > 0,
          then: Yup.array().imageDimensionCheck(t('images_wrong_size'), 360, 180),
        }),
        startAt: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
        onGoing: Yup.boolean(),
        expiresAt: Yup.date(t('Required')).typeError(t('Required')).when('onGoing', {
          is: (onGoing) => !onGoing,
          then: Yup.date(t('Required')).typeError(t('Required')).min(Yup.ref('startAt'), t('promotionDateGreater')).required(t('Required')),
          otherwise: Yup.date(t('Required')).typeError(t('Required')).nullable()
        }),
        rules: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().required(t('Required')),
            value: Yup.number(t('Please enter a valid number')).min(1, t('Required')).required(t('Required')),
            discount: Yup.number(t('Please enter a valid number')).min(1, `${t('Discount')} ${t('greater than')} 0`).max(100, `${t('Discount')} ${t('less than or equal')} 100`).required(t('Required'))
          })
        ).min(1, t('Required')).required(t('Required')),
        dealsOption: Yup.string(),
        exceptions: Yup.array().when('dealsOption', {
          is: 'all',
          then: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().when('type',{
                is: 'deals',
                then: Yup.array(),
                otherwise: Yup.array().min(1, t('Required')).required(t('Required'))
              })
              
              // .min(1, t('Required')).required(t('Required'))
            })
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().min(1, t('Required')).required(t('Required'))
            })
          )
        }),
        group: Yup.array()
      }),
      type: 'ORDER'
    },
    {
      key: 'discount_on_order',
      value: t('Discount_on_order'),
      field: ['name', 'media', 'rules', 'dealsOption', 'dates', 'group', 'limitContent', 'only', 'allowCombo'],
      schema: Yup.object().shape({
        name: Yup.string().required(t('Required')),
        media: Yup.array().max(1, `${t('images_length')}`).when({
          is: value => value.length > 0,
          then: Yup.array().imageDimensionCheck(t('images_wrong_size'), 360, 180),
        }),
        startAt: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
        onGoing: Yup.boolean(),
        expiresAt: Yup.date(t('Required')).typeError(t('Required')).when('onGoing', {
          is: (onGoing) => !onGoing,
          then: Yup.date(t('Required')).typeError(t('Required')).min(Yup.ref('startAt'), t('promotionDateGreater')).required(t('Required')),
          otherwise: Yup.date(t('Required')).typeError(t('Required')).nullable()
        }),
        rules: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().required(t('Required')),
            value: Yup.number(t('Please enter a valid number')).min(1, t('Required')).required(t('Required')),
            discount: Yup.number(t('Please enter a valid number')).min(1, `${t('Discount')} ${t('greater than')} 0`).max(100, `${t('Discount')} ${t('less than or equal')} 100`).required(t('Required'))
          })
        ) .min(1, t('Required')).required(t('Required')),
        dealsOption: Yup.string(),
        exceptions: Yup.array().when('dealsOption', {
          is: 'all',
          then: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().when('type',{
                is: 'deals',
                then: Yup.array(),
                otherwise: Yup.array().min(1, t('Required')).required(t('Required'))
              })
              
              // .min(1, t('Required')).required(t('Required'))
            })
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().min(1, t('Required')).required(t('Required'))
            })
          )
        }),
        group: Yup.array()
      }),
      type: 'ORDER'
    },
    {
      key: 'catalog',
      value: t('Catalog'),
      field: ['name', 'catalogMedia', 'dates', 'dealsOption', 'group', 'limitContent', 'only', 'allowCombo'],
      schema: Yup.object().shape({
        name: Yup.string().required(t('Required')),
        catalogMedia: Yup.array().catalogMedia().min(2, t('Required')).max(3, `${t('images_length')}`),
        discount: Yup.number().nullable(),
        startAt: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
        onGoing: Yup.boolean(),
        expiresAt: Yup.date(t('Required')).typeError(t('Required')).when('onGoing', {
          is: (onGoing) => !onGoing,
          then: Yup.date(t('Required')).typeError(t('Required')).min(Yup.ref('startAt'), t('promotionDateGreater')).required(t('Required')),
          otherwise: Yup.date(t('Required')).typeError(t('Required')).nullable()
        }),
        dealsOption: Yup.string(),
        exceptions: Yup.array().when('dealsOption', {
          is: 'all',
          then: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().when('type',{
                is: 'deals',
                then: Yup.array(),
                otherwise: Yup.array().min(1, t('Required')).required(t('Required'))
              })
              
              // .min(1, t('Required')).required(t('Required'))
            })
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().min(1, t('Required')).required(t('Required'))
            })
          )
        }),
        group: Yup.array(),
      }),
      type: 'DEAL'
    },
    {
      key: 'money_voucher_on_order',
      value: t('Money_voucher_on_order'),
      field: ['name', 'media', 'rules_money', 'limitContent', 'only', 'code', 'limit', 'limitByOrder', 'limitByBuyer', 'label', 'dates', 'dealsOption', 'group', 'toggle', 'allowCombo'],
      schema: Yup.object().shape({
        name: Yup.string().required(t('Required')),
        media: Yup.array().max(1, `${t('images_length')}`).when({
          is: value => value.length > 0,
          then: Yup.array().imageDimensionCheck(t('images_wrong_size'), 360, 180),
        }),
        // discount: Yup.number(t('Required')).min(1, `${t('Discount')} ${t('must be')} ${t('greater than')} 0`).required(t('Required')),
        promoCode: Yup.string().min(6, t('Promo code need to have 6 characters minimum')).required(t('Required')),
        isCodeValid: Yup.boolean().oneOf([true], t('Promo code invalid')),
        hasLimitByOrder: Yup.boolean(),
        quantityByOrder: Yup.number().when('hasLimitByOrder', {
          is: (hasLimitByOrder) => hasLimitByOrder,
          then: Yup.number(),
          otherwise: Yup.number().min(1, `${t('Quantity')} ${t('greater than')} 0`) .required(t('Required'))
        }),
        hasLimitByBuyer: Yup.boolean(),
        quantityByBuyer: Yup.number().when('hasLimitByBuyer', {
          is: (hasLimitByBuyer) => hasLimitByBuyer,
          then: Yup.number(),
          otherwise: Yup.number().min(1, `${t('Quantity')} ${t('greater than')} 0`) .required(t('Required'))
        }),
        hasLimitReferrer: Yup.boolean(),
        limitReferrer: Yup.number().when('hasLimitReferrer', {
          is: (hasLimitReferrer) => hasLimitReferrer,
          then: Yup.number().nullable(),
          otherwise: Yup.number().min(1, `${t('Limit')} ${t('must be')} ${t('greater than')} 0`).required()
        }),
        label: Yup.string().min(MIN_PROMO_LABEL, `${t('Label')} ${t('must be')} ${t('greater than')} ${MIN_PROMO_LABEL} `)
          .max(MAX_PROMO_LABEL, `${t('Label')} ${t('must be')} ${t('less than or equal')} ${MAX_PROMO_LABEL} `).required(t('Required')),
        startAt: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
        onGoing: Yup.boolean(),
        expiresAt: Yup.date(t('Required')).typeError(t('Required')).when('onGoing', {
          is: (onGoing) => !onGoing,
          then: Yup.date(t('Required')).typeError(t('Required')).min(Yup.ref('startAt'), t('promotionDateGreater')).required(t('Required')),
          otherwise: Yup.date(t('Required')).typeError(t('Required')).nullable()
        }),
        dealsOption: Yup.string(),
        exceptions: Yup.array().when('dealsOption', {
          is: 'all',
          then: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().when('type',{
                is: 'deals',
                then: Yup.array(),
                otherwise: Yup.array().min(1, t('Required')).required(t('Required'))
              })
              
              // .min(1, t('Required')).required(t('Required'))
            })
          ),
          otherwise: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required(t('Required')),
              value: Yup.array().min(1, t('Required')).required(t('Required'))
            })
          )
        }),
        rules_money: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().required(t('Required')),
            amount: Yup.number(t('Please enter a valid number')).min(1, t('Required')).required(t('Required')),
            value: Yup.number(t('Please enter a valid number')).min(1, `${t('Value')} ${t('must be')} ${t('at least')} 1`).required(t('Required'))
          })
        ) .min(1, t('Required')).required(t('Required')),
        rules: Yup.array(),
        group: Yup.array()
      }),
      type: 'DEAL'
    },
  ]
};

export const tempPromotionTypes = {
  data:[
    'discounts_on_products',
    'discounts_with_promo_code',
    'money_voucher',
    'money_voucher_on_order',
    'first_customer_purchase',
    'amount_discount',
    'discount_on_order',
    'catalog'
  ]
};

export const tempFilterTypes = {
  data:[
    'deals',
    'categories',
    'price range'
  ]
};

export const getAssetType = (type) => {
  let result;
  if (type && type.length) result = type?.split('/').shift();
  return result;
};

const createVerbStrings = (vt, gender) => ({
  presentPerfect: vt("created", { context: gender }),
  presentContinuous: vt("creating", { context: gender }),
  presentSimple: vt("create", { context: gender })
});

const updateVerbStrings = (vt, gender) => ({
  presentPerfect: vt("updated", { context: gender }),
  presentContinuous: vt("updating", { context: gender }),
  presentSimple: vt("update", { context: gender })
});

const uploadVerbStrings = (vt, gender) => ({
  presentPerfect: vt("uploaded", { context: gender }),
  presentContinuous: vt("uploading", { context: gender }),
  presentSimple: vt("upload", { context: gender })
});

const deleteVerbStrings = (vt, gender) => ({
  presentPerfect: vt("deleted", { context: gender }),
  presentContinuous: vt("deleting", { context: gender }),
  presentSimple: vt("delete", { context: gender })
});

const exportVerbStrings = (vt, gender) => ({
  presentPerfect: vt("exported", { context: gender }),
  presentContinuous: vt("exporting", { context: gender }),
  presentSimple: vt("export", { context: gender })
});

const listVerbStrings = (vt, gender) => ({
  presentPerfect: vt("listed", { context: gender }),
  presentContinuous: vt("listing", { context: gender }),
  presentSimple: vt("list", { context: gender })
});

const addVerbStrings = (vt, gender) => ({
  presentPerfect: vt("added", { context: gender }),
  presentContinuous: vt("adding", { context: gender }),
  presentSimple: vt("add", { context: gender })
});

const reviewVerbString = (vt, gender) => ({
  presentPerfect: vt("reviewed", { context: gender }),
  presentContinuous: vt("reviewing", { context: gender }),
  presentSimple: vt("review", { context: gender })
});


export const actions = {
  CREATE: 'create',
  UPDATE: 'update',
  UPLOAD: 'upload',
  DELETE: 'delete',
  EXPORT: 'export',
  LIST: 'list',
  ADD: 'add',
  REVIEW: 'review'
};

const actionVerbStrings = {
  create: createVerbStrings,
  update: updateVerbStrings,
  upload: uploadVerbStrings,
  delete: deleteVerbStrings,
  export: exportVerbStrings,
  list: listVerbStrings,
  add: addVerbStrings,
  review: reviewVerbString
};

/**
   * Used to simplify calling CRUD functions and showing errors via Toasts
   * @param {function} restCall async API function to call
   * @param {function} resetFunc Form reset function
   * @param {object} options
   * @param {string} options.action
   * @param {object} options.module
   * @param {string} options.name
   */
// eslint-disable-next-line consistent-return
export const crudAction = async (restCall, resetFunc, options, t, skipSuccess=false) => {
  const { module = defaultModule, action, name } = options;
  const appendName = name ? ` (${options.name})` : '';

  try {
    const actionResult = await restCall();
    console.log('actionResult', actionResult);
    if (actionResult.ok) { // [module] "name" [present perfect]!
      resetFunc();
      if (!skipSuccess) toast.success(t("notificationSuccess", { module: module.singular, label: appendName, presentPerfect: (actionVerbStrings[action](t, module.gender)).presentPerfect }));
    } else { // Error [present continuous] [module]: ERROR_MESSAGE
      toast.error(`${t("notificationFailed", { module: module.singular, presentContinuous: (actionVerbStrings[action](t, module.gender)).presentContinuous }) } ${ actionResult?.data?.message}`);
    }
    return actionResult;
  } catch (error) { // Unable to [present simple] the [module]
    console.log(error);
    toast.error(t("notificationError", { gender: module.gender, module: module.singular, presentSimple: (actionVerbStrings[action](t, module.gender)).presentSimple }));
  }
};

/**
   * Calls an API function, uses successful results as a parameter for the assignToFunction and reports any errors via Toasts
   * @async
   * @param {function} actionFunction
   * @param {function} assignToFunction
   * @param {string} actionMessage
   */
export const callAssignToast = async (actionFunction, assignToFunction, actionMessage) => {
  let result; let skipAlert;
  try {
    const response = await actionFunction();

    if (response && response.ok && response.data.data) {
      assignToFunction(response.data.data);
      result = response.data;
      skipAlert = true;
    } else if (response && response.statusCode === 200) {
      assignToFunction(response.data);
      result = response.data;
      skipAlert = true;
    } else {
      console.log(`Error trying to ${actionMessage}: `, response.problem, response.originalError.message);
    }
  } catch (error) {
    console.log(`Error attempting to ${actionMessage}: `, error);
  }

  if (!skipAlert) toast.error(`Unable to ${actionMessage}`);
  return result;
};

export const phoneMasks = { co: '(...) ... ....', ar: '(.) ... ... ....', ec: '(.) ........', mx: '.(...) ... ....' };

// eslint-disable-next-line no-promise-executor-return
const sleep = async (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const mimeTypes = {
  jpeg: 'image',
  jpg: 'image',
  gif: 'image',
  png: 'image',
  tif: 'image',
  tiff: 'image',
  webp: 'image',
  mov: 'video',
  mp4: 'video',
  pdf: 'application',
  csv: 'text'

};

export const dateToLocalZone = (date, timeZone,format = "YYYY-MM-DDTHH:mm:ss.SSSZ") => moment.tz(date, timeZone).format(format);
export const dateToOtherZone = (date, timeZone, format = "YYYY-MM-DDTHH:mm:ss.SSSZ") => moment(date).tz(timeZone).format(format);
export const dateToOtherZoneJustTimeZone = (date, timeZone, format = "YYYY-MM-DDTHH:mm:ss.SSSZ") => moment(date).tz(timeZone, true).format(format);

export const getDateZoneFormated = (date, timeZome, formatTime = "local") => {
  if(!date) return null;
  if(!timeZome) return date;

  switch (formatTime) {
    case "local":
      return dateToLocalZone(date, timeZome);
    case "otherZone":
      return dateToOtherZone(date, timeZome);
    case "justTimeZone":
      return dateToOtherZoneJustTimeZone(date, timeZome);
  
    default:
      break;
  }

};

// eslint-disable-next-line default-param-last
export const formatDate = (date, isUTC = false, format = "YYYY/MM/DD", timeZone) => {
  if (isUTC) return timeZone ? moment.utc(date).tz(timeZone).format(format) : moment.utc(date).format(format);
  const result = timeZone ? moment(date).tz(timeZone).format(format) : moment(date).format(format);
  return result;
};

// July 6, 2021 9:01 AM
// eslint-disable-next-line default-param-last
export const formatTimestamp = (date, isUTC = false, capitalizeDate = false, timeZone) => {
  const format = "MMMM D, YYYY h:mm A";
  let result = formatDate(date, isUTC, format, timeZone);
  if (capitalizeDate) result = capitalize(result);
  return result;
};

export const formatDateTime = (date, isUTC = false) => {
  const format = "YYYY-MM-DD HH:mm:ss";
  return formatDate(date, isUTC, format);
};

export const formatToDate = (date) => moment(date).toDate();

export const formatDateTimeSimple = (date, isUTC = false) => {
  const format = "YYYY-MM-DD hh:mm A";
  return formatDate(date, isUTC, format);
};


export const durationFromNow = (date) => moment(date).fromNow();

export const makeId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getFileExtension = (filename) => filename.split('.').pop();

export const getUrlExtension = (url) => {
  if (!url) return '';
  if (!toUrlValidate.test(url)) return '';
  const urlSplit = url.substr(1 + url.lastIndexOf("/")).split('?')[0];
  const result = urlSplit.split('#')[0].substr(urlSplit.lastIndexOf(".") + 1).toLowerCase();
  return result;
};

export const cleanObject = (obj, deletedProps = []) => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    } else if (obj[propName] instanceof Date) {
      obj[propName] = moment.utc(obj[propName]).format('YYYY-MM-DDThh:mm:ss');
    } else if (obj[propName] instanceof Array) {
      if (obj[propName].length < 1) delete obj[propName];
    } else if (obj[propName] instanceof Object) {
      if (Object.keys(obj[propName]).length < 1) delete obj[propName];
    }
  }
  for (const propName in deletedProps) {
    delete obj[deletedProps[propName]];
  }
  return obj;
};

export const compareObj = (a, b) => {
  
  if(typeof a !== 'object' || typeof b !== 'object'){
    // console.log('Not Object');
  }else{
    const aKeys = Object.keys(a).sort();
    const bKeys = Object.keys(b).sort();
    if (aKeys.length !== bKeys.length) {
        return false;
    }
    if (aKeys.join('') !== bKeys.join('')) {
        return false;
    }
    for (let i = 0; i < aKeys.length; i++) {
        if ( a[aKeys[i]] !== b[bKeys[i]]) {
            return false;
        }
    }
    return true;
  }
  return null;
};

export const getSortOrder = (prop) => function (a, b) {
    if (a[prop] > b[prop]) return 1;
    if (a[prop] < b[prop]) return -1;
    return 0;
  };

export const countryPhone= {
  'co': { code: '57' },
  'ar': { code: '54', enforce: '9' },
  'ec': { code: '593', enforce: '9' },
  'mx': { code: '52', enforce: '1' },
};

const pickSampleOption = (currentPaymentMethodOptions, paymentMethod, label) => {
  let result = [...currentPaymentMethodOptions];
  const methodSample = result.find(pm => pm.value === paymentMethod);
  if (methodSample) {
    result = result.filter(pm => pm.value !== paymentMethod);
    result = [
      ...result,
      {
        // ...methodSample,
        value: paymentMethod,
        label
      }
    ];
  }
  return result;
};

const cashLabelByCountry = {
  'ar': 'Rapipago o Pago Facil',
  'co': 'Efecty, PSE'
};

export const getPaymentMethodOptions = (paymentMethods) => {
  let result = paymentMethods.map(pm => ({
      ...pm,
      value: pm.type,
      label: pm.name
    }));
  /* console.log(result);
  return; */
  const country = result[0].country.toLowerCase();
  result = pickSampleOption(result, 'CASH', cashLabelByCountry[country]);
  result = pickSampleOption(result, 'CARD', 'Tarjeta de Crédito / Débito');
  result = result.filter(pm => pm.value !== 'E-VOUCHER');
  return result;
};

export const formDealLabel = deal => (`${deal.name + (deal.sku ? ` (#${deal.sku})` : '')} - ${formatDateTimeSimple(deal.created_at)}`);
// export const formExceptionLabel = exception =>
export const formUserLabel = userObject => (`${userObject?.firstName} ${userObject?.lastName} (${userObject?.phone})`);
export const formGroupLabel = (group) => (`${group?.name} (${group?.count || 0} ${group?.count !== 1 ? t("Users") : t("User")})`);

export const formCountryLabel = (countryPram, countryList) => {
 
  const countryName = countryList?.filter(country => country.code === countryPram);
  return `${countryName[0]?.name}`;
};

export const translatedSimpleOptions = (optionsArray, keys = {}, textColor = {}) => {
  if (!optionsArray) return [];
  const { valueKey = 'value', labelKey = 'label' } = keys;
  const resultingArray = optionsArray.reduce((result, option) => {
    if (typeof option === 'object') {
      const value = option[valueKey] || option[labelKey];
      const label = option[labelKey] || value;
      const color = textColor[option[valueKey]] || '';
      if (!label) return [];
      if (option?.children) {
        result.push({
          label,
          options: option.children.map(child =>
            ({ label: child.label, value: child.key, color: textColor[child.key] || '' })
          )
        });
      } else {
        result.push({ label, value, color, ...option });
      }
    } else if (typeof option === 'string') {
      result.push({ label: t(capitalize(option)), value: option, color: textColor[option] });
    }
    return result;
  }, []);
  return resultingArray.filter(option => option != null);
};

export const addDebugger = () => {
  // eslint-disable-next-line no-debugger
  window.addEventListener('keydown', e => { if (e.key === 'F8') { debugger; } }, false);
};

export const formCol = ({ label, type, alignment = 'left', colSpan= 1, sort = null, isChecked = false, method = null, width, element = null, withCopy = false, useImage = false, expandIcon = false, expanded = false, required = false, upperCase }) =>
({
  label: (upperCase ? t(label).toUpperCase() : t(label)),
  type, align: alignment, colSpan, sort, isChecked, method, width, element, withCopy, useImage, expandIcon, expanded, required
});

export const getThumbnail = (media) => {
  if (!media?.length) return "";
  //  TODO make sure it is an image
  //  const result = media.find(m => m.type === 'product')?.url || "";
  let result = "";

  media?.forEach(e => {
    if (e.source === 'zoho') result = e.url;
    else result = media.find(m => m.type === 'product')?.url || "";
  });
  return result;
};

export const groupBy = (array, key, data) =>
  // Return the end result
   array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      ...currentValue[data]
    );
    return result;
  }, {}) // empty object is the initial value for result object
;

export const deleteDuplicates = (arr) => {
  const uniqueIds = [];
  const unique = arr.filter(element => {
    const isDuplicate = uniqueIds.includes(element.id || element.label);
    if (!isDuplicate) {
      uniqueIds.push(element.id || element.label);
      return true;
    }
    return false;
  });
  return unique;
};

export const toFloat = (value) => {
  if (value === null || value === undefined) return;
  const testValue = parseFloat(value);
  if (Number.isNaN(testValue)) return testValue;
  return BigNumber(value).decimalPlaces(2).toNumber();
};

export const onEnter = (handler) => (event) => {
  if (['keydown', 'keypress'].includes(event.type)
    && ['Enter', ' '].includes(event.key)) {
    handler();
  }
};

export const getMinMax = array => {
  if (!array?.length) return [];
  return [Math.min(...array), Math.max(...array)];
};

export const durationFronNow = (date, format="YYYYMMDD") => {
  const newFormat = format;
  return moment(date, newFormat).fromNow();
};

export const badgeColor = {
  placed: "badge-success",
  confirmado: "badge-success",
  not_confirmed: "badge-warning",
  "no confirmada": "badge-warning",

  delivered: "badge-success",
  entregado: "badge-success",
  "delivery failed": "badge-danger",
  "entrega fallida": "badge-danger",
  "out for delivery": "badge-primary",
  "en reparto": "badge-primary",
  "delivery partial": "badge-warning",
  "entrega parcial": "badge-warning",


  created: "badge-warning",
  creado: "badge-warning",

  shipped: "badge-secondary",
  enviado: "badge-secondary",
  "ready-for-shipping": "badge-light",
  "listo para enviar": "badge-light",

  canceled: "badge-danger",
  cancelado: "badge-danger",
  abandoned: "badge-danger",
  abandonada: "badge-danger",

  rejected: "badge-danger",
  rechazado: "badge-danger",
  
  refund_requested: "badge-warning",
  "rembolso solicitado": "badge-warning",
  refund_successful: "badge-success",
  "rembolso exitoso": "badge-success",
  refund_rejected: "badge-danger",
  "rembolso rechazado": "badge-danger",

  return__requested: "badge-warning",
  "devolución solicitada": "badge-warning",
  return__approved: "badge-success",
  "devolución aprobada": "badge-success",
  return__rejected: "badge-danger",
  "devolución rechazada": "badge-danger",
  return_processing: "badge-secondary",
  "devolución procesada": "badge-secondary",
  return__completed: "badge-success",
  "devolución completada": "badge-success",

  change__requested: "badge-warning",
  "cambio solicitado": "badge-warning",
  change__approved: "badge-success",
  "cambio aprobado": "badge-success",
  change__rejected: "badge-danger",
  "cambio rechazado": "badge-danger",
  change_processed: "badge-secondary",
  "cambio procesado": "badge-secondary",
  change__completed: "badge-success",
  "cambio completado": "badge-success",

  paid: "badge-info",
  pago: "badge-info",
  "payment partial": "badge-warning",
  "pago parcial": "badge-warning",

  pending: "badge-warning",
  pendiente: "badge-warning",

  order: "badge-secondary",
  orden: "badge-secondary",

  reward: "badge-light",
  incentivo: "badge-light",

  "in progress": "badge-primary",
  "en progreso": "badge-primary",

  chargeback: "badge-danger",
  contracargo: "badge-danger"
};

export const days = (dayNumber) => {
  switch (dayNumber) {
    case 0:
     return t("Sunday");
    case 1:
      return t("Monday");
    case 2:
      return t("Tuesday");
    case 3:
      return t("Wenesday");
    case 4:
      return t("Thursday");
    case 5:
      return t("Friday");
    case 6:
      return t("Saturday");
    default:
      break;
  }
};

export const repeatDays = (arrayDays) => {
  if(!arrayDays) return;
  const daysList = arrayDays?.map((day)=>(
    ` ${days(day)}`
  ));

  return `${daysList}`;
};

export const weeknumber = date => {
  const dayInMilliseconds = 1000 * 60 * 60 * 24;
  const daysInAWeek = 7;
  const thursday = 4;
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  let weekday = date.getUTCDay(); // Sunday is 0, Saturday is 6
  if (weekday === 0) {
      weekday = 7;
  }
  date.setUTCDate(date.getUTCDate() - weekday + thursday);
  const beginningOfTheYear = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  const differenceOfDatesInMilliseconds = date - beginningOfTheYear;
  return Math.ceil(((differenceOfDatesInMilliseconds / dayInMilliseconds) + 1) / daysInAWeek);
};

export const reasonInsteadOfSubstastus = [
  "canceled",
  "delivery failed"
];

export const getPercentageFromPrices = (price, discountPrice) => {
  if (!price || !discountPrice) return DEFAULT_STARTING_COMMISSION;
  const priceVal = parseFloat(price);
  const discountVal = parseFloat(discountPrice);
  if (Number.isNaN(priceVal) || Number.isNaN(discountVal)) return DEFAULT_STARTING_COMMISSION;
  let result = toFloat(((priceVal - discountVal) * 100 / priceVal));
  if (result < 0) result = 0;

  return result;
};

export const getDiscountFromPriceAndPercentage = (price, percentage = DEFAULT_STARTING_COMMISSION) => {
  if (!price) return 0;
  const priceVal = parseFloat(price);
  const percentageVal = parseFloat(percentage);
  if (Number.isNaN(priceVal) || Number.isNaN(percentageVal)) return 0;

  return toFloat(priceVal - (priceVal * ((percentageVal / 100))));
};

export const getPriceFromDiscountAndPercentage = (discountPrice, percentage = DEFAULT_STARTING_COMMISSION) => {
  if (!discountPrice) return 0;
  const discountVal = parseFloat(discountPrice);
  const percentageVal = parseFloat(percentage);
  if (Number.isNaN(discountVal) || Number.isNaN(percentageVal)) return 0;
  
  return toFloat(discountVal * 100 / (100 - percentageVal));
};

/**
 * Deep diff between two object-likes
 * @param  {Object} fromObject the original object
 * @param  {Object} toObject   the updated object
 * @return {Object}            a new object which represents the diff
 */
export const deepDiff = (fromObject, toObject, getDiff = false) => {
  const changes = {};
  const diff = {};

  const buildPath = (path, obj, key) =>
    _isUndefined(path) ? key : `${path}.${key}`;

  const walk = (fromObject, toObject, path) => {
    for (const key of _keys(fromObject)) {
      const currentPath = buildPath(path, fromObject, key);
      if (!_has(toObject, key)) {
        changes[currentPath] = { from: _get(fromObject, key) };
      }
    }

    for (const [key, to] of _entries(toObject)) {
      const currentPath = buildPath(path, toObject, key);
      if (!_has(fromObject, key)) {
        changes[currentPath] = { to };
        diff[currentPath] = to;
      } else {
        const from = _get(fromObject, key);
        if (!_isEqual(from, to)) {
          if (_isObjectLike(to) && _isObjectLike(from)) {
            walk(from, to, currentPath);
          } else {
            changes[currentPath] = { from, to };
            diff[currentPath] = to;
          }
        }
      }
    }
  };

  walk(fromObject, toObject);

  return getDiff ? diff : changes;
};

export default {
  callAssignToast,
  crudAction,
  getAssetType,
  getFileExtension,
  getUrlExtension,
  mimeTypes,
  phoneMasks,
  sleep,
  durationFromNow,
  makeId,
  cleanObject,
  getSortOrder
};