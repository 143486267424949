/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import BasicModal from '../../../core/BasicModal';
import SearchSelect from '../../../core/SearchSelect';
import PayoutsCreate from '../forms/PayoutsCreate';
import CommissionsExport from '../forms/CommissionsExport';
import { columnTypes } from '../../../core/TableCommon';
import themeStyles from '../../../../../theme.module.sass';
import styles from './styles.module.sass';
import { modules, actions, tempCommissionStatuses, capitalize, crudAction, badgeColor } from '../../../utils';
import { updateCommissions } from '../../services/commission';
import Dialog from '../../../core/Dialog';
import ListView from '../../../core/ListView';
import Price from '../../../core/Price';

const CommissionListing = ({ t, commissions, commissionsLoading, fetchCommissions, country, countries, fetchCountries }) => {
  
  const module = modules(t).commission;

  
  
  const queryParamsFilters = {
    page: 1,
    limit: 10,
    sort: [],
    q: null,
    country,
    refresh: 0
  };

  const commisionData = useMemo(()=> ({
   data: commissions?.data?.commissions,
   paging: {
    limit: Number(commissions?.data?.pagination?.perPage),
    page: Number(commissions?.data?.pagination?.currentPage),
    totalItems: Number(commissions?.data?.pagination?.total),
    totalPages: Number(commissions?.data?.pagination?.lastPage)
  },
   message: commissions?.data?.message,
   statusCode: commissions?.data?.statusCode
  }),[commissions]);

    const dialogRef = useRef();
  const [dialogContent, setDialogContent] = useState('');
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [dialogResult, setDialogResult] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [checkCommissions, setCheckCommissions ] = useState({});
  const [checkCommissionsObj, setCheckCommissionsObj ] = useState({});
  const [statusSelected, /* _ */ ] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [queryParamsFiltersInit, setQueryParamsFiltersInit] = useState(queryParamsFilters);
  const [externalRefresh, setExternalRefresh] = useState(0);


  const tableColumns = [
    { label : 'SELECTALL', type : columnTypes.SELECT, alignment : 'center', method : () => {
      const aux = checkCommissions;
      const auxObj = checkCommissionsObj;
      if (commissions && commissions.data) {
        commissions.data.commissions.map((a) => {
          aux[a.id] = !selectAll;
          auxObj[a.id] = a;
        });
      }
      setExternalRefresh(externalRefresh + 1);
      setSelectAll(!selectAll);
    },isChecked : selectAll},

    { label : 'Order ID' , sort : 'id'} ,
    { label : 'Client Name / Reward'},
    { label : 'Created', type : columnTypes.DATE , sort : 'createdAt' },
    { label : 'Order Status', type :columnTypes.BADGE, alignment: 'center' },
    { label : 'Deadline', type :columnTypes.DATE, alignment: 'center' },
    { label : 'WAO Partner' , sort : 'referrerName'},
    { label : 'Bank data', alignment: 'center'},
    { label : 'Commission to Pay', type : 'center' , sort : 'amount', alignment: 'center' },
    { label :'Commission Status', type : columnTypes.BADGE, alignment: 'center', sort : 'status' },
  ];

  useEffect(() => {
    const asyncFunc = async () => {
      const data = updateData;
      const updateCom = await crudAction(updateCommissions.bind(null, data),
        () => { }, {
        name: '',
        module: { ...modules(t).status, name: 'Commission Status' },
        action: actions.UPDATE
      }, t, false
      );
      if (updateCom?.ok) {
        setCheckCommissions({});
        setSelectAll(false);
        setExternalRefresh(externalRefresh + 1);
      }
      setDialogResult(null);
    };
    if (dialogResult) asyncFunc();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogResult]);


  const refreshCurrentQuery = () => {
    const newParams = { ...queryParamsFiltersInit, refresh: (queryParamsFiltersInit.refresh + 1) };
    setQueryParamsFiltersInit(newParams);
  };

  const formatStatus = (status) => {
    const result = status?.toUpperCase();
    return result;
  };
  
  const createData = (data) => {
    const { id,amount, status, created_at,referrer ,user,reward} = data;
    const deliveryDate = data.order?.deliveryDate || "-";
    const socioWaoName = `${referrer?.fullName } ${ referrer?.phone}`;
    const clientName = user?.fullName || reward?.name;
    const statusOrder = data?.order ? data?.order?.status : data?.order_rewards?.status ;

    const orderId = data?.order ? data.order?.orderId : data?.order_rewards?.orderId;
    // Data using null, missing backend
    const dataBank = "-";
    const amountFormated = <Price value={amount || 0} />;
    // No using in the moment
    // const type = (data?.order) ? t('Order') : t('Reward');
    // const totalPriceHtml = (data?.order) ?
    //   <Price value={data?.order?.total} />
    // : '';
    const defaultColorClass = 'badge-light';
   
    return { select:{
      id,
      checked: checkCommissions[id] || false,
      onChange: (id, value) => {
        const aux = checkCommissions;
        const auxObj = checkCommissionsObj;
        aux[id] = value;
        auxObj[id] = commissions.data.commissions.find( (a) => a.id === id);
        setCheckCommissions(aux);
        setCheckCommissionsObj(auxObj);
        setExternalRefresh(externalRefresh + 1);
        setSelectAll(false);
      }
    }, orderId, clientName, created_at, statusOrder: {
      colorClass: badgeColor[statusOrder] || defaultColorClass,
      description: null,
      value: formatStatus(t(capitalize(statusOrder)))
    },deliveryDate, socioWaoName ,dataBank , amountFormated, status: {
      colorClass: badgeColor[status.toLowerCase()] || defaultColorClass,
      description: null,
      value: formatStatus(t(capitalize(status)))
    }
  };
  };
  
  useEffect(() => {
    if (commissions && commissions.data) {
      const selectAllCheck = commissions.data.commissions.every((a) => checkCommissions[a.id]);
      setSelectAll(selectAllCheck);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commissions]);

  const translatedSimpleOptions = (optionsArray, shouldCapitalize = false) => optionsArray.map(value => ({ label: shouldCapitalize ? t(capitalize(value)) : t(value), value }));



  const changeCommissionStatus = async (e) => {
    const status = e.value;
    const ids = [];
    for (const [key, value] of Object.entries(checkCommissions)) {
      if (value) {
        ids.push(Number(key));
      }
    }
    
    if (ids.length > 0) {
      setDialogContent(t('confirmStatusChangeForNumberObject', { count: ids.length, object: t('Commission', { count: ids.length }) }));
      setDialogConfirm(true);
      setUpdateData({ status, commissions: ids });
      dialogRef.current.handleClickOpen();
      // let a = confirm(`${t('Are you sure you want to change the status of '), { count: ids.length, status: t(capitalize(status))}}`);
    } else {
      // alert(`${t('choose_some_commission')}`)
      setDialogContent(t('choose_some_commission'));
      setDialogConfirm(false);
      dialogRef.current.handleClickOpen();
      // dasd
    }
  };

  const commissionUpadateComponent = () => (
    <div className={styles['container-main']}>
    <section className={`${styles["update-selected"] } ${ themeStyles["form-wrap"]}`}>
      <label htmlFor='Update Selected'>{t('Update Selected:')} </label>
      <SearchSelect className={styles["update-item"]} placeholder="" value={statusSelected} options={[
          ...translatedSimpleOptions(tempCommissionStatuses.data, true).map((a, /* i */) => ({ 'label': a.label, 'value': a.value }))
        ]}
      onChange={changeCommissionStatus} onBlur={() => { }} />
    </section>
    <section className={styles.buttons}>
      <BasicModal content={PayoutsCreate} typeButton="bulk" onClose={refreshCurrentQuery} modalSize="md" options={{label:t('payout_button_bulk')}} object={{type: 'bulk'}} />
      <BasicModal content={PayoutsCreate} typeButton="create" onClose={refreshCurrentQuery} modalSize="sm" options={{label:t('payout_button_simple')}} object={{type: 'simple', commissions: checkCommissionsObj, commissionsMap: checkCommissions }} />
    </section>
  </div>
  );
 
  return (
    <section className={themeStyles["inner-component-view"]}>
      <Dialog ref={dialogRef} content={dialogContent} alert confirm={dialogConfirm} confirmUpdate={setDialogResult}/>
      <ListView
        createData={createData} collection={commisionData} loadingTable={commissionsLoading} tableColumns={tableColumns} module={module}
        exportView={CommissionsExport} fetchFunction={fetchCommissions} country={country}
        countries={countries} fetchCountries={fetchCountries} externalRefresh={externalRefresh}
        externalComponentTop={commissionUpadateComponent} externalQueryParamsFiltersInit={queryParamsFiltersInit}
      />
    </section>
  );
};

export default withTranslation()(CommissionListing);