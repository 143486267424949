import React, { useCallback, useEffect, useState } from "react";
import Icon from "../Icon";
import { usePrevious } from "../../../../utils/hooks";
import { withTranslation } from 'react-i18next';
import styles from './styles.module.sass';


const WaoOverlay = ({ t, active = false, messageOff = false, zIndexOverlay }) => {

  const [show, setShow] = useState(false);
  const prevShow = usePrevious(show);
  const [onBack, setOnBack] = useState(true);
  const [defaultConfig] = useState({
    message: `${t('Loading')}...`,
    background: "#000"
  });
  const [currentConfig, setCurrentConfig] = useState(defaultConfig);
  
  const revertToDefaults = useCallback(() => {
    setCurrentConfig(defaultConfig);
  }, [defaultConfig]);

  useEffect(() => {
    if (prevShow !== show && !show) {
      const timeout = setTimeout(() => {
        setOnBack(true);
        clearTimeout(timeout);
      }, 500);
    }
  }, [prevShow, show, setOnBack]);

  useEffect(() => {
    if (active) {
      setShow(true);
      setOnBack(false);
      // const timeout = setTimeout(() => {
      //   setShow(false);
      //   clearTimeout(timeout);
      // }, 1500);
    } else {
      setShow(false);
      setOnBack(true);
      // revertToDefaults();
    }
  }, [active, revertToDefaults]);

  return (
    <div className={styles.overlay} style={{ opacity: show ? 1 : 0, zIndex: onBack ? -1 : (zIndexOverlay || 1400) }}>
      {messageOff ? "" : <div>
        <span style={{ background: currentConfig.background }}><Icon style={{ marginRight: '2px' }} color="#ffffff" size={14} icon="spinner6" />
          <span style={{ background: currentConfig.background }}>{currentConfig.message}</span>
        </span>
      </div>}
    </div>
  );
};

export default withTranslation()(WaoOverlay);
