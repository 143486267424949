import DealsActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case DealsActions.fetchDealsRequest().type:
    return state.merge({
      dealsLoading: true,
      dealsError: null,
      dealsCalled: false,
    });

    case DealsActions.fetchDealsSuccess().type:
      return state.merge({
        dealsLoading: false,
        dealsError: null,
        dealsCalled: true,
        deals: action.payload.deals.data || state.deals,
      });
    
    case DealsActions.createDealRequest().type:
      return state.merge({
        createDealLoading: true,
        createDealError: null,
        createDealCalled: false,
      });

    case DealsActions.createDealSuccess().type:
      return state.merge({
        createDealLoading: false,
        createDealError: null,
        createDealCalled: true,
      });

    default:
      return state;
  }

};
