/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import Box from '@mui/material/Grid';

import { withStyles , ThemeProvider } from '@mui/styles';
import FAB from '@mui/material/Fab';
import MaterialButton from '@mui/material/Button';

import Progress from './Progress';

import { theme } from '../theme';

import PropTypes from 'prop-types';


function alignResolver(type) {
  switch (type) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    default: return 'center';
  }
}

const Button = ({ className, inverted, clean = false, undo, children, isPrimary = true,
  onPress, disabled, padding, textTransform = 'none', align = 'center', shading = false,
  minWidth = '100%', maxWidth, fontSize = '15px', color, backgroundColor, bordered = false,
  labelStyle = {}, loading = false, type = 'button', ...props }) => {

  const CustomFABButton = withStyles(themeInUse => {
    const invertedToggleBackgroundColor = inverted ? themeInUse.palette.secondary.main : themeInUse.palette.primary.main;
    return {
    root: {
      border: bordered ? `1px solid ${color || (inverted ? themeInUse.palette.primary.main : themeInUse.palette.secondary.main)}` : 'none',
      borderWidth: 1,
      shadow: shading ? '0 2px 4px 0 rgba(0, 0, 0, 0.22)' : 'none',
      minWidth: maxWidth ? null : minWidth,
      padding: 0,
      width: '100%',
      maxWidth: maxWidth ? `${maxWidth}px !important` : null,
      height: '100%',
      minHeight: !clean ? 46 : null,
      maxHeight: !clean ? 46 : null,
      userSelect: 'auto',
      pointerEvents: 'all',
      cursor: 'pointer',
      boxSizing: 'border-box',
      backgroundColor: backgroundColor === undefined ? invertedToggleBackgroundColor : backgroundColor
    },
    label: {
      alignItems: alignResolver(align),
      padding: padding || '6px 12px',
      color: color || (inverted ? themeInUse.palette.primary.main : themeInUse.palette.secondary.main),
      textTransform,
      fontSize,

      fontFamily: 'FuturaBT',
      ...labelStyle,
      '&:hover': {
        color: 'white !important'
      },
      minHeight: !clean ? 42 : null,
      boxSizing: 'border-box',
    },
    disabled: {
      backgroundColor: `${themeInUse.palette.third.main} !important`
    }
  };
})(FAB);

  const CustomButton = withStyles(themeInUse => ({
    root: {
      boxShadow: 'none',
      minWidth,
      maxWidth,
      width: '100%',
      height: '100%',
      minHeight: !clean ? 46 : null,
      userSelect: 'auto',
      pointerEvents: 'all',
      display: 'flex',
      padding,
      alignItems: alignResolver(align)
    },
    label: {
      color: color || (inverted ? themeInUse.palette.primary.main : themeInUse.palette.secondary.main),
      textTransform,
      fontSize,
      fontFamily: 'FuturaBT',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: alignResolver(align),
      ...labelStyle,
    },
  }))(MaterialButton);

  const UndoButton = withStyles(themeInUse => ({
    root: {
      backgroundColor: '#fceff2',
      height: '100%',
      minHeight: !clean ? 46 : null,
      userSelect: 'auto',
      pointerEvents: 'all',
    },
    label: {
      color: color || inverted ? themeInUse.palette.primary.main : themeInUse.palette.secondary.main,
      textTransform,
      fontSize,
      fontFamily: 'FuturaBT',
      ...labelStyle,
    }
  }))(CustomFABButton);


  const BuildButton = useCallback(() => {
    if (clean) {
      return (
        <CustomButton
          type={type}
          className={className}
          disabled={disabled}
          variant="text"
          color={isPrimary ? "primary" : "secondary"}
          onClick={() => onPress()}
          {...props}>
          {children}
          {loading && (
            <Box style={{ paddingLeft: 10 }}>
              <Progress color="secondary" size={20} />
            </Box>
          )}
        </CustomButton>
      );
    }

    if (undo) {
      return (
        <UndoButton
          type={type}
          disabled={disabled}
          // eslint-disable-next-line no-nested-ternary
          variant={inverted ? 'outlined' : (clean ? 'text' : 'extended')}
          color={isPrimary ? "primary" : "secondary"}
          onClick={() => onPress()}
          {...props}>
          {children}
          {loading && (
            <Box style={{ paddingLeft: 10 }}>
              <Progress color="secondary" size={20} />
            </Box>
          )}
        </UndoButton>
      );
    }
    return (
      <CustomFABButton
        type={type}
        disabled={disabled}
        className={className || ''}
        color={isPrimary ? "primary" : "secondary"}
        variant="extended"
        onClick={() => onPress()}
        {...props} >
        {children}
        {loading && (
          <Box style={{ paddingLeft: 10 }}>
            <Progress color="secondary" size={20} />
          </Box>
        )}
      </CustomFABButton>
    );
    
  }, [children, className, clean, disabled, inverted, isPrimary, loading, onPress, props, type, undo]);

  return (
    <Box className={className}>
      <ThemeProvider theme={theme}>
        { BuildButton() }
      </ThemeProvider>
    </Box>
  );
};

Button.propTypes = {
  inverted: PropTypes.bool,
  clean: PropTypes.bool,
  isPrimary: PropTypes.bool,
  disabled: PropTypes.bool,
  customBackgroundColor: PropTypes.string,
  onPress: PropTypes.func,
};

Button.defaultProps = {
  onPress: () => {}
};

export default Button;
