import CommissionActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case CommissionActions.fetchCommissionsRequest().type:
      return state.merge({
        commissionsLoading: true,
        commissionsError: null,
        commissionsLoaded: false,
      });

      case CommissionActions.fetchCommissionsSuccess().type:
        return state.merge({
          commissionsLoading: false,
          commissionsError: null,
          commissionsLoaded: true,
          commissions: action.payload.commissions.data || state.commissions,
        });

    default:
      return state;
  }
};
