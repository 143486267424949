import WorkflowActions from "./actions";
import InitialState from ".";

// eslint-disable-next-line default-param-last
export const reducer = (state = InitialState, action) => {


  switch (action.type) {

    case WorkflowActions.fetchCountriesRequest().type:
      return state.merge({
        countriesLoading: true,
        countriesError: null,
        countriesLoaded: false,
      });

    case WorkflowActions.fetchCountriesSuccess().type:
      return state.merge({
        countriesLoading: false,
        countriesError: null,
        countriesLoaded: true,
        // countries: action.payload.countries.data.data || state.countries,
        countries: action.payload.countries.data || state.countries,
      });
    
    case WorkflowActions.fetchUserInfoRequest().type:
      return state.merge({
        userInfoLoading: true,
        userInfoError: null,
        userInfoLoaded: false,
      });

    case WorkflowActions.fetchUserInfoSuccess().type:
      return state.merge({
        userInfoLoading: false,
        userInfoError: null,
        userInfoLoaded: true,
        // userInfo: action.payload.userInfo.data.data || state.userInfo,
        userInfo: action.payload.userInfo.data || state.userInfo,
      });
    
    case WorkflowActions.fetchUserInfoFailed().type:
      return state.merge({
        userInfoLoading: false,
        userInfoError: action.payload.error.error,
        userInfoLoaded: true,
      });
    
    case WorkflowActions.setCountryRequest().type:
      return state.merge({
        countryLoading: true,
        countryError: null,
        countryLoaded: false,
      });

    case WorkflowActions.setCountrySuccess().type:
      return state.merge({
        countryLoading: false,
        countryError: null,
        countryLoaded: true,
        country: action.payload.country || state.country,
      });

    case WorkflowActions.fetchStatusesRequest().type:
      return state.merge({
        statusesLoading: true,
        statusesError: null,
        statusesCalled: false,
      });

    case WorkflowActions.fetchStatusesSuccess().type:
      return state.merge({
        statusesLoading: false,
        statusesError: null,
        statusesCalled: true,
        statuses: action.payload.orders.data.data || state.statuses,
      });

    default:
      return state;
  }
};
