/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CategoriesActions from '../models/categories/actions';
import SearchSelect from '../SearchSelect';

// eslint-disable-next-line consistent-return
function findByParam(data, value, paramName) {
  for (let i = 0; i < data.length; i++) {
    if (data[i][paramName] === value) {
      return data[i];
    } if (data[i].options && data[i].options.length && typeof data[i].options === "object") {
      const possibleResult = findByParam(data[i].options, value, paramName);
      if (possibleResult) return possibleResult;
    }
  }
}

const FilterCategories = ({ t, onChange, onBlur, _options, value, className, isMulti, placeholder, isOptionDisabled }) => {

  const dispatch = useDispatch();

  const queryParamsFilters = {
    page : 1,
    limit : 100,
    sort : [],
    q: null,
    country: null
  };

  const [queryParamsFiltersInit ] = useState(queryParamsFilters);
  const [options, setOptions] = useState(_options);
  const [optionsTrending, setOptionsTrending] = useState([]);
  const [optionsAll, setOptionsAll] = useState([]);
  

  const {
    categories,
    categoriesTrending,
  } = useSelector(state => state.categories);

  useEffect(()=> {
    // debugger;
    dispatch(CategoriesActions.fetchCategories(queryParamsFiltersInit));
    dispatch(CategoriesActions.fetchTrendingCategories(queryParamsFiltersInit));
  }, [dispatch, queryParamsFiltersInit]);

  useEffect( () => {
    if(categories && categories.data){
      let allOptions = categories.data.map( item => ({ label: item.name_root, value: item.name_root}));
      const ids = [...allOptions.map(o => o.label), ...optionsTrending.map(o => o.label)];
      allOptions = allOptions.filter(({label}, index) => !ids.includes(label, index + 1));
      setOptionsAll(allOptions);
    }
  }, [categories, optionsTrending]);

  useEffect( () => {
    if(categoriesTrending && categoriesTrending.data){
      let trendingOptions = categoriesTrending.data.map( item => ({ label: item.category_name_root, value: item.category_name_root}));
      const ids = trendingOptions.map(o => o.label);
      trendingOptions = trendingOptions.filter(({label}, index) => !ids.includes(label, index + 1));
      setOptionsTrending(trendingOptions);
    }
  }, [categoriesTrending]);

  useEffect( () => {
    // console.log("optionsTrending, optionsAll = ", optionsTrending, optionsAll)
    setOptions([{
      label: t("All Categories"),
      value: ''
    },{
      label: t("Trending"),
      options: optionsTrending
    },{
      label: t("Others"),
      options: optionsAll
    }]);
  }, [optionsTrending, optionsAll, t]);

  const defaultValue = (defaultOptions, defVal) => {
    // console.log('defaultValue', defaultValue)
    if (isMulti) return defVal;
    const result = defaultOptions ? findByParam(defaultOptions, defVal, 'value') : '';
    return result;
  };

  return (

    
      <div className={className}>
        <SearchSelect
          classNamePrefix="react-select"
          menuPlacement="auto"
          className="react-select-container"
          value={defaultValue(options, value)}
          onChange={val => { onChange(val); }}
          onBlur={val => { onBlur(val); }}
          isMulti={isMulti}
          options={options}
          placeholder={placeholder}
          isOptionDisabled={isOptionDisabled}
          />
      </div>
    
  );
};
export default FilterCategories;