/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useCallback, useState, useEffect, useRef, useMemo } from "react";
import { withTranslation } from 'react-i18next';
import moment from "moment";
import Menu, { createItem } from '../../../core/Menu';
import { columnTypes, togglerOrientation } from '../../../core/TableCommon';
import { durationFromNow, formatTimestamp, modules, MAX_ORDER_VALUE, actions, crudAction, getMinMax, toFloat} from '../../../utils';
import { updateDeal, updateDealStatus } from "../../services/deals";
import Dialog from '../../../core/Dialog';
import themeStyles from '../../../../../theme.module.sass';
import Icon from "../../../core/Icon";
import styles from './styles.module.sass';
import { Link } from 'react-router-dom';
import ListView from "../../../core/ListView";
import Price from "../../../core/Price";
import DealsImport from "../forms/DealsImport";

const createPrice = (value, currency, percentage) => {
  if (!value) return "-";
  return (
    <div className={themeStyles.flex}>
      <div className={themeStyles.nowrap}>
        <Price value={value} currency={currency} percentage={percentage} percentageSign percentageParenthesis />
      </div>
    </div>
  );
};

const getDealCommission = ({ price, discountPrice }) => toFloat((((price - discountPrice) * 100) / price));

const dateToday = moment();
const date3DaysFromNow = moment(dateToday).add(3, "days");

const DealsListing = ({ t, deals, dealsLoading, fetchDeals, country, countries, fetchCountries }) => {
  const module = modules(t).deal;
    const dialogRef = useRef();
  const [dialogContent, setDialogContent] = useState('');
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [externalRefresh, setExternalRefresh] = useState(0);
  const [, setDialogResult] = useState(false);
  
  const crudOptions = useMemo(()=> ({
    module,
    name:  '',
  }),[module]);

  const [shouldExpandAllRows, setShouldExpandAllRows] = useState(true);

  const toggleExpandAllRows = () => {
    setShouldExpandAllRows(!shouldExpandAllRows);
  };

  const badgeColor = {
    active: 'badge-success',
    inactive: 'badge-warning',
    stopped: 'badge-danger',
    completed: 'badge-secondary'
  };

  const tableColumns = [
    { label: "Orden", sort: "priority", upperCase: true },
    { label: "ID", sort: "dealId" },
    { label: "Name", type: columnTypes.TEXT, sort: "name" },
    { label: "SKU", type: columnTypes.TOGGLE, sort: "sku", method: toggleExpandAllRows, expandIcon: true, expanded: shouldExpandAllRows },
    { label: "Qty", alignment: "center", sort: "quantity" },
    { label: "End Customer Price", sort: "price" },
    { label: "WAO Socio Price", sort: "discountPrice" },
    { label: "Market Price", sort: "marketPrice" },
    { label: "Grant Points", alignment: "center", sort: "grantPoints" },
    { label: "installmentsFree", alignment: "center", sort: "installmentPayment" },
    { label: "Start at", type: columnTypes.DATE, sort: "startAt" },
    { label: "Expires at", type: columnTypes.BADGE, sort: "expiresAt" },
    { label: "Created at", type: columnTypes.DATE, sort: "created_at" },
    { alignment: "center" }
  ];

  // TODO refactor this function
  const PriorityInput = useCallback((params) => {
    const [priority, setPriority] = useState('');
    const [dealId, setDealId] = useState('');
    const [name, setName] = useState('');
    const [showInput, setShowInput] = useState(false);
    const [priorityInit, setPriorityInit] = useState('');

    useEffect(() => {
      if (params.priority) setPriority(params.priority);
      if (params.priority) setPriorityInit(params.priority);
      if (params.dealId) setDealId(params.dealId);
      if (params.name) setName(params.name);
    }, [params]);

    const enterFunc = async () => {
      if (priority.length > 0 && Number.isNaN(priority)) {
        setDialogContent(t('Please enter a valid number'));
        setDialogConfirm(false);
        dialogRef.current.handleClickOpen();
      } else {
        const value = priority !== '' ? parseInt(priority, 10) : null;
        const updateCom = await crudAction(updateDeal.bind(null, dealId, { priority: value }), () => { },
          { ...crudOptions, name: name.title, action: actions.UPDATE }, t
        );
        if (updateCom?.ok) {
          setShowInput(false);
          setExternalRefresh(externalRefresh + 1);
        }
      }
    };

    const handleKeyDown = async (e) => {
      if (e.key === 'Enter') {
        enterFunc();
      } else if (e.key === 'Backspace') {
        setPriority('');
      }
    };

    const handleOnChange = (e) => {
      e.preventDefault();
      const value = Number(e.target.value);
      if (!Number.isNaN(value)) {
        if (e.target.value > parseInt(MAX_ORDER_VALUE, 10)) {
          e.target.value = parseInt(MAX_ORDER_VALUE, 10);
        } else if (e.target.value < 1) {
          e.target.value = 1;
        }
        setPriority(e.target.value);
      } else {
        setDialogContent(t('Please enter a valid number'));
        setDialogConfirm(false);
        dialogRef.current.handleClickOpen();
      }
    };

    return <div>
      {showInput ?
        <div className={styles['main-container']}>
          <div className={styles['order-input-container']}>
            <input key={dealId} type="text" value={priority || ''} onChange={handleOnChange} onKeyDown={handleKeyDown} />
            <Icon className={themeStyles.clickable} color="#310f49" size={15} icon="cancel" onClick={() => {
              if (priorityInit !== priority) setPriority(priorityInit);
              setShowInput(false);
            }} />
          </div>
          <span className={styles['ok-button']} onClick={() => enterFunc()}>OK</span>
        </div>
        : <div className={styles['order-container']}>
          <span >{priority || '-'}</span>
          <Icon className={themeStyles.clickable} color="#310f49" size={15} icon="edit_cover-" onClick={() => {
            setShowInput(true);
          }} />
        </div>
      }
    </div>;
  }, [crudOptions, externalRefresh, t]);

  const getPrices = useCallback(({ deals: pricesDeals, ...data }) => {
    let marketPriceResult;
    let waoPrice;
    let endCustomerPrice;
    
    if (pricesDeals && pricesDeals?.length > 1) {
      const { currency } = pricesDeals[0];
      const [minPriceDeals, maxPriceDeals] = getMinMax(pricesDeals?.map(deal => deal.price));
      if (minPriceDeals === maxPriceDeals) endCustomerPrice = createPrice(minPriceDeals, currency);
      else endCustomerPrice = <div><div className={themeStyles.nowrap}>{createPrice(minPriceDeals, currency)}{'\u00A0-'}</div>{createPrice(maxPriceDeals, currency)}</div>;

      const [minDiscountPrice, maxDiscountPrice] = getMinMax(pricesDeals?.map(deal => deal.discountPrice));
      const [minCommission, maxCommission] = getMinMax(pricesDeals?.map(deal => getDealCommission(deal)));
      if (minDiscountPrice === maxDiscountPrice && minCommission === maxCommission) waoPrice = createPrice(minDiscountPrice, currency, minCommission);
      else waoPrice = <div><div className={themeStyles.nowrap}>{createPrice(minDiscountPrice, currency, minCommission)}&nbsp;-</div>{createPrice(maxDiscountPrice, currency, maxCommission)}</div>;

      const [minMarketPrice, maxMarketPrice] = getMinMax(pricesDeals?.map(deal => deal.marketPrice));
      if (!minMarketPrice) marketPriceResult = '-';
      else if (minMarketPrice === maxMarketPrice) marketPriceResult = createPrice(minMarketPrice, currency);
      else marketPriceResult = <div><div className={themeStyles.nowrap}>{createPrice(minMarketPrice, currency)} -</div>{createPrice(maxMarketPrice, currency)}</div>;

    } else {
      let deal = data;
      if (pricesDeals) [deal] = pricesDeals;
      const { price, discountPrice, marketPrice, currency } = deal;
      const commissionPercentage = getDealCommission(deal);
      marketPriceResult = createPrice(marketPrice, currency);
      waoPrice = createPrice(discountPrice, currency, commissionPercentage);
      endCustomerPrice = createPrice(price, currency);
    }
    return { marketPriceResult, endCustomerPrice, waoPrice };
  },[]);

  const createChildren = useCallback((childrenDeals) => childrenDeals.map(data => {
    const { quantity, id, sku: skuValue, dealId: ogDealId, name: dealName, currency } = data;
    const name = { title: dealName, bold: true };
    const dealId = ogDealId || id;
    const { marketPriceResult, endCustomerPrice, waoPrice } = getPrices(data, currency);
    const sku = { content: skuValue, disabled: true };
    return {
      order: '',
      dealId, name, sku, quantity, endCustomerPrice, waoPrice, marketPriceResult, grantPoints: null, points: null,
      starts: '', ends: '', created: '', actions: null
    };
  }), [getPrices]);

  const createData = useCallback((data) => {
    const { deals: cdDeals, id, dealId: ogDealId, name: dealName, currency, priority, created_at} = data;
    const name = { title: dealName, bold: true };
    const [firstDeal] = cdDeals;
    const hasVariants = (cdDeals?.length && cdDeals?.length > 1 ? cdDeals?.length : 0);
    const { startAt, expiresAt, sku, dealId: firstDealId, status, grantPoints } = firstDeal;
    let dealId = ogDealId || firstDealId || id;
    let expiredColor = 'active-deal';
    if (dateToday.isAfter(moment(expiresAt))) expiredColor = 'expired-deal';
    else if (date3DaysFromNow.isAfter(moment(expiresAt))) expiredColor = 'warning-deal';
    const expiredHtml = <span title={durationFromNow(firstDeal)}> <span className={`${themeStyles['status-dot']} ${themeStyles[expiredColor]}`}>●</span><span className={themeStyles.capitalize}>{formatTimestamp(data.deals[0].expiresAt)}</span></span>;
    const grantPointsHtml = <div style={{width:"100px"}}>{!grantPoints ? <Icon className={themeStyles.clickable} color="#310f49" size={15} icon="check-list" /> : "-"}</div>;
    const editButtonHtml = createItem({ icon: { icon: 'edit_cover-' }, text: t("Edit") });
    const editHtml = <Link className={styles['edit-color']} to={{ pathname: '/deals/create' }} state={{ object: data }}>{editButtonHtml}</Link>;
    const cloneButtonHtml = createItem({ icon: { icon: 'file_double-1' }, text: t("Clone") });
    const cloneHtml = <Link className={styles['edit-color']} to={{ pathname: '/deals/create' }} state={{ object: data, clone: true }}>{cloneButtonHtml}</Link>;
    const menuItems = [
      { html: editHtml },
      { html: cloneHtml }
    ];

    const updateDealStatustoActive = cdDeals?.reduce((dealStatus, deal) => {
      let dealStatusArray = [];
      if (deal.status === "disabled"){
        dealStatusArray = [...dealStatus, {id: deal.id, status:"active"}];
      }
      return dealStatusArray;
    }, []);

    const updateDealStatustoDisable = cdDeals?.reduce((dealStatus, deal) => {
      let dealStatusArray = [];
      if (deal.status === "active"){
        dealStatusArray = [...dealStatus, {id: deal.id, status:"disabled"}];
      }
      return dealStatusArray;
    }, []);
    
    const activePromoButton = {
      icon: {icon:  'arrow_in_right-'},
      text: t("Restart Deal"),
      onClick: async () => {
        const response = await crudAction(updateDealStatus.bind(null, data.id, {deals: updateDealStatustoActive}), ()=>{},
          {
            module, name: data.name, action: actions.UPDATE }, t
        );
        if (response?.ok) {
          setExternalRefresh(externalRefresh + 1);
        }
      }
    };

    const stopPromoButton = {
      icon: {icon: 'cancel'},
      text: t("Stop Deal"),
      onClick: async () => {
        const response = await crudAction(updateDealStatus.bind(null, data.id, {deals: updateDealStatustoDisable}), ()=>{},
          {
            module, name: data.name, action: actions.UPDATE }, t
        );
        if (response?.ok) {
          setExternalRefresh(externalRefresh + 1);
        }
      }
    };

    if (status === "disabled") menuItems.push(activePromoButton);
    if (status === "active") menuItems.push(stopPromoButton);

    const skuToggler = { content: (sku || '') };
    
    if (hasVariants) {
      dealId = "";
      skuToggler.content = '';
      // skuToggler.orientation = togglerOrientation.BOTTOM;
      skuToggler.toggler = () => <div style={{color: "#000000de"}} className={`${themeStyles.link} ${themeStyles.nowrap}`}>{t("View variants", {count: hasVariants})}</div>;
    } else {
      skuToggler.disabled = true;
    }
    
    const updatedQuantity = cdDeals.reduce((totalQuantity, deal) => {
      let quantity = null;
      if(deal.quantity === null){
        quantity = t('No Limit');
      }else{
        quantity = deal.quantity + totalQuantity;
      }
      return quantity;
    }, null);

    const getInstallmentsFree = (deals) => {
      let installments = null;
      if(deals[0].installmentPayment === null){
        installments = 'N/A';
      }else{
        installments = deals[0].installmentPayment;
      }
      return installments;
    };

    const installmentsFree = <div style={{width:"100px"}}>
      <span>
      {getInstallmentsFree(cdDeals)}
      </span>
    </div>;

    const { marketPriceResult, endCustomerPrice, waoPrice } = getPrices(data, currency);

    const menu = <Menu items={menuItems} />;

    let statusValue = t('Active');
    let colorClassStatus = badgeColor.active;


    if (status?.toUpperCase() === 'ENABLED'){
      const dateObj = moment(startAt);
      if (moment().isSameOrAfter(dateObj)){
        const dateExpired = moment(expiresAt);
        if (dateExpired.isValid()) {
          if (!moment().isSameOrBefore(dateExpired)) {
            statusValue = `${t('completed')} ${durationFromNow(data.expiresAt)}`;
            colorClassStatus = badgeColor.completed;
          }
        }
      } else {
        statusValue = `${t('Starts')} ${durationFromNow(data.startAt)}`;
        colorClassStatus = badgeColor.inactive;
      }
    } else if (status?.toUpperCase() === 'DISABLED') {
      colorClassStatus = badgeColor.inactive;
      statusValue = t('Stopped');
    }

    const result = {
      order: (expiredColor === 'expired-deal' && !priority) ? '-' : <PriorityInput priority={priority} name={name} dealId={data.id} />,
      dealId,
      name,
      skuToggler,
      updatedQuantity,
      endCustomerPrice,
      waoPrice,
      marketPriceResult,
      grantPointsHtml,
      installmentsFree,
      startAt,
      expiredHtml:{
        colorClass: status === "disabled" ? colorClassStatus : "none",
        description: expiredHtml,
        value: status === "disabled" ? statusValue : "",
        valuePosicion: togglerOrientation.BOTTOM
      },
      created_at,
      actions: menu
    };
    if (hasVariants) result.children = createChildren(data.deals);

    return result;
  }, [badgeColor.active, badgeColor.completed, badgeColor.inactive, createChildren, externalRefresh, getPrices, module, t]);


  return (
    <section className={themeStyles["inner-component-view"]}>
      <Dialog ref={dialogRef} content={dialogContent} alert confirm={dialogConfirm} confirmUpdate={setDialogResult} />
      <ListView
        bulkUpdateView={DealsImport}
        location='/deals/create' createData={createData} collection={deals} loadingTable={dealsLoading} tableColumns={tableColumns} module={module} expandAllRows={shouldExpandAllRows}
        fetchFunction={fetchDeals} country={country} countries={countries} fetchCountries={fetchCountries} externalRefresh={externalRefresh}
      />
    </section>
  );
};

export default withTranslation()(DealsListing);