/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useField, useFormikContext } from "formik";
// import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";
import { createTheme, Stack, ThemeProvider } from "@mui/material";
import Icon from "../Icon";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);

const DatePickerFieldMui = ({ typePickers = ["DesktopDatePicker"], stackDirection = "row", ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const selectPicker = (picker) => typePickers?.some(somePicker => somePicker === picker);

  const handleChange = (val) => {

    if(props.setFunction){
      props.setFunction(val.toISOString());
    } else {
      setFieldValue(field.name, val.toISOString());
    }
    switch (field.name) {
      case 'expectedDeliveryDate':
        setFieldValue('isChangedExpectedDeliveryDate', true);
        break;
      default:
        break;
    }
  };

  const customTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            padding: "7.5px 14px",
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            minWidth: "100%",
            maxHeight: "38px"
          }
        }
      },
      MuiPickersBasePicker: {
        pickerView: {
          backgroundColor: "black"
        }
      },
      MuiPickersDay: {
        day: {
          color: "light-gray",
          fontFamily: "\"Do Hyeon\", sans-serif",
          backgroundColor: "white",
          borderRadius: "0px",
        },
        container: {
          backgroundColor: "black"
        },
        daySelected: {
          backgroundColor: "",
          color: "light-gray"
        },
        dayDisabled: {
          color: "black",
        },
        current: {
          color: "",
        },
      },
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack direction={stackDirection} spacing={props?.spacingStack}>
          {selectPicker("DesktopDatePicker") && <DesktopDatePicker style={{}}
            {...field}
            {...props}
            value={dayjs(field.value).tz(props?.timeZone)}
            onChange={handleChange}
            // renderInput={(params) => <TextField {...params} />}
          />}
          {selectPicker("MobileDatePicker") && <MobileDatePicker
            {...field}
            {...props}
            value={dayjs(field.value).tz(props?.timeZone)}
            onChange={handleChange}
            // renderInput={(params) => <TextField {...params} />}
            slotProps={{ textField: { InputProps: { endAdornment: <Icon icon="clock" color="#CCC" size="16" /> } } }}
          />}
          {selectPicker("MobileTimePicker") && <MobileTimePicker
            {...field}
            {...props}
            value={dayjs(field.value).tz(props?.timeZone)}
            onChange={handleChange}
            // renderInput={(params) => <TextField {...params} />}
            slotProps={{ textField: { InputProps: { endAdornment: <Icon icon="calendar-1" color="#CCC" size="16" /> } } }}
          />}
          {selectPicker("TimePicker") && <TimePicker
            {...field}
            {...props}
            value={dayjs(field.value).tz(props?.timeZone)}
            onChange={handleChange}
            // renderInput={(params) => <TextField {...params} />}
          />}
          {selectPicker("DateTimePicker") && <DateTimePicker
            {...field}
            {...props}
            value={dayjs(field.value).tz(props?.timeZone)}
            onChange={handleChange}
            // renderInput={(params) => <TextField {...params} />}
          />}
        </Stack>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default DatePickerFieldMui;