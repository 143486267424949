/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { CircularProgress, Collapse, Grid } from '@mui/material';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import FormikErrorFocus from 'formik-error-focus';
import DatePickerField from '../../../core/DatePickerField';
import { actions, callAssignToast, countryPhone, crudAction, formatDateTime, getDatei18n, modules, phoneMasks } from '../../../utils';
import PhoneInputEnforcer from '../../../core/PhoneInputEnforcer';
import resellerStyles from './styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import SearchSelect from '../../../core/SearchSelect';
import Address, { defaultAddressValues } from '../../../core/Address';

import { getCountry, getCountries } from '../../../core/services/workflow';
import { getUserMeta, postTenderos, getTendero, updateTendero, softDeleteTendero } from '../../services/referrers';
import ModalCloseButton from '../../../core/ModalCloseButton';
import ToggleCheck from '../../../core/ToggleCheck';

const dateFormat = {
  year: 'numeric', month: 'short', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  hour12: false,
  timeZoneName: 'short'
};

const printDocumentType = (t) => {
  const typesArray = [
    { label: t('ID'), value: 'national', key: "1" },
    { label: t('Passport'), value: 'passport', key: "2" }
  ];
  return typesArray;
};

const TenderoDetails = ({ t, onClose = () => { }, ...props }) => {
  const [currentTendero, setCurrentTendero] = useState(null);
  const [userMeta, setUserMeta] = useState(null);
  const [userMetaStructured, setUserMetaStructured] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loaderEnabled, setLoaderEnabled] = useState(true);
  
  

  const module = modules(t).referrer;

  const headerCountry = getCountry();
  const initialCountry = currentTendero?.country || headerCountry || "";

  const datei18n = getDatei18n(initialCountry);

  const defaultCountries = ["us", "co", "gt", "ar", "ec"];

  // TODO Remove translation file or move it to a separate file
  const ts = {
    tenderos: t('Tenderos'),
    createTendero: t('Create Tendero'),
    createdAt: t('Created at'),
    updatedAt: t('Updated at'),
    name: t('Name'),
    tags: t('Tags'),
    description: t('Description'),
    phoneNumber: t('Phone Number'),
    email: t('Email'),
    documentType: t('Document Type'),
    document: t('Document'),
    userReferrer: t('Refferal code'),
    sendNotification: t('Send notifications'),
    country: t('Country'),
    region: t('Region'),
    city: t('City'),
    postalCode: t('Postal Code'),
    pleaseWait: t('Please Wait'),
    create: t('Create'),
    update: t('Update'),
    delete: t('Delete'),
    tenderoInformation: t('Tendero Information'),
    address: t('Address'),
    additionalInfo: t('Additional Information'),
    birthDate: t('birthDate'),
    gender: t('gender'),
    status: t('status'),
    networkSize: t('networkSize'),
    commerceType: t('commerceType'),
    marketingType: t('marketingType'),
    yes: t('Yes'),
    no: t('No'),
    required: t('Required'),
    invalidEmail: t("Invalid email")
  };

  const TenderoSchema = Yup.object().shape({
    firstName: Yup.string().required(ts.required),
    lastName: Yup.string().required(ts.required),
    country: Yup.string().required(ts.required),
    phone: Yup.string().min(8, ts.required).required(ts.required),
    email: Yup.string().email(ts.invalidEmail),
    // address: AddressSchema.default(undefined),
    documentType: Yup.string().when('document', {
      is: document => !document,
      then: Yup.string(),
      otherwise: Yup.string().required(ts.required)
    }),
    document: Yup.string().when('documentType', {
      is: documentType => !documentType,
      then: Yup.string(),
      otherwise: Yup.string().required(ts.required)
    }),
    userReferrer: Yup.string().nullable(),
    sendNotification: Yup.boolean().nullable()
  }, [['document', 'documentType']]);

  

  const crudOptions = {
    module,
    name: currentTendero?.firstName ? (`${currentTendero?.firstName } ${ currentTendero?.lastName}`) : '',
  };

  /**
   * Fetches Tenderos from the API, sets the Tenderos list with them and reports any errors via Toasts
   * @async
   */
  const loadUserMeta = () => callAssignToast(getUserMeta, setUserMeta, 'list User Metadata');

  /**
   * @async
   * Fetches Countries from the API, sets the country list with them and reports any errors via Toasts
   */
  const loadCountries = () => callAssignToast(getCountries, setCountryList, 'list Countries');

  /**
   * Passed to the list to execute on Item click, updates the form with the selected Tendero
   * @async
   * @param {string} id
   */
   const fetchTenderoByObject = (object) => callAssignToast(getTendero.bind(null, object.id), async (tendero) => {
    if (tendero.metadata) {
      Object.keys(userMeta).forEach(param => {
        let value = tendero.metadata[param];
        if (!value) return;
        if (userMeta[param].type === 'date' || userMeta[param].type === 'datetime') {
          if (value && typeof value !== 'object' || !value.toISOString) value = new Date(value);
        }
        tendero[param] = value;
      });
    }
    setCurrentTendero(tendero);
  }, 'load Reseller');

  // On load, get Countries and Tenderos
  useEffect(() => {
    const initLoad = async () => {
      await Promise.all([loadCountries(), loadUserMeta()]);
      setReadyToLoad(true);
    };
    initLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On Countrylist update, update Countrycodes
  useEffect(() => {
    const currentUpdate = async () => {
      setCountryCodes(countryList.map(country => (country.code).toLowerCase()));
    };
    if (countryList) currentUpdate();
  }, [countryList]);

  useEffect(() => {
    const afterLoad = async () => {
      if (props.object) await fetchTenderoByObject(props.object);
      setLoaderEnabled(false);
    };
    if (readyToLoad) afterLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToLoad]);

  useEffect(() => {
    const afterLoad = async () => {
      const rowEvery2 = Object.keys(userMeta).reduce((rows, key, index) => (index % 2 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows, []);
      setUserMetaStructured(rowEvery2);
    };
    if (userMeta) afterLoad();
  }, [userMeta]);

  const placeholder = {
    firstName: t("Enter your first name"),
    lastName: t("Enter your last name"),
    phone: "+1 (702) 123-4567",
    email: "email@tendero.com",
    country: t("Enter your country"),
    documentType: ` -- ${t("Select a Type")} -- `,
    document: t("Enter your document"),
    gender: t("Select a gender"),
    status: t("Select a status"),
    networkSize: t("Specify your Network Size"),
    commerceType: t("Select the Commerce Type"),
    marketingType: t("Select the Marketing Type"),
    ageRange: t("Select an Age Range"),
    onboardedDate: t("Select Onboarding Date"),
    activatedDate: t("Select Activation Date"),
    sellCategory: t("sellCategoryPlaceholder"),
    businessPhone: t("isBusinessPhone"),
    userReferrer: t('Refferal code'),
  };

  // Form type, used to differentiate between update and delete
  const submitType = {
    update: 1,
    delete: 2
  };


  const initialCountryLowerCase = initialCountry.toLowerCase();
  const defaultPhonePrefix = (`+${ countryPhone[initialCountryLowerCase].code }${countryPhone[initialCountryLowerCase].enforce ? countryPhone[initialCountryLowerCase].enforce : ""}`);

  // Formik initial/default values
  const formInitialValues = {
    firstName: currentTendero?.firstName || "",
    lastName: currentTendero?.lastName || "",
    phone: currentTendero?.phone || defaultPhonePrefix,
    email: currentTendero?.email || "",
    country: initialCountry,
    document: currentTendero?.document || "",
    userReferrer: currentTendero?.userReferrer || "",
    documentType: currentTendero?.documentType || "",
    sendNotification: currentTendero?.sendNotification || false,
    address: (currentTendero?.addresses && currentTendero?.addresses[0]) ? {
      addressId: currentTendero?.addresses?.[0]?.id || 0,
      fullAddress: currentTendero?.addresses?.[0]?.address1 || "",
      address1: currentTendero?.addresses?.[0]?.address1 || "",
      address2: currentTendero?.addresses?.[0]?.address2 || "",
      description: currentTendero?.addresses?.[0]?.description || "",
      region: currentTendero?.addresses?.[0]?.region || "",
      zipCode: currentTendero?.addresses?.[0]?.zipCode || "",
      city: currentTendero?.addresses?.[0]?.city || "",
      country: currentTendero?.addresses?.[0]?.country || currentTendero?.country || "",
      lat: currentTendero?.addresses?.[0]?.lat || "",
      lng: currentTendero?.addresses?.[0]?.lng || "",
    } : defaultAddressValues,
    type: submitType.update // update,
  };

  if (userMeta) {
    Object.keys(userMeta).forEach(param => {
      let defaultValue = '';
      if (userMeta[param].type === 'date' || userMeta[param].type === 'datetime') {
        defaultValue = null; // ageOfMayority
      }
      if (userMeta[param].default) {
        defaultValue = userMeta[param].default;
      }
      formInitialValues[param] = (currentTendero && currentTendero[param]) ? currentTendero[param] : defaultValue;
    });
  }

  /**
   * Function called whenever the Formik form is submitted
   * @async
   * @param {Object} values Form values
   * @param {Object} options Functions provided by the Formik form
   */
  const formOnSubmit = async (values) => {
    const isUpdate = !(!currentTendero);

    const newAddress = {
      address1: values.address?.address1,
      country: values.country,
      city: values.address?.city
    };

    if (isUpdate || values?.address?.address2) newAddress.address2 = values.address?.address2 || null;
    if (isUpdate || values?.address?.region) newAddress.region = values.address?.region || null;
    if (isUpdate || values?.address?.description) newAddress.description = values.address?.description || null;
    if (isUpdate || values?.address?.zipCode) newAddress.zipCode = values.address?.zipCode || null;

    const newTendero = {
      firstName: values.firstName,
      lastName: values.lastName,
      country: values.country,
      metadata: {},
      phone: values.phone[0] !== '+' ? `+${ values.phone}` : values.phone
    };
    if (isUpdate || (newAddress.address1 && newAddress.country && newAddress.city)) {
      newTendero.addresses = newAddress.address1 ? [newAddress] : [];
    }

    if (isUpdate || values?.email) newTendero.email = values.email ? values.email.trim() : null;
    if (isUpdate || values?.document) newTendero.document = values.document || null;
    if (isUpdate || values?.documentType) newTendero.documentType = values.documentType || null;
    if (isUpdate || values?.userReferrer) newTendero.userReferrer = values.userReferrer || null;
    if (isUpdate || values?.sendNotification) newTendero.sendNotification = values.sendNotification || false;

    Object.keys(userMeta).forEach(param => {
      let value = values[param];
      if (value) {
        // TODO change once the type is updated to date instead of datetime
        if (userMeta[param].type === 'datetime' || userMeta[param].type === 'date') {
          value = formatDateTime(value);
        }
        if (value !== '' && value !== "[]" && userMeta[param].editable) newTendero.metadata[param] = value;
      }
    });

    if (!currentTendero) { // CREATE
      await crudAction(postTenderos.bind(null, newTendero), onClose,
        { ...crudOptions, name: values.name, action: actions.CREATE }, t
      );
    } else if (values?.type === submitType.update) { // UPDATE
      if (newTendero.addresses.length > 0) newTendero.addresses[0].id = currentTendero?.addresses?.[0]?.id;
      await crudAction(updateTendero.bind(null, currentTendero.id, newTendero), onClose,
        { ...crudOptions, action: actions.UPDATE }, t
      );
    }
  };

  const getOptionsSelect = (data) => {
    if (!data) return;
    return data.map((item, index) => ({
        key: index,
        value: item.code,
        label: item.name
      }));
  };

  // eslint-disable-next-line react/display-name
  // eslint-disable-next-line react/no-unstable-nested-components
  const RenderMetaField = React.memo(({ param, value, setFieldValue, setFieldTouched }) => {
    const divStyles = {width: "100%"};
    const metaLabel = <label htmlFor="filters" className={themeStyles["col-form-label"]}>{userMeta[param].label}:</label>;
    if (userMeta[param].type === 'date') {
      return <div style={divStyles}>
        {metaLabel}
        <DatePickerField dateFormat="yyyy-MM-dd hh:mm" name={param} placeholderText={placeholder[param]} autoComplete="off" showTimeSelect timeIntervals={1} />
      </div>;
    } if (userMeta[param].type === 'datetime') {
      // TODO If datetime shouldn't we use datetime picker?
      return <div style={divStyles}>
        {metaLabel}
        <DatePickerField dateFormat="yyyy-MM-dd hh:mm" name={param} placeholderText={placeholder[param]} autoComplete="off" showTimeSelect timeIntervals={1} />
      </div>;
    } if (userMeta[param].type === 'number') {
      return <div style={divStyles}>
        {metaLabel}
        <Field className={themeStyles["form-control"]} name={param} placeholder={placeholder[param]} autoComplete="new-password" value={value}
          disabled={(!userMeta[param]?.editable)}
        />
      </div>;
    } if (userMeta[param].type === 'Enu' && userMeta[param].options && userMeta[param].options.length > 0) {

      const optionsArray = userMeta[param].options.map((option, index) =>
        // TODO Referrer Gender exception
         ({
          label: (param === 'gender' && userMeta[param].optionsOld[index] === 'unknown') ? t('unknownGender') : (userMeta[param].labels[index] || t(option)),
          value: option
        })
      );

      return <div style={divStyles}>
        {metaLabel}
        <SearchSelect
          onChange={e => {
            if (e) {
              setFieldValue(param, e.value);
            } else {
              setFieldValue(param, null);
            }
          }
          }
          value={value}
          onBlur={() => setFieldTouched('newFilterParam', true)}
          placeholder={placeholder[param]}
          options={optionsArray}
          disabled={(!userMeta[param]?.editable)}
        />
      </div>;

    } if (userMeta[param].type === 'Boolean') {
      return <label style={{ marginBottom: "16px", width: "100%" }} htmlFor='meta-checkbox' className={themeStyles["meta-checkbox"]}>
        <span>{metaLabel} {placeholder[param]}</span>
        <Field type="checkbox" name={param}
          disabled={(!userMeta[param]?.editable)}
        />
      </label>;
    }

    return '';
  });

  return (
    (
      loaderEnabled ? <div style={{ display: 'flex', justifyContent: 'center', padding: '200px' }}>
        <CircularProgress />
      </div>
        :
        <div>
          <ModalCloseButton onClick={() => onClose(true)} floating />
          <Grid container spacing={0} alignItems="flex-start" style={{ marginTop: "-30px" }}>
            <Grid item xs>
              <div className={themeStyles.currentContent}>
                <div className={resellerStyles["tendero-view"]}>
                  <header className={themeStyles["header-form"]}>
                    <h3>{!currentTendero ? ts.createTendero : `${currentTendero.firstName } ${ currentTendero.lastName}`}</h3>
                  </header>
                  {deleteConfirmation ? (
                    <Grid container className={resellerStyles["delete-section"]}>
                      <Grid item xs={12} className={resellerStyles["delete-description"]}>
                        {t('deleteConfirmMessage', { name: `${currentTendero.firstName } ${ currentTendero.lastName}` })}
                      </Grid>
                      <Grid item xs={12} className={`${themeStyles["form-group"] } ${ resellerStyles["delete-buttons"]}`}>
                        <button type="button" className={`${themeStyles.btn } ${ themeStyles["btn-transparent"]}`} disabled={deleting} onClick={(e) => {
                          e.preventDefault();
                          setDeleteConfirmation(false);
                        }}>{t('Cancel')}</button>
                        <button type="button" className={`${themeStyles.btn } ${ themeStyles["btn-danger"]}`} disabled={deleting} onClick={
                          async (e) => {
                            e.preventDefault();
                            setDeleting(true);
                            // DELETE
                            await crudAction(softDeleteTendero.bind(null, currentTendero.id), onClose,
                              { ...crudOptions, action: actions.DELETE }, t
                            );
                          }
                        }>{ts.delete}</button>
                      </Grid>
                    </Grid>
                  ) : ("")}

                  <Collapse in={!deleteConfirmation}>
                    {!currentTendero ? (
                      <div />
                    ) : (
                      <div>
                        <div className={themeStyles["date-created"]}>
                          <b>{ts.createdAt}: &nbsp;</b> {` ${ new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentTendero.created_at))}`}
                        </div>
                        <div className={themeStyles["date-updated"]}>
                          <b>{ts.updatedAt}: &nbsp;</b> {` ${ new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentTendero.updated_at))}`}
                        </div>
                      </div>
                    )}
                    <div className={themeStyles["form-wrap"]} >

                      <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formOnSubmit} validationSchema={TenderoSchema} autoComplete="off" >
                        {({ isSubmitting, values, handleChange, handleBlur, setFieldValue, setFieldTouched, errors, touched }) => (
                          <Form>
                            <div className={resellerStyles["tendero-title"]}>
                              {ts.tenderoInformation}
                            </div>
                            <div>
                              <Grid container spacing={1} className={themeStyles["form-group"]}>
                                <Grid item xs={6}>
                                  <label htmlFor="firstName" className={themeStyles["col-form-label"]}>{t('First Name')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <Field className={themeStyles["form-control"]} name="firstName" placeholder={placeholder.firstName} value={values.firstName} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                                  {errors.firstName && touched.firstName ? (<div className={themeStyles.formError}>{errors.firstName}</div>) : ' '}
                                </Grid>
                                <Grid item xs={6}>
                                  <label htmlFor="lastName" className={themeStyles["col-form-label"]}>{t("Last Name")}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <Field className={themeStyles["form-control"]} name="lastName" placeholder={placeholder.lastName} value={values.lastName} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                                  {errors.lastName && touched.lastName ? (<div className={themeStyles.formError}>{errors.lastName}</div>) : ' '}
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} className={themeStyles["form-group"]}>
                                <Grid item xs={6}>
                                  <label htmlFor="phone" className={themeStyles["col-form-label"]}>{ts.phoneNumber}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <PhoneInputEnforcer prefix="+" masks={phoneMasks} inputClass={themeStyles["phone-input"]}
                                    onlyCountries={countryCodes && countryCodes.length > 0 ? countryCodes : defaultCountries}
                                    country={initialCountry?.toLowerCase()}
                                    value={values.phone || ""} onChange={pNumber => setFieldValue('phone', pNumber)}
                                    autoComplete="new-password" placeholder={placeholder.phone} name="phone"
                                    onBlur={() => setFieldTouched('phone', true)} disableDropdown={initialCountry} countryCodeEditable={!initialCountry}
                                  />
                                  {errors.phone && touched.phone ? (<div className={themeStyles.formError}>{errors.phone}</div>) : ' '}
                                </Grid>
                                <Grid item xs={6}>
                                  <label htmlFor="email" className={themeStyles["col-form-label"]}>{ts.email}</label>
                                  <Field className={themeStyles["form-control"]} name="email" placeholder={placeholder.email} value={values.email} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                                  {errors.email && touched.email ? (<div className={themeStyles.formError}>{errors.email}</div>) : ' '}
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} className={themeStyles["form-group"]}>
                                <Grid item xs={12}>
                                  <label htmlFor="country" className={themeStyles["col-form-label"]}>{ts.country}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <SearchSelect
                                    onChange={e => setFieldValue('country', e?.value || '')}
                                    value={values.country} onBlur={() => setFieldTouched('country', true)}
                                    options={getOptionsSelect(countryList, t)} placeholder={placeholder.country} disabled={initialCountry}
                                  />
                                  {errors.country && touched.country ? (<div className={themeStyles.formError}>{errors.country}</div>) : ' '}
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} className={themeStyles["form-group"]}>
                                <Grid item xs={6}>
                                  <label htmlFor="documentType" className={themeStyles["col-form-label"]}>{ts.documentType}</label>
                                  <SearchSelect
                                    onChange={e => e ? setFieldValue('documentType', e.value) : setFieldValue('documentType', '')}
                                    value={values.documentType} onBlur={() => setFieldTouched('documentType', true)}
                                    options={printDocumentType(t)} placeholder={placeholder.documentType} isClearable
                                  />
                                  {errors.documentType && touched.documentType ? (<div className={themeStyles.formError}>{errors.documentType}</div>) : ' '}
                                </Grid>
                                <Grid item xs={6}>
                                  <label htmlFor="document" className={themeStyles["col-form-label"]}>{ts.document}</label>
                                  <Field className={themeStyles["form-control"]} name="document" placeholder={placeholder.document} value={values.document} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                                  {errors.document && touched.document ? (<div className={themeStyles.formError}>{errors.document}</div>) : ' '}
                                </Grid>
                                <Grid item xs={6}>
                                  <label htmlFor="userReferrer" className={themeStyles["col-form-label"]}>{ts.userReferrer}</label>
                                  <Field className={themeStyles["form-control"]} name="userReferrer" placeholder={placeholder.userReferrer} value={values.userReferrer} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                                  {errors.userReferrer && touched.userReferrer ? (<div className={themeStyles.formError}>{errors.userReferrer}</div>) : ' '}
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} className={themeStyles["form-group"]}>
                                <Grid item xs={6}>
                                  <label htmlFor="sendNotification" className={themeStyles["col-form-label"]}>{ts.sendNotification}</label>
                                  <ToggleCheck value={values.sendNotification} onChange={(event) => {
                                    setFieldValue('sendNotification', event.target.checked);
                                  }} />
                                </Grid>
                              </Grid>
                            </div>

                            <div className={resellerStyles["tendero-title"]}>
                              {ts.additionalInfo}
                            </div>
                            <div>
                              {userMetaStructured && userMetaStructured?.length > 0 ? (
                                <div>
                                  {
                                    userMetaStructured.map((row, i) => (
                                      <Grid key={`${i}cont`} container spacing={1} justifyContent="flex-start" direction="row" alignItems="center">
                                        {row.map((param, i) => (
                                          <Grid key={`${i}item`} item xs={6} >
                                            <div className={themeStyles["form-group"]} style={{display: "flex", width: "100%"}}>
                                              <RenderMetaField param={param} value={values[param]} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
                                              {errors[param] && touched[param] ? (<div className={themeStyles.formError}>{errors.startAt}</div>) : ' '}
                                            </div>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    ))
                                  }
                                </div>
                              ) : ("")}
                            </div>
                            <div className={resellerStyles["tendero-title"]}>
                              {t("address-location")}
                            </div>
                            <Address name="address" country={values.country} />
                            <div className={`${themeStyles["form-group"] } ${ themeStyles["form-crud-actions"]}`}>
                              <button type="submit" className={`${themeStyles.btn } ${ themeStyles["btn-secondary"]}`} disabled={isSubmitting} onClick={() => {
                                // eslint-disable-next-line no-console
                                console.log({ errors });
                                setFieldValue('type', submitType.update);
                              }}>{
                                  isSubmitting ? `${ts.pleaseWait}...` : (!currentTendero ? ts.create : ts.update)
                                }</button>
                            </div>
                            <FormikErrorFocus offset={0} align="top" duration={1000} />
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </Collapse>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>)
  );
};

export default withTranslation()(TenderoDetails);