import PromotionActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case PromotionActions.fetchPromotionsRequest().type:
      return state.merge({
        promotionsLoading: true,
        promotionsError: null,
        promotionsLoaded: false,
      });

      case PromotionActions.fetchPromotionsSuccess().type:
        return state.merge({
          promotionsLoading: false,
          promotionsError: null,
          promotionsLoaded: true,
          promotions: action.payload.promotions.data || state.promotions,
        });

    default:
      return state;
  }
};
