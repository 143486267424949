import Immutable from 'seamless-immutable';

const initialState = Immutable({
  promotionsInit: true,
  promotionsLoading: false,
  promotionsError: null,
  promotionsLoaded: false,
  promotions: null,

  createPromotionLoading: false,
  createPromotionError: null,
  createdPromotion: false,
});


export default initialState;
