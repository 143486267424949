/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-promise-executor-return */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormikProvider, useFormik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

// import utils from '../../../utils';
import styles from './styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import ProductsActions from '../../model/actions';
import { toast } from 'react-toastify';

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const TextInputLiveFeedback = React.memo(({ label, helpText, ...props }) => {
  // const TextInputLiveFeedback = ({ label, helpText, ...props }) => {
    const [field, meta] = useField(props);
    const [didFocus, setDidFocus] = React.useState(false);
  
    const handleFocus = () => setDidFocus(true);
    const showFeedback =
      (!!didFocus && field.value.trim().length > 2) || meta.touched;
  
    return (
  
      <div
        className={`${styles['form-control-mat'] } ${ showFeedback ? (meta.error ? styles.invalid : styles.valid) : ''}`}>
      
        <div className="flex items-center space-between">
          <label htmlFor={props.id}>{label}</label>{' '}
        </div>
        <input
          {...props}
          {...field}
          aria-describedby={`${props.id}-feedback ${props.id}-help`}
          onFocus={handleFocus}
        />
            {showFeedback ? (
            <div
              id={`${props.id}-feedback`}
              aria-live="polite"
              className={styles["control-feedback"]}
            >
              {meta.error ? meta.error : null }
            </div>
          ) : null}
      </div>
    );
  });


// eslint-disable-next-line no-unused-vars
const ProductCreate = ({ onClose = () => { }, ...props }) => {

    const dispatch = useDispatch();

  const [formValid, setFormValid] = useState(false);
  const [isSubmitting, setSetSubmitting] = useState(false);

  const t = {
    addProduct: props.t('Add Product'),
    pasteMLURL: props.t('Paste Mercado Libre URL'),
    addItem: props.t('Add Item'),
    pleaseWait: props.t("Please Wait"),
    enterURLValid: props.t("Please enter a valid URL"),
    sentProduct: props.t("Sent product")
  };

  

  // Form placeholders
  // const placeholder = {
  //   url: props.t('url')
  // }

  const formikForm = useFormik({
    initialValues: { url: "" },
    onSubmit: async (values) => {
      await sleep(500);
      console.log(JSON.stringify(values, null, 2));
    },
    validationSchema: Yup.object({
      url: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?/,
          t.enterURLValid
        )
        .required(t.RequiredField),
     
    }),

  });


  const createProduct = () => {
    const form1 = formikForm.values;
    const dataFormat = {
      url: form1.url,
    };
    setSetSubmitting(true);
    dispatch(ProductsActions.createProduct(dataFormat));
    toast.success(t.sentProduct);
  };

  useEffect(() => {
    if(formikForm){
      const form1 = (formikForm.isValid && Object.keys(formikForm.touched).length > 0);
      const validated = !!form1;
      setFormValid(validated);
    }
  }, [formikForm]);


  return (
    <div>
      <Grid container spacing={0} alignItems="flex-start" >
        <Grid item xs>

          <div className={themeStyles.currentContent}>
            <div className={themeStyles["form-wrap"]}>
              <header className={themeStyles["header-form"]}>
                <h3>{ t.addProduct }</h3>
              </header>

              <div className={styles["form-wrap"]}>

                <FormikProvider value={formikForm}>
                  <Form>

                    <div className={styles["form-group"]} >
                      <label htmlFor="name" className={styles["col-form-label"]}>{t.pasteMLURL}<span className={styles["label-asterisk"]}>*</span></label>

                      <TextInputLiveFeedback
                        label={t.url}
                        id="url"
                        name="url"
                        type="text"
                      />

                    </div>

                  </Form>
                </FormikProvider>
                
              </div>
              
              <div className={styles["form-crud-actions"]}>
                <button type="submit" className={`${styles.btn } ${ styles["btn-primary"]}`} onClick={()=>createProduct()} disabled={!formValid || isSubmitting}>{isSubmitting ? `${t.pleaseWait}...` : t.addItem}</button>
              </div>

            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation()(ProductCreate);