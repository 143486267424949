import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.sass';
import stylesRoot from '../../../../theme.module.sass';
import "react-datepicker/dist/react-datepicker.css";
import Icon from "../Icon";
import DatePicker from "react-datepicker";
import { Grid, Collapse } from '@mui/material';
import SearchSelect from '../SearchSelect';
import FilterCategories from '../FilterCategories';
import MinAndMaxPriceFilter from '../MinAndMaxPriceFilter';
import { withTranslation } from 'react-i18next';
import { capitalize, tempCommissionTypes, tempPayoutStatuses, translatedSimpleOptions } from '../../utils';

const defaultValues = {
  country: null,
  fromDate: null,
  toDate: null,
  fromExpiredDate: null,
  toExpiredDate: null,
  fromInicialDate: null,
  toInicialDate: null,
  statusFromDate: null,
  statusToDate: null,
  orderStatus: null,
  referrerStatus: null,
  commissionStatus: null,
  transactionStatus: null,
  expectedDeliveryDate: null,
  type: null,
  payoutStatus: null,
  promotionVisibility: null,
  promotionType: null,
  promotionStatus: null,
  wathItem: null

};

const TopFilters = ({ t, expanded = false, statuses, statusLabels, resetFilters, country, countries, ...props }) => {

  const [countriesOptions, setCountriesOptions] = useState(null);
  // const [isOpen, setIsOpen] = useState(false); //react-datepicker ranges fix
  const [/* orderReasons */, setOrderReasons] = useState([]);
  const [orderStatusOptions, setOrderStatusOptions] = useState(null);
  const [promotionStatusOptions, setPromotionStatusOptions] = useState(null);
  const [referrerStatusOptions, setReferrerStatusOptions] = useState(null);
  const [commissionStatusOptions, setCommissionStatusOptions] = useState(null);
  const [payoutStatusOptions, setPayoutStatusOptions] = useState(null);
  const [paymentStatusOptions, setPaymentStatusOptions] = useState(null);
  const [commissionTypesOptions, setCommissionTypesOptions] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(props?.category_name || '');
  const [innerShowPrice] = useState(props?.showPrice);

  
  const addAllOption = useCallback(array => [{ 'label': t('All'), value: 'ALL' }, ...array], [t]);

  const tempPromotionStatuses = [
    {
      "key": "ENABLED",
      "label": t("Active"),
      "reasons": [],
      "comments": false
    },
    {
      "key": "DELETED",
      "label": t('Deleted'),
      "reasons": [],
      "comments": false
    },
    {
      "key": "DISABLED",
      "label": t("Stopped"),
      "reasons": [],
      "comments": false
    },
    {
      "key": "COMPLETED",
      "label": t("Completed"),
      "reasons": [],
      "comments": false
    },
  ];

  const tempPromotionTypes = [
    {
      "value": "CATALOG",
      "label": t("Catalog", { count: 100 }),
    },
    {
      "value": "NOT-CATALOG",
      "label": t('Promotion', { count: 100 }),
    }
  ];

  const tempPromotionVisibility = [
    {
      "value": "PUBLIC",
      "label": t("Public"),
    },
    {
      "value": "PRIVATE",
      "label": t('Private'),
    }
  ];

  const tempUserTypes = [
    {
      key: 1,
      value: 'admin',
      label: t('Admin')
    },
    {
      key: 2,
      value: 'referrer',
      label: t('Referrer')
    },
    {
      key: 3,
      value: 'customer',
      label: t('Customer')
    },
    {
      key: 4,
      value: 'buyer',
      label: t('Buyer')
    },
    {
      key: 5,
      value: 'agent',
      label: t('Agent')
    },
    {
      key: 6,
      value: 'financial',
      label: t('Financial')
    },
    {
      key: 7,
      value: 'reader',
      label: t('Reader')
    },
  ];

  const wathItemsOptions = [
    { label: t("Watching"), value: "true" },
    { label: t("Not watch"), value: "false" },
  ];
  
  const initialCountry = country || "";

  // TODO Upon refactor, might want to change this to useReducer to handle filters state
  const [values, setValues] = useState(defaultValues);
  
  const setValue = (key, value) => {
    const newValues = { ...values };
    newValues[key] = value;
    setValues(newValues);
  };
    
  if (!values.country && initialCountry !== values.country) {
    setValue('country', initialCountry);
  }

  const updateValues = vals => {
    setValues({ ...values, ...vals });
  };

  const commissionTypes = tempCommissionTypes;
  const payoutStatuses = tempPayoutStatuses;
  
  const resetAllFilters = () => {
    setValues(defaultValues);
    resetFilters();
  };

  useEffect(() => {
    if (countries) {
      const cOptions = [{ 'name': t('All'), code: 'ALL' }].concat(countries.data);
      const options = cOptions.map(item => ({ label: item.name, value: item.code }));
      setCountriesOptions(options);
    }
  }, [t, countries]);

  useEffect(() => {
    if (statuses?.options && statuses?.labels) {
      setReferrerStatusOptions(
        addAllOption(statuses?.options.map((status, i) => ( { 'label': statuses?.labels[i], 'value': status } )))
      );
    }
  }, [statuses, addAllOption]);

  useEffect(() => {
    if (payoutStatuses && payoutStatuses.data) {
      setPayoutStatusOptions(
        addAllOption(payoutStatuses.data.map((type) => ({ 'label': t(capitalize(type)), 'value': type })))
      );
    }
  }, [payoutStatuses, addAllOption, t]);

  useEffect(() => {
    if (commissionTypes && commissionTypes.data) {
      setCommissionTypesOptions(
        addAllOption(commissionTypes.data.map((type) => ( { 'label': t(capitalize(type)), 'value': type } )))
      );
    }
  }, [commissionTypes, addAllOption, t]);

  useEffect(() => {
    // TODO Double and hardcoded commission options?
    if (statuses && Object.keys(statuses)?.length) {
      const orderStatusesWithReasons = statuses?.order?.reduce((result, current) => {
        const newResult = { ...result };
        if (current?.reasons?.length) newResult[current.key] = current.reasons.map(reason => ({ label: reason.label, value: reason.key }));
        return newResult;
      }, {});
      setOrderReasons(orderStatusesWithReasons);
      setOrderStatusOptions(addAllOption(translatedSimpleOptions(statuses?.order, { valueKey: 'key' }, props.textColorItemSelect)));
      setPromotionStatusOptions(addAllOption(translatedSimpleOptions(statuses?.promotion || tempPromotionStatuses, { valueKey: 'key' }, props.textColorItemSelect)));
      setPaymentStatusOptions(addAllOption(translatedSimpleOptions(statuses?.transaction, { valueKey: 'key' }, props.textColorItemSelect)));
      setCommissionStatusOptions(addAllOption(translatedSimpleOptions(statuses?.commission, { valueKey: 'key' }, props.textColorItemSelect)));
    }else {
      const options = [
        {
          "label": "Todos",
          "value": null
        },
        {
          "label": "Pendiente",
          "value": "pending",
          "key": "pending",
          "reasons": [],
          "comments": false
        },
        {
          "label": "Pago",
          "value": "paid",
          "key": "paid",
          "reasons": [],
          "comments": false
        },
        {
          "label": "Cancelado",
          "value": "canceled",
          "key": "canceled",
          "reasons": [],
          "comments": false
        }
      ];
    setCommissionStatusOptions(options);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses, addAllOption]);
  
  const onCountryChange = (data) => {
    const query = {
      country: data.value,
      priceMin: null,
      priceMax: null,
    };
    setValue('country', data.value);
    props.applyFilter(query);
  };

  const onChangeFromDate = (data) => {
    const query = { dateFrom: data };
    setValue('fromDate', data);
    props.applyFilter(query);
  };
  const onChangeExpiredFromDate = (data) => {
    const query = { expiresAtFrom: data };
    setValue('fromExpiredDate', data);
    props.applyFilter(query);
  };
  const onChangeInicialFromDate = (data) => {
    const query = { startAtFrom: data };
    setValue('fromInicialDate', data);
    props.applyFilter(query);
  };
  const onChangeDeliveryDate = (data) => {
    const query = { expectedDeliveryDate: data };
    setValue('expectedDeliveryDate', data);
    props.applyFilter(query);
  };

  const onChangeToDate = (data) => {
    // let start, end;
    // if (data) [start, end] = data;
    // setValue('fromDate', start);
    // setValue('toDate', data)
    // if (end) setIsOpen(false);
    const query = { dateTo: data };
    setValue('toDate', data);
    props.applyFilter(query);
  };

  const onChangeExpiredToDate = (data) => {
    // let start, end;
    // if (data) [start, end] = data;
    // setValue('fromDate', start);
    // setValue('toDate', data)
    // if (end) setIsOpen(false);
    const query = { expiresAtTo: data };
    setValue('toExpiredDate', data);
    props.applyFilter(query);
  };
  const onChangeInicialToDate = (data) => {
    // let start, end;
    // if (data) [start, end] = data;
    // setValue('fromDate', start);
    // setValue('toDate', data)
    // if (end) setIsOpen(false);
    const query = { startAtTo: data };
    setValue('toInicialDate', data);
    props.applyFilter(query);
  };

  const onChangeDeliveryFromDate = (data) => {
    const query = { startDate: data };
    const update = { 'statusFromDate': data };
    if (!values.orderStatus) {
      update.orderStatus = 'delivered';
      query.status = 'delivered';
    }
    updateValues(update);
    props.applyFilter(query);
  };

  const onChangeDeliveryToDate = (data) => {
    const query = { endDate: data };
    const update = { 'statusToDate': data };
    if (!values.orderStatus) {
      update.orderStatus = 'delivered';
      query.status = 'delivered';
    }
    updateValues(update);
    props.applyFilter(query);
  };

  const onChangePromotionStatus = (data) => {
    const value = data.value === 'ALL' ? null : data.value;
    setValue('promotionStatus', data.value);
    const query = { status: value };
    props.applyFilter(query);
  };

  const onChangePromotionType = (data) => {
    const value = data.value === 'ALL' ? null : data.value;
    setValue('promotionType', data.value);
    const query = { type: value };
    props.applyFilter(query);
  };

  const onChangePromotionVisibility = (data) => {
    const value = data.value === 'ALL' ? null : data.value;
    setValue('promotionVisibility', data.value);
    const query = { visibility: value };
    props.applyFilter(query);
  };

  const onChangeOrderStatus = (data) => {
    const value = data.value === 'ALL' ? null : data.value;
    setValue('orderStatus', data.value);
    const query = { status: value };
    props.applyFilter(query);
  };

  const onChangeReferrerStatus = (data) => {
    const value = data.value === 'ALL' ? null : data.value;
    setValue('referrerStatus', data.value);
    const query = { metadataStatus: value };
    props.applyFilter(query);
  };

  const onChangeCommissionStatus = (data) => {
    const value = data.value === 'ALL' ? null : data.value;
    setValue('commissionStatus', data.value);
    const query = { commissionStatus: value };
    props.applyFilter(query);
  };

  const onChangeTransactionStatus = (data) => {
    const value = data.value === 'ALL' ? null : data.value;
    setValue('transactionStatus', data.value);
    const query = { transactionStatus: value };
    props.applyFilter(query);
  };

  const onChangeCommissionType = (data) => {
    const value = data.value === 'ALL' ? null : data.value;
    setValue('type', data.value);
    const query = { type: value };
    props.applyFilter(query);
  };

  const onChangePayoutStatus = (data) => {
    const value = data.value === 'ALL' ? null : data.value;
    setValue('payoutStatus', data.value);
    const query = { status: value };
    props.applyFilter(query);
  };

  const onChangeUserType = (data) => {
    const value = data.value === 'ALL' ? null : data.value;
    setValue('type', data.value);
    const query = { type: value };
    props.applyFilter(query);
  };

  const onChangeWatches = (data) => {
    const {value} = data;
    const query = {
      isWatched: value,
    };
    setCurrentCategory(data);
    props.applyFilter(query);
  };

  const onChangeCategory = (data) => {
    const {value} = data;
    const query = {
      categories: value,
    };
    setCurrentCategory(data);
    props.applyFilter(query);
  };

  const deliveryFilter = <div>
    <div>
      <label htmlFor="deliveryDateFilter" className={styles["common-label"]}>{t("Delivery date")}</label>
    </div>
    <div className={styles["filter-wrapper"]}>
      <DatePicker
        aria-label="deliveryDateFilter"
        // isClearable
        className={styles["date-input"]}
        placeholderText={t("simpleDateFormat")}
        selected={values.expectedDeliveryDate}
        onChange={(date) => { onChangeDeliveryDate(date); }}
      />
      <i className={styles["filter-input-icon"]}>
        <Icon color="#CAC6CE" size={22} icon="calendar-1" />
      </i>
    </div>
  </div>;

  const createdFromFilter = <div>
    <div>
      <label htmlFor="createdFromFilter" className={styles["common-label"]}>{t("Created From")}</label>
    </div>
    <div className={styles["filter-wrapper"]}>
      <DatePicker
        aria-label="createdFromFilter"
        // isClearable
        className={styles["date-input"]}
        placeholderText={t("simpleDateFormat")}
        selected={values.fromDate}
        onChange={(date) => { onChangeFromDate(date); }}
      />
      <i className={styles["filter-input-icon"]}>
        <Icon color="#CAC6CE" size={22} icon="calendar-1" />
      </i>
    </div>
  </div>;

  const createdToFilter = <div>
    <div>
      <label htmlFor="createdToFilter" className={styles["common-label"]}>{t("Created To")}</label>
    </div>
    <div className={styles["filter-wrapper"]}>
      <DatePicker
        aria-label="createdToFilter"
        // isClearable
        className={styles["date-input"]}
        // selectsRange
        // onInputClick={() => setIsOpen(true)}
        // onClickOutside={() => setIsOpen(false)}
        placeholderText={t("simpleDateFormat")}
        selected={values.toDate}
        onChange={(date) => { onChangeToDate(date); }}
        // startDate={values.fromDate}
        // endDate={values.toDate}
      />
      <i className={styles["filter-input-icon"]}>
        <Icon color="#CAC6CE" size={22} icon="calendar-1" />
      </i>
    </div>
  </div>;

  const createdExpiresFromFilter = <div>
    <div>
      <label htmlFor="expiredFromFilter" className={styles["common-label"]}>{t("Expired From")}</label>
    </div>
    <div className={styles["filter-wrapper"]}>
      <DatePicker
        aria-label="expiredFromFilter"
        // isClearable
        className={styles["date-input"]}
        // selectsRange
        // onInputClick={() => setIsOpen(true)}
        // onClickOutside={() => setIsOpen(false)}
        placeholderText={t("simpleDateFormat")}
        selected={values.fromExpiredDate}
        onChange={(date) => { onChangeExpiredFromDate(date); }}
      // startDate={values.fromDate}
      // endDate={values.toDate}
      />
      <i className={styles["filter-input-icon"]}>
        <Icon color="#CAC6CE" size={22} icon="calendar-1" />
      </i>
    </div>
  </div>;

  const createdExpiresToFilter = <div>
    <div>
      <label htmlFor="expiredToFilter" className={styles["common-label"]}>{t("Expired To")}</label>
    </div>
    <div className={styles["filter-wrapper"]}>
      <DatePicker
        ari
        // isClearable
        className={styles["date-input"]}
        // selectsRange
        // onInputClick={() => setIsOpen(true)}
        // onClickOutside={() => setIsOpen(false)}
        placeholderText={t("simpleDateFormat")}
        selected={values.toExpiredDate}
        onChange={(date) => { onChangeExpiredToDate(date); }}
      // startDate={values.fromDate}
      // endDate={values.toDate}
      />
      <i className={styles["filter-input-icon"]}>
        <Icon color="#CAC6CE" size={22} icon="calendar-1" />
      </i>
    </div>
  </div>;

const createdInicialFromFilter = <div>
<div>
  <label htmlFor="startFromFilter" className={styles["common-label"]}>{t("Start From")}</label>
</div>
<div className={styles["filter-wrapper"]}>
    <DatePicker
      aria-label="startFromFilter"
    // isClearable
    className={styles["date-input"]}
    // selectsRange
    // onInputClick={() => setIsOpen(true)}
    // onClickOutside={() => setIsOpen(false)}
    placeholderText={t("simpleDateFormat")}
    selected={values.fromInicialDate}
    onChange={(date) => { onChangeInicialFromDate(date); }}
    // startDate={values.fromDate}
    // endDate={values.toDate}
  />
  <i className={styles["filter-input-icon"]}>
    <Icon color="#CAC6CE" size={22} icon="calendar-1" />
  </i>
</div>
</div>;

const createdInicialToFilter = <div>
    <div>
    <label htmlFor="startToFilter" className={styles["common-label"]}>{t("Start To")}</label>
    </div>
    <div className={styles["filter-wrapper"]}>
    <DatePicker
      aria-label="startToFilter"
        // isClearable
        className={styles["date-input"]}
        // selectsRange
        // onInputClick={() => setIsOpen(true)}
        // onClickOutside={() => setIsOpen(false)}
        placeholderText={t("simpleDateFormat")}
        selected={values.toInicialDate}
        onChange={(date) => { onChangeInicialToDate(date); }}
        // startDate={values.fromDate}
        // endDate={values.toDate}
      />
      <i className={styles["filter-input-icon"]}>
        <Icon color="#CAC6CE" size={22} icon="calendar-1" />
      </i>
    </div>
  </div>;
  const referrerStatusFilter = <div>
    <div>
      <label htmlFor="statusFilter" className={styles["common-label"]}>{t("Status")}</label>
    </div>
    <div>
      <SearchSelect aria-label="statusFilter" placeholder={t("Status")} value={values.referrerStatus} options={referrerStatusOptions} onChange={onChangeReferrerStatus} onBlur={() => { }} />
    </div>
  </div>;

  const orderCommisionStatusFilter = <div>
    <div>
      <label htmlFor="commissionStatusFilter" className={styles["common-label"]}>{t("Commission Status")}</label>
    </div>
    <div>
      <SearchSelect aria-label="commissionStatusFilter" placeholder={t("Status")} value={values.commissionStatus} options={commissionStatusOptions} onChange={onChangeCommissionStatus} onBlur={() => { }} />
    </div>
  </div>;

  const payoutStatusFilter = <div>
    <div>
      <label htmlFor="payoutStatusFilter" className={styles["common-label"]}>{t("Payout Status")}</label>
    </div>
    <div>
      <SearchSelect aria-label="payoutStatusFilter" placeholder={t("Status")} value={values.payoutStatus} options={payoutStatusOptions} onChange={onChangePayoutStatus} onBlur={() => { }} />
    </div>
  </div>;

  const orderPaymentStatusFilter = <div>
    <div>
      <label htmlFor="paymentStatusFilter" className={styles["common-label"]}>{t("Payment Status")}</label>
    </div>
    <div>
      <SearchSelect aria-label="paymentStatusFilter" placeholder={t("Status")} value={values.transactionStatus} options={paymentStatusOptions} onChange={onChangeTransactionStatus} onBlur={() => { }} />
    </div>
  </div>;

  const commissionTypeFilter = <div>
    <div>
      <label htmlFor="typeFilter" className={styles["common-label"]}>{t("Type")}</label>
    </div>
    <div>
      <SearchSelect aria-label="typeFilter" placeholder={t("Type")} value={values.type} options={commissionTypesOptions} onChange={onChangeCommissionType} onBlur={() => { }} />
    </div>
  </div>;

  const orderStatusFilter = <div>
    <div>
      <label htmlFor="orderStatusFilter" className={styles["common-label"]}>{t("Order Status")}</label>
    </div>
    <div>
      <SearchSelect aria-label="orderStatusFilter" highlightGroups placeholder={t("Status")} value={values.orderStatus} options={orderStatusOptions} onChange={onChangeOrderStatus} onBlur={() => { }} />
    </div>
  </div>;


  const orderStatusFromFilter = <div>
    <div>
      <label htmlFor="statusFromFilter" className={styles["common-label"]}>{t("statusFrom", { status: values.orderStatus ? statusLabels?.order?.[values.orderStatus] : 'Status' })}</label>
    </div>
    <div>
      <DatePicker aria-label="statusFromFilter" placeholderText={t("simpleDateFormat")} selected={values.statusFromDate} onChange={date => onChangeDeliveryFromDate(date)} />
    </div>
  </div>;

  // const orderDeliveryDateFilter = <div>
  //   <div>
  //     <label className={styles["common-label"]}>{t("Delivery date")}</label>
  //   </div>
  //   <div>
  //     <DatePicker placeholderText={t("simpleDateFormat")} selected={values.expectedDeliveryDate} onChange={date => onChangeDeliveryDate(date)} />
  //   </div>
  // </div>;

  const orderStatusToFilter = <div>
    <div>
      <label htmlFor="statusToFilter" className={styles["common-label"]}>{t("statusTo", { status: values.orderStatus ? statusLabels?.order?.[values.orderStatus] : 'Status' })}</label>
    </div>
    <div>
      <DatePicker aria-label="statusToFilter" placeholderText={t("simpleDateFormat")} selected={values.statusToDate} onChange={date => onChangeDeliveryToDate(date)} />
    </div>
  </div>;

  const promotionStatusFilter = <div>
    <div>
      <label htmlFor="promotionStatusFilter" className={styles["common-label"]}>{t("Status")}</label>
    </div>
    <div>
      <SearchSelect aria-label="promotionStatusFilter" highlightGroups placeholder={t("Status")} value={values.promotionStatus} options={promotionStatusOptions} onChange={onChangePromotionStatus} onBlur={() => { }} />
    </div>
  </div>;

    const promotionTypeFilter = <div>
      <div>
        <label htmlFor="promotionTypeFilter" className={styles["common-label"]}>{t("Type")}</label>
      </div>
      <div>
        <SearchSelect aria-label="promotionTypeFilter" highlightGroups placeholder={t("Type")} value={values.promotionType} options={addAllOption(tempPromotionTypes)} onChange={onChangePromotionType} onBlur={() => { }} />
      </div>
    </div>;

    const promotionVisibilityFilter = <div>
      <div>
        <label htmlFor="promotionVisibilityFilter" className={styles["common-label"]}>{t("Visibility")}</label>
      </div>
      <div>
        <SearchSelect aria-label="promotionVisibilityFilter" highlightGroups placeholder={t("Visibility")} value={values.promotionVisibility} options={addAllOption(tempPromotionVisibility)} onChange={onChangePromotionVisibility} onBlur={() => { }} />
      </div>
    </div>;

    const userTypeFilter = <div>
      <div>
        <label htmlFor="promotionTypeFilter" className={styles["common-label"]}>{t("Type")}</label>
      </div>
      <div>
        <SearchSelect aria-label="promotionTypeFilter" highlightGroups placeholder={t("Type")} value={values.type} options={addAllOption(tempUserTypes)} onChange={onChangeUserType} onBlur={() => { }} />
      </div>
    </div>;

    const watchItemsFilter = <div>
      <div>
        <label htmlFor="watchItemsFilter" className={styles["common-label"]}>{t("Watch Items")}</label>
      </div>
      <div>
        <SearchSelect aria-label="watchItemsFilter" placeholder={t("Watch Items")} value={values.wathItem} options={addAllOption(wathItemsOptions)} onChange={onChangeWatches} onBlur={() => { }} />
      </div>
    </div>;

    const categoriesFilter = <div>
    <div>
      <label htmlFor="categoriesFilter" className={styles["common-label"]}>{t("Categories")}</label>
    </div>
    <div>
      <FilterCategories
          className='optionListContainer'
          onChange={onChangeCategory}
          value={currentCategory}
          onBlur={() => {}}
          options={[]}
          placeholder={t("All")}
          t={t}
        />
      </div>
    </div>;

    const minAndMaxPriceFilter = <div>
      {(innerShowPrice) ? <MinAndMaxPriceFilter t={t} applyFilter={props.applyFilter} /> : null }
    </div>;

  return (
    <div>
      <Collapse in={expanded}>
        {/* <section className="box form-wrap"> */}
        <section className={`${stylesRoot.box} ${stylesRoot["form-wrap"]}`}>
          <section>
            <Grid container spacing={3}>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={12} container direction="column" alignContent="flex-end" alignItems="flex-end">
                  <Grid item xs={12}>
                    <div className={styles["reset-filters-button"]} onClick={resetAllFilters}>{t("Reset all filters")}</div>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <div>
                    <label htmlFor="countryFilter" className={styles["common-label"]}>{t("Country")}
                      {countriesOptions ? <SearchSelect aria-label="countryFilter" id="countryFilter" value={values.country} placeholder={t("Country")} options={countriesOptions} onChange={onCountryChange} onBlur={() => { }} /> : null}
                    </label>
                  </div>
                </Grid>
                {(props.module === 'Deal' || props.module === 'Referrer' || props.module === 'Customer' || props.module === 'User' || props.module === 'Commission' || props.module === 'Payout'|| props.module === 'Product') ?
                  <Grid item xs={2}>
                    {createdFromFilter}
                  </Grid>
                  : ""}
                {(props.module === 'Deal' || props.module === 'Referrer' || props.module === 'Customer' || props.module === 'User' || props.module === 'Commission' || props.module === 'Payout' || props.module === 'Product') ?
                  <Grid item xs={2}>
                    {createdToFilter}
                  </Grid>
                  : ""}
                {(props.module === 'Deal' || props.module === 'Referrer' || props.module === 'Customer' || props.module === 'User' || props.module === 'Commission' || props.module === 'Payout' || props.module === 'Product') ?
                <Grid item xs={2}>
                  {createdExpiresFromFilter}
                </Grid>
                : ""}
                 {(props.module === 'Deal' || props.module === 'Referrer' || props.module === 'Customer' || props.module === 'User' || props.module === 'Commission' || props.module === 'Payout' || props.module === 'Product') ?
                <Grid item xs={2}>
                  {createdExpiresToFilter}
                </Grid>
                : ""}
                   {(props.module === 'Deal' || props.module === 'Referrer' || props.module === 'Customer' || props.module === 'User' || props.module === 'Commission' || props.module === 'Payout' || props.module === 'Product') ?
                <Grid item xs={2}>
                  {createdInicialFromFilter}
                </Grid>
                : ""}
                 {(props.module === 'Deal' || props.module === 'Referrer' || props.module === 'Customer' || props.module === 'User' || props.module === 'Commission' || props.module === 'Payout'|| props.module === 'Product') ?
                <Grid item xs={2}>
                  {createdInicialToFilter}
                </Grid>
                : ""}
                {(props.module === 'Referrer' || props.module === 'User') ?
                  <Grid item xs={2}>
                    {referrerStatusFilter}
                  </Grid>
                  : ""}
                {(props.module === 'User') ?
                  <Grid item xs={2}>
                    {userTypeFilter}
                  </Grid>
                  : ""}
                {(props.module === 'Order') ?
                  <Grid item xs={2}>
                    {orderStatusFilter}
                  </Grid>
                  : ""}
                {(props.module === 'Order') ?
                  <Grid item xs={2}>
                    {orderStatusFromFilter}
                  </Grid>
                  : ""}
                {(props.module === 'Promotion') ?
                  <Grid item xs={2}>
                    {promotionStatusFilter}
                  </Grid>
                  : ""}
                {(props.module === 'Promotion') ?
                  <Grid item xs={2}>
                    {promotionTypeFilter}
                  </Grid>
                  : ""}
                {(props.module === 'Promotion') ?
                  <Grid item xs={2}>
                    {promotionVisibilityFilter}
                  </Grid>
                  : ""}
                {(props.module === 'Order') ?
                  <Grid item xs={2}>
                    {orderStatusToFilter}
                  </Grid>
                  : ""}
                {(props.module === 'Product') ?
                  <Grid item xs={4}>
                    {minAndMaxPriceFilter}
                  </Grid>
                  : ""}
                {(props.module === 'Product') ?
                  <Grid item xs={2}>
                    {categoriesFilter}
                  </Grid>
                  : ""}
                {(props.module === 'Product') ?
                  <Grid item xs={2}>
                    {watchItemsFilter}
                  </Grid>
                  : ""}
              </Grid>
              {props.module === 'Order' || props.module === 'Commission' || props.module === 'Payout' ?
                <Grid item xs={12} container spacing={3}>
                  {(props.module === 'Order') ?
                    <Grid item xs={2}>
                      {createdFromFilter}
                    </Grid>
                    : ""}
                  {(props.module === 'Order') ?
                    <Grid item xs={2}>
                      {createdToFilter}
                    </Grid>
                    : ""}
                     {(props.module === 'Deal') ?
                    <Grid item xs={2}>
                      {createdExpiresFromFilter}
                    </Grid>
                    : ""}
                       {(props.module === 'Deal') ?
                    <Grid item xs={2}>
                      {createdExpiresToFilter}
                    </Grid>
                    : ""}
                          {(props.module === 'Deal') ?
                    <Grid item xs={2}>
                      {createdInicialFromFilter}
                    </Grid>
                    : ""}
                       {(props.module === 'Deal') ?
                    <Grid item xs={2}>
                      {createdInicialToFilter}
                    </Grid>
                    : ""}
                  {(props.module === 'Order' || props.module === 'Commission') ?
                    <Grid item xs={2}>
                      {orderCommisionStatusFilter}
                    </Grid>
                    : ""}
                  {(props.module === 'Order') ?
                    <Grid item xs={2}>
                      {orderPaymentStatusFilter}
                    </Grid>
                    : ""}

                  {(props.module === 'Commission') ?
                    <Grid item xs={2}>
                      {commissionTypeFilter}
                    </Grid>
                    : ""}
                  {(props.module === 'Payout') ?
                    <Grid item xs={2}>
                      {payoutStatusFilter}
                    </Grid>
                    : ""}
                    {(props.module === 'Order') ?
                    <Grid item xs={2}>
                      {deliveryFilter}
                    </Grid>
                    : ""}
                </Grid>
                : ""}
                {/* {props.module === 'Order' ?
                <Grid item xs={12} container spacing={3}>
                  {(props.module === 'Order') ?
                    <Grid item xs={2}>
                      {deliveryFilter}
                    </Grid>
                    : ""}
                </Grid>
                : ""} */}
            </Grid>
          </section>
        </section>
      </Collapse>
    </div>
  );
};

export default withTranslation()(TopFilters);