import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/styles';
import theme from '../theme';

import styles from './styles.module.sass';

const Progress = ({ size = 40, color = 'primary' }) => (
    <div className={ styles.container_loader}>
      <ThemeProvider theme={theme}>
        <CircularProgress size={ size } color={color} />
      </ThemeProvider>
    </div>
  );

export default Progress;
