/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-await */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { CircularProgress, Collapse, Grid } from '@mui/material';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import FormikErrorFocus from 'formik-error-focus';
import { actions, callAssignToast, countryPhone, crudAction, formatDateTime, formCountryLabel, getDatei18n, modules, phoneMasks } from '../../../utils';
import PhoneInputEnforcer from '../../../core/PhoneInputEnforcer';
import resellerStyles from './styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import SearchSelect from '../../../core/SearchSelect';
import { getCountry, getCountries } from '../../../core/services/workflow';
import { getUserMeta, postUsers, getUser, updateUser, softDeleteUser } from '../../services/users';
import ModalCloseButton from '../../../core/ModalCloseButton';

const dateFormat = {
  year: 'numeric', month: 'short', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  hour12: false,
  timeZoneName: 'short'
};

const printDocumentType = (t) => {
  const typesArray = [
    { label: t('ID'), value: 'national', key: "1" },
    { label: t('Passport'), value: 'passport', key: "2" }
  ];
  return typesArray;
};

const UserDetails = ({ t, onClose = () => { }, ...props }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userMeta, setUserMeta] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loaderEnabled, setLoaderEnabled] = useState(true);

  const module = modules(t).user;

  const defaultCountries = ["us", "co", "gt", "ar", "ec"];

  // TODO Remove translation file or move it to a separate file
  const ts = {
    users: t('Users'),
    userType: t("User type"),
    createUser: t('Create User'),
    createdAt: t('Created at'),
    updatedAt: t('Updated at'),
    name: t('Name'),
    tags: t('Tags'),
    description: t('Description'),
    phoneNumber: t('Phone Number'),
    email: t('Email'),
    documentType: t('Document Type'),
    document: t('Document'),
    country: t('Country'),
    countries: t('Countries'),
    region: t('Region'),
    city: t('City'),
    postalCode: t('Postal Code'),
    pleaseWait: t('Please Wait'),
    create: t('Create'),
    update: t('Update'),
    delete: t('Delete'),
    userInformation: t('User Information'),
    address: t('Address'),
    additionalInfo: t('Additional Information'),
    birthDate: t('birthDate'),
    gender: t('gender'),
    status: t('status'),
    networkSize: t('networkSize'),
    commerceType: t('commerceType'),
    marketingType: t('marketingType'),
    yes: t('Yes'),
    no: t('No'),
    required: t('Required'),
    invalidEmail: t("Invalid email")
  };

  const Userschema = Yup.object().shape({
    firstName: Yup.string().required(ts.required),
    lastName: Yup.string().required(ts.required),
    country: Yup.string().required(ts.required),
    countries: Yup.array().when('type', {
      is: type => type === "admin" || type === "superAdmin",
      then: Yup.array().required(ts.required),
      otherwise: Yup.array()
    }),
    phone: Yup.string().when('type', {
      is: type => type === "referrer" || type === "customer",
      then: Yup.string().min(8, ts.required).required(ts.required),
      otherwise: Yup.string().nullable()
    }),
    email: Yup.string().email(ts.invalidEmail).required(ts.required),
    type: Yup.string().required(ts.required).nullable(),
    // address: AddressSchema.default(undefined),
    documentType: Yup.string().when('document', {
      is: document => !document,
      then: Yup.string(),
      otherwise: Yup.string().required(ts.required)
    }),
    document: Yup.string().when('documentType', {
      is: documentType => !documentType,
      then: Yup.string(),
      otherwise: Yup.string().required(ts.required)
    })
  }, [['document', 'documentType', 'type']]);

  const crudOptions = useMemo(() => ({
    module,
    name: currentUser?.firstName ? (`${currentUser?.firstName} ${currentUser?.lastName}`) : '',
  }), [currentUser?.firstName, currentUser?.lastName, module]);

  const getOptionsSelect = (data) => {
    if (!data) return;
    return data.map((item, index) => ({
      key: index,
      value: item.code,
      label: item.name
    }));
  };

  const formatCountriesArray = (countries) => (countries.map(country => ({ label: formCountryLabel(country, countryList), value: country, ...country })));


  /**
   * Fetches Users from the API, sets the Users list with them and reports any errors via Toasts
   * @async
   */
  const loadUserMeta = async () => await callAssignToast(getUserMeta, setUserMeta, 'list User Metadata');

  /**
   * @async
   * Fetches Countries from the API, sets the country list with them and reports any errors via Toasts
   */
  const loadCountries = async () => await callAssignToast(getCountries, setCountryList, 'list Countries');

  /**
 * Passed to the list to execute on Item click, updates the form with the selected User
 * @async
 * @param {string} id
 */
  const fetchUserByObject = async (object) => await callAssignToast(getUser.bind(null, object.id), async (user) => {
    if (user.metadata) {
      Object.keys(userMeta).forEach(param => {
        let value = user.metadata[param];
        if (!value) return;
        if (userMeta[param].type === 'date' || userMeta[param].type === 'datetime') {
          if (value && typeof value !== 'object' || !value.toISOString) value = new Date(value);
        }
        user[param] = value;
      });
    }
    if (user?.countries) { user.countries = formatCountriesArray(user?.countries); }

    setCurrentUser(user);
  }, 'load Reseller');

  // On load, get Countries and Users
  useEffect(() => {
    const initLoad = async () => {
      await Promise.all([loadCountries(), loadUserMeta()]);
      setReadyToLoad(true);
    };
    initLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On Countrylist update, update Countrycodes
  useEffect(() => {
    const currentUpdate = async () => {
      setCountryCodes(countryList.map(country => (country.code).toLowerCase()));
    };
    if (countryList) currentUpdate();
  }, [countryList]);

  useEffect(() => {
    const afterLoad = async () => {
      if (props.object) await fetchUserByObject(props.object);
      setLoaderEnabled(false);
    };
    if (readyToLoad) afterLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToLoad]);

  const placeholder = {
    firstName: t("Enter your first name"),
    lastName: t("Enter your last name"),
    phone: "+1 (702) 123-4567",
    email: "email@user.com",
    country: t("Enter your country"),
    countries: t("Enter countries for admin"),
    userType: t("User type"),
    documentType: ` -- ${t("Select a Type")} -- `,
    document: t("Enter your document"),
    gender: t("Select a gender"),
    status: t("Select a status"),
    networkSize: t("Specify your Network Size"),
    commerceType: t("Select the Commerce Type"),
    marketingType: t("Select the Marketing Type"),
    ageRange: t("Select an Age Range"),
    onboardedDate: t("Select Onboarding Date"),
    activatedDate: t("Select Activation Date"),
    sellCategory: t("sellCategoryPlaceholder"),
    businessPhone: t("isBusinessPhone")
  };

  // Form type, used to differentiate between update and delete
  const submitType = {
    update: 1,
    delete: 2
  };



  const headerCountry = getCountry();
  const initialCountry = currentUser?.country || headerCountry || "";
  const initialCountryLowerCase = initialCountry.toLowerCase();
  const defaultPhonePrefix = (`+${countryPhone[initialCountryLowerCase].code}${countryPhone[initialCountryLowerCase].enforce ? countryPhone[initialCountryLowerCase].enforce : ""}`);

  const datei18n = getDatei18n(initialCountry);

  // Formik initial/default values
  const formInitialValues = {
    firstName: currentUser?.firstName || "",
    lastName: currentUser?.lastName || "",
    phone: currentUser?.phone || defaultPhonePrefix,
    email: currentUser?.email || "",
    country: initialCountry,
    countries: currentUser?.countries || [],
    type: currentUser?.type || null, // update
    document: currentUser?.document || "",
    documentType: currentUser?.documentType || "",
    typeForm: submitType.update // update,
  };

  if (userMeta) {
    Object.keys(userMeta).forEach(param => {
      let defaultValue = '';
      if (userMeta[param].type === 'date' || userMeta[param].type === 'datetime') {
        defaultValue = null; // ageOfMayority
      }
      if (userMeta[param].default) {
        defaultValue = userMeta[param].default;
      }
      formInitialValues[param] = (currentUser && currentUser[param]) ? currentUser[param] : defaultValue;
    });
  };

  const countriesTypesArray = useMemo(() => (['admin', 'agent', 'financial', 'reader']), []);


  const validateTypeUser = (typesArray, userType) => {
    const validate = typesArray?.some((type) => type === userType);
    return validate;
  };

  const indicatePhoneUsersArray = useMemo(() => ([
    { indicatePhone: '+57', country: 'CO' },
    { indicatePhone: '+549', country: 'AR' },
    { indicatePhone: '+52', country: 'MX' }
  ]), []);

  const isPhoneEmpty = (indicatePhoneArray, userPhone, country) => {
    const isEmpty = indicatePhoneArray?.some((indicate) => indicate?.indicatePhone === userPhone && indicate?.country === country);
    return isEmpty;
  };

  /**
   * Function called whenever the Formik form is submitted
   * @async
   * @param {Object} values Form values
   * @param {Object} options Functions provided by the Formik form
   */
  const formOnSubmit = useCallback(async (values) => {
    const isUpdate = !(!currentUser);

    const newAddress = {
      address1: values.address?.address1,
      country: values.country,
      city: values.address?.city
    };

    if (isUpdate || values?.address?.address2) newAddress.address1 = values.address?.address2 || null;
    if (isUpdate || values?.address?.region) newAddress.region = values.address?.region || null;
    if (isUpdate || values?.address?.description) newAddress.description = values.address?.description || null;
    if (isUpdate || values?.address?.zipCode) newAddress.zipCode = values.address?.zipCode || null;

    const newUser = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      country: values.country,
      type: values.type,
      role: values.type,
      countries: values?.countries?.map((country) => country.value),
      metadata: {},
      phone: values.phone[0] !== '+' ? `+${values.phone}` : values.phone
    };

    if (!validateTypeUser(countriesTypesArray, values.type)) Reflect.deleteProperty(newUser, 'countries');
    if (isPhoneEmpty(indicatePhoneUsersArray, newUser?.phone, values?.country)) Reflect.deleteProperty(newUser, 'phone');


    Object.keys(userMeta).forEach(param => {
      let value = values[param];
      if (value) {
        // TODO change once the type is updated to date instead of datetime
        if (userMeta[param].type === 'datetime' || userMeta[param].type === 'date') {
          value = formatDateTime(value);
        }
        if (value !== '' && value !== "[]" && userMeta[param].editable) newUser.metadata[param] = value;
      }
    });

    if (!currentUser) { // CREATE
      await crudAction(postUsers.bind(null, newUser), onClose,
        { ...crudOptions, name: values.name, action: actions.CREATE }, t
      );
    } else if (values?.typeForm === submitType.update) { // UPDATE
      await crudAction(updateUser.bind(null, currentUser.id, newUser), onClose,
        { ...crudOptions, action: actions.UPDATE }, t
      );
    }
  }, [countriesTypesArray, crudOptions, currentUser, indicatePhoneUsersArray, onClose, submitType.update, t, userMeta]);

  const userTypeOptions = [
    {
      key: 1,
      value: 'admin',
      label: t('Admin')
    },
    {
      key: 2,
      value: 'referrer',
      label: t('Referrer')
    },
    {
      key: 3,
      value: 'customer',
      label: t('Customer')
    },
    {
      key: 4,
      value: 'buyer',
      label: t('Buyer')
    },
    {
      key: 5,
      value: 'agent',
      label: t('Agent')
    },
    {
      key: 6,
      value: 'financial',
      label: t('Financial')
    },
    {
      key: 7,
      value: 'reader',
      label: t('Reader')
    },
  ];

  return (
    (
      loaderEnabled ? <div style={{ display: 'flex', justifyContent: 'center', padding: '200px' }}>
        <CircularProgress />
      </div>
        :
        <div>
          <ModalCloseButton onClick={() => onClose(true)} floating />
          <Grid container spacing={0} alignItems="flex-start" style={{ marginTop: "-30px" }}>
            <Grid item xs>
              <div className={themeStyles.currentContent}>
                <div className={resellerStyles["user-view"]}>
                  <header className={themeStyles["header-form"]}>
                    <h3>{!currentUser ? ts.createUser : `${currentUser.firstName} ${currentUser.lastName}`}</h3>
                  </header>
                  {deleteConfirmation ? (
                    <Grid container className={resellerStyles["delete-section"]}>
                      <Grid item xs={12} className={resellerStyles["delete-description"]}>
                        {t('deleteConfirmMessage', { name: `${currentUser.firstName} ${currentUser.lastName}` })}
                      </Grid>
                      <Grid item xs={12} className={`${themeStyles["form-group"]} ${resellerStyles["delete-buttons"]}`}>
                        <button type="button" className={`${themeStyles.btn} ${themeStyles["btn-transparent"]}`} disabled={deleting} onClick={(e) => {
                          e.preventDefault();
                          setDeleteConfirmation(false);
                        }}>{t('Cancel')}</button>
                        <button type="button" className={`${themeStyles.btn} ${themeStyles["btn-danger"]}`} disabled={deleting} onClick={
                          async (e) => {
                            e.preventDefault();
                            setDeleting(true);
                            // DELETE
                            await crudAction(softDeleteUser.bind(null, currentUser.id), onClose,
                              { ...crudOptions, action: actions.DELETE }, t
                            );
                          }
                        }>{ts.delete}</button>
                      </Grid>
                    </Grid>
                  ) : ("")}

                  <Collapse in={!deleteConfirmation}>
                    {!currentUser ? (
                      <div />
                    ) : (
                      <div>
                        <div className={themeStyles["date-created"]}>
                          <b>{ts.createdAt}: &nbsp;</b> {` ${new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentUser.created_at))}`}
                        </div>
                        <div className={themeStyles["date-updated"]}>
                          <b>{ts.updatedAt}: &nbsp;</b> {` ${new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentUser.updated_at))}`}
                        </div>
                      </div>
                    )}
                    <div className={themeStyles["form-wrap"]} >

                      <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formOnSubmit} validationSchema={Userschema} autoComplete="off" >
                        {({ isSubmitting, values, handleChange, handleBlur, setFieldValue, setFieldTouched, errors, touched }) => (
                          <Form>
                            <div className={resellerStyles["user-title"]}>
                              {ts.userInformation}
                            </div>
                            <div>
                              <Grid container spacing={1} className={themeStyles["form-group"]}>
                                <Grid item xs={6}>
                                  <label htmlFor="firstName" className={themeStyles["col-form-label"]}>{t('First Name')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <Field className={themeStyles["form-control"]} name="firstName" placeholder={placeholder.firstName} value={values.firstName} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                                  {errors.firstName && touched.firstName ? (<div className={themeStyles.formError}>{errors.firstName}</div>) : ' '}
                                </Grid>
                                <Grid item xs={6}>
                                  <label htmlFor="lastName" className={themeStyles["col-form-label"]}>{t("Last Name")}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <Field className={themeStyles["form-control"]} name="lastName" placeholder={placeholder.lastName} value={values.lastName} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                                  {errors.lastName && touched.lastName ? (<div className={themeStyles.formError}>{errors.lastName}</div>) : ' '}
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} className={themeStyles["form-group"]}>
                                <Grid item xs={6}>
                                  <label htmlFor="phone" className={themeStyles["col-form-label"]}>{ts.phoneNumber}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <PhoneInputEnforcer prefix="+" masks={phoneMasks} inputClass={themeStyles["phone-input"]}
                                    onlyCountries={countryCodes && countryCodes.length > 0 ? countryCodes : defaultCountries}
                                    country={initialCountry?.toLowerCase()}
                                    value={values.phone || ""} onChange={pNumber => setFieldValue('phone', pNumber)}
                                    autoComplete="new-password" placeholder={placeholder.phone} name="phone"
                                    onBlur={() => setFieldTouched('phone', true)} disableDropdown={initialCountry} countryCodeEditable={!initialCountry}
                                  />
                                  {errors.phone && touched.phone ? (<div className={themeStyles.formError}>{errors.phone}</div>) : ' '}
                                </Grid>
                                <Grid item xs={6}>
                                  <label htmlFor="email" className={themeStyles["col-form-label"]}>{ts.email}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <Field className={themeStyles["form-control"]} name="email" placeholder={placeholder.email} value={values.email} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                                  {errors.email && touched.email ? (<div className={themeStyles.formError}>{errors.email}</div>) : ' '}
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} className={themeStyles["form-group"]}>
                                <Grid item xs={12}>
                                  <label htmlFor="country" className={themeStyles["col-form-label"]}>{ts.country}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <SearchSelect
                                    onChange={e => setFieldValue('country', e?.value || '')}
                                    value={values.country} onBlur={() => setFieldTouched('country', true)}
                                    options={getOptionsSelect(countryList, t)} placeholder={placeholder.country} disabled={initialCountry}
                                  />
                                  {errors.country && touched.country ? (<div className={themeStyles.formError}>{errors.country}</div>) : ' '}
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} className={themeStyles["form-group"]}>
                                <Grid item xs={6}>
                                  <label htmlFor="documentType" className={themeStyles["col-form-label"]}>{ts.documentType}</label>
                                  <SearchSelect
                                    onChange={e => e ? setFieldValue('documentType', e.value) : setFieldValue('documentType', '')}
                                    value={values.documentType} onBlur={() => setFieldTouched('documentType', true)}
                                    options={printDocumentType(t)} placeholder={placeholder.documentType} isClearable
                                  />
                                  {errors.documentType && touched.documentType ? (<div className={themeStyles.formError}>{errors.documentType}</div>) : ' '}
                                </Grid>
                                <Grid item xs={6}>
                                  <label htmlFor="document" className={themeStyles["col-form-label"]}>{ts.document}</label>
                                  <Field className={themeStyles["form-control"]} name="document" placeholder={placeholder.document} value={values.document} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                                  {errors.document && touched.document ? (<div className={themeStyles.formError}>{errors.document}</div>) : ' '}
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} className={themeStyles["form-group"]}>
                                <Grid item xs={validateTypeUser(countriesTypesArray, values.type) ? 6 : 12}>
                                  <label htmlFor="type" className={themeStyles["col-form-label"]}>{ts.userType}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <SearchSelect
                                    onChange={e => setFieldValue('type', e?.value || '')}
                                    value={values.type} onBlur={() => setFieldTouched('type', true)}
                                    options={userTypeOptions} placeholder={placeholder.userType}
                                    captureMenuScroll menuPlacement="auto" menuShouldScrollIntoView portal
                                  />
                                  {errors.type && touched.type ? (<div className={themeStyles.formError}>{errors.type}</div>) : ' '}
                                </Grid>
                                {validateTypeUser(countriesTypesArray, values.type) ? <Grid item xs={6}>
                                  <label htmlFor="countries" className={themeStyles["col-form-label"]}>{ts.countries}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <SearchSelect
                                    isMulti
                                    onChange={option => setFieldValue('countries', option)}
                                    value={values.countries} onBlur={() => setFieldTouched('countries', true)}
                                    options={getOptionsSelect(countryList, t)} placeholder={placeholder.countries}
                                  />
                                  {errors.countries && touched.countries ? (<div className={themeStyles.formError}>{errors.countries}</div>) : ' '}
                                </Grid> : ""}
                              </Grid>
                            </div>
                            <div className={`${themeStyles["form-group"]} ${themeStyles["form-crud-actions"]}`}>
                              <button type="submit" className={`${themeStyles.btn} ${themeStyles["btn-secondary"]}`} disabled={isSubmitting} onClick={() => {
                                // eslint-disable-next-line no-console
                                console.log({ errors });
                                setFieldValue('typeForm', submitType.update);
                              }}>{
                                  isSubmitting ? `${ts.pleaseWait}...` : (!currentUser ? ts.create : ts.update)
                                }</button>
                            </div>
                            <FormikErrorFocus offset={0} align="top" duration={1000} />
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </Collapse>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>)
  );
};

export default withTranslation()(UserDetails);