import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContainerGroupsListing from '../../components/shared-comp/groups/views/listing';
import GroupsActions from '../../components/shared-comp/groups/model/actions';
import WorkflowActions from '../../redux/workflow/actions';

const mapStateToProps = (state) => ({
  groupsLoading: state.groups.groupsLoading,
  groupsError: state.groups.groupsError,
  groupsCalled: state.groups.groupsCalled,
  groups: state.groups.groups,
  createGroupLoading: state.groups.createGroupLoading,
  createGroupError: state.groups.createGroupError,
  createdGroup: state.groups.createdGroup,
  country: state.workflow.country,
  countries: state.workflow.countries,
});


const mapDispatchToProps = (dispatch) => ({
  createGroup: bindActionCreators(GroupsActions.createGroup, dispatch),
  deleteGroup: bindActionCreators(GroupsActions.deleteGroup, dispatch),
  fetchGroups: bindActionCreators(GroupsActions.fetchGroups, dispatch),
  fetchGroupsFailed: bindActionCreators(GroupsActions.fetchGroupsFailed, dispatch),
  fetchGroupsRequest: bindActionCreators(GroupsActions.fetchGroupsRequest, dispatch),
  fetchGroupsSuccess: bindActionCreators(GroupsActions.fetchGroupsSuccess, dispatch),
  getGroup: bindActionCreators(GroupsActions.getGroup, dispatch),
  fetchCountries: bindActionCreators(WorkflowActions.fetchCountries, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(ContainerGroupsListing);
