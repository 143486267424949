import GroupsActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case GroupsActions.fetchGroupsRequest().type:
      return state.merge({
        groupsLoading: true,
        groupsError: null,
        groupsCalled: false,
      });

      case GroupsActions.fetchGroupsSuccess().type:
        return state.merge({
          groupsLoading: false,
          groupsError: null,
          groupsCalled: true,
          groups: action.payload.groups.data || state.groups,
        });


    default:
      return state;
  }
};
