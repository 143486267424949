/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Icon from '../../components/shared-comp/core/Icon';
import { Grid, Container, Button, TextField, Checkbox, FormControlLabel, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import styles from './guideline.module.sass';


const Guideline = () => {

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [openSuccess, setOpenSuccess] = React.useState(false);

  const handleSuccessClick = () => {
    setOpenSuccess(true);
  };
  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };



  return (
    <section>

        <header className={styles['header-guideline']}>
            <h1>UI Wao's Guideline</h1>
            <p>This theme is based on material-UI and supported for those components</p>
        </header>

        <section className={styles['section-component']}>
            <header className={styles["heaader-section-component"]}>
                <b>Colors</b>
            </header>

            <Container>
                <Grid container spacing={3}>
                    <Grid item>
                        <div>
                            <div className={`primary-bgcolor ${ styles['color-preview']}`}>
                                <div className={`primary-bgcolor ${ styles["variation-1"]}`} />
                                <div className={`primary-bgcolor ${ styles["variation-2"]}`} />
                                <div className={`primary-bgcolor ${ styles["variation-3"]}`} />
                            </div>
                            <p>Primary Color Purple</p>
                            <span>#6B246B</span>
                        </div>
                    </Grid>
                    <Grid item>
                        <div>
                            <div className={`yellow-bgcolor ${ styles['color-preview']}`}>
                                <div className={`yellow-bgcolor ${ styles["variation-1"]}`} />
                                <div className={`yellow-bgcolor ${ styles["variation-2"]}`} />
                                <div className={`yellow-bgcolor ${ styles["variation-3"]}`} />
                            </div>
                            <p>Yellow</p>
                            <span>#FFBE13</span>
                        </div>
                    </Grid>
                    <Grid item>
                        <div>
                            <div className={`dark-bgcolor ${ styles['color-preview']}`}>
                                <div className={`dark-bgcolor ${ styles["variation-1"]}`} />
                                <div className={`dark-bgcolor ${ styles["variation-2"]}`} />
                                <div className={`dark-bgcolor ${ styles["variation-3"]}`} />
                            </div>
                            <p>Dark</p>
                            <span>#2B303A</span>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item>
                        <div>
                            <div className={`secondary-bgcolor ${ styles['color-preview']}`}>
                                <div className={`secondary-bgcolor ${ styles["variation-1"]}`} />
                                <div className={`secondary-bgcolor ${ styles["variation-2"]}`} />
                                <div className={`secondary-bgcolor ${ styles["variation-3"]}`} />
                            </div>
                            <p>Secondary - green</p>
                            <span>#39DECB</span>
                        </div>
                    </Grid>
                    <Grid item>
                        <div>
                            <div className={`red-bgcolor ${ styles['color-preview']}`}>
                                <div className={`red-bgcolor ${ styles["variation-1"]}`} />
                                <div className={`red-bgcolor ${ styles["variation-2"]}`} />
                                <div className={`red-bgcolor ${ styles["variation-3"]}`} />
                            </div>
                            <p>red</p>
                            <span>#F67173</span>
                        </div>
                    </Grid>
                    <Grid item>
                        <div>
                            <div className={`orange-bgcolor ${ styles['color-preview']}`}>
                                <div className={`orange-bgcolor ${ styles["variation-1"]}`} />
                                <div className={`orange-bgcolor ${ styles["variation-2"]}`} />
                                <div className={`orange-bgcolor ${ styles["variation-3"]}`} />
                            </div>
                            <p>Orange</p>
                            <span>#FF7B24</span>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    
        <section className={styles['section-component']}>
            <header className={styles["heaader-section-component"]}>
                <b>Typography</b>
            </header>

            <Container>

                <Grid container spacing={3}>
                    <Grid item xs={5}>
                        <div>
                            <p>FONT</p>
                            <h1>Work Sans</h1>
                        </div>
                    </Grid>
                    <Grid item xs={7}>
                        <div>
                            <p>FONT WEIGHTS</p>
                        </div>
                        <div className={styles["demo-font-weights"]}>
                            <span className="font-bold">Bold</span>
                            <span className="font-regular">Regular</span>
                            <span className="font-light">Light</span>
                        </div>
                    </Grid>
                </Grid>

                <p>PRE-DEFINED SIZES</p>

                <Grid container spacing={3} alignItems="flex-end" justify="center">
                    <Grid item>
                        <h1>Aa</h1>
                        <hr className="divider" />
                        <span>H1 - 36PX</span>
                    </Grid>
                    <Grid item>
                        <h2>Aa</h2>
                        <hr className="divider" />
                        <span>H2 - 30PX</span>
                    </Grid>
                    <Grid item>
                        <h3>Aa</h3>
                        <hr className="divider" />
                        <span>H3 - 26PX</span>
                    </Grid>
                    <Grid item>
                        <h4>Aa</h4>
                        <hr className="divider" />
                        <span>H4 - 22PX</span>
                    </Grid>
                    <Grid item>
                        <h5>Aa</h5>
                        <hr className="divider" />
                        <span>H5 - 20PX</span>
                    </Grid>
                    <Grid item>
                        <h6>Aa</h6>
                        <hr className="divider" />
                        <span>H6 - 18PX</span>
                    </Grid>
                    <Grid item>
                        <p>Aa</p>
                        <hr className="divider" />
                        <span>P - 16PX</span>
                    </Grid>
                </Grid>

                <p>EXAMPLES</p>

                <Grid container spacing={3}>
                    <Grid item>
                        <h1>Citrus Lentil Salad</h1>
                        <p>“Rinse the lentils under cold running water in a fine-mesh sieve until the water runs clear. Place the lentils in a medium saucepan and add enough cold water to cover by 3 inches (7.6 centimeters). Bring to a boil, then reduce the heat to medium-low, cover, and simmer for 20 to 30 minutes or until the lentils are tender.”</p>
                    </Grid>
                    <Grid item>
                        <h1>Barbecued Shrimp</h1>
                        <p>“Rinse the shrimp and pat them dry with paper towels. Arrange them in a single layer in a 13-by-9-inch (33-by-23-centimeter) baking dish.</p>
                        <p className="font-small">Excerpt From: Williams, Nathan. “Kinfolk Table : Recipes for Small Gatherings (9781579655877).” iBooks. </p>
                    </Grid>
                    <Grid item>
                        <small>FOR THE HASH</small>
                        <h6>“Sweet Potato Hash with Sausage and Egg”</h6>
                        <p>“This dish is a complete meal. But if you are looking for something a little lighter, the hash and poached egg can stand on their own without the sausage, and that way it’s vegetarian, too.</p>
                    </Grid>
                </Grid>

            </Container>

            
        </section>

        <section className={styles['section-component']}>
            <header className={styles["heaader-section-component"]}>
                <b>Buttons</b>
            </header>

            <Container>
                <Grid container spacing={3}>
                    <Grid item>
                        <Button variant="contained" color="primary">Primary</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary">Secondary</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" className="btn-warning">Warning</Button>
                    </Grid>
                    <Grid item>
                    <Button variant="contained" className="btn-error">Error</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" disabled>Disabled</Button>
                    </Grid>
                </Grid>
                    
            </Container>
        </section>

        <section className={styles['section-component']}>
            <header className={styles["heaader-section-component"]}>
                <b>Inputs</b>
            </header>

            <Container>

                <Grid container spacing={3}>
                    <Grid item>
                        <TextField label="Normal Input" fullWidth="true" />
                    </Grid>
                    <Grid item>
                      <TextField
                          fullWidth="true"
                          error
                          id="outlined-error-helper-text"
                          label="Error"
                          defaultValue="Hello World"
                          helperText="Incorrect entry."
                          variant="outlined"
                      />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item>
                        <TextField color="primary" label="Secondary Input" variant="outlined" fullWidth="true"/>
                    </Grid>
                    <Grid item>
                      <TextField
                          fullWidth="true"
                          id="outlined-multiline-static"
                          label="Multiline"
                          multiline
                          rows={4}
                          defaultValue="Default Value"
                          variant="outlined"
                      />
                    </Grid>
                </Grid>
                

                
            </Container>
        </section>

        <section className={styles['section-component']}>
            <header className={styles["heaader-section-component"]}>
                <b>Checkboxes</b>
            </header>

            <Container>
                
                <Grid container spacing={3}>
                    <Grid item>
                      
                        <div>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={state.checkedB}
                                    onChange={handleChange}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label="Martha Higareda"
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={state.checkedB}
                                    onChange={handleChange}
                                    name="checkedB"
                                    color="primary"
                                />
                                }
                                label="Martha Higareda"
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <div>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={state.checkedB}
                                    onChange={handleChange}
                                    name="checkedB"
                                    color="secondary"
                                />
                                }
                                label="Martha Higareda"
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={state.checkedB}
                                    onChange={handleChange}
                                    name="checkedB"
                                    color="secondary"
                                />
                                }
                                label="Martha Higareda"
                            />
                        </div>
                    </Grid>
                </Grid>

                
            </Container>
        </section>

        <section className={styles['section-component']}>
            <header className={styles["heaader-section-component"]}>
                <b>Alerts</b>
            </header>

            <Container>
                <div className="align-center">
                  <Button variant="outlined" onClick={handleSuccessClick}>Launch success alert</Button>
                </div>
                
                <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
                  <Alert severity="success" variant="filled">This is a success message!</Alert>
                </Snackbar>
                
                <div className={styles["demo-parent-alert-spacing"]}>
                    <Alert severity="success" variant="filled">This is a success message!</Alert>
                </div>
                <div className={styles["demo-parent-alert-spacing"]}>
                    <Alert severity="info" variant="filled">This is an information message!</Alert>
                </div>
                <div className={styles["demo-parent-alert-spacing"]}>
                    <Alert severity="warning" variant="filled">This is a warning message!</Alert>
                </div>
                <div className={styles["demo-parent-alert-spacing"]}>
                    <Alert severity="error" variant="filled">This is an error message!</Alert>
                </div>

            </Container>
        </section>

        <section className={styles['section-component']}>
            <header className={styles["heaader-section-component"]}>
                <b>Logos</b>
            </header>

            <Container>
                <Grid container spacing={3} alignItems="center" justify="center">
                    <Grid item className="align-center">
                        <Icon color="#a646eb" size={200} icon="logo-wao" />
                    </Grid>
                    <Grid item className="align-center">
                        <Icon color="#a646eb" size={100} icon="logo-wao" />
                    </Grid>
                    <Grid item className="align-center">
                        <Icon color="#a646eb" size={70} icon="logo-wao" />
                    </Grid>
                    <Grid item className="align-center">
                    <Icon color="#39374e" size={200} icon="logo-wao" />
                    </Grid>
                    <Grid item className="align-center">
                    <Icon color="#39374e" size={100} icon="logo-wao" />
                    </Grid>
                    <Grid item className="align-center">
                    <Icon color="#39374e" size={70} icon="logo-wao" />
                    </Grid>
                </Grid>
                
            </Container>
        </section>



        
    </section>
  );
  
};

export default Guideline;
