import { createActions } from "redux-actions";

import { GenericError } from '../../../../utils/errors';

import {
    getPayouts
} from '../services/payout';

const PayoutActions = createActions({
    FETCH_PAYOUTS_REQUEST: () => {},
    FETCH_PAYOUTS_SUCCESS: (payouts) => ({payouts}),
    FETCH_PAYOUTS_FAILED: (error) => ({error}),

    GET_PAYOUT: () => {},
    CREATE_PAYOUT: () => {},
    DELETE_PAYOUT: () => {},
});


PayoutActions.fetchPayouts = (query) => async (dispatch) => {
      try {
        dispatch(PayoutActions.fetchPayoutsRequest());
        if (query.country === 'ALL') query.country = null;
        const result = await getPayouts(query);
        dispatch(PayoutActions.fetchPayoutsSuccess(result));
      } catch (err) {
          console.log('err ==>', err);
        dispatch(PayoutActions.fetchPayoutsFailed({ error: new GenericError(err, err) }));
      }
    };

export default PayoutActions;