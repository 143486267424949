import { createActions } from "redux-actions";

import { GenericError } from '../../../../utils/errors';

import {
  getCustomers
} from '../services/customers';

const CustomersActions = createActions({
  FETCH_CUSTOMERS_REQUEST: () => { },
  FETCH_CUSTOMERS_SUCCESS: (customers) => ({ customers }),
  FETCH_CUSTOMERS_FAILED: (error) => ({ error }),

  GET_CUSTOMER: () => { },
  CREATE_CUSTOMER: () => { },
  DELETE_CUSTOMER: () => { },
});


CustomersActions.fetchCustomers = (query) => async (dispatch) => {
    try {
      dispatch(CustomersActions.fetchCustomersRequest());
      if (query.country === 'ALL') query.country = null;
      const result = await getCustomers(query);
      dispatch(CustomersActions.fetchCustomersSuccess(result));
    } catch (err) {
      console.log('err ==>', err);
      dispatch(CustomersActions.fetchCustomersFailed({ error: new GenericError(err, err) }));
    }
  };

export default CustomersActions;