/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import { CircularProgress, Grid } from '@mui/material';
import blastStyles from '../../../commissions/views/forms/styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import FormikErrorFocus from 'formik-error-focus';
import styles from './styles.module.sass';
import { actions, callAssignToast, crudAction, durationFromNow, formatTimestamp, formCol, modules } from '../../../utils';
import TableCommon from '../../../core/TableCommon';
import { Field, Form, Formik } from 'formik';
import { addPointsTendero, getPointsTendero } from '../../services/referrers';
import Pagination from '../../../core/Pagination';




const ReferrersAddPoints = ({ onClose = () => { }, t, object }) => {
  
  const {data, country } = object;
  

  const module = modules(t).points;

  const tr = {
    required: t('Required'),
    requiredQuantity: t('requiredQuantity'),
    add: t('Add'),
    adding: t('Adding'),
    motive: t('Motive'),
    quantity: t('Quantity'),
  };

  const queryParamsFilters = useMemo(()=>({
    page: 1,
    limit: 10,
    sort: [],
    q: null,
    country,
    refresh: 0
  }), [country, data.id]);

  

  const crudOptions = {
    module,
    name: data?.fullName || '',
  };

  const formInitialValues = {
    motive: "",
    quantity: 0
  };

  const [tableRows, setTableRows] = useState(null);
  const [paging, setPaging] = useState(null);
  const [dataPoints, setDataPoints] = useState({});
  const [queryParamsFiltersInit, setQueryParamsFiltersInit] = useState(queryParamsFilters);
  const [addMgs, setAddMgs] = useState(null);
  const [addMgsTimeOut, setAddMgsTimeOut] = useState(false);
  const [loaderEnabled, setLoaderEnabled] = useState(true);

  const [tab, setTab] = useState('available');

  const refreshCurrentQuery = () => {
    const newParams = { ...queryParamsFiltersInit, refresh: (queryParamsFiltersInit.refresh + 1) };
    setQueryParamsFiltersInit(newParams);
  };

  const dispatchRequestFilters = (query => {
    const newQuery = { ...queryParamsFiltersInit, ...query };
    setQueryParamsFiltersInit(newQuery);
  });

  const returnPointsTenderos = async (id, query) => {
    let res = [];
    const tenderosResult = await callAssignToast(getPointsTendero.bind(null, id, query), () => { }, 'list PointsTendero');
    res = tenderosResult;
    if(res)setLoaderEnabled(false);
    setDataPoints(res);
    return res;
  };

  const changeTabData = (tab="available", accumulatedData= false) => {
    setQueryParamsFiltersInit({ ...queryParamsFiltersInit, accumulated:accumulatedData, page : 1 });
    setTab(tab);
  };
  

  const tableColumns = [
    formCol({label: 'Motive'}),
    formCol({label: 'Quantity', alignment: 'center'}),
    formCol({label: 'Addedby', alignment: 'center'}),
    formCol({label: 'Date', alignment: 'center'}),
  ];

  const createData = (data) => {

    const dateToText = (date) => {
      if (!date) return '-';
      let result = date;
      if (!Number.isNaN(Date.parse(date))) result = formatTimestamp(date);
      return result;
    };

    const formatPoints = (points) => {
      let parsedPoints = 0;
      if (data.operationType === 'earned'){
        parsedPoints = <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{color:'#39AB4B'}}>+ {points}</span>
          {data.order ? <span style={{ color: "#7f7f7f", fontSize: "12px" }}>{data.order}</span> : ""}
        </div>;
      } else if (data.operationType === 'spent'){
        parsedPoints = <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{color:'#D9001B'}}>- {points}</span>
          {data.order ? <span style={{ color: "#7f7f7f", fontSize: "12px" }}>{data.order}</span> : ""}
        </div>;
      } else {
        parsedPoints = <div style={{ display: "flex", flexDirection: "column" }}>
          <span /* style={{ color: '#D9001B' }} */> {points}</span>
          {data.order ? <span style={{ color: "#7f7f7f", fontSize: "12px" }}>{data.order}</span> : ""}
        </div>;
      }

      return parsedPoints;
    
    };

    const addedByHtml = <div style={{ display: "flex", flexDirection: "column" }}>
      <span>{!data.addedBy && !data.addedEMail ? 'Socio' : data.addedBy}</span>
      {data.addedEMail && (data.addedBy !== data.addedEMail) ? <span style={{ color: "#7f7f7f", fontSize: "12px" }}>{data.addedEMail}</span> : ""}
    </div>;

    const dateAtHtml = <div style={{ display: "flex", flexDirection: "column", fontSize: "13px" }}>
      <div title={durationFromNow(data.created_at)} className={themeStyles.capitalize}>
        {dateToText(data.created_at)}
      </div>
    </div>;

    setLoaderEnabled(false);

    return {
      motive: data.history,
      quantity: formatPoints(data.points),
      addedBy: addedByHtml,
      created_at: dateAtHtml,
    };
  };

  useEffect(() => {

    const newParams = { ...queryParamsFiltersInit };
    delete newParams.refresh;
    if (newParams.country) returnPointsTenderos(data?.id, queryParamsFiltersInit);

  }, [queryParamsFiltersInit]);



  useEffect(() => {
    if (dataPoints && dataPoints.data?.listPointsHistory) {
    setTableRows(dataPoints?.data?.listPointsHistory?.map(createData));
    setPaging(dataPoints.paging);
    }
  }, [dataPoints]);

    // Form placeholders
    const placeholder = {
      motive: t('Motive'),
      quantity: t('Quantity'),
    };

    // Formik validation
    const ExportSchema = Yup.object().shape({
      motive: Yup.string().min(10, t('Minimun N characters',{char:10})).max(100, t('Maximum N characters',{char:100})).required(t('You must specify the reason for assigning the points')),
      quantity: Yup.number(t('Quantity must be a number')).min(1, t('Must use a value at least greater than N',{number:0})).required(tr.requiredQuantity)
    });

    /**
   * Function called whenever the Formik form is submitted
   * @async
   * @param {Object} values Form values
   * @param {Object} options Functions provided by the Formik form
   */
     const formOnSubmit = async (values, {resetForm}) => {

      const body = {
      	userId: data?.id,
	      motive: values.motive,
	      points: values.quantity,
      };

      const response = await crudAction(addPointsTendero.bind(null, body), refreshCurrentQuery,
        { ...crudOptions, action: actions.ADD }, t, true
      );

      if(response?.ok && response?.data?.message === "Success"){
        setAddMgs({msg: response?.data?.data?.notificationAdded, status: "success"});
        setAddMgsTimeOut(true);
        resetForm();
      }else if(!response?.ok){
        setAddMgs({msg: t("Try it again"), status: "error"});
        setAddMgsTimeOut(true);
      }

      setTimeout(() => {
        setAddMgsTimeOut(false);
      }, 3000);
    };


  return (
    <div>
      <Grid container spacing={0} alignItems="flex-start" >
        <Grid item xs>

          <div className={themeStyles.currentContent}>
            <div style={{ textAlign: 'right', height: '1px'}}>
              <button onClick={onClose} style={{background: "transparent", color: '#c8c8c8', width: '40px', height: '40px', padding: '0px', fontSize:"20px" }}>X</button>
            </div>
            <div className={blastStyles["blast-view"]}>
              <header className={themeStyles["header-form"]}>
              <div className={styles["header-points"]}>
                  <h3>{t('WAO! Points of', {socioName:data.fullName})}</h3>
                </div>
              </header>
              <div className={themeStyles["form-wrap"]}>
              <div className={styles["available-points__container"]}>
                <div className={styles["available-points"]}>
                    {loaderEnabled ? <div style={{ display: 'flex', justifyContent: 'center', padding: 'auto' }}>
                      <CircularProgress size={20}/>
                    </div> : <span className={styles["available-points__quantity"]}>{dataPoints.data?.pointsAvailable || 0}</span>}
                    <span className={styles["available-points__text"]}>{t("Available Points")}:</span>
                </div>
                <div className={styles["available-points"]}>
                    {loaderEnabled ? <div style={{ display: 'flex', justifyContent: 'center', padding: 'auto' }}>
                      <CircularProgress size={20}/>
                    </div> : <span className={styles["available-points__quantity"]}>{dataPoints.data?.pointsAccumulated || 0}</span>}
                    <span className={styles["available-points__text"]}>{t("Pending Points")}:</span>
                </div>
              </div>
              <div className={styles["add-points"]}>
                <span>{t("Add points")}:</span>
              </div>
              <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formOnSubmit} validationSchema={ExportSchema} autoComplete="off" >
                  {({ isSubmitting, values, handleChange, handleBlur, errors, touched }) => (
                    <Form>
                      <div className={styles["form-points-container"]}>
                      <div className={`${themeStyles["form-group"]} ${styles["form-points__motive"]}`}>
                        <label htmlFor="motive" className={themeStyles["col-form-label"]}>{tr.motive}:</label>
                        <Field className={errors.motive ? themeStyles["form-control__error"] : themeStyles["form-control"]} name="motive" placeholder={placeholder.motive} value={!isSubmitting ? values.motive : ''} onChange={handleChange} onBlur={handleBlur} />
                      </div>

                      <div className={`${themeStyles["form-group"]} ${styles["form-points__quantity"]}`}>
                        <label htmlFor="quantity" className={themeStyles["col-form-label"]}>{tr.quantity}:</label>
                        <Field className={errors.quantity ? themeStyles["form-control__error"] : themeStyles["form-control"]} name="quantity" placeholder={placeholder.quantity} value={!isSubmitting ? values.quantity : ''} onChange={handleChange} onBlur={handleBlur} />
                      </div>

                      <div className={themeStyles["form-crud-actions"]}>
                        <button type="submit" style={{color:"#000"}} className={`${themeStyles.btn } ${ themeStyles["btn-secondary"]}`} disabled={isSubmitting}>{
                          isSubmitting ? `${tr.adding}...` : tr.add
                        }</button>
                      </div>
                      </div>
                      <div style={{marginTop:"-15px"}}>
                        {errors.motive && touched.motive ? (<div className={themeStyles.formError} style={{textAlign:"left"}}>{`Error: ${errors.motive}`}</div>) : ' '}
                        {errors.quantity ? (<div className={themeStyles.formError} style={{textAlign:"left"}}>{`Error: ${errors.quantity}`}</div>) : ' '}
                        {addMgs && addMgs.status === "error" && addMgsTimeOut? (<div className={themeStyles.formError} style={{textAlign:"left"}}>{`Error: ${addMgs.msg}`}</div>) : ''}
                        {addMgs && addMgs.status === "success" && addMgsTimeOut ? (<div className={themeStyles.formSuccess} style={{textAlign:"left"}}>{addMgs.msg}</div>) : ''}
                      </div>
                      <FormikErrorFocus offset={0} align="top" duration={1000} />
                    </Form>
                  )}
                </Formik>
                <div className={styles.tabContainer}>
                    <section className={themeStyles.tabs}>
                      <button className={tab === 'available' ? themeStyles.__active : null } onClick={() => changeTabData('available',false)}>{t('Availables')}</button>
                      <button className={tab === 'pending' ? themeStyles.__active : null } onClick={() => changeTabData('pending',true) }>{t('Pendings')}</button>
                    </section>
                    <div className={styles.tabContainer__section}>
                    {!dataPoints.data?.listPointsHistory && loaderEnabled ? <div style={{ display: 'flex', justifyContent: 'center', padding: '100px' }}>
                    <CircularProgress />
                    </div>
                    : ""}
                   {!dataPoints.data?.listPointsHistory && !loaderEnabled ? <div style={{textAlign:"center", margin: "50px"}}><span>{t("NotPountsData")}</span></div>
                    : ""}
                    {!loaderEnabled && dataPoints.data?.listPointsHistory ? <div className={styles["table-points"]}>
                    <div style={{margin: "0 5px"}}>
                      <h6>{t('Movements')}</h6>
                    </div>
                    <TableCommon rows={tableRows} columns={tableColumns} module={module} refresh={refreshCurrentQuery} />
                    { paging ? <Pagination onClick={dispatchRequestFilters} country={country} paging={paging}/> : null}
                    </div> : ""}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

    </div>
  );
};

export default withTranslation()(ReferrersAddPoints);