import ProductsActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  
  switch (action.type) {
    
    case ProductsActions.fetchProductsRequest().type:
      return state.merge({
        productsLoading: true,
        productsError: null,
        productsLoaded: false,
      });

    case ProductsActions.fetchProductsSuccess().type:
      return state.merge({
        productsLoading: false,
        productsError: null,
        productsLoaded: true,
        products: action.payload.products.data || state.products,
      });

    case ProductsActions.createProductRequest().type:
      return state.merge({
        createProductLoading: true,
        createProductError: null,
        createProductLoaded: false,
      });

    case ProductsActions.createProductFailed().type:
      return state.merge({
        productLoading: false,
        createProductError: action.payload.error.error,
        createdProduct: false,
        productLoaded: false,
      });

    case ProductsActions.createProductSuccess().type:
      return state.merge({
        createProductLoading: false,
        createProductError: null,
        createProductLoaded: true,
        createdProduct: true,
        product: action.payload.product || state.product,
      });

    

    case ProductsActions.watchProductRequest().type:
      return state.merge({
        watchProductLoading: true,
        watchProductError: null,
        watchProductLoaded: false,
      });

    case ProductsActions.watchProductFailed().type:
      console.log('===========> ProductsActions.watchProductFailed');
      return state.merge({
        productLoading: false,
        watchProductError: action.payload.error.error,
        watchProduct: false,
        productLoaded: false,
      });

    case ProductsActions.watchProductSuccess().type:
      console.log('===========> ProductsActions.watchProductSuccess');
      return state.merge({
        watchProductLoading: false,
        watchProductError: null,
        watchProductLoaded: true,
        watchProduct: true,
        watchedProduct: action.payload.product.data || state.product,
      });


    default:
      return state;
  }
};
