import { createActions } from "redux-actions";

import { GenericError } from '../../../../utils/errors';

import {
  getTenderos
} from '../services/referrers';

const ReferrersActions = createActions({
  FETCH_REFERRERS_REQUEST: () => { },
  FETCH_REFERRERS_SUCCESS: (referrers) => ({ referrers }),
  FETCH_REFERRERS_FAILED: (error) => ({ error }),

  GET_REFERRER: () => { },
  CREATE_REFERRER: () => { },
  DELETE_REFERRER: () => { },
});


ReferrersActions.fetchReferrers = (query) => async (dispatch) => {
    try {
      dispatch(ReferrersActions.fetchReferrersRequest());
      if (query.country === 'ALL') query.country = null;
      const result = await getTenderos(query);
      dispatch(ReferrersActions.fetchReferrersSuccess(result));
    } catch (err) {
      dispatch(ReferrersActions.fetchReferrersFailed({ error: new GenericError(err, err) }));
    }
  };

export default ReferrersActions;