import { getConfig } from '../../../../config';

let api;
const defaultLimit = 100;

const checkConfig = () => {
  if (!api) api = getConfig('api');
};

/**
{string} message Payout message
{string} name Payout name
{Array} numbers Payout numbers
*/
export const getPayouts = (query) => {
  checkConfig();
  return api.get(`/payouts`, query);
};

/**
{string} message Payout message
{string} name Payout name
{Array} numbers Payout numbers
*/
export const getPayoutsList = ({ page = 1, limit = defaultLimit, searchTerms }) => {
  checkConfig();
  if (searchTerms) return api.get(`/payouts?page=${page}&limit=${limit}&q=${searchTerms}`);
  return api.get(`/payouts?page=${page}&limit=${limit}`);
};

  
/**
 * Creates a payout
 * @param {object} data
 */
export const postPayouts = (data) => {
  checkConfig();
  return api.post('/payouts', data);
};

/**
 * Creates a payout in bulk
 * @param {object} data
 */
 export const postPayoutsBulk = (data) => {
  checkConfig();
  return api.post('/payouts/bulk', data);
};

/**
 * Get the payout with the given id
 * @param {string} id id of the payout to get
 */
export const getPayout = (id) => {
  checkConfig();
  return api.get(`/payouts/${id}`);
};

/**
 * Update the payout with the given id
 * @param {string} id id of the payout to update
 * @param {object} data
 * data.name {string} Payout name
 * data.message {string} Payout message
 * data.numbers {Array} Payout numbers
 */
export const updatePayout = (id, data) => {
  checkConfig();
  return api.put(`/payouts/${id}`, data);
};

/**
 * Delete the payout with the given id
 * @param {string} id id of the payout to delete
 */
export const deletePayout = (id) => {
  checkConfig();
  return api.delete(`/payouts/${id}`);
};

export const addingBonus = (data) => {
  checkConfig();
  return api.post(`/payouts/add-reward/`, data);
};

export const removeBonus = (data) => {
  checkConfig();
  return api.put(`/payouts/delete-reward/`, data);
};
export const updateBono = (data) => {
  checkConfig();
  return api.put(`/payouts/update-reward/`, data);
};

/**
 * Updates a payout in bulk
 * @param {object} data
 */
 export const putPayoutsBulk = (data) => {
  checkConfig();
  return api.put('/payouts/bulk', data);
};
