/* eslint-disable no-return-await */
/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import Menu, { createItem } from '../../../core/Menu';
import { columnTypes } from '../../../core/TableCommon';
import BasicModal from '../../../core/BasicModal';
import UsersCreate from '../forms/UsersCreate';
import themeStyles from '../../../../../theme.module.sass';
import UsersExport from '../forms/UsersExport';
import { callAssignToast, modules , crudAction, actions, capitalize} from '../../../utils';
import { getUserMeta, updateStatusUser } from "../../services/users";
import ListView from '../../../core/ListView';

const UsersListing = ({ t, users, usersLoading, fetchUsers, country, countries, fetchCountries }) => {
  
  const module = modules(t).user;

  

  const externalQueryParamsFiltersInit = useMemo(()=>({
    all:true
  }), []);

  
    const [userMeta, setUserMeta] = useState();
  const [statuses, setStatuses] = useState();
  const [externalRefresh, setExternalRefresh] = useState(0);
  
  
  const refreshOptions = { refresh: (externalRefresh + 1)};

  const tableColumns = [
    {label : 'ID', sort : 'id'},
    { label : 'Name' , sort : 'firstName' },
    { label : 'Email' , sort : 'email'},
    { label : 'Phone' , sort : 'phone' },
    { label : 'User type' , sort : 'type', alignment: 'center' },
    { label : 'Created at', type : columnTypes.DATE , sort : 'created_at', alignment: 'center'},
    { label : 'Actions', alignment: 'center' }
  ];


  const createData = (data) => {
    const { id, firstName, lastName, email, phone, created_at} = data;
    const buttonHtml = createItem({ icon: { icon: 'edit_cover-' }, text: t("Edit") });
    const editHtml = <BasicModal content={UsersCreate} typeButton="html" options={{ html: buttonHtml }} object={data} onClose={()=> setExternalRefresh(externalRefresh + 1)} />;
    const updateStatus = (data.status === 'active') ?
    {
      icon: { icon: 'cancel' },
      text: t("Deactivate User"),
      onClick: async () => {
        const response = await crudAction(updateStatusUser.bind(null, data.id, 'inactive'), ()=>{},
          {
            module, name: data.name, action: actions.UPDATE }, t
        );
        if (response?.ok) {
          setExternalRefresh(externalRefresh + 1);
        }
      }
    }
    :
    {
        icon: { icon: 'arrow_in_right-' },
        text: t("Activate User"),
        onClick: async () => {
          const response = await crudAction(updateStatusUser.bind(null, data.id, 'active'), ()=>{},
            { module, name: data.name, action: actions.UPDATE }, t
          );
          if (response?.ok) {
            setExternalRefresh(externalRefresh + 1);
          }
        }
    };
    const menuItems = [
      { html: editHtml },
      updateStatus
    ];
    const menu = <Menu items={menuItems} />;
    const type = t(capitalize(data?.type));

    return { id,
      name: `${firstName} ${lastName}`,
      email,
      phone,
      type,
      created_at,
      actions: menu,
      tooltip: created_at,
      data
    };
  };


  const loadUserMeta = async () => await callAssignToast(getUserMeta, setUserMeta, 'list User Metadata');
  
  useEffect(() => {
    if (userMeta && userMeta.status) setStatuses(userMeta.status);
  }, [userMeta]);

  useEffect(() => {
    const initLoad = async () => {
      await Promise.all([loadUserMeta()]);
      // setReadyToLoad(true);
    };
    initLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  


  return (
    <section className={themeStyles["inner-component-view"]}>
     <ListView
        createData={createData} collection={users} loadingTable={usersLoading} tableColumns={tableColumns} module={module} statuses={statuses}
        createView={UsersCreate} exportView={UsersExport} fetchFunction={fetchUsers} country={country}
        countries={countries} fetchCountries={fetchCountries} externalRefresh={externalRefresh}
        externalRefreshOptions={refreshOptions} externalQueryParamsFiltersInit={externalQueryParamsFiltersInit}
      />
    </section>
  );
};

export default withTranslation()(UsersListing);