import React from 'react';
import { useDispatch } from 'react-redux';
import AuthActions from '../../../redux/auth/actions';

const Logout = () => {
  const dispatch = useDispatch();
  dispatch(AuthActions.logout());
  return <div>Logout...</div>;
};

export default Logout;