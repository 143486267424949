import { createActions } from "redux-actions";

import { GenericError } from '../../../../utils/errors';

import {
    getProducts,
    createProduct,
    watchProduct
} from '../services/engine';

const ProductsActions = createActions({
    FETCH_PRODUCTS_REQUEST: () => {},
    FETCH_PRODUCTS_SUCCESS: (products) => ({products}),
    FETCH_PRODUCTS_FAILED: (error) => ({error}),

    CREATE_PRODUCT_REQUEST: () => {},
    CREATE_PRODUCT_SUCCESS: (product) => ({product}),
    CREATE_PRODUCT_FAILED: (error) => ({ error }),

    WATCH_PRODUCT_REQUEST: () => {},
    WATCH_PRODUCT_SUCCESS: (product) => ({product}),
    WATCH_PRODUCT_FAILED: (error) => ({ error }),

    GET_PRODUCT: () => {},
    CREATE_PRODUCT: () => {},
    DELETE_PRODUCT: () => {},
});


ProductsActions.fetchProducts = (query) => async (dispatch) => {
      try {
        dispatch(ProductsActions.fetchProductsRequest());
        const result = await getProducts(query);
        dispatch(ProductsActions.fetchProductsSuccess(result));
      } catch (err) {
        const error = err || {};
        const eMessage = error.message || error || 'API ERROR' ;
        dispatch(ProductsActions.fetchProductsFailed({ error: new GenericError(eMessage, eMessage) }));
      }
    };

ProductsActions.createProduct = (data) => async (dispatch) => {
    try {
      dispatch(ProductsActions.createProductRequest());
      const result = await createProduct(data);
      if(result.status === 200){
        dispatch(ProductsActions.createProductSuccess(result));
      }else{
        throw result.data;
      }
    } catch (err) {
      const error = err || {};
      const eMessage = error.message || 'API ERROR' ;
      dispatch(ProductsActions.createProductFailed({ error: eMessage }));
    }
  };


ProductsActions.watchProduct = (productId, data) => async (dispatch) => {
    try {
      dispatch(ProductsActions.watchProductRequest());
      const result = await watchProduct(productId, data);
      if(result.status === 200){
        dispatch(ProductsActions.watchProductSuccess(result));
      }else{
        throw result.data;
      }
    } catch (err) {
      const error = err || {};
      const eMessage = error.message || 'API ERROR' ;
      dispatch(ProductsActions.watchProductFailed({ error: eMessage }));
    }
  };

export default ProductsActions;