import Immutable from 'seamless-immutable';

const initialState = Immutable({
  filtersInit: true,
  countriesLoading: false,
  countriesError: null,
  countriesLoaded: false,
  countries: null,
  statusesLoading: false,
  statusesError: null,
  statusesCalled: false,
  statuses: null,
});


export default initialState;
