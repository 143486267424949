/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { pipe, find, propEq, prop, __, concat, curry, toLower, toUpper } from 'ramda';
import { Field } from "formik";
import PhoneInput from 'react-phone-input-2';
import { usePrevious } from '../../../../utils/hooks';
import 'react-phone-input-2/lib/style.css';

const enforceList = [
  { countryCode: 'ar', dialCode: '54', enforce: '9' },
  { countryCode: 'ec', dialCode: '593', enforce: '9' },
  { countryCode: 'co', dialCode: '57' },
  { countryCode: 'mx', dialCode: '52', enforce: '1' },
];

const replaceBegSubstring = curry((target, deliver, toReplace) => {
  const i = target && target.length ? target.length : 0;
  if (toReplace?.substr(0, i) === target) return `${deliver}${toReplace.substring(i)}`;

  return toReplace;
});

const PhoneInputEnforcerInner = (props) => {
  const { value: propsValue } = props;
  const prevValue = usePrevious(propsValue);

  const { form, field, value: v, country: c, setCountryCode } = props;
  const lookForCode = curry((x, name) => prop(name, find(propEq('countryCode', prop('countryCode', x)), enforceList)));
  const lookForCodeEnforce = lookForCode(__, 'enforce');
  const lookForDialCode = lookForCode(__, 'dialCode');
  const enforce = curry((curryVal, d, e) => pipe(replaceBegSubstring(d, ''), replaceBegSubstring(e, ''), concat(`${d}${e}`))(curryVal));
  const [country, setCountry] = useState(c);
  const [value, setValue] = useState(enforce(v, lookForDialCode({ countryCode: country }), lookForCodeEnforce({ countryCode: country })));
  
  const onChange = useCallback((option, countryObj) => {
    if (countryObj.countryCode !== country) {
      const codeEnforce = lookForCodeEnforce({ countryCode: toLower(country) });
      const dialCode = lookForDialCode({ countryCode: toLower(countryObj.countryCode) });
      const codeEnforce2 = lookForCodeEnforce(countryObj);
      const dialCode2 = lookForDialCode(countryObj);
      const cleanedOption = pipe(replaceBegSubstring(dialCode, ''), replaceBegSubstring(codeEnforce, ''))(option);
      const enforced = enforce(cleanedOption, dialCode2, codeEnforce2);
      
      setTimeout(() => {
        if (form && field) form.setFieldValue(field?.name, enforced);
        setValue(enforced);
      }, 100);

    } else {
      const codeEnforce = lookForCodeEnforce(countryObj);
      const dialCode = lookForDialCode(countryObj);
      const enforced = ((dialCode && codeEnforce) && enforce(option, dialCode, codeEnforce)) || option;
      if(form && field) form.setFieldValue(field?.name, enforced);
      setValue(enforced);
    }
    if (setCountryCode) setCountryCode(toUpper(countryObj.countryCode));
    setCountry(countryObj.countryCode);
  }, [lookForCodeEnforce, lookForDialCode, enforce, field, form, country, setCountryCode]);

  useEffect(() => {
    if (propsValue !== prevValue) setValue(propsValue);
  }, [prevValue, propsValue]);

  return <PhoneInput {...props} country={country} value={value} onChange={onChange} />;
};

const PhoneInputEnforcer = (props) => <Field name={props?.name} {...props} >
    {({ field, form }) => <PhoneInputEnforcerInner {...props} field={field} form={form} />}
  </Field>;

export default PhoneInputEnforcer;