import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormikErrorFocus from 'formik-error-focus';
import { withTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import Icon from '../../../core/Icon';
import blastStyles from './styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import { postPayouts, postPayoutsBulk } from '../../../payouts/services/payout';
import DatePickerField from '../../../core/DatePickerField';
import { actions, crudAction, modules } from '../../../utils';
import TableCommon from '../../../core/TableCommon';
import moment from 'moment';
import Price from '../../../core/Price';
import ModalCloseButton from '../../../core/ModalCloseButton';

const PayoutCreate = ({ onClose = () => { }, t, object }) => {
  const { type, commissions, commissionsMap } = object;
    const [objCommissions, setObjCommissions] = useState([]);
  const [tableRows, setTableRows] = useState(null);
  const [PayoutSchema, setPayoutSchema] = useState(Yup.object().shape({
    description: Yup.string().required(t('Required')),
    dateOfPayout: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
    dateFrom: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
    dateTo: Yup.date(t('Required')).typeError(t('Required')).min(Yup.ref('dateFrom'), t('paymentToGreater')).required(t('Required'))
  }));

  const [isValid, setIsValid] = useState(true);


  const module = modules(t).payout;
  

  const formCol = (label, formColType, alignment = 'left', method = () => { }, isChecked = false) => ({ label: t(label), type: formColType, align: alignment, method, isChecked });

  const crudOptions = {
    module,
    name: '',
  };

  const tableColumns = [
    formCol('Socio Name'),
    formCol('Commission_other'),
    formCol('Payout Amount')
  ];

  function createData(data) {
    return {
      user: data.user,
      commissions: data.commissions.join(', '),
      amount: <Price value={data.amount} />
    };
  }

  useEffect(() => {
    const initLoad = async () => {
      if (type === 'simple') {
        let objects = [];
        for (const [key, value] of Object.entries(commissionsMap)) {
          if (value) objects.push(commissions[key]);
        }

        if (objects.length === 0) {
          const btn = document.getElementById('submit-button');
          btn.disabled = true;
          setIsValid(false);
        }

        const helper = {};
        objects = objects.reduce((r, o) => {
          const { id, amount, userId, referrer } = o;

          if (!helper[userId]) {
            helper[userId] = {
              commissions: [id],
              amount,
              user: referrer.fullName,
              userId
            };
            r.push(helper[userId]);
          } else {
            helper[userId].commissions.push(id);
            helper[userId].amount += amount;
          }
          return r;
        }, []);
        setObjCommissions(objects);
        setTableRows(objects.map(createData));
        setPayoutSchema(Yup.object().shape({
          description: Yup.string().required(t('Required')),
          dateOfPayout: Yup.date(t('Required')).typeError(t('Required')).required(t('Required')),
          // commissions: Yup.array(t('Required')).min(1, t("Required")).of(Yup.number()).required(t("Required")),
        }));
      }
    };
    initLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
   * Function called whenever the Formik form is submitted
   * @async
   * @param {Object} values Form values
   * @param {Object} options Functions provided by the Formik form
   */
  const formOnSubmit = async (values) => {

    if (type === 'bulk') {
      const body = {
        description: values.description,
        dateOfPayout: moment(values.dateOfPayout).format('YYYY/MM/DD'),
        dateFrom: moment(values.dateFrom).format('YYYY/MM/DD'),
        dateTo: moment(values.dateTo).format('YYYY/MM/DD')
      };
      await crudAction(postPayoutsBulk.bind(null, body), onClose,
        { ...crudOptions, name: values.description, action: actions.CREATE }, t
      );
    } else {
      const body = {
        objects: objCommissions.map((a) => ({
            description: values.description,
            dateOfPayout: moment(values.dateOfPayout).format('YYYY/MM/DD'),
            userId: a.userId,
            commissions: a.commissions,
            amount: a.amount
          }))
      };
      await crudAction(postPayouts.bind(null, body), onClose,
        { ...crudOptions, name: values.description, action: actions.CREATE }, t
      );
    }
  };

  // Formik initial/default values
  const formInitialValues = {
    description: "",
    dateFrom: new Date(),
    dateTo: new Date(),
    dateOfPayout: new Date(),
    commissions: (commissionsMap) ? Object.keys(commissionsMap) : []
  };

  return (
    <div>
      <ModalCloseButton onClick={() => onClose(true)} floating />
      <Grid container spacing={0} alignItems="flex-start" style={{ marginTop: "-30px" }}>
        <Grid item xs>
          <div className={themeStyles.currentContent}>
            <div className={blastStyles["blast-view"]}>
              <header className={themeStyles["header-form"]}>
                {(type === 'bulk') ?
                  <h3>{t('payout_title_bulk')}</h3>
                  :
                  <h3>{t('payout_title_simple')}</h3>
                }
              </header>
              <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formOnSubmit} validationSchema={PayoutSchema} autoComplete="off" >
                {({ isSubmitting, values, handleChange, handleBlur, errors, touched }) => (
                  <div className={themeStyles["form-wrap"]}>
                    {(type === 'bulk') ?
                      <p>
                        {t('payoutTextBulk')}
                      </p>
                      : <div>
                        <TableCommon rows={tableRows} columns={tableColumns} module={module} refresh={() => { }} />
                        {errors.commissions || !isValid ? (<div className={themeStyles.formError}>{t('choose_some_commission_payout')}</div>) : ' '}
                      </div>
                    }
                    <div>
                      <Form>
                        <div className={themeStyles["form-group"]} >
                          <label htmlFor="description" className={themeStyles["col-form-label"]}>{t("Payout Description")}</label>
                          <Field className={themeStyles["form-control"]} name="description" placeholder={t("Payout Description")} value={values.description} onChange={handleChange} onBlur={handleBlur} autoComplete="description" />
                          {errors.description && touched.description ? (<div className={themeStyles.formError}>{errors.description}</div>) : ' '}
                        </div>
                        {(type === 'bulk') ?
                          <Grid container spacing={1} className={themeStyles["form-group"]}>
                            <Grid item xs={4} className={blastStyles["date-field"]}>
                              <label htmlFor="dateFrom" className={themeStyles["col-form-label"]}>{t("Payment From")}</label>
                              <DatePickerField popperPlacement="right" className="dateTimePicker" dateFormat="yyyy-MM-dd" name="dateFrom" placeholderText={t("Payment From")} timeIntervals={1} autoComplete="off" />
                              <i>
                                <Icon color="#CAC6CE" size={22} icon="calendar-1" />
                              </i>
                              {errors.dateFrom && touched.dateFrom ? (<div className={themeStyles.formError}>{errors.dateFrom}</div>) : ' '}
                            </Grid>
                            <Grid className={blastStyles.separator} item xs={1}>
                              <span>-</span>
                            </Grid>
                            <Grid item xs={4} className={blastStyles["date-field"]}>
                              <label htmlFor="dateTo" className={themeStyles["col-form-label"]}>{t("Payment To")}</label>
                              <DatePickerField popperPlacement="left" className="dateTimePicker" dateFormat="yyyy-MM-dd" name="dateTo" placeholderText={t("Payment To")} timeIntervals={1} autoComplete="off" />
                              <i>
                                <Icon color="#CAC6CE" size={22} icon="calendar-1" />
                              </i>
                              {errors.dateTo && touched.dateTo ? (<div className={themeStyles.formError}>{errors.dateTo}</div>) : ' '}
                            </Grid>
                          </Grid>
                          : ''}
                        <Grid container spacing={1} className={themeStyles["form-group"]}>
                          <Grid item xs={4} className={blastStyles["date-field"]}>
                            <label htmlFor="dateOfPayout" className={themeStyles["col-form-label"]}>{t("Schedule Payment Date")}</label>
                            <DatePickerField className="dateTimePicker" dateFormat="yyyy-MM-dd" name="dateOfPayout" placeholderText={t("Schedule Payment Date")} timeIntervals={1} autoComplete="off" />
                            <i>
                              <Icon color="#CAC6CE" size={22} icon="calendar-1" />
                            </i>
                            {errors.dateOfPayout && touched.dateOfPayout ? (<div className={themeStyles.formError}>{errors.dateOfPayout}</div>) : ' '}
                          </Grid>
                        </Grid>
                        <div className={themeStyles["form-crud-actions"]}>
                          <button id='submit-button' type="submit" className={`${themeStyles.btn} ${themeStyles["btn-secondary"]}`}>
                            {isSubmitting ? `${t('Please Wait')}...` : t('payout_button')}
                          </button>
                        </div>
                        <FormikErrorFocus offset={0} align="top" duration={1000} />
                      </Form>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </Grid>
      </Grid>

    </div>
  );
};

export default withTranslation()(PayoutCreate);