import React from 'react';
import { withTranslation } from 'react-i18next';
import menuStyles from './styles.module.sass';

const ToggleCheck = ({ value, onChange }) => (
    <div>
      <label className={menuStyles.switch}>
        <input type="checkbox" checked={value || false} onChange={onChange} disabled={false}/>
        <span className={`${menuStyles.slider } ${ menuStyles.round}` } />
      </label>
    </div>
  );

export default withTranslation()(ToggleCheck);