
import { createActions } from "redux-actions";
import {
    getCategories,
    getTrendingCategories,
    getKeywords,
    getTrendingKeywords
} from '../../services/engine';
import { GenericError } from '../../utils/errors';

const CategoriesActions = createActions({
    FETCH_CATEGORIES_REQUEST: () => {},
    FETCH_CATEGORIES_SUCCESS: (categories) => ({categories}),
    FETCH_CATEGORIES_FAILED: (error) => ({error}),

    FETCH_TRENDING_CATEGORIES_REQUEST: () => {},
    FETCH_TRENDING_CATEGORIES_SUCCESS: (categories) => ({categories}),
    FETCH_TRENDING_CATEGORIES_FAILED: (error) => ({error}),

    FETCH_KEYWORDS_REQUEST: () => {},
    FETCH_KEYWORDS_SUCCESS: (keywords) => ({keywords}),
    FETCH_KEYWORDS_FAILED: (error) => ({error}),

    FETCH_TRENDING_KEYWORDS_REQUEST: () => {},
    FETCH_TRENDING_KEYWORDS_SUCCESS: (keywords) => ({keywords}),
    FETCH_TRENDING_KEYWORDS_FAILED: (error) => ({error}),

    GET_CATEGORY: () => {},
    CREATE_CATEGORY: () => {},
    DELETE_CATEGORY: () => {},
});


CategoriesActions.fetchCategories = (query) => async (dispatch) => {
      try {
        dispatch(CategoriesActions.fetchCategoriesRequest());
        const result = await getCategories(query);
        dispatch(CategoriesActions.fetchCategoriesSuccess(result));
      } catch (err) {
          console.log('err ==>', err);
        dispatch(CategoriesActions.fetchCategoriesFailed({ error: new GenericError(err, err) }));
      }
    };

CategoriesActions.fetchTrendingCategories = (query) => async (dispatch) => {
    try {
      dispatch(CategoriesActions.fetchTrendingCategoriesRequest());
      const result = await getTrendingCategories(query);
      dispatch(CategoriesActions.fetchTrendingCategoriesSuccess(result));
    } catch (err) {
        console.log('err ==>', err);
      dispatch(CategoriesActions.fetchTrendingCategoriesFailed({ error: new GenericError(err, err) }));
    }
  };


CategoriesActions.fetchKeywords = (query) => async (dispatch) => {
    try {
      dispatch(CategoriesActions.fetchKeywordsRequest());
      const result = await getKeywords(query);
      dispatch(CategoriesActions.fetchKeywordsSuccess(result));
    } catch (err) {
        console.log('err ==>', err);
      dispatch(CategoriesActions.fetchKeywordsFailed({ error: new GenericError(err, err) }));
    }
  };

CategoriesActions.fetchTrendingKeywords = (query) => async (dispatch) => {
    try {
      dispatch(CategoriesActions.fetchTrendingKeywordsRequest());
      const result = await getTrendingKeywords(query);
      dispatch(CategoriesActions.fetchTrendingKeywordsSuccess(result));
    } catch (err) {
        console.log('err ==>', err);
      dispatch(CategoriesActions.fetchTrendingKeywordsFailed({ error: new GenericError(err, err) }));
    }
  };


export default CategoriesActions;