/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import ReactPaginate from 'react-paginate';
import SearchSelect from '../SearchSelect';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.sass';
import themeStyles from '../../../../theme.module.sass';

const Pagination = ({ onClick, t, ...props}) => {

  const pag = (props.paging && props.paging.page) ? props.paging.page : 1;

  const [showTotalItems, setShowTotalItems] = useState(10);
  const [pagination, setPagination] = useState(pag);
  const [paging] = useState(props.paging);
  const forcePage = props.paging.page < 0 ? 0 : props.paging.page - 1;

  const options = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
  ];


  useEffect(() => {
    setPagination(props.paging.totalPages);
  }, [props.paging, showTotalItems]);

  useEffect(() => {

    if (paging && showTotalItems) {
      const page = paging.totalItems / showTotalItems;
      setPagination(page);
    }

  }, [paging, showTotalItems]);


  const handleClickingPagination = (data, forceLimit) => {
    const selected = data.selected + 1;
    const page = selected;
    const limit = forceLimit || showTotalItems;
    // let sort = [] //this.paginationInit.sort
    onClick({ page, limit });
  };

  const applyShowItem = (limit) => {
    setPagination(paging.totalItems / limit);
    handleClickingPagination({ selected: 0 }, limit);
  };

  const handleChange = selectedOption => {
    const {value} = selectedOption;
    setShowTotalItems(value);
    applyShowItem(value);
  };

  return (
    <section className={`${styles['section-pagination']} flex justify-content-space-between`} style={{ zIndex: props.zIndexPagination }} >
      <Grid container spacing={0} alignItems="center" className={styles['wrap-pagination']}>
        <Grid item xs>

          <section className={styles['pagination-options']}>
            <span>{t("Show")}</span>

            <SearchSelect
              className={styles.optionListContainer}
              onChange={handleChange}
              menuPlacement="auto"
              onBlur={() => { }}
              options={options}
              placeholder="10"
            />

            <span>{t("results per page")}</span>
          </section>

        </Grid>
        <Grid item xs className="align-right" justify-content="flex-end">
          {
            (pagination) ?
              <section className={themeStyles.pagination} >
                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={Math.ceil(pagination)}
                  prevPageRel={null}
                  nextPageRel={null}
                  forcePage={forcePage}
                  // initialPage={this.initialPage}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={10}
                  onPageChange={handleClickingPagination}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              </section>
              :
              null
          }

        </Grid>
      </Grid>
    </section>
  );

};

export default withTranslation()(Pagination);