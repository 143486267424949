import ReferrersActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case ReferrersActions.fetchReferrersRequest().type:
      return state.merge({
        referrersLoading: true,
        referrersError: null,
        referrersCalled: false,
      });

      case ReferrersActions.fetchReferrersSuccess().type:
        return state.merge({
          referrersLoading: false,
          referrersError: null,
          referrersCalled: true,
          referrers: action.payload.referrers.data || state.referrers,
        });


    default:
      return state;
  }
};
