import Immutable from 'seamless-immutable';

const initialState = Immutable({
  recomendationsInit: true,
  recomendationsLoading: false,
  recomendationsError: null,
  recomendationsLoaded: false,
  recomendations: null,

  createRecomendationLoading: false,
  createRecomendationError: null,
  createdRecomendation: false,
});


export default initialState;
