import { createActions } from "redux-actions";

import { GenericError } from '../../../../utils/errors';

import {
    getCommissions
} from '../services/commission';

const CommissionActions = createActions({
    FETCH_COMMISSIONS_REQUEST: () => {},
    FETCH_COMMISSIONS_SUCCESS: (commissions) => ({commissions}),
    FETCH_COMMISSIONS_FAILED: (error) => ({error}),

    GET_COMMISSION: () => {},
    CREATE_COMMISSION: () => {},
    DELETE_COMMISSION: () => {},
});


CommissionActions.fetchCommissions = (query) => async (dispatch) => {
      try {
        dispatch(CommissionActions.fetchCommissionsRequest());
        if (query.country === 'ALL') query.country = null;
        const result = await getCommissions(query);
        dispatch(CommissionActions.fetchCommissionsSuccess(result));
      } catch (err) {
          console.log('err ==>', err);
        dispatch(CommissionActions.fetchCommissionsFailed({ error: new GenericError(err, err) }));
      }
    };

export default CommissionActions;