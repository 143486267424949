/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createActions } from "redux-actions";

import { GenericError } from '../../../../utils/errors';

import {
    getBlasts
} from '../services/blast';

const BlastActions = createActions({
    FETCH_BLASTS_REQUEST: () => {},
    FETCH_BLASTS_SUCCESS: (blasts) => ({blasts}),
    FETCH_BLASTS_FAILED: (error) => ({error}),

    GET_BLAST: () => {},
    CREATE_BLAST: () => {},
    DELETE_BLAST: () => {},
});


BlastActions.fetchBlasts = (query) => async (dispatch) => {
      try {
        dispatch(BlastActions.fetchBlastsRequest());
        if (query.country === 'ALL') query.country = null;
        const result = await getBlasts(query);
        dispatch(BlastActions.fetchBlastsSuccess(result));
      } catch (err) {
          console.log('err ==>', err);
        dispatch(BlastActions.fetchBlastsFailed({ error: new GenericError(err, err) }));
      }
    };

export default BlastActions;