/* eslint-disable no-console */
import { getConfig } from '../../../../config';

let api;
let firebase;
let auth;
let analytics;
let provider;

const checkConfig = () => {
  if (!api) api = getConfig('api');
  if (!firebase) {
    firebase = getConfig('firebase');
    ;({ auth, firebase, analytics, provider } = firebase);
  }
};

export const login = async (email, password) => {
  checkConfig();
  return new Promise((resolve, reject) => {
    try {
      auth.signInWithEmailAndPassword(email, password)
        .then((result) => { resolve(result.user); })
        .catch((error) => { reject(error.message); });
    } catch (err) {
      reject(err);
    }
  });
};

export const getUserToken = async () => {
  checkConfig();
  return new Promise((resolve, reject) => {
    try {
      auth.currentUser.getIdToken(true)
        .then((idToken) => { resolve(idToken); })
        .catch((error) => { reject(error.message); });
    } catch (err) {
      reject(err);
    }
  });
};

export const getIdTokenRefreshed = async () => {
  checkConfig();
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      unsubscribe();
      const refreshedToken = await user.getIdToken(true).catch(err => console.error(err));
      resolve(refreshedToken);
    }, reject);
  });
};

export const getCurrentUser = async () => {
  checkConfig();
  return new Promise((resolve, reject) => {
    let user;
    let err;
    try { user = auth.currentUser; }
    catch (error) { err = error; }
    if (user) resolve(user);
    else reject(err);
  });
};

export const createAccount = async (params) => {
  checkConfig();
  return new Promise((resolve, reject) => {
    try {
      auth.createUserWithEmailAndPassword(params.email, params.password)
        .then((result) => { resolve(result.user); })
        .catch((error) => { reject(error.message); });
    } catch (err) {
      reject(err);
    }
  });
};

export const updateProfile = async (params) => {
  checkConfig();
  return new Promise((resolve, reject) => {
    try {
      const user = auth.currentUser;
      user
        .updateProfile({ displayName: `${params.firstName }  ${ params.lastName}`, })
        .then(() => { resolve(user); })
        .catch((error) => { reject(error.message); });
    } catch (err) {
      reject(err);
    }
  });
};

export const getUserFromFirebase = () => {
  checkConfig();
  const firebaseUser = auth.currentUser;
  const user = { 'email' : firebaseUser.email, 'firstName': '', 'lastName':''};
  if (firebaseUser.displayName) {
    const [firstName, lastName] = firebaseUser.displayName.split("  ");
    if (firstName) user.firstName = firstName;
    if (lastName) user.lastName = lastName;
  }
  return user;
};

export const sendEmailVerification = async () => {
  checkConfig();
  return new Promise((resolve, reject) => {
    try {
      const user = auth.currentUser;
      user.sendEmailVerification().then(() => {
        resolve();
      }).catch((error) => {
        reject(error.message);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const verifyEmail = async (actionCode) => {
  checkConfig();
  return new Promise((resolve, reject) => {
    try {
      auth.applyActionCode(actionCode).then(() => {
        resolve();
      }).catch((error) => {
        reject(error.message);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const forgotPassword = (email) => {
  checkConfig();
  return new Promise((resolve, reject) => {
    try {
      auth.sendPasswordResetEmail(email).then(() => {
        resolve();
      }).catch((error) => {
        reject(error.message);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const changePassword = (password) => {
  checkConfig();
  return new Promise((resolve, reject) => {
    try {
      if (auth.currentUser) {
        auth.currentUser.updatePassword(password).then(() => {
          resolve();
        }).catch((error) => {
          reject(error.message);
        });
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const storeCredentials = (credentials) => {
  checkConfig();
  localStorage.setItem("access_token", credentials);
  api.setHeader("Authorization", `Bearer ${ credentials}`);
};

export const removeCredentials = async () => {
  checkConfig();
  localStorage.removeItem("access_token");
  delete api.headers.Authorization;
  await auth.signOut();
};

export const restoreCredentials = () => {
  checkConfig();
  const credentials = localStorage.getItem("access_token");
  if (credentials) {
    api.setHeader("Authorization", `Bearer ${ credentials}`);
    return true;
  }

  return false;
};

export const myData = () => {
  checkConfig();
  return api.get("users/me");
};

export const putMe = (data) => {
  checkConfig();
  return api.put("users/me", data);
};

export const checkFirebaseUserSession = () => {
  checkConfig();
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        resolve(user);
      } else {
        reject(new Error ("User is signed out."));
      }
    });
  });
};

export const setAnalyticsUserId = (userId) => {
  checkConfig();
  analytics.setUserId(userId);
};

export const signInWithGoogle = () => {
  checkConfig();
  return new Promise((resolve, reject) => {
    auth.signInWithPopup(provider)
    .then((result) => {
      resolve(result.user);
    })
    .catch((error) => {
      console.log("Error = ", error);
      reject(error.message);
    });
  });
};