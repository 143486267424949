import { createActions } from "redux-actions";

import { GenericError } from '../../../../utils/errors';

import {
  getRecomendationsList
} from '../services/recomendation';

const RecomendationActions = createActions({
    FETCH_RECOMENDATIONS_REQUEST: () => {},
    FETCH_RECOMENDATIONS_SUCCESS: (recomendations) => ({recomendations}),
    FETCH_RECOMENDATIONS_FAILED: (error) => ({error}),

    GET_RECOMENDATION: () => {},
    CREATE_RECOMENDATION: () => {},
    DELETE_RECOMENDATION: () => {},
});


RecomendationActions.fetchRecomendations = (query) => async (dispatch) => {
      try {
        dispatch(RecomendationActions.fetchRecomendationsRequest());
        if (query.country === 'ALL') query.country = null;
        const result = await getRecomendationsList(query);
        dispatch(RecomendationActions.fetchRecomendationsSuccess(result));
      } catch (err) {
          console.log('err ==>', err);
        dispatch(RecomendationActions.fetchRecomendationsFailed({ error: new GenericError(err, err) }));
      }
    };

export default RecomendationActions;