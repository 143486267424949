import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContainerRecomendationsListing from '../../components/shared-comp/recomendations/views/listing';
import RecomendationAction from '../../components/shared-comp/recomendations/model/actions';
import WorkflowActions from '../../redux/workflow/actions';

const mapStateToProps = (state) => ({
  recomendationsInit: state.recomendations.recomendationsInit,
  recomendationsLoading: state.recomendations.recomendationsLoading,
  recomendationsError: state.recomendations.recomendationsError,
  recomendationsLoaded: state.recomendations.recomendationsLoaded,
  recomendations: state.recomendations.recomendations,
  createRecomendationLoading: state.recomendations.createRecomendationLoading,
  createRecomendationError: state.recomendations.createRecomendationError,
  createdRecomendation: state.recomendations.createdRecomendation,
  country: state.workflow.country,
  countries: state.workflow.countries,
});

const mapDispatchToProps = (dispatch) => ({
  fetchRecomendations: bindActionCreators(RecomendationAction.fetchRecomendations, dispatch),
  fetchCountries: bindActionCreators(WorkflowActions.fetchCountries, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerRecomendationsListing);