import Immutable from 'seamless-immutable';

const initialState = Immutable({
  dealsLoading: false,
  dealsError: null,
  dealsCalled: false,
  deals: null,

  createDealLoading: false,
  createDealError: null,
  createdDeal: false,

});


export default initialState;
