import Immutable from 'seamless-immutable';

const initialState = Immutable({
  usersLoading: false,
  usersError: null,
  usersCalled: false,
  users: null,
});


export default initialState;
