import React from 'react';
import styles from './styles.module.sass';

const HeaderUser = () => {
  window.initFreshWidget();
  return (
    <header className={styles['header-user']} />
  );
};

export default HeaderUser;
