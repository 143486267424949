import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContainerProductsListing from '../../components/shared-comp/products/views/listing';
import ProductActions from '../../components/shared-comp/products/model/actions';
import WorkflowActions from '../../redux/workflow/actions';

const mapStateToProps = (state) => ({
  productsLoading: state.products?.productsLoading,
  productsError: state.products?.productsError,
  productsLoaded: state.products?.productsLoaded,
  products: state.products?.products,
  createProductLoading: state.products?.createProductLoading,
  createProductError: state.products?.createProductError,
  createdProduct: state.products?.createdProduct,
  country: state.workflow.country,
  countries: state.workflow.countries,
  watchedProduct: state.products?.watchedProduct,
  watchedProductError: state.products?.watchedProduct,
  watchProduct: state.products?.watchedProduct,
});

const mapDispatchToProps = (dispatch) => ({
  createProduct: bindActionCreators(ProductActions.createProduct, dispatch),
  deleteProduct: bindActionCreators(ProductActions.deleteProduct, dispatch),
  fetchProducts: bindActionCreators(ProductActions.fetchProducts, dispatch),
  fetchProductsFailed: bindActionCreators(ProductActions.fetchProductsFailed, dispatch),
  fetchProductsRequest: bindActionCreators(ProductActions.fetchProductsRequest, dispatch),
  fetchProductsSuccess: bindActionCreators(ProductActions.fetchProductsSuccess, dispatch),
  getProduct: bindActionCreators(ProductActions.getProduct, dispatch),
  watchProduct: bindActionCreators(ProductActions.watchProduct, dispatch),
  fetchCountries: bindActionCreators(WorkflowActions.fetchCountries, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerProductsListing);