import React from 'react';

import Box from '@mui/material/Box';

const Loading = () => (
    <Box>
      ...
    </Box>
  );

export default Loading;
