import Immutable from 'seamless-immutable';

const initialState = Immutable({
  groupsLoading: false,
  groupsError: null,
  groupsCalled: false,
  groups: null,
});


export default initialState;
