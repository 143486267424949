import { clone } from 'ramda';

const Storage = (orderIds) => {
  localStorage.setItem('orderIdFraud', JSON.stringify(orderIds || {}));
};

const save = (state) => {
  Storage(state.orderIds);
  return state;
};

export const OrderIdReducer = (state, action) => {
  
  const copy = clone(state);
  const orderId = copy.orderIds[action.payload.emailUser] || { ids: [] };

  switch (action.type) {
    case "ADD_ID":
      if (!orderId.ids.find(id => id === action.payload.id)) {
        orderId.ids.push(
          action.payload.id
        );
      }

      if(!copy.orderIds[action.payload.emailUser]) (copy.orderIds[action.payload.emailUser] = orderId);
      return save(copy);

    case "REMOVE_ID":
      copy.orderIds[action.payload.emailUser] = {
        ids: [...orderId.ids.filter(id => id !== action.payload.id)]
      };
      if(!copy.orderIds[action.payload.emailUser]) (copy.orderIds[action.payload.emailUser] = orderId);
      return save(copy);

    case "CLEAR":
      copy.orderIds[action.payload.emailUser] = {
        ids: [],
      };
      return save(copy);

    default:
      return state;
  }
};