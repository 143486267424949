import Immutable from 'seamless-immutable';

const initialState = Immutable({
  customersLoading: false,
  customersError: null,
  customersCalled: false,
  customers: null,

});


export default initialState;
