import React from 'react';

const ModalCloseButton = ({ styles, onClick, closeIcon = "X", floating }) => {
  const floatStyles = {
    position: "sticky",
    right: "8px",
    top: "8px",
    zIndex: 3
  };

  let extraStyles = {};
  if (floating) extraStyles = { ...extraStyles, ...floatStyles };

  return <div style={{ textAlign: 'right', ...extraStyles, ...styles }}>
    <button onClick={onClick} style={{ background: '#39DECB', color: '#FFFFFF', width: '40px', height: '40px', padding: '0px' }}>{closeIcon}</button>
  </div>;
};

export default ModalCloseButton;
