/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, { useCallback } from 'react';
import ListView from '../../../core/ListView';
import { formatDate, modules } from '../../../utils';
import themeStyles from '../../../../../theme.module.sass';
import { columnTypes } from '../../../core/TableCommon';


const CategoriesListing = ({t, categories, categoriesLoading, fetchCategories, fetchCountries, country, countries}) => {

  const module = modules(t).category;

  // const queryParamsFilters = {
  //   page : 1,
  //   limit : 10,
  //   sort : [],
  //   q: null,
  //   country: null
  // };

  const tableColumns = [
    {label: 'Category Name', type: columnTypes.CATEGORY_NAME },
    {label: 'Country'}
  ];

  const createData = useCallback((data) => {
    const {name_root, country, updated_at} = data;
    const categoryName = {name: name_root, country};
    const lastUpdate = formatDate(updated_at);

    return { categoryName, country, lastUpdate };
  }, []);
  
  return (
    <section className={themeStyles["inner-component-view"]}>
      <ListView
        createData={createData} collection={categories} loadingTable={categoriesLoading} tableColumns={tableColumns} module={module}
        fetchFunction={fetchCategories} country={country} countries={countries} fetchCountries={fetchCountries} showTitleHeader={false}
      />
    </section>
  );
  
};

export default CategoriesListing;
