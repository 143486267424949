/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import { Field } from 'formik';
import { Grid } from '@mui/material';
import themeStyles from '../../../../theme.module.sass';
import GoogleAutocompleteService from '../GoogleMaps/GoogleAutocompleteService';
import { withTranslation } from 'react-i18next';

export const addressIsEqual = (oldAddress, newAddress) => {
  let equal = true;
  const oldKeys = Object.keys(oldAddress);
  const newKeys = Object.keys(newAddress);
  if (oldKeys.length !== newKeys.length) return false;
  oldKeys.forEach(key => {
    if (!Object.prototype.hasOwnProperty.call(newAddress, key) || oldAddress[key] !== newAddress[key]) equal = false;
  });
  return equal;
};

export const defaultAddressValues = {
  fullAddress: "",
  address2: "",
  description: ""
};

const Address = React.memo(({ t, name, disabled = false, country }) => {

  const fieldName = name;
  const defaultPlaceholders = {
    fullAddress: t("Enter your address"),
    address2: t("Enter address details"),
    description: t("addressDescriptionPlaceholder"),
  };

  const masterDisable = disabled === true;
  if (disabled) {
    disabled = {
      fullAddress: masterDisable || disabled?.fullAddress || false,
      address2: masterDisable || disabled?.address2 || false,
      city: masterDisable || disabled?.city || false,
      country: masterDisable || disabled?.country || false,
      region: masterDisable || disabled?.region || false,
      zipCode: masterDisable || disabled?.zipCode || false,
      description: masterDisable || disabled?.description || false
    };
  }

  const assignAddressValues = useCallback((data, setFieldValue) => {
    console.log({data});
    setFieldValue(`${fieldName }.fullAddress`, data?.formatted_address || data?.address1);
    setFieldValue(`${fieldName }.address1`, data?.formatted_address || data?.address1);
    setFieldValue(`${fieldName }.city`, data?.city);
    setFieldValue(`${fieldName }.region`, data?.state);
    setFieldValue(`${fieldName }.zipCode`, data?.zipCode);
    setFieldValue(`${fieldName }.lat`, data?.lat);
    setFieldValue(`${fieldName }.lng`, data?.lng);
    setFieldValue(`${fieldName }.country`, country); // Uses props country
  },[country, fieldName]);


  return (
    <Field name={fieldName} id={fieldName}>
      {({ field: { value }, form: { setFieldValue, setFieldTouched, errors, touched } }) => (
        <div>
          <Grid container spacing={1} className={themeStyles["form-group"]}>
            <Grid item xs={12}>
              <div className={themeStyles["address-divider"]}>
                <label htmlFor="fullAddress" className={themeStyles["col-form-label"]}>{t("Address")}<span className={themeStyles["label-asterisk"]}>*</span></label>
                <GoogleAutocompleteService
                  outputAddressComponent={(data) => { assignAddressValues(data, setFieldValue); }}
                  countryCode={country} disabled={disabled?.fullAddress} name={`${fieldName }.fullAddress`}
                  value={{ ...value }} clearable
                />
                {errors[fieldName] && errors[fieldName].address1 && touched[fieldName] ? (<div className={themeStyles.formError}>{errors[fieldName].address1}</div>) : ' '}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <label htmlFor="description" className={themeStyles["col-form-label"]}>{t("address2Label")}</label>
                <Field className={themeStyles["form-control"]} name={`${fieldName }.address2`} placeholder={defaultPlaceholders?.address2} value={value?.address2}
                  onChange={e => { setFieldValue(`${fieldName }.address2`, e?.target?.value || undefined); }}
                  onBlur={() => setFieldTouched(`${fieldName }.address2`, true)}
                  autoComplete="new-password" disabled={disabled?.address2} />
                {errors[fieldName] && errors[fieldName].address2 && touched[fieldName] ? (<div className={themeStyles.formError}>{errors[fieldName].address2}</div>) : ' '}
              </div>
            </Grid>

            <Grid item xs={6}>

              <div>
                <label htmlFor="city" className={themeStyles["col-form-label"]}>{t("cityLabel")}<span className={themeStyles["label-asterisk"]}>*</span></label>
                <Field className={themeStyles["form-control"]} name={`${fieldName }.city`} placeholder={defaultPlaceholders?.city} value={value?.city}
                  onChange={e => { setFieldValue(`${fieldName }.city`, e?.target?.value || undefined); }}
                  onBlur={() => setFieldTouched(`${fieldName }.city`, true)}
                  autoComplete="new-password" disabled={disabled?.city} />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>

                <label htmlFor="region" className={themeStyles["col-form-label"]}>{t("regionLabel")}<span className={themeStyles["label-asterisk"]}>*</span></label>
                <Field className={themeStyles["form-control"]} name={`${fieldName }.region`} placeholder={defaultPlaceholders?.region} value={value?.region}
                  onChange={e => { setFieldValue(`${fieldName }.region`, e?.target?.value || undefined); }}
                  onBlur={() => setFieldTouched(`${fieldName }.region`, true)}
                  autoComplete="new-password" disabled={disabled?.region} />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>

                <label htmlFor="zipCode" className={themeStyles["col-form-label"]}>{t("zipCodeLabel")}</label>
                <Field className={themeStyles["form-control"]} name={`${fieldName }.zipCode`} placeholder={defaultPlaceholders?.zipCode} value={value?.zipCode}
                  onChange={e => { setFieldValue(`${fieldName }.zipCode`, e?.target?.value || undefined); }}
                  onBlur={() => setFieldTouched(`${fieldName }.zipCode`, true)}
                  autoComplete="new-password" disabled={disabled?.zipCode} />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div>
                <label htmlFor="description" className={themeStyles["col-form-label"]}>{t("Address Instructions")}</label>
                <Field rows="4" as="textarea" className={themeStyles["form-control"]} name={`${fieldName }.description`} placeholder={defaultPlaceholders.description} value={value?.description}
                  onChange={e => { setFieldValue(`${fieldName }.description`, e?.target?.value || undefined); }}
                  onBlur={() => setFieldTouched(`${fieldName }.description`, true)}
                  autoComplete="new-password" disabled={disabled?.description} />
                {errors[fieldName] && errors[fieldName].description && touched[fieldName] ? (<div className={themeStyles.formError}>{errors[fieldName].description}</div>) : ' '}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Field>
  );
});

export default withTranslation()(Address);