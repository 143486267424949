import { createActions } from "redux-actions";

import { GenericError } from '../../../../utils/errors';

import {
  getGroups
} from '../services/groups';

const GroupsActions = createActions({
  FETCH_GROUPS_REQUEST: () => { },
  FETCH_GROUPS_SUCCESS: (groups) => ({ groups }),
  FETCH_GROUPS_FAILED: (error) => ({ error }),

  GET_GROUP: () => { },
  CREATE_GROUP: () => { },
  DELETE_GROUP: () => { },
});


GroupsActions.fetchGroups = (query) => async (dispatch) => {
    try {
      dispatch(GroupsActions.fetchGroupsRequest());
      if (query.country === 'ALL') query.country = null;
      const result = await getGroups(query);
      dispatch(GroupsActions.fetchGroupsSuccess(result));
    } catch (err) {
      dispatch(GroupsActions.fetchGroupsFailed({ error: new GenericError(err, err) }));
    }
  };

export default GroupsActions;