/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-await */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import * as Yup from 'yup';
import FormikErrorFocus from 'formik-error-focus';
import { withTranslation } from 'react-i18next';
import Icon from "../../../core/Icon";
import SearchSelect from '../../../core/SearchSelect';
import PhoneInputEnforcer from '../../../core/PhoneInputEnforcer';
import Address, { addressIsEqual } from '../../../core/Address';
import {
  actions, callAssignToast, countryPhone, crudAction, formatDateTime,
  formatDateTimeSimple, formDealLabel, formUserLabel, getAssetType, getDatei18n, getPaymentMethodOptions,
  getUrlExtension, mimeTypes, modules, phoneMasks, reasonInsteadOfSubstastus, restrictPaymentStatuses, toFloat
} from '../../../utils';
import orderStyles from './styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import DatePickerField from '../../../core/DatePickerField';
import { getCountry, getCountries, updateUser } from '../../../core/services/workflow';
import { postOrders, getOrder, updateOrder, deleteOrder, updateOrderStatusesBulk } from "../../services/orders";
import { getDeals } from '../../../deals/services/deals';
import { getTenderos, getBuyers } from '../../../referrers/services/referrers';
import { getPaymentMethodsByCountry } from '../../../transactions/services/transactions';
import { omit } from 'lodash';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ModalCloseButton from '../../../core/ModalCloseButton';
import { toast } from 'react-toastify';

const editableOrderStatuses = ["pending", "placed"];

const dateFormat = {
  year: 'numeric', month: 'short', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  hour12: false,
  timeZoneName: 'short'
};
const skipPartialCrudUpdatesSuccessNotification = true;
const DEFAULT_STATUS = 'placed';

const formatCurrency = (value, currency) => new Intl.NumberFormat('en-US', { currencyDisplay: 'narrowSymbol', style: 'currency', currency }).format(value);

function createData({ price, discountPrice, currency, quantity, currentQuantity, name, media, id, sku }) {
  const commission = discountPrice ? (price - discountPrice) : price;
  const commissionPercentage = toFloat(((price - discountPrice) * 100 / price));
  return {
    price, discountPrice, currency, quantity, currentQuantity, name, media, id, commission, commissionPercentage, sku
  };
}

const calculateDiscount = (price, discountPrice, printable = false) => {
  let result = (price - discountPrice) / price * 100;
  if (!printable) result /= 100;
  else result = Math.round(result * 100) / 100;
  return result;
};

const displayFormat = (array, /* disabled = false */) => {
  const response = [];
  array.forEach(item => {
    response.push({
      ...item,
      value: item.id,
      label: formDealLabel(item),
      quantity: item.quantity === 0 ? 0 : 1,
      currentQuantity: Object.prototype.hasOwnProperty.call(item, 'currentQuantity') ? item.currentQuantity : item.quantity,
      price: Number.parseFloat(item.price),
      discountPrice: Number.parseFloat(!item.discountPrice ? item.price : item.discountPrice),
      discount: !item.discount ? calculateDiscount(item.price, item.discountPrice) : item.discount,
      disabled: true
    });
    item.deals.forEach(deal => {
      if (deal.status === 'active') {
        response.push({
          ...deal,
          value: deal.id,
          label: formDealLabel(deal),
          quantity: deal.quantity === 0 ? 0 : 1,
          currentQuantity: Object.prototype.hasOwnProperty.call(deal, 'currentQuantity') ? deal.currentQuantity : deal.quantity,
          price: Number.parseFloat(deal.price),
          discountPrice: Number.parseFloat(!deal.discountPrice ? deal.price : deal.discountPrice),
          discount: !deal.discount ? calculateDiscount(deal.price, deal.discountPrice) : deal.discount
        });
      }
    });
  });
  return response;
};

const printDocumentType = (t) => {
  const typesArray = [
    { label: t('ID'), value: 'national', key: "1" },
    { label: t('Passport'), value: 'passport', key: "2" }
  ];
  return typesArray;
};

const OrderDetails = ({ onClose = () => { }, t, ...props }) => {

  const [currentOrder, setCurrentOrder] = useState(null);
  const [currentTendero, setCurrentTendero] = useState(null);
  const [dealsEditStatus, setDealsEditStatus] = useState({});
  const [buyersList, setBuyersList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [paymentMethodsList, setPaymentMethodsList] = useState([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  const [selectedBuyer, setSelectedBuyer] = useState(null);
  const [sameAddressCheck, setSameAddressCheck] = useState(true);
  const [socioBuys, setSocioBuys] = useState(false);
  const [allowNewCustomer, setAllowNewCustomer] = useState(true);
  const [tableRows, setTableRows] = useState(null);
  const [tempPrices, setTempPrices] = useState([]);
  const [tempCommissionPercentages, setTempCommissionPercentages] = useState([]);
  const [currentDeals, setCurrentDeals] = useState([]);
  const [currentQuantities, setCurrentQuantities] = useState({});
  const [originalQuantities, setOriginalQuantities] = useState({});
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [changeDocument, setChangeDocument] = useState(false);
  const [currentBuyerAddresses, setCurrentBuyerAddresses] = useState();
  const [shippingResetCounter, setShippingResetCounter] = useState(0);
  const [billingResetCounter, setBillingResetCounter] = useState(0);
  const [statuses /* , setStatuses */] = useState(props?.object?.statuses);
  const [loaderEnabled, setLoaderEnabled] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const module = modules(t).order;
  let closeTooltipTimer;

  const hasOrderReasons = !!(statuses?.orderReasons && Object.keys(statuses?.orderReasons).length);
  const hasCommissionReasons = !!(statuses?.commissionReasons && Object.keys(statuses?.commissionReasons).length);
  const hasTransactionReasons = !!(statuses?.transactionReasons && Object.keys(statuses?.transactionReasons).length);
  const headerCountry = getCountry();
  const initialCountry = currentOrder?.user?.country || headerCountry || "";
  const initialCountryLowerCase = initialCountry.toLowerCase();
  const defaultPhonePrefix = (`+${countryPhone[initialCountryLowerCase].code}${countryPhone[initialCountryLowerCase].enforce ? countryPhone[initialCountryLowerCase].enforce : ""}`);

  const datei18n = getDatei18n(initialCountry);

  const defaultCountries = ["co", "ar", "ec"];
  const defaultAddress = {
    fullAddress: '',
    address1: '',
    address2: '',
    country: initialCountry || '',
    region: '',
    description: '',
    city: '',
    zipCode: '',
  };
  const defaultEmptyCustomer = {
    customerFirstName: '',
    customerLastName: '',
    customerPhone: defaultPhonePrefix,
    customerEmail: '',
    customerCountry: initialCountry || '',
    customerDocument: '',
    customerDocumentType: '',
    shippingAddress: defaultAddress,
    billingAddress: defaultAddress
  };

  let defaultCustomerOptions = [];
  if (selectedBuyer) defaultCustomerOptions = [{ label: `${selectedBuyer?.firstName} ${selectedBuyer?.lastName} (${selectedBuyer?.phone})`, value: selectedBuyer.id, id: selectedBuyer.id }];
  if (allowNewCustomer) defaultCustomerOptions = [...defaultCustomerOptions, { "label": t('Create a new Customer'), "value": -1 }];

  const AddressSchema = Yup.object().shape({
    address1: Yup.string().required(t("Please verify the address")),
    address2: Yup.string().nullable(),
    description: Yup.string().nullable(),
    country: Yup.string().required(t("Required")),
    city: Yup.string().required(t("Required")),
    region: Yup.string().nullable(),
    zipCode: Yup.string().nullable()
  });

  let diffAddressSchema = Yup.object({});

  if (!sameAddressCheck) diffAddressSchema = Yup.object({
    billingAddress: AddressSchema.required(t("Required"))
  });

  const now = new Date();

  const DealSchema = Yup.object().shape({
    price: Yup.number().moreThan(0).required(t("Required")),
    discountPrice: Yup.number().moreThan(0).nullable(),
    quantity: Yup.number().min(1, t("outOfStockDeals")).nullable(true),
    expiresAt: currentOrder ? Yup.date(t('Required')).typeError(t('Required')) : Yup.date(t('Required')).typeError(t('Required')).min(now).required(t("Required"))
  });

  const OrderSchema = diffAddressSchema.shape({
    customerFirstName: Yup.string().required(t("Required")),
    customerLastName: Yup.string().required(t("Required")),
    customerCountry: Yup.string().required(t("Required")),
    customerPhone: Yup.string().min(8, t("Required")).required(t("Required")),
    customerEmail: Yup.string().email(t("Invalid Email")).nullable(),
    customerDocumentType: Yup.string(),
    customerDocument: Yup.string(),
    shippingAddress: AddressSchema.required(t("Required")),
    paymentType: Yup.string().required(t("Required")),
    status: Yup.string().required(t("Required")),
    statusReason: Yup.string(),
    statusComment: Yup.string(),
    deals: Yup.array().min(1, t("Required")).of(DealSchema).required(t("Required")),
    referrer: Yup.number().required(t("Required")),
    userId: Yup.number().min(-1, t("Required")).required(t("Required"))
  });

  const crudOptions = {
    module,
    name: `#${currentOrder?.orderId}` || '',
  };

  const returnDeals = async (queryTerm) => {
    let dealsResult = await callAssignToast(getDeals.bind(null, { q: queryTerm, country: initialCountry }), () => { }, 'list Users');
    if (dealsResult.data) { dealsResult = displayFormat(dealsResult.data); }
    return dealsResult;
  };


  const returnTenderos = async (queryTerm) => {
    let tenderosResult = await callAssignToast(getTenderos.bind(null, { q: queryTerm, country: initialCountry }), () => { }, 'list Users');

    if (tenderosResult.data) {
      const referrersArray = [];
      tenderosResult = tenderosResult.data.forEach(tendero => {
        referrersArray.push({ label: formUserLabel(tendero), value: tendero.id, object: tendero });
      });
      tenderosResult = referrersArray;
    }

    return tenderosResult;
  };

  const returnBuyers = async (queryTerm) => {
    let buyersResult = await callAssignToast(getBuyers.bind(null, { q: queryTerm, country: initialCountry }), setBuyersList, 'list Users');

    if (buyersResult.data) {
      setBuyersList(buyersResult.data);
      const resultArray = defaultCustomerOptions;
      const referrersArray = [];
      const customersArray = [];
      buyersResult = buyersResult.data.forEach(buyer => {
        const buyerObject = { label: formUserLabel(buyer), value: buyer.id, object: buyer };
        if (buyer.type && buyer.type === 'referrer') referrersArray.push(buyerObject);
        else if (buyer.type && buyer.type === 'customer') customersArray.push(buyerObject);
      });

      if (referrersArray.length > 0) resultArray.push({ "label": t('Referrer', { count: 100 }), "options": referrersArray });
      if (customersArray.length > 0) resultArray.push({ "label": t('Customer', { count: 100 }), "options": customersArray });
      buyersResult = resultArray;
    } else {
      setBuyersList([]);
    }
    return buyersResult;
  };

  const disableOptionsSelect = (currentValidate) => {
    // add options needed to disable
    let disable = false;

    if (currentValidate === 'chargeback') disable = true;

    return disable;
  };


  /**
   * @async
   * Fetches Countries from the API, sets the country list with them and reports any errors via Toasts
   */
  const loadCountries = async () => {
    const countries =  await callAssignToast(getCountries, setCountryList, 'list Countries');
    return countries;
  };

  /**
   * @async
   * Fetches Countries from the API, sets the country list with them and reports any errors via Toasts
   */
  const loadPaymentMethods = useCallback(async () => {
    const paymentMethods = await callAssignToast(() => getPaymentMethodsByCountry({country:initialCountryLowerCase, totalOrder:currentOrder?.total}), setPaymentMethodsList, 'list Payment Methods');
    return paymentMethods;
  },[currentOrder?.total, initialCountryLowerCase]);

  /**
   * Passed to the list to execute on Item click, updates the form with the selected Order
   * @async
   * @param {string} id
   */
  const fetchOrderByObject = async (object) => await callAssignToast(getOrder.bind(null, object.id), async (order) => {
    setAllowNewCustomer(false);
    setSelectedBuyer({ label: formUserLabel(order.user), ...order.user });
    setCurrentTendero({ label: formUserLabel(order.referrer), ...order.referrer, value: order.referrer.id });
    setSameAddressCheck(order.sameAddressCheck || false);
    const tempDeals = [...order.deals];
    const tempQuantities = {};
    tempDeals.forEach((deal, index, originalArray) => {
      const newDeal = { ...deal };
      newDeal.quantity = deal.quantity || 1;
      newDeal.currentQuantity = deal.currentQuantity + deal.quantity;
      newDeal.label = formDealLabel(deal);
      newDeal.value = deal.id;
      newDeal.tempPrice = deal.price;
      newDeal.tempDiscount = Number.parseFloat(!newDeal.discountPrice ? newDeal.price : newDeal.discountPrice);
      newDeal.discountPrice = Number.parseFloat(!newDeal.discountPrice ? newDeal.price : newDeal.discountPrice);
      originalArray[index] = newDeal;
      tempQuantities[deal.id] = newDeal.quantity;
    });
    order.deals = tempDeals;
    order.commissionStatus = order?.commission?.status;
    order.commissionId = order?.commission?.id;
    order.transactionStatus = order?.transaction?.status;
    order.transactionId = order?.transaction?.id;
    order.paymentType = order?.paymentMethod.type;
    setCurrentOrder(order);
    setCurrentDeals(tempDeals);
    setCurrentQuantities(tempQuantities);
    setOriginalQuantities(tempQuantities);
  }, 'load Order');

  const onCloseHandler = () => setTooltipOpen(false) ;

  const copySuccessHandler = () => {
    setTooltipOpen(true);
  };
  
  const canCopy = true;

  const closeEdits = () => {
    const updated = { ...dealsEditStatus };
    for (const key in updated) {
      if (updated[key]) updated[key] = { price: false, discount: false, commissionPercentage: false };
    }
    setDealsEditStatus(updated);
  };

  const getCurrentStatus = useCallback((status) => {
    const currentStatusResult = statuses?.statuses?.order?.find(s => s.key === status);
    return currentStatusResult;
  },[statuses]);

  useEffect(() => {
    if (tooltipOpen && !closeTooltipTimer) {
      setTimeout(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        closeTooltipTimer = null;
        setTooltipOpen(false);
      }, 3000);
    }
  }, [tooltipOpen]);

  // On load, get Tenderos, Groups, Deals and Orders
  useEffect(() => {
    const initLoad = async () => {
      await Promise.all([loadCountries(), loadPaymentMethods()]);
      setReadyToLoad(true);
    };

    initLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder]);

  useEffect(() => {
    if (paymentMethodsList && paymentMethodsList.length > 0)
      setPaymentMethodOptions(getPaymentMethodOptions(paymentMethodsList));
  }, [paymentMethodsList]);

  // On Countrylist update, update Countrycodes
  useEffect(() => {
    const currentUpdate = async () => {
      setCountryCodes(countryList.map(country => (country.code).toLowerCase()));
    };
    if (countryList) currentUpdate();
  }, [countryList]);

  useEffect(() => {
    const currentUpdate = async () => {
      const newArray = [];
      const updateCurrentQuantities = { ...currentQuantities };
      currentDeals?.forEach(deal => {
        newArray.push(createData(deal));
        if (deal.quantity === 0) updateCurrentQuantities[deal.id] = 0;
        else if (!Object.prototype.hasOwnProperty.call(updateCurrentQuantities, deal.id)) updateCurrentQuantities[deal.id] = deal.quantity || 1;
      });
      setCurrentQuantities(updateCurrentQuantities);
      setTableRows(newArray);
    };
    if (currentDeals) currentUpdate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDeals]);

  useEffect(() => {
    const afterLoad = async () => {
      if (props.object) await fetchOrderByObject(props.object);
      setLoaderEnabled(false);
    };
    if (readyToLoad) afterLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToLoad]);

  useEffect(() => {
    if (socioBuys) {
      closeEdits();
      if (currentTendero) {
        setSelectedBuyer({ label: currentTendero.label, value: currentTendero.object.id, ...currentTendero.object });
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socioBuys]);



  // Form placeholders
  const placeholder = {
    customerFirstName: t("Enter your first name"),
    customerLastName: t("Enter your last name"),
    customerPhone: "+1 (702) 123-4567",
    customerEmail: t("Enter your email address"),
    customerDocumentType: ` -- ${t("Select a Type")} -- `,
    customerDocument: t("Enter your document"),
    customerCountry: ` -- ${t("Select a Country")} -- `,
    paymentType: ` -- ${t("Select a Type")} -- `,
    deals: ` -- ${t("Select a Deal")} -- `,
    referrer: ` -- ${t("Select a Tendero")} -- `,
    notes: t("Enter your order notes"),
    expectedDeliveryDate: t("Delivery date")
  };

  // Form type, used to differentiate between update and delete
  const submitType = {
    update: 1,
    delete: 2
  };

  // Formik initial/default values
  const formInitialValues = {
    isChangedExpectedDeliveryDate: false,
    customerFirstName: currentOrder?.user?.firstName || "",
    customerLastName: currentOrder?.user?.lastName || "",
    customerPhone: currentOrder?.user?.phone || "+",
    customerEmail: currentOrder?.user?.email || "",
    customerCountry: initialCountry,
    customerDocument: currentOrder?.user?.document || "",
    customerDocumentType: currentOrder?.user?.documentType || "",
    userId: currentOrder?.user?.id || -2,
    quantity: currentOrder?.quantity || 1,
    shippingAddress: {
      addressId: currentOrder?.shippingAddress?.id || 0,
      fullAddress: currentOrder?.shippingAddress?.address1 || "",
      address1: currentOrder?.shippingAddress?.address1 || "",
      address2: currentOrder?.shippingAddress?.address2 || "",
      description: currentOrder?.shippingAddress?.description || "",
      region: currentOrder?.shippingAddress?.region || "",
      zipCode: currentOrder?.shippingAddress?.zipCode || "",
      city: currentOrder?.shippingAddress?.city || "",
      country: currentOrder?.shippingAddress?.country || "",
      lat: currentOrder?.shippingAddress?.lat || "",
      lng: currentOrder?.shippingAddress?.lng || "",
    },
    paymentType: currentOrder?.paymentType || "",
    billingAddress: {
      addressId: currentOrder?.billingAddress?.id || 0,
      fullAddress: currentOrder?.billingAddress?.address1 || "",
      address1: currentOrder?.billingAddress?.address1 || "",
      address2: currentOrder?.billingAddress?.address2 || "",
      description: currentOrder?.billingAddress?.description || "",
      region: currentOrder?.billingAddress?.region || "",
      zipCode: currentOrder?.billingAddress?.zipCode || "",
      city: currentOrder?.billingAddress?.city || "",
      country: currentOrder?.billingAddress?.country || "",
      lat: currentOrder?.billingAddress?.lat || "",
      lng: currentOrder?.billingAddress?.lng || "",
    },
    status: currentOrder?.status || DEFAULT_STATUS,
    statusReason: currentOrder?.statusReason || "",
    statusComments: currentOrder?.statusComments || "",
    commissionStatus: currentOrder?.commissionStatus || "",
    transactionStatus: currentOrder?.transactionStatus || "",
    deals: currentOrder?.deals || [],
    referrer: currentOrder?.referrer?.id || "",
    sameAddressCheck: currentOrder?.sameAddressCheck || false,
    notes: currentOrder?.notes || "",
    expectedDeliveryDate: currentOrder?.expectedDeliveryDate ? formatDateTimeSimple(new Date(currentOrder?.expectedDeliveryDate)) : null
  };

  if (currentOrder?.user?.addresses && currentOrder?.user?.addresses.length > 0) {
    formInitialValues.shippingAddress = currentOrder?.user.addresses[0] || formInitialValues.shippingAddress;
    formInitialValues.billingAddress = currentOrder?.user.addresses[1] || formInitialValues.billingAddress;
  }

  const dealProps = [
    'price',
    'quantity'
  ];

  const dealsAreSame = (oldDeal, newDeal) => {
    let isDealsAreSame = true;
    for (const propertyName in oldDeal) {
      if (dealProps.includes(propertyName) && oldDeal[propertyName] !== newDeal[propertyName]) {
        isDealsAreSame = false;
        break;
      }
    }

    if (oldDeal.discountPrice !== newDeal.discountPrice) isDealsAreSame = false;
    if (isDealsAreSame && currentQuantities[oldDeal.id] !== originalQuantities[newDeal.id]) isDealsAreSame = false;
    return isDealsAreSame;
  };

  const dealInArrayChanged = (deal, dealsArray) => {
    let dealChanged = false;
    try {
      dealsArray.forEach(arrayDeal => {
        if (arrayDeal.id === deal.id) {
          // If deal found change flag if required and stop
          if (!dealsAreSame(arrayDeal, deal)) {
            dealChanged = true;
            throw new Error();
          }
        }
      });
    } catch (error) { /**/ }
    return dealChanged;
  };

  /**
   * Function called whenever the Formik form is submitted
   * @async
   * @param {Object} values Form values
   * @param {Object} options Functions provided by the Formik form
   */
  const formOnSubmit = async (values) => {
    const isUpdate = values?.type === submitType.update;
    let userType = 'customer';
    if (selectedBuyer && selectedBuyer.type) userType = selectedBuyer.type;

    const shippingAddress = {
      address1: values.shippingAddress.address1,
      country: values.shippingAddress.country,
      city: values.shippingAddress.city
    };

    if (currentBuyerAddresses && currentBuyerAddresses.length > 0 && values.userId && values.userId !== -1) shippingAddress.id = currentBuyerAddresses[0].id;

    if (isUpdate || values?.shippingAddress?.address2) shippingAddress.address2 = values.shippingAddress.address2 || null;
    if (isUpdate || values?.shippingAddress?.region) shippingAddress.region = values.shippingAddress.region || null;
    if (isUpdate || values?.shippingAddress?.description) shippingAddress.description = values.shippingAddress.description || null;
    if (isUpdate || values?.shippingAddress?.zipCode) shippingAddress.zipCode = values.shippingAddress.zipCode || null;
    if (isUpdate || values?.shippingAddress?.lat) shippingAddress.lat = values.shippingAddress.lat || null;
    if (isUpdate || values?.shippingAddress?.lng) shippingAddress.lng = values.shippingAddress.lng || null;

    let billingAddress;

    if (sameAddressCheck) {
      billingAddress = shippingAddress;
    } else {
      billingAddress = {
        address1: values.billingAddress.address1,
        country: values.billingAddress.country,
        city: values.billingAddress.city
      };

      if (currentBuyerAddresses && currentBuyerAddresses.length > 0 && values.userId && values.userId !== -1 && currentBuyerAddresses.length > 1) {
        billingAddress.id = currentBuyerAddresses[1].id;
      }

      if (isUpdate || values?.billingAddress?.address2) billingAddress.address2 = values.billingAddress.address2 || null;
      if (isUpdate || values?.billingAddress?.region) billingAddress.region = values.billingAddress.region || null;
      if (isUpdate || values?.billingAddress?.description) billingAddress.description = values.billingAddress.description || null;
      if (isUpdate || values?.billingAddress?.zipCode) billingAddress.zipCode = values.billingAddress.zipCode || null;
      if (isUpdate || values?.billingAddress?.lat) billingAddress.lat = values.billingAddress.lat || null;
      if (isUpdate || values?.billingAddress?.lng) billingAddress.lng = values.billingAddress.lng || null;

    }

    const matchingMethod = paymentMethodsList.find(method => method.type === values.paymentType);

    let newOrder = {
      user: { type: userType },
      billingAddress,
      shippingAddress,
      referrer: values.referrer,
      sameAddressCheck,
      taxId: "1",
      // TODO Remove taxId?
      paymentMethodId: (!currentOrder || currentOrder.paymentMethodId !== matchingMethod?.id) ? matchingMethod?.id : null
    };

    if (!newOrder.paymentMethodId) {
      newOrder = omit(newOrder, ['paymentMethodId']);
    }

    if (values.expectedDeliveryDate && values.isChangedExpectedDeliveryDate) {
      newOrder.expectedDeliveryDate = formatDateTime(values.expectedDeliveryDate, true);
    }

    if (isUpdate || values?.notes) newOrder.notes = values.notes || null;

    if (!currentOrder) { // CREATE
      // user
      if (!values.userId || values.userId === -1) {
        newOrder.user = {
          ...newOrder.user,
          country: values.customerCountry,
          firstName: values.customerFirstName,
          lastName: values.customerLastName,
          status: 'active',
          phone: values.customerPhone,
        };

        if (values.customerEmail) newOrder.user.email = values.customerEmail.trim();
        if (values.customerDocument && values.customerDocument !== '' && values.customerDocumentType && values.customerDocumentType !== '') {
          newOrder.user.document = values.customerDocument;
          newOrder.user.documentType = values.customerDocumentType;
        }
      } else {
        newOrder.user = { id: values.userId };
        if (!selectedBuyer.document && values.userId !== -1) {
          newOrder.user.document = values.customerDocument;
          newOrder.user.documentType = values.customerDocumentType;
        }
      }

      newOrder.user.type = userType;
      newOrder.user.phone = values.customerPhone[0] !== '+' ? `+${values.customerPhone}` : values.customerPhone;
      // deals
      const newDeals = [];
      values.deals.forEach(deal => {
        const newDeal = {
          id: deal.id,
          price: socioBuys ? deal.discountPrice : deal.price,
          discountPrice: deal.discountPrice,
          quantity: currentQuantities[deal.id]
        };
        // if (deal.discountPrice && deal.discountPrice !== 0 && deal.discountPrice !== deal.price) newDeal.discountPrice = deal.discountPrice;
        newDeals.push(newDeal);
      });
      newOrder.deals = newDeals;

      let addressUpdateOk = true;

      // If a user is selected && an address is provided and something changed for that address
      if (currentBuyerAddresses && currentBuyerAddresses.length > 1 && values.userId && values.userId !== -1 && values.billingAddress && !addressIsEqual(currentBuyerAddresses[1], values.billingAddress)) {
        const newAddress = {
          ...(newOrder.billingAddress),
          id: currentBuyerAddresses[1].id
        };

        // Remove extra params
        if (Object.prototype.hasOwnProperty.call(newAddress, 'primaryAddress')) delete newAddress.primaryAddress;

        const newUserUpdate = {
          firstName: values.customerFirstName,
          addresses: [newAddress]
        };

        const userUpdate = await crudAction(updateUser.bind(null, values.userId, newUserUpdate),
          () => { }, {
          name: '',
          module: modules(t).address,
          action: actions.UPDATE
        }, t, skipPartialCrudUpdatesSuccessNotification
        );
        addressUpdateOk = userUpdate?.ok;
      }

      if (!addressUpdateOk) return;

      const notificationName = newOrder.deals.length > 1 ? `${newOrder.deals.length} ${t('Deal', { count: 100 })}` : newOrder.deals[0].name;
      await crudAction(postOrders.bind(null, newOrder), onClose,
        { ...crudOptions, name: notificationName, action: actions.CREATE }, t
      );
    } else if (values.type && values.type === submitType.delete) { // DELETE
      await crudAction(deleteOrder.bind(null, currentOrder.id), onClose,
        { ...crudOptions, action: actions.DELETE }, t
      );
    } else { // UPDATE

      // If different, attempt to update commission and transaction status before updating order
      let commissionUpdateStatus = '';
      let transactionUpdateStatus = '';
      let orderUpdateStatus = '';
      const disableStatus = disableOptionsSelect(values.transactionStatus) !== true;
      
      if (values.commissionStatus !== currentOrder.commissionStatus && disableStatus) {
        commissionUpdateStatus = values.commissionStatus;
      }

      if (values.transactionStatus !== currentOrder.transactionStatus) {
        transactionUpdateStatus = values.transactionStatus;
      }

      if (values.status !== currentOrder.status && disableStatus) {
        orderUpdateStatus = values.status;
      }

      if (currentOrder.shippingAddress.id) newOrder.shippingAddress.id = currentOrder.shippingAddress.id;
      if (currentOrder.billingAddress.id) newOrder.billingAddress.id = currentOrder.billingAddress.id;
      if (sameAddressCheck && currentOrder.shippingAddress.id) newOrder.billingAddress.id = currentOrder.shippingAddress.id;

      // user
      newOrder.user = {
        ...newOrder.user,
        id: values.userId,
        type: userType
      };

      if (!selectedBuyer.document && values.userId !== -1) {
        newOrder.user.document = values.customerDocument;
        newOrder.user.documentType = values.customerDocumentType;
      }

      // deals
      const oldDeals = currentOrder.deals;
      const updateDeals = values.deals;
      const oldIds = oldDeals.map(a => a.id);
      const newIds = updateDeals.map(a => a.id);
      const addedDeals = updateDeals.filter(deal => !oldIds.includes(deal.id));

      const modifiedDeals = updateDeals.filter(deal => {
        let changed = false;
        if (oldIds.includes(deal.id)) changed = dealInArrayChanged(deal, oldDeals);
        return changed;
      });

      const added = [];
      const modified = [];
      const deleted = oldDeals.filter(deal => !newIds.includes(deal.id)).map(deal => deal.id);

      addedDeals.forEach((deal) => {
        const addDeal = {
          id: deal.id,
          price: Number.parseFloat(deal.price),
          quantity: currentQuantities[deal.id]
        };
        if (deal.discountPrice) addDeal.discountPrice = Number.parseFloat(deal.discountPrice);
        if (deal.discount) addDeal.discount = Number.parseFloat(deal.discount);
        added.push(addDeal);
      });

      modifiedDeals.forEach((deal) => {
        const addDeal = {
          id: deal.id,
          price: Number.parseFloat(deal.price),
          quantity: currentQuantities[deal.id]
        };
        if (deal.discountPrice) addDeal.discountPrice = Number.parseFloat(deal.discountPrice);
        if (deal.discount) addDeal.discount = Number.parseFloat(deal.discount);
        modified.push(addDeal);
      });

      if (added.length > 0 || deleted.length > 0 || modified.length > 0) newOrder.deals = { added, deleted, modified };
      newOrder.user.phone = values.customerPhone[0] !== '+' ? `+${values.customerPhone}` : values.customerPhone;

      const updatedOrderObject = {};
      if (editableOrderStatuses?.includes(values.status)) {
        updatedOrderObject.billingAddress = newOrder.billingAddress;
        updatedOrderObject.shippingAddress = newOrder.shippingAddress;
      }


      if (Object.prototype.hasOwnProperty.call(newOrder, 'notes')) updatedOrderObject.notes = newOrder.notes || null;
      if (Object.prototype.hasOwnProperty.call(newOrder, 'expectedDeliveryDate')) updatedOrderObject.expectedDeliveryDate = newOrder.expectedDeliveryDate || null;
      if (Object.prototype.hasOwnProperty.call(newOrder, 'status')) updatedOrderObject.status = newOrder.status;
      if (Object.prototype.hasOwnProperty.call(newOrder, 'statusReason')) updatedOrderObject.statusReason = newOrder.statusReason;
      if (Object.prototype.hasOwnProperty.call(newOrder, 'statusComments')) updatedOrderObject.statusComments = newOrder.statusComments;
      if (Object.prototype.hasOwnProperty.call(newOrder, 'paymentMethodId') && !restrictPaymentStatuses.includes(values.status)) updatedOrderObject.paymentMethodId = newOrder.paymentMethodId;
      
      const valueParams = ['expectedDeliveryDate','status','statusReason','statusComments','paymentMethodId','billingAddress','shippingAddress'];
      const validateNote = currentOrder?.notes !== updatedOrderObject?.notes;

      const validateDataUpdate = (valueParamsArray, dataOne, dataTwo) => {
        if(Object.prototype.hasOwnProperty.call(dataOne?.billingAddress, 'locality')) Reflect.deleteProperty(dataOne?.billingAddress, 'locality');
        if(Object.prototype.hasOwnProperty.call(dataOne?.shippingAddress, 'locality')) Reflect.deleteProperty(dataOne?.shippingAddress, 'locality');
        const validate = valueParamsArray?.some((value)=> {
          if(typeof dataOne?.[value] === 'object' && typeof dataTwo?.[value] === 'object') {
            if(Object.keys(dataTwo?.[value])?.length) return Object.keys(dataTwo?.[value])?.some((valueParamObject)=> dataOne?.[value]?.[valueParamObject] !== dataTwo?.[value]?.[valueParamObject]);
          }else{
            return dataOne?.[value] && dataTwo?.[value] && dataOne?.[value] !== dataTwo?.[value];
          }
        });
        return validate;
      };
      
      const currentStatus = getCurrentStatus(values.status);
      const validateStatusUpdate = orderUpdateStatus || commissionUpdateStatus || transactionUpdateStatus ||
      (currentStatus && (currentStatus?.reasons?.length > 0 || (currentStatus?.comments && values.statusComments)) && (currentStatus?.comments !== values.statusComments));
      
      let responsiveUpdateOrder = null;

      if((validateDataUpdate(valueParams, currentOrder, updatedOrderObject) || validateNote) || validateStatusUpdate){

        if((validateDataUpdate(valueParams, currentOrder, updatedOrderObject) || validateNote)) responsiveUpdateOrder = await crudAction(updateOrder.bind(null, currentOrder.id, updatedOrderObject), onClose,
        { ...crudOptions, action: actions.UPDATE }, t
        );
                
        // If a status or reason/comment changed
        if (validateStatusUpdate) {
        let data = {};
        if (currentStatus?.reasons?.length && disableStatus) data.orderStatusReason = values.statusReason || statuses?.orderReasons[values.status][0].value;
        if (currentStatus?.comments && currentStatus?.reasons?.length && disableStatus) data.orderStatusComments = values.statusComments;
        // if reason or comments changed, update order status
        if ((currentStatus?.reasons?.length || currentStatus?.comments || orderUpdateStatus) && disableStatus) data.orderStatus = orderUpdateStatus || values.status;
        if (commissionUpdateStatus) data.commissionStatus = commissionUpdateStatus;
        if (transactionUpdateStatus) data.paymentStatus = transactionUpdateStatus;
        if (Object.keys(data).length && responsiveUpdateOrder || validateStatusUpdate) {
          data = { ...data, orders: [currentOrder.id] };
          
          await crudAction(updateOrderStatusesBulk.bind(null, data),
          onClose, {
            
            name: '',
            module: { ...modules(t).status, name: 'Orders Status' },
            action: actions.UPDATE
          }, t, skipPartialCrudUpdatesSuccessNotification);
        }
      }
      }else{
        onClose();
      }
    }
  };

  const processDealsErrors = (deals, errors) => {
    const errorMessages = [];
    let errorMessagesResult = null;

    if (deals) {
      errors.forEach((error, index) => {
        if (error) {
          if (error.expiresAt) {
            let name = '';
            if (deals[index] && deals[index].name) name = deals[index].name;
            errorMessages.push(t('dealExpired', { name }));
          }
          if (error.quantity) {
            let name = '';
            if (deals[index] && deals[index].name) name = deals[index].name;
            errorMessages.push(t('dealOutOfStock', { name }));
          }
        }
      });

      errorMessagesResult = `${errorMessages.join(', ') }.`;
    };


    return errorMessagesResult;
  };

  const getOptionsSelect = (data) => {
    
    let optionsSelect = null;

    if (data){
      optionsSelect = data.map((item, index) => ({
        key: index,
        value: item.code,
        label: item.name
      }));
    };

    return optionsSelect;
  };

  const getDealTotalPrice = (deal) => {
    let totalPrice = 0;
    totalPrice += (socioBuys ? deal.discountPrice : deal.price) * (currentQuantities[deal.id]);
    return totalPrice;
  };

  const getTotalPrice = () => {
    let totalPrice = 0;
    currentDeals.forEach(deal => {
      totalPrice += getDealTotalPrice(deal);
    });
    return totalPrice;
  };

  const getDealTotalCommission = (deal) => {
    let totalCommission = 0;
    if (socioBuys) return totalCommission;

    if (currentQuantities[deal.id] > 0) {
      totalCommission += (deal.price - deal.discountPrice) * (currentQuantities[deal.id]);
    }
    return totalCommission;
  };
  
  const getTotalCommission = () => {
    let totalCommission = 0;
    currentDeals.forEach(deal => {
      totalCommission += getDealTotalCommission(deal);
    });
    return totalCommission;
  };


  const editDealStatus = (index, type) => {
    if (type !== 'price' && type !== 'discount' && type !== 'commissionPercentage') return;
    const updated = { ...dealsEditStatus };
    if (!updated[index]) updated[index] = { price: false, discount: false, commissionPercentage: false };
    updated[index][type] = !(updated[index][type]);
    setDealsEditStatus(updated);
  };

  const renderAsset = (fileUrl, fileType) => {
    if (fileUrl && Array.isArray(fileUrl) && fileUrl.length > 0) {

     const newFileUrl = fileUrl;
      fileUrl.map((file)=>{
        if(file.source === "zoho"){
          fileUrl = file.url;
        }else if(file.type === "product" && file.source === "manager"){
          fileUrl = newFileUrl.find(m => m.type === 'product')?.url;
        }
      });
      
      if (!fileType) fileType = getUrlExtension(fileUrl);
      if (!fileType) return;
      if (mimeTypes[fileType]) fileType = mimeTypes[fileType];
    }

    if (!fileUrl || Array.isArray(fileUrl)) {
      fileUrl = 'https://via.placeholder.com/50x50';
      fileType = 'image';
    }

    if (getAssetType(fileType) === 'application')
      return <embed width="100%" height="100%" src={fileUrl} />;

    if (getAssetType(fileType) === 'video')
      return <video width="100%" src={fileUrl} controls>
        Your browser does not support the video tag.
      </video>;
    if (getAssetType(fileType) === 'image') return <img className={themeStyles["deal-image-sm"]} src={fileUrl} />;
    return "";
  };



  const updateSingleValue = (index, value, setFunc, oldValues) => {
    const newValue = { ...oldValues };
    newValue[index] = value;
    setFunc(newValue);
  };

  const updatePrice = (dealId, deals) => {
    const i = deals.findIndex(deal => deal.id === dealId);
    const dealsCopy = [...deals];
    dealsCopy[i].price = Number.parseFloat(tempPrices[dealId]);
    dealsCopy[i].discountPrice = toFloat(dealsCopy[i].price - parseFloat((dealsCopy[i].price * ((dealsCopy[i].commissionPercentage / 100)))));
    return dealsCopy;
  };

  const updateCommissionPercentage = (dealId, deals) => {
    const i = deals.findIndex(deal => deal.id === dealId);
    const dealsCopy = [...deals];
    dealsCopy[i].commissionPercentage = parseFloat(tempCommissionPercentages[dealId]);
    dealsCopy[i].discountPrice = toFloat(dealsCopy[i].price - parseFloat((dealsCopy[i].price * ((dealsCopy[i].commissionPercentage / 100)))));
    return dealsCopy;
  };

  const okPriceButton = (deal, deals, dealsSetFunc) => {
    if (tempPrices[deal.id] >= deal.discountPrice) {
      const updatedPriceDeals = updatePrice(deal.id, deals);
      dealsSetFunc("deals", updatedPriceDeals);
      setCurrentDeals(updatedPriceDeals);
      editDealStatus(deal.id, 'price');
    }
  };

  const getPriceCell = (deal, deals, dealsSetFunc) => {
    const disabledPriceConfirm = tempPrices[deal.id] < deal.discountPrice;
    return dealsEditStatus[deal.id]?.price ? // If editing discount
      <div className={orderStyles["editable-field"]}>
        <span>
          <Field autoFocus type="number" step="any" min="0.01" className={`${themeStyles["form-control"]} ${orderStyles["edit-price"]}`}
            name={`price_${deal.id}`} placeholder={placeholder.tempPrice}
            value={parseFloat(tempPrices[deal.id])}
            onChange={(e) => {
              // Set new temp price
              const value = e?.target?.value ? Number.parseFloat(e?.target?.value) : undefined;
              updateSingleValue(deal.id, value, setTempPrices, tempPrices);
            }}
            onKeyDown={(e) => { if (e.key === 'Enter') okPriceButton(deal, deals, dealsSetFunc); }}
            autoComplete="new-password" /></span>
        <span>
          <Icon
            color={disabledPriceConfirm ? "#f00" : "#000"} size={13} icon="done_mini-" className={orderStyles["order-info-icon"]} disabled={disabledPriceConfirm}
            onClick={() => { okPriceButton(deal, deals, dealsSetFunc); }}
          />
        </span>
        <span className={orderStyles["close-price"]}>
          <Icon
            color="#000" size={13} icon="close-" className={orderStyles["order-info-icon"]}
            onClick={editDealStatus.bind(null, deal.id, 'price')}
          />
        </span>
      </div>
      :
      <div>
        <div className={orderStyles["editable-field"]}>
          <span className={orderStyles["product-discount"]}>{formatCurrency(socioBuys ? deal.discountPrice : deal.price, deal.currency)}</span>
          {currentOrder || socioBuys ? "" :
            <span>
              <i><Icon
                color="#000" size={13} icon="edit_cover-" className={orderStyles["order-info-icon"]}
                onClick={() => {
                  tempPrices[deal.id] = deal.price;
                  editDealStatus(deal.id, 'price');
                }}
              /></i>
            </span>
          }
        </div>
        {/* <div className={orderStyles["sm-base-price"]}>
          ({formatCurrency(deal.discountPrice, deal.currency)})
        </div> */}
      </div>;
  };

  const okCommissionButton = (deal, deals, dealsSetFunc) => {
    const updatedCommissionPercentageDeals = updateCommissionPercentage(deal.id, deals);
    dealsSetFunc("deals", updatedCommissionPercentageDeals);
    setCurrentDeals(updatedCommissionPercentageDeals);
    editDealStatus(deal.id, 'commissionPercentage');
  };

  const getCommissionPercentageCell = (deal, deals, dealsSetFunc) => dealsEditStatus[deal.id]?.commissionPercentage ? // If editing commission percentage
      <div className={orderStyles["editable-field"]}>
        <span>
          <Field autoFocus type="number" className={`${themeStyles["form-control"]} ${orderStyles["edit-commission"]}`}
            name={`commissionPercentage_${deal.id}`} placeholder={placeholder.tempCommissionPercentage}
            value={parseFloat(tempCommissionPercentages[deal.id])}
            onChange={(e) => {
              if (!e?.target?.value || Number.parseFloat(e?.target?.value) >= 0) {
                // Set new temp commissionPercentage
                const value = e?.target?.value ? Number.parseFloat(e?.target?.value) : undefined;
                updateSingleValue(deal.id, value, setTempCommissionPercentages, tempCommissionPercentages);
              }
            }}
            autoComplete="new-password" onKeyDown={(e) => { if (e.key === 'Enter') okCommissionButton(deal, deals, dealsSetFunc); }}
          />
        </span>
        <span>
          <Icon
            color="#000" size={13} icon="done_mini-" className={orderStyles["order-info-icon"]}
            onClick={() => { okCommissionButton(deal, deals, dealsSetFunc); }}
          />
        </span>
        <span className={orderStyles["close-price"]}><Icon color="#000" size={13} icon="close-" className={orderStyles["order-info-icon"]} onClick={editDealStatus.bind(null, deal.id, 'commissionPercentage')} /></span>
      </div>
      :
      <div>
        <span className={orderStyles["product-commission"]}>{parseFloat(socioBuys ? 0 : deal.commissionPercentage)}%</span>
        {currentOrder || socioBuys ? "" :
          <span >
            <i><Icon
              color="#000" size={13} icon="edit_cover-" className={orderStyles["order-info-icon"]}
              onClick={() => {
                tempCommissionPercentages[deal.id] = deal.commissionPercentage;
                editDealStatus(deal.id, 'commissionPercentage');
              }}
            /></i>
          </span>
        }
        <br />
        {!socioBuys && formatCurrency(deal.commission, deal.currency)}
      </div>;


  const getQuantitySelector = (deal) => {
    let maxQuantity = Number.MAX_SAFE_INTEGER;
    if (Object.prototype.hasOwnProperty.call(deal, 'currentQuantity') && deal.currentQuantity !== null && deal.currentQuantity < maxQuantity) maxQuantity = deal.currentQuantity;
    return <Grid key={deal.id} container spacing={0}>
      <Grid item xs={12}>
        <div className={orderStyles["product-quantity-section"]}>
          <div className={orderStyles["quantity-btn"]}>
            <button className={orderStyles["qty-dec-btn"]} onClick={(e) => {
              e.preventDefault();
              if (currentQuantities[deal.id] > 1) {
                updateSingleValue(deal.id, Number.parseInt(currentQuantities[deal.id], 10) - 1, setCurrentQuantities, currentQuantities);
              }
            }} disabled={deal.currentQuantity === 0 || currentOrder}>
              <div className={orderStyles["arrow-down"]} />
            </button>
            <Field className={orderStyles["qty-input"]} onChange={(e) => {
              let newVal = e.target.value;
              if (newVal > maxQuantity) newVal = maxQuantity;
              if (newVal < 1) newVal = 1;
              updateSingleValue(deal.id, Number.parseInt(newVal, 10), setCurrentQuantities, currentQuantities);
            }} name={`quant_${ deal.id}`} value={currentQuantities[deal.id] || 1} disabled={deal.currentQuantity === 0 || currentOrder} />

            <button className={orderStyles["qty-inc-btn"]} onClick={
              (e) => {
                e.preventDefault();
                // Avoid going above deal's set iventory
                if (currentQuantities[deal.id] === maxQuantity) return;
                updateSingleValue(deal.id, Number.parseInt(currentQuantities[deal.id], 10) + 1, setCurrentQuantities, currentQuantities);
              }
            } disabled={deal.currentQuantity === 0 || currentOrder}>
              <div className={orderStyles["arrow-up"]} />
            </button>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        {deal.currentQuantity ? <span className={orderStyles["product-quantity"]}>{` (Max ${maxQuantity})`}</span> : ''}
        {deal.currentQuantity === 0 ? <span className={orderStyles["product-out-of-stock"]}>{`(${t("Out of Stock")})`}</span> : ''}
      </Grid>
    </Grid>;
  };

  const adaptCustomerData = (customerData) => {
    if (!customerData) return {};
    const adaptedCustomer = {
      customerFirstName: customerData?.firstName,
      customerLastName: customerData?.lastName,
      customerPhone: customerData?.phone,
      customerEmail: customerData?.email || '',
      customerCountry: customerData?.country,
      customerDocument: customerData?.document || '',
      customerDocumentType: customerData?.documentType || '',
    };
    if (customerData?.addresses && Array.isArray(customerData?.addresses) && customerData?.addresses.length > 0) {
      const currentAddresses = customerData?.addresses;
      currentAddresses.forEach(address => {
        address.fullAddress = address.address1;
        if (address.address2 === null) address.address2 = '';
        if (address.region === null) address.region = '';
        if (address.description === null) address.description = '';
        if (address.zipCode === null) address.zipCode = '';
      });
      setCurrentBuyerAddresses(currentAddresses);
      if (currentAddresses && currentAddresses.length > 0) {
        adaptedCustomer.shippingAddress = currentAddresses[0];
        if (customerData.sameAddressCheck || currentAddresses.length < 2) {
          adaptedCustomer.billingAddress = { ...defaultAddress, ...currentAddresses[0] };
        } else {
          adaptedCustomer.billingAddress = { ...defaultAddress, ...currentAddresses[1] };
        }
      }
    }
    return adaptedCustomer;
  };


  const changeDocumentClick = async (fields) => {

    const putNewDocument = async (customer, payload) => {
      const result = await crudAction(
        updateUser.bind(null, customer, payload),
        () => { }, {
        name: '',
        module: {
          name: 'Document',
          gender: t('documentGender')
        },
        action: actions.UPDATE
      }, t);
      return result;
    };

    const customer = fields.userId;
    const payload = {
      document: fields.customerDocument,
      documentType: fields.customerDocumentType
    };
    const hasCustomer = (customer && customer !== "");
    if (!hasCustomer) { return toast.error(t("Missing customer selection")); }
    if (!changeDocument) { return setChangeDocument(true); }
    const documentUpdated = await putNewDocument(customer, payload);
    if (documentUpdated) setChangeDocument(false);
  };

  const setCustomerField = (values, setFieldTouched, setValues, customerData) => {
    setFieldTouched('userId', true);
    if (!customerData) setSelectedBuyer(null);
    else setSelectedBuyer({ label: formUserLabel(customerData), ...customerData });
    setValues({
      ...values,
      ...defaultEmptyCustomer,
      ...adaptCustomerData(customerData),
      userId: customerData?.id
    });
  };

  const validatefreeMethod = paymentMethodOptions?.length === 1 && paymentMethodOptions[0].type === "FREE";

  return (
    (
      loaderEnabled ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", padding: "200px" }}>
        <CircularProgress />
      </div>
        :
        <div>
          <ModalCloseButton onClick={() => onClose(true)} floating />
          <Grid container spacing={0} alignItems="flex-start" style={{ marginTop: "-30px" }}>
            <Grid item xs>
              <div className={themeStyles.currentContent}>
                <div className={orderStyles["order-view"]}>
                  <header className={themeStyles["header-form"]}>
                    <h3>{!currentOrder ? t('Create Order') : `#${currentOrder.orderId}`}</h3>
                  </header>
                  <div className={themeStyles["form-wrap"]}>
                    <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formOnSubmit} validationSchema={OrderSchema}>
                      {({ isSubmitting, values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, setValues }) => (
                        <Form>
                          {
                            !currentOrder ? (
                              <div />
                            ) : (
                              <div>
                                <div className={themeStyles["date-created"]}>
                                  <b>{t('Created at')}: &nbsp;</b> {` ${new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentOrder.created_at))}`}
                                </div>
                                <div className={themeStyles["date-updated"]}>
                                  <b>{t('Updated at')}: &nbsp;</b> {` ${new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentOrder.updated_at))}`}
                                </div>
                                <div className={themeStyles["form-group"]}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                      <label htmlFor="transactionStatus" className={themeStyles["col-form-label"]}>{t('Payment Status')}:</label>
                                      <SearchSelect
                                        onChange={e => e ? setFieldValue('transactionStatus', e.value) : setFieldValue('transactionStatus', '')}
                                        value={values.transactionStatus} onBlur={() => setFieldTouched('transactionStatus', true)}
                                        options={statuses?.paymentStatusOptions} placeholder={placeholder.transactionStatus}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <label htmlFor="commissionStatus" className={themeStyles["col-form-label"]}>{t('Commission Status')}:</label>
                                      <SearchSelect
                                        onChange={e => e ? setFieldValue('commissionStatus', e.value) : setFieldValue('commissionStatus', '')}
                                        value={ values.commissionStatus} onBlur={() => setFieldTouched('commissionStatus', true)}
                                        options={statuses?.commissionStatusOptions}
                                        placeholder={placeholder.commissionStatus}
                                        disabled={disableOptionsSelect(values.transactionStatus)}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <label htmlFor="status" className={themeStyles["col-form-label"]}>{t('Order Status')}:</label>
                                      <SearchSelect highlightGroups onChange={e => {
                                        e ? setFieldValue('status', e.value) : setFieldValue('status', '');
                                        e?.value && statuses?.orderReasons?.[e.value]?.[0]?.value ? setFieldValue('statusReason', statuses?.orderReasons?.[e.value]?.[0]?.value) : '';
                                      }}
                                        value={values.status} onBlur={() => setFieldTouched('status', true)}
                                        options={statuses?.orderStatusOptions} placeholder={placeholder.status}
                                        disabled={disableOptionsSelect(values.transactionStatus)}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                                {/* Reasons */}
                                {
                                  (hasOrderReasons || hasCommissionReasons || hasTransactionReasons) && (
                                    <div className={themeStyles["form-group"]}>
                                      <Grid container spacing={1}>
                                        <Grid item xs={4} />
                                        <Grid item xs={4} />
                                        {
                                          hasOrderReasons && values.status && statuses?.orderReasons[values.status] &&
                                          <Grid item xs={4}>
                                                <label htmlFor="status" className={themeStyles["col-form-label"]}>
                                                  {reasonInsteadOfSubstastus.includes(values.status) ? t('Reason') : t('Substatus')}:
                                                </label>
                                            
                                            <SearchSelect
                                              onChange={e => e ? setFieldValue('statusReason', e.value) : setFieldValue('statusReason', '')}
                                              value={values.statusReason} onBlur={() => setFieldTouched('status', true)}
                                              placeholder={t("Order substatus")}
                                              options={statuses?.orderReasons[values.status]}
                                              disabled={disableOptionsSelect(values.transactionStatus)}
                                            />
                                          </Grid>
                                        }
                                      </Grid>
                                    </div>
                                  )
                                }
                                {
                                  ((hasOrderReasons || hasCommissionReasons || hasTransactionReasons) && getCurrentStatus(values?.status)?.comments) ? (
                                    <div className={themeStyles["form-group"]}>
                                      <Grid container spacing={1}>
                                        <Grid item xs={4} />
                                        <Grid item xs={4} />
                                        {
                                          hasOrderReasons && values.status && statuses?.orderReasons[values.status] &&
                                          <Grid item xs={4}>
                                            <label htmlFor="description" className={themeStyles["col-form-label"]}>{t("Comments")}</label>
                                            <Field rows="4" as="textarea" className={themeStyles["form-control"]} name="statusComments" placeholder={placeholder.statusComments} value={values.statusComments}
                                            autoComplete="off"
                                            disabled={disableOptionsSelect(values.transactionStatus)}
                                            />
                                          </Grid>
                                        }
                                      </Grid>
                                    </div>
                                  )
                                  : ""}
                              </div>
                            )}

                          <div className={orderStyles["order-title"]}>
                            {t('Tendero Information')}
                          </div>
                          <div className={themeStyles["form-group"]}>
                            <div className={`${themeStyles["form-order"]} ${themeStyles.searchSelectGroup}`}>
                              <label htmlFor="referrer" className={themeStyles["col-form-label"]}>{t('Tendero Name')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                              <span className={themeStyles["search-description"]}>{t('referrerSearchDescription')}</span>
                              <SearchSelect
                                disabled={currentOrder}
                                onChange={field => {
                                  setCurrentTendero(field);
                                  setFieldValue('referrer', field?.object?.id);
                                  if (socioBuys) setCustomerField(values, setFieldTouched, setValues, field?.object);
                                  else if ((field?.object?.id === values.userId) && !socioBuys) {
                                    setSocioBuys(true);
                                    setCustomerField(values, setFieldTouched, setValues, field?.object);
                                  }
                                }}
                                onBlur={() => setFieldTouched('referrer', true)}
                                loadOptions={returnTenderos}
                                defaultOptions={currentTendero ? [currentTendero] : null}
                                placeholder={placeholder.referrer}
                                isOptionDisabled={(option) => option.disabled}
                                isClearable
                                value={values.referrer}
                                cacheOptions
                              />
                              {errors.referrer && touched.referrer ? (<div className={themeStyles.formError}>{errors.referrer}</div>) : ' '}
                            </div>
                            {
                              currentOrder ? '' :
                                <div className={`${themeStyles["form-group"]} ${orderStyles["socio-buys-checkbox"]}`} >
                                  <label>
                                    <input
                                      type="checkbox" name="socioBuys" checked={socioBuys} disabled={currentOrder}
                                      onChange={(e) => {
                                        setSocioBuys(e?.target?.checked);
                                        if (e?.target?.checked) setCustomerField(values, setFieldTouched, setValues, currentTendero?.object);
                                      }}
                                    />
                                    <span className={orderStyles.label}>{t('Socio is the final customer')}</span>
                                    <span className={orderStyles.description}>({t('socioBuysDesc')})</span>
                                  </label>
                                </div>
                            }
                            <div className={`${themeStyles["form-order"]} ${themeStyles.searchSelectGroup}`}>
                              <label htmlFor="deals" className={themeStyles["col-form-label"]}>{t('Deal', { count: 100 })}<span className={themeStyles["label-asterisk"]}>*</span></label>
                              <span className={orderStyles["search-description"]}>{t('dealsSearchDescription')}</span>
                              <SearchSelect
                                disabled={currentOrder}
                                onChange={field => {
                                  let res = [];
                                  if (field) res = field.map((deal) => ({ ...deal, ...deal.object }));
                                  setFieldValue('deals', res);
                                  setCurrentDeals(res);
                                }}
                                isMulti value={values.deals}
                                onBlur={() => setFieldTouched('deals', true)}
                                loadOptions={returnDeals}
                                cacheOptions
                                placeholder={placeholder.deals} isOptionDisabled={(option) => option.disabled}
                              />
                              {errors.deals && touched.deals ? (<div className={themeStyles.formError}>{
                                Array.isArray(errors.deals) ? processDealsErrors(values.deals, errors.deals) : errors.deals
                              }</div>) : ' '}
                            </div>
                            {!tableRows || !tableRows.length || tableRows.length < 1 ? ("") : (
                              <div>
                                <TableContainer className={orderStyles["products-table-cell"]}>
                                  <Table aria-label="Deals Table">
                                    <TableHead className={orderStyles["products-hr"]}>
                                      <TableRow>
                                        <TableCell align="center">{t("Media")}</TableCell>
                                        <TableCell align="center">{t("Name")}</TableCell>
                                        <TableCell align="center">{t("consumerPrice")}</TableCell>
                                        <TableCell align="center">{t("Base Commission")}</TableCell>
                                        <TableCell align="center">{t("Quantity")}</TableCell>
                                        <TableCell align="center">{t("Total Commission")}</TableCell>
                                        <TableCell align="center">{t("Total")}</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {tableRows.map((row, index) => (
                                        <TableRow key={row.id} className={errors.deals && errors.deals.length && errors.deals[index] !== null ? orderStyles["deal-error-row"] : ""}>
                                          <TableCell component="th" scope="row">
                                            <div className={orderStyles["product-image-sm"]}>{renderAsset(row.media)}</div>
                                          </TableCell>
                                          <TableCell>
                                            <Grid container>
                                              <Grid item xs={12}>
                                                {row.name}
                                              </Grid>
                                              {!row.sku ? '' :
                                                <Grid item xs={12}>
                                                  <span className={themeStyles["table-list-phone"]}>{row.sku} </span>
                                                </Grid>
                                              }
                                            </Grid>
                                          </TableCell>
                                          {/* <TableCell align="center" className={orderStyles["product-discount"]}>{getDiscountPriceCell(row.id, formatCurrency(row.discountPrice, row.currency), values.deals, setFieldValue)}</TableCell> */}
                                          <TableCell align="center" >{getPriceCell(row, values.deals, setFieldValue)}</TableCell>
                                          <TableCell align="center" className={orderStyles["product-commission"]}>{getCommissionPercentageCell(row, values.deals, setFieldValue)}</TableCell>
                                          <TableCell align="center">{getQuantitySelector(row)}</TableCell>
                                          <TableCell align="center">{formatCurrency(getDealTotalCommission(row), row.currency)}</TableCell>
                                          <TableCell align="right">{formatCurrency(getDealTotalPrice(row), row.currency)}</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                {(!currentDeals || !currentDeals.length || currentDeals.length < 1 || !currentDeals[0].currency) ?
                                  ("") :
                                  <Grid container spacing={1} item xs={12} className={orderStyles["totals-section"]}>
                                    <div className={orderStyles["totals-labels"]}>
                                      <span className={orderStyles["product-commission-label"]}>{t('Total Commission')}: </span>
                                      <span className={orderStyles["product-total-label"]}>{t('Total')}: </span>
                                    </div>
                                    <div className={orderStyles["totals-values"]}>
                                      <span className={orderStyles["product-commission-price"]}>{formatCurrency(getTotalCommission(), currentDeals[0].currency)}</span>
                                      <span className={orderStyles["product-total-price"]}>{formatCurrency(getTotalPrice(), currentDeals[0].currency)}</span>
                                    </div>
                                  </Grid>
                                }
                              </div>
                            )}

                          </div>
                          <div className={orderStyles["order-title"]}>
                            {t('Customer Information')}
                          </div>
                          <div>
                            <div>
                              <div className={themeStyles["form-group"]}>
                                <label htmlFor="customer" className={themeStyles["col-form-label"]}>{t("selectCustomerTitle")}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                <span className={orderStyles["search-description"]}>{t('customerSearchDescription')}</span>
                                <SearchSelect
                                  value={values.userId}
                                  disabled={currentOrder || socioBuys}
                                  onChange={field => {
                                    if (!field) {
                                      setValues(prevValues => ({
                                        ...prevValues,
                                        ...defaultEmptyCustomer,
                                        userId: -2
                                      }));
                                      setSelectedBuyer(null);
                                      setShippingResetCounter(shippingResetCounter + 1);
                                      setBillingResetCounter(billingResetCounter + 1);
                                    } else if (field.value === -1) {
                                        setValues(prevValues => ({
                                          ...prevValues,
                                          ...defaultEmptyCustomer,
                                          userId: field.value
                                        }));
                                        setShippingResetCounter(shippingResetCounter + 1);
                                        setBillingResetCounter(billingResetCounter + 1);
                                      } else if (field.value === values.referrer) {
                                          setCustomerField(values, setFieldTouched, setValues, currentTendero?.object);
                                          setSocioBuys(true);
                                        } else {
                                          const customerData = buyersList.find(buyer => buyer.id === field.value);
                                          setCustomerField(values, setFieldTouched, setValues, customerData);
                                        }
                                  }}
                                  onBlur={() => setFieldTouched('userId', true)}
                                  loadOptions={returnBuyers}
                                  defaultOptions={defaultCustomerOptions}
                                  placeholder={t('Search terms')}
                                  isOptionDisabled={(option) => option.disabled}
                                  isClearable
                                  cacheOptions
                                />
                                {errors.userId && touched.userId ? (<div className={themeStyles.formError}>{errors.userId}</div>) : ' '}
                              </div>
                            </div>
                            <Grid container spacing={1} className={themeStyles["form-group"]}>
                              <Grid item xs={6}>
                                <label htmlFor="customerFirstName" className={themeStyles["col-form-label"]}>{t('First Name')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                <Field className={themeStyles["form-control"]} name="customerFirstName" placeholder={placeholder.customerFirstName} value={values.customerFirstName} onChange={handleChange} onBlur={handleBlur} disabled={values.userId !== -1} autoComplete="new-password" />
                                {errors.customerFirstName && touched.customerFirstName ? (<div className={themeStyles.formError}>{errors.customerFirstName}</div>) : ' '}
                              </Grid>
                              <Grid item xs={6}>
                                <label htmlFor="customerLastName" className={themeStyles["col-form-label"]}>{t('Last Name')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                <Field className={themeStyles["form-control"]} name="customerLastName" placeholder={placeholder.customerLastName} value={values.customerLastName} onChange={handleChange} onBlur={handleBlur} disabled={values.userId !== -1} autoComplete="new-password" />
                                {errors.customerLastName && touched.customerLastName ? (<div className={themeStyles.formError}>{errors.customerLastName}</div>) : ' '}
                              </Grid>
                            </Grid>
                            <Grid container spacing={1} className={themeStyles["form-group"]}>
                              <Grid item xs={6}>
                                <label htmlFor="customerPhone" className={themeStyles["col-form-label"]}>{t('Phone Number')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                <PhoneInputEnforcer prefix="+" masks={phoneMasks}
                                  country={initialCountry?.toLowerCase()}
                                  onlyCountries={countryCodes && countryCodes.length > 0 ? countryCodes : defaultCountries}
                                  value={values.customerPhone} onChange={pNumber => setFieldValue('customerPhone', pNumber)}
                                  disabled={values.userId !== -1} inputClass={themeStyles["phone-input"]}
                                  placeholder={placeholder.customerPhone} autoComplete="new-password"
                                  onBlur={() => setFieldTouched('customerPhone', true)} name="customerPhone"
                                  disableDropdown={initialCountry} countryCodeEditable={!initialCountry}
                                />
                                {errors.customerPhone && touched.customerPhone ? (<div className={themeStyles.formError}>{errors.customerPhone}</div>) : ' '}
                              </Grid>
                              <Grid item xs={6}>
                                <label htmlFor="customerEmail" className={themeStyles["col-form-label"]}>{t('Email Address')}</label>
                                <Field className={themeStyles["form-control"]} name="customerEmail" placeholder={placeholder.customerEmail} value={values.customerEmail} onChange={handleChange} onBlur={handleBlur} disabled={values.userId !== -1} autoComplete="new-password" />
                                {errors.customerEmail && touched.customerEmail ? (<div className={themeStyles.formError}>{errors.customerEmail}</div>) : ' '}
                              </Grid>
                            </Grid>
                            <div className={themeStyles["form-group"]}>
                              <label htmlFor="customerCountry" className={themeStyles["col-form-label"]}>{t('Country')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                              <SearchSelect
                                onChange={
                                  e => {
                                    setFieldValue('customerCountry', e?.value || '');
                                    setFieldValue('shippingAddress.country', e?.value || '');
                                    setFieldValue('billingAddress.country', e?.value || '');
                                  }
                                }
                                value={values.customerCountry} onBlur={() => setFieldTouched('customerCountry', true)}
                                options={getOptionsSelect(countryList, t)} placeholder={placeholder.customerCountry} disabled={values.userId !== -1 || initialCountry}
                              />
                              {errors.customerCountry && touched.customerCountry ? (<div className={themeStyles.formError}>{errors.customerCountry}</div>) : ' '}
                            </div>
                            <Grid container spacing={1} className={themeStyles["form-group"]}>
                              <Grid item xs={6}>
                                <label htmlFor="customerDocumentType" className={themeStyles["col-form-label"]}>{t('Document Type')}</label>
                                <SearchSelect
                                  onChange={e => e ? setFieldValue('customerDocumentType', e.value) : setFieldValue('customerDocumentType', '')}
                                  value={values.customerDocumentType} onBlur={() => setFieldTouched('customerDocumentType', true)}
                                  options={printDocumentType(t)} placeholder={placeholder.customerDocumentType}
                                  disabled={!changeDocument && values.userId !== -1} isClearable
                                />
                                {errors.customerDocumentType && touched.customerDocumentType ? (<div className={themeStyles.formError}>{errors.customerDocumentType}</div>) : ' '}
                              </Grid>
                              <Grid item xs={6}>
                                <label htmlFor="customerDocument" className={themeStyles["col-form-label"]}>{t('Document')}</label>
                                <Field className={themeStyles["form-control"]} name="customerDocument" placeholder={placeholder.customerDocument} value={values.customerDocument} onChange={handleChange} onBlur={handleBlur} disabled={!changeDocument && values.userId !== -1} autoComplete="new-password" />
                                {errors.customerDocument && touched.customerDocument ? (<div className={themeStyles.formError}>{errors.customerDocument}</div>) : ' '}
                              </Grid>
                            </Grid>
                            {
                              (values.userId && values.userId > 0)
                                ? <span className={orderStyles['link-change-field']} onClick={() => changeDocumentClick(values)}> {changeDocument ? 'Save document' : 'Click to update document'}</span>
                                : null
                            }

                          </div>

                          <div className={orderStyles["order-title"]}>
                            {t('Shipping Information')}
                          </div>
                          <Address key={shippingResetCounter} name="shippingAddress" country={values.customerCountry} disabled={!editableOrderStatuses?.includes(values.status) || values.userId === -2} />

                          <Grid container spacing={1} className={themeStyles["form-group"]}>
                            <Grid item xs={6}>
                              <label htmlFor="description" className={themeStyles["col-form-label"]}>{t("Notes")}</label>
                              <Field rows="4" as="textarea" className={themeStyles["form-control"]} name="notes" placeholder={placeholder.notes} value={values.notes}
                                autoComplete="off" />
                              {errors.notes && touched.notes ? (<div className={themeStyles.formError}>{errors.notes}</div>) : ' '}
                            </Grid>
                            <Grid item xs={3} className={orderStyles["expected-delivery-date"]}>
                              <label htmlFor="expectedDeliveryDate" className={themeStyles["col-form-label"]}>{t("Expected Delivery Date")}</label>
                              <DatePickerField className="dateTimePicker" dateFormat="yyyy-MM-dd hh:mm a" name="expectedDeliveryDate" placeholderText={placeholder.expectedDeliveryDate} showTimeSelect timeIntervals={1} autoComplete="off" showTime={{ user12hours: true }} />
                              {errors.expectedDeliveryDate && touched.expectedDeliveryDate ? (<div className={themeStyles.formError}>{errors.expectedDeliveryDate}</div>) : ' '}
                            </Grid>
                          </Grid>

                          <div className={orderStyles["order-title"]}>
                            {t('Billing Information')}
                          </div>
                          <div>
                            <div className={themeStyles["form-group"]}>
                              <label htmlFor="paymentType" className={themeStyles["col-form-label"]}>{t('Payment Type')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                              <SearchSelect
                                onChange={e => e ? setFieldValue('paymentType', e.value) : setFieldValue('paymentType', '')}
                                value={values.paymentType} onBlur={() => setFieldTouched('paymentType', true)}
                                options={paymentMethodOptions} placeholder={placeholder.paymentType}
                                disabled={restrictPaymentStatuses?.includes(currentOrder?.status) || validatefreeMethod}
                                menuPlacement={sameAddressCheck ? "top" : "auto"}

                              />
                              {errors.paymentType && touched.paymentType ? (<div className={themeStyles.formError}>{errors.paymentType}</div>) : ' '}
                              {
                                currentOrder?.externalPaymentUrl && canCopy &&
                                <div className={orderStyles['copy-to-clipboard-link']}>
                                <input type="text" value={currentOrder?.externalPaymentUrl}/>
                                <CopyToClipboard text={currentOrder?.externalPaymentUrl} onCopy={copySuccessHandler}>
                                <Tooltip title={t('URL copied to clipboard!')} open={tooltipOpen} onClose={onCloseHandler} disableFocusListener disableTouchListener>
                                  <div className={orderStyles['copy-to-clipboard-link__button']}>
                                    {tooltipOpen ? <Icon color="#1a7f37" size={13} icon="checkmark"/> : <Icon color="#000" size={13} icon="content_copy"/>}
                                  </div>
                                </Tooltip>
                                </CopyToClipboard>
                                </div>
                              }
                            </div>

                            <div className={`${themeStyles["form-group"]} ${orderStyles["order-checkbox"]}`} >
                              <label>
                                <input type="checkbox" name="sameAddressBilling" checked={sameAddressCheck} onChange={
                                  (e) => { setSameAddressCheck(e.target.checked); }
                                } disabled={currentOrder} />
                                {t('Use same shipping address for billing')}
                              </label>
                            </div>

                            {sameAddressCheck ? ("") : (<Address name="billingAddress" country={values.customerCountry} disabled={!editableOrderStatuses?.includes(values.status) || values.userId === -2} />)}
                          </div>
                          <div className={`${themeStyles["form-group"]} submit-div ${themeStyles["form-crud-actions"]}`}>
                            <button type="submit" className={`${themeStyles.btn} ${themeStyles["btn-secondary"]}`} disabled={isSubmitting} onClick={() => {
                              // eslint-disable-next-line no-console
                              console.log({ errors });
                              setFieldValue('type', submitType.update);
                            }} onMouseDown={(event) => { event.preventDefault(); }}>{
                                // eslint-disable-next-line no-nested-ternary
                                isSubmitting ? `${t('Please Wait')}...` : (!currentOrder ? t('Create') : t('Update'))
                              }</button>
                          </div>
                          <FormikErrorFocus offset={0} align="top" duration={1000} />
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>)
  );
};

export default withTranslation()(OrderDetails);