import { getConfig } from '../../../../config';

let api;
const checkConfig = () => {
  if (!api) api = getConfig('api');
};

/**
 {string} message Deal message
 {string} name Deal name
 {Array} numbers Deal numbers
 */
export const getDeals = (query, getExpiredDeals) => {
  let queryObj = query;
  if (getExpiredDeals) {
    queryObj = {
      ...query,
      hasExpired: true
    };
  }
  checkConfig();
  return api.get(`/deals`, queryObj);
};

/**
 * Creates a deal
 * @param {object} data
 * data.name {string} Deal name
 * data.message {string} Deal message
 * data.numbers {Array} Tenderos phone numbers
 */
export const postDeals = (data) => {
  checkConfig();
  return api.post('/deals', data);
};

/**
 * Get the deal with the given id
 * @param {string} id id of the deal to get
 */
export const getDeal = (id) => {
  checkConfig();
  return api.get(`/deals/${id}`);
};

/**
 * Update the deal with the given id
 * @param {string} id id of the deal to update
 * @param {object} data
 * data.name {string} Deal name
 */
export const updateDeal = (id, data) => {
  checkConfig();
  return api.put(`/deals/${id}`, data);
};

/**
 * Update the deal status with the given id
 * @param {string} id id of the deal to update
 * @param {object} data
 * data.name {string} Deal name
 * { deals: [ { id: number, status: string } ] }
 */
export const updateDealStatus = (id, data) => {
  checkConfig();
  return api.put(`/deals/updateStatus/${id}`, data);
};

/**
 * Delete the deal with the given id
 * @param {string} id id of the deal to delete
 */
export const deleteDeal = (id) => {
  checkConfig();
  return api.delete(`/deals/${id}`);
};

/**
 * Get the zoho deals with the given country
 * @param {string} id id of the deal to delete
 */
 export const getZohoDeals = (country) => {
  checkConfig();
  return api.get(`/deals/zohoProducts/${country}`);
};

/**
 * Get the deal's categories
 */
 export const getDealsCategories = () => {
  checkConfig();
  return api.get('/deals/categories');
};

export const getCategories = (query) => {
  checkConfig();
  return api.get(`/deals/categories`, query);
};


/**
 *
 * @param {} data
 * {
  "message": "",
  "numbers": [],
  "imageUrl": ""
 */
export const createBlast = (data) => {
  checkConfig();
  return api.post('/deals/blast', data);
};

export const importDeals = (data) => {
  checkConfig();
  return api.post('/deals/importDeals', data);
};
