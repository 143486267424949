// import api from './api-engine';
import api from './api';

/**
{string} message Products message
{string} name Products name
{Array} numbers Products numbers
*/
export const getProducts = (query) => api.get(`/engine/products`, query);


/**
 * Get the blast with the given id
 * @param {string} id id of the products to get
 */
export const getProduct= (id) => api.get(`/engine/products/${id}`);


/**
{string} message Categories message
{string} name Categories name
{Array} numbers Categories numbers
*/
export const getCategories = (query) => api.get(`/engine/categories`, query);


/**
{string} message Keywords message
{string} name Keywords name
{Array} numbers Keywords numbers
*/
export const getKeywords = (query) => api.get(`/engine/keywords`, query);

/**
{string} message Categories message
{string} name Categories name
{Array} numbers Categories numbers
*/
export const getTrendingCategories = (query) => api.get(`/engine/categories/trending`, query);

/**
{string} message Keywords message
{string} name Keywords name
{Array} numbers Keywords numbers
*/
export const getTrendingKeywords = (query) => api.get(`/engine/keywords/trending`, query);


export const createProduct = (data) => api.post("/engine/products/add", data);

export const watchProduct = (producId, data) => api.put(`/engine/products/${producId}`, data);
