import BlastActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case BlastActions.fetchBlastsRequest().type:
      return state.merge({
        blastsLoading: true,
        blastsError: null,
        blastsLoaded: false,
      });

      case BlastActions.fetchBlastsSuccess().type:
        return state.merge({
          blastsLoading: false,
          blastsError: null,
          blastsLoaded: true,
          blasts: action.payload.blasts.data || state.blasts,
        });

    default:
      return state;
  }
};
