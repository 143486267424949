import Immutable from 'seamless-immutable';

const initialState = Immutable({
  categoriesInit: true,
  categoriesLoading: false,
  categoriesError: null,
  categoriesLoaded: false,
  categories: null,

  categoriesTrendingLoading: false,
  categoriesTrendingError: null,
  categoriesTrendingLoaded: false,
  categoriesTrending: null,

  keywordsInit: true,
  keywordsLoading: false,
  keywordsError: null,
  keywordsLoaded: false,
  keywords: null,

  keywordsTrendingLoading: false,
  keywordsTrendingError: null,
  keywordsTrendingLoaded: false,
  keywordsTrending: null,

  createCategoryLoading: false,
  createCategoryError: null,
  createdCategory: false,
});


export default initialState;
