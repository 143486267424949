import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContainerUsersListing from '../../components/shared-comp/users/views/listing';
import UsersActions from '../../components/shared-comp/users/model/actions';
import WorkflowActions from '../../redux/workflow/actions';

const mapStateToProps = (state) => ({
  usersLoading: state.users.usersLoading,
  usersError: state.users.usersError,
  usersCalled: state.users.usersCalled,
  users: state.users.users,
  createUserLoading: state.users.createUserLoading,
  createUserError: state.users.createUserError,
  createdUser: state.users.createdUser,
  country: state.workflow.country,
  countries: state.workflow.countries,
});


const mapDispatchToProps = (dispatch) => ({
  createUser: bindActionCreators(UsersActions.createUser, dispatch),
  deleteUser: bindActionCreators(UsersActions.deleteUser, dispatch),
  fetchUsers: bindActionCreators(UsersActions.fetchUsers, dispatch),
  fetchUsersFailed: bindActionCreators(UsersActions.fetchUsersFailed, dispatch),
  fetchUsersRequest: bindActionCreators(UsersActions.fetchUsersRequest, dispatch),
  fetchUsersSuccess: bindActionCreators(UsersActions.fetchUsersSuccess, dispatch),
  getUser: bindActionCreators(UsersActions.getUser, dispatch),
  fetchCountries: bindActionCreators(WorkflowActions.fetchCountries, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerUsersListing);