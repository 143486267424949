import { getConfig } from '../../../../config';

let api;

const checkConfig = () => {
  if (!api) api = getConfig('api');
};

/**
 * Get the tendero with the given id
 * @param {string} id id of the tendero to get
 */
export const getGroup = (id) => {
  checkConfig();
  return api.get(`/groups/${id}`);
};

export const getGroups = (query) => {
  checkConfig();
  return api.get(`/groups`, query);
};

export const postGroups = (data) => {
  checkConfig();
  return api.post('/groups', data);
};

export const updateGroup = (id, data) => {
  checkConfig();
  return api.put(`/groups/${id}`, data);
};

/**
 * Delete the group with the given id
 * @param {string} id id of the group to delete
 */
export const deleteGroup = (id) => {
  checkConfig();
  return api.delete(`/groups/${id}`);
};