import { getConfig } from '../../../../config';

let api;

const checkConfig = () => {
  if (!api) api = getConfig('api');
};

export const getCustomers = (query) => {
  checkConfig();
  return api.get(`/users?type=customer`, query);
};

/**
 * Get the customer with the given id
 * @param {string} id id of the customer to get
 */
export const getCustomer = (id) => {
  checkConfig();
  return api.get(`/users/${id}`);
};


export const postCustomers = (data) => {
  data.type = 'customer';
  checkConfig();
  return api.post('/users', data);
};

export const updateCustomer = (id, data) => {
  checkConfig();
  return api.put(`/users/${id}`, data);
};

/**
 * Delete the customer with the given id
 * @param {string} id id of the customer to delete
 */
export const deleteCustomer = (id) => {
  checkConfig();
  return api.delete(`/users/${id}`);
};


/**
* Soft delete the tendero with the given id
* @param {string} id id of the tendero to delete
*/
export const softDeleteCustomer = (id) => {
  checkConfig();
  return api.put(`/users/status/${id}`, { status: 'deleted' });
};