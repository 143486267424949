import React from 'react';
import themeStyles from '../../../../theme.module.sass';
import BigNumber from 'bignumber.js';
import CurrencyFormat from 'react-currency-format';

const Price = ({ value, currency, percentage, percentageParenthesis = false, percentageSign = false, multiLine = false, valueClass = '', percentageClass = '', mainClass = '', sign = '$' }) => {
  const percentageSignToUse = percentage >= 0 ? '+' : '-';
  const updatedValue = BigNumber(value).decimalPlaces(2).toNumber();
  const hasDecimalPrice = value % 1 !== 0;
  
  return <div className={`${mainClass} ${themeStyles.flex} ${themeStyles.g4} ${themeStyles['flex-center']} ${multiLine ? themeStyles.column : ''}`}>
    <div className={`${themeStyles.flex} ${themeStyles.g4} ${valueClass}`}>
      <div>
        <CurrencyFormat isNumericString value={updatedValue} displayType='text' thousandSeparator decimalSeparator='.' decimalScale={2} fixedDecimalScale={hasDecimalPrice} prefix={sign} />
      </div>
      {currency ? <div>{currency}</div> : ''}
    </div>
    {percentage ? <div className={percentageClass}>
      {percentageParenthesis ? '(' : ''}
      {percentageSign ? percentageSignToUse : ''}{percentage}%
      {percentageParenthesis ? ')' : ''}
    </div> : ''}
  </div>;
};

export default Price;
