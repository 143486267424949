import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import log from './log';

export const usePrevious = value => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

const compareInputs = (inputKeys, oldInputs, newInputs) => {
  inputKeys.forEach(key => {
    const oldInput = oldInputs[key];
    const newInput = newInputs[key];
    if (oldInput !== newInput) {
      log.log("change detected", key, "old:", oldInput, "new:", newInput);
    }
  });
};

export const useDependenciesDebugger = inputs => {
  const oldInputsRef = useRef(inputs);
  const inputValuesArray = Object.values(inputs);
  const inputKeysArray = Object.keys(inputs);
  useMemo(() => {
    const oldInputs = oldInputsRef.current;

    compareInputs(inputKeysArray, oldInputs, inputs);

    oldInputsRef.current = inputs;
  }, inputValuesArray); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useRequest = (functionToRun, autorun = false) => {
  const [data, setData] = useState({});
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [requestFunction, setRequestFunction] = useState();

  const run = useCallback(async (...args) => {
    if (!functionToRun && !requestFunction) {
      log.error('Missing function to run');
      return {};
    }
    let tempResponse;
    let tempError;
    setLoading(true);
    try {
      setError({});
      tempResponse = requestFunction ? await requestFunction(...args) : await functionToRun(...args);
    } catch (err) {
      tempError = err;
      log.log('run -err', err);
    }
    setLoading(false);
    setError(tempError);
    setData(tempResponse?.data);
    setResponse(tempResponse);
    return tempResponse;
  }, [requestFunction, functionToRun]);

  useEffect(() => {
    if (autorun) run();
  }, [autorun, run]);

  return { data, loading, error, response, run, setRequestFunction };
};

// Hook to check if the user is hidden the screen
export const useHiddenScreen = ({
  externalRef,
  once = true
}) => {

  // Create a ref to store the element
  const [isHiddenScreen, setShow] = useState(false);
  const fromRef = useRef();
  const element = externalRef ? externalRef.current : fromRef.current;

  useEffect(() => {
    let observer;


    // If the element is not defined, return early from the function and don't run the observer and set the state to false
    const onChange = (entries) => {
        if (entries[0].intersectionRatio === 0 ) {
            setShow(true);
          if (once) observer.disconnect();
        }else if (entries[0].intersectionRatio === 1) {
            setShow(false);
        }

      } ;


    // Create the observer
    
      const options = {
        threshold: [0,1]
      };

      const observerAction = new IntersectionObserver(onChange, options);

      if (element) observerAction.observe(element);

    return () => {
      if(observer) observer.disconnect();
    };

  }, [element, once]);

  return {
    isHiddenScreen,
    fromRef
  };
};


// Hook to check if the user is near the screen
export const useNearScreen = ({
  distance = '2000px',
  externalRef,
  once = true
}) => {

  // Create a ref to store the element
  const [isNearScreen, setShow] = useState(false);
  const fromRef = useRef();

  useEffect(() => {
    let observer;

    const element = externalRef ? externalRef.current : fromRef.current;

    // If the element is not defined, return early from the function and don't run the observer and set the state to false
    const onChange = (entries, observerParam) => {
      const el = entries[0];
      if (el.isIntersecting) {
        setShow(true);
        if(once) observerParam.disconnect();
      } else {
        setShow(false);
      };
    };

    // Create the observer

      const options = {
        root: element?.parentElement,
        roorMargin: `${distance} 0px 0px 0px`,
        threshold: 0
      };

      const intersectionObserver = new IntersectionObserver(onChange, options);

      if (element) intersectionObserver.observe(element);

    return () => observer && observer.disconnect();

  });

  return {
    isNearScreen,
    fromRef
  };
};