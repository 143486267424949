/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { columnTypes } from '../../../core/TableCommon';
import BasicModal from '../../../core/BasicModal';
import themeStyles from '../../../../../theme.module.sass';
import { modules, crudAction, actions, tempPromotionTypesObj, durationFromNow, toFloat } from '../../../utils';
import Dialog from '../../../core/Dialog';
import PromotionCreate from '../forms/PromotionCreate';
import Menu, { createItem } from '../../../core/Menu';
import {updatePromotionStatus} from '../../services/promotions';
import moment from 'moment';
import i18n from '../../../../../i18n';
import { putPayoutsBulk } from '../../../payouts/services/payout';
import ListView from '../../../core/ListView';
import Price from '../../../core/Price';

const PromotionListing = ({ t, promotions, promotionsLoading, fetchPromotions, country, countries, fetchCountries, fetchStatuses, statuses }) => {

  const module = modules(t).promotion;
    const dialogRef = useRef();
  const [dialogContent] = useState('');
  const [dialogConfirm] = useState(false);
  const [dialogResult, setDialogResult] = useState(false);
  const [updateData] = useState({});

  
  
  const promotionData = useMemo(()=> ({
    data: promotions?.data?.promotions,
    paging: {
      limit: Number(promotions?.data?.pagination?.perPage),
      page: Number(promotions?.data?.pagination?.currentPage),
      totalItems: Number(promotions?.data?.pagination?.total),
      totalPages: Number(promotions?.data?.pagination?.lastPage)
    },
    message: promotions?.data?.message,
    statusCode: promotions?.data?.statusCode
  }),[promotions]);

  const badgeColor = {
    active: 'badge-success',
    inactive: 'badge-warning',
    stopped: 'badge-danger',
    completed: 'badge-secondary'
  };

 
  const [, setCheckPayouts ] = useState({});
  const [, setSelectAll] = useState(false);
  const [externalRefresh, setExternalRefresh] = useState(0);
  
  useEffect(() => {
    if(fetchStatuses) fetchStatuses();
  }, [fetchStatuses]);

  useEffect(() => {
    const asyncFunc = async () => {
      const data = updateData;
      const updateCom = await crudAction(putPayoutsBulk.bind(null, data),
        () => { }, {
        name: '',
        module: { ...modules(t).status, name: 'Payout Status' },
        action: actions.UPDATE
      }, t, false
      );
      if (updateCom?.ok) {
        setCheckPayouts({});
        setSelectAll(false);
        setExternalRefresh(externalRefresh + 1);
      }
    };
    if (dialogResult) asyncFunc();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogResult]);

  const tableColumns = [
    { label : 'ID' , sort : 'id' },
    { label : 'Promotion Name' , sort : 'name'},
    { label : 'Type', sort : 'key'},
    { label : 'Promo Code' , sort : 'code'},
    { label : 'Quantity' , sort : 'limit'},
    { label : 'Value' , sort : 'discount'},
    { label : 'Starts At', type :columnTypes.DATE , sort : 'startAt'},
    { label : 'Expires At', type : columnTypes.DATE , sort : 'expiresAt'},
    { label : 'Restrictions'},
    { label : 'Status', type : columnTypes.BADGE,alignment : 'center' , sort : 'status'},
    { label : 'Is Public?', type : columnTypes.BADGE, alignment:'center' , sort :'visibility'},
    { label : 'Actions', alignment : 'center'}
  ];

  const createData = (data) => {
    moment.locale(i18n.language);
    let colorClass = badgeColor.active;
    let value = t('Active');
  
    if (data.status.toUpperCase() === 'ENABLED'){
      const dateObj = moment(data.startAt);
      if (moment().isSameOrAfter(dateObj)){
        const dateExpired = moment(data.expiresAt);
        if (dateExpired.isValid()) {
          if (!moment().isSameOrBefore(dateExpired)) {
            value = `${t('completed')} ${durationFromNow(data.expiresAt)}`;
            colorClass = badgeColor.completed;
          }
        }
      } else {
        value = `${t('Starts')} ${durationFromNow(data.startAt)}`;
        colorClass = badgeColor.inactive;
      }
      

    } else if (data.status.toUpperCase() === 'DELETED') {
      colorClass = badgeColor.stopped;
      value = t('Deleted');
    } else if (data.status.toUpperCase() === 'DISABLED') {
      colorClass = badgeColor.inactive;
      value = t('Stopped');
    }

    const type = tempPromotionTypesObj?.data?.find((e) => e.key.toLowerCase() === data.key.toLowerCase())?.value;
    const editButtonHtml = createItem({ icon: { icon: 'edit_cover-' }, text: t("Edit") });
    const editHtml = <BasicModal content={PromotionCreate} typeButton="html" options={{ html: editButtonHtml }} object={data} onClose={()=> setExternalRefresh(externalRefresh + 1)} />;
    const cloneButtonHtml = createItem({ icon: { icon: 'file_double-1' }, text: t("Clone") });
    const cloneHtml = <BasicModal content={PromotionCreate} typeButton="html" options={{ html: cloneButtonHtml, clone: true }} object={data} onClose={()=> setExternalRefresh(externalRefresh + 1)} />;


    // TODO INABLED???
    const menuItems = [
      { html: editHtml },
      { html: cloneHtml }
    ];

    const activePromoButton ={
      icon: { icon: 'arrow_in_right-' },
      text: t("Restart Promo"),
      onClick: async () => {
        const response = await crudAction(updatePromotionStatus.bind(null, data.id, {status: 'ENABLED'}), ()=>{},
          {
            module, name: data.name, action: actions.UPDATE }, t
        );
        if (response?.ok) {
          setExternalRefresh(externalRefresh + 1);
        }
      }
    };

    const stopPromoButton = {
      icon: { icon: 'cancel' },
      text: t("Stop Promo"),
      onClick: async () => {
        const response = await crudAction(updatePromotionStatus.bind(null, data.id, {status: 'DISABLED'}), ()=>{},
          {
            module, name: data.name, action: actions.UPDATE }, t
        );
        if (response?.ok) {
          setExternalRefresh(externalRefresh + 1);
        }
      }
    };

    const deletePromoButton = {
      icon: { icon: 'delete-' },
      text: t("Delete"),
      onClick: async () => {
        const response = await crudAction(updatePromotionStatus.bind(null, data.id, {status: 'DELETED'}), ()=>{},
          {
            module, name: data.name, action: actions.DELETE }, t
        );
        if (response?.ok) {
          setExternalRefresh(externalRefresh + 1);
        }
      }
    };

    if (data.status === "DISABLED") menuItems.push(activePromoButton);
    if (data.status === "ENABLED") menuItems.push(stopPromoButton);
    menuItems.push(deletePromoButton);
    
    const menu = <Menu items={menuItems} />;

    const valueValidate = data.discount?.value && data.key.toLowerCase() !== 'catalog';
    const rateValidate =  data.discount?.rate && data.key.toLowerCase() !== 'catalog';
    const catalogValidate = data.key.toLowerCase() === 'catalog';

    return {
      id: data.id,
      name: data.name,
      type,
      code: (data.code) ? <b>{data.code}</b> : 'N/A',
      quantity: (data.limit) ? data.limit : 'N/A',
      value: (valueValidate ) ?
        <Price value={data.discount?.value || 0} />
        : (rateValidate) ? (`${toFloat(data.discount?.rate * 100)} %`) : (catalogValidate ) ? 'N/A' : '-',
      startAt: data.startAt,
      expiresAt: (data.expiresAt) ? data.expiresAt : t('Ongoing'),
      restrictions:
        (data.filter) ?
          (data.filter.toLowerCase() === 'all') ?
            t('All deals')
          : (data.filter.toLowerCase() === 'allow_list') ?
              t('Specific deals')
            : (data.filter.toLowerCase() === 'exception_list') ?
                t('All deals with exceptions')
              :'N/A'
        : 'N/A',
      status: {
        colorClass,
        description: '',
        value
      },
      public: {
        colorClass: ( data.visibility === 'PUBLIC' ) ? badgeColor.active : badgeColor.stopped,
        description: '',
        value: t(data.visibility)
      },
      actions: menu, data };
  };

  return (
    <section className={themeStyles["inner-component-view"]}>
      <Dialog ref={dialogRef} content={dialogContent} alert confirm={dialogConfirm} confirmUpdate={setDialogResult} />
      <ListView
        createData={createData} createView={PromotionCreate} collection={promotionData} loadingTable={promotionsLoading} tableColumns={tableColumns} module={module}
        fetchFunction={fetchPromotions} country={country} customTitleHeaderViews="PromotionSection"
        countries={countries} fetchCountries={fetchCountries} externalRefresh={externalRefresh} modalSizeHeaderViews="sm" statuses={statuses}
      />
    </section>
  );
};

export default withTranslation()(PromotionListing);