/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { createActions } from "redux-actions";

import {
  forgotPassword as forgotPasswordService,
  changePassword as changePasswordService,
  createAccount as createAccountService,
  updateProfile,
  sendEmailVerification,
  verifyEmail as verifyEmailService,
  storeCredentials,
  removeCredentials,
  restoreCredentials,
  checkFirebaseUserSession,
  login as loginService,
  getUserToken,
  setAnalyticsUserId,
  getUserFromFirebase,
  signInWithGoogle as signInWithGoogleService
} from "../../services/auth";

import { uploadFile } from "../../services/files";

import { GenericError } from "../../utils/errors";

import NotificationActions from "../notifications/actions";

const AuthActions = createActions({
  LOGIN_REQUEST: () => {},
  LOGIN_SUCCESS: (user, emailVerified) => ({ user, emailVerified }),
  LOGIN_FAILED: (error) => ({ error }),
  RESET_LOGIN_STATE: () => {},

  FORGOT_PASSWORD_REQUEST: () => {},
  FORGOT_PASSWORD_SUCCESS: () => {},
  FORGOT_PASSWORD_FAILED: (error) => ({ error }),
  RESET_FORGOT_PASSWORD: () => {},

  EMAIL_VERIFICATION_REQUEST: () => {},
  EMAIL_VERIFICATION_SUCCESS: () => {},
  EMAIL_VERIFICATION_FAILED: (error) => ({ error }),
  RESET_EMAIL_VERIFICATION: () => {},

  CHANGE_PASSWORD_REQUEST: () => {},
  CHANGE_PASSWORD_SUCCESS: () => {},
  CHANGE_PASSWORD_FAILED: (error) => ({ error }),
  CLEAR_CHANGE_PASSWORD: () => {},

  CREATE_ACCOUNT_REQUEST: () => {},
  CREATE_ACCOUNT_SUCCESS: (user, emailVerified) => ({ user, emailVerified }),
  CREATE_ACCOUNT_FAILED: (error) => ({ error }),
  CLEAR_CREATE_ACCOUNT: () => {},

  UPDATE_ACCOUNT_REQUEST: () => {},
  UPDATE_ACCOUNT_SUCCESS: (user, emailVerified) => ({ user, emailVerified}),
  UPDATE_ACCOUNT_FAILED: (error) => ({ error }),
  CLEAR_UPDATE_ACCOUNT: () => {},

  UPLOAD_PROFILE_PICTURE_REQUEST: () => {},
  UPLOAD_PROFILE_PICTURE_SUCCESS: () => {},
  UPLOAD_PROFILE_PICTURE_FAILED: (error) => ({ error }),

  STORE_PERSONAL_INFORMATION: (firstName, lastName, email) => ({
    firstName,
    lastName,
    email,
  }),

  LOGGED_IN: () => {},
  LOGGED_OUT: () => {},

  UPDATE_USER: (user, emailVerified) => ({ user, emailVerified }),
});

AuthActions.loginWithEmailAndPassword = (email, password) => async (dispatch) => {
    try {
      dispatch(AuthActions.loginRequest());
      const firebaseUser = await loginService(email, password);
      const userToken = await getUserToken();
      setAnalyticsUserId(firebaseUser.uid);
      await storeCredentials(userToken);
      const user = getUserFromFirebase();
      dispatch(AuthActions.loginSuccess(user, firebaseUser.emailVerified));
      dispatch(AuthActions.loggedIn());
    } catch (err) {
      dispatch(
        AuthActions.loginFailed({
          error: new GenericError(err, err)
        })
      );
    }
  };

AuthActions.signInWithGoogle = () => async (dispatch) => {
    try {
      dispatch(AuthActions.loginRequest());
      const firebaseUser = await signInWithGoogleService();
      const userToken = JSON.parse(JSON.stringify(firebaseUser)).stsTokenManager.accessToken;
      setAnalyticsUserId(firebaseUser.uid);
      await storeCredentials(userToken);
      const user = getUserFromFirebase();
      dispatch(AuthActions.loginSuccess(user, firebaseUser.emailVerified));
      dispatch(AuthActions.loggedIn());
    } catch (err) {
      dispatch(
        AuthActions.loginFailed({
          error: new GenericError(err, err)
        })
      );
    }
  };

AuthActions.createAccount = (params) => async (dispatch) => {
    try {
      dispatch(AuthActions.createAccountRequest());
      const firebaseUser = await createAccountService({ ...params });
      const userToken = await getUserToken();
      await updateProfile(params);
      await sendEmailVerification();
      const user = getUserFromFirebase();
      setAnalyticsUserId(firebaseUser.uid);
      storeCredentials(userToken);
      dispatch(NotificationActions.registerNotifications());
      dispatch(
        AuthActions.createAccountSuccess(
          user,
          firebaseUser.emailVerified
        )
      );
    } catch (err) {
      dispatch(
        AuthActions.createAccountFailed({ error: new GenericError(err, err) })
      );
    }
  };

AuthActions.sendEmailVerification = () => async (dispatch) => {
    try {
      dispatch(AuthActions.emailVerificationRequest());
      await sendEmailVerification();
      dispatch(AuthActions.emailVerificationSuccess());
    } catch (err) {
      dispatch(
        AuthActions.emailVerificationFailed({ error: new GenericError(err, err) })
      );
    }
  };

AuthActions.verifyEmail = (code) => async (dispatch) => {
    try {
      dispatch(AuthActions.emailVerificationRequest());
      await verifyEmailService(code);
      dispatch(AuthActions.emailVerificationSuccess());
    } catch (err) {
      dispatch(
        AuthActions.emailVerificationFailed({ error: new GenericError(err, err) })
      );
    }
  };

AuthActions.forgotPassword = (email) => async (dispatch) => {
    try {
      dispatch(AuthActions.forgotPasswordRequest());
      await forgotPasswordService(email);
      dispatch(AuthActions.forgotPasswordSuccess());
    } catch (err) {
      dispatch(
        AuthActions.forgotPasswordFailed({ error: new GenericError(err, err) })
      );
    }
  };

AuthActions.changePassword = (password) => async (dispatch) => {
    try {
      dispatch(AuthActions.changePasswordRequest());
      await changePasswordService(password);
      dispatch(AuthActions.changePasswordSuccess());
    } catch (err) {
      dispatch(AuthActions.changePasswordFailed({ error: new GenericError(err, err) }));
    }
  };

AuthActions.restoreUserAccess = () => async (dispatch) => {
    try {
      const firebaseUser = await checkFirebaseUserSession();
      if (firebaseUser) {
        await restoreCredentials();
        const user = getUserFromFirebase();
        if (user) {
          dispatch(
            AuthActions.updateUser(user, firebaseUser.emailVerified)
          );

          setAnalyticsUserId(firebaseUser.uid);

          dispatch(AuthActions.loggedIn());
        } else {
          dispatch(AuthActions.loggedOut());
        }
      } else {
        await removeCredentials();
        dispatch({ type: "RESET_STORE" });
        return dispatch(AuthActions.loggedOut());
      }
    } catch (err) {
      dispatch(AuthActions.loggedOut());
    }
  };

AuthActions.updateAccount = (params) => async (dispatch) => {
    try {
      dispatch(AuthActions.updateAccountRequest());
      const firebaseUser = await updateProfile(params);
      const user = getUserFromFirebase();
      await dispatch(
        AuthActions.updateAccountSuccess(user, firebaseUser.emailVerified)
      );
    } catch (err) {
      dispatch(AuthActions.updateAccountFailed({ error: new GenericError(err, err) }));
      throw err;
    }
  };

AuthActions.uploadProfilePicture = (file) => async (dispatch) => {
    try {
      dispatch(AuthActions.uploadProfilePictureRequest());
      const url = await uploadFile(file);
      await dispatch(AuthActions.updateAccount({ profile_url: url }));
      dispatch(AuthActions.uploadProfilePictureSuccess());
    } catch (err) {
      dispatch(AuthActions.uploadProfilePictureFailed(err));
      throw err;
    }
  };

AuthActions.logout = () => async (dispatch) => {
    await removeCredentials();
    await dispatch({ type: "RESET_STORE" });
    dispatch(AuthActions.loggedOut());
    window.location.reload(true);
  };

export default AuthActions;
