import { getConfig } from '../../../../config';

let api;

const checkConfig = () => {
  if (!api) api = getConfig('api');
};

export const getCountry = () => {
  checkConfig();
  return api?.headers['wao-country'];
};

export const setCountry = countryCode => {
  checkConfig();
  api.setHeader('wao-country', countryCode);
};

const currencies = [
  { name: "USD (United States Dollar)", code: "USD", symbol: "$" },
  { name: "COP (Colombian Peso)", code: "COP", symbol: "$" },
  { name: "GTQ (Guatemalan Quetzal)", code: "GTQ", symbol: "Q" },
  { name: "ARS (Argentine Peso)", code: "ARS", symbol: "$" },
  { name: "MXN (Mexican Peso)", code: "MXN", symbol: "$" },
];

const countries = [
  // { name: "United States", code: "US" },
  { name: "Colombia", code: "CO" },
  // { name: "Guatemala", code: "GT" },
  { name: "Argentina", code: "AR" },
  { name: "Ecuador", code: "EC" },
  { name: "México", code: "MX" }
];

export const getCountries = () => new Promise((resolve) => {
  resolve({
    "ok": true, "data": {
      "data": countries
    }
  });
});

export const getCurrencies = () => new Promise((resolve) => { resolve({ "ok": true, "data": { "data": currencies } }); });

const currenciesByCountryCode = {
  "US": "USD",
  "EC": "USD",
  "CO": "COP",
  "GT": "GTQ",
  "AR": "ARS",
  "MX": "MXN"
};

export const getCurrencyByCountry = (countryCode) => currenciesByCountryCode[countryCode] || '';

export const updateUser = (userId, data) => {
  checkConfig();
  return api.put(`/users/${ userId}`, data);
};