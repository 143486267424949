/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import Icon from "../Icon";
import themeStyles from '../../../../theme.module.sass';

const fileSizeLimitInMB = 5;
const fileSizeLimit = fileSizeLimitInMB * 1024 * 1024;

// const defaultUploadAccept = [
//   'image/webp', 'image/jpeg', 'image/gif', 'image/png', 'image/bmp', '.pdf', '.csv', 'video/mp4', 'video/mpeg', 'video/quicktime', 'video/3gpp', 'video/3gpp2', 'video/webm'
// ];

const defaultUploadAccept = {
  'image/*': ['.png', '.jpeg', '.gif', '.jpg'],
  'video/*': ['.mp4', '.mov'],
  'application/pdf': ['.pdf'],
};

const UploadFile = (
  {
    successFunction = () => { },
    errorFunction = () => { },
    accept = defaultUploadAccept,
    t = {},
    fileTypesAllowed
  }
) => {
  // eslint-disable-next-line consistent-return
  const onDrop = useCallback((acceptedFiles) => {
    const success = [];
    const fail = [];
    try {
      if (acceptedFiles.length > 0) {
        acceptedFiles.forEach(file => {
          if (file.size < fileSizeLimit) {
            success.push(file);
          } else {
            const roundedSize = Math.round(((file.size / 1024 / 1024) + Number.EPSILON) * 100) / 100;
            fail.push(t("fileExceedsSize", { name: file.name, currentSize: roundedSize, sizeLimit: fileSizeLimitInMB }));
          }
        });
      }
    } catch (error) {
      return errorFunction(t("fileUploadError") + error);
    }

    if (success.length + fail.length < 1) return errorFunction(t('fileUnsupported'));

    fail.forEach(error => {
      errorFunction(error);
    });

    if (success.length > 0) return successFunction(success);

  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <div className={`${themeStyles["solution-images"] } ${ themeStyles.active}`}>
          <span className="upload-text">{t("Drop the files here")}...</span>
          <i><Icon color="#BBB" size={50} icon="download-1" className={themeStyles["arrow-circle-down-icon"]}/></i>
        </div>
      ) : (
          <div className={themeStyles["solution-images"]}>
            <span className={themeStyles["upload-text"]}>{t("Drop file here or click")}</span>
            <i className={themeStyles["cloud-icon"]}><Icon color="#909090" size={30} icon="cloud_up-2"/></i>
            <span className={themeStyles["upload-file-types"]}>{fileTypesAllowed || t("fileTypesAllowed")}</span>
          </div>
        )}
    </div>
  );
};
UploadFile.propTypes = {
  successFunction: PropTypes.func.isRequired,
  errorFunction: PropTypes.func,
  accept: PropTypes.object,
};

export default withTranslation()(UploadFile);