import React from 'react';
import Icon from '../Icon';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.sass';

export default withTranslation()(({ t }) => (
    <div className={styles['basic-empty-state']} >
    <Icon color="#310f49" size={100} icon="inbox_empty_round-" />
      <h3>{t("No data yet")}</h3>
    </div>
  ));