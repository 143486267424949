import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Tabs, Tab } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useHiddenScreen } from '../../../../../utils/hooks';

const Tabber = ({ tabs, styles, setTabStatus }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const tabberStickyRef = useRef();
  const {isHiddenScreen} = useHiddenScreen({externalRef: tabberStickyRef,once:false});

  useEffect(() => {
    setTabStatus(activeTab);
  }, [activeTab, setTabStatus]);
  
  
  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const selectorStyles = {
    root: {
      background: '#f6f5f7',
      padding: '5px 24px',
      minHeight: '0',
      boxShadow: `${isHiddenScreen ? "0px -2px 7px 0px rgba(0, 0, 0, 0.7)" : ""}`
    },
    indicator: {
      display: 'none'
    },
    scroller:{
      height: '30px',
      alignItems: 'center'
    }
  };

  const tabStyles = {
    root: {
      textTransform: 'none',
      fontSize: '16px'
    },
    selected: {
      fontWeight: 'bold',
      color: '#ee7d40'
    }
  };

  const Selector = withStyles(styles?.selector || selectorStyles)(Tabs);
  const SingleTab = withStyles(styles?.tab || tabStyles)(Tab);

  return (
    <div>
      <Selector value={activeTab} onChange={handleTabChange} variant="standard">
        {tabs?.map(tab => (
          <SingleTab key={tab.label} label={tab.label} />
          ))}
      </Selector>
      <div ref={tabberStickyRef}/>
      {tabs?.[activeTab]?.content}
    </div>

  );
};

export default withTranslation()(Tabber);