import Immutable from 'seamless-immutable';

const initialState = Immutable({
  commissionsInit: true,
  commissionsLoading: false,
  commissionsError: null,
  commissionsLoaded: false,
  commissions: null,

  createCommissionLoading: false,
  createCommissionError: null,
  createdCommission: false,
});


export default initialState;
