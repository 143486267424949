import { getConfig } from '../../../../config';
import { cleanObject } from '../../utils';

let api;

const checkConfig = () => {
  if (!api) api = getConfig('api');
};

const defaultRequestCleanup = (query) => cleanObject(query, ['refresh', 'priceMax', 'priceMin']);

/**
 * Get the order with the given id
 * @param {string} id id of the order to get
 */
export const getOrders = (query) => {
  checkConfig();
  return api.get(`/orders/search`, defaultRequestCleanup(query));
};
export const getOrder = (id) => {
  checkConfig();
  return api.get(`/orders/${id}`);
};


export const postOrders = (data) => {
  checkConfig();
  return api.post('/orders', data);
};

export const updateOrder = (id, data) => {
  checkConfig();
  return api.put(`/orders/${id}`, data);
};

/**
 * Delete the order with the given id
 * @param {string} id id of the order to delete
 */
export const deleteOrder = (id) => {
  checkConfig();
  return api.delete(`/orders/${id}`);
};

/**
 * Get the order statuses
 */
export const getStatuses = () => {
  checkConfig();
  return api.get(`/orders/statuses`);
};

/**
 * Export orders
 */
export const exportOrders = (data) => {
  checkConfig();
  return api.get(`/orders/exportOrders`, data);
};

export const updateOrderStatusesBulk = (data) => {
  checkConfig();
  return api.put('/orders/bulk', data);
};
export const updateOrdersBulk = (data) => {
  checkConfig();
  return api.post('/orders/pipelineBulk', data);
};

export const reviewFraudOrders = (data) => {
  checkConfig();
  return api.post('frauds/sendFraud', data);
};

