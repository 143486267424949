/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Icon from "../../../core/Icon";
import styles from './styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import ProductCreate from '../forms/ProductCreate';
import { useLocation } from 'react-router-dom';
import { durationFronNow, modules } from '../../../utils';
import ProductsActions from '../../model/actions';
import ListView from '../../../core/ListView';
import { columnTypes } from '../../../core/TableCommon';
import { toast } from 'react-toastify';

// import { stylesCalendar } from '@material-ui/pickers/views/Calendar/Calendar';



const ProductsListing = ({ t, products, productsLoading, createProductError, fetchProducts,
  createdProduct, watchedProduct, watchedProductError, watchProduct, fetchCountries, country, countries}) => {

  const module = modules(t).product;

  const location = useLocation();

  const queryParamsFilters = {
    page : 1,
    limit : 10,
    sort : [],
    q: location.phrase,
    country: location.country,
    priceMin: null,
    priceMax: null,
    dateFrom: null,
    dateto: null,
    isWatched: null,
    category_name_root: location.category_root
  };

  
  const [queryParamsFiltersInit, /* setQueryParamsFiltersInit */] = useState(queryParamsFilters);
  const [watchingItems, setWatchingItems] = useState([]);
  const [externalRefresh, setExternalRefresh] = useState(0);


  const tableColumns = [
    {label: 'Image', type:columnTypes.IMAGE},
    {label: 'Name Ref', sort:'name', type:columnTypes.NAME_REF},
    {label: 'Category Name', sort:'category_name_root', type:columnTypes.CATEGORY_NAME},
    {label: 'Price', sort:'price', type:columnTypes.PRICE},
    {label: 'Range Price', type:columnTypes.RANGE_PRICE},
    {label: 'Velocity', sort:'current_velocity', alignment: "center"},
    {label: 'Rating', sort:'rating', alignment: "center"},
    {label: 'Number Sold', sort: 'number_sold', alignment: "center"},
    {label: 'Comments', alignment: "center"},
    {label: 'Last update', alignment: "center"},
    {label: 'Actions', alignment: "center"}
  ];

  const dispatch = useDispatch();

  useEffect(() => {

    if (createProductError) toast.error(createProductError);
    if (createdProduct) toast.success(t('Created Product'));
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createProductError, createdProduct, watchProduct, t('Created Product')]);

  const createData = useCallback((data) => {
    const {image_url, name, url_mercado_libre, velocity_trend, category_name_root, country, price, currency, price_max, price_min, current_velocity, rating, number_sold, comments, updated_at} = data;
    const imageUrl = image_url;
    const nameRef = {name, link: url_mercado_libre, isTrending: velocity_trend};
    const categoryName = {name: category_name_root, country};
    const priceProduct = {price, currency};
    const RangePrice = {price_max, price_min, currency};
    const currentVelocity = current_velocity;
    const ratingProduct = rating;
    const numberSold = number_sold;
    const commentsProduct = comments;
    const lastUpdate = durationFronNow(updated_at, '');
    const actions = <WatchItem item={data}/>;


    return { imageUrl, nameRef, categoryName, priceProduct, RangePrice, currentVelocity, ratingProduct, numberSold, commentsProduct, lastUpdate, actions};
  }, []);

  const updateItems = (currentWatchedProduct, itemIsWatched) =>{
      const newItem = {
        id: currentWatchedProduct.id,
        isWatched: !itemIsWatched
      };
      let items = Object.assign([], watchingItems);
      items = items.filter( item => item.id !== currentWatchedProduct.id);

      items.push(newItem);
      setWatchingItems(items);
  };

    
  const getStatusAdding = useCallback((watchedProduct, watchedProductError) => {
    if(watchedProduct){
      toast.success(t('(Successful) Product added to watch'));
    }
  
    if(watchedProductError){
      toast.error(t('(Error) Product added to watch'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t('(Successful) Product added to watch'), t('(Error) Product added to watch')]);

  
  
  useEffect(() => {
    
    getStatusAdding(watchedProduct, watchedProductError);

  }, [watchedProduct, watchedProductError, getStatusAdding]);
  

  


  const watchClick = (item, itemIsWatched) => {

    updateItems(item, itemIsWatched);
    const productId = item.id;
    const dataFormat = {
      isWatched: !itemIsWatched
    };

    dispatch(ProductsActions.watchProduct(productId, dataFormat));
    setExternalRefresh(externalRefresh + 1);
    // toast.success(t('Sent Product to Watch'));
    
  };

  const findInWatchItems = (product) => {
    
    const hasTempItem = watchingItems.find( item => product.id === item.id);

    return (hasTempItem) ? hasTempItem.isWatched : product.isWatched;
  };


  const WatchItem = (props) => {
    const { item } = props;
    const itemIsWatched = findInWatchItems(item);
    
    return <span className={ `${styles['watched-item'] } ${ (itemIsWatched) ? styles.isWatched : styles.isNotWatched}` } onClick={ () => {watchClick(item, itemIsWatched);}}>
      <Icon color="#000" size={15} icon="view_simple-" />
    </span>;
  };
  
  return (
    <section className={themeStyles["inner-component-view"]}>
      <ListView
        createData={createData} collection={products} loadingTable={productsLoading} tableColumns={tableColumns} module={module}
        createView={ProductCreate} fetchFunction={fetchProducts} country={country} externalQueryParamsFiltersInit={queryParamsFiltersInit}
        countries={countries} fetchCountries={fetchCountries} showPrice category_name={queryParamsFiltersInit.category_name_root} externalRefresh={externalRefresh}
         
      />
    </section>
  );
  
};

export default withTranslation()(ProductsListing);
