/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import Icon from "../Icon";
import styles from './styles.module.sass';
import stylesRoot from '../../../../theme.module.sass';
import SearchSelect from '../SearchSelect';
import { withTranslation } from 'react-i18next';

const SearchFilter = ({ customStyles = {}, t, applyFilter, loadOptions, clearCounter }) => {
  const defaultDebounceMs = 500;
  const syncDebounceTime = defaultDebounceMs;

  const [searchValue, setSearchValue] = useState("");
  const [searchEntities, setSearchEntities] = useState({});
  const [searchTags, setSearchTags] = useState([]);
  const [newQuery, setNewQuery] = useState("");
  const [getAll, setGetAll] = useState(false);
  const [ready, setReady] = useState(false);

  const fetchData = (() => {
    if (newQuery !== "") {
      setGetAll(true);
      applyFilter({ q: newQuery });
    }
    if (getAll && newQuery === "") {
      applyFilter({ q: newQuery });
    }
  });

  useEffect(() => {
    const timeOutId = setTimeout(setNewQuery.bind(null, searchValue), syncDebounceTime);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (!loadOptions) setSearchValue("");
    else {
      setSearchEntities({});
      setSearchTags([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCounter]);

  useEffect(() => {
    if (loadOptions && ready) applyFilter({ entities: searchEntities });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchEntities]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchData, [newQuery]);

  const asyncSearchChanged = field => {
    if (!ready) setReady(true);
    setSearchTags(field || []);
    const result = {};
    if (field) {
      const deals = [];
      const orders = [];
      const users = [];
      field.forEach(({ object, label, type }) => {
        const termResult = { id: object.id, label };
        if (type === 'referrer' || type === 'customer') {
          users.push(termResult);
        } else if (type === 'deal') {
          deals.push(termResult);
        } else if (type === 'order') {
          orders.push(termResult);
        }
      });
      if (deals.length > 0) result.deals = deals;
      if (orders.length > 0) result.orders = orders;
      if (users.length > 0) result.users = users;
    }
    setSearchEntities(result);
  };


  const simpleSearch = <input placeholder={t("Search")} className={`${styles.__input} ${stylesRoot["form-control"]}`} value={searchValue}
    onChange={event => {
      if (!ready) setReady(true);
      setSearchValue(event.target.value);
    }}
  />;

  const asyncTagsSearch = <SearchSelect onChange={asyncSearchChanged} value={searchTags}
    loadOptions={loadOptions} defaultOptions={[]} placeholder={t("Search")}
    rounded hideButton isMulti cacheOptions
  />;

  const searchComponent = loadOptions ? asyncTagsSearch : simpleSearch;

  return (
    <div style={customStyles} className={styles["wrap-search"]}>
      {searchComponent}
      {(searchValue || Object.keys(searchEntities)?.length || searchTags?.length) ? '' :
        <button className={styles["button-search"]}>
          <i className={styles["icon-search"]}>
            <Icon color="#948E9D" size={22} icon="search_left-1" />
          </i>
        </button>
      }
    </div>
  );
};

export default withTranslation()(SearchFilter);