/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, { useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Icon from '../../../core/Icon';
import BasicModal from '../../../core/BasicModal';
import BlastCreate from '../forms/BlastCreate';
import themeStyles from '../../../../../theme.module.sass';
import { actions, crudAction, modules, capitalize, repeatDays, durationFromNow, getDatei18n } from '../../../utils';
import ListView from '../../../core/ListView';
import { Tooltip } from '@mui/material';
import { columnTypes } from '../../../core/TableCommon';
import Menu, { createItem } from '../../../core/Menu';
import { updateBlastStatus } from '../../services/blast';
import moment from 'moment';
import i18n from '../../../../../i18n';

const BlastListing = ({ t, blasts, blastsLoading, fetchBlasts, country, countries, fetchCountries }) => {

  const module = modules(t).blast;
  
  
  
  const badgeColor = {
    active: 'badge-success',
    inactive: 'badge-warning',
    stopped: 'badge-danger',
    completed: 'badge-secondary'
  };

  const dateFormat = {
    year: 'numeric', month: 'short', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false,
    timeZoneName: 'short'
  };

  const datei18n = getDatei18n(country);

  const queryParamsFilters = useMemo(() => ({
    page: 1,
    limit: 10,
    sort: [],
    q: null,
    country,
    refresh: 0
  }), [country]);

  
  
  const [queryParamsFiltersInit, /* setQueryParamsFiltersInit */] = useState(queryParamsFilters);
  const [externalRefresh, setExternalRefresh] = useState(0);

  const tableColumns = [
    {label :'ID', sort : 'id'},
    {label :'Name', sort : 'name'},
    { label : 'Users', alignment: "center", sort :'countUsers'},
    { label :'Cell Phone', alignment: "center", sort : 'hasPhone'},
    {label :'Name', alignment: "center", sort : 'hasName'},
    { label : 'Message', alignment: "center", sort : 'hasMessage'},
    { label : 'Type',alignment: "center", sort : 'type'},
    { label : 'Begin', type: columnTypes.DATE, alignment: "center", sort : 'startDate'},
    { label : 'Recurrence', alignment: "center", sort : 'hasRecurrency'},
    { label : 'End at', type: columnTypes.DATE, alignment: "center", sort : 'endDate'},
    { label : 'Status', type : columnTypes.BADGE, alignment : 'center' , sort : 'status'},
    {alignment: "center"}

  ];

  // const refreshCurrentQuery = () => {
  //   const newParams = { ...queryParamsFiltersInit, refresh: (queryParamsFiltersInit.refresh + 1) };
  //   setQueryParamsFiltersInit(newParams);
  // };


  const createData = (data) => {
    const { id, name, count, hasPhone, hasName, hasMessage, message, type, startDate, recurrency, endDate, status, limit } = data;
    moment.locale(i18n.language);
    let colorClass = badgeColor.active;
    let statusValue = t('Active');

    if (status.toLowerCase() === 'active'){
      const dateObj = moment(startDate);
      if (moment().isSameOrAfter(dateObj)){
        const dateExpired = moment(endDate);
        if (dateExpired.isValid()) {
          if (!moment().isSameOrBefore(dateExpired)) {
            statusValue = `${t('completed')} ${durationFromNow(endDate)}`;
            colorClass = badgeColor.completed;
          }
        }
      } else {
        statusValue = `${t('Starts')} ${durationFromNow(startDate)}`;
        colorClass = badgeColor.inactive;
      }
    } else if (status.toLowerCase() === 'inactive') {
      colorClass = badgeColor.inactive;
      statusValue = t('Stopped');
    };

    const nameHtml = <div style={{minWidth:"330px"}}><span style={{fontWeight: 600}}>{name}</span></div>;
    const countUsersHtml = <div><span>{limit ? `${limit && count? `${limit}/${count}`: limit }` : (count || 0) }</span></div>;
    const typeHtml = type ? t(capitalize(type)) : null;
    
    const hasPhoneHtml = hasPhone ? <Icon className={themeStyles.clickable} color="#310f49" size={15} icon="check-list" /> : "-";
    const hasNameHtml = hasName ? <Icon className={themeStyles.clickable} color="#310f49" size={15} icon="check-list" /> : "-";
    const hasMessagesHtml = hasMessage ?
    <Tooltip title={message}>
      <div><Icon className={themeStyles.clickable} color="#310f49" size={15} icon="message" /></div>
    </Tooltip>
    : "-";
    const recurrenceText = `${recurrency?.repeatMode ? t(`${capitalize(recurrency?.repeatMode)}ly`) : ""} ${recurrency?.repeatMode === "week" || recurrency?.repeatMode === "month" ? t('the') : ""} ${!recurrency?.repeatAt?.length && recurrency?.repeatMode === "month" ? new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(startDate)) : ""} ${recurrency?.repeatAt?.length && recurrency?.repeatMode === "week" ? repeatDays(recurrency?.repeatAt) : ""} ${!endDate && recurrency?.repeatAfter < 1 ? "" : t('Until')} ${endDate && recurrency?.repeatAfter < 1 ? new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(endDate)): ""} ${recurrency?.repeatAfter > 0 ? `${recurrency?.repeatAfter} ${recurrency?.repeatAfter > 1 ? t("Recurrencies") : t("Recurrence")}` : ""}`;
    const recurrencyHtml = recurrency ? <Tooltip title={recurrenceText}>
      <div> <Icon className={themeStyles.clickable} color="#310f49" size={15} icon="clock" /></div>
    </Tooltip>
    : "-";


    // const buttonHtml = <Icon className={themeStyles.clickable} color="#310f49" size={15} icon="edit_cover-" />;
    const editButtonHtml = createItem({ icon: { icon: 'edit_cover-' }, text: t("Edit") });
    const editHtml = <BasicModal modalSize="lg" content={BlastCreate} options={{ html: editButtonHtml }} object={data} onClose={()=> setExternalRefresh(externalRefresh + 1)} />;
    const cloneButtonHtml = createItem({ icon: { icon: 'file_double-1' }, text: t("Clone") });
    const cloneHtml = <BasicModal modalSize="lg" content={BlastCreate} typeButton="html" options={{ html: cloneButtonHtml, clone: true }} object={data} onClose={()=> setExternalRefresh(externalRefresh + 1)} />;
    const menuItems = [
      { html: editHtml },
      { html: cloneHtml }
    ];

    const activeBlastButton ={
      icon: { icon: 'arrow_in_right-' },
      text: t("Restart Blast"),
      onClick: async () => {
        const response = await crudAction(updateBlastStatus.bind(null,data.id,{status: 'active'}), ()=>{},
          { module, name: data.name, action: actions.UPDATE }, t
        );
        if (response?.ok) {
          setExternalRefresh(externalRefresh + 1);
        }
      }
    };

    const stopBlastButton = {
      icon: { icon: 'cancel' },
      text: t("Stop Blast"),
      onClick: async () => {
        const response = await crudAction(updateBlastStatus.bind(null, data.id,{status: 'inactive'}), ()=>{},
          { module, name: data.name, action: actions.UPDATE }, t
        );
        if (response?.ok) {
          setExternalRefresh(externalRefresh + 1);
        }
      }
    };

    if (status === "inactive") menuItems.push(activeBlastButton);
    if (status === "active") menuItems.push(stopBlastButton);

    const menu = <Menu items={menuItems} />;

    return {
      id, nameHtml, countUsersHtml, hasPhoneHtml, hasNameHtml, hasMessagesHtml,
      type: typeHtml, startDate: startDate || null , recurrencyHtml, endDate: endDate || "-",
      status: {
        colorClass,
        description: '',
        value: statusValue
      },
      actions: menu, data
    };
  };
  
  return (
    <section className={themeStyles["inner-component-view"]}>
      <ListView
        createData={createData} createView={BlastCreate} collection={blasts?.data} loadingTable={blastsLoading} tableColumns={tableColumns} module={module} externalRefresh={externalRefresh}
        fetchFunction={fetchBlasts} country={country} countries={countries} fetchCountries={fetchCountries} externalQueryParamsFiltersInit={queryParamsFiltersInit}
      />
    </section>
  );
};

export default withTranslation()(BlastListing);