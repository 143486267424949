import CategoriesActions from "./actions";
import InitialState from ".";

export const reducer = (state = InitialState, action) => {

  switch (action.type) {
    
    case CategoriesActions.fetchCategoriesRequest().type:
      return state.merge({
        categoriesLoading: true,
        categoriesError: null,
        categoriesLoaded: false,
      });

    case CategoriesActions.fetchCategoriesSuccess().type:
      return state.merge({
        categoriesLoading: false,
        categoriesError: null,
        categoriesLoaded: true,
        categories: action.payload.categories.data || state.categories,
      });

    case CategoriesActions.fetchTrendingCategoriesRequest().type:
      return state.merge({
        categoriesTrendingLoading: true,
        categoriesTrendingError: null,
        categoriesTrendingLoaded: false,
      });

    case CategoriesActions.fetchTrendingCategoriesSuccess().type:
      return state.merge({
        categoriesTrendingLoading: false,
        categoriesTrendingError: null,
        categoriesTrendingLoaded: true,
        categoriesTrending: action.payload.categories.data || state.categories,
      });

    case CategoriesActions.fetchKeywordsRequest().type:
      return state.merge({
        keywordsLoading: true,
        keywordsError: null,
        keywordsLoaded: false,
      });

    case CategoriesActions.fetchKeywordsSuccess().type:
      return state.merge({
        keywordsLoading: false,
        keywordsError: null,
        keywordsLoaded: true,
        keywords: action.payload.keywords.data || state.keywords,
      });

    case CategoriesActions.fetchTrendingKeywordsRequest().type:
      return state.merge({
        keywordsTrendingLoading: true,
        keywordsTrendingError: null,
        keywordsTrendingLoaded: false,
      });

    case CategoriesActions.fetchTrendingKeywordsSuccess().type:
      return state.merge({
        keywordsTrendingLoading: false,
        keywordsTrendingError: null,
        keywordsTrendingLoaded: true,
        keywordsTrending: action.payload.keywords.data || state.keywords,
      });

    default:
      return state;
  }
};
