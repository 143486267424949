/* eslint-disable no-param-reassign */
import React, { useState, useCallback, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import Icon from "../../../core/Icon";
import BasicModal from '../../../core/BasicModal';
import GroupsCreate from '../forms/GroupsCreate';
import themeStyles from '../../../../../theme.module.sass';
import { modules } from '../../../utils';
import ListView from '../../../core/ListView';

const GroupsListing = ({ t, groups, groupsLoading, fetchGroups, country, countries, fetchCountries }) => {

  const module = modules(t).group;

  const [externalRefresh, setExternalRefresh] = useState(0);
  // const [averageData, setAverageData] = useState(null);

  const createData = useCallback((id, name, description, tags, blasts, Tenderos, data) => {
    const buttonHtml = <Icon className={themeStyles.clickable} color="#310f49" size={15} icon="edit_cover-" />;
    const editHtml = <BasicModal content={GroupsCreate} options={{ html: buttonHtml }} object={data} onClose={()=> setExternalRefresh(externalRefresh + 1)} />;
    return { id, name, description, tags, blasts, Tenderos, actions: editHtml, data };
  },[externalRefresh]);

  const tableColumns = [
    { label : 'ID' , sort : 'id'},
    { label : 'Name', sort : 'name'},
    { label : 'Description' , sort : 'description' },
    { label : 'Tags' },
    { label : 'Blasts' },
    { label: t('Referrer', { count: 100 }) },
    { label : 'Actions', alignment :  'center' }

    
  ];

  const externalTableRows = useMemo(()=>(groups?.data.map((item) => {
    const referrers = item.referrers || [];
    const referrersLength = referrers.length;
    const blasts = item.blasts || [];
    const blastsLength = blasts.length;
    return createData(
      item.id,
      item.name,
      item.description,
      item.tags,
      blastsLength,
      referrersLength,
      item
    );
  })),[createData, groups]);

  return (
    <section className={themeStyles["inner-component-view"]}>
      <ListView collection={groups} countries={countries} country={country}
        createData={createData} createView={GroupsCreate}
        externalRefresh={externalRefresh}
        fetchCountries={fetchCountries} fetchFunction={fetchGroups} module={module} loadingTable={groupsLoading}
        tableColumns={tableColumns} externalTableRows={externalTableRows}
      />
    </section>
  );
};

export default withTranslation()(GroupsListing);