import { getConfig } from '../../../../config';

let api;
const defaultLimit = 100;

const checkConfig = () => {
  if (!api) api = getConfig('api');
};

/**
{string} message Blast message
{string} name Blast name
{Array} numbers Blast numbers
*/
export const getBlasts = (query) => {
  checkConfig();
  return api.get(`/blasts`, query);
};

/**
{string} message Blast message
{string} name Blast name
{Array} numbers Blast numbers
*/
export const getBlastsList = ({ page = 1, limit = defaultLimit, searchTerms }) => {
  checkConfig();
  if (searchTerms) return api.get(`/blasts?page=${page}&limit=${limit}&q=${searchTerms}`);
  return api.get(`/blasts?page=${page}&limit=${limit}`);
};

  
/**
 * Creates a blast
 * @param {object} data
 * data.name {string} Blast name
 * data.message {string} Blast message
 * data.numbers {Array} Tenderos phone numbers
 */
export const postBlasts = (data) => {
  checkConfig();
  return api.post('/blasts', data);
};

export const cloneBlasts = (data) => {
  checkConfig();
  return api.post('/blasts/clone', data);
};

/**
 * Get the blast with the given id
 * @param {string} id id of the blast to get
 */
export const getBlast = (id) => {
  checkConfig();
  return api.get(`/blasts/${id}`);
};

/**
 * Update the blast with the given id
 * @param {string} id id of the blast to update
 * @param {object} data
 * data.name {string} Blast name
 * data.message {string} Blast message
 * data.numbers {Array} Blast numbers
 */
export const updateBlast = (id, data) => {
  checkConfig();
  return api.put(`/blasts/${id}`, data);
};

/**
 * Delete the blast with the given id
 * @param {string} id id of the blast to delete
 */
export const deleteBlast = (id) => {
  checkConfig();
  return api.delete(`/blasts/${id}`);
};

export const updateBlastStatus = (id, data) => {
  checkConfig();
  return api.put(`/blasts/status/${id}`, data);
};