import React, { useState, useEffect, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { CircularProgress, Grid } from '@mui/material';
import blastStyles from '../../../commissions/views/forms/styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import styles from './styles.module.sass';
import { capitalize, modules } from '../../../utils';
import TableCommon from '../../../core/TableCommon';
import Icon from "../../../core/Icon";


const FraudModal = ({ onClose = () => { }, t, object }) => {
  const {fraudRawdata,tachometerFraud, colorFraudAlert } = object;
  const [tableRows, setTableRows] = useState(null);
  const [loaderEnabled, setLoaderEnabled] = useState(true);

  const module = modules(t).order;
  const formCol = (label, type, alignment = 'left') => ({ label: t(label), type, align: alignment});

  const tableColumns = [
    formCol('Risk'),
    formCol('col_target'),
  ];

  const createData = useCallback((data) => {
    setLoaderEnabled(false);

    const risk = data.suspicious_level;
    const targetValue = data.target_value;
    const columnTarget = data.col_target;
    const degreeSeparation = data.degree_of_separation;

    const riskHtml = <div className={styles.risk}>
      <span className={styles.risk__icon}><Icon color={colorFraudAlert(risk).colorAlert} size={16} icon="exclamation-circle" /></span>
      <span className={styles.risk__status}>{t(capitalize(colorFraudAlert(risk).status))}</span>
    </div>;
    const columnTargetHtml = <div>
      <span>{`${columnTarget}: `}</span>
      <b>{targetValue}</b>
      <span>{` ${t("is associated")} ${degreeSeparation > 0 ? t("degree with fraud", {degree: degreeSeparation + 1}) : t("with fraud")}`}</span>
      </div>;

    return {
      suspicious_level: riskHtml,
      col_target: columnTargetHtml,
      degree_of_separation: data.degree_of_separation
    };
  }, [colorFraudAlert, t]);

  useEffect(() => {
    setTableRows(fraudRawdata?.map(createData));
  }, [createData, fraudRawdata]);

  const levelFraud = t(`${capitalize(tachometerFraud?.status)}_female`).toLowerCase();


  return (
    <div>
      <Grid container spacing={0} alignItems="flex-start" >
        <Grid item xs>

          <div className={themeStyles.currentContent}>
            <div style={{ textAlign: 'right', height: '1px', marginTop: '20px' }}>
              <button onClick={onClose} style={{background: "transparent", color: '#c8c8c8', width: '40px', height: '40px', padding: '0px', fontSize:"20px" }}>X</button>
            </div>
            <div className={blastStyles["blast-view"]}>
              <header className={themeStyles["header-form"]} style={{margin:"15px 32px"}}>
              <div className={styles["header-fraud"]}>
                {tachometerFraud && <div className={styles["header-fraud__img"]}>
                    <Icon color={tachometerFraud?.colorAlert} size={20} icon="exclamation-circle" />
                </div>}
                  <h3>{t('Alert for possible fraud', {level: levelFraud})}</h3>
                </div>
              </header>
              <div className={themeStyles["form-wrap"]}>
                <div>
                    {!fraudRawdata && !fraudRawdata.length && loaderEnabled ? <div style={{ display: 'flex', justifyContent: 'center', padding: '200px' }}>
                    <CircularProgress />
                    </div>
                    : ""}
                    {fraudRawdata && fraudRawdata.length ? <div className={styles["table-fraud"]}>
                    <TableCommon rows={tableRows} columns={tableColumns} module={module} refresh={() => { }} />
                    </div>
                    : <div style={{textAlign:"center", margin: "50px"}}><span>{t("NotFraudData")}</span></div>}
                </div>
              </div>
              <div className={themeStyles["form-crud-actions"]} style={{textAlign:"center"}}>
                <button id='submit-button' style={{color:"#000"}} type="submit" className={`${themeStyles.btn} ${themeStyles["btn-secondary"]}`} onClick={onClose}>
                  {t('Close')}
                </button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

    </div>
  );
};

export default withTranslation()(FraudModal);