import config from 'react-global-configuration';

const getConfig = (key) => {
  let result = config.get();
  if (result && key && result[key]) result = result[key];
  return result;
};
export { getConfig };

const setConfig = (newConfig, key) => {
  if (!key) config.set(newConfig, { freeze: false });
  else {
    const newJson = {};
    newJson[key] = newConfig;
    config.set(newJson, { assign: true, freeze: false });
  }
};
export { setConfig };