import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContainerPromotionsListing from '../../components/shared-comp/promotions/views/listing';
import PromotionActions from '../../components/shared-comp/promotions/model/actions';
import WorkflowActions from '../../redux/workflow/actions';

const mapStateToProps = (state) => ({
  promotionsLoading: state.promotions.promotionsLoading,
  promotionsError: state.promotions.promotionsError,
  promotionsCalled: state.promotions.promotionsCalled,
  promotions: state.promotions.promotions,
  createPromotionLoading: state.promotions.createPromotionLoading,
  createPromotionError: state.promotions.createPromotionError,
  createdPromotion: state.promotions.createdPromotion,
  country: state.workflow.country,
  countries: state.workflow.countries,
  statuses: state.workflow.statuses,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPromotions: bindActionCreators(PromotionActions.fetchPromotions, dispatch),
  fetchPromotionsFailed: bindActionCreators(PromotionActions.fetchPromotionsFailed, dispatch),
  fetchPromotionsRequest: bindActionCreators(PromotionActions.fetchPromotionsRequest, dispatch),
  fetchPromotionsSuccess: bindActionCreators(PromotionActions.fetchPromotionsSuccess, dispatch),
  getPromotion: bindActionCreators(PromotionActions.getPromotion, dispatch),
  fetchCountries: bindActionCreators(WorkflowActions.fetchCountries, dispatch),
  fetchStatuses: bindActionCreators(WorkflowActions.fetchStatuses, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerPromotionsListing);