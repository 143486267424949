import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContainerPayoutsListing from '../../components/shared-comp/payouts/views/listing';
import PayoutActions from '../../components/shared-comp/payouts/model/actions';
import WorkflowActions from '../../redux/workflow/actions';

const mapStateToProps = (state) => ({
  payoutsLoading: state.payouts.payoutsLoading,
  payoutsError: state.payouts.payoutsError,
  payoutsCalled: state.payouts.payoutsCalled,
  payouts: state.payouts.payouts,
  createPayoutLoading: state.payouts.createPayoutLoading,
  createPayoutError: state.payouts.createPayoutError,
  createdPayout: state.payouts.createdPayout,
  country: state.workflow.country,
  countries: state.workflow.countries,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPayouts: bindActionCreators(PayoutActions.fetchPayouts, dispatch),
  fetchPayoutsFailed: bindActionCreators(PayoutActions.fetchPayoutsFailed, dispatch),
  fetchPayoutsRequest: bindActionCreators(PayoutActions.fetchPayoutsRequest, dispatch),
  fetchPayoutsSuccess: bindActionCreators(PayoutActions.fetchPayoutsSuccess, dispatch),
  getPayout: bindActionCreators(PayoutActions.getPayout, dispatch),
  fetchCountries: bindActionCreators(WorkflowActions.fetchCountries, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerPayoutsListing);