/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useCallback } from 'react';
import ListView from '../../../core/ListView';
import { columnTypes } from '../../../core/TableCommon';
import { formatDate, modules } from '../../../utils';
import themeStyles from '../../../../../theme.module.sass';


const KeywordsListing = ({t, keywords, keywordsLoading, fetchKeywords, fetchCountries, country, countries}) => {

  const module = modules(t).keyword;

  // const queryParamsFilters = {
  //   page : 1,
  //   limit : 10,
  //   sort : [],
  //   q: null,
  //   country: null
  // };
  
  const tableColumns = [
    {label: 'Phrase', type:columnTypes.PHRASE},
    {label: 'Number hits'},
    {label: 'Country'},
    {label: 'Last update'}
  ];

  const createData = useCallback((data) => {
    const {number_hits, phrase, country} = data;
    const numberHits = number_hits;
    const keywordPhrase = {phrase, country};
    const lastUpdate = formatDate(data.updated_at);

    return { numberHits, keywordPhrase, country, lastUpdate };
  }, []);
  
  return (
    <section className={themeStyles["inner-component-view"]}>
        <ListView
        createData={createData} collection={keywords} loadingTable={keywordsLoading} tableColumns={tableColumns} module={module}
        fetchFunction={fetchKeywords} country={country} countries={countries} fetchCountries={fetchCountries} showTitleHeader={false}
      />
    </section>
  );
  
};

export default KeywordsListing;
