import Immutable from 'seamless-immutable';

const initialState = Immutable({
  referrersLoading: false,
  referrersError: null,
  referrersCalled: false,
  referrers: null,
});


export default initialState;
