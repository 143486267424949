/* eslint-disable import/no-cycle */
import api from "./api";
import { GenericError } from '../utils/errors';

export const uploadFile = async file => {
  try {

    const form = new FormData();
    form.append('file', file);
    form.append('provider','GoogleCloud');
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    const response = await api.post('files', form, headers);

    if (!response.ok) throw new GenericError('google sign url not responding ok');
    return response.data.data.url;

  } catch (err) {
    throw new GenericError(err);
  }
};
const files = { uploadFile };

export default files;
