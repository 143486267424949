// import api from './api-engine';
import { getConfig } from '../../../../config';

let api;
  
const checkConfig = () => {
  if (!api) api = getConfig('api');
};

/**
{string} message Products message
{string} name Products name
{Array} numbers Products numbers
*/
export const getProducts = (query) => {
    checkConfig();
    return api.get(`/engine/products`, query);
};


/**
 * Get the blast with the given id
 * @param {string} id id of the products to get
 */
export const getProduct= (id) => {
    checkConfig();
    return api.get(`/engine/products/${id}`);
};


/**
{string} message Categories message
{string} name Categories name
{Array} numbers Categories numbers
*/
export const getCategories = (query) => {
  const queryObj = query;
  if(queryObj?.country === "ALL"){
    delete queryObj.country;
  }
  checkConfig();
  return api.get(`/engine/categories`, queryObj);
};


/**
{string} message Keywords message
{string} name Keywords name
{Array} numbers Keywords numbers
*/
export const getKeywords = (query) => {
    checkConfig();
    return api.get(`/engine/keywords`, query);
};

/**
{string} message Categories message
{string} name Categories name
{Array} numbers Categories numbers
*/
export const getTrendingCategories = (query) => {
    checkConfig();
    return api.get(`/engine/categories/trending`, query);
};

/**
{string} message Keywords message
{string} name Keywords name
{Array} numbers Keywords numbers
*/
export const getTrendingKeywords = (query) => {
    checkConfig();
    return api.get(`/engine/keywords/trending`, query);
};


export const createProduct = (data) => {
    checkConfig();
    return api.post("/engine/products/add", data);
};

export const watchProduct = (producId, data) => {
    checkConfig();
    return api.put(`/engine/products/${producId}`, data);
};
