/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { CircularProgress, Collapse, Grid } from '@mui/material';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import FormikErrorFocus from 'formik-error-focus';
import { actions, callAssignToast, countryPhone, crudAction, getDatei18n, modules, phoneMasks } from '../../../utils';
import PhoneInputEnforcer from '../../../core/PhoneInputEnforcer';
import resellerStyles from './styles.module.sass';
import themeStyles from '../../../../../theme.module.sass';
import SearchSelect from '../../../core/SearchSelect';
import Address, { defaultAddressValues } from '../../../core/Address';

import { getCountry, getCountries } from '../../../core/services/workflow';
import { postCustomers, updateCustomer, softDeleteCustomer } from "../../services/customers";
import ModalCloseButton from '../../../core/ModalCloseButton';

const printDocumentType = (t) => {
  const typesArray = [
    { label: t('ID'), value: 'national', key: "1" },
    { label: t('Passport'), value: 'passport', key: "2" }
  ];
  return typesArray;
};

const CustomersCreate = ({ t, onClose = () => { }, ...props }) => {
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [readyToLoad, setReadyToLoad] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [becomeReferrer, setBecomeReferrer] = useState(false);
  const [loaderEnabled, setLoaderEnabled] = useState(true);

    
  

  const headerCountry = getCountry();
  const initialCountry = currentCustomer?.country || headerCountry || "";

  const module = modules(t).customer;

  const dateFormat = {
    year: 'numeric', month: 'short', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false,
    timeZoneName: 'short'
  };
  const datei18n = getDatei18n(initialCountry);

  const defaultCountries = ["us", "co", "gt", "ar", "ec"];

  const ts = {
    tenderos: t('Customer', { count: 100 }),
    createCustomer: t('Create Customer'),
    createdAt: t('Created at'),
    updatedAt: t('Updated at'),
    tags: t('Tags'),
    description: t('Description'),
    phoneNumber: t('Phone Number'),
    email: t('Email'),
    country: t('Country'),
    documentType: t('Document Type'),
    document: t('Document'),
    pleaseWait: t('Please Wait'),
    create: t('Create'),
    update: t('Update'),
    delete: t('Delete'),
    customerInformation: t('Customer Information'),
    required: t('Required'),
    invalidEmail: t("Invalid Email")
  };

  const CustomerSchema = Yup.object().shape({
    firstName: Yup.string().required(ts.required),
    lastName: Yup.string().required(ts.required),
    country: Yup.string().required(ts.required),
    phone: Yup.string().min(8, ts.required).required(ts.required),
    email: Yup.string().email(ts.invalidEmail),
    documentType: Yup.string().when('document', {
      is: document => !document,
      then: Yup.string(),
      otherwise: Yup.string().required(ts.required)
    }),
    document: Yup.string().when('documentType', {
      is: documentType => !documentType,
      then: Yup.string(),
      otherwise: Yup.string().required(ts.required)
    })
  }, [['document', 'documentType']]);

  

  const crudOptions = {
    module,
    name: currentCustomer?.firstName ? (`${currentCustomer?.firstName } ${ currentCustomer?.lastName}`) : '',
  };

  /**
   * @async
   * Fetches Countries from the API, sets the country list with them and reports any errors via Toasts
   */
  const loadCountries = () => callAssignToast(getCountries, setCountryList, 'list Countries');

    /**
   * Passed to the list to execute on Item click, updates the form with the selected Customer
   * @async
   * @param {string} id
   */
     const fetchCustomerByObject = (object) => {
      const customer = { ...object };
      setCurrentCustomer(customer);
    };

  // On load, get Countries and Customers
  useEffect(() => {
    const initLoad = async () => {
      await Promise.all([loadCountries()]);
      setReadyToLoad(true);
    };
    initLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On Countrylist update, update Countrycodes
  useEffect(() => {
    const currentUpdate = async () => {
      setCountryCodes(countryList.map(country => (country.code).toLowerCase()));
    };
    if (countryList) currentUpdate();
  }, [countryList]);

  useEffect(() => {
    const afterLoad = async () => {
      if (props.object) fetchCustomerByObject(props.object);
      setLoaderEnabled(false);
    };
    if (readyToLoad) afterLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToLoad]);

  // Form placeholders
  const placeholder = {
    firstName: t("Enter your first name"),
    lastName: t("Enter your last name"),
    phone: "+1 (702) 123-4567",
    email: "email@customer.com",
    documentType: ` -- ${t("Select a Type")} -- `,
    document: t("Enter your document"),
    address1: t("Enter your address"),
    address2: t("Enter address details"),
    country: t("Enter your country")
  };

  // Form type, used to differentiate between update and delete
  const submitType = {
    update: 1,
    delete: 2
  };


  const initialCountryLowerCase = initialCountry.toLowerCase();
  const defaultPhonePrefix = (`+${ countryPhone[initialCountryLowerCase].code }${countryPhone[initialCountryLowerCase].enforce ? countryPhone[initialCountryLowerCase].enforce : ""}`);

  // Formik initial/default values
  const formInitialValues = {
    firstName: currentCustomer?.firstName || "",
    lastName: currentCustomer?.lastName || "",
    phone: currentCustomer?.phone || defaultPhonePrefix,
    email: currentCustomer?.email || "",
    country: initialCountry,
    document: currentCustomer?.document || "",
    documentType: currentCustomer?.documentType || "",
    address: (currentCustomer?.addresses && currentCustomer?.addresses[0]) ? {
      addressId: currentCustomer?.addresses?.[0]?.id || 0,
      fullAddress: currentCustomer?.addresses?.[0]?.address1 || "",
      address1: currentCustomer?.addresses?.[0]?.address1 || "",
      address2: currentCustomer?.addresses?.[0]?.address2 || "",
      description: currentCustomer?.addresses?.[0]?.description || "",
      region: currentCustomer?.addresses?.[0]?.region || "",
      zipCode: currentCustomer?.addresses?.[0]?.zipCode || "",
      city: currentCustomer?.addresses?.[0]?.city || "",
      country: currentCustomer?.addresses?.[0]?.country || currentCustomer?.country || "",
      lat: currentCustomer?.addresses?.[0]?.lat || "",
      lng: currentCustomer?.addresses?.[0]?.lng || ""
    } : defaultAddressValues,
    type: submitType.update // update,
  };

  /**
   * Function called whenever the Formik form is submitted
   * @async
   * @param {Object} values Form values
   * @param {Object} options Functions provided by the Formik form
   */
  const formOnSubmit = async (values) => {
    const isUpdate = !(!currentCustomer);

    const newAddress = {
      address1: values.address?.address1,
      country: values.country,
      city: values.address?.city
    };

    if (isUpdate || values?.address?.address2) newAddress.address2 = values.address?.address2 || null;
    if (isUpdate || values?.address?.region) newAddress.region = values.address?.region || null;
    if (isUpdate || values?.address?.description) newAddress.description = values.address?.description || null;
    if (isUpdate || values?.address?.zipCode) newAddress.zipCode = values.address?.zipCode || null;

    const newCustomer = {
      firstName: values.firstName,
      lastName: values.lastName,
      country: values.country,
      phone: values.phone[0] !== '+' ? `+${ values.phone}` : values.phone
    };
    if (isUpdate || (newAddress.address1 && newAddress.country && newAddress.city)) {
      newCustomer.addresses = newAddress.address1 ? [newAddress] : [];
    }

    if (isUpdate || values?.email) newCustomer.email = values.email ? values.email.trim() : null;
    if (isUpdate || values?.document) newCustomer.document = values.document || null;
    if (isUpdate || values?.documentType) newCustomer.documentType = values.documentType || null;

    if (!currentCustomer) { // CREATE
      await crudAction(postCustomers.bind(null, newCustomer), onClose,
        { ...crudOptions, name: values.name, action: actions.CREATE }, t
      );
    } else if (values.type && values.type === submitType.update) { // UPDATE
      if (newCustomer.addresses.length > 0) newCustomer.addresses[0].id = currentCustomer?.addresses?.[0]?.id;
      if (becomeReferrer) newCustomer.type = 'referrer';
      await crudAction(updateCustomer.bind(null, currentCustomer.id, newCustomer), onClose,
        { ...crudOptions, action: actions.UPDATE }, t
      );
    }
  };

  const getOptionsSelect = (data) => {
    if (!data) return;
    return data.map((item, index) => ({
        key: index,
        value: item.code,
        label: item.name
      }));
  };

  return (
    (
      loaderEnabled ? <div style={{ display: 'flex', justifyContent: 'center', padding: '200px' }}>
        <CircularProgress />
      </div>
        :
        <div>
          <ModalCloseButton onClick={() => onClose(true)} floating />
          <Grid container spacing={0} alignItems="flex-start" style={{ marginTop: "-30px" }}>
            <Grid item xs>
              <div className={themeStyles.currentContent}>
                <div className={resellerStyles["tendero-view"]}>
                  <header className={themeStyles["header-form"]}>
                    <h3>{!currentCustomer ? ts.createCustomer : `${currentCustomer.firstName } ${ currentCustomer.lastName}`}</h3>
                  </header>
                  {deleteConfirmation ? (
                    <Grid container className={resellerStyles["delete-section"]}>
                      <Grid item xs={12} className={resellerStyles["delete-description"]}>
                        {t('deleteConfirmMessage', { name: `${currentCustomer.firstName } ${ currentCustomer.lastName}` })}
                      </Grid>
                      <Grid item xs={12} className={`${themeStyles["form-group"] } ${ resellerStyles["delete-buttons"]}`}>
                        <button type="button" className={`${themeStyles.btn } ${ themeStyles["btn-transparent"]}`} disabled={deleting} onClick={(e) => {
                          e.preventDefault();
                          setDeleteConfirmation(false);
                        }}>{t('Cancel')}</button>
                        <button type="button" className={`${themeStyles.btn } ${ themeStyles["btn-danger"]}`} disabled={deleting} onClick={
                          async (e) => {
                            e.preventDefault();
                            setDeleting(true);
                            // DELETE
                            await crudAction(softDeleteCustomer.bind(null, currentCustomer.id), onClose,
                              { ...crudOptions, action: actions.DELETE }, t
                            );
                          }
                        }>{ts.delete}</button>
                      </Grid>
                    </Grid>
                  ) : ("")}

                  <Collapse in={!deleteConfirmation}>
                    {!currentCustomer ? (
                      <div />
                    ) : (
                      <div>
                        <div className={themeStyles["date-created"]}>
                          <b>{ts.createdAt}: &nbsp;</b> {` ${ new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentCustomer.created_at))}`}
                        </div>
                        <div className={themeStyles["date-updated"]}>
                          <b>{ts.updatedAt}: &nbsp;</b> {` ${ new Intl.DateTimeFormat(datei18n, dateFormat).format(new Date(currentCustomer.updated_at))}`}
                        </div>
                      </div>
                    )}

                    <div className={themeStyles["form-wrap"]}>
                      <Formik enableReinitialize initialValues={formInitialValues} onSubmit={formOnSubmit} validationSchema={CustomerSchema} autoComplete="off" >
                        {({ isSubmitting, values, handleChange, handleBlur, setFieldValue, setFieldTouched, errors, touched }) => (
                          <Form>
                            <div className={resellerStyles["tendero-title"]}>
                              {ts.customerInformation}
                            </div>
                            <div>
                              <Grid container spacing={1} className={themeStyles["form-group"]}>
                                <Grid item xs={6}>
                                  <label htmlFor="firstName" className={themeStyles["col-form-label"]}>{t('First Name')}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <Field className={themeStyles["form-control"]} name="firstName" placeholder={placeholder.firstName} value={values.firstName} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                                  {errors.firstName && touched.firstName ? (<div className={themeStyles.formError}>{errors.firstName}</div>) : ' '}
                                </Grid>
                                <Grid item xs={6}>
                                  <label htmlFor="lastName" className={themeStyles["col-form-label"]}>{t("Last Name")}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <Field className={themeStyles["form-control"]} name="lastName" placeholder={placeholder.lastName} value={values.lastName} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                                  {errors.lastName && touched.lastName ? (<div className={themeStyles.formError}>{errors.lastName}</div>) : ' '}
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} className={themeStyles["form-group"]}>
                                <Grid item xs={6}>
                                  <label htmlFor="phone" className={themeStyles["col-form-label"]}>{ts.phoneNumber}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <PhoneInputEnforcer prefix="+" masks={phoneMasks} inputClass={themeStyles["phone-input"]}
                                    country={initialCountry?.toLowerCase()}
                                    onlyCountries={countryCodes && countryCodes.length > 0 ? countryCodes : defaultCountries}
                                    value={values.phone} onChange={pNumber => setFieldValue('phone', pNumber)}
                                    autoComplete="new-password" placeholder={placeholder.phone} name="phone"
                                    onBlur={() => setFieldTouched('phone', true)} disableDropdown={initialCountry} countryCodeEditable={!initialCountry}
                                  />
                                  {errors.phone && touched.phone ? (<div className={themeStyles.formError}>{errors.phone}</div>) : ' '}
                                </Grid>
                                <Grid item xs={6}>
                                  <label htmlFor="email" className={themeStyles["col-form-label"]}>{ts.email}</label>
                                  <Field className={themeStyles["form-control"]} name="email" placeholder={placeholder.email} value={values.email} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                                  {errors.email && touched.email ? (<div className={themeStyles.formError}>{errors.email}</div>) : ' '}
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} className={themeStyles["form-group"]}>
                                <Grid item xs={12}>
                                  <label htmlFor="country" className={themeStyles["col-form-label"]}>{ts.country}<span className={themeStyles["label-asterisk"]}>*</span></label>
                                  <SearchSelect
                                    onChange={e => setFieldValue('country', e?.value || '')}
                                    value={values.country} onBlur={() => setFieldTouched('country', true)}
                                    options={getOptionsSelect(countryList, t)} placeholder={placeholder.country} disabled={initialCountry}
                                  />
                                  {errors.country && touched.country ? (<div className={themeStyles.formError}>{errors.country}</div>) : ' '}
                                </Grid>
                              </Grid>
                              <Grid container spacing={1} className={themeStyles["form-group"]}>
                                <Grid item xs={6}>
                                  <label htmlFor="documentType" className={themeStyles["col-form-label"]}>{ts.documentType}</label>
                                  <SearchSelect
                                    onChange={e => e ? setFieldValue('documentType', e.value) : setFieldValue('documentType', '')}
                                    value={values.documentType} onBlur={() => setFieldTouched('documentType', true)}
                                    options={printDocumentType(t)} placeholder={placeholder.documentType} isClearable
                                  />
                                  {errors.documentType && touched.documentType ? (<div className={themeStyles.formError}>{errors.documentType}</div>) : ' '}
                                </Grid>
                                <Grid item xs={6}>
                                  <label htmlFor="document" className={themeStyles["col-form-label"]}>{ts.document}</label>
                                  <Field className={themeStyles["form-control"]} name="document" placeholder={placeholder.document} value={values.document} onChange={handleChange} onBlur={handleBlur} autoComplete="new-password" />
                                  {errors.document && touched.document ? (<div className={themeStyles.formError}>{errors.document}</div>) : ' '}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={resellerStyles["tendero-title"]}>
                              {t("address-location")}
                            </div>
                            <Address name="address" country={values.country} />
                            {!currentCustomer ? (
                              <div />
                            ) : (
                              <Grid item xs={6}>
                                <h6>
                                  <input type="checkbox" name="becomeReferrer" checked={becomeReferrer} onChange={
                                    (e) => { setBecomeReferrer(e.target.checked); }
                                  } />
                                  {` ${ t('Change to referrer?')}`}
                                </h6>
                              </Grid>
                            )}
                            <div className={`${themeStyles["form-group"] } ${ themeStyles["form-crud-actions"]}`}>
                              <button type="submit" className={`${themeStyles.btn } ${ themeStyles["btn-secondary"]}`} disabled={isSubmitting} onClick={() => {
                                // eslint-disable-next-line no-console
                                console.log({ errors });
                                setFieldValue('type', submitType.update);
                              }}>{
                                  isSubmitting ? `${ts.pleaseWait}...` : (!currentCustomer ? ts.create : ts.update)
                                }</button>
                            </div>
                            <FormikErrorFocus offset={0} align="top" duration={1000} />
                          </Form>
                        )}
                      </Formik>

                    </div>
                  </Collapse>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>)
  );
};

export default withTranslation()(CustomersCreate);