import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Icon from '../Icon';
import { useTranslation } from 'react-i18next';
import themeStyles from '../../../../theme.module.sass';
import styles from './styles.module.sass';

export const DialogComponent = forwardRef(({ button, title, closeIcon, subtitle, content, alert: isAlert, confirm: isConfirm, confirmUpdate: confirmSetter, hideCancel, hideConfirm = false }, ref) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(false);
  const { t } = useTranslation();
  useImperativeHandle(ref, () => ({

    handleClickOpen() {
      setOpen(true);
    },
    handleClickClose() {
      setOpen(false);
    }

  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleClose = (newValue, backdropClick) => {
    if(!backdropClick) setValue(newValue);
    setOpen(false);
  };

  useEffect(() => {
    if (value) {
      if (isConfirm && confirmSetter) confirmSetter(value);
      setValue(null);
    }
  }, [value, isConfirm, confirmSetter]);

  useEffect(() => {
    if (open) setValue(null);
  }, [open]);

  return (
    <div>

      {button &&
        <Button className={`${themeStyles.btn } ${ themeStyles['btn-secondary']}`} onClick={handleClickOpen}>
          Open alert dialog
        </Button>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {(title || closeIcon) &&
          <DialogTitle id="alert-dialog-title" className={styles['title-section']} >
            <Box display="flex" justifyContent="space-between">
              <div className={styles.title}>
                {title || '' }
              </div>
              <div className={styles.icon} onClick={handleClickClose}>
                {closeIcon ? <Icon icon="close-" size="20" color="#d7d7d7" /> : ''}
              </div>
            </Box>
          </DialogTitle>
        }
        
        {content &&
          <DialogContent className={!title ? styles.dialog : ''}>
            <DialogContentText id="alert-dialog-description">
              {!subtitle ? '' :
                <div>
                  {subtitle}
                </div>
              }
              {content}
            </DialogContentText>
          </DialogContent>
        }
        {
          (isAlert || (isConfirm && confirmSetter) ) &&
          <DialogActions className={styles.actions}>
              {(isConfirm && !hideCancel) &&
                <Button className={`${themeStyles.btn } ${ themeStyles['btn-transparent']}`} onClick={() => {
                  handleClose();
                }}>{t('Cancel')}</Button>
            }
            {(!hideConfirm &&
                <Button className={`${themeStyles.btn } ${ themeStyles['btn-secondary']}`} onClick={() => {
                  handleClose(true);
                }} autoFocus>{t('Accept')}</Button>
            )}
          </DialogActions>
        }
      </Dialog>
    </div>
  );
});

export default DialogComponent;