import { getConfig } from '../../../../config';

let api;
const defaultLimit = 100;

const checkConfig = () => {
  if (!api) api = getConfig('api');
};

export const getTransactions = (query) => {
  checkConfig();
  return api.get(`/transactions`, query);
};

export const getTransactionsList = ({ page = 1, limit = defaultLimit, searchTerms }) => {
  checkConfig();
  if (searchTerms) return api.get(`/transactions?page=${page}&limit=${limit}&q=${searchTerms}`);
  return api.get(`/transactions?page=${page}&limit=${limit}`);
};

/**
 * Creates a transaction
 * @param {object} data
 */
export const postTransactions = (data) => {
  checkConfig();
  return api.post('/transactions', data);
};

/**
 * Get the transaction with the given id
 * @param {string} id id of the transaction to get
 */
export const getTransaction = (id) => {
  checkConfig();
  return api.get(`/transactions/${id}`);
};

/**
 * Update the transaction with the given id
 * @param {string} id id of the transaction to update
 * @param {object} data
 */
export const updateTransaction = (id, data) => {
  checkConfig();
  return api.put(`/transactions/${id}`, data);
};

/**
 * Delete the transaction with the given id
 * @param {string} id id of the transaction to delete
 */
export const deleteTransaction = (id) => {
  checkConfig();
  return api.delete(`/transactions/${id}`);
};


export const getCommissions = (query) => {
  checkConfig();
  return api.get(`/transactions/commissions`, query);
};

export const getCommissionsList = ({ page = 1, limit = defaultLimit, searchTerms }) => {
  checkConfig();
  if (searchTerms) return api.get(`/transactions/commissions?page=${page}&limit=${limit}&q=${searchTerms}`);
  return api.get(`/transactions/commissions?page=${page}&limit=${limit}`);
};

/**
 * Creates a transaction
 * @param {object} data
 */
export const postCommissions = (data) => {
  checkConfig();
  return api.post('/transactions/commissions', data);
};

/**
 * Get the transaction with the given id
 * @param {string} id id of the transaction to get
 */
export const getCommission = (id) => {
  checkConfig();
  return api.get(`/transactions/commissions/${id}`);
};

/**
 * Update the transaction with the given id
 * @param {string} id id of the transaction to update
 * @param {object} data
 */
export const updateCommission = (id, data) => {
  checkConfig();
  const dataObj = {
    status: data.status,
    commissions: [
      id
    ]
  };
  return api.put('/commissions', dataObj);
};

/**
 * Delete the transaction with the given id
 * @param {string} id id of the transaction to delete
 */
export const deleteCommission = (id) => {
  checkConfig();
  return api.delete(`/transactions/commissions/${id}`);
};

export const getPaymentMethodsByCountry = ({country, totalOrder}) => {
  if (!country) return [];
  const countryFormate = country.toUpperCase();
  checkConfig();
  return api.get(`/transactions/payment-methods`, {country:countryFormate, totalOrder});
};