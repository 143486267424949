import { getConfig } from '../../../../config';

let api;
const defaultLimit = 100;

const checkConfig = () => {
  if (!api) api = getConfig('api');
};

/**
{string} message Payout message
{string} name Payout name
{Array} numbers Payout numbers
*/
export const getPromotions = (query) => {
  checkConfig();
  // if (query.country) {
  //   query = {
  //     countries: query.country,
  //     ...query
  //   }
  //   delete query.country;
  // }
  // if (query.dateFrom) {
  //   query = {
  //     fromDate: query.dateFrom,
  //     ...query
  //   }
  //   delete query.dateFrom;
  // }
  // if (query.dateTo) {
  //   query = {
  //     toDate: query.dateTo,
  //     ...query
  //   }
  //   delete query.dateTo;
  // }
  // if (query.commissionStatus) {
  //   query = {
  //     statuses: query.commissionStatus,
  //     ...query
  //   }
  //   delete query.commissionStatus;
  // }
  // if (query.type) {
  //   query = {
  //     types: [query.type],
  //     ...query
  //   }
  //   delete query.type;
  // }
  const newQuery = {
    perPage: query.limit,
    currentPage: query.page,
    ...query
  };
  delete newQuery.limit;
  delete newQuery.page;
  return api.get(`/promotions`, newQuery);
};

/**
{string} message Payout message
{string} name Payout name
{Array} numbers Payout numbers
*/
export const getPromotionsList = ({ page = 1, limit = defaultLimit, searchTerms }) => {
  checkConfig();
  if (searchTerms) return api.get(`/promotions?page=${page}&limit=${limit}&q=${searchTerms}`);
  return api.get(`/payouts?page=${page}&limit=${limit}`);
};

  
/**
 * Creates a payout
 * @param {object} data
 */
export const postPromotions = (data) => {
  checkConfig();
  return api.post('/promotions', data);
};



/**
 * Get the payout with the given id
 * @param {string} id id of the payout to get
 */
export const getPromotion = (id) => {
  checkConfig();
  return api.get(`/promotions/${id}`);
};

/**
 * Update the payout with the given id
 * @param {string} id id of the payout to update
 * @param {object} data
 * data.name {string} Payout name
 * data.message {string} Payout message
 * data.numbers {Array} Payout numbers
 */
export const updatePromotion = (id, data) => {
  checkConfig();
  return api.put(`/promotions/${id}`, data);
};

export const validateCode = (code) => {
  checkConfig();
  return api.post('/promotions/validate-code', {code});
};

export const updatePromotionStatus = (id, data) => {
  checkConfig();
  return api.put(`/promotions/status/${id}`, data);
};
