import Immutable from 'seamless-immutable';

const initialState = Immutable({
  blastsInit: true,
  blastsLoading: false,
  blastsError: null,
  blastsLoaded: false,
  blasts: null,

  createBlastLoading: false,
  createBlastError: null,
  createdBlast: false,
});


export default initialState;
