import React, { Fragment, useEffect } from 'react';
import UrlsComponent from '../urls';
import Snakbar from '../components/snakbar';
import Button from '../components/Button';
import Box from '@mui/material/Box';
import AuthActions from '../redux/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
// import { BrowserRouter as Router, Routes } from "react-router-dom";
import { ThemeProvider } from '@mui/material';
import { theme } from '../theme';
import OrderIdContextProvider from '../components/shared-comp/orders/context/orderIdContext';

const Root = ({ showUpdateMessage = false }) => {

  const dispatch = useDispatch();
  const serviceWorker = useSelector(state => state.serviceWorker.registration);

  useEffect(() => {
    dispatch(AuthActions.restoreUserAccess());
  }, [dispatch]);

  let refreshing;

  const updateVersion = () => {
    if (serviceWorker) {
      const {waiting} = serviceWorker;
      if (waiting) {
        waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload(true);
      }
    }
  };

  if (showUpdateMessage && !refreshing) {
    refreshing = setTimeout(updateVersion, 2000);
  }


  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Snakbar
          open={showUpdateMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message="New version available, updating..."
          action={
            <Button clean onPress={updateVersion}>
                <Box style={{ fontWeight: 'bold' }} component="span">Update</Box>
              </Button>
          }
        />
        <OrderIdContextProvider>
          <UrlsComponent />
        </OrderIdContextProvider>
      </Fragment>
    </ThemeProvider>
  );
};

export default Root;
