/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, Fragment, useMemo, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, Box, IconButton } from '@mui/material';
import BasicEmptyState from '../BasicEmptyState';
import { durationFromNow, formatTimestamp } from '../../utils';
import gStyles from '../../../../theme.module.sass';
import styles from './styles.module.sass';
import Icon from '../Icon';
import AddBonus from '../../payouts/forms/AddBonus';
import BasicModal from '../BasicModal';
import moment from 'moment';
import Menu, { createItem } from '../Menu';
import { removeBonus} from '../../payouts/services/payout';
import Price from '../Price';
import WaoOverlay from '../WaoOverlay';
import { getCountry } from '../services/workflow';
import { Link } from 'react-router-dom';
import 'moment-timezone';
import { toast } from 'react-toastify';

export const columnTypes = {
  SELECT: 1,
  IMAGE: 2,
  LINK: 3,
  MAP: 4,
  DATE: 5,
  FROM_NOW: 6,
  BADGE: 7,
  MAP_HTML: 8,
  MAP_PROMO: 9,
  TEXT: 10,
  TOGGLE: 11,
  NAME_REF:12,
  PHRASE:13,
  CATEGORY_NAME:14,
  RANGE_PRICE:15,
  PRICE:16
};

export const togglerOrientation = {
  TOP: 1,
  RIGHT: 2,
  BOTTOM: 3,
  LEFT: 4,
};

const orientationStyle = {
  1: 'column-reverse',
  2: 'row',
  3: 'column',
  4: 'row-reverse',
};

const DefaultClosedToggler = ({ setOpen, color }) => (
  <IconButton style={{ marginLeft: "6px" }} aria-label='expand row' size='small' onClick={setOpen}>
    <Icon color={color || '#310f49'} icon='plus-square' />
  </IconButton>
);

const DefaultOpenToggler = ({ setOpen, color }) => (
  <IconButton style={{ marginLeft: "6px" }} aria-label='expand row' size='small' onClick={setOpen}>
    <Icon color={color || '#310f49'} icon='minus-square' />
  </IconButton>
);

const renderToggler = ({ content, orientation = 2, disabled, toggler: ClosedTogglerComponent, openToggler: OpenTogglerComponent, colorExpandIconDefault }, isOpen, toggleOpen) => {
  const closedTogglerToUse = () => ClosedTogglerComponent ? <ClosedTogglerComponent /> : <DefaultClosedToggler color={colorExpandIconDefault}/>;
  let openTogglerToUse = () => OpenTogglerComponent ? <OpenTogglerComponent /> : <DefaultOpenToggler color={colorExpandIconDefault}/>;
  // If a custom closed toggler was provided but no custom open toggler was provided, use the closed one for both statuses
  if (ClosedTogglerComponent && !OpenTogglerComponent) openTogglerToUse = closedTogglerToUse;
  return (
    <div className={`${styles.toggler} ${gStyles[orientationStyle[orientation]]}`}>
      <div>{content}</div>
      {disabled ? "" : <div onClick={toggleOpen}>{isOpen ? openTogglerToUse() : closedTogglerToUse()}</div>}
    </div>
  );
};

const renderBadge = ({ colorClass, description, descriptionStyles, reasonStyles, lowerHtmlStyles, colorIconReason, reason, reasonClickHandler, value, valuePosicion }) => {
  
  let descriptionHtml;
  let lowerHtml;
  let reasonHtml = '';
  
  if (description) {
    descriptionHtml = <div className={gStyles["badge-description"]} style={{...descriptionStyles}}>
      {description}
    </div>;
  }
  if (reason) {
    reasonHtml = <div className={gStyles.clickable} style={{...reasonStyles}} onClick={reasonClickHandler}>
      <Icon icon="help" size="20" title={reason} color={colorIconReason}/>
    </div>;
  }
  if (description || reason) {
    lowerHtml = <div style={{...lowerHtmlStyles}}>
      {description ? descriptionHtml : ''}
      {reason ? reasonHtml : ''}
    </div>;
  }

  return <div>
    {(valuePosicion === 1 || !valuePosicion) && <div className={`${gStyles["table-badge"]} ${gStyles.badge} ${gStyles["badge-pill"]} ${(colorClass ? gStyles[colorClass] : '')}`}>
      {value}
    </div>}
    {lowerHtml}
    {(valuePosicion === 3 && valuePosicion) && <div className={`${gStyles["table-badge"]} ${gStyles.badge} ${gStyles["badge-pill"]} ${(colorClass ? gStyles[colorClass] : '')}`}>
      {value}
    </div>}
  </div>;
};

const PlaceholderLoading = ({ rows, columns }) => {
  let html = '';
  if (rows && rows.length === 0) {
    html = <TableRow key={1}><TableCell key={1} colSpan={columns.length}><BasicEmptyState /></TableCell></TableRow>;
  } else {
    html = <TableRow key={1}>
      {columns.map((column, i) => (
        <TableCell key={i} className="table-placeholder-item" />
      ))}
    </TableRow>;
  }
  return (html);
};

const TableCommon = ({ t, toupdate, condition, headers=[], rows, columns = [], subcolumns = [], subcolumns2 = [], loadingTable,
  sortBy, expandAtEnd, subtableClass, subtableHeadClass, altStyle, expandAllRows, hiddenSubHeader=false, externalCellStyles, externalTableStyles, scrollToSides}) => {

  const headersLabels = (headers && headers.length) ? [...headers] : [...columns];
  
  const subTableMainContainerStyles = useMemo(()=> !condition ? {} : { padding: '10px 30px 0px' }, [condition]);
  const [sortedBy, setSortedBy] = useState({ key: '', direction: 'asc' });
  const headerCountry = getCountry();
  const initialCountry = headerCountry || "";
  const [countryTimeZone, setCountryTimeZone] = useState([]);
  const tableContainerRef = useRef();
  const tableHasHorizontalScroll = (tableContainerRef?.current?.scrollWidth && tableContainerRef?.current?.scrollWidth > tableContainerRef?.current?.clientWidth);
    
  useEffect(() => {
    // console.log("moment.tz?", moment.tz?.zonesForCountry(initialCountry)[0])
    setCountryTimeZone(moment.tz?.zonesForCountry(initialCountry));
  }, [initialCountry]);
  
  const FormatTableCell = ({ data, type, tooltip, isOpen, toggleOpen }) => {
    let html;
    switch (type) {
      case columnTypes.IMAGE:
        html = <img src={data} alt={tooltip || ""} />;
        break;

      case columnTypes.LINK:
        html = (
          <a target='_blank' rel='noopener noreferrer' href={data?.link}>
            {t(data?.label || "View")}
          </a>
        );
        break;

      case columnTypes.MAP:
        html = data?.collection.map((item, i) => (
          <Grid
            key={item.id || item.key || i}
            container
            className={i < data.collection.length - 1 ? gStyles["form-label"] : ""}
          >
            <Grid item xs={12}>
              <span>{item[data?.label]}</span>
            </Grid>
            {!item[data?.description] ? (
              ""
            ) : (
              <Grid item xs={12}>
                <span className={gStyles["table-list-phone"]}>{item[data?.description]}</span>
              </Grid>
            )}
          </Grid>
        ));
        break;

      case columnTypes.MAP_PROMO:
        if (!data?.collection?.length) {
          html = "ERR";
          break;
        }
        html = data?.collection.map((item, i) => (
          <Grid
            key={item.id || item.key || i}
            container
            className={i < data.collection.length - 1 ? gStyles["form-label"] : ""}
          >
            <Grid item xs={12}>
              <span>
                {item.promotion && item.promotion[data?.label].length < 10 ? (
                  item.promotion[data?.label]
                ) : (
                  <div className={gStyles.tooltip}>
                    {item.promotion[data?.label].substring(0, 7)}...
                    <span className={gStyles.tooltiptext}>{item.promotion[data?.label]}</span>
                  </div>
                )}
                {!item.promotion && "-"}
              </span>
            </Grid>
            <Grid item xs={12}>
              <span className={gStyles["table-list-phone"]}>
                {item.promotion ? (
                  <Price value={item.promotion[data?.description]} />
                ) : (
                  ""
                )}
              </span>
            </Grid>
          </Grid>
        ));
        break;

      case columnTypes.DATE:
        if (Number.isNaN(Date.parse(data))) {
          html = !data ? data : <span className={gStyles.capitalize} style={{minWidth: "100px"}}>{data}</span>;
        } else {
          html = !data ? (
            "-"
          ) : (
            <span className={gStyles.capitalize} style={{minWidth: "100px"}} title={durationFromNow(data)}>
              {formatTimestamp(data, null, null,countryTimeZone?.[0])}
            </span>
          );
        }
        break;

      case columnTypes.FROM_NOW:
        html = <span title={formatTimestamp(data)}>{durationFromNow(data)}</span>;
        break;

      case columnTypes.BADGE:
        html = renderBadge(data || {});
        break;

      case columnTypes.MAP_HTML:
        html = (
          <span className='parent-span'>
            {data?.collection.map((item, i) => (
              <span key={i}>{item}</span>
            ))}
          </span>
        );
        break;

      case columnTypes.SELECT:
        html = (
          <span className='parent-span'>
            <input
              type='checkbox'
              id={data?.id}
              checked={data?.checked}
              onChange={() => data?.onChange(data?.id, !data?.checked)}
            />
          </span>
        );
        break;

      case columnTypes.TEXT:
        html = (
          <div className={styles["text-row"]}>
            <div
              className={`${styles.title} ${data?.bold ? gStyles.bold : ""} ${
                data?.titleClass ? data?.titleClass : ""
              }`}
            >
              {data?.title}
            </div>
            {!data?.description ? (
              ""
            ) : (
              <div
                className={`${styles.description} ${data?.subStyle ? styles.sub : ""} ${
                  data?.descriptionClass ? data?.descriptionClass : ""
                }`}
              >
                {data?.description}
              </div>
            )}
          </div>
        );
        break;
      case columnTypes.TOGGLE:
        html = renderToggler(data, isOpen, toggleOpen);
        break;
      case columnTypes.NAME_REF:
        // if (data?.isTrending > 0) {
        //   html = <span><a target="_blank" rel="noopener noreferrer" href={data?.link} >{data?.name}</a><b>&nbsp;TRENDING</b></span>;
        // } else {
        html = <span><a target="_blank" rel="noopener noreferrer" href={data?.link} >{data?.name}</a></span>;
        // }
        break;
      case columnTypes.PHRASE:
        html = <Link to={{ pathname: '/products', phrase: data?.phrase, country: data?.country }}>{data?.phrase}</Link>;
        break;
      case columnTypes.CATEGORY_NAME:
        html = <Link to={{ pathname: '/products', category_root: data?.name, country: data?.country }}>{data?.name}</Link>;
        break;
      case columnTypes.RANGE_PRICE:
        html = <span><Price value={data?.price_min || 0} currency={data?.currency} />&nbsp;-&nbsp;<Price value={data?.price_max || 0} currency={data?.currency} /></span>;
        break;
      case columnTypes.PRICE:
        html = <Price value={data?.price || 0} currency={data?.currency} />;
        break;
      default:
        if (tooltip) html = <div title={tooltip}>{data}</div>;
        else html = <div>{data}</div>;
        break;
    }
    
    return html;
  };
  
  const clickTab = (head) => {
    const newSort = { key: head.sort, direction: (sortedBy.direction === 'asc') ? 'desc' : 'asc' };
    setSortedBy(newSort);
    if (sortBy) sortBy(newSort);
  };
  
  const Row = useCallback(({ row, columns: innerColumns, rowIndex, subcolumns: innerSubcolumnsProvided }) => {
    let skipSubheaders = false;
    let bonus = [];
    let { children: subrows } = row;
    let innerSubcolumns = innerSubcolumnsProvided;
    if ((subrows && !innerSubcolumns?.length) || hiddenSubHeader) {
      skipSubheaders = true;
      if(!hiddenSubHeader) innerSubcolumns = innerColumns;
    }

    if (condition) {
      bonus = row.data?.rewards;
      subrows = row.data?.commissions;
    }
    
    const [open, setOpen] = useState(expandAllRows || false);
    const collapsibleClickHandler = () => { setOpen(!open); };
    
    const hasSubtable = subrows?.length || !!condition;
    
    const subTableCellContainerStyles = useMemo(()=>({
      paddingBottom: 0,
      paddingTop: 0,
    }),[]);
    
    if (altStyle) {
      subTableCellContainerStyles.paddingLeft = 0;
      subTableCellContainerStyles.paddingRight = 0;
    }

    // Divider between cells made with shadow
    const cellStyles = useMemo(()=>{
      const cellStylesObj = externalCellStyles?.cellStyles || {
        border: "0px solid #BBB",
        borderTop: "4px solid #d9d9df",
        // borderRightWidth: "0px",
        boxShadow: "-30px 0px 0px -29px #d9d9d9"
      };

      return cellStylesObj;

    },[externalCellStyles]);

    const subCellStyles = useMemo(()=>{
      let subCellStylesObj = externalCellStyles?.subCellStyles || {
        border: "0px solid #BBB",
        // borderRightWidth: "0px",
        // boxShadow: "inset 18px 0px 0px -17px #BBB !important",
        boxShadow: "20px 0px 0px -19px #b3b3b3",
        color: "#7f7f7f",
        fontSize: "13px"
      };
    
      if (!open) subCellStylesObj = {...subCellStylesObj, padding: "0px", borderBottom: "none"};

      return subCellStylesObj;
  
    },[open, externalCellStyles]);

    
    const tableRowStyles = useMemo(()=>{
      let tableRowStylesObj = externalTableStyles?.tableRowStyles || {
        // background: open ? "#fff" : rowIndex % 2 == 0 ? "#fefefe" : "#fafafa"
        background: rowIndex % 2 === 0 ? "#fefefe" : "#f2f2f2",
      };

      if (open) tableRowStylesObj = {...tableRowStylesObj, boxShadow: "none"}; // "0px 18px 22px 13px #000";
      return tableRowStylesObj;
    },[open, rowIndex, externalTableStyles]);

    const headerSubCellStyles = useMemo(()=>(externalCellStyles?.headerCellStyles || {
      // borderRight: '1px solid #BBB',
      background: "#686868",
      color: "#FFF"
    }),[externalCellStyles]);


    /* *********************************************** */
    const arr = [];
    const arrBonus = [];

    if (condition) {
      
      let dealQuantity = 0;
      let orderTotal = 0;
      let commissionAmount = 0;
      subrows.forEach(subrow => {
        const arrNew = [subrow.orderId2, subrow.orderUser, subrow.dealName, subrow.dealQuantity, subrow.orderTotal, 'Base', subrow.commissionAmount];
        arr.push(arrNew);
        dealQuantity += subrow.dealQuantity;
        orderTotal += subrow.orderTotal;
        commissionAmount += subrow.commissionAmount;
      });
      arr.push([['TOTAL'], [], [], [dealQuantity], [orderTotal], [], [commissionAmount]]);
    
      /* ***********Bonus******************** */
      
      const menu = (rewardLogId) => {
        const id = {
          "idPayout": row.data?.id,
          "idRewardLog": rewardLogId
        };
        const editButtonHtml = createItem({ icon: { icon: 'edit_cover-' }, text: t("Edit") });
        const editHtml = <BasicModal toupdate={toupdate} pagoId={id} content={AddBonus} typeButton="html" modalSize="sm" object={{ type: 'update' }} options={{ html: editButtonHtml }} />;
        return (
          <Menu items={[
            { html: editHtml },
  
            {
              text: 'Eliminar', icon: { icon: "delete-" }, onClick: async () => {
                const response = await removeBonus({
                  "idRewardLog": rewardLogId,
                  "idPayout": row.data?.id
                });
                if (response?.ok) {
                  toupdate();
                  toast.error('Bono Eliminado');
                }
              }
            },
          ]} />
        );
      };
    
      let total = 0;
  
      bonus.forEach(subrow => {
        const arrNew = [subrow.rewardName, subrow.rewardDescription, subrow.commissionAmount, menu(subrow.rewardLogId)];
        arrBonus.push(arrNew);
        total += subrow.commissionAmount;
      });
      arrBonus.push([['TOTAL'], [], [total]]);
    }
    /* ******************************* */

    const renderSubRows = useCallback(() =>
      subrows.map((subrow, subRowIndex) => (
        <TableRow
          className={row.borderColorAlert ? (subRowIndex === (subrows.length - 1) ? styles.subAlertBorderRow : styles.subAlertBorderRowSides) : ""}
          key={subRowIndex}
          style={{
            background: rowIndex % 2 === 0 ? "#fefefe" : "#f2f2f2",
            // background: subRowIndex % 2 === 0 ? "#f5f5f5" : "#f9f9f9",
            
            color: "#818181",
            fontSize: "13px"
          }}
        >
          {innerSubcolumns.map((subcolumn, subColumnIndex) => (
            <TableCell key={subColumnIndex} align={subcolumn.align} style={subCellStyles} colSpan={subcolumn?.childrenRow?.colSpan || subcolumn.colSpan}>
              <Collapse in={open} timeout='auto' unmountOnExit>
                <FormatTableCell
                  data={subrow[Object.keys(subrow)[subColumnIndex]]}
                  type={subcolumn?.type}
                  object={subrow.data}
                  tooltip={subrow.tooltip}
                />
              </Collapse>
            </TableCell>
          ))}
        </TableRow>
      )), [innerSubcolumns, open, rowIndex, subCellStyles, subrows]);
  
    const renderSubTable = useCallback(() => {
    
      if (skipSubheaders) return renderSubRows();

      return (
        <TableRow className={row.borderColorAlert ? styles.subAlertBorderRow : ""}>
          <TableCell style={subTableCellContainerStyles} colSpan={innerColumns.length}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box style={{ position: "relative", width: "100%", height: "100%" }}>
                <div style={subTableMainContainerStyles}>
                  <Table
                    size='small'
                    aria-label='purchases'
                    className={`${gStyles["sub-table"]} ${altStyle ? gStyles["alt-sub-table"] : ""} ${
                      subtableClass || ""
                    }`}
                  >
                    <TableHead>
                      <TableRow
                        className={`${gStyles["sub-table-head"]} ${altStyle ? gStyles["alt-sub-table-head"] : ""} ${
                          subtableHeadClass || ""
                        }`}
                      >
                        {innerSubcolumns.map((subcolumn, index) =>
                          subcolumn.label === "SELECTALL" ? (
                            <TableCell style={headerSubCellStyles} key={index} align={subcolumn.align} colSpan={subcolumn?.childrenRow?.colSpan || subcolumn.colSpan}>
                              <input
                                type='checkbox'
                                checked={subcolumn.isChecked}
                                onChange={() => {
                                  subcolumn.method();
                                }}
                              />
                            </TableCell>
                          ) : (
                            <TableCell style={headerSubCellStyles} key={index} align={subcolumn.align} colSpan={subcolumn?.childrenRow?.colSpan || subcolumn.colSpan}>
                              {t(subcolumn.label)}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>{renderSubRows()}</TableBody>
                  </Table>
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      );
    }, [headerSubCellStyles, innerColumns.length, innerSubcolumns, open, renderSubRows, skipSubheaders, subTableCellContainerStyles]);

    return (
      <Fragment key={rowIndex || row?.id}>
        {!condition ? (
          <>
            <TableRow key={rowIndex} style={tableRowStyles} className={row.borderColorAlert ? styles.alertBorderRow : ""}>
              {innerColumns.map((column, columnIndex) => (
                <TableCell key={columnIndex} align={column.align} style={cellStyles} colSpan={column?.row?.colSpan || column.colSpan}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: (column?.align || 'left') }}>
                    {!expandAtEnd && columnIndex === 0 && hasSubtable &&
                      <IconButton aria-label="expand row" size="small" onClick={collapsibleClickHandler}>
                        {open ? "-" : "+"}
                      </IconButton>
                    }
                    <FormatTableCell data={row[Object.keys(row)[columnIndex]]} type={column?.type} object={row.data} tooltip={row.tooltip} isOpen={open} toggleOpen={collapsibleClickHandler} />
                  </div>
                </TableCell>
              ))}
          
            </TableRow>
            {!subrows?.length ? <TableRow /> : renderSubTable()}
          </>
        ) : (
          <>
            <TableRow key={rowIndex} style={tableRowStyles}>
              {innerColumns.map((column, columnIndex) => (
                <TableCell key={columnIndex} align={column.align} style={cellStyles} colSpan={column?.row?.colSpan || column.colSpan}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: (column?.align || 'left') }}>
                    {!expandAtEnd && columnIndex === 0 && hasSubtable &&
                      <IconButton aria-label="expand row" size="small" onClick={collapsibleClickHandler}>
                        {open ? "-" : "+"}
                      </IconButton>
                    }
                    <FormatTableCell data={row[Object.keys(row)[columnIndex]]} type={column?.type} object={row.data} tooltip={row.tooltip} />
                    {expandAtEnd && (columnIndex === innerColumns.length - 1) && hasSubtable &&
                      <IconButton style={{ marginLeft: "6px" }} aria-label="expand row" size="small" onClick={collapsibleClickHandler}>
                        <Icon icon={open ? "minus-square" : "plus-square"} />
                      </IconButton>
                    }
                  </div>
                </TableCell>
              ))}
          
            </TableRow>
            <TableRow>
              <TableCell style={subTableCellContainerStyles} colSpan={innerColumns.length}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box style={{ position: "relative", width: "100%", height: "100%" }}>
                      <div style={subTableMainContainerStyles}>
                        <h5>{t('Commissions products')}</h5>
                      <Table size="small" aria-label="purchases" className={`${gStyles['sub-table']} ${altStyle ? gStyles['alt-sub-table'] : ''} ${subtableClass || ''}`}>
                        <TableHead>
                          <TableRow
                            className={`${gStyles['sub-table-head']} ${altStyle ? gStyles['alt-sub-table-head'] : ''} ${subtableHeadClass || ''}`}
                          >
                            {innerSubcolumns.map((subcolumn, index) => (
                              (subcolumn.label === 'SELECTALL') ?
                                <TableCell style={headerSubCellStyles} key={index} align={subcolumn.align} colSpan={subcolumn?.row?.colSpan || subcolumn.colSpan}>
                                  <input type="checkbox" checked={subcolumn.isChecked}
                                    onChange={() => { subcolumn.method(); }} />
                                </TableCell>
                                :
                                <TableCell style={headerSubCellStyles} key={index} align={subcolumn.align} colSpan={subcolumn?.row?.colSpan || subcolumn.colSpan}>{t(subcolumn.label)}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(subrows && subrows.length > 0) ? arr.map((e, subRowIndex) => (
                            <TableRow key={subRowIndex} style={{
                              background: subRowIndex % 2 === 0 ? "#f5f5f5" : "#f9f9f9",
                              
                            }}>
                              {e.map((element, index) => (
                                <TableCell key={index}>{element}</TableCell>
                              ))}
                        
                            </TableRow>
                          ))
                            : <PlaceholderLoading rows={rows} columns={innerColumns} />}
                        </TableBody>
                      </Table>
                    </div>
                      <div style={{ marginTop: '30px', paddingLeft: '30px', paddingRight: '30px' }}>
                        <h4>{t('Bonuses')}</h4>
                      <Table size="small" aria-label="purchases" className={`${gStyles['sub-table']} ${altStyle ? gStyles['alt-sub-table'] : ''} ${subtableClass || ''}`}>

                        <TableHead>
                          <TableRow
                            className={`${gStyles['sub-table-head']} ${altStyle ? gStyles['alt-sub-table-head'] : ''} ${subtableHeadClass || ''}`}
                          >
                            {subcolumns2.map((subcolumn, index) => (
                              (subcolumn.label === 'SELECTALL') ?
                                <TableCell style={headerSubCellStyles} key={index} align={subcolumn.align} colSpan={subcolumn?.row?.colSpan || subcolumn.colSpan}>
                                  <input type="checkbox" checked={subcolumn.isChecked}
                                    onChange={() => { subcolumn.method(); }} />
                                </TableCell>
                                :
                                <TableCell style={headerSubCellStyles} key={index} align={subcolumn.align} colSpan={subcolumn?.row?.colSpan || subcolumn.colSpan}>{t(subcolumn.label)}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(subrows && subrows.length > 0) ? arrBonus.map((e, index) => (
                            <TableRow key={index} style={{
                              background: index % 2 === 0 ? "#f5f5f5" : "#f9f9f9",
                              
                            }}>
                              {e.map((element, eIndex) => (
                                <TableCell key={eIndex}>{element}</TableCell>
                              ))}

                            </TableRow>

                          )) : <PlaceholderLoading rows={rows} columns={innerColumns} />}

                        </TableBody>
                      </Table>
                      <br />
                      <section className={styles.buttons} >
                        <BasicModal toupdate={toupdate} pagoId={row.data?.id} content={AddBonus} typeButton="create" modalSize="sm" object={{ type: 'add' }} options={{ label: t('+ Add Bonus') }} />
                      </section>
                      <div style={{ width: '98%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingLeft: '20px' }}>
                        <div style={{ width: '150px', fontSize: '15px', background: '#686868', color: 'white', padding: '10px 20px', border: '0.5px solid #686868' }}>{t('TOTAL PAYMENTS')}</div>
                        <div style={{ width: '150px', fontSize: '25px', background: 'white', padding: '20px' }}>${row.total}</div>
                      </div>
                      <br />
                    </div>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        )
        }
      </Fragment>
    );
  }, [hiddenSubHeader, loadingTable, condition, expandAllRows, altStyle, externalCellStyles?.cellStyles, externalCellStyles?.headerCellStyles, externalCellStyles?.subCellStyles, externalTableStyles?.tableRowStyles, subTableMainContainerStyles, t, subtableClass, subtableHeadClass, rows, subcolumns2, toupdate, expandAtEnd]);


  const getSortClasses = (column, innerSortedBy) => {
    let result = styles['tablecell-inner'];
    if (column.sort) result = `${result } ${ styles['sort-cell']}`;
    if (innerSortedBy.key === column.sort) result = `${result } ${ styles['sorting-active'] } ${ styles[`sorting-active-${ innerSortedBy.direction}`]}`;
    return result;
  };

  const headerCellStyles = useMemo(()=>{
    const headerCellStylesObj = externalCellStyles?.headerCellStyles || {
      borderRight: '1px solid #BBB',
      background: "#481F67",
      color: "#FFF"
    };

    return headerCellStylesObj;
  
  },[externalCellStyles?.headerCellStyles]);

  const renderExpandIcon = (expanded, handler) => (
    <div onClick={handler}>
      <Icon color="#fff" icon={expanded ? "minus-square" : "plus-square"} />
    </div>
  );

  const buttonStyles = {
    position: "absolute",
    top: "5%",
    display: "block",
    background: "#DDD",
    zIndex: "200",
    borderRadius: "10px",
    cursor: "pointer",
    padding: "1px"
  };

  const iconStyles = {
    display: "flex"
  };

  const rightButtonStyles = {
    ...buttonStyles,
    transform: "scale(-1)",
    right: "-26px",
  };
  const leftButtonStyles = {
    ...buttonStyles,
    left: "-26px",
  };

  return (
    <section className={`${gStyles.box} ${gStyles['box-table']}`} style={{
        flex: "0 1 auto"
    }}>
      {scrollToSides && tableHasHorizontalScroll ?
        <div style={rightButtonStyles} onClick={() => tableContainerRef.current.scrollTo({ left: tableContainerRef.current.scrollWidth, behavior: 'smooth' })}>
          <Icon style={iconStyles} icon="arrow_back" size="18" color="white" />
        </div>
      : ''}
      {scrollToSides && tableHasHorizontalScroll ?
        <div style={leftButtonStyles} onClick={() => tableContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' })}>
          <Icon style={iconStyles} icon="arrow_back" size="18" color="white" />
        </div>
      : ''}
        <WaoOverlay active={loadingTable} messageOff zIndexOverlay={500}/>
        <TableContainer ref={tableContainerRef} component={Paper} style={{
        height: "100%"
      }}>
        <Table stickyHeader aria-label="sticky table" className={gStyles['table-common']} >
          <colgroup>
            {headersLabels.map((column, i) => (
              <col key={i} style={{ width: column?.width || null }} />
            ))}
          </colgroup>
          <TableHead style={headerCellStyles}>
            <TableRow style={headerCellStyles}>
              {headersLabels.map((column, index) => (
                (column.type === columnTypes.SELECT) ?
                  <TableCell key={index} align={column.align} style={headerCellStyles} colSpan={column?.row?.colSpan || column.colSpan}>
                    <input type="checkbox" checked={column.isChecked} onChange={() => { column.method(); }} />
                  </TableCell>
                : ( column.sort) ?
                  <TableCell key={index} align={column.align} onClick={() => clickTab(column)} style={headerCellStyles} colSpan={column?.row?.colSpan || column.colSpan}>
                    <div className={getSortClasses(column, sortedBy)}>
                      {t(column.label)}
                        {column.sort ? <i><Icon color="#000" size={14} icon="arrow_down" /></i> : null}
                    </div>
                  </TableCell>
                    :
                    <TableCell key={index} align={column.align} style={headerCellStyles} colSpan={column?.row?.colSpan || column.colSpan}>
                      {column.expandIcon ? renderExpandIcon(column.expanded, column.method) : ''}
                      {t(column.label)}
                    </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rows && rows.length > 0) ? rows.map((row, rowIndex) => (
              <Row key={rowIndex} row={row} columns={columns} rowIndex={rowIndex} subcolumns={subcolumns}/>
            )) : <PlaceholderLoading rows={rows} columns={columns} />}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};
export default withTranslation()(TableCommon);